import React from "react";
import { Button } from "semantic-ui-react";
import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";
import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../../constants/endpoints";
import ZendeskIcon from "../../../../../../../../components/Icons/ZendeskIcon/ZendeskIcon";

export const SCHENGEN_SELECT_VALUES = {
  GENDERS: "GENDERS",
  MARITAL_STATUS: "MARITAL_STATUS",
  COUNTRIES: "COUNTRIES",
  SCHENGEN_COUNTRIES: "SCHENGEN_COUNTRIES",
  SCHENGEN_COUNTRIES_FLAGS: "SCHENGEN_COUNTRIES_FLAGS",
  COVERING_COSTS: "COVERING_COSTS",
  ENTRIES_REQUESTED: "ENTRIES_REQUESTED",
  FAMILY_RELATION: "FAMILY_RELATION",
  MEANS_OF_SUPPORT: "MEANS_OF_SUPPORT",
  MEANS_OF_SUPPORT_SPONSOR: "MEANS_OF_SUPPORT_SPONSOR",
  PURPOSES: "PURPOSES",
  TRAVEL_DOCUMENT_TYPE: "TRAVEL_DOCUMENT_TYPE",
  COUNTRIES_FLAGS:"COUNTRIES_FLAGS",
  ACCOMMODATION_DETAILS:"ACCOMMODATION_DETAILS",
  FAMILY_MEMBER_NATIONALITIES: "FAMILY_MEMBER_NATIONALITIES",
};

const AddressSchema = (path) => ({
  country: {
    label: "Country",
    type: TYPES.SELECT,
    required: true,
    values: SCHENGEN_SELECT_VALUES.COUNTRIES,
    geoLocationProps: [
      "city",
      "streetAddress",
      "houseNumber",
    ],
    path,
  },
  streetAddress: {
    label: "Street address",
    type: TYPES.TEXT,
    path,
  },
  streetAddress2: {
    label: "Street address 2",
    type: TYPES.TEXT,
    path,
  },
  houseNumber: {
    label: "House number",
    type: TYPES.TEXT,
    path,
  },
  city: {
    label: "City",
    type: TYPES.TEXT,
    path,
  },
  zipCode: {
    label: "Zip code",
    type: TYPES.TEXT,
    required: true,
    path,
  },
});

export const schengenSchema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      applicationType: {
        label: "Application Type",
      },
      mainApplicationId: {
        label: "Main Application ID",
        dependsOn: "applicationType",
        dependantOnValues: ["Secondary"],
      },
      siteId: {
        label: "Referral Site",
      },
      cartSiteId: {
        label: "Purchased from website",
      },
      orderId: {
        label: "Order ID",
      },
      orderIdHistory:{
        label:"Order ID History"
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
        renderIcon: (propValue) => (
          <Button
            onClick={() => {
              window.open(
                TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(propValue),
                "_blank",
              );
            }}
            style={{ width: 36, height: 36, padding: 0, float: "right" }}
            disabled={!propValue}
          >
            <ZendeskIcon className="zendesk-icon" />
          </Button>
        ),
      },
      submissionEmail: {
        label: "Submission email",
        type: TYPES.TEXT,
      },
      submissionPassword: {
        label: "Submission password",
        type: TYPES.TEXT,
      },
      gclid: {
        label: "Google client ID",
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      salesAgent: {
        label: "Sales Agent",
      },
      salesCoupon: {
        label: "Sales Coupon",
      },
    },
  },
  contactInfoSegment: {
    label: "Contact information",
    type: TYPES.SEGMENT,
    data: {
      firstName: {
        label: "First name",
        type: TYPES.TEXT,
      },
      lastName: {
        label: "Family name",
        type: TYPES.TEXT,
      },
      userEmail: {
        label: "User email address",
        type: TYPES.TEXT,
      },
      gender: {
        label: "Gender",
        type: TYPES.SELECT,
        values: SCHENGEN_SELECT_VALUES.GENDERS,
      },
      surnameAtBirth: {
        label: "Surname at birth",
        type: TYPES.TEXT,
      },
      birthDate: {
        label: "Birth date",
        type: TYPES.DATE,
      },
      birthCountry: {
        label: "Birth country",
        type: TYPES.SELECT,
        values: SCHENGEN_SELECT_VALUES.COUNTRIES,
      },
      birthCity: {
        label: "Birth city",
        type: TYPES.TEXT,
      },
      maritalStatus: {
        label: "Marital status",
        type: TYPES.SELECT,
        values: SCHENGEN_SELECT_VALUES.MARITAL_STATUS,
      },
      otherMaritalStatus: {
        label: "Other marital status",
        type: TYPES.TEXT,
        dependsOn: "maritalStatus",
        dependantOnValues: ["OTHER"],
      },
      countryCode: {
        label: "Country code",
        type: TYPES.SELECT,
        values: SCHENGEN_SELECT_VALUES.COUNTRIES_FLAGS,
        value: "key",
        valuesText: "phone",
      },
      phoneNumber: {
        label: "Phone number",
        type: TYPES.TEXT,
      },
    },
  },
  applicantInformation: {
    label: "Applicant information",
    type: TYPES.SEGMENT,
    data: {
      applicationCountry: {
        label: "Application country",
        type: TYPES.SELECT,
        values: SCHENGEN_SELECT_VALUES.COUNTRIES,
      },
      currentNationality: {
        label: "Nationality",
        type: TYPES.SELECT,
        values: SCHENGEN_SELECT_VALUES.COUNTRIES,
      },
      hasBirthNationality: {
        label: "Has birth nationality",
        type: TYPES.CHECKBOX,
      },
      birthNationality: {
        label: "Birth nationality",
        type: TYPES.SELECT,
        values: SCHENGEN_SELECT_VALUES.COUNTRIES,
        dependsOn: "hasBirthNationality",
      },
      hasParentalAuthority: {
        label: "Has parental authority",
        type: TYPES.CHECKBOX,
      },
      parentalAuthorityInfo: {
        label: "Parental authority info",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "hasParentalAuthority",
        nested: {
          surname: {
            label: "Surname",
            type: TYPES.TEXT,
            path: "parentalAuthorityInfo",
          },
          firstName: {
            label: "First name",
            type: TYPES.TEXT,
            path: "parentalAuthorityInfo",
          },
          hasSameAddressAsApplicant: {
            label: "Has same address as applicant",
            type: TYPES.CHECKBOX,
            path: "parentalAuthorityInfo",
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn: "parentalAuthorityInfo.hasSameAddressAsApplicant",
            dependantOnValues: [false],
            path: "parentalAuthorityInfo",
            nested: AddressSchema("parentalAuthorityInfo.address"),
          },
        },
      },
      hasNationalId: {
        label: "Has national id",
        type: TYPES.CHECKBOX,
      },
      nationalId: {
        label: "National identity number",
        type: TYPES.TEXT,
        dependsOn: "hasNationalId",
      },
      travelDocumentType: {
        label: "Travel document type",
        type: TYPES.SELECT,
        values: SCHENGEN_SELECT_VALUES.TRAVEL_DOCUMENT_TYPE,
      },
      travelDocumentOtherInfo: {
        label: "Travel document other",
        type: TYPES.TEXT,
        dependsOn: "travelDocumentType",
        dependantOnValues: ["OTHER"],
      },
      passportNumber: {
        label: "Number of travel document",
        type: TYPES.TEXT,
      },
      travelDocumentDateOfIssue: {
        label: "Date of issue",
        type: TYPES.DATE,
      },
      travelDocumentValidUntil: {
        label: "Valid until",
        type: TYPES.DATE,
      },
      issueCountry: {
        label: "Issued by",
        type: TYPES.SELECT,
        values: SCHENGEN_SELECT_VALUES.COUNTRIES,
      },
      hasOtherNationality: {
        label: "Is resident in other country",
        type: TYPES.CHECKBOX,
      },
      otherNationality: {
        label: "Other resident",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "hasOtherNationality",
        nested: {
          permitType: {
            label: "Residence permit",
            type: TYPES.TEXT,
            path: "otherNationality",
          },
          permitNumber: {
            label: "Permit number",
            type: TYPES.TEXT,
            path: "otherNationality",
          },
          residencyIssuanceDate: {
            label: "Date of issue",
            type: TYPES.DATE,
            path:"otherNationality"
          },
          validUntil: {
            label: "Valid until",
            type: TYPES.DATE,
            path: "otherNationality",
          },
        },
      },
      homeAddress: {
        label: "Address",
        note: "Parts of this section will be used to verify the client's ID on Calls/Chats",
        type: TYPES.NESTED,
        newTable: true,
        nested: AddressSchema("homeAddress"),
      },
    },
  },
  familyMemberData: {
    label: "Family member data",
    type: TYPES.SEGMENT,
    data: {
      hasFamilyMemberInfo: {
        label: "Has family member info",
        type: TYPES.CHECKBOX,
      },
      familyMemberInfo: {
        label: "Family member info",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "hasFamilyMemberInfo",
        nested: {
          surname: {
            label: "Surname",
            type: TYPES.TEXT,
            path: "familyMemberInfo",
          },
          firstName: {
            label: "First name",
            type: TYPES.TEXT,
            path: "familyMemberInfo",
          },
          birthDate: {
            label: "Birth date",
            type: TYPES.DATE,
            path: "familyMemberInfo",
          },
          nationality: {
            label: "Nationality",
            type: TYPES.SELECT,
            path: "familyMemberInfo",
            values: SCHENGEN_SELECT_VALUES.FAMILY_MEMBER_NATIONALITIES,
          },
          travelDocumentId: {
            label: "Travel document id",
            type: TYPES.TEXT,
            path: "familyMemberInfo",
          },
          relation: {
            label: "Relationship",
            type: TYPES.SELECT,
            path: "familyMemberInfo",
            values: SCHENGEN_SELECT_VALUES.FAMILY_RELATION,
          },
        },
      },
    },
  },
  employmentInformation: {
    label: "Employment information",
    type: TYPES.SEGMENT,
    data: {
      isStudent: {
        label: "Is student",
        type: TYPES.CHECKBOX,
      },
      studentInfo: {
        label: "Student contact info",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "isStudent",
        nested: {
          educationalEstablishment: {
            label: "Educational establishment",
            type: TYPES.TEXT,
            path: "studentInfo",
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            path: "studentInfo",
            nested: AddressSchema("studentInfo.address"),
          },
        },
      },
      unemployedOrHomemaker: {
        label: "Is unemployed or homemaker",
        type: TYPES.CHECKBOX,
      },
      currentOccupation: {
        label: "Current occupation",
        type: TYPES.TEXT,
        dependsOn: "unemployedOrHomemaker",
        dependantOnValues:[false,undefined]
      },
      employerContactInfo: {
        label: "Employer contact info",
        type: TYPES.NESTED,
        dependsOn: "unemployedOrHomemaker",
        dependantOnValues:[false,undefined],
        newTable: true,
        nested: {
          employerName: {
            label: "Employer name",
            type: TYPES.TEXT,
            path: "employerContactInfo",
          },
          countryCode: {
            label: "Country code",
            type: TYPES.SELECT,
            values: SCHENGEN_SELECT_VALUES.COUNTRIES_FLAGS,
            value: "key",
            valuesText: "phone",
            path: "employerContactInfo",
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "employerContactInfo",
          },
          employerEmail: {
            label: "Email address",
            type: TYPES.TEXT,
            path: "employerContactInfo"
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            path: "employerContactInfo",
            nested: AddressSchema("employerContactInfo.address"),
          },
        },
      },
    },
  },
  previousTravelHistory: {
    label: "Previous travel history",
    type: TYPES.SEGMENT,
    data: {
      hadSchengenVisaBefore: {
        label: "Had Schengen visa before",
        type: TYPES.CHECKBOX,
      },
      previousSchengenVisaInfo: {
        label: "Previous Schengen Visa Info",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "hadSchengenVisaBefore",
        nested: {
            from: {
              label: "Valid until",
              type: TYPES.DATE,
              path: "previousSchengenVisaInfo",
            },
            to: {
              label: "Valid until",
              type: TYPES.DATE,
              path: "previousSchengenVisaInfo",
            },
            previousSchengenVisaNumber: {
              label: "Number of last biometric Schengen visa",
              type: TYPES.TEXT,
              path: "previousSchengenVisaInfo",
            },
        },
      },
      hadFingerprintsCollected: {
        label: "Had fingerprints collected",
        type: TYPES.CHECKBOX,
      },
      fingerprintsInfo: {
        label: "Fingerprints info",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "hadFingerprintsCollected",
        nested: {
            date: {
              label: "Date",
              type: TYPES.DATE,
              path: "fingerprintsInfo",
            },
        },
      },
    },
  },
  tripDetails: {
    label: "Trip details",
    type: TYPES.SEGMENT,
    data: {
      mainPurposeOfJourney: {
        label: "Main purpose of the journey",
        type: TYPES.SELECT,
        values: SCHENGEN_SELECT_VALUES.PURPOSES,
      },
      otherPurpose: {
        label: "Other purpose specify",
        type: TYPES.TEXT,
        dependsOn: "mainPurposeOfJourney",
        dependantOnValues: ["OTHER"],
      },
      otherPurposesOfJourney: {
        label: "Other purpose(s) of the trip",
        type: TYPES.TEXT,
        dependsOn:"mainPurposeOfJourney"
      },
      schengenCountryToApply: {
        label: "Schengen country you are applying for visa",
        type: TYPES.SELECT,
        values: SCHENGEN_SELECT_VALUES.SCHENGEN_COUNTRIES,
      },
      memberStatesOfDestination: {
        label: "Member states of destination",
        type: TYPES.MULTI_SELECT,
        values: SCHENGEN_SELECT_VALUES.SCHENGEN_COUNTRIES,
        customDependency: (applicant) => {
          if(!applicant.memberStatesOfDestinationInfo?.length) return true;
        }
      },
      memberStatesOfDestinationInfo: {
        label: "Member states of destination",
        type: TYPES.ARRAY,
        customDependency: (applicant) => {
          if(!applicant.memberStatesOfDestination?.length) return true;
        },
        data: {
          country: {
            label: "Country",
            type: TYPES.SELECT,
            path: "memberStatesOfDestinationInfo.$memberStatesOfDestinationInfo",
            values: SCHENGEN_SELECT_VALUES.SCHENGEN_COUNTRIES,
          },
          durationOfStayInDays: {
            label: "Duration of the intended stay or transit",
            type: TYPES.TEXT,
            path: "memberStatesOfDestinationInfo.$memberStatesOfDestinationInfo",
          },
        },
      },
      memberStateOfEntry: {
        label: "Member state of first entry",
        type: TYPES.SELECT,
        values: SCHENGEN_SELECT_VALUES.SCHENGEN_COUNTRIES,
      },
      numberOfEntriesRequested: {
        label: "Number of entries requested",
        type: TYPES.SELECT,
        values: SCHENGEN_SELECT_VALUES.ENTRIES_REQUESTED,
      },
      durationOfStayInDays: {
        label: "Total duration of stay",
        type: TYPES.TEXT,
      },
      numberOfPlannedTrips: {
        label:"Number of planned trips",
        type: TYPES.NUMBER
      },
      intendedDateOfArrival: {
        label: "Date of arrival",
        type: TYPES.DATE,
      },
      intendedDateOfDeparture: {
        label: "Date of departure",
        type: TYPES.DATE,
      },
      accommodationDetails: {
        label: "Accommodation details",
        type: TYPES.SELECT,
        values: SCHENGEN_SELECT_VALUES.ACCOMMODATION_DETAILS,
      },
      invitingPersonInfo: {
        label: "Inviting person info",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "accommodationDetails",
        dependantOnValues: ["INVITING_PERSON"],
        nested: {
            firstName: {
              label: "First name",
              type: TYPES.TEXT,
              path: "invitingPersonInfo",
            },
            lastName: {
              label: "Last name",
              type: TYPES.TEXT,
              path: "invitingPersonInfo",
            },
            gender: {
              label: "Gender",
              type: TYPES.SELECT,
              values: SCHENGEN_SELECT_VALUES.GENDERS,
            },
            invitingPersonInfoDetails: {
              label: "Additional Details",
              type: TYPES.NESTED,
              newTable: true,
              path: "invitingPersonInfo",
              nested: {
                address: {
                  label: "Address",
                  type: TYPES.NESTED,
                  newTable: true,
                  path: "invitingPersonInfoDetails",
                  nested: AddressSchema("invitingPersonInfo.address"),
                },
                stayAddressDifferentFromInvitingEntry: {
                  label: "The Stay address is different from inviting entity address in the Member State(s)",
                  type: TYPES.CHECKBOX,
                  path: "invitingPersonInfo",
                },
                stayAddress: {
                  label: "Stay address",
                  type: TYPES.NESTED,
                  newTable: true,
                  path: "invitingPersonInfo",
                  nested: AddressSchema("invitingPersonInfo.stayAddress"),
                  dependsOn: "invitingPersonInfo.stayAddressDifferentFromInvitingEntry",
                },
                email: {
                  label: "Email",
                  type: TYPES.TEXT,
                  path: "invitingPersonInfo",
                },
                countryCode: {
                  label: "Country code",
                  type: TYPES.SELECT,
                  values: SCHENGEN_SELECT_VALUES.SCHENGEN_COUNTRIES_FLAGS,
                  value: "key",
                  valuesText: "phone",
                  path: "invitingPersonInfo",
                },
                phoneNumber: {
                  label: "Phone number",
                  type: TYPES.TEXT,
                  path: "invitingPersonInfo",
                },
              },
          },
        },
      },
      temporaryAccommodation: {
        label: "Temporary accommodation info",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "accommodationDetails",
        dependantOnValues: ["TEMPORARY_ACCOMMODATION"],
        nested: {
            name: {
              label: "Name",
              type: TYPES.TEXT,
              path: "temporaryAccommodation",
            },
            address: {
              label: "Address",
              type: TYPES.NESTED,
              newTable: true,
              path: "temporaryAccommodation",
              nested: AddressSchema("temporaryAccommodation.address"),
            },
            stayAddressDifferentFromInvitingEntry: {
              label: "The Stay address is different from inviting entity address in the Member State(s)",
              type: TYPES.CHECKBOX,
              path: "temporaryAccommodation"
            },
            stayAddress: {
              label: "Stay address",
              type: TYPES.NESTED,
              newTable: true,
              path: "temporaryAccommodation",
              nested: AddressSchema("temporaryAccommodation.stayAddress"),
              dependsOn: "temporaryAccommodation.stayAddressDifferentFromInvitingEntry",
            },
            email: {
              label: "Email",
              type: TYPES.TEXT,
              path: "temporaryAccommodation",
            },
            countryCode: {
              label: "Country code",
              type: TYPES.SELECT,
              values: SCHENGEN_SELECT_VALUES.SCHENGEN_COUNTRIES_FLAGS,
              value: "key",
              valuesText: "phone",
              path: "temporaryAccommodation",
            },
            phoneNumber: {
              label: "Phone number",
              type: TYPES.TEXT,
              path: "temporaryAccommodation",
            },
        },
      },
      invitingCompanyInfo: {
        label: "Inviting company info",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "accommodationDetails",
        dependantOnValues: ["INVITING_COMPANY"],
        nested: {
          companyName: {
            label:"Company/organization name",
            type: TYPES.TEXT,
            path: "invitingCompanyInfo",
          },
          address: {
            label: "Company/organization address",
            type: TYPES.NESTED,
            newTable: true,
            nested: AddressSchema("invitingCompanyInfo.address"),
            path: "invitingCompanyInfo",
          },
          stayAddressDifferentFromInvitingEntry: {
            label: "The Stay address is different from inviting entity address in the Member State(s)",
            type: TYPES.CHECKBOX,
            path: "invitingCompanyInfo",
          },
          stayAddress: {
            label: "Stay address",
            type: TYPES.NESTED,
            newTable: true,
            path: "invitingCompanyInfo",
            nested: AddressSchema("invitingCompanyInfo.stayAddress"),
            dependsOn: "invitingCompanyInfo.stayAddressDifferentFromInvitingEntry",
          },
          contactPerson: {
            label:"Contact person",
            type: TYPES.NESTED,
            newTable: true,
            path: "invitingCompanyInfo",
            nested: {
              firstName: {
              label: "First name",
              type: TYPES.TEXT,
              path: "invitingCompanyInfo.contactPerson",
              },
              surname: {
                label: "Surname",
                type: TYPES.TEXT,
                path: "invitingCompanyInfo.contactPerson",
              },
              address: {
                label: "Contact person's address",
                type: TYPES.NESTED,
                newTable: true,
                nested: AddressSchema("invitingCompanyInfo.contactPerson.address"),
                path: "invitingCompanyInfo.contactPerson",
              },
              countryCode: {
                label: "Contact person's country code",
                type: TYPES.SELECT,
                values: SCHENGEN_SELECT_VALUES.SCHENGEN_COUNTRIES_FLAGS,
                value: "key",
                valuesText: "phone",
                path: "invitingCompanyInfo.contactPerson",
              },
              number: {
                label: "Contact person's phone",
                type: TYPES.TEXT,
                path: "invitingCompanyInfo.contactPerson",
              },
              email: {
                label: "Contact person's email",
                type: TYPES.TEXT,
                path: "invitingCompanyInfo.contactPerson",
              },
            },
          },
        }
      },
      coveringCosts: {
        label: "Covering costs",
        type: TYPES.MULTI_SELECT,
        values: SCHENGEN_SELECT_VALUES.COVERING_COSTS,
      },
      otherCoveringCosts: {
        label: "Other covering costs",
        type: TYPES.TEXT,
        dependsOn: "coveringCosts",
        dependantOnValues: ["OTHER"],
      },
      meansOfSupport: {
        label: "Means of support",
        type: TYPES.MULTI_SELECT,
        values: SCHENGEN_SELECT_VALUES.MEANS_OF_SUPPORT,
        dependsOn: "coveringCosts",
        dependantOnValues: ["BY_APPLICANT"],
      },
      meansOfSupportOther: {
        label: "Other means of support",
        type: TYPES.TEXT,
        dependsOn: "meansOfSupport",
        dependantOnValues: ["OTHER"],
      },
      meansOfSupportSponsor: {
        label: "Means of support sponsor",
        type: TYPES.MULTI_SELECT,
        values: SCHENGEN_SELECT_VALUES.MEANS_OF_SUPPORT_SPONSOR,
        dependsOn: "coveringCosts",
        dependantOnValues: ["BY_SPONSOR"],
      },
      meansOfSupportSponsorOther: {
        label: "Other means of support sponsor",
        type: TYPES.TEXT,
        dependsOn: "meansOfSupportSponsor",
        dependantOnValues: ["OTHER"],
      },
      sponsor: {
        label: "Sponsor",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn:"coveringCosts",
        dependantOnValues: ["BY_SPONSOR"],
        nested: {
          firstName: {
            label: "Sponsor's first name",
            type: TYPES.TEXT,
            path: "sponsor",
          },
          lastName: {
            label: "Sponsor's last name",
            type: TYPES.TEXT,
            path: "sponsor",
          },
          address: {
            label: "Sponsor's address",
            type: TYPES.NESTED,
            newTable: true,
            path: "sponsor",
            nested: AddressSchema("sponsor.address"),
          },
          countryCode: {
            label: "Sponsor's country code",
            type: TYPES.SELECT,
            values: SCHENGEN_SELECT_VALUES.COUNTRIES_FLAGS,
            value: "key",
            valuesText: "phone",
            path: "sponsor",
          },
          phoneNumber: {
            label: "Sponsor's phone number",
            type: TYPES.TEXT,
            path: "sponsor"
          },
          sponsorEmail: {
            label: "Sponsor's email",
            type: TYPES.TEXT,
            path: "sponsor",
          },
          relationToApplicant: {
            label: "Relation to applicant",
            type: TYPES.TEXT,
            path: "sponsor",
          }
        }
      },
      hasEntryPermit: {
        label: "Has entry permit",
        type: TYPES.CHECKBOX,
      },
      entryPermitInfo: {
        label: "Entry permit info",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "hasEntryPermit",
        nested: {
            issuedBy: {
              label: "Issued by",
              type: TYPES.SELECT,
              path: "entryPermitInfo",
              values: SCHENGEN_SELECT_VALUES.SCHENGEN_COUNTRIES,
            },
            from: {
              label: "Valid from",
              type: TYPES.DATE,
              path: "entryPermitInfo",
            },
            isValidAfterDepartureDate: {
              label: "Permit it`s still valid",
              type: TYPES.CHECKBOX,
              path: "entryPermitInfo",
            },
        },
      },
    },
  },
}
