import React from "react";
import "./Timespan.styles.scss";

const Timespan = (props) => {
  const { backgroundColor, children, className } = props;
  return (
    <div className={`sk-active-timespan ${className || ""}`} style={props}>
      <div className="sk-timespan-preview" style={{ backgroundColor }}>
        {children}
      </div>
    </div>
  );
};

export default Timespan;
