export const ZENDESK_HOSTS = [
  {
    link: "https://travelassist.zendesk.com",
    name: "Travelassist",
  },
];

export const getZendeskNameByUrl = (url) => {
  return ZENDESK_HOSTS.find((zdHost) => zdHost.link === url)?.name || "";
};
