import {
  Button,
  Checkbox,
  Icon,
  Input,
  Popup,
  Select,
  Table,
  TextArea,
} from "semantic-ui-react";
import {
  DEFAULT_SCHEMA_DATA,
  getEmptyApplicantFromSchema,
} from "./schemaManager";

import { DateInput, DateTimeInput } from "semantic-ui-calendar-react";
import React from "react";
import { FORM_SCHEMA_TYPES as TYPES } from "../constants/schemaGenerator/formSchemaTypes";
import { get, isArray } from "lodash";
import { getNestedPathPropValue } from "./nestedObjects";
import moment from "moment";
import { URL_REGEX } from "../../../../../../common/regex-templates";
import ApplicantViewSegment from "../components/ApplicantView/components/ApplicantViewSegment";
import SecretInputField from "../SecretInputField/SecretInputField";

export function generateSchema(
  schema,
  applicant,
  schemaData,
  changeState,
  errors,
  openAllSection,
) {
  const now = moment(new Date());
  const birthDate = applicant.birthDate ? moment(applicant.birthDate) : null;
  const nonMinorPerson = birthDate ? now.diff(birthDate, "years") >= 18 : null;
  const renderDateFormat = (format) => {
    return format ? ` (${format}) ` : " (dd-mmm-yyyy) ";
  };

  return Object.keys(schema).map((applicantProperty, index) => {
    const schemaItem = schema[applicantProperty];
    const {
      label,
      dependsOn,
      dependantOnValues,
      yearDependent,
      required,
      geoLocationProps,
      customDependency,
      path,
      type,
      renderIcon,
      format,
      note,
    } = schemaItem;

    if (dependsOn) {
      let depsArray = [];
      let valueDeps = [];
      if (!Array.isArray(dependsOn)) {
        depsArray = [dependsOn];
        valueDeps = dependantOnValues ? [dependantOnValues] : null;
      } else {
        depsArray = dependsOn;
        valueDeps = dependantOnValues;
      }
      for (let index in depsArray) {
        const dependency = depsArray[index];
        const dependencyOnValue = valueDeps ? valueDeps[index] : null;
        const dependValue = get(applicant, dependency);

        if (
          (dependValue === null ||
            dependValue === undefined ||
            dependValue === false) &&
          (!dependencyOnValue ||
            (!dependencyOnValue.includes(false) &&
              !dependencyOnValue.includes(undefined)))
        ) {
          return null;
        }
        if (
          !Array.isArray(dependValue) &&
          dependencyOnValue &&
          !dependencyOnValue.includes(dependValue)
        ) {
          return null;
        }
        if (
          Array.isArray(dependValue) &&
          dependencyOnValue &&
          !dependencyOnValue.some((el) => dependValue.includes(el))
        ) {
          return null;
        }
      }
    }
    if (customDependency && typeof customDependency === "function") {
      const passesDependency = customDependency(applicant, path, schemaData);
      if (!passesDependency) {
        return null;
      }
    }
    if (!nonMinorPerson && yearDependent) {
      return null;
    }
    const propData = generateValueCell(
      schema,
      schemaData,
      applicantProperty,
      applicant,
      changeState,
      false,
      errors,
      openAllSection,
    );

    const labelStyle = [TYPES.NESTED, TYPES.ARRAY].includes(type)
      ? { fontSize: 22 }
      : {};

    let firstCell = (
      <div style={{ display: "grid" }}>
        <span style={labelStyle}>
          {typeof label === "function" ? label(applicant) : label}
          {type === TYPES.DATE ? renderDateFormat(format) : ""}
          {required === false ? "(optional)" : null}
          {geoLocationProps ? (
            <span>
              <Button
                onClick={() => {
                  const propValue = getPropertyValue(
                    applicant,
                    path,
                    applicantProperty,
                  );
                  const values = getValuesConstant(
                    applicant,
                    path,
                    schemaItem.values,
                    schemaData,
                  );

                  const countryName = values
                    ? schemaData[values][propValue]
                    : propValue;

                  const geoLocationValues = geoLocationProps.map((prop) =>
                    getPropertyValue(applicant, path, prop),
                  );

                  window.open(
                    `https://www.google.com/maps/search/${countryName}+${geoLocationValues
                      .filter(Boolean)
                      .join("+")}`,
                    "_blank",
                  );
                }}
                color={"red"}
                size="medium"
                icon
                style={{ float: "right" }}
              >
                <Icon name="map" />
              </Button>
            </span>
          ) : null}
          {renderIcon &&
            renderIcon(
              getPropertyValue(applicant, path, applicantProperty),
              applicant,
            )}
        </span>
        {note ? <label>{note}</label> : null}
      </div>
    );

    const rowData = (
      <React.Fragment>
        {propData.error ? (
          <Popup
            content={propData.error.message}
            trigger={<Table.Cell error>{firstCell}</Table.Cell>}
          />
        ) : (
          <Table.Cell>{firstCell}</Table.Cell>
        )}
        <Table.Cell> {propData.cell}</Table.Cell>
      </React.Fragment>
    );

    return propData.type === TYPES.SEGMENT ? (
      propData.cell
    ) : (
      <Table.Row key={index}>
        {propData.type === TYPES.SEPARATOR ? propData.cell : rowData}
      </Table.Row>
    );
  });
}

export function generateValueCell(
  schema,
  schemaData,
  applicantProperty,
  applicant,
  changeState,
  useLabel = false,
  errors,
  openAllSection,
) {
  const schemaItem = schema[applicantProperty];

  const {
    type,
    dependsOn,
    label: labelValue,
    path,
    onChange = () => {},
    disabled = false,
    property,
    data,
    hint,
  } = schemaItem;
  applicantProperty = property ? property : applicantProperty;
  let label = labelValue;
  if (typeof labelValue === "function") {
    label = labelValue(applicant, path, schemaData);
  }
  const values = getValuesConstant(
    applicant,
    path,
    schemaItem.values,
    schemaData,
  );

  const propertyValue = getPropertyValue(applicant, path, applicantProperty);

  let cellValue = propertyValue;

  switch (type) {
    case TYPES.SEPARATOR:
      return {
        type: TYPES.SEPARATOR,
        cell: (
          <Table.Cell colSpan="2">
            <h2>{label}</h2>
          </Table.Cell>
        ),
      };
    case TYPES.TEXT:
      const { textArea = false, allowEditSecret = true } = schemaItem;
      
      cellValue = textArea ? (
        <TextArea
          className="sk-textarea"
          label={useLabel ? label : null}
          value={propertyValue ? propertyValue : ""}
          type="text"
          disabled={disabled}
          onChange={(fieldOptions, field) => {
            changeState(applicantProperty, field.value, path, () =>
              onChange(field.value, changeState, applicant, schemaData),
            );
          }}
          onBlur={() => {
            let value = getPropertyValue(applicant, path, applicantProperty);
            if (value) {
              value = value.trim().replace(/ {2,}/g, " ");
              changeState(applicantProperty, value, path);
            }
          }}
        />
      ) : (
        <SecretInputField
          label={useLabel ? label : null}
          getPropertyValue={getPropertyValue}
          changeState={changeState}
          applicantProperty={applicantProperty}
          path={path}
          applicant={applicant}
          propertyValue={propertyValue}
          onChange={onChange}
          schemaData={schemaData}
          disabled={disabled}
          useLabel={useLabel}
          allowEditSecret={allowEditSecret}
        />

      );
      break;
    case TYPES.MULTI_TEXT:
      const { onlyNumbers, maxLength } = schemaItem;
      cellValue = (
        <React.Fragment>
          {propertyValue?.map((value, index) => (
            <div key={index}>
              <Input
                label={useLabel ? label : null}
                value={value ? value : ""}
                type={onlyNumbers ? "number" : "text"}
                disabled={disabled}
                onChange={(fieldOptions, field) =>
                  changeState(
                    applicantProperty,
                    propertyValue.map((val, ind) =>
                      index === ind ? field.value : val,
                    ),
                    path,
                  )
                }
                onBlur={() =>
                  changeState(
                    applicantProperty,
                    propertyValue.map((value) =>
                      value.trim().replace(/ {2,}/g, " "),
                    ),
                    path,
                  )
                }
              />
              <Button
                style={{ float: "right" }}
                onClick={() =>
                  changeState(
                    applicantProperty,
                    propertyValue.filter(
                      (data, arrayIndex) => arrayIndex !== index,
                    ),
                    path,
                  )
                }
              >
                Delete
              </Button>
            </div>
          ))}
        </React.Fragment>
      );
      cellValue = (
        <React.Fragment>
          <div>{cellValue}</div>
          {(!propertyValue ||
            !maxLength ||
            propertyValue.length < maxLength) && (
            <Button
              onClick={() => {
                if (!propertyValue) changeState(applicantProperty, [""], path);
                else
                  changeState(
                    propertyValue.length,
                    "",
                    path ? `${path}.${applicantProperty}` : applicantProperty,
                  );
              }}
            >
              Add
            </Button>
          )}
        </React.Fragment>
      );
      break;
    case TYPES.EMAIL:
      cellValue = (
        <Input
          label={useLabel ? label : null}
          value={propertyValue ? propertyValue.trim() : ""}
          type="email"
          disabled={disabled}
          onChange={(fieldOptions, field) => {
            changeState(applicantProperty, field.value, path, () =>
              onChange(field.value, changeState, applicant, schemaData),
            );
          }}
        />
      );
      break;
    case TYPES.NUMBER:
      const { min, max } = schemaItem;
      cellValue = (
        <Input
          type="number"
          label={useLabel ? label : null}
          min={min}
          max={max}
          value={propertyValue ? propertyValue : 0}
          disabled={disabled}
          onChange={(fieldOptions, field) => {
            changeState(applicantProperty, field.value, path, () =>
              onChange(field.value, changeState, applicant, schemaData),
            );
          }}
        />
      );
      break;
    case TYPES.CHECKBOX:
      cellValue = (
        <Checkbox
          label={useLabel ? label : null}
          checked={propertyValue ? true : false}
          disabled={disabled}
          onClick={() => {
            changeState(applicantProperty, !propertyValue, path, () =>
              onChange(!propertyValue, changeState, applicant, schemaData),
            );
          }}
        />
      );
      break;
    case TYPES.SELECT: {
      const { value = "$key", text = "$value", valuesText, objectAsValue} = schemaItem;
      let valueArray;
      if (typeof values === "object") {
        valueArray = values;
      } else {
        if (values.includes("default-")) {
          valueArray = DEFAULT_SCHEMA_DATA[values];
        } else {
          valueArray = schemaData[values];
        }
      }
      if (applicant.api === "esta" && label === "Gender") {
        const hasThirdGender =
          get(applicant, "countryOfCitizenship") === "DE" ||
          get(applicant, "issuingCountry") === "DE";
        if (!hasThirdGender)
          valueArray = { 0: valueArray[0], 1: valueArray[1] };
      }

      let options;

      if (isArray(valueArray)) {
        if(objectAsValue) {
          options = valueArray.map((obj) => {
            return { value: obj, text: obj[text] }
          })
        } else {
          options = valueArray.map((obj) => {
            return { value: obj[value], text: valuesText === 'phone' ? `+${obj.phone} - ${obj.value}` : obj[text]}
          })
        }
      } else {
        options = Object.keys(valueArray).map((key) => {
          const option = {};
          switch (value) {
            case "$key":
              option.value = isNaN(key) ? key : +key;
              break;
            case "$value":
              option.value = valueArray[key];
              break;
            default:
          }
          switch (text) {
            case "$key":
              option.text = isNaN(key) ? key : +key;
              break;
            case "$value":
              option.text = valueArray[key];
              break;
            default:
          }
          return option;
        });
      }
      const select = (
        <Select
          lazyLoad
          value={
            propertyValue &&
            (isNaN(propertyValue) ? propertyValue : +propertyValue)
          }
          search={true}
          options={options}
          disabled={disabled}
          placeholder="Please select"
          onChange={(fieldOptions, field) => {
            changeState(applicantProperty, field.value, path, () =>
              onChange(field.value, changeState, applicant, schemaData),
            );
          }}
        />
      );
      cellValue = useLabel ? (
        <div>
          <label>{label}</label>
          {select}
        </div>
      ) : (
        select
      );
      break;
    }
    case TYPES.DEPENDENT_SELECT: {
      const specificData = schemaData[values][get(applicant, dependsOn)];
      const options = Object.keys(specificData).map((key) => {
        return { text: specificData[key], value: isNaN(key) ? key : +key };
      });
      const select = (
        <Select
          lazyLoad
          value={propertyValue}
          options={options}
          disabled={disabled}
          onChange={(fieldOptions, field) => {
            changeState(applicantProperty, field.value, path, () =>
              onChange(field.value, changeState, applicant, schemaData),
            );
          }}
        />
      );
      cellValue = useLabel ? (
        <div>
          <label>{label}</label>
          {select}
        </div>
      ) : (
        select
      );
      break;
    }
    case TYPES.DATE: {
      const { format } = schemaItem;
      let showValue = "";
      if (propertyValue) {
        const _format = format ? format : "DD-MMM-YYYY";
        const formattedDate = moment(propertyValue, _format);
        let dateValue = moment(propertyValue).toDate();
        if (formattedDate.isValid()) {
          dateValue = formattedDate.toDate();
        }
        showValue = moment(dateValue).format(_format);
      }
      cellValue = (
        <DateInput
          name={applicantProperty}
          dateFormat={format ? format : "DD-MMM-YYYY 12:00:00"}
          clearable={true}
          placeholder={label}
          value={showValue}
          popupPosition="top left"
          animation="none"
          duration={0}
          iconPosition="left"
          disabled={disabled}
          onChange={(fieldOptions, field) => {
            changeState(
              applicantProperty,
              field.value
                ? moment(field.value, format).format("DD-MMM-YYYY 12:00:00")
                : "",
              path,
            );
          }}
        />
      );
      break;
    }
    case TYPES.DATE_TIME: {
      const { format } = schemaItem;
      cellValue = (
        <DateTimeInput
          name={applicantProperty}
          clearable
          closable
          dateFormat={format ? format : "DD-MMM-YYYY"}
          placeholder={label}
          value={propertyValue}
          startMode="month"
          popupPosition="top left"
          animation="none"
          duration={0}
          iconPosition="left"
          onChange={(fieldOptions, field) =>
            changeState(applicantProperty, field.value, path)
          }
        />
      );
      break;
    }
    case TYPES.MULTI_SELECT:
      const { selectType } = schemaItem;
      const specificData = schemaData[values];
      const selectedValues = selectType === "value" ? [] : [...propertyValue];
      const options = Object.keys(specificData).map((key) => {
        const item = {
          text: specificData[key],
          value: isNaN(key) || selectType === "number" ? key : +key,
        };
        if (selectType === "value") {
          if (propertyValue.includes(specificData[key])) {
            selectedValues.push(item.value);
          }
        }
        return item;
      });
      const select = (
        <Select
          lazyLoad
          value={selectedValues}
          multiple={true}
          search
          options={options}
          disabled={disabled}
          onChange={(fieldOptions, field) =>
            changeState(
              applicantProperty,
              selectType === "value"
                ? field.value.map((key) => specificData[key])
                : field.value,
              path,
            )
          }
        />
      );
      cellValue = useLabel ? (
        <div>
          <label>{label}</label>
          {select}
        </div>
      ) : (
        select
      );
      break;
    case TYPES.NESTED:
      const { nested, newTable } = schemaItem;
      cellValue = newTable ? (
        <Table>
          <Table.Body>
            {generateSchema(nested, applicant, schemaData, changeState, errors)}
          </Table.Body>
        </Table>
      ) : (
        <div className="nested">
          {Object.keys(nested).map((key, index) => {
            return (
              <div key={index}>
                {
                  generateValueCell(
                    nested,
                    schemaData,
                    key,
                    applicant,
                    changeState,
                    true,
                    errors,
                    openAllSection,
                  ).cell
                }
              </div>
            );
          })}
        </div>
      );
      break;
    case TYPES.ARRAY:
      const arrayValue = propertyValue || [];
      const fields = arrayValue.map((arrayData, index) => {
        const arrayLabel = schemaItem && schemaItem.arrayLabel;
        return (
          <React.Fragment key={index}>
            {arrayLabel && (
              <div>
                {arrayLabel} {index + 1}:
              </div>
            )}
            <Table>
              <Table.Body>
                {generateSchema(
                  transformSchemaData(data, "$" + applicantProperty, index),
                  applicant,
                  schemaData,
                  arrayChangeState(changeState, applicantProperty, index),
                  errors,
                )}
              </Table.Body>
            </Table>
            <Button
              style={{ float: "right" }}
              onClick={() =>
                changeState(
                  applicantProperty,
                  arrayValue.filter((data, arrayIndex) => arrayIndex !== index),
                  path,
                )
              }
            >
              Delete
            </Button>
          </React.Fragment>
        );
      });
      const value = getEmptyApplicantFromSchema(data);
      cellValue = (
        <React.Fragment>
          <div>{fields}</div>
          <Button
            onClick={() =>
              changeState(applicantProperty, [...arrayValue, value], path)
            }
          >
            Add
          </Button>
        </React.Fragment>
      );
      break;
    case TYPES.SEGMENT:
      const { initialOpen } = schemaItem;
      return {
        type: TYPES.SEGMENT,
        cell: (
          <ApplicantViewSegment
            initialOpen={initialOpen}
            data={data}
            label={label}
            applicant={applicant}
            schemaData={schemaData}
            changeState={changeState}
            errors={errors}
            openAllSection={openAllSection}
            hint={hint}
          />
        ),
      };
    default:
      switch (typeof propertyValue) {
        case "boolean":
          cellValue = propertyValue ? "Yes" : "No";
          break;
        case "object":
          cellValue = Array.isArray(propertyValue)
            ? propertyValue.length > 5
              ? propertyValue.map((el) => <div>{el}</div>)
              : propertyValue.join(", ")
            : JSON.stringify(propertyValue);
          break;
        default:
          if (propertyValue === null || propertyValue === undefined) {
            cellValue = "";
            break;
          }
          if (!Number.isNaN(+propertyValue)) {
            cellValue = propertyValue;
            break;
          }
          if (
            moment(propertyValue).isValid() &&
            !propertyValue.includes("node")
          ) {
            cellValue = moment(propertyValue).format("YYYY-MM-DD HH:mm:ss");
            break;
          }
          if (URL_REGEX.test(propertyValue)) {
            cellValue = (
              <a target="_blank" rel="noopener noreferrer" href={propertyValue}>
                {propertyValue}
              </a>
            );
            break;
          }
          cellValue = propertyValue;
      }

      break;
  }
  const stripperBasePath = path
    ? `${path}.${applicantProperty}`
    : applicantProperty;
  let error = errors ? get(errors, stripperBasePath) : null;

  if (!error && errors) {
    if (
      Object.keys(errors).some((errorKey) =>
        errorKey.startsWith(stripperBasePath),
      )
    ) {
      error = {
        message: "Check nested fields for more information.",
      };
    }
  }

  return {
    type,
    cell: cellValue,
    error,
  };
}

const arrayChangeState = (changeState, indexName, index) => {
  return (applicantProperty, value, path) => {
    return changeState(
      applicantProperty,
      value,
      path.replace("$" + indexName, index),
    );
  };
};

const transformSchemaData = (schemaData, indexName, index) => {
  const newSchema = {};
  Object.keys(schemaData).forEach((key) => {
    newSchema[key] = transformSchemaProp(schemaData[key], indexName, index);
  });
  return newSchema;
};

const transformSchemaProp = (schema, indexName, index) => {
  const newSchema = {};
  Object.keys(schema).forEach((key) => {
    switch (key) {
      case "path":
        newSchema.path = schema.path.replace(indexName, index);
        break;
      case "dependsOn":
        if (Array.isArray(schema[key])) {
          newSchema[key] = schema[key].map((value) =>
            value.replace(indexName, index),
          );
        } else {
          newSchema[key] = schema[key].replace(indexName, index);
        }
        break;
      case "data":
      case "nested":
        newSchema[key] = transformSchemaData(schema[key], indexName, index);
        break;
      default:
        newSchema[key] = schema[key];
        break;
    }
  });
  return newSchema;
};

// function pad(value) {
//   return value < 10 ? "0" + value : value;
// }
// function createOffset(date) {
//   var sign = date.getTimezoneOffset() > 0 ? "-" : "+";
//   var offset = Math.abs(date.getTimezoneOffset());
//   var hours = pad(Math.floor(offset / 60));
//   var minutes = pad(offset % 60);
//   return sign + hours + ":" + minutes;
// }

function getPropertyValue(applicant, path, applicantProperty) {
  if (!path || path.includes("$")) {
    return applicant[applicantProperty];
  } else {
    return getNestedPathPropValue(path, applicantProperty, applicant);
  }
}

function getValuesConstant(applicant, path, values, schemaData) {
  if (typeof values === "function") {
    return values(applicant, path, schemaData);
  }
  return values;
}
