import React from "react";
import { Button } from "semantic-ui-react";
import ZendeskIcon from "../../../../../../../../components/Icons/ZendeskIcon/ZendeskIcon";
import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../../constants/endpoints";
import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";
import { get } from "lodash";
import moment from "moment";

export const GLOBAL_ENTRY_SELECT_VALUES = {
  COUNTRIES_1_FLAG_CODES: "COUNTRIES_1_FLAG_CODES",
  COUNTRIES_1: "COUNTRIES_1",
  COUNTRIES_1_WITH_FLAGS: "COUNTRIES_1_WITH_FLAGS",
  COUNTRIES_2: "COUNTRIES_2",
  ADDITIONAL_CITIZENSHIP_COUNTRIES: "ADDITIONAL_CITIZENSHIP_COUNTRIES",
  ADDITIONAL_CITIZENSHIP_COUNTRIES_WITH_FLAGS:
    " ADDITIONAL_CITIZENSHIP_COUNTRIES_WITH_FLAGS",
  COUNTRIES_2_WITH_FLAGS: "COUNTRIES_2_WITH_FLAGS",
  USA_PROVINCES: "USA_PROVINCES",
  PROVINCES_STATES: "PROVINCES_STATES",
  CANADA_PROVINCES: "CANADA_PROVINCES",
  MEXICO_PROVINCES: "MEXICO_PROVINCES",
  CITIZENSHIP_DOCUMENT_TYPE: "CITIZENSHIP_DOCUMENT_TYPE",
  PROOF_TO_ENTER_US_DOCUMENT_TYPE: "PROOF_TO_ENTER_US_DOCUMENT_TYPE",
  VISA_CLASS: "VISA_CLASS",
  VEHICLE_OWNER: "VEHICLE_OWNER",
  ADDITIONAL_INFO_COUNTRIES: "ADDITIONAL_INFO_COUNTRIES",
  ALL_PROVINCES: "ALL_PROVINCES",
  NATIONALITY: "NATIONALITY",
  NATIONALITY_WITH_FLAGS: "NATIONALITY_WITH_FLAGS",
  COUNTRY_OF_BIRTH: "COUNTRY_OF_BIRTH",
  COUNTRY_OF_BIRTH_WITH_FLAGS: "COUNTRY_OF_BIRTH_WITH_FLAGS",
  COUNTRY_OF_CITIZENSHIP: "COUNTRY_OF_CITIZENSHIP",
  COUNTRY_OF_CITIZENSHIP_WITH_FLAGS: "COUNTRY_OF_CITIZENSHIP_WITH_FLAGS",
  GENDERS: "GENDERS",
  EYE_COLOR: "EYE_COLOR",
  HEIGHT_STANDARDS: "HEIGHT_STANDARDS",
  PHONE_TYPE: "PHONE_TYPE",
  COUNTRY_CODES: "COUNTRY_CODES",
  PHONE_CODES: "PHONE_CODES",
  PHONE_NUMBER_FORMAT: "PHONE_NUMBER_FORMAT",
  RESIDENCY_STATUSES: "RESIDENCY_STATUSES",
  PROGRAM_ID_OPTIONS: "PROGRAM_ID_OPTIONS",
  CITIZENSHIP_COUNTRIES: "CITIZENSHIP_COUNTRIES",
  TRAVEL_HISTORY_COUNTRIES: "TRAVEL_HISTORY_COUNTRIES",
  TRAVEL_HISTORY_COUNTRIES_WITH_FLAGS: "TRAVEL_HISTORY_COUNTRIES_WITH_FLAGS",
  EMPLOYMENT_STATUSES: "EMPLOYMENT_STATUSES",
  HEAR_ABOUT_US_OPTIONS: "HEAR_ABOUT_US_OPTIONS",
};
const AddressSchema = (path, countries) => ({
  country: {
    label: "Country",
    type: TYPES.SELECT,
    required: true,
    values: countries,
    path,
    geoLocationProps: [
      "state",
      "zipCode",
      "city",
      "streetAddress",
      "streetAddress2",
    ],
  },
  addressLine1: {
    label: "Street address",
    type: TYPES.TEXT,
    path,
  },
  addressLine2: {
    label: "Street address 2",
    type: TYPES.TEXT,
    path,
  },
  city: {
    label: "City",
    type: TYPES.TEXT,
    path,
  },
  state: {
    label: "State",
    type: TYPES.TEXT,
    path,
    customDependency: (applicant, path, schemaData) => {
      const country = get(applicant, `${path}.country`);
      if (
        !["UNITED STATES", "CANADA", "MEXICO"].includes(
          schemaData[countries][country],
        )
      )
        return true;
    },
  },
  stateSelectUsa: {
    label: "State",
    type: TYPES.NESTED,
    dependsOn: `${path}.country`,
    dependantOnValues: ["1: US"],
    nested: {
      stateSelect: {
        type: TYPES.SELECT,
        values: GLOBAL_ENTRY_SELECT_VALUES.USA_PROVINCES,
        path,
      },
    },
  },
  stateSelectCanada: {
    label: "State",
    type: TYPES.NESTED,
    dependsOn: `${path}.country`,
    dependantOnValues: ["2: CA"],
    nested: {
      stateSelect: {
        type: TYPES.SELECT,
        values: GLOBAL_ENTRY_SELECT_VALUES.CANADA_PROVINCES,
        path,
      },
    },
  },
  stateSelectMexico: {
    label: "State",
    type: TYPES.NESTED,
    dependsOn: `${path}.country`,
    dependantOnValues: ["3: MX"],
    nested: {
      stateSelect: {
        type: TYPES.SELECT,
        values: GLOBAL_ENTRY_SELECT_VALUES.MEXICO_PROVINCES,
        path,
      },
    },
  },
  neighborhood: {
    label: "Neighborhood",
    type: TYPES.TEXT,
    dependsOn: `${path}.country`,
    dependantOnValues: ["3: MX"],
    path,
  },
  zipCode: {
    label: "Zip code",
    type: TYPES.TEXT,
    path,
  },
});

export const geSchema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      applicationType: {
        label: "Application Type",
      },
      mainApplicationId: {
        label: "Main Application ID",
        dependsOn: "applicationType",
        dependantOnValues: ["Secondary"],
      },
      siteId: {
        label: "Referral Site",
      },
      cartSiteId: {
        label: "Purchased from website",
      },
      orderId: {
        label: "Order ID",
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
        renderIcon: (propValue) => (
          <Button
            onClick={() => {
              window.open(
                TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(propValue),
                "_blank",
              );
            }}
            style={{ width: 36, height: 36, padding: 0, float: "right" }}
            disabled={!propValue}
          >
            <ZendeskIcon className="zendesk-icon" />
          </Button>
        ),
      },
      submissionEmail: {
        label: "Submission email",
        type: TYPES.TEXT,
      },
      submissionPassword: {
        label: "Submission password",
        type: TYPES.TEXT,
      },
      submissionPasswordHistory: {
        label: "Submission password history",
      },
      backupCodes: {
        label: "Backup Codes",
        type: TYPES.MULTI_TEXT,
        maxLength: 10,
        path: "botAccountInformation",
      },
      gclid: {
        label: "Google client ID",
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      salesAgent: {
        label: "Sales Agent",
      },
      salesCoupon: {
        label: "Sales Coupon",
      },
    },
  },
  registrationStep: {
    label: "Registration information",
    type: TYPES.SEGMENT,
    data: {
      userEmail: {
        label: "User email address",
        type: TYPES.TEXT,
      },
      lastName: {
        label: "Surname/last name",
        type: TYPES.TEXT,
      },
      firstName: {
        label: "Given/first name",
        type: TYPES.TEXT,
      },
      middleName: {
        label: "Middle name",
        type: TYPES.TEXT,
      },
      birthDate: {
        label: "Date of birth",
        type: TYPES.DATE,
      },
      birthCountry: {
        label: "Country of birth",
        type: TYPES.SELECT,
        values: GLOBAL_ENTRY_SELECT_VALUES.COUNTRIES_1,
      },
      birthState: {
        label: "Province/state of birth",
        type: TYPES.TEXT,
        customDependency: (applicant, path, schemaData) => {
          const country = get(applicant, "birthCountry");
          if (
            !["UNITED STATES", "CANADA", "MEXICO"].includes(
              schemaData.COUNTRIES_1[country],
            )
          )
            return true;
        },
      },
      stateSelectUsa: {
        label: "State",
        type: TYPES.NESTED,
        dependsOn: "birthCountry",
        dependantOnValues: ["1: US"],
        nested: {
          birthStateSelect: {
            type: TYPES.SELECT,
            values: GLOBAL_ENTRY_SELECT_VALUES.USA_PROVINCES,
          },
        },
      },
      stateSelectCanada: {
        label: "State",
        type: TYPES.NESTED,
        dependsOn: "birthCountry",
        dependantOnValues: ["2: CA"],
        nested: {
          birthStateSelect: {
            type: TYPES.SELECT,
            values: GLOBAL_ENTRY_SELECT_VALUES.CANADA_PROVINCES,
          },
        },
      },
      stateSelectMexico: {
        label: "State",
        type: TYPES.NESTED,
        dependsOn: "birthCountry",
        dependantOnValues: ["3: MX"],
        nested: {
          birthStateSelect: {
            type: TYPES.SELECT,
            values: GLOBAL_ENTRY_SELECT_VALUES.MEXICO_PROVINCES,
          },
        },
      },
      birthCity: {
        label: "City of birth",
        type: TYPES.TEXT,
      },
      phones: {
        label: "Phone numbers",
        type: TYPES.ARRAY,
        data: {
          phoneType: {
            label: "Phone type",
            type: TYPES.SELECT,
            values: GLOBAL_ENTRY_SELECT_VALUES.PHONE_TYPE,
            path: "phones.$phones",
          },
          countryCode: {
            label: "Country code",
            type: TYPES.SELECT,
            values: GLOBAL_ENTRY_SELECT_VALUES.PHONE_CODES,
            value: "key",
            text: "phone",
            path: "phones.$phones",
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "phones.$phones",
          },
          extension: {
            label: "Extension",
            type: TYPES.TEXT,
            path: "phones.$phones",
            dependsOn: "phones.$phones.phoneType",
            dependantOnValues: ["W"],
          },
        },
      },
      haveAppliedForGeNexusOrSentri: {
        label: "Have you ever applied for Global Entry?",
        type: TYPES.CHECKBOX,
      },
      haveAppliedForFast: {
        label: "Have you ever applied for FAST",
        type: TYPES.CHECKBOX,
      },
      programIdOption: {
        label: "Program ID",
        type: TYPES.SELECT,
        values: GLOBAL_ENTRY_SELECT_VALUES.PROGRAM_ID_OPTIONS,
        customDependency: (applicant) => {
          if (
            applicant.haveAppliedForGeNexusOrSentri ||
            applicant.haveAppliedForFast
          )
            return true;
        },
      },
      passId: {
        label: "Membership Number/PASSID",
        type: TYPES.TEXT,
        dependsOn: "programIdOption",
        dependantOnValues: ["0", 0],
      },
      fastId: {
        label: "Fast ID",
        type: TYPES.TEXT,
        dependsOn: "programIdOption",
        dependantOnValues: ["1", 1],
      },
    },
  },
  legalGuardianStep: {
    label: "Legal guardian information",
    type: TYPES.SEGMENT,
    customDependency: (applicant) => {
      const date = new Date(applicant.birthDate);
      const years = moment().diff(date, "years");
      return years < 18;
    },
    data: {
      lastName: {
        label: "Surname/last name",
        type: TYPES.TEXT,
        path: "legalGuardian",
      },
      firstName: {
        label: "Given/first name",
        type: TYPES.TEXT,
        path: "legalGuardian",
      },
      middleName: {
        label: "Middle name",
        type: TYPES.TEXT,
        path: "legalGuardian",
      },
      birthDate: {
        label: "Date of birth",
        type: TYPES.DATE,
        path: "legalGuardian",
      },
      gender: {
        label: "Gender",
        type: TYPES.SELECT,
        values: GLOBAL_ENTRY_SELECT_VALUES.GENDERS,
        path: "legalGuardian",
      },
    },
  },
  preFormFillInfoSegment: {
    label: "Pre-form fill information",
    type: TYPES.SEGMENT,
    data: {
      citizenOfUs: {
        label: "Is citizen of the United States",
        type: TYPES.CHECKBOX,
      },
      citizenshipCountry: {
        label:
          "What country of citizenship will be using to enter the United States",
        type: TYPES.SELECT,
        values: GLOBAL_ENTRY_SELECT_VALUES.COUNTRY_OF_CITIZENSHIP,
        dependsOn: "citizenOfUs",
        dependantOnValues: [false],
      },
      residencyStatus: {
        label: "Residency status",
        type: TYPES.SELECT,
        values: GLOBAL_ENTRY_SELECT_VALUES.RESIDENCY_STATUSES,
        dependsOn: "citizenOfUs",
        dependantOnValues: [false],
      },
      accessCode: {
        label: "Access code",
        type: TYPES.TEXT,
        customDependency: (applicant, path, schemaData) => {
          const country = get(applicant, "citizenshipCountry");
          if (
            ["UNITED KINGDOM"].includes(
              schemaData.COUNTRY_OF_CITIZENSHIP[country],
            ) &&
            applicant.residencyStatus === "3"
          )
            return true;
        },
      },
      promotionalCode: {
        label: "Promotional code",
        type: TYPES.TEXT,
        customDependency: (applicant, path, schemaData) => {
          const country = get(applicant, "citizenshipCountry");
          if (
            ["ISRAEL", "JAPAN"].includes(
              schemaData.COUNTRY_OF_CITIZENSHIP[country],
            ) &&
            applicant.residencyStatus === "3"
          )
            return true;
        },
      },
      howDidYouHearAboutUs: {
        label: "How did you hear about us",
        type: TYPES.SELECT,
        values: GLOBAL_ENTRY_SELECT_VALUES.HEAR_ABOUT_US_OPTIONS,
      },
      additionalInfo: {
        label: "Additional Information",
        type: TYPES.NESTED,
        newTable: true,
        customDependency: (applicant, path, schemaData) => {
          const usCitizen = get(applicant, "usCitizen");
          const residencyStatus = get(applicant, "residencyStatus");
          const citizenshipCountry = get(
            applicant,
            "citizenshipCountry",
          );

          if (
            !usCitizen &&
            [3, "3"].includes(residencyStatus) &&
            [
              "ARGENTINA",
              "BRAZIL",
              "COLOMBIA",
              "MEXICO",
              "QATAR",
              "SINGAPORE",
              "TAIWAN",
            ].includes(
              schemaData.COUNTRY_OF_CITIZENSHIP[citizenshipCountry],
            )
          )
            return true;
        },
        nested: {
          argentina: {
            label:
              "Information Requested by the Government of ARGENTINA",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn: "citizenshipCountry",
            dependantOnValues: ["10: Object"],
            nested: {
              nationalIdNumber: {
                label: "National identification number",
                type: TYPES.TEXT,
                path: "additionalInfo.argentina",
              },
              motherLastName: {
                label: "Mother's surname/last name",
                type: TYPES.TEXT,
                path: "additionalInfo.argentina",
              },
              motherFirstName: {
                label: "Mother's given/first name",
                type: TYPES.TEXT,
                path: "additionalInfo.argentina",
              },
              fatherLastName: {
                label: "Father's surname/last name",
                type: TYPES.TEXT,
                path: "additionalInfo.argentina",
              },
              fatherFirstName: {
                label: "Father's given/fist name",
                type: TYPES.TEXT,
                path: "additionalInfo.argentina",
              },
            },
          },
          brazil: {
            label:
              "Information Requested by the Government of BRAZIL",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn:
              "citizenshipCountry",
            dependantOnValues: ["29: Object"],
            nested: {
              cpf: {
                label: "Cadastro de pessoas fisicalas (CPF)",
                type: TYPES.TEXT,
                path: "additionalInfo.brazil",
              },
            },
          },
          colombia: {
            label:
              "Information Requested by the Government of COLOMBIA",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn: "citizenshipCountry",
            dependantOnValues: ["43: Object"],
            nested: {
              nationalIdNumber: {
                label: "Colombia National Identification Number",
                type: TYPES.TEXT,
                path: "additionalInfo.colombia",
              },
              secondSurname: {
                label: "Second surname",
                type: TYPES.TEXT,
                path: "additionalInfo.colombia",
              },
            },
          },
          mexico: {
            label: "Information Requested by the Government of MEXICO",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn:
              "citizenshipCountry",
            dependantOnValues: ["2: Object"],
            nested: {
              rfc: {
                label: "RFC (Registro Federal de Contribuyentes)",
                type: TYPES.TEXT,
                path: "additionalInfo.mexico",
              },
              isRfcOwnedByApplicant: {
                label: "Is RFC owned by the applicant",
                type: TYPES.CHECKBOX,
                path: "additionalInfo.mexico",
              },
              curp: {
                label: "CURP (Clave Unica de Registro de Poblacion)",
                type: TYPES.TEXT,
                path: "additionalInfo.mexico",
              },
              maternalName: {
                label: "Maternal name",
                type: TYPES.TEXT,
                path: "additionalInfo.mexico",
              },
            },
          },
          singapore: {
            label:
              "Information Requested by the Government of SINGAPORE",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn:
              "citizenshipCountry",
            dependantOnValues: ["169: Object"],
            nested: {
              singaporeNationalIdCard: {
                label:
                  "Singapore National Registration Identity Card (NRIC)",
                type: TYPES.TEXT,
                path: "additionalInfo.singapore",
              },
            },
          },
          taiwan: {
            label: "Information Requested by the Government of TAIWAN",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn:
              "citizenshipCountry",
            dependantOnValues: ["184: Object"],
            nested: {
              nationalIdCardNumber: {
                label: "Taiwan national identity card",
                type: TYPES.TEXT,
                path: "additionalInfo.taiwan",
              },
              policeCriminalRecordNumber: {
                label: "Police criminal record certificate number",
                type: TYPES.TEXT,
                path: "additionalInfo.taiwan",
              },
            },
          },
          qatar: {
            label: "Information Requested by the Government of QATAR",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn:
              "citizenshipCountry",
            dependantOnValues: ["154: Object"],
            nested: {
              nationalIdNumber: {
                label: "Qatar national id",
                type: TYPES.TEXT,
                path: "additionalInfo.qatar",
              },
            },
          },
        },
      },
    },
  },
  personalInfoSegment: {
    label: "Personal information",
    type: TYPES.SEGMENT,
    data: {
      gender: {
        label: "Gender",
        type: TYPES.SELECT,
        values: GLOBAL_ENTRY_SELECT_VALUES.GENDERS,
      },
      eyeColor: {
        label: "Eye color",
        type: TYPES.SELECT,
        values: GLOBAL_ENTRY_SELECT_VALUES.EYE_COLOR,
      },
      height: {
        label: "Height",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          standard: {
            label: "Height standard",
            type: TYPES.SELECT,
            values: GLOBAL_ENTRY_SELECT_VALUES.HEIGHT_STANDARDS,
            path: "height",
          },
          feets: {
            label: "Feets",
            type: TYPES.TEXT,
            dependsOn: "height.standard",
            path: "height",
            dependantOnValues: ["ENGLISH"],
          },
          inches: {
            label: "Inches",
            type: TYPES.TEXT,
            dependsOn: "height.standard",
            path: "height",
            dependantOnValues: ["ENGLISH"],
          },
          centimeters: {
            label: "Centimeters",
            type: TYPES.TEXT,
            dependsOn: "height.standard",
            path: "height",
            dependantOnValues: ["METRIC"],
          },
        },
      },
      hasUsedOtherNames: {
        label: "Has used any other names before",
        type: TYPES.CHECKBOX,
      },
      otherNames: {
        label: "Other names",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "hasUsedOtherNames",
        nested: {
          firstName: {
            label: "Other given/first name(s) used",
            type: TYPES.MULTI_TEXT,
            path: "otherNames",
          },
          lastName: {
            label: "Other surname/last name(s) used",
            type: TYPES.MULTI_TEXT,
            path: "otherNames",
          },
        },
      },
    },
  },
  documentsInfoSegment: {
    label: "Documents information",
    type: TYPES.SEGMENT,
    data: {
      citizenships: {
        label: "Citizenship",
        type: TYPES.ARRAY,
        data: {
          country: {
            label: "Citizenship",
            type: TYPES.SELECT,
            values: GLOBAL_ENTRY_SELECT_VALUES.ADDITIONAL_CITIZENSHIP_COUNTRIES,
            path: "citizenships.$citizenships",
          },
          documents: {
            label: "Documents",
            type: TYPES.ARRAY,
            path: "citizenships.$citizenships",
            data: {
              documentType: {
                label: "Document type",
                type: TYPES.SELECT,
                values: GLOBAL_ENTRY_SELECT_VALUES.CITIZENSHIP_DOCUMENT_TYPE,
                path: "citizenships.$citizenships.documents.$documents",
              },
              lastName: {
                label: "Surname/last name",
                type: TYPES.TEXT,
                path: "citizenships.$citizenships.documents.$documents",
              },
              firstName: {
                label: "Given name",
                type: TYPES.TEXT,
                path: "citizenships.$citizenships.documents.$documents",
              },
              middleName: {
                label: "Middle name",
                type: TYPES.TEXT,
                path: "citizenships.$citizenships.documents.$documents",
              },
              birthDate: {
                label: "Date of birth",
                type: TYPES.DATE,
                path: "citizenships.$citizenships.documents.$documents",
              },
              issuanceCountry: {
                label: "Issuance country",
                type: TYPES.SELECT,
                values: GLOBAL_ENTRY_SELECT_VALUES.COUNTRIES_1,
                path: "citizenships.$citizenships.documents.$documents",
              },
              passportNumber: {
                label: "Passport number",
                type: TYPES.TEXT,
                path: "citizenships.$citizenships.documents.$documents",
                dependsOn:
                  "citizenships.$citizenships.documents.$documents.documentType",
                dependantOnValues: ["PT"],
              },
              naturalizationCertificateNumber: {
                label: "Naturalization certificate number",
                type: TYPES.TEXT,
                path: "citizenships.$citizenships.documents.$documents",
                dependsOn:
                  "citizenships.$citizenships.documents.$documents.documentType",
                dependantOnValues: ["NC"],
              },
              aNumber: {
                label: "A - Number",
                type: TYPES.TEXT,
                path: "citizenships.$citizenships.documents.$documents",
                dependsOn:
                  "citizenships.$citizenships.documents.$documents.documentType",
                dependantOnValues: ["NC"],
              },
              birthCertificateNumber: {
                label: "Birth certificate number",
                type: TYPES.TEXT,
                path: "citizenships.$citizenships.documents.$documents",
                dependsOn:
                  "citizenships.$citizenships.documents.$documents.documentType",
                dependantOnValues: ["BB"],
              },
              citizenshipCertificateNumber: {
                label: "Citizenship Certificate Number",
                type: TYPES.TEXT,
                path: "citizenships.$citizenships.documents.$documents",
                dependsOn:
                  "citizenships.$citizenships.documents.$documents.documentType",
                dependantOnValues: ["CSC"],
              },
              registryGroup: {
                label: "Registry group",
                type: TYPES.TEXT,
                path: "citizenships.$citizenships.documents.$documents",
                dependsOn:
                  "citizenships.$citizenships.documents.$documents.documentType",
                dependantOnValues: ["CI"],
              },
              registryNumber: {
                label: "Registry number",
                type: TYPES.TEXT,
                path: "citizenships.$citizenships.documents.$documents",
                dependsOn:
                  "citizenships.$citizenships.documents.$documents.documentType",
                dependantOnValues: ["CI"],
              },
              issuingState: {
                label: "State/province of issuance",
                type: TYPES.TEXT,
                customDependency: (applicant, path, schemaData) => {
                  const country = get(applicant, `${path}.issuanceCountry`);
                  const documentType = get(applicant, `${path}.documentType`);

                  if (
                    documentType === "BB" &&
                    !["UNITED STATES", "CANADA", "MEXICO"].includes(
                      schemaData.COUNTRIES_1[country],
                    )
                  )
                    return true;
                },
                path: "citizenships.$citizenships.documents.$documents",
              },
              stateSelectUsa: {
                label: "State",
                type: TYPES.NESTED,
                dependsOn: [
                  "citizenships.$citizenships.documents.$documents.documentType",
                  "citizenships.$citizenships.documents.$documents.issuanceCountry",
                ],
                dependantOnValues: ["BB", "1: US"],
                nested: {
                  issuingStateSelect: {
                    type: TYPES.SELECT,
                    values: GLOBAL_ENTRY_SELECT_VALUES.USA_PROVINCES,
                    path: "citizenships.$citizenships.documents.$documents",
                  },
                },
              },
              stateSelectCanada: {
                label: "State",
                type: TYPES.NESTED,
                dependsOn: [
                  "citizenships.$citizenships.documents.$documents.documentType",
                  "citizenships.$citizenships.documents.$documents.issuanceCountry",
                ],
                dependantOnValues: ["BB", "2: CA"],
                nested: {
                  issuingStateSelect: {
                    type: TYPES.SELECT,
                    values: GLOBAL_ENTRY_SELECT_VALUES.CANADA_PROVINCES,
                    path: "citizenships.$citizenships.documents.$documents",
                  },
                },
              },
              stateSelectMexico: {
                label: "State",
                type: TYPES.NESTED,
                dependsOn: [
                  "citizenships.$citizenships.documents.$documents.documentType",
                  "citizenships.$citizenships.documents.$documents.issuanceCountry",
                ],
                dependantOnValues: ["BB", "3: MX"],
                nested: {
                  issuingStateSelect: {
                    type: TYPES.SELECT,
                    values: GLOBAL_ENTRY_SELECT_VALUES.MEXICO_PROVINCES,
                    path: "citizenships.$citizenships.documents.$documents",
                  },
                },
              },
              hasIssuanceDate: {
                label: "Has issuance date",
                type: TYPES.CHECKBOX,
                path: "citizenships.$citizenships.documents.$documents",
                dependsOn:
                  "citizenships.$citizenships.documents.$documents.documentType",
                dependantOnValues: ["PT"],
              },
              issuanceDate: {
                label: "Date of issue",
                type: TYPES.DATE,
                customDependency: (applicant, path) => {
                  const hasIssuanceDate = get(
                    applicant,
                    `${path}.hasIssuanceDate`,
                  );
                  const documentType = get(applicant, `${path}.documentType`);

                  if (
                    documentType === "NC" ||
                    (documentType === "PT" && hasIssuanceDate)
                  )
                    return true;
                },
                path: "citizenships.$citizenships.documents.$documents",
              },
              expiryDate: {
                label: "Date of expiry (expiration date)",
                type: TYPES.DATE,
                path: "citizenships.$citizenships.documents.$documents",
                dependsOn:
                  "citizenships.$citizenships.documents.$documents.documentType",
                dependantOnValues: ["PT", "CI"],
              },
            },
          },
        },
      },
      proofOfPermanentResidentStatus: {
        label: "Proof of permanent status",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "residencyStatus",
        dependantOnValues: "0",
        nested: {
          residentCardHaveMrz: {
            label: "Has MRZ",
            type: TYPES.CHECKBOX,
            path: "proofOfPermanentResidentStatus",
          },
          firstName: {
            label: "First name",
            type: TYPES.TEXT,
            dependsOn: "proofOfPermanentResidentStatus.residentCardHaveMrz",
            path: "proofOfPermanentResidentStatus",
          },
          lastName: {
            label: "Last name",
            type: TYPES.TEXT,
            dependsOn: "proofOfPermanentResidentStatus.residentCardHaveMrz",
            path: "proofOfPermanentResidentStatus",
          },
          middleName: {
            label: "Middle name",
            type: TYPES.TEXT,
            dependsOn: "proofOfPermanentResidentStatus.residentCardHaveMrz",
            path: "proofOfPermanentResidentStatus",
          },
          birthDate: {
            label: "Date of birth",
            type: TYPES.DATE,
            dependsOn: "proofOfPermanentResidentStatus.residentCardHaveMrz",
            path: "proofOfPermanentResidentStatus",
          },
          aNumber: {
            label: "A - Number",
            type: TYPES.TEXT,
            dependsOn: "proofOfPermanentResidentStatus.residentCardHaveMrz",
            path: "proofOfPermanentResidentStatus",
          },
          expiryDate: {
            label: "Date of expiry",
            type: TYPES.DATE,
            dependsOn: "proofOfPermanentResidentStatus.residentCardHaveMrz",
            path: "proofOfPermanentResidentStatus",
          },
        },
      },
      hasSameProofsToEnter : {
        label: "Proof you may enter the United States is same as above",
        type: TYPES.CHECKBOX
      },
      proofsToEnterUs: {
        label: "Proof you may enter the United States",
        type: TYPES.ARRAY,
        dependsOn: "hasSameProofsToEnter",
        dependantOnValues: [false],
        data: {
          documentType: {
            label: "Document type",
            type: TYPES.SELECT,
            values: GLOBAL_ENTRY_SELECT_VALUES.PROOF_TO_ENTER_US_DOCUMENT_TYPE,
            path: "proofsToEnterUs.$proofsToEnterUs",
          },
          firstName: {
            label: "First name",
            type: TYPES.TEXT,
            path: "proofsToEnterUs.$proofsToEnterUs",
          },
          lastName: {
            label: "Last name",
            type: TYPES.TEXT,
            path: "proofsToEnterUs.$proofsToEnterUs",
          },
          middleName: {
            label: "Middle name",
            type: TYPES.TEXT,
            path: "proofsToEnterUs.$proofsToEnterUs",
          },
          birthDate: {
            label: "Date of birth",
            type: TYPES.DATE,
            path: "proofsToEnterUs.$proofsToEnterUs",
          },
          issuanceCountry: {
            label: "Country of issuance",
            type: TYPES.SELECT,
            values: GLOBAL_ENTRY_SELECT_VALUES.COUNTRIES_1,
            path: "proofsToEnterUs.$proofsToEnterUs",
          },
          passportNumber: {
            label: "Passport number",
            type: TYPES.TEXT,
            path: "proofsToEnterUs.$proofsToEnterUs",
            dependsOn: "proofsToEnterUs.$proofsToEnterUs.documentType",
            dependantOnValues: ["PT"],
          },
          visaNumber: {
            label: "Visa number",
            type: TYPES.TEXT,
            path: "proofsToEnterUs.$proofsToEnterUs",
            dependsOn: "proofsToEnterUs.$proofsToEnterUs.documentType",
            dependantOnValues: ["VS"],
          },
          visaClass: {
            label: "Visa class",
            type: TYPES.SELECT,
            values: GLOBAL_ENTRY_SELECT_VALUES.VISA_CLASS,
            path: "proofsToEnterUs.$proofsToEnterUs",
            dependsOn: "proofsToEnterUs.$proofsToEnterUs.documentType",
            dependantOnValues: ["VS"],
          },
          permanentResidentCardNumber: {
            label: "Permanent resident card number",
            type: TYPES.TEXT,
            path: "proofsToEnterUs.$proofsToEnterUs",
            dependsOn: "proofsToEnterUs.$proofsToEnterUs.documentType",
            dependantOnValues: ["PR"],
          },
          borderCrossingCardNumber: {
            label: "Border crossing card number",
            type: TYPES.TEXT,
            path: "proofsToEnterUs.$proofsToEnterUs",
            dependsOn: "proofsToEnterUs.$proofsToEnterUs.documentType",
            dependantOnValues: ["BCC"],
          },
          citizenshipCertificateNumber: {
            label: "Citizenship certificate number",
            type: TYPES.TEXT,
            path: "proofsToEnterUs.$proofsToEnterUs",
            dependsOn: "proofsToEnterUs.$proofsToEnterUs.documentType",
            dependantOnValues: ["CSC"],
          },
          hasIssuanceDate: {
            label: "Has issuance date",
            type: TYPES.CHECKBOX,
            path: "proofsToEnterUs.$proofsToEnterUs",
          },
          issuanceDate: {
            label: "Date of issue (issuance date)",
            type: TYPES.DATE,
            dependsOn: "proofsToEnterUs.$proofsToEnterUs.hasIssuanceDate",
            path: "proofsToEnterUs.$proofsToEnterUs",
          },
          expiryDate: {
            label: "Date of expiry",
            type: TYPES.DATE,
            path: "proofsToEnterUs.$proofsToEnterUs",
          },
        },
      },
    },
  },
  driversLicenseInfoSegment: {
    label: "Driver's license Information",
    type: TYPES.SEGMENT,
    data: {
      hasDriversLicense: {
        label: "Has a valid driver's license",
        type: TYPES.CHECKBOX,
      },
      skipForNowDriverLicense : {
        label: "Skip for now",
        type: TYPES.CHECKBOX,
        dependsOn: "hasDriversLicense",
        dependantOnValues: [true]
      },
      driversLicense: {
        label: "Driver's license",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn:[ "skipForNowDriverLicense", "hasDriversLicense"],
        dependantOnValues: [[false, undefined], [true]],
        nested: {
          number: {
            label: "Driver's license number",
            type: TYPES.TEXT,
            path: "driversLicense",
          },
          issuingCountry: {
            label: "Country of issuance",
            type: TYPES.SELECT,
            values: GLOBAL_ENTRY_SELECT_VALUES.COUNTRIES_2,
            path: "driversLicense",
          },
          issuingState: {
            label: "State/province of issuance",
            type: TYPES.TEXT,
            customDependency: (applicant, path, schemaData) => {
              const country = get(applicant, "driversLicense.issuingCountry");
              if (
                !["UNITED STATES", "CANADA", "MEXICO"].includes(
                  schemaData.COUNTRIES_2[country],
                )
              )
                return true;
            },
            path: "driversLicense",
          },
          stateSelectUsa: {
            label: "State",
            type: TYPES.NESTED,
            dependsOn: "driversLicense.issuingCountry",
            dependantOnValues: ["1: US"],
            nested: {
              issuingStateSelect: {
                type: TYPES.SELECT,
                values: GLOBAL_ENTRY_SELECT_VALUES.USA_PROVINCES,
                path: "driversLicense",
              },
            },
          },
          stateSelectCanada: {
            label: "State",
            type: TYPES.NESTED,
            dependsOn: "driversLicense.issuingCountry",
            dependantOnValues: ["2: CA"],
            nested: {
              issuingStateSelect: {
                type: TYPES.SELECT,
                values: GLOBAL_ENTRY_SELECT_VALUES.CANADA_PROVINCES,
                path: "driversLicense",
              },
            },
          },
          stateSelectMexico: {
            label: "State",
            type: TYPES.NESTED,
            dependsOn: "driversLicense.issuingCountry",
            dependantOnValues: ["3: MX"],
            nested: {
              issuingStateSelect: {
                type: TYPES.SELECT,
                values: GLOBAL_ENTRY_SELECT_VALUES.MEXICO_PROVINCES,
                path: "driversLicense",
              },
            },
          },
          hasExpiryDate: {
            label: "Has expiry date",
            type: TYPES.CHECKBOX,
            path: "driversLicense",
          },
          expiryDate: {
            label: "Driver's license expiration date",
            type: TYPES.DATE,
            dependsOn: "driversLicense.hasExpiryDate",
            path: "driversLicense",
          },
          additionalQuestions: {
            label: "Additional questions",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn: "driversLicense.issuingCountry",
            dependantOnValues: ["1: US", "2: CA", "3: MX"],
            path: "driversLicense",
            nested: {
              canada: {
                label: "Canada",
                type: TYPES.NESTED,
                newTable: true,
                dependsOn: "driversLicense.issuingCountry",
                dependantOnValues: ["2: CA"],
                path: "driversLicense.additionalQuestions",
                nested: {
                  enhancedDriversLicense: {
                    label: "Enhanced driver's license",
                    type: TYPES.CHECKBOX,
                    path: "driversLicense.additionalQuestions.canada",
                  },
                },
              },
              mexico: {
                label: "Mexico",
                type: TYPES.NESTED,
                newTable: true,
                dependsOn: "driversLicense.issuingCountry",
                dependantOnValues: ["3: MX"],
                path: "driversLicense.additionalQuestions",
                nested: {
                  commercialDriversLicense: {
                    label: "Commercial driver's licence",
                    type: TYPES.CHECKBOX,
                    path: "driversLicense.additionalQuestions.mexico",
                  },
                  hazmatEndorsement: {
                    label:
                      "Is there a HAZMAT (hazardous material) endorsement on the CDL?",
                    type: TYPES.CHECKBOX,
                    dependsOn:
                      "driversLicense.additionalQuestions.mexico.commercialDriversLicense",
                    path: "driversLicense.additionalQuestions.mexico",
                  },
                },
              },
              usa: {
                label: "USA",
                type: TYPES.NESTED,
                newTable: true,
                dependsOn: "driversLicense.issuingCountry",
                dependantOnValues: ["1: US"],
                path: "driversLicense.additionalQuestions",
                nested: {
                  enhancedDriversLicense: {
                    label: "Enhanced driver's license",
                    type: TYPES.CHECKBOX,
                    path: "driversLicense.additionalQuestions.usa",
                  },
                  commercialDriversLicense: {
                    label: "Comercial driver's license",
                    type: TYPES.CHECKBOX,
                    path: "driversLicense.additionalQuestions.usa",
                  },
                  hazmatEndorsement: {
                    label:
                      "Is there a HAZMAT (hazardous material) endorsement on the CDL",
                    type: TYPES.CHECKBOX,
                    dependsOn:
                      "driversLicense.additionalQuestions.usa.commercialDriversLicense",
                    path: "driversLicense.additionalQuestions.usa",
                  },
                },
              },
            },
          },
          firstName: {
            label: "Given/first name",
            type: TYPES.TEXT,
            path: "driversLicense",
          },
          lastName: {
            label: "Surname/last name",
            type: TYPES.TEXT,
            path: "driversLicense",
          },
          middleName: {
            label: "Middle name",
            type: TYPES.TEXT,
            path: "driversLicense",
          },
          birthDate: {
            label: "Date of birth",
            type: TYPES.DATE,
            path: "driversLicense",
          },
        },
      },
    },
  },
  vehicleInfoSegment: {
    label: "Vehicle Information",
    type: TYPES.SEGMENT,
    data: {
      determineVehicleRegistration: {
        label: "Vehicle(s)",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          driveFromMexicoToUs: {
            label:
              "Has plan to drive across the border of Mexico to the United States",
            type: TYPES.CHECKBOX,
            path: "determineVehicleRegistration",
          },
          vehicleAlreadyRegistered: {
            label: "Is vehicle already actively registered",
            type: TYPES.CHECKBOX,
            dependsOn: "determineVehicleRegistration.driveFromMexicoToUs",
            path: "determineVehicleRegistration",
          },
          wantToRegisterVehicleNow: {
            label: "Wants to register the vehicle now",
            type: TYPES.CHECKBOX,
            customDependency: (applicant) => {
              if (
                !applicant.determineVehicleRegistration
                  ?.vehicleAlreadyRegistered &&
                applicant.determineVehicleRegistration?.driveFromMexicoToUs
              )
                return true;
            },
            path: "determineVehicleRegistration",
          },
          skipForNowVehicle : {
            label: "Skip for now",
            type: TYPES.CHECKBOX,
            dependsOn: "determineVehicleRegistration.wantToRegisterVehicleNow",
            dependantOnValues: [true],
          },
          registerVehicles: {
            label: "Vehicle for registration",
            type: TYPES.ARRAY,
            path: "determineVehicleRegistration",
            dependsOn: "skipForNowVehicle",
            dependantOnValues: [false, undefined],
            data: {
              make: {
                label: "Make",
                type: TYPES.TEXT,
                path: "determineVehicleRegistration.registerVehicles.$registerVehicles",
              },
              model: {
                label: "Model",
                type: TYPES.TEXT,
                path: "determineVehicleRegistration.registerVehicles.$registerVehicles",
              },
              year: {
                label: "Year",
                type: TYPES.TEXT,
                path: "determineVehicleRegistration.registerVehicles.$registerVehicles",
              },
              color: {
                label: "Color",
                type: TYPES.TEXT,
                path: "determineVehicleRegistration.registerVehicles.$registerVehicles",
              },
              vin: {
                label: "VIN",
                type: TYPES.TEXT,
                path: "determineVehicleRegistration.registerVehicles.$registerVehicles",
              },
              licensePlateNumber: {
                label: "License plate number",
                type: TYPES.TEXT,
                path: "determineVehicleRegistration.registerVehicles.$registerVehicles",
              },
              licenseIssuingCountry: {
                label: "License country of issuance",
                type: TYPES.SELECT,
                values: GLOBAL_ENTRY_SELECT_VALUES.COUNTRIES_1,
                path: "determineVehicleRegistration.registerVehicles.$registerVehicles",
              },
              licenseIssuingState: {
                label: "License state/province of issuance",
                type: TYPES.TEXT,
                path: "determineVehicleRegistration.registerVehicles.$registerVehicles",
                customDependency: (applicant, path, schemaData) => {
                  const country = get(
                    applicant,
                    `${path}.licenseIssuingCountry`,
                  );
                  if (
                    !["UNITED STATES", "CANADA", "MEXICO"].includes(
                      schemaData.COUNTRIES_1[country],
                    )
                  )
                    return true;
                },
              },
              stateSelectUsa: {
                label: "License state/province of issuance",
                type: TYPES.NESTED,
                dependsOn:
                  "determineVehicleRegistration.registerVehicles.$registerVehicles.licenseIssuingCountry",
                dependantOnValues: ["1: US"],
                nested: {
                  licenseIssuingStateSelect: {
                    type: TYPES.SELECT,
                    values: GLOBAL_ENTRY_SELECT_VALUES.USA_PROVINCES,
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles",
                  },
                },
              },
              stateSelectCanada: {
                label: "License state/province of issuance",
                type: TYPES.NESTED,
                dependsOn:
                  "determineVehicleRegistration.registerVehicles.$registerVehicles.licenseIssuingCountry",
                dependantOnValues: ["2: CA"],
                nested: {
                  licenseIssuingStateSelect: {
                    type: TYPES.SELECT,
                    values: GLOBAL_ENTRY_SELECT_VALUES.CANADA_PROVINCES,
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles",
                  },
                },
              },
              stateSelectMexico: {
                label: "License state/province of issuance",
                type: TYPES.NESTED,
                dependsOn:
                  "determineVehicleRegistration.registerVehicles.$registerVehicles.licenseIssuingCountry",
                dependantOnValues: ["3: MX"],
                nested: {
                  licenseIssuingStateSelect: {
                    type: TYPES.SELECT,
                    values: GLOBAL_ENTRY_SELECT_VALUES.MEXICO_PROVINCES,
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles",
                  },
                },
              },
              usGovernmentIssued: {
                label: "Issued by U.S. Goverment",
                type: TYPES.CHECKBOX,
                path: "determineVehicleRegistration.registerVehicles.$registerVehicles",
              },
              vehicleOwner: {
                label: "Vehicle owner",
                type: TYPES.SELECT,
                values: GLOBAL_ENTRY_SELECT_VALUES.VEHICLE_OWNER,
                path: "determineVehicleRegistration.registerVehicles.$registerVehicles",
              },
              individualInfo: {
                label: "Vehicle owner",
                type: TYPES.NESTED,
                newTable: true,
                dependsOn:
                  "determineVehicleRegistration.registerVehicles.$registerVehicles.vehicleOwner",
                dependantOnValues: ["ANOTHER_INDIVIDUAL"],
                path: "determineVehicleRegistration.registerVehicles.$registerVehicles",
                nested: {
                  firstName: {
                    label: "First name",
                    type: TYPES.TEXT,
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles.individualInfo",
                  },
                  lastName: {
                    label: "Last name",
                    type: TYPES.TEXT,
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles.individualInfo",
                  },
                  gender: {
                    label: "Gender",
                    type: TYPES.SELECT,
                    values: GLOBAL_ENTRY_SELECT_VALUES.GENDERS,
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles.individualInfo",
                  },
                  birthDate: {
                    label: "Date of birth",
                    type: TYPES.DATE,
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles.individualInfo",
                  },
                  countryCode: {
                    label: "Country code",
                    type: TYPES.SELECT,
                    values: GLOBAL_ENTRY_SELECT_VALUES.PHONE_CODES,
                    value: "key",
                    text: "phone",
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles.individualInfo",
                  },
                  phoneNumber: {
                    label: "Phone number",
                    type: TYPES.TEXT,
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles.individualInfo",
                  },
                  extension: {
                    label: "Extension",
                    type: TYPES.TEXT,
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles.individualInfo",
                  }
                },
              },
              bussinessInfo: {
                label: "Vehicle owner",
                type: TYPES.NESTED,
                newTable: true,
                path: "determineVehicleRegistration.registerVehicles.$registerVehicles",
                dependsOn:
                  "determineVehicleRegistration.registerVehicles.$registerVehicles.vehicleOwner",
                dependantOnValues: ["BUSINESS"],
                nested: {
                  businessName: {
                    label: "Business name",
                    type: TYPES.TEXT,
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles.businessInfo",
                  },
                  country: {
                    label: "Country",
                    type: TYPES.SELECT,
                    values: GLOBAL_ENTRY_SELECT_VALUES.COUNTRIES_1,
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles.businessInfo",
                  },
                  address: {
                    label: "Address",
                    type: TYPES.NESTED,
                    newTable: true,
                    nested: AddressSchema(
                      "determineVehicleRegistration.registerVehicles.$registerVehicles.businessInfo",
                      GLOBAL_ENTRY_SELECT_VALUES.COUNTRIES_1,
                    ),
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles.businessInfo",
                  },
                  countryCode: {
                    label: "Country code",
                    type: TYPES.SELECT,
                    values: GLOBAL_ENTRY_SELECT_VALUES.PHONE_CODES,
                    value: "key",
                    text: "phone",
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles.businessInfo",
                  },
                  phoneNumber: {
                    label: "Phone number",
                    type: TYPES.TEXT,
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles.businessInfo",
                  },
                  extension: {
                    label: "Extension",
                    type: TYPES.TEXT,
                    path: "determineVehicleRegistration.registerVehicles.$registerVehicles.businessInfo",
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  addressInfoSegment: {
    label: "Address information",
    hint:"Please enter the addresses of where you have lived in the past 5 years from the present moment. One address must be your current address.",
    type: TYPES.SEGMENT,
    data: {
      currentAddress: {
        label: "Current address",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          livedHereSince: {
            label: "Have lived here since",
            type: TYPES.DATE,
            format: "MMM-YYYY",
            path: "currentAddress",
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            nested: AddressSchema(
              "currentAddress",
              GLOBAL_ENTRY_SELECT_VALUES.COUNTRIES_2,
            ),
            path: "currentAddress",
          },
        },
      },
      previousAddresses: {
        label: "Previous addresses",
        type: TYPES.ARRAY,
        data: {
          livedHereSince: {
            label: "Have lived here since",
            type: TYPES.DATE,
            format: "MMM-YYYY",
            path: "previousAddresses.$previousAddresses",
          },
          livedHereTo: {
            label: "Have lived here to",
            type: TYPES.DATE,
            format: "MMM-YYYY",
            path: "previousAddresses.$previousAddresses", 
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            nested: AddressSchema(
              "previousAddresses.$previousAddresses",
              GLOBAL_ENTRY_SELECT_VALUES.COUNTRIES_2,
            ),
          },
        },
      },
      hasSameMailingAddress: {
        label: "Has same mailing address as home address",
        type: TYPES.CHECKBOX,
      },
      mailingAddressInfo: {
        label: "Mailing address",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "hasSameMailingAddress",
        dependantOnValues: [false],
        nested: {
          receivedEmailHereSince: {
            label: "Received mail here since",
            type: TYPES.DATE,
            format: "MMM-YYYY",
            path: "mailingAddressInfo",
          },
          address: {
            label: "Mailing address",
            type: TYPES.NESTED,
            newTable: true,
            nested: AddressSchema(
              "mailingAddressInfo",
              GLOBAL_ENTRY_SELECT_VALUES.COUNTRIES_2,
            ),
          },
        },
      },
      contactInfo: {
        label: "U.S. Contact Information",
        type: TYPES.NESTED,
        customDependency: (applicant, path, schemaData) => {
          const currentCountry = get(applicant, "currentAddress.country");
          const residencyStatus = get(applicant, "residencyStatus");
          if (
            ["0", 0].includes(residencyStatus) &&
            !["UNITED STATES", "CANADA"].includes(
              schemaData.COUNTRIES_2[currentCountry],
            )
          )
            return true;
        },
        newTable: true,
        nested: {
          fullName: {
            label: "Contact's full name",
            type: TYPES.TEXT,
            path: "contactInfo",
          },
          country: {
            label: "Country",
            type: TYPES.SELECT,
            values: GLOBAL_ENTRY_SELECT_VALUES.COUNTRIES_2,
            path: "contactInfo",
          },
          addressLine1: {
            label: "Street address",
            type: TYPES.TEXT,
            path: "contactInfo",
          },
          addressLine2: {
            label: "Street address 2",
            type: TYPES.TEXT,
            path: "contactInfo",
          },
          city: {
            label: "City",
            type: TYPES.TEXT,
            path: "contactInfo",
          },
          state: {
            label: "State",
            type: TYPES.SELECT,
            values: GLOBAL_ENTRY_SELECT_VALUES.USA_PROVINCES,
            path: "contactInfo",
          },
          zipCode: {
            label: "Zip code",
            type: TYPES.TEXT,
            path: "contactInfo",
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "contactInfo",
          },
          extension: {
            label: "Extension",
            type: TYPES.TEXT,
            path: "contactInfo",
          },
        },
      },
    },
  },
  employmentInfoSegment: {
    label: "Employment information",
    type: TYPES.SEGMENT,
    data: {
      skipForNowEmployments: {
        label: "Skip for now",
        type: TYPES.CHECKBOX,
      },
      employments: {
        label: "Employment info",
        type: TYPES.ARRAY,
        dependsOn: "skipForNowEmployments",
        dependantOnValues: [false, undefined],
        data: {
          currentEmploymentStatus: {
            label: "This is current employment status",
            type: TYPES.CHECKBOX,
            path: "employments.$employments",
          },
          status: {
            label: "Employment status",
            type: TYPES.SELECT,
            values: GLOBAL_ENTRY_SELECT_VALUES.EMPLOYMENT_STATUSES,
            path: "employments.$employments",
          },
          whenDidStart: {
            label: "When did it start",
            type: TYPES.DATE,
            format: "MMM-YYYY",
            path: "employments.$employments",
          },
          whenDidEnd: {
            label: "When did it end",
            type: TYPES.DATE,
            format: "MMM-YYYY",
            path: "employments.$employments",
            dependsOn: "employments.$employments.currentEmploymentStatus",
            dependantOnValues: [false, undefined],
          },
          additionalInfo: {
            label: "Additional info",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn: "employments.$employments.status",
            dependantOnValues: ["E", "F"],
            nested: {
              occupation: {
                label: "Current occupation",
                type: TYPES.TEXT,
                path: "employments.$employments.additionalInfo",
              },
              employer: {
                label: "Employer's Information",
                type: TYPES.TEXT,
                path: "employments.$employments.additionalInfo",
              },
              countryCode: {
                label: "Country code",
                type: TYPES.SELECT,
                values: GLOBAL_ENTRY_SELECT_VALUES.PHONE_CODES,
                value: "key",
                text: "phone",
                path: "employments.$employments.additionalInfo",
              },
              phoneNumber: {
                label: "Phone number",
                type: TYPES.TEXT,
                path: "employments.$employments.additionalInfo",
              },
              extension: {
                label: "Extension",
                type: TYPES.TEXT,
                path: "employments.$employments.additionalInfo",
              },
              address: {
                label: "Address",
                type: TYPES.NESTED,
                newTable: true,
                nested: AddressSchema(
                  "employments.$employments.additionalInfo",
                  GLOBAL_ENTRY_SELECT_VALUES.COUNTRIES_2,
                ),
              },
            },
          },
        },
      },
    },
  },
  travelInfoSegment: {
    label: "Travel History",
    type: TYPES.SEGMENT,
    data: {
      haveTraveledToOtherCountries: {
        label:
          "Has traveled to countries other than US, Canada and Mexico since December 2018",
        type: TYPES.CHECKBOX,
      },
      otherCountriesList: {
        label: "Countries list",
        type: TYPES.MULTI_SELECT,
        values: GLOBAL_ENTRY_SELECT_VALUES.TRAVEL_HISTORY_COUNTRIES,
        dependsOn: "haveTraveledToOtherCountries",
        selectType: "number",
      },
    },
  },
  additionalInfoSegment: {
    label: "Additional information",
    type: TYPES.SEGMENT,
    data: {
      beenConvicted: {
        label:
          "Have you ever been convicted of a criminal offense in the United States or any other country?",
        type: TYPES.CHECKBOX,
      },
      convictedCountry: {
        label: "In which country",
        type: TYPES.SELECT,
        values: GLOBAL_ENTRY_SELECT_VALUES.COUNTRIES_1,
        dependsOn: "beenConvicted",
      },
      provideConvictedDetails: {
        label: "Explain",
        type: TYPES.TEXT,
        textArea: true,
        dependsOn: "beenConvicted",
      },
      describeArrestOrCharges: {
        label: "Has ever been arrested expain",
        type: TYPES.TEXT,
        textArea: true,
      },
      waiverOfInadmissibility: {
        label:
          "Has ever received a waiver of inadmissibility to the U.S. from a U.S. government agency",
        type: TYPES.CHECKBOX,
      },
      waiverDetails: {
        label: "Explain",
        type: TYPES.TEXT,
        textArea: true,
        dependsOn: "waiverOfInadmissibility",
      },
      violationOfCustomLaws: {
        label: "Has ever been found in violation of customs laws",
        type: TYPES.CHECKBOX,
      },
      customLawsDetails: {
        label: "Explain",
        type: TYPES.TEXT,
        textArea: true,
        dependsOn: "violationOfCustomLaws",
      },
      violationOfImmigrationLaws: {
        label: "Has ever been found in violation of immigration laws",
        type: TYPES.CHECKBOX,
      },
      immigrationLawsDetails: {
        label: "Explain",
        type: TYPES.TEXT,
        textArea: true,
        dependsOn: "violationOfImmigrationLaws",
      },
      flyingToUsWithin6Months: {
        label: "Do you plan on flying internationally into the U.S. within the next 6 months?",
        type: TYPES.CHECKBOX,
      },
      flyingToUsWithin6MonthsDetails: {
        label: "Explain",
        type: TYPES.TEXT,
        textArea: true,
        dependsOn: "flyingToUsWithin6Months",
      },
    },
  },
};
