import moment from "moment";
import toastr from "toastr";
import { DOCUMENT_TYPE } from "./Router/routers/DashboardRouter/routers/Products/EVisa/pages/ApplicantDocumentHandler/components/ApplicantDocumentHandlerView/ApplicantDocumentHandlerView.component";
import { API_NAMES } from "./Router/routers/DashboardRouter/routers/Products/EVisa/constants/apiNames";

export function sortedIndex(array, value) {
  let low = 0;
  let high = array.length;

  while (low < high) {
    const mid = (low + high) >>> 1;
    if (array[mid] < value) low = mid + 1;
    else high = mid;
  }
  return low;
}

export function startOfDate(date) {
  return moment(date).startOf("day").toDate();
}

export function getRandomString(length, allowedCharacters) {
  let result = "";
  let characters =
    allowedCharacters ||
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function copyToClipboard(containerid, message) {
  var range = document.createRange();
  range.selectNode(document.getElementById(containerid));
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);
  document.execCommand("copy");
  window.getSelection().removeAllRanges();
  if (message) {
    toastr.success(message, "Copied!", { timeOut: 500 });
  }
}

export function downloadCsv(csv, fileName) {
  const blob = new Blob(["\ufeff", csv]);
  const url = URL.createObjectURL(blob);

  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = `${fileName}.csv`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  URL.revokeObjectURL(url);
}

export function reduceFieldArrayToArrayOfObjects(formResults, fieldArrayName) {
  return Object.keys(formResults).reduce((acc, v) => {
    if (v.startsWith(fieldArrayName)) {
      const index = v[v.indexOf("[") + 1];
      return !!(acc || [])[+index]
        ? acc?.map((el, ind) =>
            ind === +index
              ? { ...el, [v.substring(v.indexOf(".") + 1)]: formResults[v] }
              : el,
          )
        : [
            ...(acc || []),
            { [v.substring(v.indexOf(".") + 1)]: formResults[v] },
          ];
    } else return [];
  });
}

export function capitalizeEachWord(stringValue, hasCapitalLetters) {
  if (typeof stringValue !== "string") return "";

  const processString = hasCapitalLetters
    ? stringValue
    : stringValue.toLowerCase();

  return processString
    .replace(/ +(?= )/g, "")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function byteArrayToBlob(byteArray, type) {
  const blob = new Blob([new Uint8Array(byteArray)], { type });

  return blob;
}

export function byteArrayToUrl(byteArray, type) {
  const url = URL.createObjectURL(byteArrayToBlob(byteArray, type));

  return url;
}

export const ALLOWED_UNICODE_CHARS = [
  32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
  51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69,
  70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88,
  89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106,
  107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121,
  122, 123, 124, 125, 126, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169,
  170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184,
  185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199,
  200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214,
  215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229,
  230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244,
  245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 338, 339, 352, 353,
  376, 381, 382, 402, 710, 732, 8211, 8212, 8216, 8217, 8218, 8220, 8221, 8222,
  8224, 8225, 8226, 8230, 8240, 8249, 8250, 8364, 8482,
];

export function isAllowedUnicodeChars(value) {
  if (!value || value.length === 0) {
    return true;
  }
  const allowedUnicodeSet = new Set(ALLOWED_UNICODE_CHARS);
  return value.split('').every((char) => allowedUnicodeSet.has(char.charCodeAt(0)));
} 

export const DOCUMENTS_OPTIONS = {
  [DOCUMENT_TYPE.client]: {
    [API_NAMES.ESTA]: [{ text: "Passport", value: "passport" }],
    [API_NAMES.IND]: [
      { text: "Passport", value: "passport" },
      { text: "Passport PDF", value: "passportPdf" },
      { text: "Portrait", value: "portrait" },
      {
        text: "Confirmation Letter G20",
        value: "confirmationLetterG20",
      },
      { text: "Hospital Letter", value: "letter" },
      {
        text: "Organizer invitation",
        value: "oragnizerInvitation",
      },
      {
        text: "Political clearance",
        value: "politicalClearance",
      },
      { text: "Business card", value: "businessCard" },
      { text: "Business invitation letter", value: "businessInvitationLetter" },
      { text: "Host institute invitation", value: "hostInstituteInvitation" },
      { text: "Sanction order", value: "sactionOrder" },
      { text: "Courses synopsis", value: "synopsis" },
      { text: "Sponsorship letter", value: "sponsorshipLetter" },
      { text: "Government approval", value: "governmentApproval" },
      { text: "Event clearance", value: "eventClearance" },
      {
        text: "Indian shipping letter",
        value: "indianShippingLetter",
      },
      {
        text: "Continous discharge certificate",
        value: "continuousDischargeCertificate",
      },
      {
        text: "Organization concerned letter",
        value: "letterFromOrganizationConcerned",
      },
      {
        text: "Institute, organization or hospital concerned letter",
        value: "letterFromInstituteOrganizationHospitalConcerned",
      },
      { text: "Sports invitation letter", value: "sportsInvitationLetter" },
      { text: "Clearance for holding", value: "clearanceForHolding" },
      { text: "Requisite clearance", value: "requisiteClearance" },
      { text: "Support tax compliance", value: "supportTaxCompliance" },
    ],
    [API_NAMES.DS160]: [{ text: "Portrait", value: "portrait" }],
    [API_NAMES.NZ]: [{ text: "Portrait", value: "portrait" }],
    [API_NAMES.VN]: [
      { text: "Passport", value: "passport" },
      { text: "Portrait", value: "portrait" },
      { text: "First child portrait", value: "firstChildPortrait" },
      { text: "Second child portrait", value: "secondChildPortrait" },
    ],
    [API_NAMES.ETA]: [],
    [API_NAMES.TRK]: [],
    [API_NAMES.AUS]: [{ text: "Passport", value: "passport" }],
    [API_NAMES.SCHENGEN]: [
      { text: "Passport", value: "passport" },
      { text: "Last visa", value: "lastVisa" },
      { text: "Residency permit", value: "residencyPermit" },
      { text: "Other", value: "other" },
    ],
    [API_NAMES.INV]: [],
    [API_NAMES.DIVERSITY]: [{ text: "Portrait", value: "portrait" }],
    [API_NAMES.GE]: [
      { text: "Birth Certificate", value: "birthCertificate" },
      {
        text: "Certificate of Indian status",
        value: "certificateOfIndianStatus",
      },
      {
        text: "Naturalization Certificate",
        value: "naturalizationCertificate",
      },
      { text: "Passport", value: "passport" },
      { text: "Citizenship Certificate", value: "citizenshipCertificate" },
      { text: "Visa", value: "visa" },
    ],
    [API_NAMES.SG]: [],
    [API_NAMES.DS11]: [
      {
        text: "Portrait Picture",
        value: "portraitPicture",
      },],
    [API_NAMES.DS82]: [
      {
        text: "Portrait Picture",
        value: "portraitPicture",
      },],
    [API_NAMES.DS64]: [],
    [API_NAMES.SL]: [],
    [API_NAMES.TSA]: [],
    [API_NAMES.SENTRI]: [],
    [API_NAMES.NEXUS]: [],
  },
  [DOCUMENT_TYPE.agent]: {
    [API_NAMES.ESTA]: [
      { text: "ESTA Confirmation page", value: "estaConfirmation" },
      { text: "Other", value: "other" },
    ],
    [API_NAMES.IND]: [{ text: "Other", value: "other" }],
    [API_NAMES.DS160]: [
      {
        text: "Appointment confirmation page",
        value: "appointmentConfirmationPage",
      },
      {
        text: "Confirmation and instructions page",
        value: "confirmationInstructionsPage",
      },
      { text: "Courier in receipt", value: "courierReceipt" },
      { text: "Visa fee payment receipt", value: "paymentReceipt" },
      { text: "DS160 Form", value: "dsForm" },
      {
        text: "Non-immigrant VISA Application Confirmation",
        value: "visaApplicationConfirmation",
      },
      { text: "Other", value: "other" },
    ],
    [API_NAMES.NZ]: [{ text: "Other", value: "other" }],
    [API_NAMES.SCHENGEN]: [
      { text: "Schengen Visa application form", value: "schengenAppForm" },
      {
        text: "Schengen Visa Application Form Draft",
        value: "schengenAppFormDraft",
      },
      { text: "Appointment Letter", value: "appointmentLetter" },
      {
        text: "Schengen Visa incomplete application form",
        value: "incompleteVisaApplicationForm",
      },
      { text: "Other", value: "other" },
    ],
    [API_NAMES.ETA]: [
      { text: "Canada eTA confirmation", value: "canadaeTAconfirmation" },
      { text: "Other", value: "other" },
    ],
    [API_NAMES.TRK]: [
      { text: "Turkey Confirmation page", value: "approvalDocTrk" },
      { text: "Other", value: "other" },
    ],
    [API_NAMES.AUS]: [
      {
        text: "Australia eVisitor Confirmation Page",
        value: "australiaConfirmationPage",
      },
      { text: "Other", value: "other" },
    ],
    [API_NAMES.VN]: [
      {
        text: "Vietnam eTA Confirmation Page",
        value: "vietnamConfirmationPage",
      },
      { text: "Other", value: "other" },
    ],
    [API_NAMES.INV]: [{ text: "Other", value: "other" }],
    [API_NAMES.DIVERSITY]: [
      {
        text: "Electronic Diversity Visa Program",
        value: "electronicDiversityVisaProgram",
      },
      { text: "Other", value: "other" },
    ],
    [API_NAMES.GE]: [
      {
        text: "Global Entry Application Draft",
        value: "globalEntryApplicationDraft",
      },
      { text: "Global Entry Backup Codes", value: "globalEntryBackupCodes" },
      {
        text: "Global Entry Approval Confirmation",
        value: "globalEntryApprovalConfirmation",
      },
      {
        text: "Global Entry Payment Receipt",
        value: "globalEntryPaymentReceipt",
      },
      {
        text: "Global Entry Conditional Approval Notification",
        value: "globalEntryConditionalApprovalNotification",
      },
      {
        text: "Global Entry Appointment Confirmation",
        value: "globalEntryAppointmentConfirmation",
      },
      {
        text: "Global Entry Refusal Notification",
        value: "globalEntryRefusalNotification",
      },
      { text: "Other", value: "other" },
    ],
    [API_NAMES.SG]: [{ text: "Other", value: "other" }],
    [API_NAMES.DS11]: [
      { text: "Other", value: "other" },
    ],
    [API_NAMES.DS82]: [
      { text: "Other", value: "other" },
    ],
    [API_NAMES.DS64]: [{ text: "Other", value: "other" }],
    [API_NAMES.SL]: [{ text: "Other", value: "other" }],
    [API_NAMES.TSA]: [{ text: "Other", value: "other" }],
    [API_NAMES.SENTRI]: [{ text: "Other", value: "other" }],
    [API_NAMES.NEXUS]: [{ text: "Other", value: "other" }],
  },
  [DOCUMENT_TYPE.financial]: Object.values(API_NAMES).reduce(
    (prev, cur) => ({
      ...prev,
      [cur]: [
        { text: "Invoice for our services", value: "invoice" },
        { text: "Proof of the Refund", value: "proofOfTheRefund" },
        { text: "Proof of the Cancelation", value: "proofOfTheCancelation" },
        {
          text: "Acquirer Reference Number (ARN)",
          value: "acquireReferenceNumber",
        },
        { text: "Bank Transfer Proof", value: "bankTransferProof" },
        { text: "Other", value: "other" },
      ],
    }),
    {},
  ),
};

export const checkFileType = (fileName, preview) => {
  const fileExtension = fileName.split(".").pop().toLowerCase();
  const imageExtensions = ["jpg", "jpeg"];
  const pdfExtensions = ["pdf"];
  if (imageExtensions.includes(fileExtension))
    return preview ? "image/jpeg" : "image";
  if (pdfExtensions.includes(fileExtension))
    return preview ? "application/pdf" : "pdf";
  return null;
};

export const getUpdatedApplicantProperties = (
  oldObject,
  newObject,
  path = "",
) => {
  let differences = [];
  const allKeys = new Set([
    ...Object.keys(oldObject || {}),
    ...Object.keys(newObject || {}),
  ]);

  allKeys.forEach((key) => {
    const currentPath = `${path}${path ? "." : ""}${
      Array.isArray(oldObject) ? `[${key}]` : key
    }`;
    const oldValue = oldObject ? oldObject[key] : undefined;
    const newValue = newObject ? newObject[key] : undefined;

    if (typeof oldValue === "object" || typeof newValue === "object") {
      differences = [
        ...differences,
        ...getUpdatedApplicantProperties(
          oldValue || {},
          newValue || {},
          currentPath,
        ),
      ];
    } else if (oldValue !== newValue) {
      differences.push({ propertyName: currentPath, oldValue, newValue });
    }
  });

  return differences;
};

export const isPassportApplication = (productApi) => {
  return [API_NAMES.DS11, API_NAMES.DS64, API_NAMES.DS82].includes(productApi);
};

export default {
  sortedIndex,
  startOfDate,
  getRandomString,
  copyToClipboard,
  downloadCsv,
};
