import { useCallback, useContext } from "react";
import { UsersContext } from "../common/UsersContext";

export function useSpecificFilter({
  selectedAgents,
  selectedProducts,
  selectedAgentCategories,
  selectedGeneralCategories,
  selectedGroups,
}) {
  const { getUsersFiltered } = useContext(UsersContext);

  const onChangeSpecificFilter = useCallback(
    (value, type, categoryName) => {
      switch (type) {
        case "products":
          const newSelectedProducts = { ...selectedProducts };
          if (selectedProducts[value]) delete newSelectedProducts[value];
          else newSelectedProducts[value] = value;

          const hasSelectedProducts =
            Object.keys(newSelectedProducts).length > 0;

          return {
            selectedProducts: newSelectedProducts,
            selectedAllProducts: hasSelectedProducts ? false : true,
          };

        case "select-all-products":
          const productsAllSelected = value.reduce((ret, filter) => {
            ret[filter.value] = filter.value;
            return ret;
          }, {});

          return {
            selectedProducts: productsAllSelected,
            selectedAllProducts: true,
          };

        case "deselect-all-products":
          return {
            selectedProducts: {},
            selectedAllProducts: false,
          };

        case "agent-categories":
          const newSelectedAgentCategories = { ...selectedAgentCategories };
          if (newSelectedAgentCategories[value])
            delete newSelectedAgentCategories[value];
          else newSelectedAgentCategories[value] = categoryName;

          const hasSelectedAgentCategories =
            Object.keys(newSelectedAgentCategories).length > 0;

          return {
            selectedAgentCategories: newSelectedAgentCategories,
            selectedAllAgentCategories: hasSelectedAgentCategories
              ? false
              : true,
          };

        case "select-all-agent-categories":
          const agentCategoriesAllSelected = value.reduce((ret, filter) => {
            ret[filter.value] = filter.label;
            return ret;
          }, {});

          return {
            selectedAgentCategories: agentCategoriesAllSelected,
            selectedAllAgentCategories: true,
          };

        case "deselect-all-agent-categories":
          return {
            selectedAgentCategories: {},
            selectedAllAgentCategories: false,
          };

        case "general-categories":
          const newSelectedGeneralCategories = { ...selectedGeneralCategories };
          if (newSelectedGeneralCategories[value])
            delete newSelectedGeneralCategories[value];
          else newSelectedGeneralCategories[value] = categoryName;

          const hasSelectedGeneralCategories =
            Object.keys(newSelectedGeneralCategories).length > 0;

          return {
            selectedGeneralCategories: newSelectedGeneralCategories,
            selectedAllGeneralCategories: hasSelectedGeneralCategories
              ? false
              : true,
          };

        case "select-all-general-categories":
          const generalAllSelected = value.reduce((ret, filter) => {
            ret[filter.value] = filter.label;
            return ret;
          }, {});

          return {
            selectedGeneralCategories: generalAllSelected,
            selectedAllGeneralCategories: true,
          };

        case "deselect-all-general-categories":
          return {
            selectedGeneralCategories: {},
            selectedAllGeneralCategories: false,
          };

        case "agents":
          const newSelectedAgents = { ...selectedAgents };
          if (newSelectedAgents[value]) delete newSelectedAgents[value];
          else newSelectedAgents[value] = { id: value, name: categoryName };

          const hasSelectedAgents = Object.keys(newSelectedAgents).length > 0;

          return {
            selectedAgents: newSelectedAgents,
            selectedAllAgents: hasSelectedAgents ? false : true,
          };

        case "select-all-agents":
          return {
            selectedAgents: {},
            selectedAllAgents: true,
          };

        case "groups":
          let newSelectedGroups = [...selectedGroups];

          if (newSelectedGroups.includes(value))
            newSelectedGroups = newSelectedGroups.filter(
              (group) => group !== value,
            );
          else newSelectedGroups.push(value);

          let newSelectedAgentsByGroup = {};

          if (newSelectedGroups.length > 0)
            newSelectedAgentsByGroup = getUsersFiltered({
              roles: newSelectedGroups,
            }).reduce((ret, { id, fullName }) => {
              ret[id] = { id, name: fullName };

              return ret;
            }, {});

          return {
            selectedGroups: newSelectedGroups,
            selectedAgents: newSelectedAgentsByGroup,
            selectedAllAgents: newSelectedGroups.length > 0 ? false : true,
          };

        default:
          break;
      }
    },
    [
      selectedAgentCategories,
      selectedAgents,
      selectedGeneralCategories,
      selectedProducts,
      selectedGroups,
      getUsersFiltered,
    ],
  );

  return {
    onChangeSpecificFilter,
  };
}
