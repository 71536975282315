import React, { useCallback, useState, useMemo, cloneElement } from "react";
import ReactDOM from "react-dom";
import { Button, Segment, Table } from "semantic-ui-react";
import "./ModalTable.styles.scss";
import { useSelector } from "react-redux";

const ModalTable = ({
  trigger,
  onOpen,
  onClose,
  parent,
  className,
  columns,
  data,
  headerText,
}) => {
  const [open, setOpen] = useState(false);

  const { currentUser } = useSelector((state) => ({
    currentUser: state.currentUser,
  }));

  const openModal = useCallback(() => {
    setOpen(true);
    if (onOpen) {
      onOpen();
    }
  }, [onOpen]);

  const closeModal = useCallback(() => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  trigger = useMemo(
    () =>
      trigger &&
      cloneElement(trigger, {
        onClick: () => {
          openModal();
        },
      }),
    [openModal, trigger],
  );

  parent = useMemo(() => document.querySelector(parent), [parent]);

  return (
    <>
      {trigger}
      {ReactDOM.createPortal(
        <>
          {open && (
            <div
              onClick={() => closeModal()}
              className={`modal-table ${className}`}
            >
              <div
                onClick={(e) => e.stopPropagation()}
                className="modal-table__body"
              >
                <Segment.Group>
                  <h2>{headerText}</h2>
                  <Table celled striped fixed selectable className="user-table">
                    <Table.Header>
                      <Table.Row>
                        {columns.map((column) => (
                          <Table.HeaderCell
                            key={column.prop}
                            width={column.width ? column.width : 1}
                          >
                            {column.label}
                          </Table.HeaderCell>
                        ))}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data &&
                        data.map((row) => (
                          <Table.Row key={row._id}>
                            {columns.map((column) => (
                              <Table.Cell key={column.prop}>
                                <span
                                  className={
                                    row[column.prop]?.length > 50
                                      ? "overflow"
                                      : ""
                                  }
                                >
                                  <span>
                                    {column.getValue
                                      ? column.getValue(row[column.prop])
                                      : `${row[column.prop]}`}
                                  </span>
                                </span>
                              </Table.Cell>
                            ))}
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                  <Button
                    onClick={() => closeModal()}
                    basic
                    inverted={currentUser.settings.activeTheme === "dark"}
                    type="button"
                    className="right floated"
                  >
                    Close
                  </Button>
                </Segment.Group>
              </div>
            </div>
          )}
        </>,
        parent,
      )}
    </>
  );
};

ModalTable.defaultProps = {
  parent: "body",
};

export default ModalTable;
