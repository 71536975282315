import React, { Component } from "react";
import { render } from "react-dom";
import { Confirm, Button } from "semantic-ui-react";
import "./ConfirmPopup.styles.scss";

let resolve;
class ConfirmPopup extends Component {
  static create() {
    const containerElement = document.createElement("div");
    document.body.appendChild(containerElement);
    return render(<ConfirmPopup />, containerElement);
  }

  constructor() {
    super();

    this.state = {
      open: false,
      title: "Confirm Popup",
      message: "Are you sure?",
      className: "confirm-popup",
      negative: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.show = this.show.bind(this);
  }

  handleCancel() {
    this.setState({ ...this.state, open: false });
    resolve(false);
  }

  handleConfirm() {
    this.setState({ ...this.state, open: false });
    resolve(true);
  }

  show(props = {}) {
    this.setState({ ...this.state, ...props, open: true });
    return new Promise((res) => {
      resolve = res;
    });
  }

  render() {
    const { open, title, message, cancelButton, className } = this.state;
    return (
      <Confirm
        open={open}
        header={title}
        content={message}
        onConfirm={this.handleConfirm}
        onCancel={this.handleCancel}
        cancelButton={cancelButton ? <Button basic content="Cancel" /> : <></>}
        className={`popup-basic-class ${className}`}
        confirmButton={
          <Button
            className={className}
            content={`${className === "delete-popup" ? "Delete" : "Confirm"}`}
          />
        }
      />
    );
  }
}

export default ConfirmPopup;
