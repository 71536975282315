import { getAllShiftDependOfUTC } from "./getAllShiftDependOfUTC";

/**
 *
 * Make shift report content (description)
 *
 * @typedef {{segmentGroup: { firstSegment: string, lastSegment: string }}} segmentGroup
 * @typedef {{segmentGroup1: segmentGroup, segmentGroup2: segmentGroup, segmentGroup3: segmentGroup }} shiftGroup
 *
 * @param {Array<string>} names
 * @param {Array<shiftGroup>} allShifts
 * @returns {string}
 */
export const makeShiftHoursByNames = (names, allShifts) => {
  let res = "";

  // check if there are any shifts during these hours
  //
  if (names.length === 1 && names[0] === "empty") {
    res = "There is no shift for these hours";
  } else {
    res = "Shifts: ";
    names.forEach((name) => {
      // make shift report content (description)
      //
      getAllShiftDependOfUTC(allShifts).forEach((shift) => {
        if (shift[name]) {
          const { firstSegment, lastSegment } = shift[name];
          res = res.concat(firstSegment + " - " + lastSegment + ", ");
        }
      });
    });
    // remove extra ', '
    //
    res = res.substring(0, res.length - 2);
  }

  return res;
};
