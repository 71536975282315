import React from "react";
import FormFields from "../../../../../../../../../../../components/common/FormFields";
import utils from "../../../../../../../../../../../utils";

const fields = ["name", "companyName", "email", "trackingId"];

const PartnerForm = ({ partnerFromParent }) => {
  return <FormFields currentItem={partnerFromParent} fields={fields} />;
};

PartnerForm.defaultProps = {
  partnerFromParent: {
    name: "",
    companyName: "",
    email: "",
    trackingId: utils.getRandomString(8),
  },
};

export default PartnerForm;
