const VWP_COUNTRIES = [
  "ANDO",
  "ASTL",
  "AUST",
  "BELG",
  "BRNI",
  "CHIL",
  "CZEC",
  "DEN",
  "EST",
  "FIN",
  "FRAN",
  "GER",
  "GRC",
  "HUNG",
  "ICLD",
  "IRE",
  "ITLY",
  "JPN",
  "KOR",
  "LATV",
  "LCHT",
  "LITH",
  "LXM",
  "MLTA",
  "MON",
  "NETH",
  "NZLD",
  "NORW",
  "POL",
  "PORT",
  "SMAR",
  "SING",
  "SVK",
  "SVN",
  "SPN",
  "SWDN",
  "SWTZ",
  "TWAN",
  "GRBR",
];

export default VWP_COUNTRIES;
