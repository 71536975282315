import moment from "moment";

export const initialAvailability = {
  "5d8fc421a9f7047b4aa81fe5": [
    {
      _id: "5e39430203ae604a10f45779",
      name: "available",
      type: "availability",
      user: "5d8fc421a9f7047b4aa81fe5",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-01 05:10:10",
      __v: 0,
    },
    {
      _id: "5e39430e03ae604a10f4577b",
      name: "unavailable",
      type: "availability",
      user: "5d8fc421a9f7047b4aa81fe5",
      data: {
        availabilityStatus: "Unavailable",
      },
      createdAt: "2020-08-04 05:10:22",
      __v: 0,
    },
    {
      _id: "5e39430e03ae604a10f4577b",
      name: "offline",
      type: "availability",
      user: "5d8fc421a9f7047b4aa81fe5",
      data: {
        availabilityStatus: "Unavailable",
      },
      createdAt: "2020-08-07 05:10:22",
      __v: 0,
    },
    {
      _id: "5e39430e03ae604a10f4577b",
      name: "available",
      type: "availability",
      user: "5d8fc421a9f7047b4aa81fe5",
      data: {
        availabilityStatus: "Unavailable",
      },
      createdAt: "2020-08-11 05:10:22",
      __v: 0,
    },
    {
      _id: "5e3975a05446aa6cb9cc11e7",
      name: "offline",
      type: "availability",
      user: "5d5493b4e54fdd4b318dbdd8",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-12 08:46:08",
      __v: 0,
    },
    {
      _id: "5e39430e03ae604a10f4577b",
      name: "unavailable",
      type: "availability",
      user: "5d8fc421a9f7047b4aa81fe5",
      data: {
        availabilityStatus: "Unavailable",
      },
      createdAt: "2020-08-14 05:10:22",
      __v: 0,
    },
    {
      _id: "5e3945af079d514d1c8e31d1",
      name: "available",
      type: "availability",
      user: "5d8fc421a9f7047b4aa81fe5",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-17 05:21:35",
      __v: 0,
    },
    {
      _id: "5e3945af079d514d1c8e31d1",
      name: "offline",
      type: "availability",
      user: "5d8fc421a9f7047b4aa81fe5",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-18 05:21:35",
      __v: 0,
    },
    {
      _id: "5e3945af079d514d1c8e31d1",
      name: "unavailable",
      type: "availability",
      user: "5d8fc421a9f7047b4aa81fe5",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-21 05:21:35",
      __v: 0,
    },
  ],
  "5e18aef3db4a416342832071": [
    {
      _id: "5e395f43c661c5562d9d3bd9",
      name: "unavailable",
      type: "availability",
      user: "5e18aef3db4a416342832071",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-02 07:10:43",
      __v: 0,
    },
    {
      _id: "5e395f43c661c5562d9d3bd9",
      name: "offline",
      type: "availability",
      user: "5e18aef3db4a416342832071",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-07 07:10:43",
      __v: 0,
    },
    {
      _id: "5e395f43c661c5562d9d3bd9",
      name: "unavailable",
      type: "availability",
      user: "5e18aef3db4a416342832071",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-10 07:10:43",
      __v: 0,
    },
    {
      _id: "5e395f43c661c5562d9d3bd9",
      name: "available",
      type: "availability",
      user: "5e18aef3db4a416342832071",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-13 07:10:43",
      __v: 0,
    },
    {
      _id: "5e395f43c661c5562d9d3bd9",
      name: "offline",
      type: "availability",
      user: "5e18aef3db4a416342832071",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-16 07:10:43",
      __v: 0,
    },
    {
      _id: "5e398573b85e4a77f65e8a9f",
      name: "unavailable",
      type: "availability",
      user: "5e18aef3db4a416342832071",
      data: {
        availabilityStatus: "Unavailable",
      },
      createdAt: "2020-08-19 09:53:39",
      __v: 0,
    },
    {
      _id: "5e3995acb85e4a77f65e8ab1",
      name: "offline",
      type: "availability",
      user: "5e18aef3db4a416342832071",
      data: {
        availabilityStatus: "Offline",
      },
      createdAt: "2020-08-23 11:02:52",
      __v: 0,
    },
    {
      _id: "5e3a68d667e3c01081c7e19b",
      name: "unavailable",
      type: "availability",
      user: "5e18aef3db4a416342832071",
      data: {
        availabilityStatus: "Unavailable",
      },
      createdAt: "2020-08-24 02:03:50",
      __v: 0,
    },
    {
      _id: "5e3a6cf467e3c01081c7e19d",
      name: "available",
      type: "availability",
      user: "5e18aef3db4a416342832071",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-25 02:21:24",
      __v: 0,
    },
  ],
  "5d5493b4e54fdd4b318dbdd8": [
    {
      _id: "5e39617ec661c5562d9d3bed",
      name: "available",
      type: "availability",
      user: "5d5493b4e54fdd4b318dbdd8",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-07-29 07:20:14",
      __v: 0,
    },
    {
      _id: "5e39617ec661c5562d9d3bed",
      name: "unavailable",
      type: "availability",
      user: "5d5493b4e54fdd4b318dbdd8",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-02 07:20:14",
      __v: 0,
    },
    {
      _id: "5e39617ec661c5562d9d3bed",
      name: "offline",
      type: "availability",
      user: "5d5493b4e54fdd4b318dbdd8",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-05 07:20:14",
      __v: 0,
    },
    {
      _id: "5e39617ec661c5562d9d3bed",
      name: "available",
      type: "availability",
      user: "5d5493b4e54fdd4b318dbdd8",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-08 07:20:14",
      __v: 0,
    },
    {
      _id: "5e397440c661c5562d9d3c19",
      name: "unavailable",
      type: "availability",
      user: "5d5493b4e54fdd4b318dbdd8",
      data: {
        availabilityStatus: "Unavailable",
      },
      createdAt: "2020-08-11 08:40:16",
      __v: 0,
    },
    {
      _id: "5e3975a05446aa6cb9cc11e7",
      name: "available",
      type: "availability",
      user: "5d5493b4e54fdd4b318dbdd8",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-16 08:46:08",
      __v: 0,
    },
    {
      _id: "5e3975a05446aa6cb9cc11e7",
      name: "offline",
      type: "availability",
      user: "5d5493b4e54fdd4b318dbdd8",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-17 08:46:08",
      __v: 0,
    },
    {
      _id: "5e3975a05446aa6cb9cc11e7",
      name: "available",
      type: "availability",
      user: "5d5493b4e54fdd4b318dbdd8",
      data: {
        availabilityStatus: "Available",
      },
      createdAt: "2020-08-20 08:46:08",
      __v: 0,
    },
    {
      _id: "5e397a5158aa6a6e1c427231",
      name: "unavailable",
      type: "availability",
      user: "5d5493b4e54fdd4b318dbdd8",
      data: {
        availabilityStatus: "Unavailable",
      },
      createdAt: "2020-08-21 09:06:09",
      __v: 0,
    },
  ],
};

export const EVENT_NAMES = {
  AVAILABLE: "available",
  UNAVAILABLE: "unavailable",
  OFFLINE: "offline",
};

export const UNITS_OF_TIME = {
  HOUR: "hour",
  DAY: "day",
  MONTH: "month",
};

export const transformDifference = (diff) => {
  const timeDifferences = ["year", "month", "day", "hour", "minute"];
  return timeDifferences.reduce((acc, unit) => {
    acc[unit] = moment.duration(diff)[`${unit}s`]();
    return acc;
  }, {});
};
