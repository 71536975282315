import axios from "../httpClient";

function fetchProductConfig() {
  return axios.get("/esta/configuration");
}

function updateProductConfig(params) {
  return axios.patch("/esta/configuration", params);
}

export default {
  fetchProductConfig,
  updateProductConfig,
};
