import { cloneDeep, get } from "lodash";

/**
 *
 * @param {array} path
 * @param {string} propName
 * @param {any} propValue
 */
export function nestedUpdateObject(path, propName, propValue, stateData) {
  const initName = path.shift();
  const nestedObject = { [initName]: cloneDeep(stateData[initName]) };
  let updateValue = nestedObject[initName];
  if (!updateValue) {
    updateValue = nestedObject[initName] = {};
  }
  path.forEach((name) => {
    if (!updateValue[name]) {
      updateValue[name] = {};
    }
    updateValue = updateValue[name];
  });
  updateValue[propName] = propValue;
  return nestedObject;
}

/**
 *
 * @param {string|null} path
 * @param {string} propName
 * @param {any} data
 */
export function getNestedPathPropValue(path, propName, data) {
  let pathValue = data;
  if (path) {
    pathValue = get(data, path);
  }
  if (pathValue) {
    return pathValue[propName];
  }
  return null;
}
