import httpMainClient from "../httpClient";

function getUserLogin(loginBody) {
  return httpMainClient.post("auth/login", loginBody);
}

function getSimulatedUserLogin(userId) {
  return httpMainClient.post(`auth/login/${userId}`);
}

function forgotPassword(forgotPasswordBody) {
  return httpMainClient.post("auth/forgot-password", forgotPasswordBody);
}

function resetPassword(token, resetPasswordBody) {
  return httpMainClient.post(`auth/${token}/reset-password`, resetPasswordBody);
}

function getAuthUser() {
  return httpMainClient.get("auth/get-user");
}

function generateOTPSecret(userId) {
  return httpMainClient.post("auth/otp/generate", { userId });
}

function validateOTP(userId, otpToken) {
  return httpMainClient.post("auth/otp/validate", { userId, otpToken });
}

function refreshSession(refreshToken) {
  return httpMainClient.post("auth/refresh-token", { refreshToken })
}

function initiateSmsVerification(userId, phoneNumber) {
  return httpMainClient.post("auth/initiate-sms-verification", { userId }, {
    params: { phoneNumber },
  });
}

function checkSmsVerificationCode(userId, verificationCode) {
  return httpMainClient.post("auth/check-sms-verification-code", {
    userId,
    verificationCode,
  });
}

function fetchUser(userId) {
  return httpMainClient.get(`user-apikey-internal/${userId}`, {
    params: {
      ApiKey: "9c2a2ac5-c609-4bb6-a735-d45dda6ed868",
    },
  });
}

export default {
  getUserLogin,
  getSimulatedUserLogin,
  forgotPassword,
  resetPassword,
  getAuthUser,
  generateOTPSecret,
  validateOTP,
  refreshSession,
  initiateSmsVerification,
  checkSmsVerificationCode,
  fetchUser,
};
