import React from "react";
import { Table, Checkbox } from "semantic-ui-react";
import { ALLOWED_REFERRAL_SITES } from "../../../../../EVisa/pages/ApplicantsEdit/components/constants";

const SLReviewAndCertification = ({ referralSite = ALLOWED_REFERRAL_SITES.DEFAULT }) => {
  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell colSpan="2">
          <h4>Consent and Declaration</h4>
          <h5>Applicant's Declaration</h5>
          <div>
            <p>
              I declare that the information in this application is truthful,
              complete and accurate to best of my knowledge.
            </p>
          </div>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell colSpan="2">
          <h5>Terms & Conditions</h5>
          <p>
            I hereby certify that I have read and understood all the terms and
            conditions set forth in this application and all the information I
            have furnished in this form are true and accurate to the best of my
            knowledge and belief. I understand that any false or misleading
            statement may result in the permanent refusal of an ETA or denial of
            entry into Sri Lanka. I understand that possession of an ETA does
            not entitle me to enter Sri Lanka upon arrival at a port of entry if
            I am found inadmissible. Applicable in case a multiple-entry visa is
            applied for (cf. field no 24): I am aware of the need to have an
            adequate travel medical insurance for my first stay and any
            subsequent visits to the territory of Member States.
          </p>
          <p>1. All ETA holders should be able to prove:</p>
          <p>
            (i) A round trip ticket to show at the port of entry in Sri Lanka
            (only if you are traveling by air). (ii) Evidence of sufficient
            funds to cover your expenses in Sri Lanka.
          </p>
          <p>
            2. Employment: Holder of ETA, should not engage in any form of
            employment, paid or unpaid, or in any trade or business other than
            specified in the ETA, during the period of his/her stay in Sri Lanka
          </p>
          <p>
            3. Fees and Payments: You agree that your credit card(s) will be
            billed immediately after submission of your application.
            Requirements and Fees related to processing of your application are
            subject to change from time to time and you agree that the ETA
            processing fee once paid by you is not refundable and not
            transferable.
          </p>
          <p>
            4. Communication: By using this site, you do authorize the
            Department of Immigration & Emigration Sri Lanka to contact you via
            e-mail, messaging, or any other electronic or non-electronic means
            of communication.
          </p>
          <p>
            5. Accuracy: By submitting your application, you do certify that the
            information provided by you is true and correct.
          </p>
          <p>
            6. Limitations of use: You may not use this site for any purpose
            other than the intended purpose.
          </p>
          <p>
            7. (1) Application Submitting Terms You must enter the passport
            number correctly You must enter nationality correctly as appears in
            the travel document You must enter your date of birth correctly You
            must recheck / crosscheck above information to ascertain that they
            have been entered correctly. You are required to pay for the visa
            again on arrival in case of any inconstancy in above particulars.
          </p>
          <p>
            (2) You must make sure that the same travel document which was used
            for applying online visa is used for entering into Sri Lanka. In the
            event you produce a travel document other than the one used for
            applying online visa, you are required to pay again on arrival for
            the visa.
          </p>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell colSpan="2">
          <h5>Third Party Authorization</h5>
          <p>
            I understand that I am applying via GovAssist, LLC; a third-party
            that is not affiliated with the government, that the third-party
            cannot guarantee application approval, that the third-party service
            is offered to help expedite the process, and that I have the option
            to apply directly with the pertinent government websites. I also
            understand that my purchase consists of a processing and service fee
            to GovAssist, which includes the non-refundable government fee,
            which is required.
          </p>
          <p>
            I confirm and authorize GovAssist, LLC to assist me, by using their
            processing service in completing and reviewing my application. I
            understand and acknowledge that I have not been offered legal advice
            and that this company or its agents and affiliates have never
            claimed to be qualified to offer legal advice under any
            circumstance. I also confirm that I am submitting my application and
            signing it myself.
          </p>
          <p>
            By obtaining services from the GovAssist website, I have agreed to
            the following:{" "}
            <a href={`https://${referralSite}/terms-conditions`}>
              Terms & Conditions
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/refund-policy`}>
              Refund Policy{" "}
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/privacy-policy`}>
              Privacy Policy{" "}
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/legal-disclaimer`}>
              Disclaimer{" "}
            </a>
          </p>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>
    </React.Fragment>
  );
};

export default SLReviewAndCertification;
