import React, { useState, useEffect, useCallback } from "react";
import { Dropdown, Icon, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ListNotifications from "./components/ListNotifications";
import hashMapColorIcons from "./common/HashMapColorIcons";
import "./Notifications.styles.scss";
import apis from "../../../../apis";
import notificationClasses from "./common/classes";

const Notifications = () => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    async function getImportantNotifications() {
      try {
        const {
          data: { unreadCount, notifications },
        } = await apis.notifications.getImportantNotificationsApi();
        setUnreadCount(unreadCount);
        setNotifications(notifications);
      } catch (err) {
        console.error(err);
      }
    }
    getImportantNotifications();
  }, []);

  const readNotification = useCallback(async (notId) => {
    await apis.notifications.readNotification(notId);
    setNotifications((old) =>
      old.map((not) =>
        not._id === notId ? { ...not, readAt: new Date().toISOString() } : not,
      ),
    );
    setUnreadCount((old) => --old);
  }, []);

  return (
    <div className="notifications--dropdown">
      <Dropdown icon={<Icon name="bell" size="large" color="teal" />}>
        <Dropdown.Menu>
          <div className="notifications--dropdown__subheader">
            <div className="notifications--dropdown__title">Notifications</div>
            <div>
              <ListNotifications
                trigger={
                  <button className="notifications--dropdown__subheader__modal-trigger">
                    See last 100
                  </button>
                }
              />
              <Icon name="delete" />
            </div>
          </div>
          <Dropdown.Menu scrolling>
            <div>
              {notifications.length > 0 ? (
                notifications.map((notification) => {
                  return (
                    <Dropdown.Item
                      onClick={() => readNotification(notification._id)}
                      as={Link}
                      to={notification.linkTo}
                      key={notification._id}
                      className={notificationClasses(notification)}
                    >
                      <Icon
                        name={
                          hashMapColorIcons[notification.color] || "info circle"
                        }
                        color={notification.color || "grey"}
                      />
                      <div className="notification__text">
                        <span>{notification.text}</span>
                        <span className="notifications--dropdown__message__ticket">
                          TicketId: {notification._id}
                        </span>
                      </div>
                    </Dropdown.Item>
                  );
                })
              ) : (
                <div className="notifications--dropdown__empty-list">
                  No notifications for you
                </div>
              )}
            </div>
          </Dropdown.Menu>
        </Dropdown.Menu>
      </Dropdown>
      {unreadCount > 0 && (
        <Label circular color="red" size="mini">
          {unreadCount}
        </Label>
      )}
    </div>
  );
};

export default Notifications;
