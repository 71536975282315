import React, { Component } from "react";
import { connect } from "react-redux";
import { Subject, from } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
  filter,
} from "rxjs/operators";

import {
  Table,
  Button,
  Icon,
  Pagination,
  Segment,
  Input,
  Header,
  Message,
  Select,
} from "semantic-ui-react";
import NavigateButton from "../../../../../../../../../../components/NavigateButton";
import toastr from "toastr";
import ConfirmPopup from "../../../../../../../../../../components/ConfirmPopup";
import SmallConfirmPopup from "../../../../../../../../../../components/SmallConfirmPopup";
import { ROLES } from "../../../../../../../../../../constants/rolesConstants";
import {
  USPS_PAYMENT_STATUSES,
  USPS_APPLICANT_STATUSES,
} from "../../constants/applicant_statuses.js";
import { columns as USPS_TABLE_COLUMNS } from "../../../../../constants/tableData/usps";
import Spinner from "../../../../../../../../../../components/Spinner";
import {
  uspsApplicantsFetchApi,
  uspsChangeApplicantPaymentStatusApi,
  uspsRunBotApi,
  uspsDeleteApplicantApi,
  uspsChangeApplicantPhoneNumbers,
} from "../../../../../../../../../../apis/usps/uspsApplicantApi";
import { SOCKET_EVENTS } from "../../../../../../../../../../constants/socketEvents";
import socketService from "../../../../../../../../../../services/socketService";
import ModalButton from "../../../../../../../../../../components/ModalButton";
import PhoneBook from "../../../../../../../../../../components/PhoneBook";

class UspsApplicantsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applicants: null,
      searchString: "",
      sortCriteria: "",
      sortDirection: "",
      page: 1,
      paymentStatus: USPS_PAYMENT_STATUSES.PAID,
      totalPages: null,
    };
    this.subscriptions = [];
    this.searchField$ = new Subject();
  }

  componentDidMount() {
    this.setSearchBarListener();
    this.fetchApplicants();
    this.setNewApplicantListener();
    this.setPaymentStatusChangeListener();
    this.setStatusListener();
  }

  componentWillUnmount() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  setNewApplicantListener = () => {
    const applicantAddSubscription = socketService.socketSubject
      .pipe(filter((event) => event.type === SOCKET_EVENTS.usps.NEW_APPLICANT))
      .subscribe((event) => {
        const { paymentStatus } = this.state;
        const {
          data: { paymentStatus: newApplicantPaymentStatus },
          data: newApplicant,
        } = event;
        const { applicants } = this.state;
        if (
          newApplicantPaymentStatus === paymentStatus &&
          !applicants.some((applicant) => applicant._id === newApplicant._id)
        ) {
          toastr.info(
            `New '${newApplicantPaymentStatus}' applicant has been added', 'Ok`,
          );
          this.setState({ applicants: [newApplicant, ...applicants] });
        }
      });

    this.subscriptions.push(applicantAddSubscription);
  };

  setStatusListener = () => {
    const applicantStatusChangeSubscription = socketService.socketSubject
      .pipe(filter((event) => event.type === SOCKET_EVENTS.usps.STATUS_CHANGED))
      .subscribe((event) => {
        const { _id, status } = event.data;
        const { applicants } = this.state;
        this.setState({
          applicants: applicants.map((applicant) =>
            applicant._id === _id ? { ...applicant, status } : applicant,
          ),
        });
      });

    this.subscriptions.push(applicantStatusChangeSubscription);
  };

  setPaymentStatusChangeListener = () => {
    const applicantPaymentStatusChangeSubscription = socketService.socketSubject
      .pipe(
        filter(
          (event) => event.type === SOCKET_EVENTS.usps.PAYMENT_STATUS_CHANGED,
        ),
      )
      .subscribe((event) => {
        const {
          data: { _id, paymentStatus: newPaymentStatus },
        } = event;
        const { paymentStatus, applicants } = this.state;
        if (paymentStatus !== newPaymentStatus) {
          this.setState({
            applicants: applicants.filter((applicant) => applicant._id !== _id),
          });
        }
      });

    this.subscriptions.push(applicantPaymentStatusChangeSubscription);
  };

  async fetchApplicants() {
    const {
      page,
      searchString,
      sortCriteria,
      sortDirection,
      paymentStatus,
    } = this.state;
    const options = {
      page,
      sortCriteria,
      sortDirection,
      searchString,
      paymentStatus,
    };
    const {
      data: { applicants, currentPage, totalPages },
    } = await uspsApplicantsFetchApi(options);
    this.setState({ applicants, page: currentPage, totalPages });
  }
  async changePaymentStatus(applicantId, paymentStatus) {
    await uspsChangeApplicantPaymentStatusApi(applicantId, paymentStatus);
  }

  async runBot(applicantId) {
    await uspsRunBotApi(applicantId);
  }

  async deleteApplicant(applicantId) {
    try {
      await uspsDeleteApplicantApi(applicantId);
      const { applicants } = this.state;
      this.setState({
        applicants: applicants.filter(
          (applicant) => applicant._id !== applicantId,
        ),
      });
      toastr.success("Applicant successfully deleted!", "OK");
    } catch (error) {
      toastr.error("Applicant cannot be deleted!", "Error");
    }
  }

  setSearchBarListener = () => {
    const subscription = this.searchField$
      .pipe(
        tap((searchString) => {
          this.setState({ searchString });
        }),
        distinctUntilChanged(),
        debounceTime(450),
        switchMap(() => from(this.fetchApplicants())),
      )
      .subscribe();
    this.subscriptions.push(subscription);
  };

  handleSort(columnName) {
    const { sortCriteria, sortDirection } = this.state;
    const newState = {};
    if (sortCriteria === columnName) {
      if (sortDirection === "descending") {
        newState.sortCriteria = null;
        newState.sortDirection = null;
      } else {
        newState.sortDirection =
          sortDirection === "ascending" ? "descending" : "ascending";
      }
    } else {
      newState.sortCriteria = columnName;
      newState.sortDirection = "ascending";
    }
    this.setState(newState, () => this.fetchApplicants());
  }

  async changePhoneNumber(id, phoneNumbers) {
    const {
      data: { data: newPhoneNumbers },
    } = await uspsChangeApplicantPhoneNumbers(id, phoneNumbers);
    this.setState({
      applicants: this.state.applicants.map((applicant) =>
        applicant._id !== id
          ? applicant
          : { ...applicant, phoneNumbers: newPhoneNumbers },
      ),
    });
  }

  renderActions(applicant) {
    const { _id, status, paymentStatus } = applicant;
    const { user } = this.props;

    let buttons = (
      <NavigateButton
        route={`usps/applicant/${_id}`}
        color="blue"
        size="mini"
        icon
      >
        <Icon name="edit" />
      </NavigateButton>
    );

    if (paymentStatus === USPS_PAYMENT_STATUSES.UNPAID) {
      buttons = (
        <ConfirmPopup
          content="Are you sure you want to change applicant payment status?"
          callback={() => {
            this.changePaymentStatus(_id, USPS_PAYMENT_STATUSES.PAID);
          }}
        >
          <Button color="green" size="mini" icon>
            <Icon name="dollar" />
          </Button>
        </ConfirmPopup>
      );
    } else {
      switch (status) {
        case USPS_APPLICANT_STATUSES.PENDING_SUBMISSION:
          buttons = (
            <React.Fragment>
              {buttons}
              <SmallConfirmPopup
                header={"Are you sure you want to run the bot?"}
                callback={() => {}}
                content={
                  <div>
                    <Button
                      onClick={() => this.runBot(_id)}
                      positive
                      floated="right"
                      content="Start"
                    />
                  </div>
                }
              >
                <Button color="green" size="mini" icon>
                  <Icon name="play" />
                </Button>
              </SmallConfirmPopup>
            </React.Fragment>
          );
          break;
        case USPS_APPLICANT_STATUSES.BOT_IN_PROGRESS:
          buttons = (
            <React.Fragment>
              {buttons}
              <Button color="green" size="mini" icon loading disabled>
                <Icon name="play" />
              </Button>
            </React.Fragment>
          );
          break;
        case USPS_APPLICANT_STATUSES.BOT_RETURNED_ERROR:
          buttons = (
            <React.Fragment>
              {buttons}
              <NavigateButton
                route={`usps/applicant/${_id}`}
                color="red"
                size="mini"
                icon
              >
                <Icon name="exclamation triangle" />
              </NavigateButton>
            </React.Fragment>
          );
          break;
        default:
          break;
      }
    }

    if (user && user.roles.includes(ROLES.ADMIN)) {
      buttons = (
        <React.Fragment>
          {buttons}
          <SmallConfirmPopup
            header="Are you sure you want to delete the applicant?"
            content={<Button positive floated="right" content="Delete" />}
            callback={() => this.deleteApplicant(_id)}
          >
            <Button color="red" size="mini" icon>
              <Icon name="trash" />
            </Button>
          </SmallConfirmPopup>
        </React.Fragment>
      );
    }

    const { canCall, canSendMessage } = user.permissions;
    if (canCall || canSendMessage) {
      buttons = (
        <React.Fragment>
          {buttons}
          <ModalButton
            buttonProps={{ color: "green", size: "mini" }}
            buttonClassName={"icon"}
            buttonIcon="call"
            modalComponent={
              <PhoneBook
                numbers={applicant.phoneNumbers}
                applicant={applicant}
                onChange={(phoneNumbers) => {
                  this.changePhoneNumber(_id, phoneNumbers);
                }}
              />
            }
          ></ModalButton>
        </React.Fragment>
      );
    }
    return <Table.Cell className="action-buttons">{buttons}</Table.Cell>;
  }

  render() {
    const {
      applicants,
      searchString,
      sortCriteria,
      sortDirection,
      page,
      paymentStatus,
      totalPages,
    } = this.state;
    return (
      <Segment.Group>
        <Segment className="tableTitle adminTable" inverted>
          <Header size="large" floated="left">
            USPS Applicants
          </Header>
          <div className="filters-container">
            <Select
              placeholder="Select payment status"
              options={Object.values(USPS_PAYMENT_STATUSES).map((status) => ({
                text: status,
                value: status,
                key: status,
              }))}
              onChange={(fieldOptions, field) => {
                this.setState({ paymentStatus: field.value }, () =>
                  this.fetchApplicants(),
                );
              }}
              value={paymentStatus}
            />
            <Input
              value={searchString}
              type="text"
              placeholder="Search"
              onChange={(fieldOptions, field) => {
                this.searchField$.next(field.value);
              }}
            />
          </div>
        </Segment>
        {
          <Table sortable celled striped>
            <Table.Header>
              <Table.Row>
                {Object.keys(USPS_TABLE_COLUMNS).map((columnName) => {
                  const { label } = USPS_TABLE_COLUMNS[columnName];

                  return (
                    <Table.HeaderCell
                      key={columnName}
                      width={1}
                      sorted={
                        sortCriteria === columnName ? sortDirection : null
                      }
                      onClick={() => this.handleSort(columnName)}
                    >
                      {label}
                    </Table.HeaderCell>
                  );
                })}
                <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {applicants &&
                applicants.map((applicant) => (
                  <Table.Row key={applicant._id}>
                    {Object.keys(USPS_TABLE_COLUMNS).map((columnName) => {
                      const { value } = USPS_TABLE_COLUMNS[columnName];
                      const tdValue = value
                        ? value(applicant)
                        : applicant[columnName];

                      return (
                        <Table.Cell key={applicant.id + columnName}>
                          {tdValue}
                        </Table.Cell>
                      );
                    })}
                    {this.renderActions(applicant)}
                  </Table.Row>
                ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell
                  colSpan={Object.keys(USPS_TABLE_COLUMNS).length + 2}
                >
                  {applicants ? (
                    applicants.length > 0 ? (
                      <Pagination
                        activePage={page}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={1}
                        totalPages={totalPages}
                        floated="right"
                        onPageChange={(e, { activePage }) => {
                          this.setState({ page: activePage }, () =>
                            this.fetchApplicants(),
                          );
                        }}
                      />
                    ) : (
                      <Message size="big">No results!</Message>
                    )
                  ) : (
                    <Spinner></Spinner>
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        }
      </Segment.Group>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.currentUser,
  };
};

export default connect(mapStateToProps)(UspsApplicantsTable);
