import React from "react";
import { useParams } from "react-router-dom";
import ApplicantDocumentsContextProvider from "../../../../../../../../common/ApplicantDocumentsContext";
import ApplicantDocumentHandlerView from "./components/ApplicantDocumentHandlerView";

const ApplicantDocumentHandler = (props) => {
  const { api } = props;

  const { id } = useParams();

  return (
    <ApplicantDocumentsContextProvider applicantId={id} api={api}>
      <ApplicantDocumentHandlerView />
    </ApplicantDocumentsContextProvider>
  );
};

export default ApplicantDocumentHandler;
