import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";

export const USPS_SELECT_VALUES = {
  STATE: "STATE",
  MOVING_CATEGORY: "MOVING_CATEGORY",
  PHONE_TYPE: "PHONE_TYPE",
  SUFFIX: "SUFFIX",
};

const MOVING_CATEGORIES = {
  INDIVIDUAL: "individual",
  FAMILY: "family",
  BUSINESS: "business",
};

export const uspsSchema = {
  generalInfoSeparator: {
    label: "General application information",
    type: TYPES.SEPARATOR,
  },
  _id: {
    label: "ID",
  },
  orderId: {
    label: "Order ID",
  },
  orderIdHistory:{
    label:"Order ID History"
  },
  invoiceEmail: {
    label: "Payment email",
  },
  zendeskId: {
    label: "Zendesk ID",
    type: TYPES.TEXT,
    required: false,
  },
  botRuns: {
    label: "Number of bot runs",
  },
  temporaryApplicationStatus: {
    label: "Temporary application status",
    dependsOn: "isPermanent",
    dependantOnValues: [false],
  },
  personalInfoSeparator: {
    label: "Personal information",
    type: TYPES.SEPARATOR,
  },
  userEmail: {
    label: "User contact email",
    type: TYPES.EMAIL,
  },
  submissionEmail: {
    label: "User submission email",
    type: TYPES.TEXT,
  },
  phoneNumber: {
    label: "Phone number",
    type: TYPES.TEXT,
  },
  phoneType: {
    label: "Phone type",
    type: TYPES.TEXT,
    values: USPS_SELECT_VALUES.PHONE_TYPE,
    value: "$value",
    text: "$key",
  },
  firstName: {
    label: "First name",
    type: TYPES.TEXT,
    dependsOn: "movingCategory",
    dependantOnValues: [MOVING_CATEGORIES.FAMILY, MOVING_CATEGORIES.INDIVIDUAL],
  },
  middleName: {
    label: "Middle name",
    type: TYPES.TEXT,
    required: false,
    dependsOn: "movingCategory",
    dependantOnValues: [MOVING_CATEGORIES.FAMILY, MOVING_CATEGORIES.INDIVIDUAL],
  },
  lastName: {
    label: "Family name",
    type: TYPES.TEXT,
    dependsOn: "movingCategory",
    dependantOnValues: [MOVING_CATEGORIES.FAMILY, MOVING_CATEGORIES.INDIVIDUAL],
  },
  businessName: {
    label: "Business name",
    type: TYPES.TEXT,
    dependsOn: "movingCategory",
    dependantOnValues: [MOVING_CATEGORIES.BUSINESS],
  },
  movingCategory: {
    label: "Moving category",
    type: TYPES.SELECT,
    values: USPS_SELECT_VALUES.MOVING_CATEGORY,
    value: "$value",
    text: "$key",
  },
  allSameLastName: {
    label: "All same last name",
    type: TYPES.CHECKBOX,
    dependsOn: "movingCategory",
    dependantOnValues: [MOVING_CATEGORIES.FAMILY],
  },
  anyoneRemaining: {
    label: "Anyone remaining",
    type: TYPES.CHECKBOX,
    dependsOn: "movingCategory",
    dependantOnValues: [MOVING_CATEGORIES.FAMILY],
  },
  entireBusinessMoving: {
    label: "Anyone remaining",
    type: TYPES.CHECKBOX,
    dependsOn: "movingCategory",
    dependantOnValues: [MOVING_CATEGORIES.BUSINESS],
  },
  isPermanent: {
    label: "Is moving type permanent",
    type: TYPES.CHECKBOX,
  },
  hasAlreadyMoved: {
    label: "Has already moved",
    type: TYPES.CHECKBOX,
  },
  startForwarding: {
    label: "Moving date",
    type: TYPES.DATE,
    dependsOn: "hasAlreadyMoved",
    dependantOnValues: [false],
  },
  endForwarding: {
    label: "End of Stay date",
    type: TYPES.DATE,
    dependsOn: "isPermanent",
    dependantOnValues: [false],
  },

  oldAddress: {
    label: "Old address",
    type: TYPES.NESTED,
    newTable: true,
    nested: {
      zip: {
        label: "Zip code",
        type: TYPES.TEXT,
        path: "oldAddress",
      },
      city: {
        label: "City",
        type: TYPES.TEXT,
        path: "oldAddress",
      },
      state: {
        label: "State",
        type: TYPES.SELECT,
        values: USPS_SELECT_VALUES.STATE,
        path: "oldAddress",
      },
      street: {
        label: "Street address",
        type: TYPES.TEXT,
        path: "oldAddress",
      },
    },
  },
  newAddress: {
    label: "New address",
    type: TYPES.NESTED,
    newTable: true,
    nested: {
      zip: {
        label: "Zip code",
        type: TYPES.TEXT,
        path: "newAddress",
      },
      city: {
        label: "City",
        type: TYPES.TEXT,
        path: "newAddress",
      },
      state: {
        label: "State",
        type: TYPES.SELECT,
        values: USPS_SELECT_VALUES.STATE,
        path: "newAddress",
      },
      street: {
        label: "Street address",
        type: TYPES.TEXT,
        path: "newAddress",
      },
    },
  },
  newAddressType: {
    label: "New address type",
    dependsOn: "isPermanent",
  },
  ownsNewLocation: {
    label: "Does applicant owns new address",
    dependsOn: "isPermanent",
  },
};
