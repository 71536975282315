import React, { useEffect, useState } from 'react'

const Avatar = ({ text }) => {
    const [name, setName] = useState(undefined);

    useEffect(() => {
        const textParts = text.split(" ");
        if (textParts.length > 0) {
            let initials = textParts[0][0]; 
            if (textParts.length >= 2) {
                initials = initials + textParts[1][0];
            }

            setName(initials);
        }
    }, [text]);

    if (name) {
        return (
          <div className="avatar-container">
              <span className="avatar-text">{name}</span>
          </div>
        )
    }

    return null
}

export default Avatar