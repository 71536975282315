export const PHONE_REGEX = /^\+?[0-9 ]{3,20}$/;
export const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>[\].,;:\s@"]+\.)+[^<>()[\].,;:\s!@#$%^&*()_+=|"'{}/?]{2,})$/i,
);

export const URL_REGEX = new RegExp(
  "^(https?:\\/\\/)" +
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
    "((\\d{1,3}\\.){3}\\d{1,3}))" +
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
    "(\\?[;&a-z\\d%_.~+=-]*)?" +
    "(\\#[-a-z\\d_]*)?",
  "i",
);
