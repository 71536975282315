/* eslint-disable no-restricted-globals */
import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from "react";
import {
  Segment,
  Header,
  Table,
  Button,
  Icon,
  Select,
  Input,
} from "semantic-ui-react";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import * as toastr from "toastr";
import { capitalize } from "../../../../../../../common/string-format";
import {
  assignQATicketApi,
  getQATicketsApi,
  getAgentsQATickets,
  acknowledgeTicketApi,
  openTicketDisputeApi,
} from "../../../../../../../apis/departments/qa/QATickets";
import NavigateButton from "../../../../../../../components/NavigateButton";
import { QA_EVALUATION_TICKET_STATUS } from "../../../../constants/qaTicketStatuses";
import Spinner from "../../../../../../../components/Spinner";
import { AuthService } from "../../../../../../../services/authService";
import { ROLES } from "../../../../../../../constants/rolesConstants";
import { connect } from "react-redux";
import { PRODUCT_NAMES_MAPPING } from "../../../Products/constants/productNames";
import TicketDisputeModal from "./components/TicketDisputeModal";
import { ZENDESK_HOSTS } from "../../constants/zendesk";
import { usePagination } from "../../../../../../../hooks/usePagination.hook";
import {
  useFilters,
  regExpValidator,
  notEmptyValidator,
} from "../../../../../../../hooks/useFilters.hook";
import { logServerError } from "../../../../../../../common/logs";
import { UsersContext } from "../../../../../../../common/UsersContext";
import { getDateFormat } from "../../../../../../../common/date-format";
import "./QATicketTable.styles.scss";

const QATicketTable = ({ user }) => {
  const isQATeamMember = useMemo(() => {
    return AuthService.checkRolesForUser(user, [
      ROLES.QA_AGENT,
      ROLES.QA_MANAGER,
      ROLES.ADMIN,
    ]);
  }, [user]);

  const isQAAgent = useMemo(() => {
    return AuthService.checkRolesForUser(user, [ROLES.QA_AGENT]);
  }, [user]);

  const [availableAgents, setAvailableAgents] = useState();
  const [searchString, setSearchString] = useState("");

  const {
    items: tickets,
    loadItems: loadTickets,
    setItems: setTickets,
    Paginator,
  } = usePagination(isQATeamMember ? getQATicketsApi : getAgentsQATickets, {
    clearOnLoad: false,
    initialLoad: false,
  });

  const { getUsersFiltered } = useContext(UsersContext);

  const filterTransformators = useMemo(
    () => getFilterTransformators(user),
    [user],
  );

  const { filters, setFormFilter, updateFilters, normalizedFilters } =
    useFilters(
      {
        zendeskId: "",
        zendeskHost: [ZENDESK_HOSTS[0].link],
        status: [],
        "agents.assessmentResult": null,
        "agents.agentId": null,
      },
      {
        localStorageKey: "qa-ticket-filters",
        temporaryFilters: ["zendeskId"],
        filterTransformators: filterTransformators,
        filterValidators: filterValidators,
      },
    );

  useEffect(() => {
    const { zendeskHost } = filters;

    setAvailableAgents(
      getUsersFiltered({
        name: searchString,
        zendeskHost,
      }),
    );
  }, [getUsersFiltered, searchString, filters]);

  const finalFilters = useMemo(() => {
    return {
      ...normalizedFilters,
      sortOrder: "asc",
      sortCriteria: `acknowledge.${user._id}`,
    };
  }, [normalizedFilters, user]);

  const assignQATicket = useCallback(
    (ticketId, agentId) => async () => {
      try {
        const {
          data: { evaluation },
        } = await assignQATicketApi(ticketId, agentId);

        setTickets((tickets) =>
          tickets.map((ticket) =>
            ticket._id === ticketId ? evaluation : ticket,
          ),
        );
        toastr.success("Ticket successfully assigned");
      } catch (err) {
        toastr.error(
          "Failed ticket assignment, probably ticket is already assigned",
        );
      }
    },
    [setTickets],
  );

  useEffect(() => {
    loadTickets(1, finalFilters);
  }, [finalFilters, loadTickets]);

  const acknowledgeTicket = useCallback(
    (ticketId) => async () => {
      try {
        await acknowledgeTicketApi(ticketId);
        setTickets((tickets) =>
          tickets.map((ticket) =>
            ticket._id === ticketId
              ? { ...ticket, acknowledge: { [user._id]: true } }
              : ticket,
          ),
        );
      } catch (err) {
        toastr.error("Failed ticket acknowledging");
      }
    },
    [setTickets, user],
  );

  const createDispute = useCallback(
    (ticketId) => async (newDispute) => {
      if (
        !confirm(
          `Are you sure you want to open dispute with reason text: "${newDispute.disputeReasonText}"`,
        )
      ) {
        return;
      }
      try {
        const {
          data: { evaluation },
        } = await openTicketDisputeApi(ticketId, newDispute);
        setTickets((tickets) =>
          tickets.map((ticket) =>
            ticket._id === ticketId ? { ...ticket, ...evaluation } : ticket,
          ),
        );
        toastr.success("Successfully created dispute");
      } catch (err) {
        logServerError(err);
      }
    },
    [setTickets],
  );

  const isTicketAcknowledged = useCallback(
    (ticket) => {
      return ticket.acknowledge && ticket.acknowledge[user._id];
    },
    [user],
  );

  const getAssessmentResult = useCallback(
    (ticket) => {
      if (isQATeamMember) {
        if (filters["agents.agentId"]) {
          const agentInfo = ticket.agents.find(
            (agent) => agent.agentId === filters["agents.agentId"],
          );
          return `${
            agentInfo && agentInfo.assessmentResult ? "Positive" : "Negative"
          }`;
        }
        const totalAgents = ticket.agents.length;
        const positiveAssessed = ticket.agents.reduce(
          (acc, agent) => (acc + agent.assessmentResult ? 1 : 0),
          0,
        );
        return `${positiveAssessed} / ${totalAgents}`;
      }
      const agentInfo = ticket.agents.find(
        (agent) => agent.agentId === user._id,
      );
      return `${
        agentInfo && agentInfo.assessmentResult ? "Positive" : "Negative"
      }`;
    },
    [isQATeamMember, user, filters],
  );

  const getEvaluationPageLink = useCallback(
    (ticket) => {
      return `/evaluation-tickets/${ticket._id}${
        filters["agents.agentId"] ? `?agentId=${filters["agents.agentId"]}` : ""
      }`;
    },
    [filters],
  );

  const ticketActions = useMemo(() => {
    if (!tickets) {
      return [];
    }
    return tickets.map((ticket) => {
      const specifiedAgent =
        ticket.agents.find((agent) => agent.agentId === user._id) || {};
      return (
        <React.Fragment>
          <NavigateButton
            route={getEvaluationPageLink(ticket)}
            color="blue"
            size="mini"
            icon
          >
            <Icon name="edit" />
          </NavigateButton>
          {!ticket.assignedAgentId &&
            AuthService.checkRolesForUser(user, ROLES.QA_AGENT) && (
              <ConfirmPopup
                callback={assignQATicket(ticket._id)}
                content={<p>Are you sure you want to take this ticket?</p>}
              >
                <Button color="green" size="mini" icon>
                  <Icon name="user plus" />
                </Button>
              </ConfirmPopup>
            )}
          {!isTicketAcknowledged(ticket) && (
            <ConfirmPopup
              callback={acknowledgeTicket(ticket._id)}
              content={<p>Are you sure you want to acknowledge this ticket?</p>}
            >
              <Button color="green" size="mini" icon>
                <Icon name="archive" />
              </Button>
            </ConfirmPopup>
          )}
          {!isQATeamMember &&
            ticket.status === QA_EVALUATION_TICKET_STATUS.Submitted && (
              <TicketDisputeModal
                ticketDispute={specifiedAgent.dispute}
                agentAssessmentResult={specifiedAgent.assessmentResult}
                modalState="creating"
                onSubmit={createDispute(ticket._id)}
              />
            )}
        </React.Fragment>
      );
    });
  }, [
    user,
    tickets,
    isQATeamMember,
    acknowledgeTicket,
    assignQATicket,
    createDispute,
    isTicketAcknowledged,
    getEvaluationPageLink,
  ]);

  const filtersRendered = useMemo(() => {
    return (
      <div className="sk-table-filters">
        <Input
          label={"Zendesk ID"}
          value={filters.zendeskId || ""}
          type="text"
          name="zendeskId"
          onChange={setFormFilter}
        />
        <Select
          placeholder="Select acknowledgement status"
          options={[
            { text: "All", value: null, key: "empty" },
            { text: "Acknowledged", value: true, key: "true" },
            { text: "Not acknowledged", value: false, key: "false" },
          ]}
          value={filters[`acknowledge.${user._id}`]}
          name={`acknowledge.${user._id}`}
          onChange={setFormFilter}
        />
        <Select
          placeholder="Select status"
          multiple
          options={Object.entries(QA_EVALUATION_TICKET_STATUS).map(
            ([text, value]) => ({
              text: text,
              value: value,
              key: text,
            }),
          )}
          value={filters.status || []}
          name="status"
          onChange={setFormFilter}
        />
        {isQATeamMember &&
          !isQAAgent && ( // QA team specific filters
            <React.Fragment>
              <Select
                placeholder="Select specific agent"
                options={[
                  {
                    text: "Agent not specified",
                    value: null,
                    key: -1,
                  },
                  ...(availableAgents || []).map((user) => ({
                    value: user._id,
                    text: user.fullName,
                    key: user._id,
                  })),
                ]}
                value={filters["agents.agentId"]}
                search
                onSearchChange={(ev, { searchQuery }) => {
                  setSearchString(searchQuery);
                }}
                onChange={(ev, field) => {
                  const changeObject = {
                    "agents.agentId": field.value,
                  };
                  if (!field.value) {
                    changeObject["agents.assessmentResult"] = null;
                  }
                  updateFilters(changeObject);
                }}
              />
            </React.Fragment>
          )}
        {
          // If agent selecting his, or manager already selected
          <React.Fragment>
            <Select
              placeholder="Select assessment result"
              options={[
                { text: "Any", value: null, key: "empty" },
                { text: "Positive", value: true, key: "positive" },
                { text: "Negative", value: false, key: "negative" },
              ]}
              value={filters["agents.assessmentResult"]}
              name="agents.assessmentResult"
              onChange={setFormFilter}
            />
          </React.Fragment>
        }
      </div>
    );
  }, [
    filters,
    user,
    isQAAgent,
    isQATeamMember,
    setFormFilter,
    updateFilters,
    availableAgents,
  ]);

  return (
    <Segment.Group>
      <Segment className="tableTopSegment" inverted>
        <Header className="tableTitle" size="large" floated="left">
          <h3>QA Tickets</h3>
        </Header>
      </Segment>
      {filtersRendered}
      <Table celled striped fixed className="qa-ticket-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="3">Ticket ID</Table.HeaderCell>
            <Table.HeaderCell width="2">Created Date and Time</Table.HeaderCell>
            <Table.HeaderCell width="2">Submit Date and Time</Table.HeaderCell>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <Table.HeaderCell>Ticket Zendesk ID</Table.HeaderCell>
            <Table.HeaderCell>Assigned agent</Table.HeaderCell>
            <Table.HeaderCell>Assessment result</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!tickets ? (
            <Table.Row>
              <Table.Cell>
                <Spinner />
              </Table.Cell>
            </Table.Row>
          ) : (
            tickets.map((ticket, index) => (
              <Table.Row key={ticket._id} active={isTicketAcknowledged(ticket)}>
                <Table.Cell width="3">
                  <a
                    href={getEvaluationPageLink(ticket)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {ticket._id}
                  </a>
                </Table.Cell>
                <Table.Cell width="2">
                  {getDateFormat(ticket.createdAt)}
                </Table.Cell>
                <Table.Cell width="2">
                  {ticket.submittedAt && getDateFormat(ticket.submittedAt)}
                </Table.Cell>
                <Table.Cell>{PRODUCT_NAMES_MAPPING[ticket.product]}</Table.Cell>
                <Table.Cell>
                  <a
                    href={ticket.zendeskUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {ticket.zendeskId}
                  </a>
                </Table.Cell>
                <Table.Cell>
                  {ticket.assignedAgent && ticket.assignedAgent.fullName}
                </Table.Cell>
                <Table.Cell>{getAssessmentResult(ticket)}</Table.Cell>
                <Table.Cell>{capitalize(ticket.status)}</Table.Cell>
                <Table.Cell>{ticketActions[index]}</Table.Cell>
              </Table.Row>
            ))
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={9}>
              <Paginator />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment.Group>
  );
};

const filterValidators = {
  zendeskId: regExpValidator(/^\d+$/),
  "agents.agentId": (value, filters) => !filters["agents.assessmentResult"],
  "agents.assessmentResult": notEmptyValidator(),
};
const getFilterTransformators = (user) => ({
  [`acknowledge.${user._id}`]: (value) => {
    if (value === false) {
      return {
        propKey: `acknowledge.${user._id}`,
        propValue: {
          $exists: false,
        },
      };
    }
  },
  "agents.assessmentResult": (value, filters) => {
    if (filters["agents.agentId"]) {
      return {
        propKey: "agents",
        propValue: {
          $elemMatch: {
            assessmentResult: filters["agents.assessmentResult"],
            agentId: filters["agents.agentId"],
          },
        },
      };
    }
    return {
      propKey: "agents",
      propValue: {
        $elemMatch: {
          assessmentResult: filters["agents.assessmentResult"],
        },
      },
    };
  },
});

const mapStateToProps = (state) => {
  return {
    user: state.currentUser,
  };
};

export default connect(mapStateToProps)(QATicketTable);
