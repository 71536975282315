import React from "react";
import "./PhoneBook.scss";
import { Button, Input, Form, List, Image, Popup } from "semantic-ui-react";
import MessagePopup from "../MessagePopup";
import Dialpad from "../Dialpad";
import ModalButton from "../ModalButton";
import { PHONE_REGEX } from "../../common/regex-templates";
import { connect } from "react-redux";
import { CALL_AVAILABLE_STATUSES } from "../../Router/routers/DashboardRouter/routers/Users/constants/agentAvailability";

class PhoneBook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      updatingIndex: -1,
      newNumber: this.getEmptyNewNumber(),
      numbers: props.numbers || [],
      filteredNumbers: props.numbers || [],
      searchInput: "",
      openedModalIndex: -1,
    };
  }

  getEmptyNewNumber() {
    return {
      name: "",
      number: "",
      default: false,
    };
  }

  addNewContactHandler() {
    const { numbers, newNumber } = this.state;
    const { onChange } = this.props;
    const newNumbers = [...numbers, newNumber];
    onChange(newNumbers);
    this.setState({
      openForm: false,
      newNumber: this.getEmptyNewNumber(),
      numbers: newNumbers,
      openedModalIndex: -1,
    });
  }

  updateContactHandler() {
    const { numbers, newNumber, updatingIndex } = this.state;
    const { onChange } = this.props;
    const newNumbers = numbers.map((number, index) =>
      index === updatingIndex ? newNumber : number,
    );
    onChange(newNumbers);
    this.setState({
      openForm: false,
      newNumber: this.getEmptyNewNumber(),
      numbers: newNumbers,
      updatingIndex: -1,
      openedModalIndex: -1,
    });
  }

  deleteNumber(event, index) {
    const { numbers } = this.state;
    event.stopPropagation();
    const { onChange } = this.props;
    const newNumbers = numbers.filter(
      (number, numberIndex) => index !== numberIndex,
    );
    onChange(newNumbers);
    this.setState({
      numbers: newNumbers,
      openedModalIndex: -1,
    });
  }

  setDefault(event, updatingIndex) {
    const { numbers } = this.state;
    event.stopPropagation();
    const { onChange } = this.props;
    const newNumbers = numbers.map((number, index) =>
      index === updatingIndex
        ? { ...number, default: true }
        : { ...number, default: false },
    );
    onChange(newNumbers);
    this.setState({
      numbers: newNumbers,
      openedModalIndex: -1,
    });
  }

  searchHandler(value) {
    const { numbers } = this.state;
    this.setState({
      filteredNumbers: numbers.filter(
        (numberInfo) =>
          numberInfo.number.includes(value) || numberInfo.name.includes(value),
      ),
    });
  }

  renderCEContactForm() {
    const { newNumber, updatingIndex } = this.state;
    return (
      <Form
        className="form-add-contact"
        onSubmit={() =>
          updatingIndex !== -1
            ? this.updateContactHandler()
            : this.addNewContactHandler()
        }
      >
        <Input
          label="Phone name:"
          type="text"
          name="name"
          className="form-add-input-contact"
          value={newNumber.name}
          onChange={(event) => {
            this.setState({
              newNumber: { ...newNumber, name: event.target.value },
            });
          }}
        ></Input>
        <Input
          label="Phone number:"
          type="text"
          name="number"
          className="form-add-input-number"
          value={newNumber.number}
          onChange={(event) => {
            const { value } = event.target;
            this.setState({
              newNumber: { ...newNumber, number: value },
            });
          }}
        ></Input>
        <div className="form-add-button-group">
          <Button
            className="form-add-button"
            disabled={
              !newNumber.number ||
              !newNumber.name ||
              !PHONE_REGEX.test(newNumber.number)
            }
          >
            {updatingIndex !== -1 ? "Edit" : "Add"}
          </Button>
          <Button
            className="form-add-exit-button"
            circular
            type="button"
            onClick={() =>
              this.setState({
                openForm: false,
                updatingIndex: -1,
                newNumber: this.getEmptyNewNumber(),
                openedModalIndex: -1,
              })
            }
          >
            Exit
          </Button>
        </div>
      </Form>
    );
  }

  render() {
    const { numbers, updatingIndex, openForm, openedModalIndex } = this.state;
    const {
      applicant = {},
      currentUser: {
        permissions: { canCall } = {},
        settings: { availability } = {},
      } = {},
    } = this.props;
    return (
      <div className="sk-phone-book-modal">
        <div className="phone-book-container">
          <div className="phone-book-header">
            <p>Phone numbers</p>
          </div>
          <div className="phone-book-content">
            <List className="phone-book-list" relaxed selection divided>
              {numbers.map((phoneInfo, index) => (
                <div
                  key={index}
                  className={`phone-book-list-item ${
                    phoneInfo.default ? "main" : ""
                  }`}
                >
                  <Popup
                    key={index}
                    trigger={
                      <List.Item key={index}>
                        <Image
                          avatar
                          src="https://react.semantic-ui.com/images/avatar/small/christian.jpg"
                        />
                        <List.Content>
                          <List.Header as="a">
                            {phoneInfo.default && "(main) "}
                            {phoneInfo.name}
                          </List.Header>
                          <List.Description>
                            {phoneInfo.number}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    }
                    on="click"
                    open={index === openedModalIndex}
                    onOpen={() => {
                      this.setState({ openedModalIndex: index });
                    }}
                    onClose={() => {
                      this.setState({ openedModalIndex: -1 });
                    }}
                    disabled={updatingIndex !== -1}
                  >
                    <div className="phone-book-contact-handlers">
                      <div className="phone-book-contact-update-button">
                        <button
                          // className="sk-call-button"
                          onClick={() => {
                            this.setState({
                              updatingIndex: index,
                              openForm: true,
                              newNumber: JSON.parse(JSON.stringify(phoneInfo)),
                            });
                          }}
                        >
                          <i aria-hidden="true" className="download icon"></i>
                        </button>
                      </div>
                      <div className="phone-book-contact-delete-button">
                        <button
                          // className="sk-call-button"
                          onClick={(event) => this.deleteNumber(event, index)}
                        >
                          <i aria-hidden="true" className="trash icon"></i>
                        </button>
                      </div>
                      {!phoneInfo.default && (
                        <div className="phone-book-contact-set-default">
                          <button
                            // className="sk-call-button"
                            onClick={(event) => this.setDefault(event, index)}
                          >
                            <i aria-hidden="true" className="adjust icon"></i>
                          </button>
                        </div>
                      )}
                    </div>
                  </Popup>
                  <div className="phone-book-list-item-button-group">
                    {canCall &&
                      CALL_AVAILABLE_STATUSES.includes(availability) && (
                        <ModalButton
                          buttonClassName="sk-call-button"
                          buttonProps={{ color: "green" }}
                          buttonIcon="call icon"
                          modalComponent={
                            <Dialpad
                              zendeskId={applicant.zendeskId}
                              phoneNumber={phoneInfo.number}
                              product={applicant.api}
                            ></Dialpad>
                          }
                        ></ModalButton>
                      )}
                    {/* {canSendMessage && ( */}
                    {false && (
                      <ModalButton
                        buttonClassName="sk-call-button"
                        buttonProps={{ color: "blue" }}
                        buttonIcon="envelope outline"
                        modalComponent={
                          <MessagePopup
                            zendeskId={applicant.zendeskId}
                            phoneNumber={phoneInfo.number}
                          ></MessagePopup>
                        }
                      ></ModalButton>
                    )}
                  </div>
                </div>
              ))}
            </List>
          </div>
          <div className="phone-book-add-contact">
            {openForm === true ? (
              this.renderCEContactForm()
            ) : (
              <Button
                className="add-contact-button"
                onClick={() => {
                  this.setState({ openForm: true });
                }}
              >
                + Add Contanct
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps)(PhoneBook);
