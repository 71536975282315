import configuration from "./configuration";
import notifications from "./notifications";
import calls from "./calls";
import evaluation from "./evaluation";
import exportTool from "./exportTool";
import productConfig from "./productConfig";
import botMonitoring from "./botMonitoring";
import documentHandler from "./documentHandler";
import applicantDocumentHandler from "./applicantDocumentsHandler";
import documentHandlerTemplates from "./documentHandlerTemplates";
import logs from "./logs";

export default {
  configuration,
  notifications,
  calls,
  evaluation,
  exportTool,
  productConfig,
  botMonitoring,
  documentHandler,
  applicantDocumentHandler,
  documentHandlerTemplates,
  logs,
};
