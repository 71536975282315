import React from "react";
import FormFields from "../../../../../../../../../components/common/FormFields";

const fields = [
  "siteName",
  "siteId",
  {
    key: "form",
    type: "select",
    fieldSpecific: {
      options: [
        { text: "VISA", value: "VISA" },
        { text: "ESTA", value: "ESTA" },
      ],
    },
    defaultValue: null,
  }
];

const SiteForm = ({ site }) => {
  return <FormFields currentItem={site} fields={fields} />;
};

SiteForm.defaultProps = {
  site: {
    siteName: "",
    siteId: "",
    form: "",
  },
};

export default SiteForm;
