import { getUtcOffset } from "../../../../../../../../../../common/date-format";

/**
 * Calculates hours with offset included
 *
 * @param {string} segment - ordinal number of hour and period (am or pm) - e.g. 11am, 2pm...
 * @returns - string in same format with calculated offset
 */
export const calculateTimeByOffset = (segment) => {
  let segmentRes = "";

  // get ordinal number of hour
  //
  const ordinalNumber = parseInt(segment);

  // ordinal number of hours plus offset
  //
  const hourPlusOffset = ordinalNumber + 5 + getUtcOffset();

  // hourPlusOffset can be negative if the offset is negative
  //
  if (hourPlusOffset < 0) {
    // we calculate exactly what time it is and what the new period is (am or pm)
    //
    segmentRes = 12 + hourPlusOffset;
    segmentRes += segment.includes("am") ? "pm" : "am";
  } else {
    // we calculate exactly what time it is
    //
    segmentRes = hourPlusOffset % 12;

    // we calculate what the new period is (am or pm)
    //
    if (
      ordinalNumber < 12 &&
      hourPlusOffset >= 12 &&
      hourPlusOffset <= 24 &&
      hourPlusOffset / 12 !== 2
    ) {
      segmentRes += segment.includes("am") ? "pm" : "am";
    } else {
      segmentRes += segment.includes("am") ? "am" : "pm";
    }
  }

  return segmentRes;
};
