import * as moment from "moment";
import store from "../store";

export function getDateFormat(
  date,
  forceTimeZone = null,
  format = "YYYY-MM-DD HH:mm",
) {
  return moment(date)
    .utc()
    .utcOffset(+(forceTimeZone !== null ? forceTimeZone : getUtcOffset()))
    .format(format);
}

export function formatSecondsToMinutes(seconds) {
  const mins = ~~(seconds / 60);
  const secs = ~~(seconds % 60);
  return `${mins}:${secs < 10 ? `0${secs}` : secs} min`;
}

export function getUtcOffset() {
  const { currentUser } = store.getState();
  const timeZone = currentUser ? currentUser.settings.timeZone : -5;
  return timeZone;
}

export function getDateInUserTz(date) {
  return moment(date).utcOffset(getUtcOffset(), true).toDate();
}

export function getDateInUserTzOrNow(date) {
  // In case end time is current time, don't trasform
  if (moment().diff(moment(date), "hours") === 0) {
    return new Date();
  }

  return getDateInUserTz(date);
}

export function getStartOfDay(date = moment(), utcOffset = 0) {
  return moment.utc(date).startOf("day").utcOffset(utcOffset, true).toISOString(true);
}

export function getStartOfDayNoOffset(date = moment()) {
  return moment.utc(date).startOf("day").toISOString();
}

export function getEndOfDay (date = moment(), utcOffset = 0) {
  return moment.utc(date).endOf("day").utcOffset(utcOffset, true).toISOString(true);
}

export function getEndOfDayNoOffset(date = moment()) {
  return moment.utc(date).endOf("day").toISOString();
}
