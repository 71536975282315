import React from "react";
import "./Toggle.styles.scss";
import { Checkbox } from "semantic-ui-react";

class Toggle extends React.Component {
  render() {
    const { callback, checked, ...restProps } = this.props;

    return (
      <Checkbox toggle className="custom-toggle"
        checked={checked}
        onChange={() => {
          this.setState({ checked: !checked });
          callback && callback(!checked);
        }}
        {...restProps} />
    );
  }
}

export default Toggle;
