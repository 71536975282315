import { LocalStorageService } from "./localStorageService";
import { SessionStorageService } from "./sessionStorageService";

export class CredentialsService {
  static isSimulation() {
    return (
      new URLSearchParams(window.location.search).get("simulate-user") ||
      SessionStorageService.getUserId()
    );
  }

  static getUserId() {
    return (
      new URLSearchParams(window.location.search).get("simulate-user") ||
      SessionStorageService.getUserId() ||
      LocalStorageService.getUserId()
    );
  }

  static getUser() {
    return SessionStorageService.getUser() || LocalStorageService.getUser();
  }

  static getToken() {
    return SessionStorageService.getToken() || LocalStorageService.getToken();
  }
}
