import { useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import { Observable, PartialObserver, OperatorFunction } from "rxjs";

/**
 *
 * @param {Observable} Observable
 * @param {PartialObserver} observer
 * @param {OperatorFunction[]} pipe
 */
function useSubscription(Observable, observer, pipe = []) {
  useEffect(() => {
    const subscription = Observable.pipe(...pipe).subscribe(observer);
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observer]);
}

export { useSubscription };
