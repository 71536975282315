import React from "react";
import { Table, Checkbox } from "semantic-ui-react";
import { ALLOWED_REFERRAL_SITES } from "../../../../../EVisa/pages/ApplicantsEdit/components/constants";

const TSAReviewAndCertification = ({ referralSite = ALLOWED_REFERRAL_SITES.DEFAULT }) => {
  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell colSpan="2">
          <p>
            <b>Declaration</b>
          </p>
          <p>
            I undertake that I am aware of the contents of my submission and
            that it will be taken to have been personally submitted by me.
          </p>
          <p>
            I am aware that I may be liable for offense(s) concerning any false,
            inaccurate, or incomplete statement or declaration contained in the
            arrival card.
          </p>
          <p>
            I also understand that I may be liable for abetting these offenses.
            I am aware that the government fee is not refunded if the TSA
            application is refused.
          </p>
          <p>If you are submitting on behalf of another individual: </p>
          <p>
            The individual I am submitting the application for is aware of all
            the contents of my submission, and acknowledges and consents to all
            the aforementioned and following statements. The individual on whose
            behalf I am submitting the application for is aware that he/she may
            be liable for offence(s) concerning any false, inaccurate, or
            incomplete statement or declaration contained in the application. I
            also understand that I may be liable for abetting these offences.
          </p>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell colSpan="2">
          <p>
            <b>Privacy Act Statement</b>
          </p>
          <p>
            Your information may be shared within DHS on a need-to-know basis,
            adhering to the Privacy Act, 5 U.S.C. § 552a(b), and may also be
            disclosed to the United States Coast Guard if you are applying for a
            Merchant Mariner Credential.
          </p>
          <p>
            {" "}
            Additionally, your facial biometric data collected during the
            application process may be utilized during screening under the TSA
            PreCheck Application Program at airports. Moreover, biometric data
            gathered from applicants to the TSA PreCheck Application Program may
            be utilized for screening purposes at airport checkpoints. Your
            fingerprints and associated data will be forwarded to the Federal
            Bureau of Investigation (FBI) for comparison with other fingerprints
            in the FBI’s Next Generation Identification (NGI) system or its
            successor systems, encompassing civil, criminal, and latent
            fingerprint repositories.
          </p>
          <p>
            The FBI may retain your fingerprints and associated data in NGI
            beyond the conclusion of the application process, enabling continued
            comparison with other submitted or retained fingerprints. DHS will
            also transmit your fingerprints for enrollment into the Automated
            Biometrics Identification System (IDENT). Should you provide your
            Social Security Number (SSN), DHS may furnish your name and SSN to
            the Social Security Administration (SSA) for comparison against SSA
            records to ensure data accuracy.
          </p>
          <p>
            Please check the following laws for reference: 6 U.S.C. § 1140; 46
            U.S.C. § 70105; 49 U.S.C. §§ 106, 114, 5103a, 40103(b)(3), 40113,
            44903, 44935-44936, 44939, and 46105; the Implementing
            Recommendations of the 9/11 Commission Act of 2007, § 1520 (121
            Stat. 444, Public Law 110-52, August 3, 2007); and Executive Order
            9397, as amended.
          </p>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell colSpan="2">
          <p>
            <b>Paperwork Reduction Act Statement</b>
          </p>
          <p>
            Participation in providing this information is voluntary; however,
            failure to do so may lead to the inability to approve your
            eligibility for the desired TSA program or benefit. The TSA
            estimates that the average time burden per response for enrollment
            in this collection is approximately 30 minutes. An agency is
            prohibited from conducting or sponsoring such a collection of
            information unless it displays a valid Office of Management and
            Budget (OMB) control number, and individuals are not obligated to
            respond to it.
          </p>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell colSpan="2">
          <h5>Third Party Authorization</h5>
          <p>
            I understand that I am applying via GovAssist, LLC; a third-party
            that is not affiliated with the government, that the third-party
            cannot guarantee application approval, that the third-party service
            is offered to help expedite the process, and that I have the option
            to apply directly with the pertinent government websites. I also
            understand that my purchase consists of a processing and service fee
            to GovAssist, which DOES NOT include the Form non-refundable
            government fee, which is required.
          </p>
          <p>
            I confirm and authorize GovAssist, LLC to assist me, by using their
            processing service in completing and reviewing my application. I
            understand and acknowledge that I have not been offered legal advice
            and that this company or its agents and affiliates have never
            claimed to be qualified to offer legal advice under any
            circumstance. I also confirm that I am submitting my application and
            signing it myself.
          </p>
          <p>
            By obtaining services from the GovAssist website, I have agreed to
            the following:{" "}
            <a href={`https://${referralSite}/terms-conditions`}>
              Terms & Conditions
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/refund-policy`}>
              Refund Policy{" "}
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/privacy-policy`}>
              Privacy Policy{" "}
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/legal-disclaimer`}>
              Disclaimer{" "}
            </a>
          </p>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>
    </React.Fragment>
  );
};

export default TSAReviewAndCertification;
