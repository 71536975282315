export function dashCaseToReadable(stringValue) {
  if (typeof stringValue !== "string") return "";
  return capitalize(
    stringValue.replace(/_[a-z]/g, (value) => ` ${value[1].toUpperCase()}`),
  );
}

export function capitalize(stringValue) {
  if (typeof stringValue !== "string") return "";
  return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
}

export function propetyToReadable(propertyName) {
  return dashCaseToReadable(propertyName);
}

export const transformConstantsText = (text) => text.replace(/(_|-)/, " ");

export function addSpaceBeforeCapital(stringValue) {
  if (typeof stringValue !== 'string') return '';

  const stringWithSpaces = stringValue.replace(/([a-z])([A-Z])/g, '$1 $2');
  return capitalize(stringWithSpaces);
}
