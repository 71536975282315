import httpClient from "../httpClient";

function getAllUserNotificationsApi() {
  return httpClient.get(`/notifications`);
}

function getImportantNotificationsApi() {
  return httpClient.get(`/notifications/important`);
}

function readNotification(notificationId) {
  return httpClient.get(`/notifications/${notificationId}`);
}

export default {
  getAllUserNotificationsApi,
  getImportantNotificationsApi,
  readNotification,
};
