import React from "react";
import { Button } from "semantic-ui-react";
import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";
import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../../constants/endpoints";
import ZendeskIcon from "../../../../../../../../components/Icons/ZendeskIcon/ZendeskIcon";

export const ESTA_SELECT_VALUES = {
  GENDER: "GENDER",
  COUNTRY: "COUNTRY",
  CITIZENSHIP_COUNTRY: "CITIZENSHIP_COUNTRY",
  COUNTRY_CODE: "COUNTRY_CODE",
  STATE: "STATE",
  TELEPHONE: "TELEPHONE",
  SOCIAL_MEDIAS: "SOCIAL_MEDIAS",
  FORBIDDEN_COUNTRY: "FORBIDDEN_COUNTRY",
  FORBIDDEN_COUNTRY_REASON: "FORBIDDEN_COUNTRY_REASON",
  DOCUMENT_TYPE: "DOCUMENT_TYPE",
  CITIZENSHIP_AQUIRE_TYPE: "CITIZENSHIP_AQUIRE_TYPE",
};

const MONTH_SELECT_OPTIONS = {
  0: "JANUARY",
  1: "FEBRUARY",
  2: "MARCH",
  3: "APRIL",
  4: "MAY",
  5: "JUNE",
  6: "JULY",
  7: "AUGUST",
  8: "SEPTEMBER",
  9: "OCTOBER",
  10: "NOVEMBER",
  11: "DECEMBER",
};

export const estaSchema = {
  currentStage: {
    label: "Current bot stage",
  },
  brokerNodeName: {
    label: "Esta bot node name",
  },
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      applicantNumber: {
        label: "Applicant number",
        type: TYPES.TEXT,
      },
      applicantNumberHistory: {
        label: "Applicant number history",
      },
      applicationType: {
        label: "Application Type",
      },
      mainApplicationId: {
        label: "Main Application ID",
        dependsOn: "applicationType",
        dependantOnValues: ["Secondary"],
      },
      siteId: {
        label: "Referral Site",
      },
      cartSiteId: {
        label: "Purchased from website",
      },
      orderId: {
        label: "Order ID",
      },
      orderIdHistory:{
        label:"Order ID History"
      },
      ivisaOrderId: {
        label: "iVisa order ID",
      },
      ivisaOrderIdHistory: {
        label: "iVisa order ID history",
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
        renderIcon: (propValue) => (
          <Button
            onClick={() => {
              window.open(
                TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(propValue),
                "_blank",
              );
            }}
            style={{ width: 36, height: 36, padding: 0, float: "right" }}
            disabled={!propValue}
          >
            <ZendeskIcon className="zendesk-icon" />
          </Button>
        ),
      },
      gclid: {
        label: "Google client ID",
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      salesAgent: {
        label: "Sales Agent",
      },
      salesCoupon: {
        label: "Sales Coupon",
      },
      botRuns: {
        label: "Number of bot runs",
      },
      numberOfChecks: {
        label: "Number of done checks",
      },
      timeOfNextCheck: {
        label: "Time of next check",
      },
    },
  },
  botConfigSegment: {
    label: "Bot Configuration segment",
    type: TYPES.SEGMENT,
    data: {
      botConfig: {
        label: "Bot Configuration",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          overrideExisting: {
            label: "Override existing application",
            type: TYPES.CHECKBOX,
            path: "botConfig",
          },
          acceptContactPhone: {
            label: "Accept invalid contact phone",
            type: TYPES.CHECKBOX,
            path: "botConfig",
          },
          acceptContactAddress: {
            label: "Accept invalid contact address",
            type: TYPES.CHECKBOX,
            path: "botConfig",
          },
          acceptEmploymentPhone: {
            label: "Accept invalid employment phone",
            type: TYPES.CHECKBOX,
            path: "botConfig",
          },
          acceptEmploymentAddress: {
            label: "Accept invalid employment address",
            type: TYPES.CHECKBOX,
            path: "botConfig",
          },
          acceptUsContactCityOrState: {
            label: "Accept US contact invalid city/state",
            type: TYPES.CHECKBOX,
            path: "botConfig",
          },
          acceptUsContactPhone: {
            label: "Accept US contact invalid phone",
            type: TYPES.CHECKBOX,
            path: "botConfig",
          },
          acceptAddressWhileInUS: {
            label: "Accept Address while in US invalid address",
            type: TYPES.CHECKBOX,
            path: "botConfig",
          },
          acceptUsEmergencyPhone: {
            label: "Accept US contact invalid emergency phone",
            type: TYPES.CHECKBOX,
            path: "botConfig",
          },
          hasAdditionalCobInfo: {
            label:
              "User needs to answer additional questions for birth of country",
            type: TYPES.CHECKBOX,
            path: "botConfig",
          },
          cobStatements: {
            label: "Additional fields for birth of country",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn: "botConfig.hasAdditionalCobInfo",
            path: "botConfig",
            nested: {
              didNotAcquire: {
                label:
                  "Did not acquire citizenship at birth or have never held citizenship in birth country",
                type: TYPES.CHECKBOX,
                path: "botConfig.cobStatements",
              },
              renounced: {
                label: "Renounced citizenship of birth country",
                type: TYPES.CHECKBOX,
                path: "botConfig.cobStatements",
              },
              haveNotLived: {
                label:
                  "Have not lived or resided in birth country within the past five years",
                type: TYPES.CHECKBOX,
                path: "botConfig.cobStatements",
              },
              haveNotHeld: {
                label:
                  "Have not held a passport or national identity card from birth country within the past five years",
                type: TYPES.CHECKBOX,
                path: "botConfig.cobStatements",
              },
              other: {
                label: "Other",
                type: TYPES.CHECKBOX,
                path: "botConfig.cobStatements",
              },
              answer: {
                label: "Text description",
                type: TYPES.TEXT,
                path: "botConfig.cobStatements",
              },
            },
          },
        },
      },
    },
  },
  eligibilityQuestionSegment: {
    label: "Eligibility questions",
    type: TYPES.SEGMENT,
    customDependency: ({ 
      mentalDisorder,
      beenArrested,
      drugs,
      terrorist,
      obtainVisa,
      usaEmployment,
      rejectedVisa,
      stayLonger,
      hasBeenInForbiddenCountries,
      hasBeenInCuba
    }) => {
        if ( mentalDisorder ||
          beenArrested ||
          drugs ||
          terrorist ||
          obtainVisa ||
          usaEmployment ||
          rejectedVisa ||
          stayLonger ||
          hasBeenInForbiddenCountries ||
          hasBeenInCuba ) {
          return true;
        }
      },
    data: {
      mentalDisorder: {
        label: "Do you have a physical or mental disorder; or are you a drug abuser or addict; or do you currently have any of the following diseases (communicable diseases are specified pursuant to section 361(b) of the Public Health Service Act): Cholera, Diphtheria, Tuberculosis, infectious, Plague, Smallpox, Yellow Fever, Viral Hemorrhagic Fevers, including Ebola, Lassa, Marburg, Crimean-Congo, Severe acute respiratory illnesses capable of transmission to other persons and likely to cause mortality.",
        type: TYPES.CHECKBOX,
        dependsOn: "mentalDisorder",
      },
      beenArrested: {
        label: "Have you ever been arrested or convicted for a crime that resulted in serious damage to property, or serious harm to another person or government authority?",
        type: TYPES.CHECKBOX,
        dependsOn: "beenArrested",
      },
      drugs: {
        label: "Have you ever violated any law related to possessing, using, or distributing illegal drugs?",
        type: TYPES.CHECKBOX,
        dependsOn: "drugs",
      },
      terrorist: {
        label: "Do you seek to engage in or have you ever engaged in terrorist activities, espionage, sabotage, or genocide?",
        type: TYPES.CHECKBOX,
        dependsOn: "terrorist",
      },
      obtainVisa: {
        label: "Have you ever committed fraud or misrepresented yourself or others to obtain, or assist others to obtain, a visa or entry into the United States?",
        type: TYPES.CHECKBOX,
        dependsOn: "obtainVisa",
      },
      usaEmployment: {
        label: "Are you currently seeking employment in the United States or were you previously employed in the United States without prior permission from the U.S. government?",
        type: TYPES.CHECKBOX,
        dependsOn: "usaEmployment",
      },
      rejectedVisa: {
        label: "Have you ever been denied a U.S. visa you applied for with your current or previous passport, or have you ever been refused admission to the United States or withdrawn your application for admission at a U.S. port of entry?",
        type: TYPES.CHECKBOX,
        dependsOn: "rejectedVisa",
      },
      stayLonger: {
        label: "Have you ever stayed in the United States longer than the admission period granted to you by the U.S. government?",
        type: TYPES.CHECKBOX,
        dependsOn: "stayLonger",
      },
      hasBeenInForbiddenCountries: {
        label: "Have you traveled to, or been present in Iran, Iraq, Libya, North Korea, Somalia, Sudan, Syria or Yemen on or after March 1, 2011?",
        type: TYPES.CHECKBOX,
        dependsOn: "hasBeenInForbiddenCountries",
      },
      hasBeenInCuba: {
        label: "Have you ever traveled to, or been present in Cuba on or after January 12, 2021?",
        type: TYPES.CHECKBOX,
        dependsOn: "hasBeenInCuba",
      },
      
    },
  },
  applicantPassportInfoSegment: {
    label: "Applicant/passport information",
    type: TYPES.SEGMENT,
    data: {
      submissionEmail: {
        label: "Submission email where code/applicant number are received",
        type: TYPES.TEXT,
      },
      firstName: {
        label: "First name",
        type: TYPES.TEXT,
      },
      lastName: {
        label: "Family name",
        type: TYPES.TEXT,
      },
      userEmail: {
        label: "Users email address",
        type: TYPES.TEXT,
      },
      gender: {
        label: "Gender",
        type: TYPES.SELECT,
        values: ESTA_SELECT_VALUES.GENDER,
      },
      hasOtherAliases: {
        label: "User has other alias",
        type: TYPES.CHECKBOX,
      },
      additionalAliases: {
        label: "Additional aliases",
        type: TYPES.ARRAY,
        dependsOn: "hasOtherAliases",
        data: {
          firstName: {
            label: "First name",
            type: TYPES.TEXT,
            path: "additionalAliases.$additionalAliases",
          },
          familyName: {
            label: "Family name",
            type: TYPES.TEXT,
            path: "additionalAliases.$additionalAliases",
          },
        },
      },
      birthDate: {
        label: "Birth date",
        type: TYPES.DATE,
      },
      birthCountry: {
        label: "Birth country",
        type: TYPES.SELECT,
        values: ESTA_SELECT_VALUES.COUNTRY,
        geoLocationProps: ["birthCity"],
      },
      birthCity: {
        label: "Birth city",
        type: TYPES.TEXT,
      },
      passportNumber: {
        label: "Passport number",
        type: TYPES.TEXT,
      },
      countryOfCitizenship: {
        label: "Citizenship country",
        type: TYPES.SELECT,
        values: ESTA_SELECT_VALUES.CITIZENSHIP_COUNTRY,
      },
      nationalId: {
        label: "National ID",
        type: TYPES.TEXT,
        dependsOn: "countryOfCitizenship",
        dependantOnValues: [
          "BE",
          "BN",
          "CL",
          "CZ",
          "EE",
          "DE",
          "GR",
          "HU",
          "LU",
          "MT",
          "MC",
          "NL",
          "PT",
          "SG",
          "SK",
          "SI",
          "KR",
          "ES",
          "HR",
        ],
      },
      issuingCountry: {
        label: "Issuing country",
        type: TYPES.SELECT,
        values: ESTA_SELECT_VALUES.CITIZENSHIP_COUNTRY,
      },
      personalId: {
        label: "Personal ID",
        type: TYPES.TEXT,
        dependsOn: "issuingCountry",
        dependantOnValues: ["TW"],
      },
      issuanceDate: {
        label: "Issuance date",
        type: TYPES.DATE,
      },
      expirationDate: {
        label: "Expiration date",
        type: TYPES.DATE,
      },
      hasOtherIssuingCountry: {
        label: "Has other issuing documents",
        type: TYPES.CHECKBOX,
      },
      otherIssuingInfo: {
        label: "Other issuing info",
        type: TYPES.ARRAY,
        dependsOn: "hasOtherIssuingCountry",
        data: {
          issuingCountry: {
            label: "Issuing country",
            type: TYPES.SELECT,
            values: ESTA_SELECT_VALUES.COUNTRY,
            path: "otherIssuingInfo.$otherIssuingInfo",
          },
          documentType: {
            label: "Document type",
            type: TYPES.SELECT,
            values: ESTA_SELECT_VALUES.DOCUMENT_TYPE,
            path: "otherIssuingInfo.$otherIssuingInfo",
          },
          documentNumber: {
            label: "Document number",
            type: TYPES.TEXT,
            path: "otherIssuingInfo.$otherIssuingInfo",
          },
          expDay: {
            label: "Expiration day",
            type: TYPES.SELECT,
            values: "default-days",
            dependsOn: "otherIssuingInfo.$otherIssuingInfo.issuingCountry",
            dependantOnValues: ["US"],
            path: "otherIssuingInfo.$otherIssuingInfo",
          },
          expMonth: {
            label: "Expiration month",
            type: TYPES.SELECT,
            values: MONTH_SELECT_OPTIONS,
            dependsOn: "otherIssuingInfo.$otherIssuingInfo.issuingCountry",
            dependantOnValues: ["US"],
            path: "otherIssuingInfo.$otherIssuingInfo",
          },
          expYear: {
            label: "Expiration year",
            type: TYPES.TEXT,
            path: "otherIssuingInfo.$otherIssuingInfo",
          },
        },
      },
    },
  },
  otherCitizenshipSegment: {
    label: "Other citizenship/nationality information",
    type: TYPES.SEGMENT,
    data: {
      hasOtherCitizenshipNow: {
        label: "Currently has other citizenship",
        type: TYPES.CHECKBOX,
      },
      otherCitizenshipNow: {
        label: "Other current citizenship info",
        type: TYPES.ARRAY,
        dependsOn: "hasOtherCitizenshipNow",
        data: {
          country: {
            label: "Country",
            type: TYPES.SELECT,
            values: ESTA_SELECT_VALUES.COUNTRY,
            path: "otherCitizenshipNow.$otherCitizenshipNow",
          },
          howDidGet: {
            label: "How did user get it",
            type: TYPES.SELECT,
            values: ESTA_SELECT_VALUES.CITIZENSHIP_AQUIRE_TYPE,
            path: "otherCitizenshipNow.$otherCitizenshipNow",
          },
          other: {
            label: "Other description:",
            type: TYPES.TEXT,
            dependsOn: "otherCitizenshipNow.$otherCitizenshipNow.howDidGet",
            dependantOnValues: [4],
            path: "otherCitizenshipNow.$otherCitizenshipNow",
          },
        },
      },
      hadOtherCitizenshipBefore: {
        label: "Had other citizenship in past",
        type: TYPES.CHECKBOX,
      },
      otherCitizenshipBefore: {
        label: "Other citizenship info from past",
        type: TYPES.ARRAY,
        dependsOn: "hadOtherCitizenshipBefore",
        data: {
          country: {
            label: "Country",
            type: TYPES.SELECT,
            values: ESTA_SELECT_VALUES.COUNTRY,
            path: "otherCitizenshipBefore.$otherCitizenshipBefore",
          },
          from: {
            label: "Date from",
            type: TYPES.DATE,
            path: "otherCitizenshipBefore.$otherCitizenshipBefore",
          },
          to: {
            label: "Date to",
            type: TYPES.DATE,
            path: "otherCitizenshipBefore.$otherCitizenshipBefore",
          },
        },
      },
      hasGeMembership: {
        label: "Has Ge membership",
        type: TYPES.CHECKBOX,
      },
      passid: {
        label: "Passid/Membership Number",
        type: TYPES.TEXT,
        dependsOn: "hasGeMembership",
      },
    },
  },
  parentsSegment: {
    label: "Parents information",
    type: TYPES.SEGMENT,
    data: {
      fatherFirstName: {
        label: "Father first name",
        type: TYPES.TEXT,
        required: false,
      },
      fatherFamilyName: {
        label: "Father familty name",
        type: TYPES.TEXT,
        required: false,
      },
      motherFirstName: {
        label: "Mother first name",
        type: TYPES.TEXT,
        required: false,
      },
      motherFamilyName: {
        label: "Mother familiy name",
        type: TYPES.TEXT,
        required: false,
      },
    },
  },
  contactInformationSegment: {
    label: "Contact information",
    type: TYPES.SEGMENT,
    data: {
      addressLine1: {
        label: "First address",
        note: "This will be used to verify the client's ID on Calls/Chats",
        type: TYPES.TEXT,
      },
      addressLine2: {
        label: "Secondary address",
        type: TYPES.TEXT,
        required: false,
      },
      apartmentNumber: {
        label: "Apartment number",
        type: TYPES.TEXT,
        required: false,
      },
      city: {
        label: "Address city",
        type: TYPES.TEXT,
      },
      state: {
        label: "Address state",
        type: TYPES.TEXT,
      },
      country: {
        label: "Address country",
        type: TYPES.SELECT,
        values: ESTA_SELECT_VALUES.COUNTRY,
        geoLocationProps: ["city", "addressLine1"],
      },

      contactInfo: {
        label: "Contact info",
        type: TYPES.ARRAY,
        data: {
          telephoneType: {
            label: "Telephone type",
            type: TYPES.SELECT,
            values: ESTA_SELECT_VALUES.TELEPHONE,
            path: "contactInfo.$contactInfo",
          },
          countryCode: {
            label: "Country code",
            type: TYPES.SELECT,
            values: ESTA_SELECT_VALUES.COUNTRY_CODE,
            path: "contactInfo.$contactInfo",
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "contactInfo.$contactInfo",
          },
        },
      },
      hasSocialMedia: {
        label: "Has social media",
        type: TYPES.CHECKBOX,
      },
      socialMediaPrimary: {
        label: "Social media primary",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          facebookId: {
            label: "Facebook id",
            type: TYPES.TEXT,
            path: "socialMediaPrimary",
            required: false,
          },
          linkedInId: {
            label: "Linkedin id",
            type: TYPES.TEXT,
            path: "socialMediaPrimary",
            required: false,
          },
          twitterId: {
            label: "Twitter id",
            type: TYPES.TEXT,
            path: "socialMediaPrimary",
            required: false,
          },
          instagramId: {
            label: "Instagram id",
            type: TYPES.TEXT,
            path: "socialMediaPrimary",
            required: false,
          },
        },
        dependsOn: "hasSocialMedia",
      },
      socialMedia: {
        label: "Social media",
        type: TYPES.ARRAY,
        data: {
          platform: {
            label: "Platform",
            type: TYPES.SELECT,
            values: ESTA_SELECT_VALUES.SOCIAL_MEDIAS,
            path: "socialMedia.$socialMedia",
          },
          otherPlatform: {
            label: "Name Platform",
            type: TYPES.TEXT,
            path: "socialMedia.$socialMedia",
            dependsOn: "socialMedia.$socialMedia.platform",
            dependantOnValues: [99],
          },
          username: {
            label: "Username",
            type: TYPES.TEXT,
            path: "socialMedia.$socialMedia",
          },
        },
        dependsOn: "hasSocialMedia",
      },
    },
  },
  employmentSegment: {
    label: "Employment information",
    type: TYPES.SEGMENT,
    data: {
      hasEmploymentInformation: {
        label: "Has employment information",
        type: TYPES.CHECKBOX,
      },
      jobTitle: {
        label: "Job title",
        type: TYPES.TEXT,
        dependsOn: "hasEmploymentInformation",
      },
      employerName: {
        label: "Employer name",
        type: TYPES.TEXT,
        dependsOn: "hasEmploymentInformation",
      },
      employerAddress: {
        label: "Employer address",
        type: TYPES.TEXT,
        dependsOn: "hasEmploymentInformation",
      },
      employerAddress2: {
        label: "Employer address 2",
        type: TYPES.TEXT,
        required: false,
        dependsOn: "hasEmploymentInformation",
      },
      employerCity: {
        label: "Employer city",
        type: TYPES.TEXT,
        dependsOn: "hasEmploymentInformation",
      },
      employerState: {
        label: "Employer state",
        type: TYPES.TEXT,
        dependsOn: "hasEmploymentInformation",
      },
      employerCountry: {
        label: "Employer country",
        type: TYPES.SELECT,
        values: ESTA_SELECT_VALUES.COUNTRY,
        dependsOn: "hasEmploymentInformation",
        geoLocationProps: ["employerCity", "employerAddress"],
      },
      employerCountryCode: {
        label: "Employer country code",
        type: TYPES.SELECT,
        required: false,
        values: ESTA_SELECT_VALUES.COUNTRY_CODE,
        dependsOn: "hasEmploymentInformation",
      },
      employerPhone: {
        label: "Employer phone",
        type: TYPES.TEXT,
        dependsOn: "hasEmploymentInformation",
      },
    },
  },
  USPointOfContactSegment: {
    label: "US point of contact information",
    type: TYPES.SEGMENT,
    data: {
      departureDate: {
        label: "Departure date",
        type: TYPES.DATE,
        dependsOn: "departureDateLessThan24hours",
        dependantOnValues: [false, undefined],
      },
      departureDateLessThan24hours: {
        label: "Departure in less than 24 hours",
        type: TYPES.CHECKBOX,
      },
      transitSelect: {
        label: "Is trip occurring in transit to other country",
        type: TYPES.CHECKBOX,
      },
      usContactName: {
        label: "US contact name",
        type: TYPES.TEXT,
        dependsOn: "transitSelect",
        dependantOnValues: [false],
      },
      usContactCountryCode: {
        label: "US contact country code",
        type: TYPES.SELECT,
        values: ESTA_SELECT_VALUES.COUNTRY_CODE,
        dependsOn: "transitSelect",
        dependantOnValues: [false],
        geoLocationProps: ["usContactCity", "usContactAddressLine1"],
      },
      usContactState: {
        label: "US contact state",
        type: TYPES.SELECT,
        values: ESTA_SELECT_VALUES.STATE,
        dependsOn: "transitSelect",
        dependantOnValues: [false],
      },
      usContactCity: {
        label: "US contact city",
        type: TYPES.TEXT,
        dependsOn: "transitSelect",
        dependantOnValues: [false],
      },
      usContactAddressLine1: {
        label: "US contact address",
        type: TYPES.TEXT,
        dependsOn: "transitSelect",
        dependantOnValues: [false],
      },
      usContactAddressLine2: {
        label: "US contact address 2",
        type: TYPES.TEXT,
        required: false,
        dependsOn: "transitSelect",
        dependantOnValues: [false],
      },
      usContactApartmentNumber: {
        label: "US contact apartment number",
        type: TYPES.TEXT,
        required: false,
        dependsOn: "transitSelect",
        dependantOnValues: [false],
      },
      usContactPhone: {
        label: "US contact phone number",
        type: TYPES.TEXT,
        dependsOn: "transitSelect",
        dependantOnValues: [false],
      },
    },
  },
  stayAddressSegment: {
    label: "Address while in US information",
    type: TYPES.SEGMENT,
    data: {
      sameInfoAsUsContact: {
        label: "Stay info same as US contact",
        type: TYPES.CHECKBOX,
      },
      usState: {
        label: "Stay address state",
        type: TYPES.SELECT,
        values: ESTA_SELECT_VALUES.STATE,
        dependsOn: "sameInfoAsUsContact",
        dependantOnValues: [false],
      },
      usCity: {
        label: "Stay address city",
        type: TYPES.TEXT,
        dependsOn: "sameInfoAsUsContact",
        dependantOnValues: [false],
      },
      usAddressLine1: {
        label: "Stay address",
        type: TYPES.TEXT,
        dependsOn: "sameInfoAsUsContact",
        dependantOnValues: [false],
      },
      usAddressLine2: {
        label: "Stay address 2",
        type: TYPES.TEXT,
        dependsOn: "sameInfoAsUsContact",
        dependantOnValues: [false],
      },
      usApartmentNumber: {
        label: "Stay address apartment number",
        type: TYPES.TEXT,
        dependsOn: "sameInfoAsUsContact",
        dependantOnValues: [false],
      },
    },
  },
  emergencySegment: {
    label: "Emergency contact information",
    type: TYPES.SEGMENT,
    data: {
      emergencyCountryCode: {
        label: "Emergency country code",
        type: TYPES.SELECT,
        values: ESTA_SELECT_VALUES.COUNTRY_CODE,
      },
      emergencyFirstName: {
        label: "Emergency first name",
        type: TYPES.TEXT,
      },
      emergencyFamilyName: {
        label: "Emergency family name",
        type: TYPES.TEXT,
      },
      emergencyEmail: {
        label: "Emergency email",
        type: TYPES.TEXT,
        required: false,
      },
      emergencyPhone: {
        label: "Emergency phone number`",
        type: TYPES.TEXT,
        required: false,
      },
    },
  },
  additionalSegment: {
    label: "Additional information",
    type: TYPES.SEGMENT,
    data: {
      rejectedVisa: {
        label: "Visa was rejected",
        type: TYPES.CHECKBOX,
      },
      whenRejected: {
        label: "When rejected?",
        type: TYPES.TEXT,
        dependsOn: "rejectedVisa",
      },
      whereRejected: {
        label: "Where rejected?`",
        type: TYPES.TEXT,
        dependsOn: "rejectedVisa",
      },
      hasBeenInForbiddenCountries: {
        label: "Has been in forbidden countries",
        type: TYPES.CHECKBOX,
      },
      forbiddenCountries: {
        label: "Forbidden countries",
        type: TYPES.ARRAY,
        dependsOn: "hasBeenInForbiddenCountries",
        data: {
          country: {
            label: "Country",
            type: TYPES.SELECT,
            values: ESTA_SELECT_VALUES.FORBIDDEN_COUNTRY,
            path: "forbiddenCountries.$forbiddenCountries",
          },
          fromDate: {
            label: "From date",
            type: TYPES.DATE,
            format: "YYYY-MM 12:00:00",
            path: "forbiddenCountries.$forbiddenCountries",
          },
          toDate: {
            label: "To date",
            type: TYPES.DATE,
            format: "YYYY-MM 12:00:00",
            path: "forbiddenCountries.$forbiddenCountries",
          },
          primaryReason: {
            label: "Primary reason",
            type: TYPES.SELECT,
            values: ESTA_SELECT_VALUES.FORBIDDEN_COUNTRY_REASON,
            path: "forbiddenCountries.$forbiddenCountries",
          },
          otherReason: {
            label: "Other reason",
            type: TYPES.TEXT,
            dependsOn: "forbiddenCountries.$forbiddenCountries.primaryReason",
            dependantOnValues: "OTH",
            path: "forbiddenCountries.$forbiddenCountries",
          },
        },
      },
    },
  },
};
