import React from 'react';
import { Button, Input } from "semantic-ui-react";
import './SecretInputField.styles.scss';

const EditSecretInputField = ({isLoading,useLabel,label, onChange,handleUpdate,handleCancelEdit}) => {
    return (
        <div>
        <Input
          disabled={isLoading}
          label={useLabel ? label : null}
          onChange={(_,data) => {
            onChange(data)
          }}
        />
        <Button disabled={isLoading} onClick={handleUpdate} color='green'>Save</Button>
        <Button disabled={isLoading} onClick={handleCancelEdit}>Cancel</Button>
      </div>
    )
}

export default EditSecretInputField