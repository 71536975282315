import React from "react";
import FormFields from "../../../../../../../../../components/common/FormFields";

const fields = [
  {
    key: "cardName",
    type: "input",
    fieldSpecific: {
      type: "text",
      required: true,
    },
  },
  {
    key: "cardHolder",
    type: "input",
    fieldSpecific: {
      type: "text",
      required: true,
    },
  },
  {
    key: "cardNumber",
    type: "input",
    fieldSpecific: {
      type: "text",
      required: true,
    },
  },
  {
    key: "expirationDate",
    type: "input",
    fieldSpecific: {
      type: "date",
      required: true,
    },
  },
  {
    key: "securityCode",
    type: "input",
    fieldSpecific: {
      type: "number",
      required: true,
    },
  },

  {
    key: "group-first",
    type: "group",
    fieldSpecific: {
      className: "credit-card-group",
    },
    groupFields: [
      {
        key: "billingAddress",
        type: "input",
        fieldSpecific: {
          type: "text",
          required: true,
        },
      },
      {
        key: "billingAddress2",
        type: "input",
        fieldSpecific: {
          type: "text",
          required: true,
        },
      },
    ],
  },
  {
    key: "group-second",
    type: "group",
    fieldSpecific: {
      className: "credit-card-group",
    },
    groupFields: [
      {
        key: "city",
        type: "input",
        fieldSpecific: {
          type: "text",
          required: true,
        },
      },
      {
        key: "state",
        type: "input",
        fieldSpecific: {
          type: "text",
          required: true,
        },
      },
    ],
  },
  {
    key: "group-third",
    type: "group",
    fieldSpecific: {
      className: "credit-card-group",
    },
    groupFields: [
      {
        key: "zip",
        type: "input",
        fieldSpecific: {
          type: "number",
          required: true,
        },
      },
      {
        key: "country",
        type: "input",
        fieldSpecific: {
          type: "text",
          required: true,
        },
      },
    ],
  },
];

const CreditCardForm = ({ creditCard }) => {
  return <FormFields fields={fields} currentItem={creditCard} />;
};

CreditCardForm.defaultProps = {
  creditCard: {
    cardName: "",
    cardHolder: "",
    cardNumber: "",
    expirationDate: new Date("MM/dd/yyyy"),
    securityCode: 0,
    billingAddress: "",
    billingAddress2: "",
    city: "",
    state: "",
    zip: null,
    country: "",
  },
};

export default CreditCardForm;
