import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import "./TextIcon.styles.scss";

export default class TextIcon extends Component {
  render() {
    return (
      <div
        style={{ whiteSpace: "nowrap", display: "inline-flex" }}
        title={this.props.children}
      >
        <Icon size="large" color={this.props.color} name={this.props.name} />
        <div className="text-icon">{this.props.children}</div>
      </div>
    );
  }
}
