import { getTwilioTokenApi, sendTwilioSMS } from "../apis/twilio";
// eslint-disable-next-line no-unused-vars
import { ReplaySubject, Observable } from "rxjs";
import { LocalStorageService } from "./localStorageService";
import socketService from "./socketService";
import toastr from "toastr";
import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../constants/endpoints";
import { CredentialsService } from "./credentialsService";

// eslint-disable-next-line no-unused-vars
const { Device, Connection } = require("twilio-client");
const TOKEN_EXPIRED_CODE = 31205;
const SMS_NOT_VALID_NUMBER_ERROR = 21211;
const SMS_NOT_ENABLED_REGION_ERROR = 21408;
const NO_DEVICE_FOUND = 31201;
const JWT_TOKEN_PARSING_FAILED = 31204;

let triedLoadingWithoutToken = false;
const _twilioStatus = new ReplaySubject(0);
const _callRinging = new ReplaySubject(0);
const _callRingingStopped = new ReplaySubject(0);
const _callStarted = new ReplaySubject(0);
const _callStopped = new ReplaySubject(0);
/**
 * @class TwilioService
 *
 * https://www.twilio.com/docs/voice/client/javascript/connection
 */
class TwilioService {
  constructor() {
    this.twilioStatus = false;
    _twilioStatus.next(false);
    /**
     * @type {string}
     * @description token used to identify the client, it is fetched from the API
     */
    this.token = null;
    /**
     * @type {Observable<boolean>}
     * @description emits the status changes of twilio
     */
    this.statusObservable = _twilioStatus.asObservable();
    this.statusObservable.subscribe((status) => (this.twilioStatus = status));
    /**
     * @type {Observable<{connection:Connection,isIncoming:boolean}>}
     * @description emits event phone is ringing, if isIncoming is set it is incoming call and it can be answered by calling accept() or rejected by calling reject() on connection object
     */
    this.callRingingObservable = _callRinging.asObservable();
    /**
     * @type {Observable<{connection:Connection,accepted:boolean,isIncoming:boolean}>}
     * @description emits when phone stops ringing
     */
    this.callRingingStoppedObservable = _callRingingStopped.asObservable();
    /**
     * @type {Observable<{connection:Connection,isIncoming:boolean}>}
     * @description emits event when inbound/outbound call is started
     */
    this.callStartedObservable = _callStarted.asObservable();
    /**
     * @type {Observable<Connection>}
     * @description emits event when inbound/outbound call is finished by anyone
     */
    this.callStoppedObservable = _callStopped.asObservable();
  }

  /**
   * Starts all the listeners and calls Device.setup with loaded token
   */
  start() {
    if (!CredentialsService.isSimulation()) {
      // If token is still not fethed set flag to initialize when it loads
      if (!this.token) {
        triedLoadingWithoutToken = true;
      } else {
        this.initializeListeners();
        Device.setup(this.token);
        socketService.sendEvent("twilio-start");
      }
    }
  }

  stop() {
    Device.destroy();
    this.token = null;
    triedLoadingWithoutToken = false;
  }

  async loadToken() {
    const {
      data: { token },
    } = await getTwilioTokenApi();

    this.token = token;
    if (triedLoadingWithoutToken) {
      this.start();
    }
  }

  clearToken() {
    this.token = null;
  }

  initializeListeners() {
    Device.destroy();
    Device.on("ready", () => {
      console.log("Twilio is ready");
      _twilioStatus.next(true);
    });
    Device.on("error", (error) => {
      console.log("Twilio error happend");
      console.log(error);
      switch (error.code) {
        case TOKEN_EXPIRED_CODE:
          this.stop();
          triedLoadingWithoutToken = true;
          this.loadToken();
          break;
        case NO_DEVICE_FOUND:
          toastr.error("No voice device found!", "Call failed!");
          break;
        case JWT_TOKEN_PARSING_FAILED:
          break;
        default:
          toastr.error(error.message, "Twilio call error");
          break;
      }
    });

    Device.on("incoming", (connection) => {
      this.setConnectionListeners(connection, false);
    });
  }

  /**
   * Called to initiate call with given phone number
   *
   * @param {string} phoneNumber
   * @param {{zendeskId?:string,forceNoTicket?:boolean,product:string}} applicantInfo if its clients number
   */
  startCall(phoneNumber, applicantInfo = {}) {
    if (this.deviceStatus === "ready") {
      const connection = Device.connect({
        phoneNumber,
        token: LocalStorageService.getToken(),
        ...applicantInfo,
      });
      this.setConnectionListeners(connection, true);
    } else {
      toastr.error(
        "Please click to enable calls before calling applicants",
        "Call failed",
      );
      console.error(
        "Twilio must be initialized with TwilioService.start() before making the call",
      );
    }
  }

  getIncomingCallParticipantInfo(connection) {
    const callerProperties = {};
    const {
      parameters: { Params } = {},
      message: { zendeskId } = {},
    } = connection;
    if (Params) {
      const searchParams = new URLSearchParams(`?${Params}`);
      for (let [prop, value] of searchParams) {
        callerProperties[prop] = value;
      }
    }
    if (zendeskId) {
      callerProperties.zendeskUrl = TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(
        zendeskId,
      );
    }
    return callerProperties;
  }

  sendDigits(connection, digit) {
    connection.sendDigits(digit);
  }

  setConnectionListeners(connection, clientIsCaller) {
    _callRinging.next({ connection, isIncoming: !clientIsCaller });
    connection.on("accept", () => {
      _callRingingStopped.next({
        connection,
        accepted: true,
        isIncoming: !clientIsCaller,
      });
      _callStarted.next({ connection, isIncoming: !clientIsCaller });
    });
    connection.on("cancel", () => {
      _callRingingStopped.next({
        connection,
        accepted: false,
        isIncoming: !clientIsCaller,
      });
    });
    connection.on("reject", () => {
      _callRingingStopped.next({
        connection,
        accepted: false,
        isIncoming: !clientIsCaller,
      });
    });
    connection.on("disconnect", (arg) => {
      _callStopped.next(connection);
    });
  }

  async sendSMS(phoneNumber, message, applicantInfo) {
    try {
      const response = await sendTwilioSMS(phoneNumber, message, applicantInfo);
      return response.data;
    } catch (error) {
      const errorCode = error.response.data.error.code;
      switch (errorCode) {
        case SMS_NOT_VALID_NUMBER_ERROR:
          toastr.error(
            `Phone number: ${phoneNumber} is not valid.`,
            "Error 21211",
          );
          break;
        case SMS_NOT_ENABLED_REGION_ERROR:
          toastr.error(
            `Permission to send an SMS has not been enabled for the region indicated by the Phone number: ${phoneNumber}`,
            "Error 21408",
          );
          break;
        default:
          break;
      }
    }
    // console.log(response);
  }

  /**
   * @returns {'ready'|'offline'|'busy'|null}
   */
  get deviceStatus() {
    try {
      return Device.status();
    } catch (err) {
      // in case Device isn't setup
      return null;
    }
  }
}

export default new TwilioService();
