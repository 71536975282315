import React from 'react'
import { Checkbox, Table } from "semantic-ui-react";
import { ALLOWED_REFERRAL_SITES } from '../../../../../EVisa/pages/ApplicantsEdit/components/constants';

const VNReviewAndCertification = ({ referralSite = ALLOWED_REFERRAL_SITES.DEFAULT }) => {
  return (
    <React.Fragment>
        <Table.Row>
            <Table.Cell colSpan="2">
                <h4>Declaration of Applicant</h4>
                <div>
                    <p>I declare that I have carefully read the instructions and completed the application.</p>
                    <p>I assure that I have truthfully declared all relevant details.</p>
                </div>
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>AGREE</Table.Cell>
            <Table.Cell>
                <Checkbox checked={true} />
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell colSpan="2">
                <h5>Third Party Authorization</h5>
                <p>I confirm and authorize GovAssist LLC to review, modify, and submit my application on my behalf. I understand and acknowledge that i have not been offered legal advice and that this company or its agents and affiliates have never claimed to be qualified to offer legal advice under any circumstance</p>
                <p>I understand that I am applying via a third-party service that is not associated with the Vietnamese Government, and that my purchase includes the fee charged by the government as well as a processing and service fee to GovAssist</p>
                <p>
                    By obtaining services from the GovAssist website I have
                    agreed to the following:{" "}
                    <a href={`https://${referralSite}/terms-conditions`}>
                        Terms & Conditions
                    </a>{" "}
                    |{" "}
                    <a href={`https://${referralSite}/refund-policy`}>
                        Refund Policy{" "}
                    </a>{" "}
                    |{" "}
                    <a href={`https://${referralSite}/privacy-policy`}>
                        Privacy Policy{" "}
                    </a>{" "}
                    |{" "}
                    <a href={`https://${referralSite}/legal-disclaimer`}>
                        Disclaimer{" "}
                    </a>
                </p>
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>AGREE</Table.Cell>
            <Table.Cell>
                <Checkbox checked={true} />
            </Table.Cell>
        </Table.Row>
    </React.Fragment>
  )
}

export default VNReviewAndCertification