import React from "react";
import ConfirmPopup from "../../../../../../../../../components/ConfirmPopup";
import { Button } from "semantic-ui-react";
import { checkStatusApi } from "../../../../../../../../../apis/applicantApi";
import toastr from "toastr";
import { APPLICANT_STATUSES } from "../../../../constants/applicantStatuses";
import { API_NAMES } from "../../../constants/apiNames";

class StatusChecker extends React.Component {
  checkStatus() {
    const { api, id } = this.props;
    checkStatusApi(id, api)
      .then(() => {
        toastr.success("Status checker started", "OK");
      })
      .catch(err => {
        toastr.error(
          "Status checker was recently started, please wait 5 minutes before starting it again",
          "OK"
        );
      });
  }
 
  eligibleStatuses = {
    [API_NAMES.ESTA]:[
      APPLICANT_STATUSES["Waiting for approval"],
      APPLICANT_STATUSES["Processed manually"],
      APPLICANT_STATUSES["Approved"],
      APPLICANT_STATUSES["Rejected"],
      APPLICANT_STATUSES["Payment could not be determined"],
      APPLICANT_STATUSES["Status checker error"],
      APPLICANT_STATUSES["Escalated"],
      APPLICANT_STATUSES["Submitted to IVISA"],
      APPLICANT_STATUSES["Submitted and paid"],
    ],
    [API_NAMES.VN]:[
      APPLICANT_STATUSES["Waiting for approval"],
      APPLICANT_STATUSES["Processed manually"],
      APPLICANT_STATUSES["Approved"],
      APPLICANT_STATUSES["Rejected"],
      APPLICANT_STATUSES["Payment could not be determined"],
      APPLICANT_STATUSES["Status checker error"],
      APPLICANT_STATUSES["Escalated"],
      APPLICANT_STATUSES["Submitted and paid"],
    ],
    [API_NAMES.ETA]:[
      APPLICANT_STATUSES["Waiting for approval"],
      APPLICANT_STATUSES["Processed manually"],
      APPLICANT_STATUSES["Approved"],
      APPLICANT_STATUSES["Rejected"],
      APPLICANT_STATUSES["Payment could not be determined"],
      APPLICANT_STATUSES["Status checker error"],
      APPLICANT_STATUSES["Escalated"],
      APPLICANT_STATUSES["Submitted and paid"],
      APPLICANT_STATUSES["More information required"],
      APPLICANT_STATUSES["Cancelled"]
    ],
};

  render() {
    const { applicantStatus, api} = this.props;
    return (
      <ConfirmPopup
      content="Are you sure you want to manually run status check?"
      callback={() => {
        this.eligibleStatuses[api].includes(applicantStatus)
          ? this.checkStatus()
          : toastr.error('Action not allowed in this application stage!')
      }}
    >
        <Button>Check status</Button>
      </ConfirmPopup>
    );
  }
}

export default StatusChecker;
