import React from "react";
import { Button } from "semantic-ui-react";

function FileInput({ multiple, onFileUpload, accept = '', disabled }) {
    const fileInputRef = React.createRef();
    return (
      <React.Fragment>
        <Button
          content="Choose File"
          labelPosition="left"
          icon="file"
          onClick={() => fileInputRef.current.click()}
          style={{ float: "right" }}
          disabled={disabled}
        />
        <input
          ref={fileInputRef}
          type="file"
          accept={accept}
          multiple={multiple}
          hidden
          onChange={(event) => onFileUpload(event)}
        />
      </React.Fragment>
    );
}

export default FileInput;