import React from "react";
import ReactDOM from "react-dom";
import Confirm from "../components/Confirm/Confirm.component";

export default async (text) => {
  const div = document.createElement("div");

  document.body.appendChild(div);

  const close = () => {
    ReactDOM.unmountComponentAtNode(div);
    document.body.removeChild(div);
  };

  return new Promise((resolve) => {
    ReactDOM.render(
      <Confirm
        parent={div}
        onCancel={() => {
          resolve(false);
          close();
        }}
        onConfirm={() => {
          resolve(true);
          close();
        }}
      >
        {text}
      </Confirm>,
      div,
    );
  });
};
