import { useState, useCallback } from "react";
import { startOfDate } from "../utils";
import moment from "moment";

export function useCalendarFilter(
  initialSelectDate = {
    startDate: startOfDate(moment().subtract(7, "days")),
    endDate: new Date(),
  },
  initialSelectSingleFilter = "7",
) {
  const [selectedDateSingleFilter, setSelectedDateSingleFilter] = useState(
    initialSelectSingleFilter,
  );
  const [selectedDate, setSelectedDate] = useState(initialSelectDate);

  const onChangeDate = useCallback((value, type, unit) => {
    if (!value) {
      setSelectedDateSingleFilter(null);
      setSelectedDate({
        startDate: null,
        endDate: null,
      });
    } else if (type === "range") {
      setSelectedDateSingleFilter(null);
      setSelectedDate({
        startDate: startOfDate(value[0]),
        endDate: value[1],
      });
    } else if (type === "radio") {
      setSelectedDateSingleFilter(value);
      setSelectedDate({
        startDate: startOfDate(moment(new Date()).subtract(value, unit)),
        endDate: new Date(),
      });
    }
  }, []);

  return {
    selectedDateSingleFilter,
    selectedDate,
    onChangeDate,
  };
}
