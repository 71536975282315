/**
 * Make shifts with arrays of hours between first and last segments
 *
 * @typedef {{segmentGroup: { firstSegment: string, lastSegment: string }}} segmentGroup
 * @typedef {{segmentGroup1: segmentGroup, segmentGroup2: segmentGroup, segmentGroup3: segmentGroup }} shiftGroup
 * @param {shiftGroup} shiftGroup
 * @returns - array of hours between first and last segment
 */
export const makeShiftSegments = (shiftGroup) => {
  let shiftSegments = {};

  // iterating through the shiftGroup object
  //
  for (const [key, value] of Object.entries(shiftGroup)) {
    // key - shift group name
    // value - object: first and last segment
    //
    const { firstSegment, lastSegment } = value;

    // get first and last hour in 00-24 format
    //
    const firstHour =
      parseInt(firstSegment) + (firstSegment.includes("pm") && 12);
    const lastHour = parseInt(lastSegment) + (lastSegment.includes("pm") && 12);

    let length;

    // get number of hours between first and last segment
    //
    if (firstHour < lastHour) {
      length = lastHour - firstHour;
    } else {
      length = 24 - firstHour + lastHour;
    }

    // make object - properties: shift names, values: array of hours between first and last segment
    //
    shiftSegments = {
      ...shiftSegments,
      [key]: Array.from({ length: length + 1 }, (_, ind) =>
        ind + firstHour >= 24 ? ind + firstHour - 24 : ind + firstHour,
      ),
    };
  }

  return shiftSegments;
};
