import axios from '../httpClient'

export function getAllBotErrorStatistics() {
    return axios.get(`/bot-error-statistics`);
}

export function getPaginatedBotErrorStatisticsByProduct(product, queryParams) {
    return axios.get(`/bot-error-statistics/product/${product}`, { params: queryParams });
}

export function getAllBotErrorStatisticsByProduct(product) {
	return axios.get(`/bot-error-statistics/all/product/${product}`);
}

export function getEventLogsTypes() {
	return axios.get(`/bot-error-statistics/products`);
}

export function getBotErrorStatisticsTotalCount() {
	return axios.get(`/bot-error-statistics/total-count`);
  }

export function updateBotErrorStatistics(query, body) {
    return axios.put(`/bot-error-statistics`, body, { params: query });
}

