import {
  FETCH_CURRENT_USER,
  EDIT_USER_SETTINGS
} from "../actions/actionTypes";

const currentUserReducer = (state = null, action) => {
  switch (action.type) {
    case FETCH_CURRENT_USER:
      return action.payload;
    case EDIT_USER_SETTINGS:
      return {
        ...state,
        settings: { ...state.settings, ...action.payload }
      };
    default:
      return state;
  }
};

export default currentUserReducer;
