import React, { Component } from "react";
import { Button, Select } from "semantic-ui-react";
import { editAvailability } from "../../../../store/actions/userActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TwilioService from "../../../../services/twilioService";
import Dialpad from "../../../Dialpad";
import MessagePopup from "../../../MessagePopup";
import CallStatus from "../../../CallStatus";
import socketService from "../../../../services/socketService";
import { SOCKET_EVENTS } from "../../../../constants/socketEvents";
import { filter } from "rxjs/operators";
import { LocalStorageService } from "../../../../services/localStorageService";
import { EDIT_USER_SETTINGS } from "../../../../store/actions/actionTypes";
import {
  AGENT_AVAILABILITY,
  CALL_AVAILABLE_STATUSES,
} from "../../../../Router/routers/DashboardRouter/routers/Users/constants/agentAvailability";
import ModalButton from "../../../ModalButton";
import Spinner from "../../../Spinner";

class TwilioInitMenu extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      isTwilioActive: TwilioService.twilioStatus,
      isLoading: false,
    };
  }

  get agentAvailability() {
    const { currentUser } = this.props;
    return currentUser ? currentUser.settings.availability : null;
  }

  componentDidMount() {
    this.initializeListeners();
  }
  componentWillUnmount() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  initializeListeners() {
    this.subscriptions.push(
      TwilioService.statusObservable.subscribe((status) => {
        this.setState({ isLoading: false });
      }),
    );
    this.subscriptions.push(
      TwilioService.statusObservable.subscribe((status) => {
        this.setState({ isTwilioActive: status });
      }),
    );
    this.subscriptions.push(
      socketService.socketSubject
        .pipe(
          filter(
            ({ type }) => SOCKET_EVENTS.AGENTS.AVAILABILITY_CHANGED === type,
          ),
          filter(
            ({ data: { agentId } }) =>
              agentId === LocalStorageService.getUserId(),
          ),
        )
        .subscribe(({ data: { agentId, agentAvailability } }) => {
          const { dispatch } = this.props;
          dispatch({
            type: EDIT_USER_SETTINGS,
            payload: { availability: agentAvailability },
          });
        }),
    );
  }
  renderTwilioIcons() {
    const {
      currentUser: {
        permissions: { canCall },
      },
    } = this.props;
    const { isTwilioActive } = this.state;
    return (
      <React.Fragment>
        {<CallStatus></CallStatus>}
        {isTwilioActive ? (
          <React.Fragment>
            {canCall &&
              CALL_AVAILABLE_STATUSES.includes(this.agentAvailability) && (
                <ModalButton
                  buttonClassName="sk-call-button force-left-margin icon"
                  buttonProps={{ color: "green" }}
                  buttonIcon="call"
                  modalComponent={<Dialpad></Dialpad>}
                ></ModalButton>
              )}
          </React.Fragment>
        ) : CALL_AVAILABLE_STATUSES.includes(this.agentAvailability) && (
          <Button
            style={{ marginLeft: "16px" }}
            onClick={() => TwilioService.start()}
          >
            Click to enable calls
          </Button>
        )}
        {false && CALL_AVAILABLE_STATUSES.includes(this.agentAvailability) && (
          <ModalButton
            buttonClassName="sk-call-button force-left-margin icon"
            buttonProps={{ color: "blue" }}
            buttonIcon="envelope outline"
            modalComponent={<MessagePopup></MessagePopup>}
          ></ModalButton>
        )}
      </React.Fragment>
    );
  }

  renderAvailabilityStatus() {
    const { editAvailability } = this.props;
    return (
      <Select
        value={this.agentAvailability}
        search={true}
        options={Object.values(AGENT_AVAILABILITY).map((status) => ({
          text: status,
          value: status,
        }))}
        onChange={(fieldOptions, field) => {
          editAvailability(field.value);
          if (CALL_AVAILABLE_STATUSES.includes(field.value)) {
            TwilioService.start();
          }
        }}
      />
    );
  }

  render() {
    const { isLoading } = this.state;
    const { currentUser } = this.props;
    if (!currentUser || isLoading) {
      return <Spinner />;
    }
    return (
      <React.Fragment>
        {this.renderAvailabilityStatus()}
        {this.renderTwilioIcons()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  editAvailability: bindActionCreators(editAvailability, dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(TwilioInitMenu);
