import React, { useCallback } from "react";
import { Checkbox, Input, Select, Table } from "semantic-ui-react";
import ApplicantViewSegment from "../ApplicantViewSegment";
import moment from "moment";
import { API_NAMES } from "../../../../EVisa/constants/apiNames";
import DS11ReviewAndCertification from "./ds11";
import ETAReviewAndCertification from "./eta";
import NZReviewAndCertification from "./nz";
import VNReviewAndCertification from "./vn";
import DS160ReviewAndCertification from "./ds160";
import INDReviewAndCertification from "./ind";
import SchengenReviewAndCertification from "./schengen";
import TRKReviewAndCertification from "./trk";
import AUSReviewAndCertification from "./aus";
import SGReviewAndCertification from "./sg";
import GEReviewAndCertification from "./ge";
import SLReviewAndCertification from "./sl";
import TSAReviewAndCertification from "./tsa";
import SentriReviewAndCertification from "./sentri";
import NexusReviewAndCertification from "./nexus";
import { ALLOWED_REFERRAL_SITES } from "../../../../EVisa/pages/ApplicantsEdit/components/constants";

const ReviewCertificationSegment = ({
  applicant,
  declarations,
  setApplicant,
  schemaData,
  openAllSection,
}) => {
  const getReferralSite = (api, siteId) => {
    const isSupportedSite = Object.values(ALLOWED_REFERRAL_SITES).find(el => el === siteId);

    switch (api) {
      case API_NAMES.IND:
        return isSupportedSite ? siteId : ALLOWED_REFERRAL_SITES.TRAVELASSIST;
      case API_NAMES.DS11:
      case API_NAMES.DS82:
      case API_NAMES.DS64:
        return isSupportedSite ? siteId: ALLOWED_REFERRAL_SITES.GOVASSIST;
      default:
        return isSupportedSite ? siteId : ALLOWED_REFERRAL_SITES.DEFAULT;
    }
  }
  
  const renderDeclarations = useCallback(() => {
    const { api, siteId } = applicant;

    const referralSite = getReferralSite(api, siteId);
    
    switch (api) {
      case API_NAMES.ESTA:
      case API_NAMES.DS160:
        return (<DS160ReviewAndCertification declarations={declarations} referralSite={referralSite} />);
      case API_NAMES.VN:
        return (<VNReviewAndCertification referralSite={referralSite} />);
      case API_NAMES.IND:
        return (<INDReviewAndCertification referralSite={referralSite} />);
      case API_NAMES.SCHENGEN:
        return (<SchengenReviewAndCertification applicant={applicant} referralSite={referralSite} />);
      case API_NAMES.TRK:
        return (<TRKReviewAndCertification referralSite={referralSite} />);
      case API_NAMES.NZ:
        return (<NZReviewAndCertification referralSite={referralSite} />);
      case API_NAMES.AUS:
        return (<AUSReviewAndCertification referralSite={referralSite} />);
      case API_NAMES.ETA:
        return (<ETAReviewAndCertification referralSite={referralSite} />);
      case API_NAMES.SG:
        return (<SGReviewAndCertification referralSite={referralSite} />);
      case API_NAMES.GE:
        return (<GEReviewAndCertification referralSite={referralSite} />);
      case API_NAMES.SL:
        return (<SLReviewAndCertification referralSite={referralSite} />);
      case API_NAMES.TSA:
        return (<TSAReviewAndCertification referralSite={referralSite} />);
      case API_NAMES.SENTRI:
        return (<SentriReviewAndCertification referralSite={referralSite} />);
      case API_NAMES.NEXUS:
        return (<NexusReviewAndCertification referralSite={referralSite} />);
      case API_NAMES.DS11:
      case API_NAMES.DS82:
      case API_NAMES.DS64:
        return (<DS11ReviewAndCertification referralSite={referralSite} />);
      default:
        return <></>;
    }
  }, [applicant, declarations]);

  const checkIfApplicantIsUnder18 = useCallback((birthDate) => {
    const date = new Date(birthDate);
    const years = moment().diff(date, "years");
    return years < 18;
  }, []);

  if ([API_NAMES.DIVERSITY, API_NAMES.INV].includes(applicant.api)) return null;

  return (
    <ApplicantViewSegment
      label="Review and Certification"
      openAllSection={openAllSection}
      customData={
        <React.Fragment>
          {renderDeclarations()}

          {checkIfApplicantIsUnder18(applicant.birthDate) &&
            applicant.parentOrGuardianSignature && (
              <>
                <Table.Row>
                  <Table.Cell colSpan="2">
                    <div>
                      As part of our commitment to protecting the privacy of
                      minors, we require parental consent before handling any
                      personal data belonging to individuals under the age of
                      18.
                    </div>
                    <div>
                      I{" "}
                      <Input
                        value={applicant.parentOrGuardianOption}
                        type="text"
                        disabled
                      />{" "}
                      parent/guardian of{" "}
                      {applicant.api === API_NAMES.DS160
                        ? `${applicant.firstName} ${applicant.lastName}`
                        : applicant.fullName}
                      , grant GovAssist LLC and any of its subsidiary companies
                      or affiliates
                    </div>
                    <div>
                      (Hereafter 'GovAssist') consent to collect, process, and
                      store{" "}
                      {applicant.api === API_NAMES.DS160
                        ? `${applicant.firstName} ${applicant.lastName}`
                        : applicant.fullName}{" "}
                      personal data in accordance with the company's privacy
                      policy and applicable laws. I ratify my child's signature
                      on all travel document applications and required
                      electronic applications.
                    </div>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Signature of parent/guardian</Table.Cell>
                  <Table.Cell>
                    <Input
                      value={applicant.parentOrGuardianSignature}
                      type="text"
                      disabled
                    />
                  </Table.Cell>
                </Table.Row>
              </>
            )}

          <Table.Row>
            <Table.Cell colSpan="2">
              <h5>Personal Data Confidentiality & Protection</h5>
              {applicant.identityVerificationService?.pin ? (
                <div>
                  In order for us to keep your personal information secure, you
                  are required to authenticate your identity with a 4-digit
                  security PIN in the communications that will follow. Please
                  also answer one of the security questions, which will help to
                  reset your security PIN just in case you forget it in the
                  future.
                </div>
              ) : (
                <div>
                  Please answer one of the security questions, which will help
                  us verify your ID in case we can’t verify your street address.
                </div>
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Has identity verification service</Table.Cell>
            <Table.Cell>
              <Checkbox
                checked={
                  applicant.identityVerificationService
                    ?.hasIdentityVerificationService
                }
                onClick={() => {
                  setApplicant((prev) => ({
                    ...prev,
                    identityVerificationService: {
                      ...prev.identityVerificationService,
                      hasIdentityVerificationService:
                        !prev.identityVerificationService
                          ?.hasIdentityVerificationService,
                    },
                  }));
                }}
              />
            </Table.Cell>
          </Table.Row>

          {applicant.identityVerificationService
            ?.hasIdentityVerificationService && (
            <React.Fragment>
              {applicant.identityVerificationService?.pin && (
                <Table.Row>
                  <Table.Cell>Security PIN</Table.Cell>
                  <Table.Cell>
                    <Input
                      value={applicant.identityVerificationService?.pin}
                      type="text"
                      onChange={(fieldOptions, field) => {
                        setApplicant((prev) => ({
                          ...prev,
                          identityVerificationService: {
                            ...prev.identityVerificationService,
                            pin: field.value,
                          },
                        }));
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              )}

              <Table.Row>
                <Table.Cell>Security Question</Table.Cell>
                <Table.Cell>
                  <Select
                    lazyLoad
                    disabled
                    value={applicant.identityVerificationService?.question}
                    options={Object.keys(
                      schemaData.IDENTITY_VERIFICATION_QUESTIONS,
                    ).map((el) => ({
                      text: schemaData.IDENTITY_VERIFICATION_QUESTIONS[el],
                      value: el,
                    }))}
                  />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Security Answer</Table.Cell>
                <Table.Cell>
                  <Input
                    value={applicant.identityVerificationService?.answer}
                    type="text"
                    disabled
                  />
                </Table.Cell>
              </Table.Row>
            </React.Fragment>
          )}

          {[API_NAMES.NZ, API_NAMES.SG].includes(applicant.api) && (
            <Table.Row>
              <Table.Cell>
                <div style={{ display: "grid" }}>
                  <span>Street address</span>
                  <span>
                    This will be used to verify the client's ID on Calls/Chats
                  </span>
                </div>
              </Table.Cell>
              <Table.Cell>
                <Input value={applicant.streetAddress} type="text" disabled />
              </Table.Cell>
            </Table.Row>
          )}

          <Table.Row>
            <Table.Cell>Signature of Applicant</Table.Cell>
            <Table.Cell>
              <Input
                value={
                  applicant.signature ? applicant.signature : applicant.fullName
                }
                type="text"
                disabled
              />
            </Table.Cell>
          </Table.Row>
        </React.Fragment>
      }
    />
  );
};

export default ReviewCertificationSegment;
