
/**
 * 
 * @param {any} object 
 * @param {{addEmpty:boolean,optionValueFrom:'key'|'value',optionTextFrom:'key'|'value',optionKeyFrom:'key'|'value'}} options 
 */
export function getOptionsFromObject(object, options = {}) {
    const { addEmpty = false, optionValueFrom = 'key', optionTextFrom = 'value', optionKeyFrom = 'key' } = options
    const emptyOption = {
        text: '',
        value: null,
        key: -1
    }
    const selectOptions = Object.entries(object).map(([key, value]) => ({
        text: optionTextFrom === 'value' ? value : key,
        value: optionValueFrom === 'key' ? key : value,
        key: optionKeyFrom === 'key' ? key : value
    }))
    if (addEmpty) {
        return [emptyOption, ...selectOptions]
    }
    return selectOptions
}