import React, { useEffect, useState, useMemo } from "react";
import "./CategoryAndProductQAReport.styles.scss";
import CategoryAndProductChart from "./components/CategoryAndProductChart/CategoryAndProductChart.component";
import { isEmpty } from "lodash";

const CategoryAndProductQAReport = ({
  selectedDateReport,
  categoryFilter,
  productFilter,
  chartConfig,
}) => {
  const [chartData, setChartData] = useState({});
  const [chartDataSeparated, setChartDataSeparated] = useState({});
  const hasProductFilter = useMemo(
    () => JSON.stringify(productFilter) !== "{}",
    [productFilter],
  );
  const hasCategoryFilter = useMemo(
    () => JSON.stringify(categoryFilter) !== "{}",
    [categoryFilter],
  );

  useEffect(() => {
    if (selectedDateReport) {
      const chartData = selectedDateReport.reduce(
        (ret, { product, category, count, answer }) => {
          if (!ret[product]) ret[product] = {};

          if (categoryFilter[category]) {
            if (!ret[product][category]) ret[product][category] = [0, 0];

            if (answer) ret[product][category][0] += count;
            else ret[product][category][1] += count;
          }
          return ret;
        },
        {},
      );

      const chartDataSeparated = selectedDateReport.reduce(
        (ret, { product, category, count, answer, date }) => {
          if (isEmpty(ret[category])) ret[category] = { [product]: {} };

          if (categoryFilter[category]) {
            if (isEmpty(ret[category][product]))
              ret[category][product] = { positive: [], negative: [] };

            if (answer) {
              ret[category][product].positive = [
                ...ret[category][product].positive,
                ...[{ count, date }],
              ];
            } else {
              ret[category][product].negative = [
                ...ret[category][product].negative,
                ...[{ count, date }],
              ];
            }
          }
          return ret;
        },
        {},
      );

      setChartDataSeparated(chartDataSeparated);
      setChartData(chartData);
    }
  }, [selectedDateReport, categoryFilter]);

  return (
    <div className="cat-and-prod-qa-report">
      {hasProductFilter ? (
        <div className="cat-and-prod-qa-report__products">
          {Object.keys(productFilter).map((filter) => (
            <div
              className="cat-and-prod-qa-report__products__product"
              key={filter}
            >
              <h3>{filter.toUpperCase()}</h3>
              <div
                className={`cat-and-prod-qa-report__products__product__categories${
                  !hasCategoryFilter ? "--empty" : ""
                }`}
              >
                {hasCategoryFilter ? (
                  Object.keys(categoryFilter).map((category) => (
                    <CategoryAndProductChart
                      key={category + filter}
                      category={category}
                      chartConfig={chartConfig}
                      filter={filter}
                      categoryFilter={categoryFilter}
                      chartData={chartData}
                      chartDataSeparated={chartDataSeparated}
                    />
                  ))
                ) : (
                  <h4>No Category Selected</h4>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <h4>No Product Selected</h4>
      )}
    </div>
  );
};

export default CategoryAndProductQAReport;
