import React, { useState, useEffect, useRef } from "react";
import ConfirmPopup from "../../../../../../../../../components/ConfirmPopup";
import { generatePassportAppointmentOptionsApi } from "../../../../../../../../../apis/applicantApi";
import {
  Segment,
  Button,
  HeaderContent,
  Header,
  PlaceholderParagraph,
  PlaceholderLine,
  PlaceholderHeader,
  Placeholder,
  Message,
  Icon,
  Transition,
} from "semantic-ui-react";
import LoadingModalContent from "./common/LoadingModalContent";
import GeneralModalMessage from "./common/GeneralModalMessage";
import { VALIDATION_TYPES } from "../../../../../../../../../constants/applicationValidation";
import { sanitize } from "dompurify";

const PassportAppointmentOptions = ({ applicantId, api }) => {
  const [content, setContent] = useState(null);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [optionsHtml, setOptionsHtml] = useState("");
  const optionsHtmlRef = useRef("");
  const baseErrorMessage =
    "We could not retrieve the passport appointment options for this application";

  const handleCopyClick = () => {
    if (optionsHtmlRef.current) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(optionsHtmlRef.current);
      selection.removeAllRanges();
      selection.addRange(range);
      try {
        document.execCommand("copy");
        setCopiedToClipboard(true);
      } catch (err) {
        console.error("Failed to copy:", err);
      }
      // Clear selection if you do not want it to remain highlighted
      selection.removeAllRanges();
    }
  };

  const generatePassportAppointmentOptions = async () => {
    setContent(defaultContent);

    try {
      const {
        data: { textBlurb: htmlContent },
      } = await generatePassportAppointmentOptionsApi(applicantId, api);
      console.log({ htmlContent });

      setOptionsHtml(htmlContent);

      if (!htmlContent) {
        setContent(
          <GeneralModalMessage
            message={`${baseErrorMessage}`}
            type={VALIDATION_TYPES.ERROR}
          />,
        );
      }
    } catch (error) {
      console.log(error);
      setContent(
        <GeneralModalMessage
          message={`${baseErrorMessage}. Error: ${error.message}`}
          type={VALIDATION_TYPES.ERROR}
        />,
      );
    }
  };

  const defaultContent = (
    <Segment>
      <LoadingModalContent message="Generating Passport Appointment Options..." />

      <Placeholder fluid>
        <PlaceholderHeader image>
          <PlaceholderLine />
          <PlaceholderLine />
        </PlaceholderHeader>
        <PlaceholderParagraph>
          <PlaceholderLine />
          <PlaceholderLine />
          <PlaceholderLine />
        </PlaceholderParagraph>
      </Placeholder>
    </Segment>
  );

  const optionsContent = (
    <Segment>
      <Header as="h2" color="black">
        <HeaderContent>Available Passport Appointment Options</HeaderContent>
      </Header>
      <Header as="h4" color="grey">
        <HeaderContent>
          Click on the options to copy them to your clipboard.
        </HeaderContent>
      </Header>

      <Transition
        visible={copiedToClipboard}
        animation="fade up"
        duration={500}
      >
        <Message color="green" attached="bottom">
          <Icon name="check" />
          The appointment options have been copied to your clipboard.
        </Message>
      </Transition>

      <Segment
        raised
        color="green"
        size="large"
        style={{ cursor: "pointer" }}
        onClick={handleCopyClick}
      >
        <div
          ref={optionsHtmlRef}
          style={{ lineHeight: 2 }}
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{ __html: sanitize(optionsHtml) }}
        />
      </Segment>
    </Segment>
  );

  // Automatically clear the copiedToClipboard state after 3 seconds to hide the success message.
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setContent(optionsContent);
    if (copiedToClipboard) {
      const timer = setTimeout(() => {
        setCopiedToClipboard(false);
        setContent(optionsContent);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [copiedToClipboard]);

  // Update refs whenever state changes
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    optionsHtmlRef.current = optionsHtml;
    setContent(optionsContent);
  }, [optionsHtml]);

  return (
    <ConfirmPopup
      onOpen={generatePassportAppointmentOptions}
      content={content || defaultContent}
    >
      <Button>Generate Passport Appointment Options</Button>
    </ConfirmPopup>
  );
};

export default PassportAppointmentOptions;
