import axios from "../httpClient";

export function getUserAvailabilityReportApi(id, timeRange = {}) {
  return axios.get(`/report/availability/users/${id}`, {
    params: timeRange,
  });
}

export function getCurrentUserAvailabilityReportApi(timeRange = {}) {
  return axios.get("/report/availability", {
    params: timeRange,
  });
}

export function getUsersAvailabilityReportApi(timeRange = {}, userIds = "") {
  return axios.get("/report/availability/users", {
    params: { ...timeRange, userIds },
  });
}
