import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { generateSchema } from "../../../../helpers/schemaGenerator";

import "./ApplicantViewSegment.styles.scss";

const ApplicantViewSegment = ({
  initialOpen,
  data,
  label,
  hint,
  applicant,
  schemaData,
  changeState,
  errors,
  customData,
  openAllSection,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if(openAllSection) setOpen(true);
    else setOpen(false);
  },[openAllSection])

  useEffect(() => {
    if(initialOpen) setOpen(true)
  }, [initialOpen])

  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell colSpan="2">
          <h2
            className={`segment-toggle ${open ? "segment-toggle--open" : ""}`}
            onClick={() => setOpen((prev) => !prev)}
          >
            {label}
          </h2>
        </Table.Cell>
      </Table.Row>
      {open && (
        <React.Fragment>
       {hint && <Table.Row>
        <Table.Cell colSpan="2">
        {hint}
        </Table.Cell>
          </Table.Row>}
          {customData
            ? customData
            : generateSchema(data, applicant, schemaData, changeState, errors)}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ApplicantViewSegment;
