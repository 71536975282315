import React from "react";
import "./ApplicantNotes.styles.scss";
import CommentBox from "./_components/CommentBox.component";

const ApplicantNotes = ({ api, editingApplicant, setPinnedNote }) => {
  return (
    <div className="appn-container">
      <div className="appn-actions-container">
        <CommentBox api={api} editingApplicant={editingApplicant} setPinnedNote={setPinnedNote} />
      </div>
    </div>
  );
};

export default ApplicantNotes;
