import React from "react";
import { QATicketTable, QAReportPage } from "./components";
import { QA_REPORT_FOR } from "../../constants/qaReports";
import ManagerAvailabilityReport from "./components/ManagerAvailabilityReport";
import MenuTabs from "../../../../../../../components/MenuTabs";
import DisputesTable from "../components/DisputesTable";

const tabs = [
  {
    name: "Disputes",
    component: <DisputesTable disableActions />,
    route: "disputes",
  },
  {
    name: "Quality",
    component: <QATicketTable />,
    route: "evaluations",
  },
  {
    name: "Report",
    component: <QAReportPage type={QA_REPORT_FOR.MANAGER} />,
    route: "report",
  },
  {
    name: "Reports availability",
    component: <ManagerAvailabilityReport />,
    route: "availability",
  },
];

class ManagerArea extends React.Component {
  render() {
    return (
      <MenuTabs
        {...this.props}
        tabs={tabs}
        defaultRoute="my-account/manager"
        pathParam="subRoute"
      />
    );
  }
}

export default ManagerArea;
