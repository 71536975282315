import React from "react";
import { Button } from "semantic-ui-react";
import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";
import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../../constants/endpoints";
import ZendeskIcon from "../../../../../../../../components/Icons/ZendeskIcon/ZendeskIcon";
import { get } from "lodash";

export const TSA_SELECT_VALUES = {
    GENDER: "GENDER",
    SUFFIXES: "SUFFIXES",
    COUNTRIES: "COUNTRIES",
    COUNTRIES_WITH_FLAGS: "COUNTRIES_WITH_FLAGS",
    PREFERRED_LANUAGE: "PREFERRED_LANUAGE",
    METHOD_OF_CONTACT: "METHOD_OF_CONTACT",
    HAIR_COLOR: "HAIR_COLOR",
    EYE_COLOR: "EYE_COLOR",
    PHOTO_ID: "PHOTO_ID",
    DOCUMENTS_REQUIRED: "DOCUMENTS_REQUIRED",
    CITIZENSHIP_DOCUMENT: "CITIZENSHIP_DOCUMENT",
    CITIZENSHIP_DOCUMENT_AND_VISA: "CITIZENSHIP_DOCUMENT_AND_VISA",
    COUNTRY_OF_CITIZENSHIP: "COUNTRY_OF_CITIZENSHIP",
    PHONE_CODES: "PHONE_CODES",
    STATE_OF_BIRTH: "STATE_OF_BIRTH" ,
    MEASURE_STANDARDS: "MEASURE_STANDARDS",
    NON_IMMIGRANT_VISA: "NON_IMMIGRANT_VISA",
    ADDITIONAL_DOCUMENT: "ADDITIONAL_DOCUMENT",
    TEXT_ALERTS_PHONE: "TEXT_ALERTS_PHONE",
    CANADA_STATES: "CANADA_STATES",
    MEXICO_STATES: "MEXICO_STATES",
    USA_STATES: "USA_STATES",
    SPECIFIC_COUNTRIES_LIST: "SPECIFIC_COUNTRIES_LIST",
    PHONE_USED: "PHONE_USED",
    COUNTRY_CODES: "COUNTRY_CODES",
    APPOINTMENT_TIMES: "APPOINTMENT_TIMES",
    ENROLLMENT_CENTERS: "ENROLLMENT_CENTERS",
    DOCUMENTS_REQUIRED_US_CITIZEN: "DOCUMENTS_REQUIRED_US_CITIZEN",
    DOCUMENTS_REQUIRED_NON_US_CITIZEN: "DOCUMENTS_REQUIRED_NON_US_CITIZEN",
    CITIZENSHIP_DOCUMENT_US_CITIZEN: "CITIZENSHIP_DOCUMENT_US_CITIZEN",
    SPECIFIC_APPOINTMENT_TIME: "SPECIFIC_APPOINTMENT_TIME",
};

const AddressSchema = (path) => ({ 
  country:{
      label: "Country",
      type: TYPES.SELECT,
      values: TSA_SELECT_VALUES.COUNTRIES,
      path,
  },
  addressLine1: {
    label: "Address Line 1",
    type: TYPES.TEXT,
    path,
  },
  addressLine2: {
    label: "Address Line 2",
    type: TYPES.TEXT,
    path,
  },
  city:{
      label: "City",
      type: TYPES.TEXT,
      path,
  },
  state: {
    label: "State",
    type: TYPES.SELECT,
    values: (applicant) => {
                switch (get(applicant, `${path}.country`)) {
                    case "CD": // Canada
                        return TSA_SELECT_VALUES.CANADA_STATES;
                    case "US": // United States
                        return TSA_SELECT_VALUES.USA_STATES;
                    case "MM": // Mexico
                        return TSA_SELECT_VALUES.MEXICO_STATES;
                    default:
                        return [];
                }
            },
    path,
    customDependency: (applicant, path) => {
      const country = get(applicant, `${path}.country`);
      if (
        ["US", "MM", "CD"].includes(
          country,
        )
      )
        return true;
    },
  },
  postalCode:{
      label: "Zip Code",
      type: TYPES.TEXT,
      path,
  },
});

export const tsaSchema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      applicationType: {
        label: "Application Type",
      },
      mainApplicationId: {
        label: "Main Application ID",
        dependsOn: "applicationType",
        dependantOnValues: ["Secondary"],
      },
      siteId: {
        label: "Referral Site",
      },
      cartSiteId: {
        label: "Purchased from website",
      },
      orderId: {
        label: "Order ID",
      },
      orderIdHistory: {
        label: "Order ID history",
      },
      tripsApplicationNumbers: {
        label: "Trips application numbers",
        type: TYPES.MULTI_TEXT,
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
        renderIcon: (propValue) => (
          <Button
            onClick={() => {
              window.open(
                TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(propValue),
                "_blank",
              );
            }}
            style={{ width: 36, height: 36, padding: 0, float: "right" }}
            disabled={!propValue}
          >
            <ZendeskIcon className="zendesk-icon" />
          </Button>
        ),
      },
      submissionEmail: {
        label: "Submission email",
        type: TYPES.TEXT,
      },
      submissionPassword: {
        label: "Submission password",
        type: TYPES.TEXT,
      },
      gclid: {
        label: "Google client ID",
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      salesAgent: {
        label: "Sales Agent",
      },
      salesCoupon: {
        label: "Sales Coupon",
      },
    },
  },
  applicantInfoSegment: {
    label: "Applicant information",
    type: TYPES.SEGMENT,
    data: {
      firstName: {
        label: "First name",
        type: TYPES.TEXT,
      },
      middleName: {
        label: "Middle name",
        type: TYPES.TEXT,
      },
      lastName: {
        label: "Family name",
        type: TYPES.TEXT,
      },
      userEmail: {
        label: "User email address",
        type: TYPES.TEXT,
      },
      suffix: {
        label:"Suffix",
        type:TYPES.SELECT,
        values: TSA_SELECT_VALUES.SUFFIXES,
      },
      gender: {
        label: "Gender",
        type: TYPES.SELECT,
        values: TSA_SELECT_VALUES.GENDER,
      },
      birthDate: {
        label: "Birth date",
        type: TYPES.DATE,
      },
      preferredLanguage: {
        label:"Preferred Language",
        type:TYPES.SELECT,
        values:TSA_SELECT_VALUES.PREFERRED_LANUAGE
      },
      textAlerts: {
        label:"Sign up for Text Alerts",
        type:TYPES.CHECKBOX,
        default: [false]
      },
      textAlertsPhone:{
        label:"Which phone would you like to receive text alerts on?",
        type:TYPES.SELECT,
        values:TSA_SELECT_VALUES.PHONE_USED,
        dependsOn:"textAlerts",
        dependantOnValues:[true]
      },
      telephoneNumberCountryCode: {
        label: "Country Code",
        type: TYPES.SELECT,
        values: TSA_SELECT_VALUES.PHONE_CODES,
        valuesText: "phone",
        value: "value"
      },
      telephoneNumber: {
        label: "Phone 1",
        type: TYPES.TEXT,
      },
      secondTelephoneNumberCountryCode: {
        label: "Country Code",
        type: TYPES.SELECT,
        values: TSA_SELECT_VALUES.PHONE_CODES,
        text: "phone",
        value: "value"
      },
      secondTelephoneNumber: {
        label: "Phone 2",
        type: TYPES.TEXT,
      },
      preferredContactMethod:{
        label:"Preferred Method of Contact",
        type: TYPES.SELECT,
        values: TSA_SELECT_VALUES.METHOD_OF_CONTACT,
        default: "EMAIL"
      },
      hasUsedPreviousName: {
        label: "Have you ever used a maiden/previous name?",
        type: TYPES.CHECKBOX,
      },
      maidenNames: {
        label: "Maiden/Previous Name",
        type: TYPES.ARRAY,
        dependsOn: "hasUsedPreviousName",
        dependantOnValues: [true],
        //arrayLabel: "Applicant Number",
        data: {
          firstName: {
            label: "First name",
            type: TYPES.TEXT,
            path: "maidenNames.$maidenNames",
          },
          middleName: {
            label: "Middle name",
            type: TYPES.TEXT,
            path: "maidenNames.$maidenNames",
          },
          lastName: {
            label: "Last name",
            type: TYPES.TEXT,
            path: "maidenNames.$maidenNames",
          },
          suffix: {
            label: "Suffix",
            type: TYPES.SELECT,
            values: TSA_SELECT_VALUES.SUFFIXES,
            path: "maidenNames.$maidenNames",
          }
        },
      },
      hasUsedAlias: {
        label: "Have you ever used an alias",
        type: TYPES.CHECKBOX,
      },
      aliases: {
        label: "Alias",
        type: TYPES.ARRAY,
        dependsOn: "hasUsedAlias",
        dependantOnValues: [true],
        //arrayLabel: "Applicant Number",
        data: {
          firstName: {
            label: "First name",
            type: TYPES.TEXT,
            path: "aliases.$aliases",
          },
          middleName: {
            label: "Middle name",
            type: TYPES.TEXT,
            path: "aliases.$aliases",
          },
          lastName: {
            label: "Last name",
            type: TYPES.TEXT,
            path: "aliases.$aliases",
          },
          suffix: {
            label: "Suffix",
            type: TYPES.SELECT,
            values: TSA_SELECT_VALUES.SUFFIXES,
            path: "aliases.$aliases",
          }
        },
      },
      measures: {
        label: "Measures",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          standard: {
            label: "Standard",
            type: TYPES.SELECT,
            values: TSA_SELECT_VALUES.MEASURE_STANDARDS,
            path: "measures",
          },
          feets: {
            label: "Feets",
            type: TYPES.TEXT,
            dependsOn: "measures.standard",
            path: "measures",
            dependantOnValues: ["US"],
          },
          inches: {
            label: "Inches",
            type: TYPES.TEXT,
            dependsOn: "measures.standard",
            path: "measures",
            dependantOnValues: ["US"],
          },
          pounds: {
            label: "Pounds",
            type: TYPES.TEXT,
            dependsOn: "measures.standard",
            path: "measures",
            dependantOnValues: ["US"],
          },
          centimeters: {
            label: "Centimeters",
            type: TYPES.TEXT,
            dependsOn: "measures.standard",
            path: "measures",
            dependantOnValues: ["METRIC"],
          },
          kilograms:{
            label: "Kilograms",
            type: TYPES.TEXT,
            dependsOn: "measures.standard",
            path: "measures",
            dependantOnValues: ["METRIC"],
          }
        },
      },
      hairColor: {
        label:"Hair color",
        type: TYPES.SELECT,
        values: TSA_SELECT_VALUES.HAIR_COLOR,
      },
      eyeColor: {
        label:"Eye color",
        type:TYPES.SELECT,
        values: TSA_SELECT_VALUES.EYE_COLOR,
      },
    },
  },
  addressInformation: {
    label: "Address Information",
    type: TYPES.SEGMENT,
    data: {
      birthCountry: {
        label: "Birth country",
        type: TYPES.SELECT,
        values: TSA_SELECT_VALUES.COUNTRIES,
      },
      birthCity: {
        label: "City of birth",
        type: TYPES.TEXT,
      },
      stateOfBirth: {
        label: "State/Province of Birth",
        type: TYPES.SELECT,
        values: (applicant) => {
            switch (applicant.birthCountry) {
                case "CD": // Canada
                    return TSA_SELECT_VALUES.CANADA_STATES;
                case "US": // United States
                    return TSA_SELECT_VALUES.USA_STATES;
                case "MM": // Mexico
                    return TSA_SELECT_VALUES.MEXICO_STATES;
                default:
                    return [];
            }
        },
        dependsOn: "birthCountry",
        customDependency: (applicant) => {
            return ["CD", "US", "MM"].includes(applicant.birthCountry);
        },
    },        
      citizenshipCountry: {
        label:"Country of Citizenship",
        type: TYPES.SELECT,
        values: TSA_SELECT_VALUES.COUNTRIES,
      },
      nonImigrantVisaOrStatus: {
        label: "Non-Immigrant Visa or Status",
        type: TYPES.SELECT,
        values: TSA_SELECT_VALUES.NON_IMMIGRANT_VISA,
        dependsOn:"citizenshipCountry",
        customDependency: (applicant) => {
          return applicant.citizenshipCountry !== "US";
      },
      },
      isMailngAddressSameAsResidental: {
        label: "Is your mailing address the Is your mailing address the same as your residential address?",
        type: TYPES.CHECKBOX,
      },
      haveLivedAtCurrentResidental: {
        label: "Have you lived at your current residental address for more than five (5) year?",
        type: TYPES.CHECKBOX,
      },
      mailingAddress: {
        label: "Mailing address",
        type: TYPES.NESTED,
        newTable: true,
        nested: AddressSchema("mailingAddress", TSA_SELECT_VALUES.COUNTRIES),
      },
      residentialAddress: {
        label: "Residential address",
        type: TYPES.NESTED,
        newTable: true,
        nested: AddressSchema("residentialAddress", TSA_SELECT_VALUES.COUNTRIES),
        dependsOn: "isMailngAddressSameAsResidental",
        dependantOnValues: [false]
      },
      previousAddress: {
        label: "Previous address",
        type: TYPES.NESTED,
        newTable: true,
        nested: AddressSchema("previousAddress", TSA_SELECT_VALUES.COUNTRIES),
        dependsOn: "haveLivedAtCurrentResidental",
        dependantOnValues: [false]
      },
    }
  },
  programEligibilityQuestions: {
    label: "Program eligibility questions",
    type: TYPES.SEGMENT,
    data: {
    isUsCitizen: {
      label: "Are you a U.S. citizen, U.S. National or Lawful Permanent Resident (LPR)?",
      type: TYPES.CHECKBOX,
    },
    haveBeenConvictedPartA: {
      label: "Excluding cases adjudicated under juvenile offender provisions, have you been convicted, pled guilty including “no contest” (nolo contendere), found not competent to stand trial, or found not guilty by reason of insanity, of any disqualifying felony listed in TSA Eligibility Requirements, Part A, in any jurisdiction, military or civilian?",
      type: TYPES.CHECKBOX
    },
    haveBeenConvictedPartB: {
      label: "Excluding cases adjudicated under juvenile offender provisions, have you been convicted, pled guilty including “no contest” (nolo contendere), found not competent to stand trial, or found not guilty by reason of insanity, of any disqualifying felony listed in TSA Eligibility Requirements, Part B in any jurisdiction, military or civilian, during the 7 years before the date of this application?",
      type: TYPES.CHECKBOX
    },
    hasCommitedFelony: {
      label: "Have you been released from incarceration in any jurisdiction, military or civilian, for committing any disqualifying felony listed in TSA Eligibility Requirements, Part B, during the 5 years before the date of this application?",
      type: TYPES.CHECKBOX
    },
    isUnderIndictment: {
      label: "Are you wanted or under indictment for any disqualifying crime listed in TSA Eligibility Requirements, Parts A or B?",
      type: TYPES.CHECKBOX
    },
    hasMentallIllness: {
      label: "Within the past 7 years, has a court, board, commission, or other government authority determined that you, as a result of mental illness, pose a danger to yourself or to others, or that you lack the capacity to conduct or manage your own affairs or have you been involuntarily committed to an inpatient facility for mental health or psychiatric reasons?",
      type: TYPES.CHECKBOX
    },
  },
},
  documentsRequiredForEnrollment: {
    label:"Documents required for  enrollment",
    type: TYPES.SEGMENT,
    data:{
      photoID: {
        label: "Photo ID (Must match your current legal name)",
        type: TYPES.SELECT,
        values: (applicant) => {
          if (applicant.citizenshipCountry === 'US') {
            return TSA_SELECT_VALUES.DOCUMENTS_REQUIRED_US_CITIZEN;
          } else {
            return TSA_SELECT_VALUES.DOCUMENTS_REQUIRED_NON_US_CITIZEN;
          }
        },
      },
      citizenshipDocument: {
        label: "Citizenship/Immigration Document",
        type: TYPES.SELECT,
        values: (applicant) => {
          if (applicant.citizenshipCountry === 'US') {
            return TSA_SELECT_VALUES.CITIZENSHIP_DOCUMENT_US_CITIZEN;
          } else if (['IDF', 'DC', 'DL', 'DLU', 'FP', 'ID', 'MI', 'MIR', 'TW'].includes(applicant.photoID)) {
            return TSA_SELECT_VALUES.CITIZENSHIP_DOCUMENT_AND_VISA;
          } else {
            return TSA_SELECT_VALUES.CITIZENSHIP_DOCUMENT;
          }
        },
        dependsOn: "photoID",
        dependantOnValues: ["IDF", "DC", "DL", "DLU", "FP", "ID", "MI", "MIR", "TW", "TD", "CR", "ID"],
      },
      additionalDocument: {
        label: "Additional document",
        type: TYPES.SELECT,
        values: TSA_SELECT_VALUES.CITIZENSHIP_DOCUMENT_AND_VISA,
        dependsOn:"citizenshipDocument",
        dependantOnValues: ["FP"], 
      },
      photoIdData: {
        type: TYPES.NESTED,
              newTable: true,
              dependsOn:"photoID",
              dependantOnValues: ["IDF", "DC", "DL", "DLU", "FP", "ID", "MI", "MIR", "TW", "TD", "CR", "ID"],
              nested:{
                firstName: {
                  label: "First Name",
                  type: TYPES.TEXT,
                  path: "photoIdData",
                },
                middleName: {
                  label: "Middle Name",
                  type: TYPES.TEXT,
                  path: "photoIdData",
                },
                lastName: {
                  label: "Last Name",
                  type: TYPES.TEXT,
                  path: "photoIdData",
                },         
          },
      },
      citizenshipDocumentData: {
        type: TYPES.NESTED,
              newTable: true,
              dependsOn:"photoID",
              dependantOnValues: ["IDF", "DC", "DL", "DLU", "FP", "ID", "MI", "MIR", "TW", "TD", "CR", "ID"],
              nested:{
                firstName: {
                  label: "First Name",
                  type: TYPES.TEXT,
                  path: "citizenshipDocumentData",
                },
                middleName: {
                  label: "Middle Name",
                  type: TYPES.TEXT,
                  path: "citizenshipDocumentData",
                },
                lastName: {
                  label: "Last Name",
                  type: TYPES.TEXT,
                  path: "citizenshipDocumentData",
                },         
          },
      },
  },
},
appointmentSchedule: {
  label:"Create an appointment",
  type: TYPES.SEGMENT,
  data: {
    appointmentInformation: {
      label: 'Appointment Information',
      type: TYPES.NESTED,
      newTable: true,
      dependsOn: "walkIn",
        dependantOnValues: [false],
      nested: {  
        appointmentDate: {
        label: "Appointment date",
        type: TYPES.DATE,
        path: "appointmentInformation"
      },
      appointmentTime: {
        label: "Appointment time",
        type: TYPES.SELECT,
        values: TSA_SELECT_VALUES.APPOINTMENT_TIMES,
        path: "appointmentInformation"
      },
      appointmentTimeByAgent: {
        label: "Appointment time selected by agent",
        type: TYPES.SELECT,
        values: TSA_SELECT_VALUES.SPECIFIC_APPOINTMENT_TIME,
        path: "appointmentInformation"
      }
    },
  },
  appointmentLocation: {
    label: "Appointment location",
    type: TYPES.SELECT,
    values: TSA_SELECT_VALUES.ENROLLMENT_CENTERS,
    objectAsValue: true,
    text: 'name',
  },         
  walkIn: {
    label: "Walk in",
    type: TYPES.CHECKBOX
  }
  }
}
    
};
