import axios from "../httpClient";

function getCalls(params) {
  return axios.get(`/twilio/call`, { params });
}

function exportCallsToGoogleSheet(params) {
  return axios.get(`/twilio/call/export`, { params });
}

export default {
  getCalls,
  exportCallsToGoogleSheet,
};
