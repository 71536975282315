import React, { useMemo, useCallback } from "react";
import { filter } from "rxjs/operators";
import { SOCKET_EVENTS } from "../../../../../../../../constants/socketEvents";

import { Button, Icon, Input, Select, Popup } from "semantic-ui-react";
import {
  PAYMENT_STATUSES,
  ZENDESK_VIEW_PAYMENT_STATUSES,
} from "../../../constants/applicantStatuses";
import toastr from "toastr";
import {
  continueApplicantApi,
  runBotApi,
  removeBotRunApi,
  changeApplicantStatusToPaymentFailed,
  runEmbassyBotApi,
  removeEmbassyBotRunApi,
  runPaymentBotApi,
} from "../../../../../../../../apis/applicantApi";
import SmallConfirmPopup from "../../../../../../../../components/SmallConfirmPopup";
import API_TABLE_DATA from "../../../constants/tableData";
import { useCallbackRef } from "../../../../../../../../hooks/useCallbackRef.hook.js";
import {
  useFilters,
  notEmptyValidator,
} from "../../../../../../../../hooks/useFilters.hook.js";
import { EVISA_APPLICANT_STATUSES } from "../../constants/applicantStatuses";
import { getOptionsFromObject } from "../../../../../../../../common/select-helper.js";
import { useSubscription } from "../../../../../../../../hooks/useSubscription.hook.js";
import socketService from "../../../../../../../../services/socketService.js";
import { logServerError } from "../../../../../../../../common/logs.js";
import ApplicantsTable from "../../../components/ApplicantsTable";
import { API_NAMES } from "../../constants/apiNames";
import ApplicantErrors from "../../../components/ApplicantErrors/ApplicantErrors.component.jsx";
import { PRODUCT_NAMES_WITH_PAYMENTS_BOTS } from "../../../constants/productNames.js";

const VisaApplicantsTable = ({ api }) => {
  const zendeskView = window.location.href.includes(`zendesk/${api}`);
  const [applicantsTable, applicantsTableRef] = useCallbackRef();
  const { filters, normalizedFilters, setFormFilter } = useFilters(
    {
      searchString: "",
      paymentStatus: zendeskView
        ? PAYMENT_STATUSES.Captured
        : [PAYMENT_STATUSES.Captured],
      status: [],
    },
    {
      localStorageKey: `${api}-table`,
      filterValidators: {
        searchString: notEmptyValidator(),
      },
      filterDebounces: {
        searchString: 700,
      },
      temporaryFilters: ["searchString"],
    },
  );

  const filtersRendered = useMemo(() => {
    if (!applicantsTable) {
      return null;
    }
    const { searchString, paymentStatus, status } = filters;
    return (
      <div className="filters-container">
        <Select
          placeholder="Select status"
          options={getOptionsFromObject(EVISA_APPLICANT_STATUSES[api], {
            optionTextFrom: "key",
            optionValueFrom: "value",
          })}
          name="status"
          multiple
          value={status || []}
          onChange={setFormFilter}
        />
        <Select
          placeholder="Select payment status"
          options={getOptionsFromObject(
            zendeskView ? ZENDESK_VIEW_PAYMENT_STATUSES : PAYMENT_STATUSES,
            {
              optionTextFrom: "key",
              optionValueFrom: "value",
            },
          )}
          multiple={!zendeskView}
          name="paymentStatus"
          value={paymentStatus === undefined ? [] : paymentStatus}
          onChange={setFormFilter}
        />
        <Input
          value={searchString || ""}
          type="text"
          name="searchString"
          placeholder="Search"
          onChange={setFormFilter}
        />
        <Button onClick={applicantsTable.refreshItems}>
          {applicantsTable.isLoading ? "Loading.." : "Refresh"}
        </Button>
      </div>
    );
  }, [applicantsTable, filters, api, setFormFilter, zendeskView]);

  useSubscription(
    socketService.socketSubject,
    useCallback(
      (event) => {
        if (!applicantsTable) {
          return;
        }
        const { setApplicants } = applicantsTable;
        const {
          data: { applicantId, botError },
        } = event;

        setApplicants((applicants) =>
          applicants
            ? applicants.map((applicant) =>
                applicant._id !== applicantId
                  ? applicant
                  : {
                      ...applicant,
                      errors: [botError, ...applicant.errors],
                    },
              )
            : null,
        );
        toastr.error("Bot returned error!", "Error");
      },
      [applicantsTable],
    ),
    [filter((event) => event.type === SOCKET_EVENTS[api].BOT_ERROR)],
  );

  const botRunHandler = useCallback(
    async (applicantId) => {
      try {
        await runBotApi(applicantId, api);
      } catch (err) {
        logServerError(err);
      }
    },
    [api],
  );

  const botRunPaymentHandler = useCallback(
    async (applicantId) => {
      try {
        await runPaymentBotApi(applicantId, api);
      } catch (err) {
        logServerError(err);
      }
    },
    [api],
  );

  const botRunRemoveHandler = useCallback(
    async (applicantId) => {
      try {
        await removeBotRunApi(applicantId, api);
      } catch (err) {
        logServerError(err);
      }
    },
    [api],
  );

  const botRunEmbassyHandler = useCallback(
    async (applicantId) => {
      try {
        await runEmbassyBotApi(applicantId, api);
      } catch (err) {
        logServerError(err);
      }
    },
    [api],
  );

  const botRunRemoveEmbassyHandler = useCallback(
    async (applicantId) => {
      try {
        await removeEmbassyBotRunApi(applicantId, api);
      } catch (err) {
        logServerError(err);
      }
    },
    [api],
  );

  const botContinueHandler = useCallback(
    async (applicantId) => {
      try {
        await continueApplicantApi(applicantId, api);
      } catch (err) {
        logServerError(err);
      }
    },
    [api],
  );

  const changeStatusToPaymentFailedHandler = useCallback(
    async (applicantId) => {
      try {
        await changeApplicantStatusToPaymentFailed(applicantId, api);
      } catch (err) {
        logServerError(err);
      }
    },
    [api],
  );
  const additionalActions = useCallback(
    (applicant) => {
      const { _id, status, applicantNumber } =
        applicant;

      let buttons = null;

      const changeStatusToPaymentFailed = [API_NAMES.ESTA, API_NAMES.VN].includes(api) &&
        !["Approved", "Rejected"].includes(status) && (
          <Popup
          content={`Change status to "Payment failed"`}
          trigger={
            <span>
              <SmallConfirmPopup
                header={`Are you sure you want to change status to "Payment failed"?`}
                callback={() => {}}
                content={
                  <div>
                    <Button
                      onClick={() => changeStatusToPaymentFailedHandler(_id)}
                      negative
                      floated="right"
                      content="Change"
                    />
                  </div>
                }
              >
                <Button color="orange" size="mini" icon>
                  <Icon name="warning" />
                </Button>
              </SmallConfirmPopup>
            </span>
          }
          />
        );
      const continueApplicantButton = ((api === API_NAMES.DS160 &&
        ![
          "Submitted",
          "Embassy bot in queue",
          "Embassy bot returned error",
          "Embassy bot in progress",
        ].includes(status) &&
        applicantNumber) ||
        (((status === "Payment failed - will retry payment" &&
          applicantNumber) ||
          status === "Bot returned error" ||
          status === "Pending submission") &&
          API_NAMES.ESTA === api) ||
          (status === "Payment failed - will retry payment" && API_NAMES.VN === api)) && (
            <Popup
            content={"Continue submitting the application"}
            trigger={
              <span>
                <SmallConfirmPopup
                  header={"Are you sure you want to continue processing the applicant?"}
                  callback={() => {}}
                  content={
                    <div>
                      <Button
                        onClick={() => botContinueHandler(_id)}
                        positive
                        floated="right"
                        content="Start"
                      />
                    </div>
                  }
                >
                  <Button color="yellow" size="mini" disabled={api === API_NAMES.ESTA && applicant.submittedToIvisa ? true : false} icon>
                    <Icon name="angle double right" />
                  </Button>
                </SmallConfirmPopup>
              </span>
            }
            />
      );
     
      const embassyBotRun = api === API_NAMES.DS160 &&
        (status === "Submitted" || status === "Embassy bot returned error") &&
        ["A", "B"].includes(applicant.visaType) && (
          <SmallConfirmPopup
            header={
              "Are you sure you want to create Embassy account for this applicant?"
            }
            callback={() => {}}
            content={
              <div>
                <Button
                  onClick={() => botRunEmbassyHandler(_id)}
                  positive
                  floated="right"
                  content="Start"
                />
              </div>
            }
          >
            <Button color="blue" size="mini" icon>
              <Icon name="play circle" />
            </Button>
          </SmallConfirmPopup>
        );

      const removeBotRunHandler = (
        <Popup
        content={"Remove bot from the queue"}
        trigger={
          <span>
            <SmallConfirmPopup
              header={
                "Are you sure you want to remove this application from queue?"
              }
              callback={() => {}}
              content={
                <div>
                  <Button
                    onClick={() => botRunRemoveHandler(_id)}
                    positive
                    floated="right"
                    content="Remove"
                  />
                </div>
              }
            >
              <Button color="purple" size="mini" icon>
                <Icon name="clipboard" />
              </Button>
            </SmallConfirmPopup>
          </span>
        }
        />
      );

      const runPaymentBot = Object.keys(PRODUCT_NAMES_WITH_PAYMENTS_BOTS).includes(api)  &&
      ["Submitted", "Processed manually", "Payment failed"].includes(status) && (
        <Popup
          content={"Start the payment bot"}
          trigger={
            <span>
              <SmallConfirmPopup
                header={"Are you sure you want to run the payment bot?"}
                callback={() => {}}
                content={
                  <div>
                    <Button
                      onClick={() => botRunPaymentHandler(_id)}
                      positive
                      floated="right"
                      content="Start"
                    />
                  </div>
                }
              >
                <Button color="blue" size="mini" icon>
                  <Icon name="credit card outlined" />
                </Button>
              </SmallConfirmPopup>
            </span>
          }
        />
      );

      switch (status) {
        case "Pending submission":
          buttons = (
            <React.Fragment>
              {buttons}
                <Popup
                content={"Start a new application"}
                trigger={
                  <span>
                    <SmallConfirmPopup
                      header={"Are you sure you want to run the bot?"}
                      callback={() => {}}
                      content={
                        <div>
                          <Button
                            onClick={() => botRunHandler(_id)}
                            positive
                            floated="right"
                            content="Start"
                          />
                        </div>
                      }
                    >
                      <Button color="green" size="mini" disabled={api === API_NAMES.ESTA && applicant.submittedToIvisa ? true : false} icon>
                        <Icon name="play" />
                      </Button>
                    </SmallConfirmPopup>
                  </span>
                }
                />
              {changeStatusToPaymentFailed}
              {continueApplicantButton}
              {removeBotRunHandler}
            </React.Fragment>
          );
          break;
        case "Bot in queue":
          buttons = (
            <React.Fragment>
              {buttons}
              {removeBotRunHandler}
            </React.Fragment>
          );
          break;
        case "Embassy bot in queue":
          buttons = (
            <React.Fragment>
              {buttons}
              <SmallConfirmPopup
                header={
                  "Are you sure you want to remove this application from queue?"
                }
                callback={() => {}}
                content={
                  <div>
                    <Button
                      onClick={() => botRunRemoveEmbassyHandler(_id)}
                      positive
                      floated="right"
                      content="Remove"
                    />
                  </div>
                }
              >
                <Button color="purple" size="mini" icon>
                  <Icon name="clipboard" />
                </Button>
              </SmallConfirmPopup>
            </React.Fragment>
          );
          break;
        case "Bot in progress":
          buttons = (
            <React.Fragment>
              {buttons}
              <Button color="green" size="mini" icon loading disabled>
                <Icon name="play" />
              </Button>
            </React.Fragment>
          );
          break;
        case "Embassy bot in progress":
          buttons = (
            <React.Fragment>
              {buttons}
              <Button color="blue" size="mini" icon loading disabled>
                <Icon name="play" />
              </Button>
            </React.Fragment>
          );
          break;
        case "Bot returned error":
          buttons = (
            <React.Fragment>
              {buttons}
              <Popup
              content={"Preview applicant errors"}
              trigger={
                <span>
                  <ApplicantErrors id={applicant.id} api={api} icon/>
                </span>
              }
              /> 
              {continueApplicantButton}
              {changeStatusToPaymentFailed}
              {removeBotRunHandler}
            </React.Fragment>
          );
          break;
        default:
          buttons = (
            <React.Fragment>
              {buttons}
              {continueApplicantButton}
              {changeStatusToPaymentFailed}
              {embassyBotRun}
              {runPaymentBot}
            </React.Fragment>
          );
          break;
      }
      return buttons;
    },
    [api, changeStatusToPaymentFailedHandler, botContinueHandler, botRunEmbassyHandler, botRunRemoveHandler, botRunPaymentHandler, botRunHandler, botRunRemoveEmbassyHandler],
  );

  const tableColumns = useMemo(() => {
    return zendeskView
      ? Object.fromEntries(
          Object.entries(API_TABLE_DATA[api].columns).filter(
            ([key]) =>
              ![
                "applicationType",
                "createdAt",
                ...(!Array.isArray(filters.paymentStatus)
                  ? ["paymentStatus"]
                  : []),
              ].includes(key),
          ),
        )
      : API_TABLE_DATA[api].columns;
  }, [api, filters.paymentStatus, zendeskView]);

  return (
    <ApplicantsTable
      api={api}
      normalizedFilters={normalizedFilters}
      filtersRendered={filtersRendered}
      ref={applicantsTableRef}
      tableColumns={tableColumns}
      additionalActions={additionalActions}
      zendeskView={zendeskView}
    />
  );
};

export default VisaApplicantsTable;
