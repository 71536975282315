import React from "react";
import ConfirmPopup from "../../../../../../../../../../components/ConfirmPopup";
import { Modal, Button } from "semantic-ui-react";
import { getDateFormat } from "../../../../../../../../../../common/date-format";

const EmbassyDocumentHistory = (props) => {
    const { logs } = props;
    if (!logs || !logs.length) {
      return null;
    }
    return (
      <ConfirmPopup
        content={
          <React.Fragment>
            <Modal.Header>Embassy document history</Modal.Header>
            <Modal.Content scrolling>
              <Modal.Description>
                {logs.map((log, index) => (
                  <React.Fragment key={index}>
                    <div style={{ display: "flex", justifyContent: "space-between"}}>
                      <span>{log.agent}</span>
                      <div>
                        <span>{log.status}</span>
                        <span>{log.statusText && `: ${log.statusText}`}</span>
                      </div>
                      <span>
                        {getDateFormat(log.createdAt)}
                      </span>
                    </div>
                    <hr />
                  </React.Fragment>
                ))}
              </Modal.Description>
            </Modal.Content>
          </React.Fragment>
        }
        cancelButton={null}
        callback={async () => {}}
      >
        <Button>Embassy document history</Button>
      </ConfirmPopup>
    );
}

export default EmbassyDocumentHistory;
