import React from "react";
import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";
import { Button } from "semantic-ui-react";
import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../../constants/endpoints";
import ZendeskIcon from "../../../../../../../../components/Icons/ZendeskIcon/ZendeskIcon";
import { get } from "lodash";
import VWP_COUNTRIES from "../../../../../../../../constants/vwpCountries";
import moment from "moment";

export const DS_SELECT_VALUES = {
  PAYER_TYPE: "PAYER_TYPE",
  RELATIVE_TYPE: "RELATIVE_TYPE",
  PASSPORT_TYPE: "PASSPORT_TYPE",
  ADDRESS_TYPE: "ADDRESS_TYPE",
  VISA_MAIN_TYPE: "VISA_MAIN_TYPE",
  VISA_SUBTYPE: "VISA_SUBTYPE",
  JOB_TYPE: "JOB_TYPE",
  PAYER_RELATION: "PAYER_RELATION",
  TRAVEL_RELATION: "TRAVEL_RELATION",
  CONTACT_RELATION: "CONTACT_RELATION",
  RELATIVE_STATUS: "RELATIVE_STATUS",
  MARITAL_STATUS: "MARITAL_STATUS",
  STATES: "STATES",
  TIME_LENGTH: "TIME_LENGTH",
  COUNTRIES_1: "COUNTRIES_1",
  COUNTRIES_2: "COUNTRIES_2",
  COUNTRIES_3: "COUNTRIES_3",
  COUNTRIES_4: "COUNTRIES_4",
  COUNTRIES_5: "COUNTRIES_5",
  COUNTRIES_6: "COUNTRIES_6",
  NATIONALITY_1: "NATIONALITY_1",
  NATIONALITY_2: "NATIONALITY_2",
  GENDER: "GENDER",
  SOCIAL_MEDIA: "SOCIAL_MEDIA",
  US_CONTACT_TYPE: "US_CONTACT_TYPE",
  PHONE_COUNTRIES: "PHONE_COUNTRIES",
  REASON_FOR_THE_TRIP: "REASON_FOR_THE_TRIP",
  TRIP_STAY_TYPE: "TRIP_STAY_TYPE",
};

const VISA_RELATIVE_OPTIONS = {
  1: "CHILD OF AN VISA CARRIER",
  2: "SPOUSE OF AN VISA CARRIER",
};

const CREW_MEMBER_OPTIONS = {
  0: "YES",
  1: "NO",
};

const jobsWithInfo = [
  "A",
  "AP",
  "B",
  "CM",
  "CS",
  "C",
  "ED",
  "EN",
  "G",
  "LP",
  "MH",
  "M",
  "NS",
  "PS",
  "RV",
  "R",
  "SS",
  "S",
  "O",
];

const StudentVisaAddressSchema = (path) => ({
  streetAddress: {
    label: "Street address",
    type: TYPES.TEXT,
    path,
  },
  hasStreetAddress2: {
    label: "Has street address 2",
    path,
    type: TYPES.CHECKBOX,
    required: false,
  },
  streetAddress2: {
    label: "Street address 2",
    type: TYPES.TEXT,
    path,
    dependsOn: `${path}.hasStreetAddress2`,
  },
  city: {
    label: "City",
    type: TYPES.TEXT,
    path,
  },
  state: {
    label: "US state",
    type: TYPES.SELECT,
    values: DS_SELECT_VALUES.STATES,
    path,
    geoLocationProps: ["zipCode", "city", "streetAddress", "streetAddress2"],
  },
  zipCode: {
    label: "Zip code",
    type: TYPES.TEXT,
    path,
  },
});

const USAddressSchema = (path, requiredOverride = {}) => ({
  stayType: {
    label: "Staying place",
    type: TYPES.SELECT,
    values: DS_SELECT_VALUES.TRIP_STAY_TYPE,
    path,
    customDependency: () => {
      if (path === "stayAddress") return true;
    },
  },
  hotelName: {
    label: "Hotel name",
    type: TYPES.TEXT,
    path,
    customDependency: (applicant) => {
      if (applicant?.stayAddress?.stayType === 2) return true;
    },
  },
  streetAddress: {
    label: "Street address",
    type: TYPES.TEXT,
    path,
  },
  hasStreetAddress2: {
    label: "Has street address 2",
    path,
    type: TYPES.CHECKBOX,
    required: false,
  },
  streetAddress2: {
    label: "Street address 2",
    type: TYPES.TEXT,
    path,
    dependsOn: `${path}.hasStreetAddress2`,
  },
  city: {
    label: "City",
    type: TYPES.TEXT,
    path,
  },
  state: {
    label: "US state",
    type: TYPES.SELECT,
    values: DS_SELECT_VALUES.STATES,
    path,
    geoLocationProps: ["zipCode", "city", "streetAddress", "streetAddress2"],
  },
  hasZipCode: {
    label: "Has zip code",
    path,
    type: TYPES.CHECKBOX,
    required: false,
  },
  zipCode: {
    label: "Zip code",
    type: TYPES.TEXT,
    path,
    dependsOn: `${path}.hasZipCode`,
  },
});

const AddressSchema = (path) => ({
  country: {
    label: "Country",
    type: TYPES.SELECT,
    required: true,
    values: DS_SELECT_VALUES.COUNTRIES_3,
    path,
    geoLocationProps: [
      "state",
      "zipCode",
      "city",
      "streetAddress",
      "streetAddress2",
    ],
  },
  streetAddress: {
    label: "Street address",
    type: TYPES.TEXT,
    path,
  },
  hasStreetAddress2: {
    label: "Has street address 2",
    path,
    type: TYPES.CHECKBOX,
    required: false,
  },
  streetAddress2: {
    label: "Street address 2",
    type: TYPES.TEXT,
    path,
    dependsOn: `${path}.hasStreetAddress2`,
  },
  city: {
    label: "City",
    type: TYPES.TEXT,
    path,
  },
  hasState: {
    label: "Has state",
    path,
    type: TYPES.CHECKBOX,
    required: false,
  },
  state: {
    label: "State",
    type: TYPES.TEXT,
    path,
    dependsOn: `${path}.hasState`,
  },
  hasZipCode: {
    label: "Has zip code",
    path,
    type: TYPES.CHECKBOX,
    required: false,
  },
  zipCode: {
    label: "Zip code",
    type: TYPES.TEXT,
    path,
    dependsOn: `${path}.hasZipCode`,
  },
});

export const dsSchema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      applicantNumber: {
        label: "Applicant number",
        customDependency: (applicant) => {
          if (applicant.status !== 18) return true;
        },
      },
      applicantNumberEditable: {
        label: "Applicant number",
        type: TYPES.NESTED,
        customDependency: (applicant) => {
          if (applicant.status === 18) return true;
        },
        nested: {
          applicantNumber: {
            type: TYPES.TEXT,
          },
        },
      },
      currentStage: {
        label: "Current bot stage",
      },
      isFreeReapplication: {
        label: "Is free reapplication",
      },
      serviceExtension: {
        label: "Service extension",
      },
      serviceExtensionAt: {
        label: "Service extended at",
        dependsOn: "serviceExtension",
      },
      applicationType: {
        label: "Application Type",
      },
      mainApplicationId: {
        label: "Main Application ID",
        dependsOn: "applicationType",
        dependantOnValues: ["Secondary"],
      },
      siteId: {
        label: "Referral Site",
      },
      cartSiteId: {
        label: "Purchased from website",
      },
      orderId: {
        label: "Order ID",
      },
      orderIdHistory:{
        label:"Order ID History"
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
        renderIcon: (propValue) => (
          <Button
            onClick={() => {
              window.open(
                TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(propValue),
                "_blank",
              );
            }}
            style={{ width: 36, height: 36, padding: 0, float: "right" }}
            disabled={!propValue}
          >
            <ZendeskIcon className="zendesk-icon" />
          </Button>
        ),
      },
      interviewDate: {
        label: "Interview date",
        type: TYPES.DATE_TIME,
      },
      gclid: {
        label: "Google client ID",
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      salesAgent: {
        label: "Sales Agent",
        type: TYPES.TEXT,
        required: false,
      },
      salesCoupon: {
        label: "Sales Coupon",
        type: TYPES.TEXT,
        required: false,
      },
      botRuns: {
        label: "Number of bot runs",
      },
    },
  },
  personalInfoSegment: {
    label: "Personal information",
    type: TYPES.SEGMENT,
    data: {
      submissionEmail: {
        label: "Embassy email",
        type: TYPES.TEXT,
      },
      embassyPassword: {
        label: "Embassy password",
        type: TYPES.TEXT,
      },
      embassyPasswordHistory: {
        label: "Embassy password history",
      },
      hasUsername: {
        label: "New interface?",
        type: TYPES.CHECKBOX,
      },
      username: {
        label: "Username",
        type: TYPES.TEXT,
        dependsOn: "hasUsername",
      },
      firstName: {
        label: "First name / Given name",
        type: TYPES.TEXT,
      },
      lastName: {
        label: "Last name / Surname",
        type: TYPES.TEXT,
      },
      hasFullName: {
        label: "Has full name",
        type: TYPES.CHECKBOX,
        required: false,
      },
      fullName: {
        label: "Full name",
        dependsOn: "hasFullName",
        type: TYPES.TEXT,
        required: false,
      },
      userEmail: {
        label: "Email",
        type: TYPES.TEXT,
      },
      gender: {
        label: "Gender",
        type: TYPES.SELECT,
        values: DS_SELECT_VALUES.GENDER,
      },
      applicationCountry: {
        label: "Application country",
        type: TYPES.SELECT,
        values: DS_SELECT_VALUES.COUNTRIES_1,
      },
      securityQuestionsEmbassyTypeA: {
        label: "Security questions embassy type A",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "applicationCountry",
        dependantOnValues: [
          "ACK",
          "NHA",
          "KBO",
          "TKY",
          "MLB",
          "PRT",
          "SYD",
          "NWD",
          "BMB",
          "CLC",
          "HYD",
          "MDR",
          "SUV",
        ],
        nested: {
          birthCity: {
            label: "Birth city/town",
            type: TYPES.TEXT,
            path: "securityQuestionsEmbassyTypeA",
          },
          leastFavoriteFood: {
            label: "Least favorite food",
            type: TYPES.TEXT,
            path: "securityQuestionsEmbassyTypeA",
          },
          childhoodHero: {
            label: "Childhood hero",
            type: TYPES.TEXT,
            path: "securityQuestionsEmbassyTypeA",
          },
        },
      },
      chooseLocationLater: {
        label: "Choose location later selected",
        type: TYPES.CHECKBOX,
      },
      hasAdditionalNames: {
        label: "User has other alias",
        type: TYPES.CHECKBOX,
      },
      additionalFirstName: {
        label: "Additional first name / given name",
        type: TYPES.TEXT,
        dependsOn: "hasAdditionalNames",
      },
      additionalLastName: {
        label: "Additional last name / surname",
        type: TYPES.TEXT,
        dependsOn: "hasAdditionalNames",
      },
      hasTelecode: {
        label: "User has telecode",
        type: TYPES.CHECKBOX,
      },
      telecodeFirstName: {
        label: "Telecode first name / given name",
        type: TYPES.TEXT,
        dependsOn: "hasTelecode",
      },
      telecodeLastName: {
        label: "Telecode last name / surname",
        type: TYPES.TEXT,
        dependsOn: "hasTelecode",
      },
      birthDate: {
        label: "Birth date",
        type: TYPES.DATE,
      },
      birthCountry: {
        label: "Birth country",
        type: TYPES.SELECT,
        values: DS_SELECT_VALUES.COUNTRIES_2,
        geoLocationProps: ["birthCity", "birthStateProvince"],
      },
      birthCity: {
        label: "Birth city",
        type: TYPES.TEXT,
      },
      hasBirthStateProvince: {
        label: "Has birth state/province",
        type: TYPES.CHECKBOX,
        required: false,
      },
      birthStateProvince: {
        label: "Birth state/province",
        dependsOn: "hasBirthStateProvince",
        type: TYPES.TEXT,
        required: false,
      },
      maritalStatus: {
        label: "Marital status",
        type: TYPES.SELECT,
        values: DS_SELECT_VALUES.MARITAL_STATUS,
      },
      maritalStatusExplain: {
        label: "Explain marital status",
        type: TYPES.TEXT,
        dependsOn: "maritalStatus",
        dependantOnValues: ["O"],
      },
    },
  },
  personalInfo2Segment: {
    label: "Personal information 2",
    type: TYPES.SEGMENT,
    data: {
      nationality: {
        label: "User nationality",
        type: TYPES.SELECT,
        values: DS_SELECT_VALUES.NATIONALITY_1,
      },
      hasMultipleNationalities: {
        label: "User has multiple nationalities",
        type: TYPES.CHECKBOX,
      },
      nationalities: {
        label: "Additional nationalities",
        type: TYPES.ARRAY,
        dependsOn: "hasMultipleNationalities",
        data: {
          country: {
            label: "Country",
            type: TYPES.SELECT,
            path: "nationalities.$nationalities",
            values: DS_SELECT_VALUES.NATIONALITY_2,
          },
          passportNumber: {
            label: "Passport number",
            type: TYPES.TEXT,
            required: false,
            path: "nationalities.$nationalities",
          },
        },
      },
      hasMultiplePermanentResidents: {
        label: "User has multiple permanent residents",
        type: TYPES.CHECKBOX,
      },
      permanentResidents: {
        label: "Select additional permanent residents",
        type: TYPES.MULTI_SELECT,
        values: DS_SELECT_VALUES.COUNTRIES_3,
        dependsOn: "hasMultiplePermanentResidents",
      },
      hasNationalId: {
        label: "Has national ID",
        type: TYPES.CHECKBOX,
        required: false,
      },
      nationalId: {
        label: "National ID",
        dependsOn: "hasNationalId",
        type: TYPES.TEXT,
        required: false,
      },
      hasUSSocialSecurityNumber: {
        label: "Has US social security number",
        type: TYPES.CHECKBOX,
        required: false,
      },
      USSocialSecurityNumber: {
        label: "US social security number",
        dependsOn: "hasUSSocialSecurityNumber",
        type: TYPES.TEXT,
        required: false,
      },
      hasUSTaxpayerIdNumber: {
        label: "Has US taxpayer ID number",
        type: TYPES.CHECKBOX,
        required: false,
      },
      USTaxpayerIdNumber: {
        label: "US taxpayer ID number",
        dependsOn: "hasUSTaxpayerIdNumber",
        type: TYPES.TEXT,
        required: false,
      },
    },
  },
  travelInfoSegment: {
    label: "Travel information",
    type: TYPES.SEGMENT,
    data: {
      reasonForTripToUS: {
        label: "Reason for the Trip",
        type: TYPES.TEXT,
        dependsOn: "reasonForTripToUSSelect",
        dependantOnValues: [undefined],
      },
      reasonForTripToUSSelect: {
        label: "Reason for the Trip (Select)",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "reasonForTripToUS",
        dependantOnValues: [undefined],
        nested: {
          value: {
            label: "Reason",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.REASON_FOR_THE_TRIP,
            path: "reasonForTripToUSSelect",
          },
          applyingForSomeoneElse: {
            label: "Applying for someone else",
            type: TYPES.CHECKBOX,
            dependsOn: "reasonForTripToUSSelect.value",
            dependantOnValues: [3, 5],
            path: "reasonForTripToUSSelect",
          },
          someoneElse: {
            label: "Applying for who?",
            type: TYPES.SELECT,
            customDependency: (applicant) => {
              if ([3,5].includes(applicant?.reasonForTripToUSSelect?.value) && applicant?.reasonForTripToUSSelect?.applyingForSomeoneElse) {
                return true;
              }
            },
            values: VISA_RELATIVE_OPTIONS,
            path: "reasonForTripToUSSelect",
          },
          crewMemberInTransit: {
            label: "Crew member in transit?",
            type: TYPES.SELECT,
            values: CREW_MEMBER_OPTIONS,
            dependsOn: "reasonForTripToUSSelect.value",
            dependantOnValues: [11, 15],
            path: "reasonForTripToUSSelect",
          },
          explain: {
            label: "Explain",
            type: TYPES.TEXT,
            customDependency: (applicant) => {
              if (![3,5].includes(applicant?.reasonForTripToUSSelect?.value) || !applicant?.reasonForTripToUSSelect?.applyingForSomeoneElse) {
                return true;
              }
            },
            path: "reasonForTripToUSSelect",
          },
        },
      },
      purposes: {
        label: "Visa types",
        type: TYPES.ARRAY,
        data: {
          mainPurpose: {
            label: "Main visa type",
            type: TYPES.SELECT,
            path: "purposes.$purposes",
            values: DS_SELECT_VALUES.VISA_MAIN_TYPE,
          },
          specify: {
            label: "Visa subtype",
            type: TYPES.SELECT,
            required: false,
            path: "purposes.$purposes",
            values: (applicant, path, schemaData) => {
              const { passportIssueCountry } = applicant;
              const { [DS_SELECT_VALUES.VISA_SUBTYPE]: VISA_SUBTYPE } =
                schemaData;
              const mainPurpose = get(applicant, `${path}.mainPurpose`);
              if (passportIssueCountry !== "MEX" && mainPurpose === "B") {
                const { "BC-C": bcc, ...regularSubtypes } = VISA_SUBTYPE.B;
                const ST = { ...VISA_SUBTYPE, B: regularSubtypes };
                return ST[mainPurpose];
              }
              return VISA_SUBTYPE[mainPurpose];
            },
            dependsOn: "purposes.$purposes.mainPurpose",
          },
          principalApplicantInfo: {
            label: "Principal Applicant Info",
            type: TYPES.SEPARATOR,
            dependsOn: "purposes.$purposes.specify",
            dependantOnValues: [
              "C3-CH",
              "C3-SP",
              "F2-CH",
              "F2-SP",
              "M2-CH",
              "M2-SP",
              "I-SP",
              "I-CH",
              "J2-CH",
              "J2-SP",
            ],
          },
          firstName: {
            label: "First name / Given name",
            type: TYPES.TEXT,
            path: "purposes.$purposes",
            dependsOn: "purposes.$purposes.specify",
            dependantOnValues: [
              "C3-CH",
              "C3-SP",
              "F2-CH",
              "F2-SP",
              "M2-CH",
              "M2-SP",
              "I-SP",
              "I-CH",
              "J2-CH",
              "J2-SP",
            ],
          },
          lastName: {
            label: "Last name / Surname",
            type: TYPES.TEXT,
            path: "purposes.$purposes",
            dependsOn: "purposes.$purposes.specify",
            dependantOnValues: [
              "C3-CH",
              "C3-SP",
              "F2-CH",
              "F2-SP",
              "M2-CH",
              "M2-SP",
              "I-SP",
              "I-CH",
              "J2-CH",
              "J2-SP",
            ],
          },
          sevisId: {
            label: "Sevis ID",
            type: TYPES.TEXT,
            path: "studentVisa",
            dependsOn: "purposes.$purposes.specify",
            dependantOnValues: [
              "F2-CH",
              "F2-SP",
              "M2-CH",
              "M2-SP",
              "J2-CH",
              "J2-SP",
            ],
          },
          principalSevisId: {
            label: "Principal Sevis ID",
            type: TYPES.TEXT,
            path: "studentVisa",
            dependsOn: "purposes.$purposes.specify",
            dependantOnValues: [
              "F2-CH",
              "F2-SP",
              "M2-CH",
              "M2-SP",
              "J2-CH",
              "J2-SP",
            ],
          },
        },
      },
      hasTravelPlans: {
        label: "User has travel plans",
        type: TYPES.CHECKBOX,
      },
      hasTravelPlansNotSure: {
        label: "User has travel plans - not sure/skip/answer later",
        type: TYPES.CHECKBOX,
      },
      arrivalDate: {
        label: "Date of arrival",
        type: TYPES.DATE,
      },
      stayLengthValue: {
        label: "Stay length value",
        type: TYPES.TEXT,
        dependsOn: "hasTravelPlans",
        dependantOnValues: [false],
      },
      stayLengthType: {
        label: "Stay length type",
        type: TYPES.SELECT,
        values: DS_SELECT_VALUES.TIME_LENGTH,
        dependsOn: "hasTravelPlans",
        dependantOnValues: [false],
      },
      arrivalFlight: {
        label: "Arrival flight",
        type: TYPES.TEXT,
        required: false,
        dependsOn: "hasTravelPlans",
      },
      arrivalCity: {
        label: "Arrival city",
        type: TYPES.TEXT,
        dependsOn: "hasTravelPlans",
      },
      departureDate: {
        label: "Date of departure",
        type: TYPES.DATE,
        dependsOn: "hasTravelPlans",
      },
      departureFlight: {
        label: "Departure flight",
        type: TYPES.TEXT,
        required: false,
        dependsOn: "hasTravelPlans",
      },
      departureCity: {
        label: "Departure city",
        type: TYPES.TEXT,
        dependsOn: "hasTravelPlans",
      },
      travelLocations: {
        label: "Travel locations",
        type: TYPES.MULTI_TEXT,
        customDependency: (applicant) => {
          if (applicant?.hasTravelPlans) {
            return true;
          }
          if (["Y", "M", "W", "D"].includes(applicant?.stayLengthType)) {
            return true;
          }
        },
      },
      stayAddress: {
        label: "Stay address",
        type: TYPES.NESTED,
        newTable: true,
        nested: USAddressSchema("stayAddress"),
        customDependency: (applicant) => {
          if (applicant?.hasTravelPlans) {
            return true;
          }
          if (["Y", "M", "W", "D"].includes(applicant?.stayLengthType)) {
            return true;
          }
        },
      },
      personPayingForTrip: {
        label: "Person paying for trip",
        type: TYPES.SELECT,
        values: DS_SELECT_VALUES.PAYER_TYPE,
      },
      payerInfo: {
        label: "Payer info",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "personPayingForTrip",
        dependantOnValues: ["O"],
        nested: {
          firstName: {
            label: "First name / Given name",
            type: TYPES.TEXT,
            path: "payerInfo",
          },
          lastName: {
            label: "Last name / Surname",
            type: TYPES.TEXT,
            path: "payerInfo",
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "payerInfo",
          },
          hasEmail: {
            label: "Has email",
            type: TYPES.CHECKBOX,
            required: false,
            path: "payerInfo",
          },
          email: {
            label: "Email",
            type: TYPES.TEXT,
            dependsOn: "payerInfo.hasEmail",
            path: "payerInfo",
          },
          relation: {
            label: "Relation",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.PAYER_RELATION,
            path: "payerInfo",
          },
          hasSameAddressAsMailingOrHome: {
            label: "Has same address as mailing or home",
            type: TYPES.CHECKBOX,
            path: "payerInfo",
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn: "payerInfo.hasSameAddressAsMailingOrHome",
            dependantOnValues: [false],
            nested: AddressSchema("payerInfo.address"),
            path: "payerInfo",
          },
        },
      },
      companyPayerInfo: {
        label: "Company payer info",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "personPayingForTrip",
        dependantOnValues: ["C"],
        nested: {
          name: {
            label: "Company name",
            type: TYPES.TEXT,
            path: "companyPayerInfo",
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "companyPayerInfo",
          },
          relation: {
            label: "Relation",
            type: TYPES.TEXT,
            path: "companyPayerInfo",
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            nested: AddressSchema("companyPayerInfo.address"),
            path: "companyPayerInfo",
          },
        },
      },
      additionalVisaTypeInfoSeparator: {
        label: "Additional information for selected visa type",
        type: TYPES.SEPARATOR,
        customDependency: (applicant) =>
          applicant.purposes.some((visaInfo) =>
            ["C1-D", "D-D", "F1-F1", "M1-M1"].includes(visaInfo.specify),
          ),
      },
      crewMemberVisa: {
        label: "Crew member visa",
        type: TYPES.NESTED,
        newTable: true,
        customDependency: (applicant) =>
          applicant.purposes.some((visaInfo) =>
            ["C1-D", "D-D"].includes(visaInfo.specify),
          ),
        nested: {
          jobTitle: {
            label: "Job title",
            type: TYPES.TEXT,
            path: "crewMemberVisa",
          },
          companyName: {
            label: "Company name",
            type: TYPES.TEXT,
            path: "crewMemberVisa",
          },
          companyPhone: {
            label: "Company phone",
            type: TYPES.TEXT,
            path: "crewMemberVisa",
          },
          didAquirePositionUsingAgency: {
            label: "Did aquire position using recruiting agency",
            type: TYPES.CHECKBOX,
            path: "crewMemberVisa",
          },
          agencyName: {
            label: "Agency name",
            type: TYPES.TEXT,
            path: "crewMemberVisa",
            dependsOn: "crewMemberVisa.didAquirePositionUsingAgency",
          },
          contactFirstName: {
            label: "Contact first name / given name",
            type: TYPES.TEXT,
            path: "crewMemberVisa",
            dependsOn: "crewMemberVisa.didAquirePositionUsingAgency",
          },
          contactLastName: {
            label: "Contact last name / surname",
            type: TYPES.TEXT,
            path: "crewMemberVisa",
            dependsOn: "crewMemberVisa.didAquirePositionUsingAgency",
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            nested: AddressSchema("crewMemberVisa.address"),
            path: "crewMemberVisa",
            dependsOn: "crewMemberVisa.didAquirePositionUsingAgency",
          },
          agencyPhone: {
            label: "Agency phone",
            type: TYPES.TEXT,
            path: "crewMemberVisa",
            dependsOn: "crewMemberVisa.didAquirePositionUsingAgency",
          },
          isServingAboardShipOrVessel: {
            label: "Is serving aboard ship or vessel",
            type: TYPES.CHECKBOX,
            path: "crewMemberVisa",
          },
          shipName: {
            label: "Ship name",
            type: TYPES.TEXT,
            path: "crewMemberVisa",
            dependsOn: "crewMemberVisa.isServingAboardShipOrVessel",
          },
          shipId: {
            label: "Ship identification number",
            type: TYPES.TEXT,
            path: "crewMemberVisa",
            dependsOn: "crewMemberVisa.isServingAboardShipOrVessel",
          },
        },
      },
      studentVisa: {
        label: "Additional student visa information",
        type: TYPES.NESTED,
        newTable: true,
        customDependency: (applicant) =>
          applicant.purposes.some((visaInfo) =>
            ["F1-F1", "M1-M1", "J1-J1", "J2-CH", "J2-SP"].includes(
              visaInfo.specify,
            ),
          ),
        nested: {
          sevisId: {
            label: "SEVIS ID",
            type: TYPES.TEXT,
            path: "studentVisa",
          },
          schoolName: {
            label: "School name",
            type: TYPES.TEXT,
            path: "studentVisa",
          },
          programNumber: {
            label: "Program number",
            type: TYPES.TEXT,
            path: "studentVisa",
            required: false,
          },
          principalSevisId: {
            label: "Principal applicant Sevis id",
            type: TYPES.TEXT,
            path: "studentVisa",
            required: false,
          },
          studyInUs: {
            label: "Study in US",
            type: TYPES.CHECKBOX,
            path: "studentVisa",
            required: false,
          },
          courseOfStudy: {
            label: "Course of study",
            type: TYPES.TEXT,
            path: "studentVisa",
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            path: "studentVisa",
            nested: StudentVisaAddressSchema("studentVisa.address"),
          },
          additionalContacts: {
            label: "Additional contacts",
            type: TYPES.ARRAY,
            path: "studentVisa",
            data: {
              firstName: {
                label: "First name / Given name",
                type: TYPES.TEXT,
                path: "studentVisa.additionalContacts.$additionalContacts",
              },
              lastName: {
                label: "Last name / Surname",
                type: TYPES.TEXT,
                path: "studentVisa.additionalContacts.$additionalContacts",
              },
              address: {
                label: "Contact address",
                type: TYPES.NESTED,
                newTable: true,
                nested: AddressSchema(
                  "studentVisa.additionalContacts.$additionalContacts.address",
                ),
              },
              emailAddress: {
                label: "Email address",
                type: TYPES.TEXT,
                required: false,
                path: "studentVisa.additionalContacts.$additionalContacts",
              },
              phoneNumber: {
                label: "Phone number",
                type: TYPES.TEXT,
                required: false,
                path: "studentVisa.additionalContacts.$additionalContacts",
              },
            },
          },
        },
      },
    },
  },
  travelCompanionsInfoSegment: {
    label: "Travel companions information",
    type: TYPES.SEGMENT,
    data: {
      hasOtherTravelers: {
        label: "Has other travelers",
        type: TYPES.CHECKBOX,
      },
      travelingWithOrganization: {
        label: "Traveling with organization",
        type: TYPES.CHECKBOX,
        dependsOn: "hasOtherTravelers",
      },
      groupName: {
        label: "Group name",
        type: TYPES.TEXT,
        dependsOn: "travelingWithOrganization",
      },
      travelers: {
        label: "Travelers",
        type: TYPES.ARRAY,
        dependsOn: ["hasOtherTravelers", "travelingWithOrganization"],
        dependantOnValues: [[true], [false]],
        data: {
          firstName: {
            label: "First name / Given name",
            type: TYPES.TEXT,
            path: "travelers.$travelers",
          },
          lastName: {
            label: "Last name / Surname",
            type: TYPES.TEXT,
            path: "travelers.$travelers",
          },
          relation: {
            label: "Relation",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.TRAVEL_RELATION,
            path: "travelers.$travelers",
          },
        },
      },
    },
  },
  previousUSTravelsInfoSegment: {
    label: "Previous US travel information",
    type: TYPES.SEGMENT,
    data: {
      haveYouEverBeenToUS: {
        label: "Has user every been to US",
        type: TYPES.CHECKBOX,
      },
      previousUSVisits: {
        label: "Previous US visits",
        type: TYPES.ARRAY,
        dependsOn: ["haveYouEverBeenToUS"],
        data: {
          arrivalDate: {
            label: "Arrival date",
            type: TYPES.DATE,
            path: "previousUSVisits.$previousUSVisits",
          },
          stayLengthValue: {
            label: "Stay length value",
            type: TYPES.TEXT,
            path: "previousUSVisits.$previousUSVisits",
          },
          stayLengthType: {
            label: "Stay length type",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.TIME_LENGTH,
            path: "previousUSVisits.$previousUSVisits",
          },
        },
      },
      hasUSDriversLicense: {
        label: "Has US drivers license",
        type: TYPES.CHECKBOX,
        dependsOn: "haveYouEverBeenToUS",
      },
      USDriverLicenses: {
        label: "US driver licenses",
        type: TYPES.ARRAY,
        dependsOn: ["haveYouEverBeenToUS", "hasUSDriversLicense"],
        dependantOnValues: [[true], [true]],
        data: {
          state: {
            label: "State",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.STATES,
            path: "USDriverLicenses.$USDriverLicenses",
          },
          hasLicenseId: {
            label: "Has license ID",
            type: TYPES.CHECKBOX,
            required: false,
            path: "USDriverLicenses.$USDriverLicenses",
          },
          licenseId: {
            label: "License ID",
            type: TYPES.TEXT,
            dependsOn: "USDriverLicenses.$USDriverLicenses.hasLicenseId",
            path: "USDriverLicenses.$USDriverLicenses",
          },
        },
      },
      hasIssuedVisa: {
        label: "Has issued visa",
        type: TYPES.CHECKBOX,
      },
      lastVisa: {
        label: "Last visa",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "hasIssuedVisa",
        nested: {
          applicationCountry: {
            label: "Application country",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.COUNTRIES_6,
            path: "lastVisa",
          },
          issueDate: {
            label: "Issue date",
            type: TYPES.DATE,
            path: "lastVisa",
          },
          expirationDate: {
            label: "Expiration date",
            type: TYPES.DATE,
            path: "lastVisa",
          },
          hasVisaNumber: {
            label: "Has visa number",
            type: TYPES.CHECKBOX,
            required: false,
            path: "lastVisa",
          },
          visaNumber: {
            label: "Visa number",
            dependsOn: "lastVisa.hasVisaNumber",
            type: TYPES.TEXT,
            path: "lastVisa",
          },
          sameType: {
            label: "Same type as requested visa",
            type: TYPES.CHECKBOX,
            path: "lastVisa",
          },
          sameCountry: {
            label: "Same country as requested visa",
            type: TYPES.CHECKBOX,
            path: "lastVisa",
          },
          tenPrinted: {
            label: "User was ever ten printed",
            type: TYPES.CHECKBOX,
            path: "lastVisa",
          },
          visaLost: {
            label: "User ever lost visa",
            type: TYPES.CHECKBOX,
            path: "lastVisa",
          },
          visaLostYear: {
            label: "Visa lost year",
            type: TYPES.TEXT,
            path: "lastVisa",
            dependsOn: "lastVisa.visaLost",
          },
          visaLostExplain: {
            label: "Explain visa lost",
            type: TYPES.TEXT,
            path: "lastVisa",
            dependsOn: "lastVisa.visaLost",
          },
          visaCancelled: {
            label: "Ever had visa cancelled",
            type: TYPES.CHECKBOX,
            path: "lastVisa",
          },
          visaCancelledExplain: {
            label: "Explain why was visa cancelled",
            type: TYPES.TEXT,
            path: "lastVisa",
            dependsOn: "lastVisa.visaCancelled",
          },
          clearanceReceived: {
            label: "Visa has clearance received",
            type: TYPES.CHECKBOX,
            path: "lastVisa",
          },
        },
      },
      parentsHaveValidVisa: {
        label: "Does at least one of your parents have a valid US Visa at the moment?",
        type: TYPES.CHECKBOX,
        customDependency: ({ birthDate }) => {
          if (!birthDate) return false;
          return moment().diff(birthDate, 'years') <= 14;
        }
      },            
      hasBeenRefusedForVisa: {
        label: "Has ever been refused for visa",
        type: TYPES.CHECKBOX,
      },
      hasBeenRefusedForVisaExplain: {
        label: "Explain visa refuse",
        type: TYPES.TEXT,
        dependsOn: "hasBeenRefusedForVisa",
      },
      visaDeniedDate: {
        label: "Date",
        type: TYPES.DATE,
        format: "MMM-YYYY",
        dependsOn: "hasBeenRefusedForVisa",
      },
      haveYouEverBeenDeniedESTATravelAuthorization: {
        label:
          "Have you ever been denied travel authorization by the Department of Homeland Security through the Electronic System for Travel Authorization (ESTA)?",
        type: TYPES.CHECKBOX,
        customDependency: (applicant) => {
          const { nationality, nationalities } = applicant;

          if (VWP_COUNTRIES.includes(nationality)) {
            return true;
          }

          if (!!nationalities?.length) {
            return nationalities.some(({ country }) =>
              VWP_COUNTRIES.includes(country),
            );
          }

          return false;
        },
      },
      haveYouEverBeenDeniedESTATravelAuthorizationExplain: {
        label: "Explain",
        type: TYPES.TEXT,
        textArea: true,
        customDependency: (applicant) => {
          const {
            nationality,
            nationalities,
            haveYouEverBeenDeniedESTATravelAuthorization,
          } = applicant;

          if (!haveYouEverBeenDeniedESTATravelAuthorization) {
            return false;
          }

          if (VWP_COUNTRIES.includes(nationality)) {
            return true;
          }

          if (!!nationalities?.length) {
            return nationalities.some(({ country }) =>
              VWP_COUNTRIES.includes(country),
            );
          }

          return false;
        },
      },
      hasAnyoneEverFilledOnBehalf: {
        label: "Has anyone ever filled ds on behalf of user",
        type: TYPES.CHECKBOX,
      },
      hasAnyoneEverFilledOnBehalfExplain: {
        label: "Explain ds being filled by someone",
        type: TYPES.TEXT,
        dependsOn: "hasAnyoneEverFilledOnBehalf",
      },
    },
  },
  addressAndPhoneInfoSegment: {
    label: "Address and phone information",
    type: TYPES.SEGMENT,
    data: {
      homeAddress: {
        label: "Home address",
        note: "Parts of this section will be used to verify the client's ID on Calls/Chats",
        type: TYPES.NESTED,
        newTable: true,
        nested: AddressSchema("homeAddress"),
      },
      hasSameMailingAddressAsHome: {
        label: "Has same mailing address as home address",
        type: TYPES.CHECKBOX,
      },
      mailingAddress: {
        label: "Mailing address",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "hasSameMailingAddressAsHome",
        dependantOnValues: [false],
        nested: AddressSchema("mailingAddress"),
      },
      hasAdditionalEmails: {
        label: "Has additional emails",
        type: TYPES.CHECKBOX,
      },
      additionalEmails: {
        label: "Additional emails",
        type: TYPES.MULTI_TEXT,
        dependsOn: "hasAdditionalEmails",
      },
      phonePrimaryNumber: {
        label: "Primary phone number",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          phonePrimary: {
            label: "Phone number",
            type: TYPES.TEXT,
          },
          phonePrimaryCode: {
            label: "Country code",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.PHONE_COUNTRIES,
          },
        },
      },
      hasPhoneSecondary: {
        label: "Has secondary phone",
        type: TYPES.CHECKBOX,
        required: false,
      },
      phoneSecondaryNumber: {
        label: "Secondary phone number",
        dependsOn: "hasPhoneSecondary",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          phoneSecondary: {
            label: "Phone number",
            type: TYPES.TEXT,
            required: false,
          },
          phoneSecondaryCode: {
            label: "Country code",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.PHONE_COUNTRIES,
          },
        },
      },
      hasPhoneWork: {
        label: "Has work phone",
        type: TYPES.CHECKBOX,
        required: false,
      },
      phoneWorkNumber: {
        label: "Phone work number",
        dependsOn: "hasPhoneWork",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          phoneWork: {
            label: "Phone number",
            type: TYPES.TEXT,
            required: false,
          },
          phoneWorkCode: {
            label: "Country code",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.PHONE_COUNTRIES,
          },
        },
      },
      hasAdditionalPhoneNumbers: {
        label: "Has additional phone numbers",
        type: TYPES.CHECKBOX,
      },
      additionalPhones: {
        label: "Additional phone numbers",
        type: TYPES.MULTI_TEXT,
        dependsOn: "hasAdditionalPhoneNumbers",
      },
      socialMedias: {
        label: "Social media",
        type: TYPES.ARRAY,
        data: {
          platform: {
            label: "Platform",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.SOCIAL_MEDIA,
            path: "socialMedias.$socialMedias",
          },
          username: {
            label: "Username",
            type: TYPES.TEXT,
            path: "socialMedias.$socialMedias",
          },
        },
      },
      hasAdditionalSocialMedia: {
        label: "Has additional social media",
        type: TYPES.CHECKBOX,
      },
      additionalSocialMedia: {
        label: "Additional social media",
        type: TYPES.ARRAY,
        dependsOn: "hasAdditionalSocialMedia",
        data: {
          platform: {
            label: "Platform name",
            type: TYPES.TEXT,
            path: "additionalSocialMedia.$additionalSocialMedia",
          },
          username: {
            label: "Platform username",
            type: TYPES.TEXT,
            path: "additionalSocialMedia.$additionalSocialMedia",
          },
        },
      },
    },
  },
  passportInfoSegment: {
    label: "Passport information",
    type: TYPES.SEGMENT,
    data: {
      passportType: {
        label: "Passport type",
        type: TYPES.SELECT,
        values: DS_SELECT_VALUES.PASSPORT_TYPE,
      },
      passportTypeExplain: {
        label: "Explain passport type",
        type: TYPES.TEXT,
        dependsOn: "passportType",
        dependantOnValues: ["T"],
      },
      passportNumber: {
        label: "Passport number",
        type: TYPES.TEXT,
      },
      hasPassportBookNumber: {
        label: "Has passport book number",
        type: TYPES.CHECKBOX,
        required: false,
      },
      passportBookNumber: {
        label: "Passport book number",
        dependsOn: "hasPassportBookNumber",
        type: TYPES.TEXT,
      },
      passportIssueCountry: {
        label: "Country/authority that issued the passport (nationality)",
        type: TYPES.SELECT,
        values: DS_SELECT_VALUES.COUNTRIES_4,
      },
      passportIssueDate: {
        label: "Passport issue date",
        type: TYPES.DATE,
      },
      hasPassportExpiryDate: {
        label: "Has passport expiry date",
        type: TYPES.CHECKBOX,
        required: false,
      },
      passportExpiryDate: {
        label: "Passport expiry date",
        dependsOn: "hasPassportExpiryDate",
        type: TYPES.DATE,
      },
      passportCountry: {
        label: "Passport country where issued",
        type: TYPES.SELECT,
        values: DS_SELECT_VALUES.COUNTRIES_3,
        geoLocationProps: ["passportState", "passportCity"],
      },
      hasPassportState: {
        label: "Has passport state",
        type: TYPES.CHECKBOX,
        required: false,
      },
      passportState: {
        label: "State where passport was issued",
        dependsOn: "hasPassportState",
        type: TYPES.TEXT,
      },
      passportCity: {
        label: "City where passport was issued",
        type: TYPES.TEXT,
      },
      hasEverLostPassport: {
        label: "User has ever lost passport",
        type: TYPES.CHECKBOX,
      },
      lostPassports: {
        label: "Lost passports",
        type: TYPES.ARRAY,
        dependsOn: "hasEverLostPassport",
        data: {
          hasPassportNumber: {
            label: "Has passport number",
            path: "lostPassports.$lostPassports",
            type: TYPES.CHECKBOX,
          },
          passportNumber: {
            label: "Passport number",
            type: TYPES.TEXT,
            path: "lostPassports.$lostPassports",
            dependsOn: "lostPassports.$lostPassports.hasPassportNumber",
          },
          country: {
            label: "Country",
            type: TYPES.SELECT,
            path: "lostPassports.$lostPassports",
            values: DS_SELECT_VALUES.COUNTRIES_4,
          },
          explain: {
            label: "Explain",
            type: TYPES.TEXT,
            path: "lostPassports.$lostPassports",
          },
        },
      },
    },
  },
  USPointOfContactInfoSegment: {
    label: "US point of contact information",
    type: TYPES.SEGMENT,
    customDependency: (applicant) => {
      if (applicant?.hasTravelPlans) {
        return true;
      }
      if (["Y", "M", "W", "D"].includes(applicant?.stayLengthType)) {
        return true;
      }
    },
    data: {
      // USContactType: {
      //   label: "US contact type",
      //   type: TYPES.SELECT,
      //   values: DS_SELECT_VALUES.US_CONTACT_TYPE,
      //   customDependency: (applicant) => {
      //     if (applicant?.hasTravelPlans) {
      //       return true;
      //     }
      //     if (["Y", "M", "W", "D"].includes(applicant?.stayLengthType)) {
      //       return true;
      //     }
      //   },
      // },
      USContactFirstName: {
        label: "US contact first name / given name",
        type: TYPES.TEXT,
        customDependency: (applicant) => {
          if (applicant?.hasTravelPlans) {
            return true;
          }
          if (["Y", "M", "W", "D"].includes(applicant?.stayLengthType)) {
            return true;
          }
        },
      },
      USContactLastName: {
        label: "US contact last name / surname",
        type: TYPES.TEXT,
        customDependency: (applicant) => {
          if (applicant?.hasTravelPlans) {
            return true;
          }
          if (["Y", "M", "W", "D"].includes(applicant?.stayLengthType)) {
            return true;
          }
        },
      },
      USContactOrganizationName: {
        label: "US contact organization name",
        type: TYPES.TEXT,
        customDependency: (applicant) => {
          if (applicant?.hasTravelPlans) {
            return true;
          }
          if (["Y", "M", "W", "D"].includes(applicant?.stayLengthType)) {
            return true;
          }
        },
      },
      USContactRelation: {
        label: "US contact relation",
        type: TYPES.SELECT,
        values: DS_SELECT_VALUES.CONTACT_RELATION,
        customDependency: (applicant) => {
          if (applicant?.hasTravelPlans) {
            return true;
          }
          if (["Y", "M", "W", "D"].includes(applicant?.stayLengthType)) {
            return true;
          }
        },
      },
      USContactAddress: {
        label: "US contact address",
        type: TYPES.NESTED,
        newTable: true,
        nested: USAddressSchema("USContactAddress"),
        customDependency: (applicant) => {
          if (applicant?.hasTravelPlans) {
            return true;
          }
          if (["Y", "M", "W", "D"].includes(applicant?.stayLengthType)) {
            return true;
          }
        },
      },
      USContactPhoneNumber: {
        label: "US contact phone number",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          USContactPhoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            customDependency: (applicant) => {
              if (applicant?.hasTravelPlans) {
                return true;
              }
              if (["Y", "M", "W", "D"].includes(applicant?.stayLengthType)) {
                return true;
              }
            },
          },
          USContactPhoneNumberCode: {
            label: "Country code",
            type: TYPES.SELECT,
            customDependency: (applicant) => {
              if (applicant?.hasTravelPlans) {
                return true;
              }
              if (["Y", "M", "W", "D"].includes(applicant?.stayLengthType)) {
                return true;
              }
            },
            values: DS_SELECT_VALUES.PHONE_COUNTRIES,
          },
        },
      },
      USContactEmailAddress: {
        label: "US contact email address",
        type: TYPES.TEXT,
        required: false,
        customDependency: (applicant) => {
          if (applicant?.hasTravelPlans) {
            return true;
          }
          if (["Y", "M", "W", "D"].includes(applicant?.stayLengthType)) {
            return true;
          }
        },
      },
    },
  },
  relativesInfoSegment: {
    label: "Relatives information",
    type: TYPES.SEGMENT,
    data: {
      fatherInfo: {
        label: "Father info",
        type: TYPES.NESTED,
        newTable: true,
        required: false,
        nested: {
          hasFirstName: {
            label: "Has first name",
            type: TYPES.CHECKBOX,
            path: "fatherInfo",
            required: false,
          },
          firstName: {
            label: "First name / Given name",
            type: TYPES.TEXT,
            required: false,
            path: "fatherInfo",
            dependsOn: "fatherInfo.hasFirstName",
            dependantOnValues: [true, undefined],
          },
          hasLastName: {
            label: "Has last name",
            type: TYPES.CHECKBOX,
            path: "fatherInfo",
            required: false,
          },
          lastName: {
            label: "Last name / Surname",
            type: TYPES.TEXT,
            required: false,
            path: "fatherInfo",
            dependsOn: "fatherInfo.hasLastName",
            dependantOnValues: [true, undefined],
          },
          hasBirthDate: {
            label: "Has birth date",
            type: TYPES.CHECKBOX,
            path: "fatherInfo",
            required: false,
            customDependency: (applicant) => {
              const { fatherInfo } = applicant;
              return fatherInfo?.hasFirstName || fatherInfo?.hasLastName;
            },
          },
          birthDate: {
            label: "Birth date",
            type: TYPES.DATE,
            dependsOn: "fatherInfo.hasBirthDate",
            path: "fatherInfo",
          },
          isInUS: {
            label: "Is in US?",
            type: TYPES.CHECKBOX,
            path: "fatherInfo",
            required: false,
          },
          status: {
            label: "Status in US",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.RELATIVE_STATUS,
            dependsOn: "fatherInfo.isInUS",
            path: "fatherInfo",
          },
        },
      },
      motherInfo: {
        label: "Mother info",
        type: TYPES.NESTED,
        newTable: true,
        required: false,
        nested: {
          hasFirstName: {
            label: "Has first name",
            type: TYPES.CHECKBOX,
            path: "motherInfo",
            required: false,
          },
          firstName: {
            label: "First name / Given name",
            type: TYPES.TEXT,
            required: false,
            path: "motherInfo",
            dependsOn: "motherInfo.hasFirstName",
            dependantOnValues: [true, undefined],
          },
          hasLastName: {
            label: "Has last name",
            type: TYPES.CHECKBOX,
            path: "motherInfo",
            required: false,
          },
          lastName: {
            label: "Last name / Surname",
            type: TYPES.TEXT,
            required: false,
            path: "motherInfo",
            dependsOn: "motherInfo.hasLastName",
            dependantOnValues: [true, undefined],
          },
          hasBirthDate: {
            label: "Has birth date",
            type: TYPES.CHECKBOX,
            path: "motherInfo",
            required: false,
            customDependency: (applicant) => {
              const { motherInfo } = applicant;
              return motherInfo?.hasFirstName || motherInfo?.hasLastName;
            },
          },
          birthDate: {
            label: "Birth date",
            type: TYPES.DATE,
            dependsOn: "motherInfo.hasBirthDate",
            path: "motherInfo",
          },
          isInUS: {
            label: "Is in US?",
            type: TYPES.CHECKBOX,
            path: "motherInfo",
            required: false,
          },
          status: {
            label: "Status in US",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.RELATIVE_STATUS,
            dependsOn: "motherInfo.isInUS",
            path: "motherInfo",
          },
        },
      },
      hasImmediateRelativesInUS: {
        label: "Has immediate relatives in US",
        type: TYPES.CHECKBOX,
      },
      hasRelativesInUS: {
        label: "Has relatives in US",
        type: TYPES.CHECKBOX,
        dependsOn: "hasImmediateRelativesInUS",
        dependantOnValues: [false],
      },
      immediateRelatives: {
        label: "Immediate relatives",
        type: TYPES.ARRAY,
        dependsOn: "hasImmediateRelativesInUS",
        data: {
          firstName: {
            label: "First name / Given name",
            type: TYPES.TEXT,
            path: "immediateRelatives.$immediateRelatives",
          },
          hasLastName: {
            label: "Has last name",
            required: false,
            type: TYPES.CHECKBOX,
            path: "immediateRelatives.$immediateRelatives",
          },
          lastName: {
            label: "Last name / Surname",
            type: TYPES.TEXT,
            dependsOn: "immediateRelatives.$immediateRelatives.hasLastName",
            path: "immediateRelatives.$immediateRelatives",
          },
          hasBirthDate: {
            label: "Has birth date",
            type: TYPES.CHECKBOX,
            required: false,
            path: "immediateRelatives.$immediateRelatives",
          },
          birthDate: {
            label: "Birth date",
            type: TYPES.DATE,
            path: "immediateRelatives.$immediateRelatives",
            dependsOn: "immediateRelatives.$immediateRelatives.hasBirthDate",
          },
          relation: {
            label: "Relation",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.RELATIVE_TYPE,
            path: "immediateRelatives.$immediateRelatives",
          },
          status: {
            label: "Status in US",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.RELATIVE_STATUS,
            path: "immediateRelatives.$immediateRelatives",
          },
        },
      },
    },
  },
  partnerInfoSegment: {
    label: "Partner information",
    type: TYPES.SEGMENT,
    dependsOn: "maritalStatus",
    dependantOnValues: ["M", "C", "P", "W", "L", "D"],
    data: {
      partnerInfo: {
        label: "Partner info",
        type: TYPES.NESTED,
        dependsOn: "maritalStatus",
        newTable: true,
        dependantOnValues: ["M", "C", "P", "W", "L"],
        nested: {
          firstName: {
            label: "First name / Given name",
            type: TYPES.TEXT,
            path: "partnerInfo",
          },
          lastName: {
            label: "Last name / Surname",
            type: TYPES.TEXT,
            path: "partnerInfo",
          },
          nationalityCountry: {
            label: "Nationality country",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.NATIONALITY_1,
            path: "partnerInfo",
          },
          birthDate: {
            label: "Birth date",
            type: TYPES.DATE,
            path: "partnerInfo",
          },
          birthCountry: {
            label: "Birth country",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.COUNTRIES_2,
            path: "partnerInfo",
            geoLocationProps: ["birthCity"],
          },
          hasBirthCity: {
            label: "Has birth city",
            type: TYPES.CHECKBOX,
            path: "partnerInfo",
            required: false,
          },
          birthCity: {
            label: "Birth city",
            type: TYPES.TEXT,
            path: "partnerInfo",
            dependsOn: "partnerInfo.hasBirthCity",
          },
          addressType: {
            label: "Address type",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.ADDRESS_TYPE,
            path: "partnerInfo",
            dependsOn: "maritalStatus",
            dependantOnValues: ["M", "C", "P", "L"],
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn: ["partnerInfo.addressType", "maritalStatus"],
            dependantOnValues: [["O"], ["M", "C", "P", "L"]],
            nested: AddressSchema("partnerInfo.address"),
          },
        },
      },
      divorced: {
        label: "Divorced wifes info",
        type: TYPES.ARRAY,
        dependsOn: "maritalStatus",
        dependantOnValues: ["D"],
        data: {
          firstName: {
            label: "First name / Given name",
            type: TYPES.TEXT,
            path: "divorced.$divorced",
          },
          lastName: {
            label: "Last name / Surname",
            type: TYPES.TEXT,
            path: "divorced.$divorced",
          },
          nationalityCountry: {
            label: "Nationality country",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.NATIONALITY_1,
            path: "divorced.$divorced",
          },
          birthDate: {
            label: "Birth date",
            type: TYPES.DATE,
            path: "divorced.$divorced",
          },
          birthCountry: {
            label: "Birth country",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.COUNTRIES_2,
            path: "divorced.$divorced",
            geoLocationProps: ["birthCity"],
          },
          birthCity: {
            label: "Birth city",
            type: TYPES.TEXT,
            path: "divorced.$divorced",
            required: false,
          },
          marriageFrom: {
            label: "Marriage start date",
            type: TYPES.DATE,
            path: "divorced.$divorced",
          },
          marriageTo: {
            label: "Marriage end date",
            type: TYPES.DATE,
            path: "divorced.$divorced",
          },
          marriageEndReason: {
            label: "Marriage end reason",
            type: TYPES.TEXT,
            path: "divorced.$divorced",
          },
          marriageTerminationCountry: {
            label: "Marriage terminate country",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.COUNTRIES_3,
            path: "divorced.$divorced",
          },
        },
      },
    },
  },
  presentWorkInfoSegment: {
    label: "Present work information",
    type: TYPES.SEGMENT,
    customDependency: (applicant) => {
      if (moment().diff(moment(applicant?.birthDate), "years") >= 14) {
        return true;
      }
    },
    data: {
      jobType: {
        label: "Job type",
        type: TYPES.SELECT,
        values: DS_SELECT_VALUES.JOB_TYPE,
        customDependency: (applicant) => {
          if (moment().diff(moment(applicant?.birthDate), "years") >= 14) {
            return true;
          }
        },
      },
      jobExplain: {
        label: "Job explain",
        type: TYPES.TEXT,
        dependsOn: "jobType",
        dependantOnValues: ["O", "N"],
      },
      jobEmployer: {
        label: "Job employer",
        type: TYPES.TEXT,
        dependsOn: "jobType",
        dependantOnValues: jobsWithInfo,
      },
      jobAddress: {
        label: "Job address",
        type: TYPES.NESTED,
        dependsOn: "jobType",
        newTable: true,
        dependantOnValues: jobsWithInfo,
        nested: AddressSchema("jobAddress"),
      },
      jobPhoneNumber: {
        label: "Job phone number",
        type: TYPES.TEXT,
        dependsOn: "jobType",
        dependantOnValues: jobsWithInfo,
      },
      jobStartDate: {
        label: "Job start date",
        type: TYPES.DATE,
        dependsOn: "jobType",
        dependantOnValues: jobsWithInfo,
      },
      hasJobMonthlyIncome: {
        label: "Has monthly income",
        type: TYPES.CHECKBOX,
        required: false,
        customDependency: (applicant) => {
          if (moment().diff(moment(applicant?.birthDate), "years") >= 14) {
            return true;
          }
        },
      },
      jobMonthlyIncome: {
        label: "Job monthly income",
        type: TYPES.TEXT,
        dependsOn: "hasJobMonthlyIncome",
        // dependantOnValues: jobsWithInfo,
      },
      jobDescribe: {
        label: "Job describe",
        type: TYPES.TEXT,
        textArea: true,
        dependsOn: "jobType",
        dependantOnValues: jobsWithInfo,
      },
    },
  },
  previousJobsInfoSegment: {
    label: "Previous work and education information",
    type: TYPES.SEGMENT,
    customDependency: (applicant) => {
      if (moment().diff(moment(applicant?.birthDate), "years") >= 14) {
        return true;
      }
    },
    data: {
      hasBeenPreviouslyEmployed: {
        label: "Has been previously employed",
        type: TYPES.CHECKBOX,
        customDependency: (applicant) => {
          if (moment().diff(moment(applicant?.birthDate), "years") >= 14) {
            return true;
          }
        },
      },
      previousJobs: {
        label: "Previous jobs",
        type: TYPES.ARRAY,
        dependsOn: "hasBeenPreviouslyEmployed",
        data: {
          employer: {
            label: "Employer",
            type: TYPES.TEXT,
            path: "previousJobs.$previousJobs",
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            nested: AddressSchema("previousJobs.$previousJobs.address"),
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "previousJobs.$previousJobs",
          },
          jobTitle: {
            label: "Job title",
            type: TYPES.TEXT,
            path: "previousJobs.$previousJobs",
          },
          hasSupervisorFirstName: {
            label: "Has supervisor first name",
            type: TYPES.CHECKBOX,
            path: "previousJobs.$previousJobs",
            required: false,
          },
          supervisorFirstName: {
            label: "Supervisor first name / given name",
            type: TYPES.TEXT,
            dependsOn: "previousJobs.$previousJobs.hasSupervisorFirstName",
            path: "previousJobs.$previousJobs",
          },
          hasSupervisorLastName: {
            label: "Has supervisor last name",
            type: TYPES.CHECKBOX,
            path: "previousJobs.$previousJobs",
            required: false,
          },
          supervisorLastName: {
            label: "Supervisor last name / surname",
            type: TYPES.TEXT,
            dependsOn: "previousJobs.$previousJobs.hasSupervisorLastName",
            path: "previousJobs.$previousJobs",
          },
          dateStart: {
            label: "Date started doing job",
            type: TYPES.DATE,
            path: "previousJobs.$previousJobs",
          },
          dateEnd: {
            label: "Date ended job",
            type: TYPES.DATE,
            path: "previousJobs.$previousJobs",
          },
          describe: {
            label: "Job describe",
            textArea: true,
            type: TYPES.TEXT,
            path: "previousJobs.$previousJobs",
          },
        },
      },
      hasAttendedEducationalInstitutions: {
        label: "Has attender educational institutions",
        type: TYPES.CHECKBOX,
        customDependency: (applicant) => {
          if (moment().diff(moment(applicant?.birthDate), "years") >= 14) {
            return true;
          }
        },
      },
      educationalInstitutions: {
        label: "Educational institutions",
        type: TYPES.ARRAY,
        dependsOn: "hasAttendedEducationalInstitutions",
        data: {
          name: {
            label: "Name",
            type: TYPES.TEXT,
            path: "educationalInstitutions.$educationalInstitutions",
          },
          address: {
            label: "Address",
            type: TYPES.NESTED,
            newTable: true,
            nested: AddressSchema(
              "educationalInstitutions.$educationalInstitutions.address",
            ),
            path: "educationalInstitutions.$educationalInstitutions",
          },
          courseOfStudy: {
            label: "Course of study",
            type: TYPES.TEXT,
            path: "educationalInstitutions.$educationalInstitutions",
          },
          dateStart: {
            label: "Start date",
            type: TYPES.DATE,
            path: "educationalInstitutions.$educationalInstitutions",
          },
          dateEnd: {
            label: "End date",
            type: TYPES.DATE,
            path: "educationalInstitutions.$educationalInstitutions",
          },
        },
      },
    },
  },
  additionalInfoSegment: {
    label: "Additional work/education/training information",
    type: TYPES.SEGMENT,
    customDependency: (applicant) => {
      if (moment().diff(moment(applicant?.birthDate), "years") >= 14) {
        return true;
      }
    },
    data: {
      belongsToTribe: {
        label: "Belongs to tribe",
        type: TYPES.CHECKBOX,
        customDependency: (applicant) => {
          if (moment().diff(moment(applicant?.birthDate), "years") >= 14) {
            return true;
          }
        },
      },
      tribeName: {
        label: "Tribe name",
        type: TYPES.TEXT,
        dependsOn: "belongsToTribe",
      },
      speakingLanguages: {
        label: "Speaking languages",
        type: TYPES.MULTI_TEXT,
        customDependency: (applicant) => {
          if (moment().diff(moment(applicant?.birthDate), "years") >= 14) {
            return true;
          }
        },
      },
      hasOtherSpeakingLanguages: {
        label: "Has other speaking languages",
        type: TYPES.CHECKBOX,
        customDependency: (applicant) => {
          if (moment().diff(moment(applicant?.birthDate), "years") >= 14) {
            return true;
          }
        },
      },
      otherSpeakingLanguages: {
        label: "Other speaking languages",
        dependsOn: "hasOtherSpeakingLanguages",
        type: TYPES.MULTI_TEXT,
      },
      hasTraveledWithinFiveYear: {
        label: "Has traveled within 5 years",
        type: TYPES.CHECKBOX,
        customDependency: (applicant) => {
          if (moment().diff(moment(applicant?.birthDate), "years") >= 14) {
            return true;
          }
        },
      },
      travelCountries: {
        label: "Travel countries",
        dependsOn: "hasTraveledWithinFiveYear",
        type: TYPES.MULTI_SELECT,
        values: DS_SELECT_VALUES.COUNTRIES_3,
      },
      hasWorkedToOrganization: {
        label: "Has worked to organization",
        type: TYPES.CHECKBOX,
        customDependency: (applicant) => {
          if (moment().diff(moment(applicant?.birthDate), "years") >= 14) {
            return true;
          }
        },
      },
      organizations: {
        label: "Organizations",
        type: TYPES.MULTI_TEXT,
        dependsOn: "hasWorkedToOrganization",
      },
      hasServedMilitary: {
        label: "Has served military",
        type: TYPES.CHECKBOX,
        customDependency: (applicant) => {
          if (moment().diff(moment(applicant?.birthDate), "years") >= 14) {
            return true;
          }
        },
      },
      militaryExperiences: {
        label: "Military experience",
        type: TYPES.ARRAY,
        dependsOn: "hasServedMilitary",
        data: {
          country: {
            label: "Country",
            type: TYPES.SELECT,
            values: DS_SELECT_VALUES.COUNTRIES_5,
            path: "militaryExperiences.$militaryExperiences",
          },
          service: {
            label: "Service",
            type: TYPES.TEXT,
            path: "militaryExperiences.$militaryExperiences",
          },
          rank: {
            label: "Rank",
            type: TYPES.TEXT,
            path: "militaryExperiences.$militaryExperiences",
          },
          speciality: {
            label: "Speciality",
            type: TYPES.TEXT,
            path: "militaryExperiences.$militaryExperiences",
          },
          dateStart: {
            label: "Date start",
            type: TYPES.DATE,
            path: "militaryExperiences.$militaryExperiences",
          },
          dateEnd: {
            label: "Date end",
            type: TYPES.DATE,
            path: "militaryExperiences.$militaryExperiences",
          },
        },
      },
      hasExplosiveExperience: {
        label: "Has explosive experience",
        type: TYPES.CHECKBOX,
        customDependency: (applicant) => {
          if (moment().diff(moment(applicant?.birthDate), "years") >= 14) {
            return true;
          }
        },
      },
      explosiveExplain: {
        label: "Explosive experience explain",
        type: TYPES.TEXT,
        textArea: true,
        dependsOn: "hasExplosiveExperience",
      },
    },
  },
  securityQuestionsSegment: {
    label: "Security questions",
    type: TYPES.SEGMENT,
    data: {
      communicableDisease: {
        label: "Has communicable disease",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      communicableDiseaseExplain: {
        label: "Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.communicableDisease",
      },
      disorder: {
        label: "Disorder",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      disorderExplain: {
        label: "Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.disorder",
      },
      drugAddict: {
        label: "Drug addict",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      drugAddictExplain: {
        label: "Drug addict explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.drugAddict",
      },
      arrested: {
        label: "Arrested",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      arrestedExplain: {
        label: "Arrested explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.arrested",
      },
      controlledSubstances: {
        label: "Controlled Substances",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      controlledSubstancesExplain: {
        label: "Controlled Substances Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.controlledSubstances",
      },
      prostitution: {
        label: "Prostitution",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      prostitutionExplain: {
        label: "Prostitution Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.prostitution",
      },
      moneyLaundering: {
        label: "Money Laundering",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      moneyLaunderingExplain: {
        label: "Money Laundering Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.moneyLaundering",
      },
      trafficking: {
        label: "Trafficking",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      traffickingExplain: {
        label: "Trafficking Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.trafficking",
      },
      assistedTrafficking: {
        label: "Assisted Trafficking",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      assistedTraffickingExplain: {
        label: "Assisted Trafficking Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.assistedTrafficking",
      },
      relativeTrafficking: {
        label: "Relative Trafficking",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      relativeTraffickingExplain: {
        label: "Relative Trafficking Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.relativeTrafficking",
      },
      illegalActivity: {
        label: "Illegal Activity",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      illegalActivityExplain: {
        label: "Illegal Activity Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.illegalActivity",
      },
      terroristActivity: {
        label: "Terrorist Activity",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      terroristActivityExplain: {
        label: "Terrorist Activity Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.terroristActivity",
      },
      financingTerrorists: {
        label: "Financing Terrorists",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      financingTerroristsExplain: {
        label: "Financing Terrorists Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.financingTerrorists",
      },
      terroristMember: {
        label: "Terrorist Member",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      terroristMemberExplain: {
        label: "Terrorist Member Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.terroristMember",
      },
      hasTerroristRelative: {
        label: "Has Terrorist Relative",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      hasTerroristRelativeExplain: {
        label: "Has Terrorist Relative Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.hasTerroristRelative",
      },
      genocide: {
        label: "Genocide",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      genocideExplain: {
        label: "Genocide Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.genocide",
      },
      torture: {
        label: "Torture",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      tortureExplain: {
        label: "Torture Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.torture",
      },
      killings: {
        label: "Killings",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      killingsExplain: {
        label: "Killings Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.killings",
      },
      childSoldier: {
        label: "Child Soldier",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      childSoldierExplain: {
        label: "Child Soldier Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.childSoldier",
      },
      religiousFreedom: {
        label: "Religious Freedom",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      religiousFreedomExplain: {
        label: "Religious Freedom Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.religiousFreedom",
      },
      sterilization: {
        label: "Sterilization",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      sterilizationExplain: {
        label: "Sterilization Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.sterilization",
      },
      transplantation: {
        label: "Transplantation",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      transplantationExplain: {
        label: "Transplantation Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.transplantation",
      },
      visaFraud: {
        label: "Visa Fraud",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      visaFraudExplain: {
        label: "Visa Fraud Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.visaFraud",
      },
      wasDeported: {
        label: "Was Deported",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      wasDeportedExplain: {
        label: "Was Deported Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.wasDeported",
      },
      withheldCustody: {
        label: "Withheld Custody",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      withheldCustodyExplain: {
        label: "Withheld Custody Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.withheldCustody",
      },
      voted: {
        label: "Voted",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      votedExplain: {
        label: "Voted Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.voted",
      },
      avoidTax: {
        label: "Avoid Tax",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
      },
      avoidTaxExplain: {
        label: "Avoid Tax Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.avoidTax",
      },
      insurgentOrganizationMember: {
        label: "Insurgent Organization Member",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
        customDependency: (applicant) => {
          if (moment().diff(moment(applicant?.birthDate), "years") >= 14) {
            return true;
          }
        },
      },
      insurgentOrganizationMemberExplain: {
        label: "Insurgent Organization Member Explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.insurgentOrganizationMember",
      },
      wasSubjectOfDeport: {
        label: "Was subject of deportation",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
        dependsOn: "haveYouEverBeenToUS",
      },
      wasSubjectOfDeportExplain: {
        label: "Was subject of deportation explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.wasSubjectOfDeport",
      },
      failedAttendRemovability: {
        label: "Failed to attend a hearing on removability",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
        dependsOn: "haveYouEverBeenToUS",
      },
      failedAttendRemovabilityExplain: {
        label: "Failed to attend a hearing on removability explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.failedAttendRemovability",
      },
      unlawfullyPresentOverstayed: {
        label: "Unlawfully present overstayed",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
        dependsOn: "haveYouEverBeenToUS",
      },
      unlawfullyPresentOverstayedExplain: {
        label: "Unlawfully present overstayed explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.unlawfullyPresentOverstayed",
      },
      attendedPublicSchool: {
        label: "Attended public school",
        type: TYPES.CHECKBOX,
        path: "securityAndBackground",
        dependsOn: "haveYouEverBeenToUS",
      },
      attendedPublicSchoolExplain: {
        label: "Attended public school explain",
        type: TYPES.TEXT,
        textArea: true,
        path: "securityAndBackground",
        dependsOn: "securityAndBackground.attendedPublicSchool",
      },
    },
  },
};
