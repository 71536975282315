import React, { useCallback, useEffect } from "react";
import {
  getTicketDisputesApi,
  resolveTicketDisputeApi,
} from "../../../../../../../../apis/departments/qa/QATickets";
import { usePagination } from "../../../../../../../../hooks/usePagination.hook";
import { useFilters } from "../../../../../../../../hooks/useFilters.hook";
import {
  Table,
  Segment,
  Loader,
  Header,
  Input,
  Select,
} from "semantic-ui-react";
import { PRODUCT_NAMES_MAPPING } from "../../../../Products/constants/productNames";
import { ZENDESK_HOSTS } from "../../../constants/zendesk";
import toastr from "toastr";
import TicketDisputeModal from "../../../components/QATicketTable/components/TicketDisputeModal";
import {
  DISPUTE_STATUS_MAPPING,
  QA_EVALUATION_TICKET_DISPUTE_STATUS,
} from "../../../../../constants/qaTicketStatuses";

const DisputesTable = ({ disableActions }) => {
  const {
    items: disputes,
    loadItems: loadDisputes,
    setItems: setDisputes,
    Paginator,
  } = usePagination(getTicketDisputesApi, {
    clearOnLoad: false,
    initialLoad: false,
  });
  const { filters, setFilter } = useFilters(
    {
      zendeskHost: [ZENDESK_HOSTS[0].link],
    },
    {
      localStorageKey: "disputes-filter",
      temporaryFilters: ["zendeskId"],
    },
  );

  useEffect(() => {
    const normalizedFilters = {};
    Object.entries(filters).forEach(([prop, value]) => {
      if (value) {
        normalizedFilters[prop] = value;
      }
    });
    loadDisputes(1, normalizedFilters);
  }, [filters, loadDisputes]);

  const onDisputeResolution = useCallback(
    async (disputeId, disputeResolutionBody) => {
      try {
        const {
          data: { dispute },
        } = await resolveTicketDisputeApi(disputeId, disputeResolutionBody);
        setDisputes(
          disputes.map((stateDispute) =>
            stateDispute._id === disputeId
              ? { ...stateDispute, ...dispute }
              : stateDispute,
          ),
        );
        toastr.success("Successfully resolved dispute");
      } catch (err) {
        toastr.error("Failed resolving dispute!");
      }
    },
    [disputes, setDisputes],
  );

  if (!disputes) {
    return <Loader active />;
  }

  return (
    <Segment.Group>
      <Segment className="tableTopSegment" inverted>
        <Header className="tableTitle" size="large" floated="left">
          <h3>Ticket Disputes</h3>
        </Header>
      </Segment>
      <div className="sk-table-filters">
        <Input
          label={"Zendesk ID"}
          value={filters.zendeskId}
          type="text"
          onChange={(ev, { value }) => {
            setFilter("zendeskId", value);
          }}
        />
        <Select
          placeholder="Select status"
          multiple
          options={Object.entries(QA_EVALUATION_TICKET_DISPUTE_STATUS).map(
            ([value]) => ({
              text: DISPUTE_STATUS_MAPPING[value],
              value: value,
              key: value,
            }),
          )}
          value={filters.status || []}
          onChange={(ev, { value }) => {
            setFilter("status", value);
          }}
        />
      </div>
      <Table celled striped fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Ticket ID</Table.HeaderCell>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <Table.HeaderCell>Ticket Zendesk ID</Table.HeaderCell>
            <Table.HeaderCell>QA Agent</Table.HeaderCell>
            <Table.HeaderCell>Support Agent</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {disputes.map((dispute) => (
            <Table.Row key={dispute._id}>
              <Table.Cell>
                <a
                  href={`/evaluation-tickets/${dispute.evaluationId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {dispute.evaluationId}
                </a>
              </Table.Cell>
              <Table.Cell>{PRODUCT_NAMES_MAPPING[dispute.product]}</Table.Cell>
              <Table.Cell>
                <a
                  href={dispute.zendeskUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {dispute.zendeskId}
                </a>
              </Table.Cell>
              <Table.Cell>
                {dispute.qaAgent ? dispute.qaAgent.fullName : "Agent deleted"}
              </Table.Cell>
              <Table.Cell>
                {dispute.agent ? dispute.agent.fullName : "Agent deleted"}
              </Table.Cell>
              <Table.Cell>{DISPUTE_STATUS_MAPPING[dispute.status]}</Table.Cell>
              <Table.Cell>
                <TicketDisputeModal
                  disableActions={disableActions}
                  ticketDispute={dispute}
                  onSubmit={(disputeBody) =>
                    onDisputeResolution(dispute._id, disputeBody)
                  }
                  modalState="resolving"
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={7}>
              <Paginator />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment.Group>
  );
};

export default DisputesTable;
