import axios from "./httpClient";

export function getAllReviewsAndCertifications() {
  return axios.get(`/review-and-certification`);
}

export function createReviewAndCertification(body) {
  return axios.post(`/review-and-certification`, body);
}

export function updateReviewAndCertification(id, body) {
  return axios.patch(`/review-and-certification/${id}`, body);
}

export function deleteReviewAndCertification(id) {
  return axios.delete(`/review-and-certification/${id}`);
}
