import { PRODUCT_NAMES } from "../productNames";
import * as ETA from "./eta";
import * as ESTA from "./esta";
import * as DS160 from "./ds160";
import * as NZ from "./nz";
import * as TRK from "./trk";
import * as AUS from "./aus";
import * as IND from "./ind";
import * as USPS from "./usps";
import * as INV from "./inv";
import * as SCHENGEN from "./schengen";
import * as VN from "./vn";
import * as DIVERSITY from "./diversity";
import * as GE from "./ge";
import * as SG from "./sg";
import * as DS11 from './ds11';
import * as DS82 from './ds82';
import * as DS64 from './ds64';
import * as SL from "./sl";
import * as TSA from "./tsa";
import * as SENTRI from "./sentri";
import * as NEXUS from "./nexus";


export default {
  [PRODUCT_NAMES.ETA]: ETA,
  [PRODUCT_NAMES.ESTA]: ESTA,
  [PRODUCT_NAMES.DS160]: DS160,
  [PRODUCT_NAMES.NZ]: NZ,
  [PRODUCT_NAMES.TRK]: TRK,
  [PRODUCT_NAMES.AUS]: AUS,
  [PRODUCT_NAMES.IND]: IND,
  [PRODUCT_NAMES.VN]: VN,
  [PRODUCT_NAMES.USPS]: USPS,
  [PRODUCT_NAMES.INV]: INV,
  [PRODUCT_NAMES.SCHENGEN]: SCHENGEN,
  [PRODUCT_NAMES.DIVERSITY]: DIVERSITY,
  [PRODUCT_NAMES.GE]: GE,
  [PRODUCT_NAMES.SG]: SG,
  [PRODUCT_NAMES.DS11]: DS11,
  [PRODUCT_NAMES.DS82]: DS82,
  [PRODUCT_NAMES.DS64]: DS64,
  [PRODUCT_NAMES.SL]: SL,
  [PRODUCT_NAMES.TSA]: TSA,
  [PRODUCT_NAMES.SENTRI]: SENTRI,
  [PRODUCT_NAMES.NEXUS]: NEXUS,

};
