import React, { useCallback, useState, useMemo } from "react";
import { useParams } from "react-router";
import { Button } from "semantic-ui-react";
import ConfirmPopup from "../../../../../../../../../../components/ConfirmPopup";
import toastr from "toastr";

import { PRODUCT_NAMES } from "../../../../../constants/productNames";
import { changeScheduleStatusApi } from "../../../../../../../../../../apis/applicantApi";
import { useCallbackRef } from "../../../../../../../../../../hooks/useCallbackRef.hook";
import ApplicantView from "../../../../../components/ApplicantView";

const K1ApplicantEdit = () => {
  const { id: applicantId } = useParams();
  const [api] = useState(PRODUCT_NAMES.K1);

  const [applicantView, applicantViewRef] = useCallbackRef();

  const enableScheduleLink = useCallback(async () => {
    if (!applicantView) {
      return;
    }
    const { editingApplicant, changeState } = applicantView;
    try {
      await changeScheduleStatusApi(editingApplicant._id, api);
      toastr.success("Schedule link successfully enabled!");
      changeState("scheduleLinkEnabled", true);
    } catch (err) {
      toastr.error("Failed updating schedule link availability");
    }
  }, [applicantView, api]);

  const customHeader = useMemo(() => {
    if (!applicantView || !applicantView.editingApplicant) {
      return null;
    }
    const { editingApplicant } = applicantView;

    return (
      <React.Fragment>
        <ConfirmPopup
          callback={() => enableScheduleLink()}
          content="Are you sure you want to enable user to enter new schedule information?"
        >
          <Button disabled={editingApplicant.scheduleLinkEnabled} color="green">
            {editingApplicant.scheduleLinkEnabled
              ? "Schedle link is enabled"
              : "Enable schedule link"}
          </Button>
        </ConfirmPopup>
      </React.Fragment>
    );
  }, [applicantView, enableScheduleLink]);

  return (
    <ApplicantView
      ref={applicantViewRef}
      customHeader={customHeader}
      applicantId={applicantId}
      api={api}
    />
  );
};

export default K1ApplicantEdit;
