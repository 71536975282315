import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import {
  Button,
  Checkbox,
  Input,
  Label,
  Modal,
  Radio,
  Segment,
  Header,
  Divider,
  Select,
  Loader,
} from "semantic-ui-react";
import { ApplicantDocumentsContext } from "../../../../../../../../../../common/ApplicantDocumentsContext";
import apis from "../../../../../../../../../../apis";
import { getDateFormat } from "../../../../../../../../../../common/date-format";
import toastr from "toastr";
import moment from "moment";
import Toggle from "../../../../../../../../../../components/Toggle";
import { capitalize } from "../../../../../../../../../../common/string-format";
import { departmentOptions } from "../../../../../../MyAccount/components/DocHandlerTemplates/components/TemplateForm/TemplateForm.component";
import Spinner from "../../../../../../../../../../components/Spinner";
import {
  byteArrayToUrl,
  capitalizeEachWord,
} from "../../../../../../../../../../utils";
import { EVENT_TYPE, LOG_TYPE } from "../../../../../../../../../../constants/logs";
import { useSelector } from "react-redux";

import "./SendEmail.styles.scss";

const SendEmail = () => {
  const {
    api,
    applicantId,
    applicant,
    documentsAgents,
    documentsClients,
    documentsBots,
    documentsFinancial,
    documentsBrochures,
    setDocumentsAgents,
    setDocumentsClients,
    setDocumentsBots,
    setDocumentsFinancial,
    setDocumentsBrochures,
    brochureLanguageOptions,
  } = useContext(ApplicantDocumentsContext);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [cc, setCc] = useState([]);
  const [additionalCc, setAdditionalCc] = useState("");
  const [subject, setSubject] = useState("");
  const [selectedDocumentsBot, setSelectedDocumentsBot] = useState([]);
  const [selectedDocumentsAgents, setSelectedDocumentsAgents] = useState([]);
  const [selectedDocumentsClients, setSelectedDocumentsClients] = useState([]);
  const [selectedDocumentsFinancial, setSelectedDocumentsFinancial] = useState([]);
  const [selectedDocumentsBrochures, setSelectedDocumentsBrochures] = useState(
    [],
  );
  const [emailPreview, setEmailPreview] = useState({});
  const [selectedEmail, setSelectedEmail] = useState({
    templateName: "",
    templateId: "",
    id: "",
  });
  const [documentPreview, setDocumentPreview] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedTemplateLanguage, setSelectedTemplateLanguage] =
    useState("English");
  const [selectedDepartment, setSelectedDepartment] = useState("Level 1");
  const [templateLanguageOptions, setTemplateLanguageOptions] = useState("");
  const [showEmailPreview, setShowEmailPreview] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openedSegments, setOpenedSegments] = useState([
    "bot",
    "agents",
    "clients",
  ]);

  const user = useSelector((state) => state.currentUser);

  const filteredBrochures = useMemo(
    () =>
      documentsBrochures.filter(
        (el) =>
          (!selectedLanguage || el.brochure.language === selectedLanguage) &&
          applicant.productType === el.brochure.visaType,
      ),
    [applicant.productType, documentsBrochures, selectedLanguage],
  );

  const shouldDisableSubmit = useMemo(
    () => isLoading || !selectedEmail.id,
    [isLoading, selectedEmail],
  );

  const shouldDisablePreview = useMemo(() => {
    const hasSelectedDocs =
      selectedDocumentsBot.length > 0 ||
      selectedDocumentsAgents.length > 0 ||
      selectedDocumentsClients.length > 0 ||
      selectedDocumentsFinancial.length > 0 ||
      selectedDocumentsBrochures.length > 0;
    if (isLoading || !hasSelectedDocs) {
      return true;
    }
    return false;
  }, [
    isLoading,
    selectedDocumentsAgents.length,
    selectedDocumentsClients.length,
    selectedDocumentsBot.length,
    selectedDocumentsFinancial.length,
    selectedDocumentsBrochures.length,
  ]);

  const handleUpdateCc = useCallback(
    (checked, value) =>
      checked
        ? setCc((prev) => [...prev, value])
        : setCc((prev) => prev.filter((email) => email !== value)),
    [],
  );

  const handleUpdateDocBot = useCallback(
    (checked, value) =>
      checked
        ? setSelectedDocumentsBot((prev) => [...prev, value])
        : setSelectedDocumentsBot((prev) => prev.filter((id) => id !== value)),
    [],
  );

  const handleUpdateDocAgent = useCallback(
    (checked, value) =>
      checked
        ? setSelectedDocumentsAgents((prev) => [...prev, value])
        : setSelectedDocumentsAgents((prev) =>
            prev.filter((id) => id !== value),
          ),
    [],
  );

  const handleUpdateDocClient = useCallback(
    (checked, value) =>
      checked
        ? setSelectedDocumentsClients((prev) => [...prev, value])
        : setSelectedDocumentsClients((prev) =>
            prev.filter((id) => id !== value),
          ),
    [],
  );

  const handleUpdateDocFinancial = useCallback(
    (checked, value) =>
      checked
        ? setSelectedDocumentsFinancial((prev) => [...prev, value])
        : setSelectedDocumentsFinancial((prev) =>
            prev.filter((id) => id !== value),
          ),
    [],
  );

  const handleUpdateDocBrochure = useCallback(
    (checked, value) =>
      checked
        ? setSelectedDocumentsBrochures((prev) => [...prev, value])
        : setSelectedDocumentsBrochures((prev) =>
            prev.filter((id) => id !== value),
          ),
    [],
  );

  const updateDocumentsAfterEmailSent = useCallback(() => {
    if (selectedDocumentsAgents.length > 0) {
      setDocumentsAgents((prev) =>
        prev.map((el) =>
          selectedDocumentsAgents.includes(el.id)
            ? {
                ...el,
                activeVersion: {
                  ...el.activeVersion,
                  hasAlreadySentToCustomer: true,
                  sentDate: moment().utc(),
                },
              }
            : el,
        ),
      );
    }

    if (selectedDocumentsClients.length > 0) {
      setDocumentsClients((prev) =>
        prev.map((el) =>
          selectedDocumentsClients.includes(el.id)
            ? {
                ...el,
                activeVersion: {
                  ...el.activeVersion,
                  hasAlreadySentToCustomer: true,
                  sentDate: moment().utc(),
                },
              }
            : el,
        ),
      );
    }

    if (selectedDocumentsBot.length > 0) {
      setDocumentsBots((prev) =>
        prev.map((el) =>
          selectedDocumentsBot.includes(el.id)
            ? {
                ...el,
                activeVersion: {
                  ...el.activeVersion,
                  hasAlreadySentToCustomer: true,
                  sentDate: moment().utc(),
                },
              }
            : el,
        ),
      );
    }

    if (selectedDocumentsFinancial.length > 0) {
      setDocumentsFinancial((prev) =>
        prev.map((el) =>
          selectedDocumentsFinancial.includes(el.id)
            ? {
                ...el,
                activeVersion: {
                  ...el.activeVersion,
                  hasAlreadySentToCustomer: true,
                  sentDate: moment().utc(),
                },
              }
            : el,
        ),
      );
    }

    if (selectedDocumentsBrochures.length > 0) {
      setDocumentsBrochures((prev) =>
        prev.map((el) =>
          selectedDocumentsBrochures.includes(el.id)
            ? {
                ...el,
                brochureSentDate: moment().utc(),
                hasBrochureSentToCustomer: true,
              }
            : el,
        ),
      );
    }
  }, [selectedDocumentsAgents, selectedDocumentsClients, selectedDocumentsBot, selectedDocumentsFinancial, selectedDocumentsBrochures, setDocumentsAgents, setDocumentsClients, setDocumentsBots, setDocumentsFinancial, setDocumentsBrochures]);

  const resetState = useCallback(() => {
    setCc([]);
    setAdditionalCc("");
    setSubject("");
    setSelectedDocumentsBot([]);
    setSelectedDocumentsAgents([]);
    setSelectedDocumentsClients([]);
    setSelectedDocumentsFinancial([]);
    setSelectedDocumentsBrochures([]);
    setEmailPreview({});
    setSelectedEmail({ templateName: "", templateId: "", id: "" });
    setSelectedTemplateLanguage("English");
    setSelectedDepartment("");
    setShowEmailPreview(false);
    setErrorMessage("");
  }, []);

  const createEventLog = useCallback(
    async (subject, templateName, applicantDocumentIds) => {
      try {
        const documents = applicantDocumentIds.map((el, ind) => ({
          propertyName: `applicantDocumentId[${ind}]`,
          oldValue: null,
          newValue: el,
        }));
        await apis.logs.createEventLog({
          logType: LOG_TYPE.INSIDE_APPLICANT,
          eventType: EVENT_TYPE.EMAIL_SEND,
          userId: user.id,
          applicantId: applicant.id,
          visaType: api,
          actions: [
            { propertyName: "subject", oldValue: null, newValue: subject },
            {
              propertyName: "templateName",
              oldValue: null,
              newValue: templateName,
            },
            ...documents,
          ],
        });
      } catch (e) {
        console.error(e);
      }
    },
    [api, applicant, user],
  );

  const handleSubmit = useCallback(async () => {
    const applicantDocumentIds = [
      ...selectedDocumentsBot,
      ...selectedDocumentsAgents,
      ...selectedDocumentsClients,
      ...selectedDocumentsFinancial,
      ...selectedDocumentsBrochures,
    ];
    const allCc = additionalCc ? [...cc, additionalCc] : [...cc];

    setIsLoading(true);

    try {
      await apis.applicantDocumentHandler.sendEmail(
        api,
        applicantId,
        applicantDocumentIds,
        allCc,
        subject,
        selectedEmail.templateName,
        selectedEmail.templateId,
      );
      createEventLog(subject, selectedEmail.templateName, applicantDocumentIds);
      setShowEmailPreview(false);
      updateDocumentsAfterEmailSent();
      resetState();
      toastr.success("Successfully sent email.");
      setIsLoading(false);
      setIsOpen(false);
    } catch (error) {
      toastr.error("Failed to send email.");
      console.error(error);
      setIsLoading(false);
    }
  }, [
    selectedDocumentsBot,
    selectedDocumentsAgents,
    selectedDocumentsClients,
    selectedDocumentsFinancial,
    selectedDocumentsBrochures,
    additionalCc,
    cc,
    api,
    applicantId,
    subject,
    selectedEmail,
    updateDocumentsAfterEmailSent,
    resetState,
    createEventLog,
  ]);

  const handleCloseModal = useCallback(() => {
    resetState();
    setIsOpen(false);
  }, [resetState]);

  const handleBack = useCallback(() => {
    setShowEmailPreview(false);
    setEmailPreview({});
    setSelectedEmail({ templateName: "", templateId: "", id: "" });
  }, []);

  const handlePreviewEmail = useCallback(
    async (language, department) => {
      const applicantDocumentIds = [
        ...selectedDocumentsBot,
        ...selectedDocumentsAgents,
        ...selectedDocumentsClients,
        ...selectedDocumentsFinancial,
        ...selectedDocumentsBrochures,
      ];

      try {
        setIsLoading(true);
        setErrorMessage("");
        const { data } = await apis.applicantDocumentHandler.previewEmail(
          api,
          applicantId,
          applicantDocumentIds,
          language,
          department,
        );
        if (data?.message) {
          setEmailPreview({});
          setErrorMessage(data?.message);
        } else setEmailPreview(data);
      } catch (error) {
        toastr.error("Failed to prepare email.");
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      api,
      applicantId,
      selectedDocumentsAgents,
      selectedDocumentsClients,
      selectedDocumentsFinancial,
      selectedDocumentsBot,
      selectedDocumentsBrochures,
    ],
  );

  const fetchLanguages = useCallback(async () => {
    try {
      const { data } =
        await apis.documentHandlerTemplates.getTemplateLanguages();
      setTemplateLanguageOptions(
        data.languages.map((lng) => ({ text: lng, value: lng })),
      );
    } catch (e) {
      toastr.error("Failed to fetch languages.");
      console.error(e);
    }
  }, []);

  const previewDocument = useCallback(async (key, id, type) => {
    try {
      setIsFileLoading(id);
      const {
        data: {
          buffer: { data },
        },
      } = await apis.applicantDocumentHandler.getFile(key);
      const url = byteArrayToUrl(data, type);
      setDocumentPreview((prev) => [...prev, { id, url }]);
    } catch (e) {
      console.error(e);
      toastr.error("Failed to open file");
    } finally {
      setIsFileLoading(false);
    }
  }, []);

  useEffect(() => {
    showEmailPreview && fetchLanguages();
  }, [fetchLanguages, showEmailPreview]);

  useEffect(() => {
    showEmailPreview &&
      handlePreviewEmail(selectedTemplateLanguage, selectedDepartment);
  }, [
    handlePreviewEmail,
    selectedDepartment,
    selectedTemplateLanguage,
    showEmailPreview,
  ]);

  return (
    <Modal
      closeIcon
      className="send-email-modal"
      onClose={handleCloseModal}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      trigger={
        <Button type="button" color="blue">
          Send email
        </Button>
      }
    >
      <Modal.Header>
        {showEmailPreview
          ? `Email to: ${applicant.userEmail}`
          : "Pick template to send"}
      </Modal.Header>
      <Modal.Content className="send-email-modal__content">
        <div className="send-email-modal__content__container">
          {showEmailPreview ? (
            <React.Fragment>
              <div>
                <Select
                  placeholder="Select department"
                  value={selectedDepartment}
                  disabled={isLoading}
                  options={[{ text: "All", value: "" }, ...departmentOptions]}
                  onChange={(ev, field) => {
                    setSelectedDepartment(field.value);
                    setSelectedEmail({
                      templateName: "",
                      templateId: "",
                      id: "",
                    });
                  }}
                />

                <Select
                  placeholder="Select language"
                  value={selectedTemplateLanguage}
                  disabled={isLoading}
                  options={
                    templateLanguageOptions
                      ? [{ text: "All", value: "" }, ...templateLanguageOptions]
                      : []
                  }
                  onChange={(ev, field) => {
                    setSelectedTemplateLanguage(field.value);
                    setSelectedEmail({
                      templateName: "",
                      templateId: "",
                      id: "",
                    });
                  }}
                />
              </div>
              {isLoading ? (
                <Spinner />
              ) : (
                <React.Fragment>
                  {errorMessage ? (
                    <div>{errorMessage}</div>
                  ) : (
                    <React.Fragment>
                      {Object.entries(emailPreview).map(([key, value]) => (
                        <Segment.Group
                          key={value.id}
                          className="document-segment-group"
                        >
                          <div>
                            <Radio
                              id={value.id}
                              value={value.id}
                              checked={value.id === selectedEmail.id}
                              onChange={(_, { id }) =>
                                setSelectedEmail({
                                  templateName: key,
                                  templateId: value.templateId,
                                  id: value.id,
                                })
                              }
                            />
                            <div className="document-segment-group__info">
                              <label 
                                htmlFor={value.id}
                              >{`${key} - ${
                                value.language
                              } - ${value.department.join(", ")}`}</label>
                              <a
                                href={value.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Preview document
                              </a>
                            </div>
                          </div>
                          {selectedEmail.id === value.id && (
                            <div className="new-brochure-modal__content__preview">
                              <object
                                data={value.url}
                                type={"application/pdf"}
                                width="50%"
                                height="100%"
                              >
                                Preview couldn't be loaded, please click on
                                above link to preview uploaded file
                              </object>
                            </div>
                          )}
                        </Segment.Group>
                      ))}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <>
              <div className="send-email-modal__content__container__field">
                <Label className="send-email-modal__content__container__field__label">
                  CC
                </Label>
                <Input
                  name="additionalCc"
                  className="send-email-modal__content__container__field__input"
                  value={additionalCc}
                  onChange={(event) => setAdditionalCc(event.target.value)}
                />
              </div>
              {applicant.additionalEmails?.length > 0 && (
                <div className="send-email-modal__content__container__field">
                  <Label className="send-email-modal__content__container__field__label">
                    Additional CC
                  </Label>
                  <div className="send-email-modal__content__container__field__checkboxes">
                    {applicant.additionalEmails.map((email) => (
                      <Checkbox
                        key={email}
                        label={email}
                        value={email}
                        checked={cc.includes(email)}
                        onChange={(_, { checked, value }) =>
                          handleUpdateCc(checked, value)
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
              <div className="send-email-modal__content__container__field">
                <Label className="send-email-modal__content__container__field__label">
                  Subject
                </Label>
                <Input
                  className="send-email-modal__content__container__field__input"
                  name="subject"
                  value={subject}
                  onChange={(event) => setSubject(event.target.value)}
                />
              </div>

              {api !== 'inv_letter' && <div className="send-email-modal__content__container__field">
                <div className="send-email-modal__content__container__field__header">
                  <Label className="send-email-modal__content__container__field__label">
                    Documents downloaded by BOT
                  </Label>
                  <div className="document-segment-group__info__preview">
                    <span>Show</span>
                    <Toggle
                      checked={openedSegments.includes("bot")}
                      callback={(checked) => {
                        checked
                          ? setOpenedSegments((prev) => [...prev, "bot"])
                          : setOpenedSegments((prev) =>
                              prev.filter((el) => el !== "bot"),
                            );
                      }}
                      disabled={isFileLoading}
                    />
                  </div>
                </div>
                {openedSegments.includes("bot") && (
                  <Segment>
                    {documentsBots.map((applicant, index) => {
                      const { documents } = applicant;
                      return (
                        <>
                          {index > 0 && <Divider />}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                            }}
                          >
                            <Segment className="tableTopSegment" inverted>
                              <Header inverted>
                                {capitalizeEachWord(
                                  `${
                                    applicant.type === "Main"
                                      ? "Main Applicant"
                                      : "Secondary Applicant"
                                  } - ${applicant.firstName} ${
                                    applicant.lastName
                                  }`,
                                )}
                              </Header>
                            </Segment>
                          </div>
                          {documents.length > 0 ? (
                            documents.map(
                              ({
                                id,
                                activeVersion,
                                name,
                              }) => {
                                if (!activeVersion)
                                  return (
                                    <Segment.Group
                                      key={id}
                                      className="document-segment-group"
                                      style={{ color: "red" }}
                                    >
                                      Document "{name}" is missing (doesn't have
                                      active version)
                                    </Segment.Group>
                                  );
                                return (
                                  <Segment.Group
                                    key={id}
                                    className="document-segment-group"
                                  >
                                    <div>
                                      <Checkbox
                                        id={id}
                                      value={id}
                                        checked={selectedDocumentsBot.includes(
                                          id,
                                        )}
                                        onChange={(_, { checked, value }) =>
                                          handleUpdateDocBot(checked, value)
                                        }
                                        disabled={isFileLoading === id}
                                      />
                                      <div className="document-segment-group__info">
                                        <label htmlFor={id}>
                                          {name}
                                          {activeVersion.hasAlreadySentToCustomer && (
                                            <span
                                              style={{ color: "red" }}
                                            >{` (already sent ${getDateFormat(
                                              activeVersion.sentDate,
                                            )})`}</span>
                                          )}
                                        </label>
                                        <div className="document-segment-group__info__preview">
                                          <span>Preview</span>
                                          <Toggle
                                            checked={documentPreview.some(
                                              (el) => el.id === id,
                                            )}
                                            callback={(checked) => {
                                              checked
                                                ? previewDocument(
                                                    activeVersion.fileKey,
                                                    id,
                                                    "application/pdf",
                                                  )
                                                : setDocumentPreview((prev) =>
                                                    prev.filter(
                                                      (el) => el.id !== id,
                                                    ),
                                                  );
                                            }}
                                            disabled={isFileLoading}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {isFileLoading === id && <Loader />}
                                    {documentPreview.some(
                                      (el) => el.id === id,
                                    ) && (
                                      <div className="new-brochure-modal__content__preview">
                                        <object
                                          data={
                                            documentPreview.find(
                                              (el) => el.id === id,
                                            ).url
                                          }
                                          type={"application/pdf"}
                                          width="50%"
                                          height="100%"
                                        >
                                          Preview couldn't be loaded, please
                                          click on above link to preview
                                          uploaded file
                                        </object>
                                      </div>
                                    )}
                                  </Segment.Group>
                                );
                              },
                            )
                          ) : (
                            <Segment.Group className="document-segment-group">
                              No documents.
                            </Segment.Group>
                          )}
                        </>
                      );
                    })}
                  </Segment>
                )}
              </div>}

              {api !== 'inv_letter' && <div className="send-email-modal__content__container__field">
                <div className="send-email-modal__content__container__field__header">
                  <Label className="send-email-modal__content__container__field__label">
                    Documents downloaded by AGENTS
                  </Label>
                  <div className="document-segment-group__info__preview">
                    <span>Show</span>
                    <Toggle
                      checked={openedSegments.includes("agents")}
                      callback={(checked) => {
                        checked
                          ? setOpenedSegments((prev) => [...prev, "agents"])
                          : setOpenedSegments((prev) =>
                              prev.filter((el) => el !== "agents"),
                            );
                      }}
                      disabled={isFileLoading}
                    />
                  </div>
                </div>
                {openedSegments.includes("agents") && (
                  <Segment>
                    {documentsAgents.map((applicant, index) => {
                      const { documents } = applicant;
                      return (
                        <>
                          {index > 0 && <Divider />}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                            }}
                          >
                            <Segment className="tableTopSegment" inverted>
                              <Header inverted>
                                {capitalizeEachWord(
                                  `${
                                    applicant.type === "Main"
                                      ? "Main Applicant"
                                      : "Secondary Applicant"
                                  } - ${applicant.firstName} ${
                                    applicant.lastName
                                  }`,
                                )}
                              </Header>
                            </Segment>
                          </div>
                          {documents.length > 0 ? (
                            documents.map(
                              ({
                                id,
                                activeVersion,
                                name,
                              }) => {
                                if (!activeVersion)
                                  return (
                                    <Segment.Group
                                      key={id}
                                      className="document-segment-group"
                                      style={{ color: "red" }}
                                    >
                                      Document "{name}" is missing (doesn't have
                                      active version)
                                    </Segment.Group>
                                  );
                                return (
                                  <Segment.Group
                                    key={id}
                                    className="document-segment-group"
                                  >
                                    <div>
                                      <Checkbox
                                        id={id}
                                      value={id}
                                        checked={selectedDocumentsAgents.includes(
                                          id,
                                        )}
                                        onChange={(_, { checked, value }) =>
                                          handleUpdateDocAgent(checked, value)
                                        }
                                        disabled={isFileLoading === id}
                                      />
                                      <div className="document-segment-group__info">
                                        <label htmlFor={id}>
                                          {name}
                                          {activeVersion.hasAlreadySentToCustomer && (
                                            <span
                                              style={{ color: "red" }}
                                            >{` (already sent ${getDateFormat(
                                              activeVersion.sentDate,
                                            )})`}</span>
                                          )}
                                        </label>
                                        <div className="document-segment-group__info__preview">
                                          <span>Preview</span>
                                          <Toggle
                                            checked={documentPreview.some(
                                              (el) => el.id === id,
                                            )}
                                            callback={(checked) => {
                                              checked
                                                ? previewDocument(
                                                    activeVersion.fileKey,
                                                    id,
                                                    "application/pdf",
                                                  )
                                                : setDocumentPreview((prev) =>
                                                    prev.filter(
                                                      (el) => el.id !== id,
                                                    ),
                                                  );
                                            }}
                                            disabled={isFileLoading}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {isFileLoading === id && <Loader />}
                                    {documentPreview.some(
                                      (el) => el.id === id,
                                    ) && (
                                      <div className="new-brochure-modal__content__preview">
                                        <object
                                          data={
                                            documentPreview.find(
                                              (el) => el.id === id,
                                            ).url
                                          }
                                          type={"application/pdf"}
                                          width="50%"
                                          height="100%"
                                        >
                                          Preview couldn't be loaded, please
                                          click on above link to preview
                                          uploaded file
                                        </object>
                                      </div>
                                    )}
                                  </Segment.Group>
                                );
                              },
                            )
                          ) : (
                            <Segment.Group className="document-segment-group">
                              No documents.
                            </Segment.Group>
                          )}
                        </>
                      );
                    })}
                  </Segment>
                )}
              </div>}

              <div className="send-email-modal__content__container__field">
                <div className="send-email-modal__content__container__field__header">
                  <Label className="send-email-modal__content__container__field__label">
                    Documents downloaded by CLIENTS
                  </Label>
                  <div className="document-segment-group__info__preview">
                    <span>Show</span>
                    <Toggle
                      checked={openedSegments.includes("clients")}
                      callback={(checked) => {
                        checked
                          ? setOpenedSegments((prev) => [...prev, "clients"])
                          : setOpenedSegments((prev) =>
                              prev.filter((el) => el !== "clients"),
                            );
                      }}
                      disabled={isFileLoading}
                    />
                  </div>
                </div>
                {openedSegments.includes("clients") && (
                  <Segment>
                    {documentsClients.map((applicant, index) => {
                      const { documents } = applicant;
                      return (
                        <>
                          {index > 0 && <Divider />}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                            }}
                          >
                            <Segment className="tableTopSegment" inverted>
                              <Header inverted>
                                {capitalizeEachWord(
                                  `${
                                    applicant.type === "Main"
                                      ? "Main Applicant"
                                      : "Secondary Applicant"
                                  } - ${applicant.firstName} ${
                                    applicant.lastName
                                  }`,
                                )}
                              </Header>
                            </Segment>
                          </div>
                          {documents.length > 0 ? (
                            documents.map(
                              ({
                                id,
                                activeVersion,
                                name,
                              }) => {
                                if (!activeVersion)
                                  return (
                                    <Segment.Group
                                      key={id}
                                      className="document-segment-group"
                                      style={{ color: "red" }}
                                    >
                                      Document "{name}" is missing (doesn't have
                                      active version)
                                    </Segment.Group>
                                  );
                                return (
                                  <Segment.Group
                                    key={id}
                                    className="document-segment-group"
                                  >
                                    <div>
                                      <Checkbox
                                        id={id}
                                      value={id}
                                        checked={selectedDocumentsClients.includes(
                                          id,
                                        )}
                                        onChange={(_, { checked, value }) =>
                                          handleUpdateDocClient(checked, value)
                                        }
                                        disabled={isFileLoading === id}
                                      />
                                      <div className="document-segment-group__info">
                                        <label htmlFor={id}>
                                          {capitalize(name)}
                                          {activeVersion.hasAlreadySentToCustomer && (
                                            <span
                                              style={{ color: "red" }}
                                            >{` (already sent ${getDateFormat(
                                              activeVersion.sentDate,
                                            )})`}</span>
                                          )}
                                        </label>
                                        <div className="document-segment-group__info__preview">
                                          <span>Preview</span>
                                          <Toggle
                                            checked={documentPreview.some(
                                              (el) => el.id === id,
                                            )}
                                            callback={(checked) => {
                                              checked
                                                ? previewDocument(
                                                    activeVersion.fileKey,
                                                    id,
                                                    [
                                                      "passport",
                                                      "portrait",
                                                      "firstChildPortrait",
                                                      "secondChildPortrait",
                                                    ].includes(name)
                                                      ? "image/jpg"
                                                      : "application/pdf",
                                                  )
                                                : setDocumentPreview((prev) =>
                                                    prev.filter(
                                                      (el) => el.id !== id,
                                                    ),
                                                  );
                                            }}
                                            disabled={isFileLoading}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {isFileLoading === id && <Loader />}
                                    {documentPreview.some(
                                      (el) => el.id === id,
                                    ) && (
                                      <div className="new-brochure-modal__content__preview">
                                        <object
                                          data={
                                            documentPreview.find(
                                              (el) => el.id === id,
                                            ).url
                                          }
                                          type={"image/jpg"}
                                          width="50%"
                                          height="100%"
                                        >
                                          Preview couldn't be loaded, please
                                          click on above link to preview
                                          uploaded file
                                        </object>
                                      </div>
                                    )}
                                  </Segment.Group>
                                );
                              },
                            )
                          ) : (
                            <Segment.Group className="document-segment-group">
                              No documents.
                            </Segment.Group>
                          )}
                        </>
                      );
                    })}
                  </Segment>
                )}
              </div>

              {api !== 'inv_letter' && <div className="send-email-modal__content__container__field">
                <Label className="send-email-modal__content__container__field__label">
                  Financial Documents
                </Label>
                <Segment>
                  {documentsFinancial.map((applicant, index) => {
                    const { documents } = applicant;
                    return (
                      <>
                        {index > 0 && <Divider />}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "16px",
                          }}
                        >
                          <Segment className="tableTopSegment" inverted>
                            <Header inverted>
                              {capitalizeEachWord(
                                `${
                                  applicant.type === "Main"
                                    ? "Main Applicant"
                                    : "Secondary Applicant"
                                } - ${applicant.firstName} ${
                                  applicant.lastName
                                }`,
                              )}
                            </Header>
                          </Segment>
                        </div>
                        {documents.length > 0 ? (
                          documents.map(
                            ({
                              id,
                              activeVersion,
                              name,
                            }) => {
                              if (!activeVersion)
                                return (
                                  <Segment.Group
                                    key={id}
                                    className="document-segment-group"
                                    style={{ color: "red" }}
                                  >
                                    Document "{name}" is missing (doesn't have
                                    active version)
                                  </Segment.Group>
                                );
                              return (
                                <Segment.Group
                                  key={id}
                                  className="document-segment-group"
                                >
                                  <div>
                                    <Checkbox
                                      value={id}
                                      checked={selectedDocumentsFinancial.includes(
                                        id,
                                      )}
                                      onChange={(_, { checked, value }) =>
                                        handleUpdateDocFinancial(checked, value)
                                      }
                                      disabled={isFileLoading === id}
                                    />
                                    <div className="document-segment-group__info">
                                      <span>
                                        {name}
                                        {activeVersion.hasAlreadySentToCustomer && (
                                          <span
                                            style={{ color: "red" }}
                                          >{` (already sent ${getDateFormat(
                                            activeVersion.sentDate,
                                          )})`}</span>
                                        )}
                                      </span>
                                      <div className="document-segment-group__info__preview">
                                        <span>Preview</span>
                                        <Toggle
                                          checked={documentPreview.some((el) => el.id === id)}
                                          callback={(checked) => {
                                            checked
                                            ? previewDocument(activeVersion.fileKey, id, "application/pdf")
                                            : setDocumentPreview((prev) => prev.filter((el) => el.id !== id))
                                          }}
                                          disabled={isFileLoading}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {isFileLoading === id && <Loader />}
                                  {documentPreview.some((el) => el.id === id ) && (
                                    <div className="new-brochure-modal__content__preview">
                                      <object
                                        data={documentPreview.find((el) => el.id === id).url}
                                        type={"application/pdf"}
                                        width="50%"
                                        height="100%"
                                      >
                                        Preview couldn't be loaded, please click
                                        on above link to preview uploaded file
                                      </object>
                                    </div>
                                  )}
                                </Segment.Group>
                              );
                            },
                          )
                        ) : (
                          <Segment.Group className="document-segment-group">
                            No documents.
                          </Segment.Group>
                        )}
                      </>
                    );
                  })}
                </Segment>
              </div>}

              {api !== 'inv_letter' && <div className="send-email-modal__content__container__field">
                <div className="send-email-modal__content__container__field__header">
                  <div>
                    <Label className="send-email-modal__content__container__field__label">
                      Brochures
                    </Label>
                    {openedSegments.includes("brochures") && (
                      <Select
                        placeholder="Select language"
                        value={selectedLanguage}
                        options={brochureLanguageOptions || []}
                        onChange={(ev, field) =>
                          setSelectedLanguage(field.value)
                        }
                      />
                    )}
                  </div>
                  <div className="document-segment-group__info__preview">
                    <span>Show</span>
                    <Toggle
                      checked={openedSegments.includes("brochures")}
                      callback={(checked) => {
                        checked
                          ? setOpenedSegments((prev) => [...prev, "brochures"])
                          : setOpenedSegments((prev) =>
                              prev.filter((el) => el !== "brochures"),
                            );
                      }}
                      disabled={isFileLoading}
                    />
                  </div>
                </div>
                {openedSegments.includes("brochures") && (
                  <Segment>
                    {filteredBrochures.length > 0 ? (
                      filteredBrochures.map(
                        ({
                          id,
                          brochure: {
                            name,
                            language,
                            activeVersion,
                          },
                          hasBrochureSentToCustomer,
                          brochureSentDate,
                        }) => {
                          if (!activeVersion)
                            return (
                              <Segment.Group
                                key={id}
                                className="document-segment-group"
                                style={{ color: "red" }}
                              >
                                Document "{name}" is missing (doesn't have
                                active version)
                              </Segment.Group>
                            );
                          return (
                            <Segment.Group
                              key={id}
                              className="document-segment-group"
                            >
                              <div>
                                <Checkbox
                                  id={id}
                                value={id}
                                  checked={selectedDocumentsBrochures.includes(
                                    id,
                                  )}
                                  onChange={(_, { checked, value }) =>
                                    handleUpdateDocBrochure(checked, value)
                                  }
                                  disabled={isFileLoading === id}
                                />
                                <div className="document-segment-group__info">
                                  <label htmlFor={id}>
                                    {`${name} - ${language}`}
                                    {hasBrochureSentToCustomer && (
                                      <span
                                        style={{ color: "red" }}
                                      >{` (already sent ${getDateFormat(
                                        brochureSentDate,
                                      )})`}</span>
                                    )}
                                  </label>
                                  <div className="document-segment-group__info__preview">
                                    <span>Preview</span>
                                    <Toggle
                                      checked={documentPreview.some(
                                        (el) => el.id === id,
                                      )}
                                      callback={(checked) => {
                                        checked
                                          ? previewDocument(
                                              activeVersion.brochureKey,
                                              id,
                                              "application/pdf",
                                            )
                                          : setDocumentPreview((prev) =>
                                              prev.filter((el) => el.id !== id),
                                            );
                                      }}
                                      disabled={isFileLoading}
                                    />
                                  </div>
                                </div>
                              </div>
                              {isFileLoading === id && <Loader />}
                              {documentPreview.some((el) => el.id === id) && (
                                <div className="new-brochure-modal__content__preview">
                                  <object
                                    data={
                                      documentPreview.find((el) => el.id === id)
                                        .url
                                    }
                                    type={"application/pdf"}
                                    width="50%"
                                    height="100%"
                                  >
                                    Preview couldn't be loaded, please click on
                                    above link to preview uploaded file
                                  </object>
                                </div>
                              )}
                            </Segment.Group>
                          );
                        },
                      )
                    ) : (
                      <Segment.Group className="document-segment-group">
                        No documents.
                      </Segment.Group>
                    )}
                  </Segment>
                )}
              </div>
              }
            </>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={handleCloseModal}>
          Cancel
        </Button>
        {showEmailPreview ? (
          <>
            <Button type="button" onClick={handleBack}>
              Back
            </Button>
            <Button
              color="blue"
              type="button"
              onClick={handleSubmit}
              disabled={shouldDisableSubmit}
              loading={isLoading}
            >
              Send
            </Button>
          </>
        ) : (
          <Button
            color="blue"
            type="button"
            onClick={() => {
              setShowEmailPreview(true);
            }}
            disabled={shouldDisablePreview}
            loading={isLoading}
          >
            Preview email
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default SendEmail;
