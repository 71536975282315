import React, { Component } from "react";
import { Popup, Button } from "semantic-ui-react";

class SmallConfirmPopup extends Component {
  state = { open: false };

  open = (event) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState({ open: true });
  };
  close = (event) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState({ open: false });
  };
  confirm = (event) => {
    this.close(event);
    const { callback } = this.props;
    callback();
  };

  render() {
    const {
      children,
      content,
      header,
      position,
      size,
      pinned,
      inverted,
    } = this.props;
    return (
      <Popup
        trigger={React.cloneElement(children, { onClick: this.open })}
        header={header}
        content={
          content ? (
            React.cloneElement(content, { onClick: this.confirm })
          ) : (
            <Button onClick={this.confirm}>Confirm</Button>
          )
        }
        position={position ? position : "top right"}
        size={size ? size : "small"}
        on="click"
        pinned={pinned !== undefined ? pinned : false}
        inverted={inverted !== undefined ? inverted : true}
        open={this.state.open}
        onClose={this.close}
      />
    );
  }
}

export default SmallConfirmPopup;
