import React from "react";
import { HeaderContent, Header, Loader } from "semantic-ui-react";

const LoadingModalContent = ({ message }) => {
  return (
    <Loader inverted active size="large">
      <Header as="h3" color="black">
        <HeaderContent>{message}</HeaderContent>
      </Header>
    </Loader>
  );
};

export default LoadingModalContent;
