import React, { Component } from "react";
import { Button, Modal } from "semantic-ui-react";
import {
  checkAvailableAppointmentsApi,
  getLetterInfoApi,
  resendCustomerEmailApi,
  resendSponsorEmailApi,
} from "../../../../../../../../../apis/eVisaExtras/dsLettersApi";
import { resendPhotoToolEmailApi } from "../../../../../../../../../apis/applicantApi";
import Spinner from "../../../../../../../../../components/Spinner";
import ConfirmPopup from "../../../../../../../../../components/ConfirmPopup";
import * as toastr from "toastr";
import { propetyToReadable } from "../../../../../../../../../common/string-format";
import { logServerError } from "../../../../../../../../../common/logs";
import applicantDocumentsHandler from "../../../../../../../../../apis/applicantDocumentsHandler";
import { byteArrayToUrl } from "../../../../../../../../../utils";
import { APPLICANT_STATUSES } from "../../../../constants/applicantStatuses";
import { APPLICATION_COUNTRY_TO_EMBASSY_COUNTRY, EMBASSY_COUNTRY_TO_EMBASSY_TYPE } from "./constants";
import ModalForm from "../../../../../../../../../components/common/ModalForm";
import CheckTypeCForm from "./CheckTypeCForm";

class DSLettersApi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }
  componentDidMount() {
    this.loadLetterData();
  }

  async loadLetterData() {
    const { applicantId, api } = this.props;
    try {
      const { data } = await getLetterInfoApi(applicantId, api);
      this.setState({ letterData: data, isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }

  async resendCustomerEmail() {
    const { applicantId, api } = this.props;
    try {
      this.setState({ isLoading: true });
      await resendCustomerEmailApi(applicantId, api);
      toastr.success("Succesfully sent email");
    } catch (err) {
      toastr.error("Failed to resend customer email");
    }
    this.setState({ isLoading: false });
  }

  async resendSponsorEmail() {
    const { applicantId, api } = this.props;
    try {
      this.setState({ isLoading: true });
      await resendSponsorEmailApi(applicantId, api);
      toastr.success("Succesfully sent email");
    } catch (err) {
      toastr.error("Failed to resend sponsor email");
    }
    this.setState({ isLoading: false });
  }

  async sendPhotoToolEmailHandler(applicantId) {
    try {
      await resendPhotoToolEmailApi(applicantId, "ds160");
      toastr.success("Email sent successfully");
    } catch (err) {
      logServerError(err);
    }
  }

  async checkAvailableAppointments(usConsulate) {
    const { applicantId, api } = this.props;
    try {
      this.setState({ isLoading: true });
      await checkAvailableAppointmentsApi(applicantId, api, usConsulate);
      toastr.success("Succesfully started checking available appointments");
    } catch (err) {
      let message = err.message;
      if (err.response) {
        message = err.response.data?.message;
      }
      toastr.error(message);
    }
    this.setState({ isLoading: false });
  }

  appointmentsEligibleStatuses = [
    APPLICANT_STATUSES["Embassy account created"],
    APPLICANT_STATUSES["Interview Scheduled"],
    APPLICANT_STATUSES["Biometrical appointment"],
    APPLICANT_STATUSES["Drop off Appointment"],
  ];

  async openDocument(key) {
    try {
      const {
        data: {
          buffer: { data },
        },
      } = await applicantDocumentsHandler.getFile(key);
      const url = byteArrayToUrl(data, "application/pdf");
      window.open(url, "_blank");
    } catch (e) {
      console.error(e);
      toastr.error("Failed to open file");
    }
  }

  renderCustomerLetterData() {
    const { letterData: { letter } = {} } = this.state;

    if (!letter) {
      return <p>Customer letter data is empty</p>;
    }
    return Object.entries(letter).map(([key, value]) => (
      <p>
        <b>{propetyToReadable(key)}</b>: {value}
      </p>
    ));
  }

  renderSponsorLetterData() {
    const { letterData: { signature } = {} } = this.state;

    if (!signature) {
      return <p>Sponsor data is empty</p>;
    }
    return (
      <React.Fragment>
        <p>
          <b>ID</b>: {signature.id}
        </p>
        <p>
          <b>Letter id</b>: {signature.letter_id}
        </p>
        <p>
          <b>IP</b>: {signature.ip}
        </p>
        <p>
          <b>Agent</b>: {signature.agent}
        </p>
        <p>
          <b>PDF Download</b>:
          <Button onClick={() => this.openDocument(signature.download_url)}>
            Open Invitation Letter
          </Button>
        </p>
        <p>
          <b>Created at</b>: {signature.created_at}
        </p>
        <p>
          <b>Updated at</b>: {signature.updated_at}
        </p>
      </React.Fragment>
    );
  }

  renderServiceExtension(applicantId) {
    return (
      <React.Fragment>
        <Modal.Header>Service Extension Purchase Url</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div className="ui basic segments">
              <div className="ui basic segment">
                <div className="ui input container">
                  <input
                    type="text"
                    value={`https://payments.govassist.com/222/${applicantId}`}
                    onFocus={(e) => {
                      e.target.select();
                    }}
                    readOnly
                  />
                </div>
              </div>
              <div className="ui basic segment">
                Please copy the link above and send to the customer to purchase
                the Service Extension service.
              </div>
            </div>
          </Modal.Description>
        </Modal.Content>
      </React.Fragment>
    );
  }

  renderCheckAvailableAppointments() {
    const { applicationCountry, applicantStatus } = this.props;
    const applicationCountryEmbassy = APPLICATION_COUNTRY_TO_EMBASSY_COUNTRY[
      applicationCountry
    ]
      ? APPLICATION_COUNTRY_TO_EMBASSY_COUNTRY[applicationCountry]
      : undefined;
    const type = EMBASSY_COUNTRY_TO_EMBASSY_TYPE[applicationCountryEmbassy];

    if (this.appointmentsEligibleStatuses.includes(applicantStatus)) {
      if (type === "C") {
        return (
          <ModalForm
            headerText="Choose the U.S. Consulate"
            loaderText="Preparing data..."
            onSubmit={(result) =>
              this.checkAvailableAppointments(result?.usConsulate)
            }
            className="change-type-modal"
            closeOnSubmit
            trigger={
              <div style={{ display: "flex", gap: "10px" }}>
                <Button>Check available appointments</Button>
              </div>
            }
          >
            <CheckTypeCForm applicationCountry={applicationCountryEmbassy} />
          </ModalForm>
        );
      }
      return (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button onClick={() => this.checkAvailableAppointments()}>
            Check available appointments
          </Button>
        </div>
      );
    }
  }

  render() {
    const { isLoading } = this.state;
    const { applicantId, api } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <div style={{ display: "flex", gap: "5px" }}>
        {api !== "inv_letter" && (
          <ConfirmPopup
            content={this.renderServiceExtension(applicantId)}
            cancelButton={null}
            callback={() => void 0}
          >
            <Button>Service Extension</Button>
          </ConfirmPopup>
        )}

        <ConfirmPopup
          cancelButton={null}
          callback={async () => {}}
          content={
            <React.Fragment>
              <Modal.Header>Tools</Modal.Header>
              <Modal.Content>
                <Modal.Description
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <ConfirmPopup
                      content={
                        <React.Fragment>
                          <Modal.Header>Customer Data</Modal.Header>
                          <Modal.Content>
                            <Modal.Description>
                              {this.renderCustomerLetterData()}
                            </Modal.Description>
                          </Modal.Content>
                          <Modal.Header>Sponsor Data</Modal.Header>
                          <Modal.Content>
                            <Modal.Description>
                              {this.renderSponsorLetterData()}
                            </Modal.Description>
                          </Modal.Content>
                        </React.Fragment>
                      }
                      cancelButton={null}
                      callback={async () => {}}
                    >
                      <Button>Preview letter data</Button>
                    </ConfirmPopup>
                    {api !== "inv_letter" && (
                      <ConfirmPopup
                        content="Are you sure you want add resend photo tool email to this user?"
                        callback={() =>
                          this.sendPhotoToolEmailHandler(applicantId)
                        }
                      >
                        <Button color="blue">Resend photo tool email</Button>
                      </ConfirmPopup>
                    )}
                  </div>

                  <div style={{ display: "flex", gap: "10px" }}>
                    <ConfirmPopup
                      content="Are you sure you want to resend CUSTOMER email?"
                      callback={() => this.resendCustomerEmail()}
                    >
                      <Button>Resend & Reset customer invitation letter</Button>
                    </ConfirmPopup>
                    <ConfirmPopup
                      content="Are you sure you want to resend SPONSOR email?"
                      callback={() => this.resendSponsorEmail()}
                    >
                      <Button>Resend sponsor invitation letter</Button>
                    </ConfirmPopup>
                  </div>

                  {this.renderCheckAvailableAppointments()}

                  {/* {this.appointmentsEligibleStatuses.includes(applicantStatus) && 
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Button onClick={() => this.checkAvailableAppointments()}>
                        Check available appointments
                      </Button>
                    </div>} */}
                </Modal.Description>
              </Modal.Content>
            </React.Fragment>
          }
        >
          <Button>Tools</Button>
        </ConfirmPopup>
      </div>
    );
  }
}

export default DSLettersApi;
