import React, { useState } from "react";
import { TopMenu, SideMenu } from "./components";
import ZendeskTopMenu from "./components/ZendeskTopMenu/ZendeskTopMenu.component";

const Layout = ({ zendeskView, children }) => {
  const [sideBarVisible, setSideBarVisible] = useState(false);
  const [sideMenuExtended, setSideMenuExtended] = useState(true);

  return zendeskView ? (
    <>
      <ZendeskTopMenu />
      <div className="main-container__content" style={{ paddingTop: "5em" }}>
        {children}
      </div>
    </>
  ) : (
    <>
      <TopMenu
        setSideMenuExtended={setSideMenuExtended}
        setSideBarVisible={setSideBarVisible}
      />
      <SideMenu
        visible={sideBarVisible}
        setVisible={setSideBarVisible}
        extended={sideMenuExtended}
        setExtended={setSideMenuExtended}
      >
        {children}
      </SideMenu>
    </>
  );
};

export default Layout;
