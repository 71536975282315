export const K1_APPLICANT_STATUSES = {
  New: 0,
  Scheduled: 25,
  Active: 26,
  Completed: 27,
};

export const K1_PAYMENT_STATUSES = {
  Unpaid: 0,
  Paid: 1,
  "Payment refunded": 3,
  "Payment charged back": 4,
};
