import React, { useCallback, useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import apis from "../../../../apis";
import { getDateFormat } from "../../../../common/date-format";

import "./BotMonitoring.styles.scss";

const BOT_NAMES = {
  esta: "ESTA",
  ds160: "VISA",
  "esta-status-checker": "ESTA status checker",
};

const BOT_STATUS_COLOR = {
  ok: "green",
  warning: "orange",
  error: "red",
};

const BotMonitoring = () => {
  const [botStatuses, setBotStatuses] = useState([]);

  const fetchBotMonitoring = useCallback(async () => {
    try {
      const { data } = await apis.botMonitoring.getBotMonitoring();
      setBotStatuses(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchBotMonitoring();
  }, [fetchBotMonitoring]);

  return (
    <div className="dropdown">
      <Dropdown text="Gov Site Status" button icon="bug">
        <Dropdown.Menu>
          <div className="bot-status">
            {botStatuses.map(
              ({ botName, botStatus, statusMessage, updatedAt }) => (
                <Dropdown.Item
                  key={botName}
                  className="bot-status__dropdown-item"
                >
                  <div className="bot-status__item">
                    <div className="bot-status__item__title">
                      <span
                        className="bot-status__item__title__status"
                        style={{
                          backgroundColor: BOT_STATUS_COLOR[botStatus],
                        }}
                      />
                      <span>{BOT_NAMES[botName]}</span>
                      <span style={{ marginLeft: "auto" }}>
                        {getDateFormat(updatedAt)}
                      </span>
                    </div>
                    <div
                      className="bot-status__item__content"
                      style={{ color: BOT_STATUS_COLOR[botStatus] }}
                    >
                      {statusMessage}
                    </div>
                  </div>
                </Dropdown.Item>
              ),
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default BotMonitoring;
