export const LOG_TYPE = {
  INSIDE_APPLICANT: "insideApplicant",
  OUTSIDE_APPLICANT: "outsideApplicant",
};

export const EVENT_TYPE = {
  APPLICANT_EDIT: "applicantEdit",
  DOCUMENT_UPLOAD: "documentUpload",
  STATUS_CHANGE: "statusChange",
  TYPE_CHANGE: "typeChange",
  EMAIL_SEND: "emailSend",
  NAVIGATION: "navigation",
};
