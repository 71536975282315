import React, { useState, useEffect, cloneElement, useMemo } from "react";
import { Modal, Icon, Button } from "semantic-ui-react";
import hashMapColorIcons from "../../common/HashMapColorIcons";
import "./ListNotifications.styles.scss";
import apis from "../../../../../../apis";
import { history } from "../../../../../../modules/history";
import notificationClasses from "../../common/classes";

const ListNotifications = ({ trigger }) => {
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(false);

  const handlerLinkNavigate = ({ linkTo }) => {
    setOpen(false);
    history.push(linkTo);
  };

  useEffect(() => {
    async function getNotifications() {
      try {
        const {
          data: { items },
        } = await apis.notifications.getAllUserNotificationsApi();
        setNotifications(items);
      } catch (err) {
        console.error(err);
      }
    }
    getNotifications();
  }, []);

  trigger = useMemo(
    () =>
      trigger &&
      cloneElement(trigger, {
        onClick: () => setOpen(true),
      }),
    [trigger],
  );

  return (
    <Modal trigger={trigger} onClose={() => setOpen(false)} open={open}>
      <Modal.Header>Last 100 notifications</Modal.Header>
      <Modal.Content className="notifications">
        {notifications.length > 0 ? (
          notifications.map((notification) => {
            return (
              <div
                onClick={() => handlerLinkNavigate(notification)}
                className={notificationClasses(notification)}
                key={notification._id}
              >
                <Icon
                  name={hashMapColorIcons[notification.color] || "info circle"}
                  color={notification.color || "grey"}
                />
                {notification.text} TicketId: {notification._id}
              </div>
            );
          })
        ) : (
          <div className="dropdown__empty-list">No notifications for you</div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="teal" onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default ListNotifications;
