import React, { Component } from "react";
import { Button, Icon } from "semantic-ui-react";
import TwilioService from "../../services/twilioService";
import "./CallStatus.scss";

const APP_TITLE = "Govassist dashboard";

class CallStatus extends Component {
  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      isInCall: false,
      isRinging: false,
      isIncoming: false,
      minimized: false,
    };
  }

  componentDidMount() {
    this.initializeListeners();
  }
  componentWillUnmount() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
  }

  initializeListeners() {
    this.subscriptions.push(
      TwilioService.callRingingObservable.subscribe((data) => {
        const { connection, isIncoming } = data;
        document.title = `(Ringing) ${APP_TITLE}`;
        this.setState({
          isRinging: true,
          isIncoming,
          connection,
          minimized: false,
        });
      }),
    );
    this.subscriptions.push(
      TwilioService.callRingingStoppedObservable.subscribe((data) => {
        const { accepted, isIncoming } = data;
        const newState = { isRinging: false, isIncoming };
        if (!accepted) {
          newState.connection = null;
          document.title = APP_TITLE;
        }
        this.setState(newState);
      }),
    );
    this.subscriptions.push(
      TwilioService.callStartedObservable.subscribe((data) => {
        const { connection, isIncoming } = data;
        document.title = `(In call) ${APP_TITLE}`;
        this.setState({
          isInCall: true,
          connection,
          ringing: false,
          isIncoming,
          minimized: !isIncoming,
        });
      }),
    );
    this.subscriptions.push(
      TwilioService.callStoppedObservable.subscribe((data) => {
        document.title = APP_TITLE;
        this.setState({
          isInCall: false,
          connection: null,
          isIncoming: null,
        });
      }),
    );
  }

  acceptCall() {
    const { connection } = this.state;
    connection.accept();
  }

  rejectCall() {
    const { connection } = this.state;
    connection.reject();
  }

  endCall() {
    const { connection } = this.state;
    connection.disconnect();
  }

  getCallParticipant() {
    try {
      const {
        connection: {
          parameters: { From } = {},
          message: { phoneNumber } = {},
        } = {},
        isIncoming,
      } = this.state;
      return isIncoming ? From : phoneNumber;
    } catch (err) {
      return null;
    }
  }

  renderRinging() {
    const { isIncoming } = this.state;
    return (
      <React.Fragment>
        {isIncoming && (
          <Button
            color={"green"}
            onClick={() => this.acceptCall()}
            className={"call-button"}
          >
            <Icon className={"call icon"}></Icon>
          </Button>
        )}
        <Button
          color={"red"}
          className={"call-button"}
          onClick={() => this.rejectCall()}
        >
          <Icon className={"call icon"}></Icon>
        </Button>
      </React.Fragment>
    );
  }

  renderInCall() {
    return (
      <React.Fragment>
        <Button
          color={"red"}
          className={"call-button"}
          onClick={() => this.endCall()}
        >
          <Icon className={"call icon"}></Icon>
        </Button>
      </React.Fragment>
    );
  }

  render() {
    const { isInCall, isRinging, minimized, connection } = this.state;
    if (!isInCall && !isRinging) {
      return null;
    }
    if (minimized) {
      return (
        <Button
          className={"sk-incoming-call-minimized-button"}
          onClick={() => this.setState({ minimized: false })}
        >
          <Icon className={"window maximize outline icon"}></Icon>
        </Button>
      );
    }
    const {
      zendeskUrl,
      dashboardUrl,
      firstName,
      lastName,
      userEmail,
    } = TwilioService.getIncomingCallParticipantInfo(connection);
    return (
      <div className="sk-incoming-call-glider">
        <div className="sk-incoming-call-row">
          {(isInCall || isRinging) && (
            <span className={"sk-call-participant"}>
              <p>
                <strong>{this.getCallParticipant()}</strong>
              </p>
              {firstName && lastName && <p>{`${firstName} ${lastName}`}</p>}
              {userEmail && <p>{userEmail}</p>}
              {zendeskUrl && (
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={zendeskUrl}
                  >
                    Zendesk URL
                  </a>
                </p>
              )}
              {dashboardUrl && (
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={dashboardUrl}
                  >
                    Dashboard URL
                  </a>
                </p>
              )}
            </span>
          )}
          <Button
            className={"minimize-button"}
            onClick={() => this.setState({ minimized: true })}
          >
            <Icon className={"window minimize outline icon"}></Icon>
          </Button>
        </div>
        <div className="sk-incoming-call-row sk-top-margin">
          {isRinging && this.renderRinging()}
          {isInCall && this.renderInCall()}
        </div>
      </div>
    );
  }
}

export default CallStatus;
