import React from "react";
import { Button } from "semantic-ui-react";
import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";
import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../../constants/endpoints";
import ZendeskIcon from "../../../../../../../../components/Icons/ZendeskIcon/ZendeskIcon";

export const SRILANKA_SELECT_VALUES = {
  GENDER: "GENDER",
  COUNTRIES: "COUNTRIES",
  COUNTRIES_WITH_FLAGS: "COUNTRIES_WITH_FLAGS",
  TOURIST_PURPOSE_OF_VISIT: "TOURIST_PURPOSE_OF_VISIT",
  BUSINESS_PURPOSE_OF_VISIT: "BUSINESS_PURPOSE_OF_VISIT",
  TRANSIT_PURPOSE_OF_VISIT: "TRANSIT_PURPOSE_OF_VISIT",
  PURPOSES_OF_VISIT: "PURPOSES_OF_VISIT",
  TITLE: "TITLE",
  VISA_LENGTHS: "VISA_LENGTHS",
  TYPE_OF_THIRD_PARTY: "TYPE_OF_THIRD_PARTY",
  VISA_MAIN_TYPE: "VISA_MAIN_TYPE",
  VISA_SUB_TYPE: "VISA_SUB_TYPE",
  RELATIONSHIP: "RELATIONSHIP",
  PHONE_CODES: "PHONE_CODES",
  MARITAL_STATUS: "MARITAL_STATUS",
  PORT_OF_ENTRY: "PORT_OF_ENTRY",
  PLACE_OF_STAY: "PLACE_OF_STAY"
};

export const sriLankaSchema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      applicationType: {
        label: "Application Type",
      },
      mainApplicationId: {
        label: "Main Application ID",
        dependsOn: "applicationType",
        dependantOnValues: ["Secondary"],
      },
      siteId: {
        label: "Referral Site",
      },
      cartSiteId: {
        label: "Purchased from website",
      },
      orderId: {
        label: "Order ID",
      },
      orderIdHistory: {
        label: "Order ID history",
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
        renderIcon: (propValue) => (
          <Button
            onClick={() => {
              window.open(
                TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(propValue),
                "_blank",
              );
            }}
            style={{ width: 36, height: 36, padding: 0, float: "right" }}
            disabled={!propValue}
          >
            <ZendeskIcon className="zendesk-icon" />
          </Button>
        ),
      },
      submissionEmail: {
        label: "Submission email",
        type: TYPES.TEXT,
      },
      submissionPassword: {
        label: "Submission password",
        type: TYPES.TEXT,
      },
      gclid: {
        label: "Google client ID",
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      salesAgent: {
        label: "Sales Agent",
      },
      salesCoupon: {
        label: "Sales Coupon",
      },
    },
  },
  contactInfoSegment: {
    label: "Applicant information",
    type: TYPES.SEGMENT,
    data: {
      visaMainType: {
        label: "Visa main type",
        type: TYPES.SELECT,
        values: SRILANKA_SELECT_VALUES.VISA_MAIN_TYPE,
        value: "$value",
      },
      visaSubType: {
        label: "Visa sub type",
        type: TYPES.TEXT,
      },
      visaType: {
        label: "Visa type",
        type: TYPES.TEXT
      },
      applicantInfo: {
        label: "Applicant information",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          firstName: {
            label: "First name",
            type: TYPES.TEXT,
          },
          lastName: {
            label: "Family name",
            type: TYPES.TEXT,
          },
          userEmail: {
            label: "User email address",
            type: TYPES.TEXT,
          },
          birthDate: {
            label: "Birth date",
            type: TYPES.DATE,
          },
          purposeOfVisit: {
            label: "Purpose of visit",
            type: TYPES.SELECT,
            values: (applicant, path, schemaData) => {
              switch(applicant.visaMainType) {
                case "Tourist": return schemaData.TOURIST_PURPOSE_OF_VISIT;
                case "Transit": return schemaData.TRANSIT_PURPOSE_OF_VISIT;
                case "Business": return schemaData.BUSINESS_PURPOSE_OF_VISIT;
                default: return schemaData.PURPOSES_OF_VISIT
              }
            },
          },
          travelDocumentType: {
            label:"Type of Travel Document",
            type: TYPES.TEXT
          }
        },
      },
  },
},
  passportInformation: {
    label: "Passport information",
    type: TYPES.SEGMENT,
    data: {
      nationality: {
        label: "Nationality",
        type: TYPES.SELECT,
        values: SRILANKA_SELECT_VALUES.COUNTRIES,
      },
      countryOfResidence: {
        label: "Country of residence",
        type: TYPES.SELECT,
        values: SRILANKA_SELECT_VALUES.COUNTRIES
      },
      birthCity: {
        label:"Place of birth",
        type:TYPES.TEXT,
      },
      passportNumber: {
        label: "Passport number",
        type: TYPES.TEXT,
      },
      passportPlaceOfIssue: {
        label: "Passport place of issue",
        type: TYPES.TEXT,
      },
      passportIssueDate: {
        label: "Date of issue",
        type: TYPES.DATE,
      },
      passportExpiryDate: {
        label: "Valid until",
        type: TYPES.DATE,
      },
      title: {
            label: "Title",
            type: TYPES.SELECT,
            values: SRILANKA_SELECT_VALUES.TITLE,
      },
      gender: {
            label: "Gender",
            type: TYPES.SELECT,
            values: SRILANKA_SELECT_VALUES.GENDER,
      },
      maritalStatus:{
            label:"Marital status",
            type:TYPES.SELECT,
           values:SRILANKA_SELECT_VALUES.MARITAL_STATUS
      }
      
    },
  },
  contactInformation: {
    label: "Contact information",
    type: TYPES.SEGMENT,
    data: {
      mobileNumbercountryCode: {
        label: "Mobile number country code",
        type: TYPES.SELECT,
        values: SRILANKA_SELECT_VALUES.PHONE_CODES,
        text: "phone",
        value: "key",
      },
      mobileNumber: {
        label: "Mobile number",
        type: TYPES.TEXT,
      },
      telephoneNumbercountryCode: {
        label: "Telephone number country code",
        type: TYPES.SELECT,
        values: SRILANKA_SELECT_VALUES.PHONE_CODES,
        text: "phone",
        value: "key",
      },
      telephoneNumber: {
        label: "Whats App",
        type: TYPES.TEXT,
      },
      facebook: {
        label: "Facebook",
        type: TYPES.TEXT
      },
      instagram: {
        label: "Instagram",
        type: TYPES.TEXT
      },
      twitter: {
        label: "Twitter",
        type: TYPES.TEXT
      },
      sriLankaCompany: {
        label: "Sri Lanka company contact",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "visaMainType",
        dependantOnValues: ["Business"],
        nested: {
          companyName: {
            label: "Company name",
            type: TYPES.TEXT,
            path: "sriLankaCompany",
          },
          email: {
            label: "Email",
            type: TYPES.TEXT,
            path: "sriLankaCompany",
          },
          businessRegistrationNumber: {
            label: "Business Registration Number",
            type: TYPES.TEXT,
            path: "sriLankaCompany",
          },
          addressLine1: {
            label: "Address line 1 in Sri Lanka",
            type: TYPES.TEXT,           
          },
          addressLine2: {
            label: "Address line 2 in Sri Lanka",
            type: TYPES.TEXT,
        
          },
          city: {
            label: "City",
            type: TYPES.TEXT,
          },
          state: {
            label: "State",
            type: TYPES.TEXT,
          },
          zipCode: {
            label: "Zip code",
            type: TYPES.TEXT,
          },
          telephoneNumbercountryCode: {
            label: "Telephone number country code",
            type: TYPES.SELECT,
            values: SRILANKA_SELECT_VALUES.PHONE_CODES,
            text: "phone",
            value: "key",
            path: "sriLankaCompany",
          },
          telephoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "sriLankaCompany",
          },
         
        },
      },
    },
  },

  travelInformation: {
    label: "Travel information",
    type: TYPES.SEGMENT,
    data: {
      intendedArrivalDate: {
        label: "Intended date of arrival",
        type: TYPES.DATE,
      },
      portOfEntry: {
        label: "Port of entry in Sri Lanka",
        type: TYPES.SELECT,
        values:SRILANKA_SELECT_VALUES.PORT_OF_ENTRY
      },
      placeOfStay: {
        label: "During your stay in Sri Lanka, your place of stay will be?",
        type: TYPES.SELECT,
        values:SRILANKA_SELECT_VALUES.PLACE_OF_STAY,
      },
      accomodationAddress: {
        label: "Address of Accomodation",
        type: TYPES.TEXT,
        dependsOn: "visaMainType",
        dependantOnValues: ["Business"]

      },
      finalDestination: {
        label: "Country of Destination",
        type: TYPES.SELECT,
        values:SRILANKA_SELECT_VALUES.COUNTRIES,
        dependsOn: "visaMainType",
        dependantOnValues: ["Transit"]

      },
      intendedDepartureDate: {
        label: "Date of proposed departure from Sri Lanka",
        type: TYPES.DATE
      },
      addressLine1: {
        label: "Address line 1 in Sri Lanka",
        type: TYPES.TEXT,
        note: "This will be used to verify the client's ID on Calls/Chats",
        dependsOn: "visaMainType",
        dependantOnValues: ["Tourist"]
      },
      addressLine2: {
        label: "Address line 2 in Sri Lanka",
        type: TYPES.TEXT,
        dependsOn: "visaMainType",
        dependantOnValues: ["Tourist"]
      },
      city: {
        label: "City",
        type: TYPES.TEXT,
        dependsOn: "visaMainType",
        dependantOnValues: ["Tourist"]
      },
      state: {
        label: "State",
        type: TYPES.TEXT,
        dependsOn: "visaMainType",
        dependantOnValues: ["Tourist"]
      },
      zipCode: {
        label: "Zip code",
        type: TYPES.TEXT,
        dependsOn: "visaMainType",
        dependantOnValues: ["Tourist"]
      },
      sriLankaCountryCode: {
        label: "Telephone number country code",
        type: TYPES.SELECT,
        values: SRILANKA_SELECT_VALUES.PHONE_CODES,
        text: "phone",
        value: "key",
        },
       sriLankaContactNumber: {
        label: "Phone number in Sri Lanka",
        type: TYPES.TEXT,
      },
    },
  },
 };
