import React, { Component } from "react";
import { Segment, Header, Table, Button, Icon } from "semantic-ui-react";
import ConfirmPopup from "../../../../../../../../../components/ConfirmPopup";
import {
  getSalesTeamApi,
  createMemberApi,
  updateMemberApi,
  deleteMemberApi,
} from "../../../../../../../../../apis/departments/admin/salesteam";
import Spinner from "../../../../../../../../../components/Spinner";
import * as toastr from "toastr";
import ModalForm from "../../../../../../../../../components/common/ModalForm";
import SalesTeamForm from "./components/SalesTeamForm";

class Salesman extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      salesTeamMemebers: null,
      isEditing: false,
      currentPartner: null,
      reportData: null,
    };
  }

  componentDidMount() {
    this.fetchQAPartners();
  }

  async fetchQAPartners() {
    try {
      const {
        data: { members:salesTeamMemebers, report: reportData },
      } = await getSalesTeamApi();
      this.setState({ isLoading: false, salesTeamMemebers, reportData });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }

  async updateSalesTeam(salesTeamId, changedPart) {
    const { salesTeamMemebers } = this.state;
    // update for user instantly
    this.setState({
      salesTeamMemebers: salesTeamMemebers.map((statePartner) =>
        statePartner._id === salesTeamId
          ? { ...statePartner, ...changedPart }
          : statePartner,
      ),
    });
    const {
      data: { member },
    } = await updateMemberApi(salesTeamId, changedPart);
    // update when response from server comes to ensure valid data
    this.setState({
      salesTeamMemebers: salesTeamMemebers.map((statePartner) =>
        statePartner._id === salesTeamId ? member : statePartner,
      ),
    });
    toastr.success("Successfully updated salesTeam");
  }

  async createSalesTeam(partnerToCreate) {
    const { salesTeamMemebers } = this.state;

    const resPartnerToCreate = {
      ...partnerToCreate,
      companyName: "GovAssist",
    }
    try {
      const {
        data: { member },
      } = await createMemberApi(resPartnerToCreate);
      this.setState({ salesTeamMemebers: [member, ...salesTeamMemebers] });
      toastr.success("Successfully created salesTeam");
    } catch (err) {
      toastr.error("Failed creating salesTeam");
    }
  }

  async deletePartner(salesTeam) {
    const { salesTeamMemebers } = this.state;
    try {
      this.setState({ isLoading: true });
      await deleteMemberApi(salesTeam._id);
      toastr.success("Deleted salesTeam");
      this.setState({
        salesTeamMemebers: salesTeamMemebers.filter(
          (statePartner) => salesTeam._id !== statePartner._id,
        ),
      });
    } catch (err) {
      toastr.error("Couldnt delete salesTeam");
    }
    this.setState({ isLoading: false });
  }

  changeCurrentPartnerPropertyValue(property, value) {
    const { currentPartner } = this.state;
    this.setState({
      currentPartner: { ...currentPartner, [property]: value },
    });
  }

  renderSalesTeamEdit(partner) {
    return (
      <ModalForm
        headerText="Edit Sales Team"
        loaderText="Preparing data..."
        onSubmit={(result) => this.updateSalesTeam(partner._id, result)}
        closeOnSubmit
        trigger={
          <Button color="blue" size="mini" icon>
            <Icon name="edit" />
          </Button>
        }
      >
        <SalesTeamForm partnerFromParent={partner} />
      </ModalForm>
    );
  }

  renderSalesTeamCreate() {
    return (
      <ModalForm
        headerText="Create Sales Team"
        trigger={<Button>Create new sales team</Button>}
        onSubmit={(result) => this.createSalesTeam(result)}
        closeOnSubmit
      >
        <SalesTeamForm />
      </ModalForm>
    );
  }

  renderPartnerDelete(salesTeam) {
    return (
      <ConfirmPopup
        callback={() => this.deletePartner(salesTeam)}
        content={`Are you sure you want to REMOVE salesTeam: ${salesTeam.name} (${salesTeam.companyName})`}
      >
        <Button color="red" size="mini" icon>
          <Icon name="trash" />
        </Button>
      </ConfirmPopup>
    );
  }

  renderActions(salesTeam) {
    return (
      <React.Fragment>
        {this.renderSalesTeamEdit(salesTeam)}
        {!salesTeam.usedInEvaluation && this.renderPartnerDelete(salesTeam)}
      </React.Fragment>
    );
  }

  render() {
    const { salesTeamMemebers, isLoading, reportData } = this.state;
    if (isLoading) {
      return <Spinner />;
    }

    if (!salesTeamMemebers) {
      return <p>Failed loading salesTeamMemebers</p>;
    }

    return (
      <Segment.Group>
        <Segment className="tableTopSegment" inverted>
          <Header className="tableTitle" size="large" floated="left">
            <h3>Sales Team</h3>
            <div style={{ float: "right" }}>{this.renderSalesTeamCreate()}</div>
          </Header>
        </Segment>
        <Table celled striped fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan={2} width={1}>Name</Table.HeaderCell>
              <Table.HeaderCell rowSpan={2} width={1}>Email</Table.HeaderCell>
              <Table.HeaderCell rowSpan={2} width={1}>Company Name</Table.HeaderCell>
              <Table.HeaderCell rowSpan={2} width={1}>Tracking Code</Table.HeaderCell>
              <Table.HeaderCell colSpan={4} width={4} textAlign="center">Statistics</Table.HeaderCell>
              <Table.HeaderCell rowSpan={2} width={1}>Actions</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell width={1}>Sales Count</Table.HeaderCell>
              <Table.HeaderCell width={1}>Sales Value</Table.HeaderCell>
              <Table.HeaderCell width={1}>Refund Value</Table.HeaderCell>
              <Table.HeaderCell width={1}>Chargeback Value</Table.HeaderCell>
            </Table.Row>
            
          </Table.Header>
          <Table.Body>
            {salesTeamMemebers.map((salesTeam) => (
              <Table.Row key={salesTeam._id}>
                <Table.Cell width={1}>{salesTeam.name}</Table.Cell>
                <Table.Cell width={1}>{salesTeam.email}</Table.Cell>
                <Table.Cell width={1}>{salesTeam.companyName}</Table.Cell>
                <Table.Cell width={1}>{salesTeam.trackingId}</Table.Cell>
                <Table.Cell width={1}>{reportData[salesTeam.trackingId]?reportData[salesTeam.trackingId].salesCount:0}</Table.Cell>
                <Table.Cell width={1}>${reportData[salesTeam.trackingId]?parseFloat(reportData[salesTeam.trackingId].salesValue).toFixed(2):0}</Table.Cell>
                <Table.Cell width={1}>{reportData[salesTeam.trackingId]?reportData[salesTeam.trackingId].refundValue:0}</Table.Cell>
                <Table.Cell width={1}>{reportData[salesTeam.trackingId]?reportData[salesTeam.trackingId].chargeBackValue:0}</Table.Cell>
                <Table.Cell width={1}>{this.renderActions(salesTeam)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment.Group>
    );
  }
}

export default Salesman;
