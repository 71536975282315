export const API_NAMES = {
  ETA: "eta",
  ESTA: "esta",
  DS160: "ds160",
  NZ: "nz",
  TRK: "trk",
  IND: "ind",
  AUS: "aus",
  INV: "inv_letter",
  SCHENGEN: "schengen",
  VN: "vn",
  DIVERSITY: "diversity",
  GE: "ge",
  SG: "sg",
  DS11: 'ds11',
  DS82: 'ds82',
  DS64: 'ds64',
  SL: "sl",
  TSA:"tsa",
  SENTRI: 'sentri',
  NEXUS: "nexus",
};
