import React, { useContext, useMemo, useCallback } from "react";
import { Header, Segment, Tab, Button } from "semantic-ui-react";
import Spinner from "../../../../../../../../../../components/Spinner";
import { ApplicantDocumentsContext } from "../../../../../../../../../../common/ApplicantDocumentsContext";
import {
  embassyDocumentsEligibleStatuses,
  getApplicantStatusString,
} from "../../../../../constants/applicantStatuses";
import DocumentView from "../DocumentView";
import BrochureView from "../BrochureView";
import SendEmail from "../SendEmail/SendEmail.component";
import { NavLink } from "react-router-dom";
import apis from "../../../../../../../../../../apis";
import toastr from "toastr";
import EmbassyDocumentHistory from "../EmbassyDocumentHistory";
import { capitalizeEachWord } from "../../../../../../../../../../utils";
import RequestDocumentModal from "../RequestDocumentModal/RequestDocumentModal.component";

export const DOCUMENT_TYPE = {
  bot: "Bot downloaded",
  agent: "Agent uploaded",
  client: "Client uploaded",
  financial: "Financial documents"
};

const ApplicantDocumentHandlerView = () => {
  const {
    isLoading,
    setIsLoading,
    applicant,
    setApplicant,
    documentsBots,
    setDocumentsBots,
    documentsAgents,
    setDocumentsAgents,
    documentsClients,
    setDocumentsClients,
    documentsFinancial,
    setDocumentsFinancial,
    documentsBrochures,
    api,
  } = useContext(ApplicantDocumentsContext);

  const handleDownloadEmbassyDocuments = useCallback(async () => {
    setIsLoading(true);
    if (applicant.isVisaEmbassyBotStarted)
      toastr.error("Embassy documents downloading is already in progress!");
    else {
      try {
        await apis.applicantDocumentHandler.downloadEmbassyDocuments(
          api,
          applicant.id,
        );
        setApplicant((prev) => ({ ...prev, isVisaEmbassyBotStarted: true }));
        toastr.success("Successfully started downloading embassy documents.");
      } catch (error) {
        toastr.error("Failed to start downloading embassy documents.");
        console.error(error);
      }
    }
    setIsLoading(false);
  }, [api, applicant, setApplicant, setIsLoading]);

  const panes = useMemo(
    () =>
      api === "inv_letter"
        ? [
            {
              menuItem: "Documents uploaded by CLIENTS",
              render: () => (
                <Tab.Pane>
                  <DocumentView
                    data={documentsClients}
                    setData={setDocumentsClients}
                    documentType={DOCUMENT_TYPE["client"]}
                    shouldUpload={false}
                  />
                </Tab.Pane>
              ),
            },
          ]
        : [
            {
              menuItem: "Documents downloaded by BOT",
              render: () => (
                <Tab.Pane>
                  <DocumentView
                    data={documentsBots}
                    setData={setDocumentsBots}
                    documentType={DOCUMENT_TYPE["bot"]}
                    shouldUpload={false}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Documents uploaded by AGENTS",
              render: () => (
                <Tab.Pane>
                  <DocumentView
                    data={documentsAgents}
                    setData={setDocumentsAgents}
                    documentType={DOCUMENT_TYPE["agent"]}
                    shouldUpload={true}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Documents uploaded by CLIENTS",
              render: () => (
                <Tab.Pane>
                  <DocumentView
                    data={documentsClients}
                    setData={setDocumentsClients}
                    documentType={DOCUMENT_TYPE["client"]}
                    shouldUpload={true}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Financial documents",
              render: () => (
                <Tab.Pane>
                  <DocumentView
                    data={documentsFinancial}
                    setData={setDocumentsFinancial}
                    documentType={DOCUMENT_TYPE["financial"]}
                    shouldUpload={true}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: "Brochures",
              render: () => (
                <Tab.Pane>
                  <BrochureView
                    data={documentsBrochures}
                    visaType={applicant?.productType}
                  />
                </Tab.Pane>
              ),
            },
          ],
    [api, applicant, documentsAgents, documentsBots, documentsBrochures, documentsClients, documentsFinancial, setDocumentsAgents, setDocumentsBots, setDocumentsClients, setDocumentsFinancial],
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (!applicant) {
    return null;
  }

  return (
    <Segment.Group className="applicant-view-container">
      <Segment inverted className="tableTopSegment">
        <Header className="tableTitle" size="large" floated="left">
          <h3>Document handler</h3>
          <h3>
            {capitalizeEachWord(`${applicant.firstName} ${applicant.lastName}`)}
          </h3>
          <h3>{getApplicantStatusString(applicant.status)}</h3>
        </Header>
      </Segment>
      <Segment className="document-menu-buttons">
        <NavLink
          to={`/${api}/applicant/${applicant.id}`}
          className="ui button blue"
        >
          Back
        </NavLink>
        <div>
          <React.Fragment>
            <RequestDocumentModal
              documentType="client"
            />
          </React.Fragment>
          <React.Fragment>
            <EmbassyDocumentHistory
              logs={applicant.visaEmbassyDocumentsBotHistory}
            />
            {embassyDocumentsEligibleStatuses.includes(applicant.status) && (
              <Button
                type="button"
                color="green"
                onClick={handleDownloadEmbassyDocuments}
              >
                {applicant.isVisaEmbassyBotStarted
                  ? "Bot in progress"
                  : "Download Embassy Documents"}
              </Button>
            )}
            <SendEmail />
          </React.Fragment>
          {/* } */}
        </div>
      </Segment>
      <Segment>
        <Tab panes={panes} />
      </Segment>
    </Segment.Group>
  );
};

export default ApplicantDocumentHandlerView;
