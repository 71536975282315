import axios from "axios";
import { API_ENDPOINT } from "../constants/endpoints";
import { emitLogout } from "../events";
import { LocalStorageService } from "../services/localStorageService";

export const initStandardAxios = axios.create({
  baseURL: API_ENDPOINT,
});

initStandardAxios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      //If the header does not contain the token and the url not public, redirect to login
      const accessToken = `Bearer ${LocalStorageService.getItem("token")}`;

      //if token is found add it to the header
      if (accessToken) {
        if (config.method !== "OPTIONS") {
          config.headers.Authorization = accessToken;
        }
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  initStandardAxios.interceptors.response.use(null,async (error) => {
    if (error.response && error.response.status === 401) {
      emitLogout();
    }
    return Promise.reject(error);
  });

export default initStandardAxios;