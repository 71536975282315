import React from "react";
import { DisputesTable, QATicketTable, QAReportPage } from "./components";
import { QA_REPORT_FOR } from "../../constants/qaReports";
import MenuTabs from "../../../../../../../components/MenuTabs";

const tabs = [
  {
    name: "Disputes",
    component: <DisputesTable disableActions />,
    route: "disputes",
  },
  {
    name: "Evaluation tickets",
    component: <QATicketTable />,
    route: "evaluations",
  },
  {
    name: "Report",
    component: <QAReportPage type={QA_REPORT_FOR.QA_AGENT} />,
    route: "report",
  },
];

class QAAgentArea extends React.Component {
  render() {
    return (
      <MenuTabs
        {...this.props}
        tabs={tabs}
        defaultRoute="my-account/qa-agent"
        pathParam="subRoute"
      />
    );
  }
}

export default QAAgentArea;
