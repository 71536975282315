export const QA_REPORTS = {
  OVERALL_REPORT: "overall_report",
  PRODUCT_REPORT: "product_report",
  CATEGORY_REPORT: "category_report",
  PRODUCT_CATEGORY_REPORT: "product_category_report",
  DISPUTES_REPORT: "disputes_report",
  AFTER_DISPUTES_REPORT: "after_disputes_report",
  GENERAL_TICKET_REPORT: "general_ticket_report",
  SUCCESS_RATIO_REPORT: "success_ratio_report",
  GENERAL_PRODUCT_REPORT: "general_product_report",
};

export const QA_REPORT_FOR = {
  AGENT: "agent",
  QA_AGENT: "qa_agent",
  MANAGER: "manager",
  QA_MANAGER: "qa_manager",
  ADMIN: "admin",
};

export const GROUPS = {
  L1: "L1 Agent",
  L2: "L2 Agent",
  BILLING: "BT Agent",
};
