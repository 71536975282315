import React, { useMemo, useCallback, useState, useContext } from "react";
import {
  Header,
  Icon,
  Segment,
  Table,
  Button,
  Message,
  Input,
  Select,
  Loader,
  Pagination,
} from "semantic-ui-react";

import { getDateFormat } from "../../../../../../../common/date-format";
import { history } from "../../../../../../../modules/history";
import { ROLES } from "../../../../../../../constants/rolesConstants";

import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import "./UserTable.styles.scss";
import { AuthService } from "../../../../../../../services/authService";

import { useSelector, shallowEqual } from "react-redux";
import UserForm from "./components/UserForm";
import ModalForm from "../../../../../../../components/common/ModalForm";
import { UsersContext } from "../../../../../../../common/UsersContext";
import { AGENT_AVAILABILITY } from "../../constants/agentAvailability";
import { updateUserAvailability } from "../../../../../../../apis/userApi";
import { ZENDESK_HOSTS } from "../../../MyAccount/constants/zendesk";
import { useFilters } from "../../../../../../../hooks";
import { FILTERED_PRODUCTS } from "../../../Products/constants/productNames";

const columns = [
  {
    label: "Roles",
    prop: "roles",
    getValue: (fieldValue) => (!!fieldValue ? fieldValue.join() : ""),
  },
  {
    label: "Username",
    prop: "username",
    width: 2,
  },
  {
    label: "Email",
    prop: "email",
    width: 2,
  },
  {
    label: "First Name",
    prop: "firstName",
    width: 2,
  },
  {
    label: "Last Name",
    prop: "lastName",
    width: 2,
  },
  {
    label: "Created At",
    prop: "createdAt",
    width: 2,
    getValue: (fieldValue) => (fieldValue ? getDateFormat(fieldValue) : ""),
  },
];

const ITEMS_PER_PAGE = 15;

const UserTable = () => {
  const user = useSelector((state) => state.currentUser, shallowEqual);

  const { createUser, updateUser, deleteUser, getUsersFiltered } =
    useContext(UsersContext);

  const [currentPage, setCurrentPage] = useState(1);

  const { normalizedFilters, filters, setFormFilter } = useFilters(
    {
      name: "",
      roles: [],
      zendeskHost: [ZENDESK_HOSTS[0].link],
    },
    {
      localStorageKey: "user-table-filters",
      temporaryFilters: ["name", "online"],
    },
  );

  const getAdmin = useMemo(() => {
    return AuthService.checkRolesForUser(user, [ROLES.ADMIN]);
  }, [user]);

  const users = useMemo(() => {
    const { zendeskHost, ...restFilters } = normalizedFilters;
    return getUsersFiltered(restFilters);
  }, [getUsersFiltered, normalizedFilters]);

  const simulateUser = useCallback((userId) => {
    window.open(`/${FILTERED_PRODUCTS[0]}?simulate-user=${userId}`, "_blank");
  }, []);

  const createUserMethod = useCallback(
    async (data) => {
      let resData = { ...data };

      if (resData.managerId === "NO_MANAGER") {
        resData = { ...resData, managerId: null };
      }
      if (resData.zendeskHost === "") {
        resData = { ...resData, zendeskHost: null };
      }
      await createUser(resData);
    },
    [createUser],
  );

  const updateUserMethod = useCallback(
    async (user, data) => {
      const result = {
        ...data,
        roles: data.roles || [],
        products: data.products || [],
        zendeskId: data.zendeskId || "",
        zendeskHost: data.zendeskHost || null,
        managerId: data.managerId === "NO_MANAGER" ? null : data.managerId,
      };
      await updateUser(user._id, { ...user, ...result });
    },
    [updateUser],
  );

  const renderUserEdit = useCallback(
    (user) => {
      return (
        <ModalForm
          trigger={<Button color="blue" size="mini" icon="edit" />}
          headerText="Edit user form"
          loaderText="Preparing data..."
          onSubmit={(result) => updateUserMethod(user, result)}
          closeOnSubmit
        >
          <UserForm userFromParent={user} isEditing />
        </ModalForm>
      );
    },
    [updateUserMethod],
  );

  const renderUserCreate = useCallback(() => {
    return (
      <ModalForm
        trigger={<Button>Create new user</Button>}
        headerText="Create user form"
        loaderText="Preparing data..."
        onSubmit={async (result) => createUserMethod(result)}
        closeOnSubmit
      >
        <UserForm />
      </ModalForm>
    );
  }, [createUserMethod]);

  const renderUserDelete = useCallback(
    (user) => {
      return (
        <ConfirmPopup
          callback={async () => await deleteUser(user)}
          content={`Are you sure you want to REMOVE user: ${user.email}`}
        >
          <Button color="red" size="mini" icon>
            <Icon name="trash" />
          </Button>
        </ConfirmPopup>
      );
    },
    [deleteUser],
  );

  const renderHeader = useCallback(() => {
    const onlineOptions = [
      { text: "Online", value: true },
      { text: "Offline", value: false },
      { text: "All", value: null },
    ];
    const rolesOptions = Object.values(ROLES).map((role, index) => ({
      key: index,
      text: role.toUpperCase(),
      value: role,
    }));

    const { name, roles, online } = filters;

    return (
      <div className="users-header">
        <div className="users-filters">
          <Input
            label="Search"
            id="search-input"
            name="name"
            value={name}
            onChange={setFormFilter}
          />
          <Select
            key="roles"
            id="roles-select"
            options={rolesOptions}
            placeholder="Select Role"
            multiple
            name="roles"
            value={roles}
            onChange={setFormFilter}
          />
          <Select
            key="online"
            id="online-select"
            placeholder="Select online status"
            options={onlineOptions}
            name="online"
            value={online}
            onChange={setFormFilter}
          />
        </div>
        {getAdmin && renderUserCreate()}
      </div>
    );
  }, [filters, getAdmin, renderUserCreate, setFormFilter]);

  const renderActions = (user) => {
    return (
      <React.Fragment>
        {renderUserEdit(user)}
        {getAdmin && renderUserDelete(user)}
        <Button
          onClick={() => simulateUser(user.id)}
          color="yellow"
          size="mini"
          icon
        >
          <Icon name="user" />
        </Button>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {!users ? (
        <Loader active />
      ) : (
        <Segment.Group>
          <Segment className="tableTopSegment" inverted>
            <Header className="tableTitle" size="large" floated="left">
              Users
            </Header>
          </Segment>
          {renderHeader()}
          <Table celled striped fixed selectable className="user-table">
            <Table.Header>
              <Table.Row>
                {columns.map((column) => (
                  <Table.HeaderCell
                    key={column.prop}
                    width={column.width ? column.width : 1}
                  >
                    {column.label}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell>Availability</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {users &&
                Array.isArray(users) &&
                users
                  .slice(
                    (currentPage - 1) * ITEMS_PER_PAGE,
                    currentPage * ITEMS_PER_PAGE,
                  )
                  .map((user) => (
                    <Table.Row key={user._id}>
                      {columns.map((column) => (
                        <Table.Cell
                          onClick={() => history.push(`/users/${user._id}`)}
                          key={column.prop}
                        >
                          {column.getValue
                            ? column.getValue(user[column.prop])
                            : user[column.prop]}
                        </Table.Cell>
                      ))}
                      <Table.Cell className="availability-table-cell">
                        <Select
                          disabled={!user.state.isOnline || !getAdmin}
                          value={user.settings.availability}
                          search={true}
                          options={Object.values(AGENT_AVAILABILITY).map(
                            (status) => ({
                              text: status,
                              value: status,
                            }),
                          )}
                          onChange={async (fieldOptions, field) => {
                            const { status } = await updateUserAvailability(
                              user._id,
                              field.value,
                            );
                            if (status === 200)
                              updateUser(
                                user._id,
                                {
                                  settings: {
                                    ...user.settings,
                                    availability: field.value,
                                  },
                                },
                                true,
                              );
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell>{renderActions(user)}</Table.Cell>
                    </Table.Row>
                  ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={columns.length + 2}>
                  {users && users.length > 0 ? (
                    <Pagination
                      className="floated right"
                      activePage={currentPage}
                      totalPages={Math.ceil(users.length / ITEMS_PER_PAGE)}
                      onPageChange={(event, data) =>
                        setCurrentPage(data.activePage)
                      }
                    />
                  ) : (
                    <Message size="big">No results!</Message>
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Segment.Group>
      )}
    </React.Fragment>
  );
};

export default UserTable;
