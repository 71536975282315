import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, Segment, Checkbox, Button } from "semantic-ui-react";
import ConfirmPopup from "../../../../../components/ConfirmPopup";
import apis from "../../../../../apis";
import { UPDATE_PRODUCT_CONFIG } from "../../../../../store/actions/productConfigActions";
import toastr from "toastr";
import { logServerError } from "../../../../../common/logs";
import "./ProductConfig.styles.scss";
import { PRODUCT_NAMES_MAPPING } from "../../routers/Products/constants/productNames";

const ProductConfig = () => {
  const productConfig = useSelector((state) => state.productConfig);
  const [productConfigValues, setProductConfigValues] = useState(productConfig);

  const dispatch = useDispatch();

  const isPristine = useMemo(
    () => productConfig === productConfigValues,
    [productConfig, productConfigValues],
  );

  const onDiscardHandle = useCallback(
    () => setProductConfigValues(productConfig),
    [productConfig],
  );

  const onSaveHandle = useCallback(async () => {
    try {
      await apis.productConfig.updateProductConfig(productConfigValues);
      dispatch({ type: UPDATE_PRODUCT_CONFIG, payload: productConfigValues });
      toastr.success("Configurations have been updated!");
    } catch (error) {
      logServerError(error);
    }
  }, [dispatch, productConfigValues]);

  return (
    <Segment.Group>
      <Segment className="tableTitle adminTable" inverted>
        <Header size="large" floated="left">
          Product Configuration
        </Header>
      </Segment>
      <Segment inverted className="product-config">
        <div className="product-config__item">
          <span></span>
          <span>Show in side menu</span>
        </div>
        {Object.entries(productConfigValues).map(([name, value]) => (
          <div className="product-config__item" key={name}>
            <span className="product-config__item__title">
              {PRODUCT_NAMES_MAPPING[name.toLowerCase()] || name.toUpperCase()}
            </span>
            <Checkbox
              toggle
              checked={value}
              onChange={() =>
                setProductConfigValues((old) => ({
                  ...old,
                  [`${name}`]: !value,
                }))
              }
            />
          </div>
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "12px",
          }}
        >
          <ConfirmPopup
            content="Are you sure you want discard changes?"
            callback={onDiscardHandle}
          >
            <Button className="discard-button" disabled={isPristine}>
              Discard
            </Button>
          </ConfirmPopup>
          <ConfirmPopup
            content="Are you sure you want save changes?"
            callback={onSaveHandle}
          >
            <Button className="save-button" disabled={isPristine}>
              Save
            </Button>
          </ConfirmPopup>
        </div>
      </Segment>
    </Segment.Group>
  );
};

export default ProductConfig;
