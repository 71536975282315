export class SessionStorageService {
  static getItem(key) {
    const data = sessionStorage.getItem(key);
    if (data && data !== "undefined") {
      return JSON.parse(data);
    }

    return null;
  }

  static setItem(key, data) {
    if (typeof data === "string") {
      sessionStorage.setItem(key, data);
    }

    sessionStorage.setItem(key, JSON.stringify(data));
  }

  static removeItem(key) {
    sessionStorage.removeItem(key);
  }

  static getToken() {
    return SessionStorageService.getItem("token");
  }

  static getUser() {
    return SessionStorageService.getItem("user");
  }

  static getUserId() {
    return (SessionStorageService.getItem("user") || {})._id;
  }
}
