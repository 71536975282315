import React from "react";
import { Table, Checkbox } from "semantic-ui-react";
import { ALLOWED_REFERRAL_SITES } from "../../../../../EVisa/pages/ApplicantsEdit/components/constants";

const AUSReviewAndCertification = ({ referralSite = ALLOWED_REFERRAL_SITES.DEFAULT }) => {
  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell colSpan="2">
          <div>
            <p>
              I have read and understood the information provided to them in
              this application.
            </p>
          </div>
          <div>
            <p>
              I have provided complete and correct information in every detail
              on this form, and on any attachments to it.
            </p>
          </div>
          <div>
            <p>
              I understand that if any fraudulent documents or false or
              misleading information has been provided with this application, or
              if any of the applicants fail to satisfy the Minister of their
              identity, the application may be refused and the applicant(s), and
              any member of their family unit, may become unable to be granted a
              visa for a specified period of time.
            </p>
          </div>
          <div>
            <p>
              I understand that if documents are found to be fraudulent or
              information to be incorrect after the grant of a visa, the visa
              may subsequently be cancelled.
            </p>
          </div>
          <div>
            <p>
              I understand that if this application is approved, any person not
              included in this application will not have automatic right of
              entry to Australia.
            </p>
          </div>
          <div>
            <p>
              I will inform the Department in writing immediately as they become
              aware of a change in circumstances (including change of address)
              or if there is any change relating to information they have
              provided in or with this application, while it is being
              considered.
            </p>
          </div>
          <div>
            <p>
              I have read and understood the information provided to them in
              this application.
            </p>
          </div>
          <div>
            <p>
              I have read the information contained in the Privacy Notice{" "}
              <a href="https://immi.homeaffairs.gov.au/form-listing/forms/1442i.pdf">
                {" "}
                (Form 1442i)
              </a>
            </p>
          </div>
          <div>
            <p>
              I understand that the department may collect, use and disclose the
              applicant's personal information (including biometric information
              and other sensitive information) as outlined in the Privacy Notice{" "}
              <a href="https://immi.homeaffairs.gov.au/form-listing/forms/1442i.pdf">
                {" "}
                (Form 1442i)
              </a>
            </p>
          </div>
          <div>
            <p>
              I understand that if my visa ceases to be in effect and I do not
              hold another visa to remain in Australia at that time, I will be
              an unlawful non-citizen under the Migration Act 1958. As such, I
              will be expected to depart from Australia, and be subject to
              removal under the Migration Act 1958.
            </p>
          </div>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell colSpan="2">
          <h5>Third Party Authorization</h5>
          <p>
            I understand that I am applying via GovAssist, LLC; a third-party
            that is not affiliated with the Australian government, that the
            third-party cannot guarantee application approval, that the
            third-party service is offered to help expedite the process and that
            I have the option to apply directly with the pertinent government
            websites. I also understand that my purchase includes a processing
            and service fee to GovAssist.
          </p>
          <p>
            I confirm and authorize GovAssist, LLC to assist me, by using their
            processing service in completing and reviewing my application. I
            also confirm that I am submitting my application and signing it
            myself.
          </p>
          <p>
            By obtaining services from the GovAssist website, I have agreed to
            the following:{" "}
            <a href={`https://${referralSite}/terms-conditions`}>
              Terms & Conditions
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/refund-policy`}>
              Refund Policy{" "}
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/privacy-policy`}>
              Privacy Policy{" "}
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/legal-disclaimer`}>
              Disclaimer{" "}
            </a>
          </p>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>
    </React.Fragment>
  );
};

export default AUSReviewAndCertification;
