import React, { Fragment, useState, useContext, useMemo, useCallback } from "react";
import { Button, Loader, Popup, Select, Table } from "semantic-ui-react";
import { getDateFormat } from "../../../../../../../../../../common/date-format";
import { ApplicantDocumentsContext } from "../../../../../../../../../../common/ApplicantDocumentsContext";
import { byteArrayToUrl } from "../../../../../../../../../../utils";
import apis from "../../../../../../../../../../apis";
import toastr from "toastr";

const BrochureView = (props) => {
  const { data, visaType } = props;

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { brochureLanguageOptions } = useContext(ApplicantDocumentsContext);

  const filteredData = useMemo(
    () =>
      data.filter(
        (el) =>
          (!selectedLanguage || el.brochure.language === selectedLanguage) &&
          el.brochure.visaType === visaType,
      ),
    [data, selectedLanguage, visaType],
  );

  const openBrochure = useCallback(async (key) => {
    try {
      setIsLoading(key)
      const {
        data: {
          buffer: { data },
        },
      } = await apis.applicantDocumentHandler.getFile(key);
      const url = byteArrayToUrl(data, "application/pdf");
      window.open(url, "_blank");
    } catch (e) {
      console.error(e);
      toastr.error("Failed to open brochure");
    } finally {
      setIsLoading(false)
    }
  }, []);

  return (
    <>
      <div className="sk-table-filters__brochures">
      <Select
        placeholder="Select language"
        value={selectedLanguage}
        options={brochureLanguageOptions || []}
        onChange={(ev, field) => setSelectedLanguage(field.value)}
      />
      </div>
    <Table celled striped fixed>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>Document name</Table.HeaderCell>
          <Table.HeaderCell width={1}>Language</Table.HeaderCell>
          <Table.HeaderCell width={1}>Visa Type</Table.HeaderCell>
          <Table.HeaderCell width={1}>Uploaded date</Table.HeaderCell>
          <Table.HeaderCell width={1}>Is sent to applicant?</Table.HeaderCell>
          <Table.HeaderCell width={1}>Sent date</Table.HeaderCell>
          <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {filteredData.length === 0 && (
          <Table.Row>
            <Table.Cell width={1}>No documents</Table.Cell>
          </Table.Row>
        )}
        {filteredData.length > 0 &&
          filteredData.map(
            ({
              id: documentId,
              hasBrochureSentToCustomer,
              brochureSentDate,
              brochure: { name, language, visaType, activeVersion },
            }) => {
              return (
                <Fragment key={documentId}>
                  <Table.Row>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{language}</Table.Cell>
                    <Table.Cell>{visaType}</Table.Cell>
                    <Table.Cell>
                      {getDateFormat(activeVersion.dateUploaded)}
                    </Table.Cell>
                    <Table.Cell>
                      {hasBrochureSentToCustomer ? "YES" : "NO"}
                    </Table.Cell>
                    <Table.Cell>
                      {hasBrochureSentToCustomer
                        ? getDateFormat(brochureSentDate)
                        : "-"}
                    </Table.Cell>
                    <Table.Cell>
                      <Popup
                        content="Preview file"
                        position="top center"
                        trigger={
                          isLoading === activeVersion.brochureKey ? (
                            <Loader inline active />
                          ) : (
                            <Button
                              color="yellow"
                              disabled={!activeVersion.brochureKey || isLoading}
                              icon="file"
                              onClick={() =>
                                openBrochure(activeVersion.brochureKey)
                              }
                            />
                          )
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                </Fragment>
              );
            },
          )}
      </Table.Body>
    </Table>
    </>
  );
};

export default BrochureView;
