import axios from "./httpClient";

export function getUsersApi(queryParams = {}) {
  const params = { ...queryParams };
  if (params.roles && Array.isArray(params.roles)) {
    params.roles = params.roles.join(",");
  }
  return axios.get("/user", { params });
}

export function getAllUsersApi() {
  return axios.get("/user/all");
}

export function deleteUsersApi(id) {
  return axios.delete(`/user/${id}`);
}

export function createUserApi(body) {
  return axios.post(`/user/`, body);
}

export function updateUserApi(id, body) {
  return axios.patch(`/user/${id}`, body);
}

export function getUserApi(id) {
  return axios.get(`/user/${id}`);
}

export function getOnlineUsers() {
  return axios.get("user/current/online");
}

export function getUsersAvailableManager(id) {
  return axios.get(`/user/${id}/manager`);
}

export function getCurrentUserData() {
  return axios.get("user/current");
}

export function patchUserSettings(body) {
  return axios.patch(`/user/settings`, body);
}

export function patchUserAvailability(body) {
  return axios.patch(`/user/settings/availability`, { availability: body });
}

export function updateUserAvailability(id, body) {
  return axios.patch(`/user/settings/availability/${id}`, {
    availability: body,
  });
}

export function patchUserPermission(id, permissions) {
  return axios.patch(`/user/${id}/permissions`, permissions);
}

export function resetTwoFactorAuthentication(id) {
  return axios.post(`/user/${id}/reset-2fa`);
}
