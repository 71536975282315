import React from "react";
import "./TimespanLegend.styles.scss";

const defProps = [
  {
    color: "#e3b207",
    name: "Unavailable",
  },
  {
    color: "#6f6f6f",
    name: "Offline",
  },
  {
    color: "#54ab13",
    name: "Available",
  },
];

const TimespanLegend = ({ legendProps }) => {
  return (
    <div className="sk-activity-legend">
      {(legendProps || defProps).map((lp) => {
        return (
          <div className="sk-activity-color" key={lp.name}>
            <span style={{ backgroundColor: `${lp.color}` }} />
            {lp.name}
          </div>
        );
      })}
    </div>
  );
};

export default TimespanLegend;
