import React from "react";
import "./UserProfile.scss";
import Toggle from "../../../../../../../components/Toggle";
import { Button, Loader } from "semantic-ui-react";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import UserReport from "./components/UserReport";
import toastr from "toastr";
import {
  getUserApi,
  patchUserPermission,
  resetTwoFactorAuthentication,
} from "../../../../../../../apis/userApi";
import { CredentialsService } from "../../../../../../../services/credentialsService";
import { ROLES } from "../../../../../../../constants/rolesConstants";
import UserLogsTable from "./components/UserLogsTable";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      permissions: {},
    };
  }

  async componentDidMount() {
    this.getUser();
  }

  get id() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    return id;
  }

  async getUser() {
    try {
      const { data: user } = await getUserApi(this.id);
      const { permissions } = user;
      this.setState({
        user,
        permissions,
      });

      this.clonedPermissions = JSON.parse(JSON.stringify(permissions || "{}"));
    } catch (err) {
      toastr.error("Failed loading user");
    }
  }

  async updateUsersPermissions() {
    const { permissions } = this.state;

    try {
      const {
        data: { data: newPermissions },
      } = await patchUserPermission(this.id, permissions);
      this.setState({
        permissions: newPermissions,
      });

      this.clonedPermissions = JSON.parse(
        JSON.stringify(newPermissions || "{}"),
      );
      toastr.success("Successfully updated user permissions");
    } catch (err) {
      toastr.error("Failed updating users");
    }
  }

  async resetTwoFactorAuthentication() {
    try {
      await resetTwoFactorAuthentication(this.id);
      this.setState((state) => ({
        user: { ...state.user, isConfiguredTwoFactorAuth: false },
      }));
    } catch (err) {
      toastr.error("Failed ");
    }
  }

  checkCurrentlyLoggedUserPermissions(currentLoggedUser, userToCheck) {
    if (currentLoggedUser.roles.includes(ROLES.ADMIN)){
      return true
    }
    if (this.checkIfAgentIsAssignedToManager(currentLoggedUser, userToCheck)) {
      return true
    }
    return false
  }
  
  checkIfAgentIsAssignedToManager(currentLoggedUser, userToCheck) {
    return userToCheck.managerId === currentLoggedUser.id
  }

  discardChanges() {
    this.setState({ permissions: this.clonedPermissions });
  }

  permissionChange(permission) {
    const { permissions } = this.state;
    this.setState({
      permissions: {
        ...permissions,
        [permission]: !permissions[permission],
      },
    });
  }

  render() {
    const { user, permissions } = this.state;

    if (!user) {
      return <Loader active />;
    }
    return (
      <div className="user-profile-container">
        <h3 className="user-profile-header">
          <div className="user-profile-header-email">{user.email}</div>
          <div className="profileImage">
            {user.firstName.charAt(0)}
            {user.lastName.charAt(0)}
          </div>
        </h3>
        <div className="user-profile-content">
          <h2>
            {user.firstName} {user.lastName}
          </h2>
          <h4>Permissions</h4>
          <div className="user-profile-content-permissions">
            <div className="permission">
              <p> Can call</p>
              <Toggle
                name="canCall"
                checked={permissions.canCall}
                callback={() => {
                  this.permissionChange("canCall");
                }}
              ></Toggle>
            </div>
            <div className="permission">
              <p>Can receive calls</p>
              <Toggle
                name="canReceiveCalls"
                checked={permissions.canReceiveCalls}
                callback={() => {
                  this.permissionChange("canReceiveCalls");
                }}
              ></Toggle>
            </div>
            <div className="permission">
              <p>Can send message</p>
              <Toggle
                name="canSendMessage"
                checked={permissions.canSendMessage}
                callback={() => {
                  this.permissionChange("canSendMessage");
                }}
              ></Toggle>
            </div>
            <div className="permission">
              <p>Use email for ESTA bot</p>
              <Toggle
                name="canUseEmailForEstaBot"
                checked={permissions.canUseEmailForEstaBot}
                callback={() => {
                  this.permissionChange("canUseEmailForEstaBot");
                }}
              ></Toggle>
            </div>
          </div>
        </div>
        <div className="user-profile-apply-changes-button">
          { (user.isConfiguredTwoFactorAuth || user.smsAuthenticationNumber) &&
            (this.checkCurrentlyLoggedUserPermissions(CredentialsService.getUser(), user)) && (
              <ConfirmPopup
                content="Are you sure you want reset 2FA?"
                callback={() => {
                  this.resetTwoFactorAuthentication();
                }}
              >
                <Button color="green">Reset 2FA</Button>
              </ConfirmPopup>
            )}
          <ConfirmPopup
            content="Are you sure you want discard changes?"
            callback={() => this.discardChanges()}
          >
            <Button className="discard-button">Discard</Button>
          </ConfirmPopup>
          <ConfirmPopup
            content="Are you sure you want save changes?"
            callback={() => this.updateUsersPermissions()}
          >
            <Button color="teal">Apply Changes</Button>
          </ConfirmPopup>
        </div>
        <UserReport userId={this.id}></UserReport>
        <UserLogsTable userId={this.id} />
      </div>
    );
  }
}

export default UserProfile;
