import axios from "../httpClient";

function getApplicants(params) {
  return axios.get(`/export/csv/applicants`, { params });
}

function exportApplicantsToCsv(params) {
  return axios.get(`/export/csv/export-tools`, { params });
}

export default {
  getApplicants,
  exportApplicantsToCsv,
};
