import axios from "../httpClient";

function getTemplates(language) {
  return axios.get("sendgrid-templates");
}

function getTemplate(id) {
  return axios.get(`sendgrid-templates/${id}`);
}

function getTemplateLanguages() {
    return axios.get(`sendgrid-templates/languages`);
  }

function updateTemplate(templateId, template) {
  return axios.patch(`/sendgrid-templates/${templateId}`, template);
}

function deleteTemplate(id) {
  return axios.delete(`sendgrid-templates/${id}`);
}

function addTemplate(template) {
  return axios.post("sendgrid-templates", template);
}

function addTemplateLanguage(language) {
    return axios.patch("languages/add-language", {modelType: "SendgridTemplate", language});
  }

export default {
  getTemplates,
  getTemplate,
  deleteTemplate,
  addTemplate,
  updateTemplate,
  getTemplateLanguages,
  addTemplateLanguage,
};
