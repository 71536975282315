import React from "react";
import { Tab } from "semantic-ui-react";
import { AuthService } from "../../services/authService";
import { CredentialsService } from "../../services/credentialsService";
import apis from "../../apis";
import { EVENT_TYPE, LOG_TYPE } from "../../constants/logs";
import { connect } from "react-redux";

class MenuTabs extends React.Component {
  state = {
    activeIndex: 0,
  };
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      panes: this.getPanesFromTabs(),
    };
  }

  createEventLog = async (currentPath, destination) => {
    const { user } = this.props;
    try {
      await apis.logs.createEventLog({
        logType: LOG_TYPE.OUTSIDE_APPLICANT,
        eventType: EVENT_TYPE.NAVIGATION,
        userId: user.id,
        actions: [
          {
            propertyName: "navigation",
            oldValue: currentPath,
            newValue: destination,
          },
        ],
      });
    } catch (e) {
      console.error(e);
    }
  };

  getPanesFromTabs() {
    const { tabs = [] } = this.props;
    const panes = tabs
      .filter((tab) => {
        if (!tab.authorizedRoles) {
          return true;
        }
        const user = CredentialsService.getUser();
        return AuthService.checkRolesForUser(user, tab.authorizedRoles);
      })
      .map((tab) => ({
        menuItem: tab.name,
        render: () => <Tab.Pane>{tab.component}</Tab.Pane>,
        route: tab.route,
      }));
    return panes;
  }

  navigateToPane(pane) {
    const { history, defaultRoute, match } = this.props;
    const { route } = pane;
    this.createEventLog(match.url, `/${defaultRoute}/${route}`);
    history.push(`/${defaultRoute}/${route}`);
  }

  componentDidMount() {
    const {
      match: { params },
      pathParam,
    } = this.props;
    const routeParam = params[pathParam];
    const { panes } = this.state;
    const paneIndex = panes.findIndex((pane) => pane.route === routeParam);
    if (paneIndex === -1) {
      const chosenPane = panes[0];
      this.navigateToPane(chosenPane);
    } else {
      this.setState({ activeIndex: paneIndex });
    }
  }

  render() {
    const { activeIndex, panes } = this.state;
    return (
      <Tab
        activeIndex={activeIndex}
        panes={panes}
        onTabChange={(event, component) => {
          this.setState({ activeIndex: component.activeIndex });
          const pane = component.panes[component.activeIndex];
          this.navigateToPane(pane);
        }}
      />
    );
  }
}

const mapStateToProps = ({ currentUser }) => {
  return {
    user: currentUser,
  };
};

export default connect(mapStateToProps)(MenuTabs);
