import React from 'react'
import { Checkbox, Table } from "semantic-ui-react";

const ETAReviewAndCertification = () => {
  return (
    <React.Fragment>
        <Table.Row>
            <Table.Cell colSpan="2">
                <h4>Declaration of Applicant</h4>
                <div>
                    <p>The information I have provided in this application is truthful, complete and correct.</p>
                    <p>I understand that misrepresentation is an offence under section 127 of the <a href="https://laws-lois.justice.gc.ca/eng/annualstatutes/2001_27/FullText.html">Immigration and Refugee Protection Act</a> and may result in a finding of inadmissibility to Canada or removal from Canada.</p>
                    <p>I also do agree that by typing my name below and clicking submit, I am electronically signing this application.</p>
                </div>
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>AGREE</Table.Cell>
            <Table.Cell>
                <Checkbox checked={true} />
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell colSpan="2">
                <h5>Third Party Authorization</h5>
                <p>Before I submit this form, I authorize GovAssist LLC to review, modify, and submit the application on my behalf.</p>
                <p>At the same time, I acknowledge that I am applying via a third party service that is not associated with the Canadian Government. My purchase would include the standard government fee, as well as processing and service fees to GovAssist.</p>
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>AGREE</Table.Cell>
            <Table.Cell>
                <Checkbox checked={true} />
            </Table.Cell>
        </Table.Row>
    </React.Fragment>
  )
}

export default ETAReviewAndCertification