import pages from "./pages";
import { ROLES } from "../../../../../constants/rolesConstants";
import routeConfig from "../../../../modules/routeConfig";

const routes = [
  {
    path: "/admin/:subRoute",
    component: pages.AdminArea,
    authorizedRoles: [ROLES.ADMIN],
  },
  // {
  //   path: "/qa-manager/:subRoute",
  //   component: pages.QAManagerArea,
  //   authorizedRoles: [ROLES.ADMIN, ROLES.QA_MANAGER],
  // },
  // {
  //   path: "/qa-agent/:subRoute",
  //   component: pages.QAAgentArea,
  //   authorizedRoles: [ROLES.ADMIN, ROLES.QA_AGENT],
  // },
  // {
  //   path: "/manager/:subRoute",
  //   component: pages.ManagerArea,
  //   authorizedRoles: [
  //     ROLES.ADMIN,
  //     ROLES.BT_MANAGER,
  //     ROLES.L1_MANAGER,
  //     ROLES.L2_MANAGER,
  //   ],
  // },
  // {
  //   path: "/agent/:subRoute",
  //   component: pages.AgentArea,
  //   authorizedRoles: [
  //     ROLES.ADMIN,
  //     ROLES.BT_AGENT,
  //     ROLES.L1_AGENT,
  //     ROLES.L2_AGENT,
  //   ],
  // },
];

export default routeConfig(routes);
