import React, { useCallback, useEffect } from "react";
import {
  Header,
  Segment,
  Table,
  Button,
  Input,
  Loader,
  Icon,
} from "semantic-ui-react";
import { useFilters, notEmptyValidator } from "../../../../../hooks";
import {
  createEmbassyApi,
  updateEmbassyApi,
  deleteEmbassyApi,
  getEmbassiesApi,
} from "../../../../../apis/interviewsApi";
import ModalForm from "../../../../../components/common/ModalForm";
import ConfirmPopup from "../../../../../components/ConfirmPopup";
import * as toastr from "toastr";
import EmbassyForm from "./components/EmbassyForm";
import { usePagination } from "../../../../../hooks/usePagination.hook";

const columns = [
  {
    label: "Country",
    prop: "country",
    width: 2,
  },
  {
    label: "U.S. Consulates",
    prop: "usConsulate",
    width: 2,
  },
  {
    label: "Interview type",
    prop: "accountType",
    width: 2,
  },
  {
    label: "Embassy Login",
    prop: "embassyEmail",
    width: 4,
  },
  {
    label: "Embassy Password",
    prop: "embassyPassword",
    width: 3,
  },
  {
    label: "Type",
    prop: "type",
    width: 2,
  },
];

const InterviewConfigurationPage = () => {
  const { filters, normalizedFilters, setFormFilter } = useFilters(
    {
      searchString: "",
    },
    {
      localStorageKey: "embassies",
      filterValidators: {
        searchString: notEmptyValidator(),
      },
      filterDebounces: {
        searchString: 700,
      },
      temporaryFilters: ["searchString"],
    },
  );

  const {
    items: embassies,
    Paginator,
    loadItems: loadEmbassies,
    setItems: setEmbassies,
    isLoading,
  } = usePagination(getEmbassiesApi, {
    initialLoad: false,
    clearOnLoad: false,
  });

  const updateEmbassy = useCallback(
    async (embassyId, changedPart) => {
      try {
        const { data } = await updateEmbassyApi(embassyId, changedPart);
        setEmbassies((prev) =>
          prev.map((el) => (el._id === embassyId ? data : el)),
        );
        toastr.success("Successfully updated embassy account");
      } catch (err) {
        console.error(err);
        toastr.error("Failed to edit embassy account");
      }
    },
    [setEmbassies],
  );

  const deleteEmbassy = useCallback(
    async (embassyId) => {
      try {
        await deleteEmbassyApi(embassyId);
        setEmbassies((prev) => prev.filter((el) => el._id !== embassyId));
        toastr.success("Successfully deleted embassy account");
      } catch (err) {
        console.error(err);
        toastr.error("Failed to delete embassy account");
      }
    },
    [setEmbassies],
  );

  const createEmbassy = useCallback(
    async (embassyToCreate) => {
      try {
        const { data } = await createEmbassyApi(embassyToCreate);
        setEmbassies((prev) => [...prev, data]);
        toastr.success("Successfully created embassy account");
      } catch (err) {
        console.error(err);
        toastr.error("Failed creating embassy account");
      }
    },
    [setEmbassies],
  );

  useEffect(() => {
    loadEmbassies(1, { ...normalizedFilters });
  }, [loadEmbassies, normalizedFilters]);

  const renderHeaderActions = useCallback(() => {
    const { searchString } = filters;

    return (
      <div
        style={{ float: "right" }}
        className="interviews-table__header__actions"
      >
        <Input
          placeholder="Search"
          id="search-input"
          name="searchString"
          value={searchString || ""}
          onChange={setFormFilter}
        />
        <ModalForm
          headerText="Add new embassy account"
          trigger={<Button>Add New</Button>}
          onSubmit={(result) => createEmbassy(result)}
          className="embassy-modal-form"
          closeOnSubmit
        >
          <EmbassyForm />
        </ModalForm>
      </div>
    );
  }, [createEmbassy, filters, setFormFilter]);

  const renderEmbassyActions = useCallback(
    (embassy) => {
      return (
        <React.Fragment>
          <ModalForm
            headerText="Edit embassy account"
            loaderText="Preparing data..."
            onSubmit={(result) => updateEmbassy(embassy._id, result)}
            closeOnSubmit
            trigger={
              <Button color="blue" size="mini" icon>
                <Icon name="edit" />
              </Button>
            }
          >
            <EmbassyForm
              embassy={
                typeof embassy.usConsulate === "string"
                  ? { ...embassy, usConsulate: embassy.usConsulate }
                  : embassy
              }
            />
          </ModalForm>
          <ConfirmPopup
            callback={() => deleteEmbassy(embassy._id)}
            content={`Are you sure you want to REMOVE embassy account: ${embassy.embassyLogin}`}
          >
            <Button color="red" size="mini" icon>
              <Icon name="trash" />
            </Button>
          </ConfirmPopup>
        </React.Fragment>
      );
    },
    [deleteEmbassy, updateEmbassy],
  );

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader active />
      ) : (
        <Segment.Group>
          <Segment
            className="tableTopSegment interviews-table__header"
            inverted
          >
            <Header className="tableTitle" size="large" floated="left">
              Configuration
            </Header>
            {renderHeaderActions()}
          </Segment>
          <Table celled striped fixed className="interviews-table">
            <Table.Header>
              <Table.Row>
                {columns.map((column) => (
                  <Table.HeaderCell key={column.prop} width={column.width}>
                    {column.label}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell width={2}>Account Status</Table.HeaderCell>
                <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {embassies &&
                Array.isArray(embassies) &&
                embassies.map((embassy) => (
                  <Table.Row key={embassy._id}>
                    {columns.map((column) => (
                      <Table.Cell key={column.prop}>
                        {embassy[column.prop]}
                      </Table.Cell>
                    ))}
                    <Table.Cell>
                      {embassy.accountDisabled ? "Disabled" : "Active"}
                    </Table.Cell>
                    <Table.Cell width={1}>
                      {renderEmbassyActions(embassy)}
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={columns.length + 2}>
                  <Paginator />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Segment.Group>
      )}
    </React.Fragment>
  );
};

export default InterviewConfigurationPage;
