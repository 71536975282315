import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Table,
  Segment,
  Header,
  Button,
  Icon,
  Popup,
  Select,
  Loader,
} from "semantic-ui-react";
import { history } from "../../../../../../../../../../modules/history";
import { EMAIL_PREVIEW } from "../../../../../../../../../../constants/serverEndpoints";
import ConfirmPopup from "../../../../../../../../../../components/ConfirmPopup";
import toastr from "toastr";
import { nestedUpdateObject } from "../../../../../helpers/nestedObjects";
import { generateSchema } from "../../../../../helpers/schemaGenerator";
import { SCHEMAS } from "../../../../../constants/schemaGenerator/schemas";
import { SOCKET_EVENTS } from "../../../../../../../../../../constants/socketEvents";
import socketService from "../../../../../../../../../../services/socketService";
import { filter } from "rxjs/operators";
import { getEmptyApplicantFromSchema } from "../../../../../helpers/schemaManager";
import { fetchUspsSchemaData } from "../../../../../../../../../../store/actions/schemaDataActions";
import {
  USPS_APPLICANT_STATUSES,
  PRE_SUBMITTED_USPS_STATUSES,
  USPS_AGENT_CHANGEABLE_STATUSES,
} from "../../constants/applicant_statuses";
import {
  uspsGetApplicantApi,
  uspsUpdateApplicantApi,
  uspsChangeApplicantStatusApi,
} from "../../../../../../../../../../apis/usps/uspsApplicantApi";
import StatusHistory from "../../../../../components/StatusHistory";

class UspsApplicantEdit extends Component {
  constructor(props) {
    super(props);

    const emptyApplicant = getEmptyApplicantFromSchema(SCHEMAS["usps"]);
    this.state = {
      errors: null,
      editingApplicant: emptyApplicant,
      newStatus: null,
    };

    this.subscriptions = [];

    this.changeState = this.changeState.bind(this);
    this.submitChanges = this.submitChanges.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.setNewImagesListener();
    this.setBotErrorListener();
    this.setStatusListener();
  }

  async componentDidMount() {
    const { fetchSchemaData } = this.props;
    const { id } = this.props.match.params;
    if (!this.schemaData) {
      fetchSchemaData();
    }
    if (id) {
      const { data } = await uspsGetApplicantApi(id);
      if (data) {
        this.setState({
          editingApplicant: { ...this.state.editingApplicant, ...data },
        });
        return;
      }
    }
    this.navigateDashboard();
  }

  get schemaData() {
    const {
      schemaData: { usps: uspsSchemaData },
    } = this.props;
    return uspsSchemaData;
  }

  componentWillUnmount() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }
  setBotErrorListener = () => {
    const { id } = this.props.match.params;
    const subscription = socketService.socketSubject
      .pipe(
        filter(
          (event) =>
            event.type === SOCKET_EVENTS.usps.BOT_ERROR &&
            id === event.data.applicantId,
        ),
      )
      .subscribe((event) => {
        const { botError } = event.data;
        this.setState({
          editingApplicant: {
            ...this.state.editingApplicant,
            errors: [botError, ...this.state.editingApplicant.errors],
          },
        });
      });

    this.subscriptions.push(subscription);
  };

  setNewImagesListener = () => {
    const { id } = this.props.match.params;
    const subscription = socketService.socketSubject
      .pipe(
        filter(
          (event) =>
            event.type === SOCKET_EVENTS.usps.NEW_IMAGE &&
            id === event.data.applicantId,
        ),
      )
      .subscribe((event) => {
        const { applicantId, images } = event.data;
        if (id === applicantId) {
          this.setState({
            editingApplicant: {
              ...this.state.editingApplicant,
              imagePaths: images,
            },
          });
        }
      });

    this.subscriptions.push(subscription);
  };

  setStatusListener = () => {
    const { id } = this.props.match.params;
    const applicantStatusChangeSubscription = socketService.socketSubject
      .pipe(
        filter(
          (event) =>
            event.type === SOCKET_EVENTS.usps.STATUS_CHANGED &&
            id === event.data._id,
        ),
      )
      .subscribe((event) => {
        const { status } = event.data;
        this.setState({
          editingApplicant: {
            ...this.state.editingApplicant,
            status,
          },
        });
        toastr.success("Current applicant status changed", "Status update");
      });
    this.subscriptions.push(applicantStatusChangeSubscription);
  };

  navigateDashboard() {
    history.push("/usps");
  }

  async submitChanges() {
    const { editingApplicant } = this.state;
    try {
      const { data } = await uspsUpdateApplicantApi(
        editingApplicant._id,
        editingApplicant,
      );
      this.setState({ errors: null, editingApplicant: data });
      toastr.success("Applicant successfully changed!", "OK");
    } catch (error) {
      if (error.response && [422, 409].includes(error.response.status)) {
        const { errors } = error.response.data.error;
        this.setState({ errors });
      }
      toastr.error("Applicant cannot be submitted! Validation error.", "Error");
    }
  }

  /**
   *
   * @param {string} propName
   * @param {any} propValue
   * @param {string} propPath
   */
  changeState(propName, propValue, propPath, afterChange = () => {}) {
    if (propPath) {
      const propNames = propPath.split(".");
      const newValue = nestedUpdateObject(
        propNames,
        propName,
        propValue,
        this.state.editingApplicant,
      );
      this.setState(
        { editingApplicant: { ...this.state.editingApplicant, ...newValue } },
        () => afterChange(),
      );
      return;
    }
    if (this.state.editingApplicant.hasOwnProperty(propName)) {
      const state = { [propName]: propValue };
      this.setState(
        { editingApplicant: { ...this.state.editingApplicant, ...state } },
        () => afterChange(),
      );
    } else {
      console.error(propName, "FIELD IS MISSING!");
      this.setState(
        {
          editingApplicant: {
            ...this.state.editingApplicant,
            [propName]: propValue,
          },
        },
        () => afterChange(),
      );
    }
  }

  async changeStatus(status) {
    const { editingApplicant } = this.state;
    await uspsChangeApplicantStatusApi(editingApplicant._id, status);
    toastr.success("Applicant status changed!", "OK");
    this.navigateDashboard();
  }

  renderStatusChange() {
    const { newStatus, editingApplicant } = this.state;
    return (
      <div style={{ display: "flex" }}>
        <Select
          placeholder="Select new status"
          options={USPS_AGENT_CHANGEABLE_STATUSES.map((status) => ({
            value: status,
            text: status,
          }))}
          style={{ flex: "0 1 30%" }}
          onChange={(fieldOptions, field) =>
            this.setState({ newStatus: field.value })
          }
          value={newStatus}
        />
        <ConfirmPopup
          content="Are you sure you want to change applicant status?"
          callback={async () => {
            const { newStatus } = this.state;
            this.changeStatus(newStatus);
          }}
        >
          <Button
            primary
            disabled={
              newStatus === null || newStatus === editingApplicant.status
            }
          >
            Submit status change
          </Button>
        </ConfirmPopup>
      </div>
    );
  }

  renderIcons(property, getContent, onClick, color, iconName) {
    const { editingApplicant } = this.state;
    return (
      editingApplicant[property] &&
      editingApplicant[property].map((value, index) => (
        <Popup
          key={index}
          content={getContent(value)}
          trigger={
            <Button
              onClick={() => onClick(value)}
              color={color}
              size="medium"
              icon
            >
              {index + 1}.<Icon name={iconName} />
            </Button>
          }
        />
      ))
    );
  }

  render() {
    const { editingApplicant, errors } = this.state;

    if (!editingApplicant || !this.schemaData) {
      return <Loader active />;
    }

    const schema = SCHEMAS["usps"];

    return (
      <Segment.Group>
        <Segment className="tableTopSegment" inverted>
          <Header className="tableTitle" size="large" floated="left">
            <h3>
              {editingApplicant.firstName} ({editingApplicant.middleName}){" "}
              {editingApplicant.lastName} - {editingApplicant.status}
            </h3>
            <StatusHistory logs={editingApplicant.logs}></StatusHistory>
          </Header>
        </Segment>
        <div style={{ display: "flex", margin: "12px" }}>
          {this.renderIcons(
            "errors",
            (error) => error.message,
            (error) => window.open(error.imagePath, "_blank"),
            "red",
            "exclamation triangle",
          )}
          {this.renderIcons(
            "emails",
            (email) => email.subject,
            (email) => window.open(`${EMAIL_PREVIEW(email._id)}`, "_blank"),
            "blue",
            "mail",
          )}
          {this.renderIcons(
            "imagePaths",
            (image) => image,
            (image) => window.open(image, "_blank"),
            "green",
            "image",
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "12px",
          }}
        >
          {this.renderStatusChange()}
        </div>

        <br />
        <Table celled striped fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>Property name</Table.HeaderCell>
              <Table.HeaderCell width={3}>Property value</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {generateSchema(
              schema,
              editingApplicant,
              this.schemaData,
              this.changeState,
              errors,
            )}
          </Table.Body>
        </Table>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "12px",
          }}
        >
          <ConfirmPopup
            content="Are you sure you want to mark applicant for submission?"
            callback={async () => {
              this.changeStatus(USPS_APPLICANT_STATUSES.PENDING_SUBMISSION);
            }}
          >
            {PRE_SUBMITTED_USPS_STATUSES.includes(editingApplicant.status) ? (
              <Button primary>Mark as ready for submission</Button>
            ) : (
              <Button primary>
                Restart applicant status to 'Ready for submission'
              </Button>
            )}
          </ConfirmPopup>

          <ConfirmPopup
            content="Are you sure you want submit applicant changes?"
            callback={() => this.submitChanges()}
          >
            {PRE_SUBMITTED_USPS_STATUSES.includes(editingApplicant.status) ? (
              <Button positive>Save</Button>
            ) : (
              <Button disabled positive>
                Submit
              </Button>
            )}
          </ConfirmPopup>
        </div>
      </Segment.Group>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    schemaData: state.schemaData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSchemaData: bindActionCreators(fetchUspsSchemaData, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UspsApplicantEdit),
);
