import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Segment,
  Header,
  Table,
  Button,
  Form,
  Select,
} from "semantic-ui-react";
import Spinner from "../../../../../../../components/Spinner";
import apis from "../../../../../../../apis";
import toastr from "toastr";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import ModalForm from "../../../../../../../components/common/ModalForm";
import TemplateForm from "./components/TemplateForm";
import { departmentOptions } from "./components/TemplateForm/TemplateForm.component";
import { reduceFieldArrayToArrayOfObjects } from "../../../../../../../utils";

import "./DocHandlerTemplates.styles.scss";

const DocHandlerTemplates = () => {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [languageOptions, setLanguageOptions] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const fetchLanguages = useCallback(async () => {
    setIsPageLoading(true);

    try {
      const { data } =
        await apis.documentHandlerTemplates.getTemplateLanguages();
      setLanguageOptions(
        data.languages.map((lng) => ({ text: lng, value: lng })),
      );
    } catch (e) {
      toastr.error("Failed to fetch languages.");
      console.error(e);
    }

    setIsPageLoading(false);
  }, []);

  const fetchTemplates = useCallback(async () => {
    setIsPageLoading(true);

    try {
      const { data } = await apis.documentHandlerTemplates.getTemplates();
      const filteredByLanguage = selectedLanguage
        ? data.filter((el) => el.language === selectedLanguage)
        : data;
      const filteredByDepartment = selectedDepartment
        ? filteredByLanguage.filter((el) =>
            el.departments.includes(selectedDepartment),
          )
        : filteredByLanguage;
      setTemplates(filteredByDepartment);
    } catch (error) {
      toastr.error("Failed to fetch templates.");
      console.error(error);
    }

    setIsPageLoading(false);
  }, [selectedDepartment, selectedLanguage]);

  const handleDeleteTemplate = useCallback(async (templateId) => {
    setIsRequestLoading(true);

    try {
      await apis.documentHandlerTemplates.deleteTemplate(templateId);
      setTemplates((prev) => prev.filter((el) => el._id !== templateId));
      toastr.success("Successfully deleted template.");
    } catch (error) {
      toastr.error("Failed to delete template.");
      console.error(error);
    }

    setIsRequestLoading(false);
  }, []);

  const handleCreateTemplate = useCallback(async (template) => {
    setIsRequestLoading(true);

    let tagsAndProps = reduceFieldArrayToArrayOfObjects(
      template,
      "tagsAndProps",
    );

    tagsAndProps =
      tagsAndProps.length > 1
        ? tagsAndProps.reduce((acc, v, index) => {
            return {
              ...(index > 1
                ? acc
                : { [acc.tag === "Other" ? acc.other : acc.tag]: acc.prop }),
              [v.tag === "Other" ? v.other : v.tag]: v.prop,
            };
          })
        : {
            [tagsAndProps[0].tag === "Other"
              ? tagsAndProps[0].other
              : tagsAndProps[0].tag]: tagsAndProps[0].prop,
          };

    try {
      const { name, templateId, language, departments } = template;
      const { data } = await apis.documentHandlerTemplates.addTemplate({
        name,
        templateId,
        language,
        departments,
        tagsAndProps,
      });
      setTemplates((prev) => [...prev, data]);
      toastr.success("Successfully added new template.");
    } catch (error) {
      toastr.error("Failed to add new template.");
      if (error?.response?.data?.message) {
        toastr.error(error.response.data.message);
      }
      console.error(error);
    }

    setIsRequestLoading(false);
  }, []);

  const handleEditTemplate = useCallback(async (id, template) => {
    setIsRequestLoading(true);

    let tagsAndProps = reduceFieldArrayToArrayOfObjects(
      template,
      "tagsAndProps",
    );

    tagsAndProps =
      tagsAndProps.length > 1
        ? tagsAndProps.reduce((acc, v, index) => {
            return {
              ...(index > 1
                ? acc
                : { [acc.tag === "Other" ? acc.other : acc.tag]: acc.prop }),
              [v.tag === "Other" ? v.other : v.tag]: v.prop,
            };
          })
        : {
            [tagsAndProps[0].tag === "Other"
              ? tagsAndProps[0].other
              : tagsAndProps[0].tag]: tagsAndProps[0].prop,
          };

    try {
      const { name, templateId, language, departments, emailSubject } =
        template;
      await apis.documentHandlerTemplates.updateTemplate(id, {
        name,
        templateId,
        language,
        departments,
        tagsAndProps,
        emailSubject,
      });
      setTemplates((prev) =>
        prev.map((el) => (el._id === id ? { ...el, ...template } : el)),
      );
      toastr.success("Successfully edited template.");
    } catch (error) {
      toastr.error("Failed to edit template.");
      console.error(error);
    }
    setIsRequestLoading(false);
  }, []);

  const handleAddNewLanguage = useCallback(async (language) => {
    setIsRequestLoading(true);

    try {
      await apis.documentHandlerTemplates.addTemplateLanguage(language);
      setLanguageOptions((prev) => [
        ...prev,
        { text: language, value: language },
      ]);
      toastr.success("Successfully added new language.");
    } catch (e) {
      toastr.error("Failed to add new language.");
      console.error(e);
    }

    setIsRequestLoading(false);
  }, []);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  return (
    <Segment.Group>
      <Segment className="tableTopSegment" inverted>
        <Header className="tableTitle" size="large" floated="left">
          <h3>Document handler templates</h3>
          <div className="template-actions">
            <Select
              placeholder="Select language"
              value={selectedLanguage}
              options={
                languageOptions
                  ? [{ text: "All", value: "" }, ...languageOptions]
                  : []
              }
              onChange={(ev, field) => setSelectedLanguage(field.value)}
            />
            <Select
              placeholder="Select department"
              value={selectedDepartment}
              options={[{ text: "All", value: "" }, ...departmentOptions]}
              onChange={(ev, field) => setSelectedDepartment(field.value)}
            />
            <ModalForm
              headerText="Add new language"
              loaderText="Preparing data..."
              onSubmit={(result) => handleAddNewLanguage(result.language)}
              closeOnSubmit
              trigger={<Button type="button">Add New Language</Button>}
            >
              <Form.Field>
                <Form.Input type="input" name="language" label="New language" />
              </Form.Field>
            </ModalForm>
            <ModalForm
              headerText="Add new document handler template"
              trigger={<Button>Add New Template</Button>}
              onSubmit={(result) => handleCreateTemplate(result)}
              closeOnSubmit
              className="template-modal-form"
            >
              <TemplateForm languages={languageOptions} />
            </ModalForm>
          </div>
        </Header>
      </Segment>
      <Segment>
        {isPageLoading ? (
          <Spinner />
        ) : (
          <>
            <Table celled striped fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>Template name</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Language</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Departments</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Sendgrid ID</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {templates.length === 0 && (
                  <Table.Row>
                    <Table.Cell width={1}>No templates</Table.Cell>
                  </Table.Row>
                )}
                {templates.length > 0 &&
                  templates.map((template) => {
                    return (
                      <Fragment key={template._id}>
                        <Table.Row>
                          <Table.Cell>{template.name}</Table.Cell>
                          <Table.Cell>{template.language}</Table.Cell>
                          <Table.Cell>
                            {template.departments.join(", ")}
                          </Table.Cell>
                          <Table.Cell>{template.templateId}</Table.Cell>
                          <Table.Cell>
                            <ModalForm
                              headerText="Edit template"
                              loaderText="Preparing data..."
                              onSubmit={(result) =>
                                handleEditTemplate(template._id, result)
                              }
                              closeOnSubmit
                              submitButtonText="Save"
                              className="template-modal-form"
                              trigger={
                                <Button
                                  color="green"
                                  type="button"
                                  disabled={isRequestLoading}
                                  icon="edit"
                                />
                              }
                            >
                              <TemplateForm
                                template={template}
                                languages={languageOptions}
                              />
                            </ModalForm>

                            <ConfirmPopup
                              content="Are you sure you want to delete this template?"
                              callback={() =>
                                handleDeleteTemplate(template._id)
                              }
                            >
                              <Button
                                color="red"
                                type="button"
                                disabled={isRequestLoading}
                                icon="trash"
                              />
                            </ConfirmPopup>
                          </Table.Cell>
                        </Table.Row>
                      </Fragment>
                    );
                  })}
              </Table.Body>
            </Table>
          </>
        )}
      </Segment>
    </Segment.Group>
  );
};

export default DocHandlerTemplates;
