import { makeColorForShift } from "./makeColorForShift";

/**
 * Get shift reports legend objects
 *
 * @typedef {{segmentGroup: { firstSegment: string, lastSegment: string }}} segmentGroup
 * @typedef {{segmentGroup1: segmentGroup, segmentGroup2: segmentGroup, segmentGroup3: segmentGroup }} shiftGroup
 * @typedef {{color: string, name: string}} legendObject
 *
 * @param {shiftGroup} shifts
 * @returns {legendObject}
 */
export const makeLegendForShifts = (shifts) => {
  const res = [];

  // iterating through the shiftGroup object
  //
  for (const [key, value] of Object.entries(shifts)) {
    // key - shift group name
    // value - object: first and last segment
    //
    res.push({
      color: makeColorForShift(key),
      name: key + ": " + value.firstSegment + " - " + value.lastSegment,
    });
  }

  return res;
};
