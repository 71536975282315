import React from 'react';
import { Input } from "semantic-ui-react";
import toastr from "toastr";
import { getSecretById, updateSecretById } from '../../../../../../apis/secrets';
import { capitalizeWordsForApplicantName } from "./capitalizeWordsForApplicantName";
import EditSecretInputField from './EditSecretInputField';
import { parseSecret } from './parseSecret';
import SecretField from './SecretField';
import './SecretInputField.styles.scss';



const SecretInputField = ({schemaData, useLabel,path, onChange, disabled, propertyValue, getPropertyValue,changeState,label,applicantProperty,applicant, allowEditSecret = true}) => {
    const [isEditingSecret, setIsEditingSecret]= React.useState(false)
    
    const {isSecret, value,secretId} = parseSecret(propertyValue)

    const newValue = React.useRef('')
    const [isLoading,setIsLoading] = React.useState(false)
    const [reveledSecret, setReveledSecret] = React.useState(null)

    const handleUpdate = async () => {
      setIsLoading(true)
      await updateSecretById(secretId,newValue.current.value)
      newValue.current  = ''
      setIsLoading(false)
      setIsEditingSecret(false)
      toastr.success(`Secret updated.`);
    }
    
    const handleCancelEdit = () => {
      newValue.current  = ''
      setIsEditingSecret(p=>!p)
    }
    const handleRevealSecret = async () => {
      const result = await getSecretById(secretId)
      setReveledSecret(result)
    }
    React.useEffect(() => {
      if(reveledSecret) { 
        const timeoutId = setTimeout(() => {
          setReveledSecret(null)
        },30000)
        return () => clearTimeout(timeoutId)
      }
    },[reveledSecret])
    
    if(isEditingSecret){
      return (
        <EditSecretInputField 
            isLoading={isLoading}
            useLabel={useLabel}
            label={label}
             onChange={(v) => {newValue.current = v}}
            handleUpdate={handleUpdate}
            handleCancelEdit={handleCancelEdit}
        />
      )
    }
    if(isSecret) {
      return (
        <SecretField
            isLoading={isLoading}
            reveledSecret={reveledSecret}
            onRevealSecret= {handleRevealSecret}
            onEditClick={() => setIsEditingSecret(p=>!p)}
            allowEdit={allowEditSecret}
        />
      )
    } else {
      return (
          <Input
          label={useLabel ? label : null}
          value={capitalizeWordsForApplicantName({propertyValue: value, applicantProperty,applicant})}
          type="text"
          disabled={disabled}
          onChange={(_, field) => {
            changeState(applicantProperty, field.value, path, () =>
              onChange(field.value, changeState, applicant, schemaData),
            );
          }}
          onBlur={() => {
            let value = getPropertyValue(applicant, path, applicantProperty);
            if (value) {
              value = value.trim().replace(/ {2,}/g, " ");
              changeState(applicantProperty, value, path);
            }
          }}
        />
      )   
  }
}

export default SecretInputField