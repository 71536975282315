export const APPLICATION_COUNTRY_TO_EMBASSY_COUNTRY = {
  TIA: "Albania",
  ALG: "Algeria",
  LUA: "Angola",
  BNS: "Argentina",
  YRV: "Armenia",
  MLB: "Australia",
  PRT: "Australia",
  SYD: "Australia",
  VNN: "Austria",
  BKU: "Azerbaijan",
  NSS: "Bahamas",
  MNA: "Bahrain",
  DHK: "Bangladesh",
  BGN: "Barbados",
  BRS: "Belgium",
  BLZ: "Belize",
  COT: "Benin",
  HML: "Bermuda",
  LPZ: "Bolivia",
  SAR: "Bosnia and Herzegovina",
  GAB: "Botswana",
  BRA: "Brazil",
  PTA: "Brazil",
  RCF: "Brazil",
  RDJ: "Brazil",
  SPL: "Brazil",
  BSB: "Brunei",
  SOF: "Bulgaria",
  OUG: "Burkina Faso",
  RNG: "Burma",
  BUJ: "Burundi",
  PIA: "Cabo Verde",
  PHP: "Cambodia",
  YDE: "Cameroon",
  CLG: "Canada",
  HLF: "Canada",
  MTL: "Canada",
  OTT: "Canada",
  QBC: "Canada",
  TRT: "Canada",
  VAC: "Canada",
  NDJ: "Chad",
  SNT: "Chile",
  BEJ: "China",
  GUZ: "China",
  SHG: "China",
  SNY: "China",
  WUH: "China",
  BGT: "Colombia",
  BRZ: "The Democratic Republic of the Congo",
  KIN: "The Democratic Republic of the Congo",
  SNJ: "Costa Rica",
  ABJ: "Cote d'Ivoire (Ivory Coast)",
  ZGB: "Croatia",
  CRC: "Curacao",
  NCS: "Cyprus",
  PRG: "Czech Republic",
  CPN: "Denmark",
  DJI: "Djibouti",
  SDO: "Dominican Republic",
  GYQ: "Ecuador",
  QTO: "Ecuador",
  CRO: "Egypt",
  SNS: "El Salvador",
  LND: "United Kingdom",
  MBO: "Equatorial Guinea",
  ASM: "Eritrea",
  TAL: "Estonia",
  MBA: "Eswatini",
  ADD: "Ethiopia",
  SUV: "Fiji",
  HLS: "Finland",
  PRS: "France",
  LIB: "Gabon",
  BAN: "Gambia",
  TBL: "Georgia",
  BRL: "Germany",
  FRN: "Germany",
  MUN: "Germany",
  ACC: "Ghana",
  ATH: "Greece",
  GTM: "Guatemala",
  CRY: "Guinea",
  GEO: "Guyana",
  PTP: "Haiti",
  TGG: "Honduras",
  HNK: "Hong Kong",
  BDP: "Hungary",
  RKJ: "Iceland",
  MDR: "India",
  HYD: "India",
  CLC: "India",
  BMB: "India",
  NWD: "India",
  JAK: "Indonesia",
  SRB: "Indonesia",
  BGH: "Iraq",
  ERB: "Iraq",
  DBL: "Ireland",
  TLV: "Israel, Jerusalem, The West Bank, and Gaza",
  FLR: "Italy",
  MLN: "Italy",
  NPL: "Italy",
  RME: "Italy",
  KNG: "Jamaica",
  NHA: "Japan",
  KBO: "Japan",
  TKY: "Japan",
  JRS: "Israel, Jerusalem, The West Bank, and Gaza",
  AMM: "Jordan",
  ATA: "Kazakhstan",
  AST: "Kazakhstan",
  NRB: "Kenya",
  PRI: "Kosovo",
  KWT: "Kuwait",
  BKK: "Kyrgyzstan",
  VNT: "Laos",
  RGA: "Latvia",
  BRT: "Lebanon",
  MRV: "Liberia",
  VIL: "Lithuania",
  LXM: "Luxembourg",
  SKO: "North Macedonia",
  ANT: "Madagascar",
  LIL: "Malawi",
  KLL: "Malaysia",
  BAM: "Mali",
  VLL: "Malta",
  MAJ: "Marshall Islands",
  NUK: "Mauritania",
  PTL: "Mauritius",
  CDJ: "Mexico",
  GDL: "Mexico",
  HER: "Mexico",
  MTM: "Mexico",
  MER: "Mexico",
  MEX: "Mexico",
  MTR: "Mexico",
  NGL: "Mexico",
  NVL: "Mexico",
  TJT: "Mexico",
  KOL: "Micronesia",
  CHS: "Moldova",
  ULN: "Mongolia",
  POD: "Montenegro",
  CSB: "Morocco",
  MAP: "Mozambique",
  WHK: "Namibia",
  KDU: "Nepal",
  AMS: "Netherlands",
  ACK: "New Zealand",
  MNG: "Nicaragua",
  NMY: "Niger",
  ABU: "Nigeria",
  LGS: "Nigeria",
  BLF: "United Kingdom",
  OSL: "Norway",
  MST: "Oman",
  ISL: "Pakistan",
  KRC: "Pakistan",
  KOR: "Palau",
  PNM: "Panama",
  PTM: "Papua New Guinea",
  ASN: "Paraguay",
  LMA: "Peru",
  MNL: "Philippines",
  KRK: "Poland",
  WRW: "Poland",
  LSB: "Portugal",
  DOH: "Qatar",
  BCH: "Romania",
  MOS: "Russia",
  KGL: "Rwanda",
  APA: "Samoa",
  DHR: "Saudi Arabia",
  JDD: "Saudi Arabia",
  RID: "Saudi Arabia",
  DKR: "Senegal",
  BLG: "Serbia",
  FTN: "Sierra Leone",
  SGP: "Singapore",
  BTS: "Slovakia",
  LJU: "Slovenia",
  CPT: "South Africa",
  DRB: "South Africa",
  JHN: "South Africa",
  SEO: "Korea",
  JBA: "South Sudan",
  MDD: "Spain and Andorra",
  CLM: "Sri Lanka",
  PRM: "Suriname",
  STK: "Sweden",
  BEN: "Switzerland",
  TAI: "Taiwan",
  DHB: "Tajikistan",
  DRS: "Tanzania",
  BNK: "Thailand",
  CHN: "Thailand",
  DIL: "Timor Leste",
  LOM: "Togo",
  PTS: "Trinidad and Tobago",
  TNS: "Tunisia",
  ANK: "Turkey",
  IST: "Turkey",
  AKD: "Turkmenistan",
  KMP: "Uganda",
  KEV: "Ukraine",
  ABD: "United Arab Emirates",
  DUB: "United Arab Emirates",
  MTV: "Uruguay",
  THT: "Uzbekistan",
  HAN: "Vietnam",
  HCM: "Vietnam",
  LUS: "Zambia",
  HRE: "Zimbabwe",
};

export const EMBASSY_COUNTRY_TO_EMBASSY_TYPE = {
  Afghanistan: "C",
  Albania: "B",
  Algeria: "A",
  Angola: "B",
  Argentina: "B",
  Armenia: "B",
  Australia: "A",
  Austria: "A",
  Azerbaijan: "B",
  Bahamas: "B",
  Bahrain: "A",
  Bangladesh: "A",
  Barbados: "B",
  Belarus: "C",
  Belgium: "B",
  Belize: "B",
  Benin: "C",
  Bermuda: "C",
  Bolivia: "B",
  "Bosnia and Herzegovina": "B",
  Botswana: "C",
  Brazil: "B",
  Brunei: "C",
  Bulgaria: "A",
  "Burkina Faso": "A",
  Burma: "C",
  Burundi: "C",
  "Cabo Verde": "B",
  Cambodia: "A",
  Cameroon: "B",
  Canada: "B",
  Chad: "C",
  Chile: "B",
  China: "A",
  Colombia: "B",
  "The Democratic Republic of the Congo": "B",
  "Costa Rica": "A",
  "Cote d'Ivoire (Ivory Coast)": "A",
  Croatia: "B",
  Cuba: "A",
  Curacao: "C",
  Cyprus: "B",
  "Czech Republic": "A",
  Denmark: "A",
  Djibouti: "C",
  "Dominican Republic": "A",
  Ecuador: "B",
  Egypt: "A",
  "El Salvador": "A",
  "United Kingdom": "B",
  "Equatorial Guinea": null,
  Eritrea: null,
  Estonia: "A",
  Eswatini: "C",
  Ethiopia: "B",
  "Falkland Islands": "C",
  Fiji: "A",
  Finland: "A",
  France: "B",
  Gabon: "C",
  Gambia: "A",
  Georgia: "A",
  Germany: "A",
  Ghana: "A",
  Greece: "B",
  Guatemala: "A",
  Guinea: "C",
  Guyana: "B",
  Haiti: "A",
  Honduras: "A",
  "Hong Kong": "A",
  Hungary: "A",
  Iceland: null,
  India: "A",
  Indonesia: "A",
  Iraq: "C",
  Ireland: "B",
  "Israel, Jerusalem, The West Bank, and Gaza": "B",
  Italy: "B",
  Jamaica: "B",
  Japan: "A",
  Jordan: "A",
  Kazakhstan: "A",
  Kenya: "B",
  Kosovo: "B",
  Kuwait: "A",
  Kyrgyzstan: null,
  Laos: "A",
  Latvia: "A",
  Lebanon: "A",
  Liberia: "A",
  Lithuania: "A",
  Luxembourg: "C",
  "North Macedonia": "B",
  Madagascar: "C",
  Malawi: "C",
  Malaysia: "A",
  Mali: "C",
  Malta: "C",
  "Marshall Islands": null,
  Mauritania: "C",
  Mauritius: "C",
  Mexico: "B",
  Micronesia: null,
  Moldova: "A",
  Mongolia: "A",
  Montenegro: "C",
  Morocco: "A",
  Mozambique: "C",
  Namibia: "C",
  Nepal: "A",
  Netherlands: "B",
  "New Zealand": "A",
  Nicaragua: "A",
  Niger: null,
  Nigeria: "A",
  Norway: "A",
  Oman: "A",
  Pakistan: "A",
  Palau: null,
  Panama: "A",
  "Papua New Guinea": "C",
  Paraguay: "B",
  Peru: "B",
  Philippines: "A",
  Poland: "A",
  Portugal: "B",
  Qatar: "A",
  "Republic of the Congo": "C",
  Romania: "A",
  Russia: "A",
  Rwanda: "B",
  Samoa: "C",
  "Sant Maarten": "C",
  "Saudi Arabia": "A",
  Senegal: "B",
  Serbia: "B",
  "Sierra Leone": "A",
  Singapore: "A",
  Slovakia: "A",
  Slovenia: "C",
  "South Africa": "B",
  Korea: "A",
  "South Sudan": "C",
  "Spain and Andorra": "B",
  "Sri Lanka": "A",
  Suriname: "C",
  Sweden: "A",
  Switzerland: "A",
  Taiwan: "A",
  Tajikistan: "C",
  Tanzania: "B",
  Thailand: "A",
  "Timor Leste": null,
  Togo: "C",
  "Trinidad and Tobago": "B",
  Tunisia: "A",
  Turkey: "B",
  Turkmenistan: null,
  Uganda: "B",
  Ukraine: "A",
  "United Arab Emirates": "B",
  Uruguay: "B",
  Uzbekistan: "C",
  Venezuela: "A",
  Vietnam: "A",
  Zambia: "B",
  Zimbabwe: "C",
};

export const TRANSACTION_ACTION_TYPES = {
  CAPTURE: "Capture Authorization",
  CANCEL: "Cancel Authorization",
  REFUND: "Refund Transaction",
}

export const ALLOWED_REFERRAL_SITES = {
  IVS_US: "apply.ivs.us",
  USVISACONNECTION: "apply.usvisaconnection.com",
  VISATRAVEL: "apply.visatravel.com",
  EVISA: "evisa.us",
  GOVASSIST: "govassist.com",
  TRAVELASSIST: "travelassist.us.com",  
  VISA_GOVASSIST: "visa.govassist.com",
  VISAEXPESS: "visaexpress.us.com",
  VISATRAVEL: "visatravel.com",
  DEFAULT: "visa.govassist.com"
}