import React, { useContext, useEffect, useState } from "react";
import "./SearchAndDisplay.styles.scss";
import { Search, List } from "semantic-ui-react";
import { UsersContext } from "../../../../../../../../../common/UsersContext";
import classNames from "classnames";

/**
 *
 * @prop {function} getRequest, request that fetches data
 * @prop {object} requestOptions, options for request
 * @prop {object} selectedItems, needed for coloring selected item in dropdown
 * @prop {function} onSelectItem, is called when you select an item from dropdown
 * @prop {array} itemsToDisplay, items that will be displayed in list below search
 * @prop {string} itemName, group name of the items
 *
 */
const SearchAndDisplay = ({
  requestOptions = {},
  selectedItems = {},
  onSelectItem,
  itemsToDisplay = [],
  itemName = "",
  itemsFromParent,
}) => {
  const [results, setResults] = useState([]);
  const [resultsOpen, setResultsOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [items, setItems] = useState(itemsFromParent);

  const { getUsersFiltered } = useContext(UsersContext);

  const handleResultSelect = (e, { result }) => {
    const { resultid: id, title } = result;
    onSelectItem(id, itemName.toLowerCase(), title);
  };

  const handleSearchChange = (e, { value }) => setSearchString(value);

  // prop is lowercase -resultid- because semantic only accepts props {title, description, image, price}
  const renderResult = ({ resultid, title }) => {
    return (
      <div className={selectedItems[resultid] ? "result-selected" : null}>
        {title}
      </div>
    );
  };

  useEffect(() => {
    setItems(
      getUsersFiltered({
        name: searchString,
        ...requestOptions,
      }),
    );
  }, [searchString, requestOptions, getUsersFiltered]);
  useEffect(() => {
    if (items) {
      // resultid is lowercase on purpose
      const transformedResults = items.map(({ id, fullName }) => ({
        resultid: id,
        title: fullName,
        key: id,
      }));
      setResults(transformedResults);
    }
  }, [items]);

  return (
    <div className={classNames("search-and-display")}>
      <Search
        className="search-and-display__search"
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        results={results}
        value={searchString}
        fluid
        onFocus={() => setResultsOpen(true)}
        onBlur={() => setResultsOpen(false)}
        open={resultsOpen}
        resultRenderer={renderResult}
      />
      <List
        className={classNames("search-and-display__list", {
          "search-and-display__list--all-selected": itemsToDisplay.length === 0,
        })}
      >
        {itemsToDisplay.length > 0 ? (
          itemsToDisplay.map(({ id, name }) => (
            <List.Item key={id} className="search-and-display__list__item">
              <List.Icon
                name="remove"
                onClick={() => onSelectItem(id, itemName.toLowerCase())}
                className="search-and-display__list__item__icon"
              />
              <List.Content>
                <List.Header>{name}</List.Header>
              </List.Content>
            </List.Item>
          ))
        ) : (
          <List.Item>
            <List.Content>
              <List.Header>All {itemName} Selected</List.Header>
            </List.Content>
          </List.Item>
        )}
      </List>
    </div>
  );
};

export default SearchAndDisplay;
