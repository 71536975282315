import React, { useCallback, useEffect, useState } from "react";
import Spinner from "../../../../../../../components/Spinner";
import {
  Segment,
  Header,
  Table,
  Button,
  Input,
  Select,
} from "semantic-ui-react";
import {
  notEmptyValidator,
  useFilters,
  usePagination,
} from "../../../../../../../hooks";
import {
  getApplicantsForDeletionMarked,
  getApplicantsForDeletionUnfinished,
  getApplicantsForDeletionUnpaid,
} from "../../../../../../../apis/configuration/applicantsDeletionApi";
import "./ApplicantsDeletionConfiguration.styles.scss";
import { DateInput } from "semantic-ui-calendar-react";
import {
  getEndOfDayNoOffset,
  getStartOfDayNoOffset,
} from "../../../../../../../common/date-format";
import { capitalizeEachWord } from "../../../../../../../utils";

const columns = [
  { width: 2, prop: "productType", label: "Product type" },
  { width: 2, prop: "firstName", label: "First Name" },
  { width: 2, prop: "lastName", label: "Last Name" },
  { width: 3, prop: "userEmail", label: "Email Address" },
];

const apiRequest = {
  1: getApplicantsForDeletionMarked,
  2: getApplicantsForDeletionUnfinished,
  3: getApplicantsForDeletionUnpaid,
};

const ApplicantsDeletionConfiguration = (props) => {
  const [fetchApi, setFetchApi] = useState(1);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const { filters, normalizedFilters, setFormFilter, resetFilters } =
    useFilters(
      {
        searchString: "",
      },
      {
        localStorageKey: "deletion",
        filterValidators: {
          searchString: notEmptyValidator(),
        },
        filterDebounces: {
          searchString: 700,
        },
        temporaryFilters: ["searchString"],
      },
    );

  const {
    items: applicants,
    Paginator,
    loadItems: loadApplicants,
    isLoading,
  } = usePagination(apiRequest[fetchApi], {
    initialLoad: false,
    clearOnLoad: false,
  });

  useEffect(() => {
    loadApplicants(1, { ...normalizedFilters });
  }, [loadApplicants, normalizedFilters]);

  const handleResetFilters = useCallback(() => {
    setDateFrom("");
    setDateTo("");
    resetFilters();
  }, [resetFilters]);

  const renderFilters = useCallback(() => {
    const { searchString, __t } = filters;

    return (
      <div
        style={{ float: "right", display: "flex", alignItems: "center" }}
        className="users-filters"
      >
        <DateInput
          name="dateFrom"
          dateFormat={"YYYY-MM-DD"}
          clearable={true}
          placeholder="Date from"
          value={dateFrom}
          popupPosition="top right"
          animation="none"
          duration={0}
          iconPosition="left"
          onChange={(_, field) => {
            const { value } = field;
            setDateFrom(value);
            setFormFilter(null, {
              name: "dateFrom",
              value: getStartOfDayNoOffset(value),
            });
          }}
        />
        <DateInput
          name="dateTo"
          dateFormat={"YYYY-MM-DD"}
          clearable={true}
          placeholder="Date to"
          value={dateTo}
          popupPosition="top right"
          animation="none"
          duration={0}
          iconPosition="left"
          onChange={(_, field) => {
            const { value } = field;
            setDateTo(value);
            setFormFilter(null, {
              name: "dateTo",
              value: getEndOfDayNoOffset(value),
            });
          }}
        />
        <Select
          placeholder="Select visa type"
          options={[
            {
              text: "VISA",
              value: fetchApi === 2 ? "DsTmpApplicant" : "DsApplicant",
            },
            {
              text: "ESTA",
              value: fetchApi === 2 ? "EstaTmpApplicant" : "EstaApplicant",
            },
          ]}
          name="__t"
          multiple
          value={__t || []}
          onChange={setFormFilter}
        />

        <Input
          placeholder="Search (by ID, name, email)"
          id="search-input"
          name="searchString"
          value={searchString || ""}
          onChange={setFormFilter}
          style={{ "min-width": "250px" }}
        />

        <Button onClick={handleResetFilters}>
          {isLoading ? "Loading.." : "Refresh"}
        </Button>
      </div>
    );
  }, [
    dateFrom,
    dateTo,
    fetchApi,
    filters,
    handleResetFilters,
    isLoading,
    setFormFilter,
  ]);

  return (
    <Segment.Group>
      <div>
        <Button
          color={fetchApi === 1 && "green"}
          onClick={() => {
            setFetchApi(1);
            handleResetFilters();
          }}
        >
          Marked applicants
        </Button>
        <Button
          color={fetchApi === 2 && "green"}
          onClick={() => {
            setFetchApi(2);
            handleResetFilters();
          }}
        >
          Unfinished applicants
        </Button>
        <Button
          color={fetchApi === 3 && "green"}
          onClick={() => {
            setFetchApi(3);
            handleResetFilters();
          }}
        >
          Unpaid applicants
        </Button>
      </div>
      {isLoading ? (
        <Spinner />
      ) : !applicants ? (
        "Couldnt get applicants marked for deletion"
      ) : (
        <>
          <Segment className="applicants-deletion__table-header" inverted>
            <Header className="tableTitle" size="large" floated="left">
              <h3>Applicants for deletion</h3>
            </Header>
            {renderFilters()}
          </Segment>
          <Table celled striped fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}>ID</Table.HeaderCell>
                {columns.map((column) => (
                  <Table.HeaderCell key={column.prop} width={column.width}>
                    {column.label}
                  </Table.HeaderCell>
                ))}
                {fetchApi === 1 && (
                  <Table.HeaderCell width={2}>
                    Failsafe immunity
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell width={3}>
                  Time to be deleted
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {applicants.map((applicant) => (
                <Table.Row key={applicant.applicant._id}>
                  <Table.Cell>
                    {fetchApi === 2 ? (
                      applicant.applicant._id
                    ) : applicant.applicant.productType === "VISA" ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/ds160/applicant/${applicant.applicant._id}`}
                      >
                        {applicant.applicant._id}
                      </a>
                    ) : (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/esta/applicant/${applicant.applicant._id}`}
                      >
                        {applicant.applicant._id}
                      </a>
                    )}
                  </Table.Cell>
                  {columns.map((column) => (
                    <Table.Cell key={column.prop}>
                      {["firstName", "lastName"].includes(column.prop)
                        ? capitalizeEachWord(applicant.applicant[column.prop])
                        : applicant.applicant[column.prop]}
                    </Table.Cell>
                  ))}
                  {fetchApi === 1 && (
                    <Table.Cell>
                      {applicant.failSafeImmunity ? "Yes" : "No"}
                    </Table.Cell>
                  )}
                  <Table.Cell>
                    {new Date(applicant.timeToBeDeleted).toLocaleString()}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell
                  colSpan={
                    fetchApi === 1 ? columns.length + 3 : columns.length + 2
                  }
                >
                  <Paginator />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      )}
    </Segment.Group>
  );
};

export default ApplicantsDeletionConfiguration;
