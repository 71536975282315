import { FETCH_CURRENT_USER } from "../actions/actionTypes";
import { CredentialsService } from "../../services/credentialsService";

const defaultState = {
  loggedIn: CredentialsService.getUser() ? undefined : false,
};
const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_CURRENT_USER:
      if (Boolean(action.payload) !== state.loggedIn) {
        return { ...state, loggedIn: Boolean(action.payload) };
      }
      return state;
    default:
      return state;
  }
};

export default authReducer;
