import React from "react";
import { TimespanLegend } from "../Report";
import "./ShiftReport.styles.scss";
import "../Report/components/TimespanFrame/components/Timespan/Timespan.styles.scss";
import {
  getAllShiftDependOfUTC,
  makeSingleShiftSegments,
  makeShiftHoursByNames,
  makeHourInAMPM,
  makeLegendForShifts,
} from "./utils";

const allShifts = [
  {
    S1: {
      firstSegment: "2am",
      lastSegment: "11am",
    },
    S3: {
      firstSegment: "10am",
      lastSegment: "7pm",
    },
    S5: {
      firstSegment: "6pm",
      lastSegment: "3am",
    },
  },
  {
    S2: {
      firstSegment: "6am",
      lastSegment: "3pm",
    },
    S4: {
      firstSegment: "2pm",
      lastSegment: "11pm",
    },
    S6: {
      firstSegment: "10pm",
      lastSegment: "7am",
    },
  },
];

const ShiftReport = ({ firstDate, lastDate }) => {
  return (
    <div>
      {getAllShiftDependOfUTC(allShifts).map((s) => {
        return (
          <React.Fragment key={Object.keys(s).join()}>
            <div className="shift-display">
              {makeSingleShiftSegments(firstDate, lastDate, s).map((part) => {
                return (
                  <div
                    key={part.id}
                    className="sk-active-timespan"
                    style={{
                      flexGrow: `${part.hours.length}`,
                      backgroundImage: `repeating-linear-gradient(0deg, ${
                        part.colors[0] +
                        " 40%, " +
                        part.colors.join() +
                        ", " +
                        part.colors[part.colors.length - 1]
                      } 60%)`,
                    }}
                  >
                    <div
                      className="sk-timespan-preview"
                      style={{
                        backgroundImage: `linear-gradient(90deg, ${
                          part.colors[0] +
                          ", " +
                          part.colors[part.colors.length - 1]
                        })`,
                      }}
                    >
                      <p>
                        {makeShiftHoursByNames(
                          part.shifts.split(" "),
                          allShifts,
                        )}
                      </p>
                      <p>
                        Hours (intersection hours):{" "}
                        {makeHourInAMPM(part.hours[0], "first")} -{" "}
                        {makeHourInAMPM(
                          part.hours[part.hours.length - 1],
                          "last",
                        )}{" "}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <TimespanLegend legendProps={makeLegendForShifts(s)} />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ShiftReport;
