import React from 'react'
import { Checkbox, Table } from "semantic-ui-react";
import { ALLOWED_REFERRAL_SITES } from '../../../../../EVisa/pages/ApplicantsEdit/components/constants';

const NZReviewAndCertification = ({ referralSite =  ALLOWED_REFERRAL_SITES.DEFAULT }) => {
  return (
    <React.Fragment>
        <Table.Row>
            <Table.Cell colSpan="2">
                <h4>Declaration of Applicant</h4>
                <div>
                    <p>I declare that the information in this application is truthful, complete and accurate to best of my knowledge.</p>
                    <p>I understand that I am applying for a New Zealand Electronic Travel Authority (ETA), as prescribed under the Immigration Act of 2009. and attempting to obtain a New Zealand ETA by the willful & deliberate misrepresentation of a material fact, or fraud, is an offense under the <a href="https://www.mbie.govt.nz/">Government of New Zealand</a> and the <a href="https://www.govt.nz/browse/immigration-and-visas/">Ministry of Business, Innovation & Employment Immigration and Tourism section</a> and may result in the permanent refusal of an ETA, or adjudicated inadmissibility for entry into New Zealand as well as removal from New Zealand and its territories after entry has been granted based on such willful misrepresentations.</p>
                </div>
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>AGREE</Table.Cell>
            <Table.Cell>
                <Checkbox checked={true} />
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell colSpan="2">
                <h5>Third Party Authorization</h5>
                <p>I understand that I am applying via GovAssist, LLC; a third-party that is not affiliated with the New Zealand government, that the third-party cannot guarantee application approval, that the third-party service is offered to help expedite the process and that I have the option to apply directly with the pertinent government websites. I also understand that my purchase includes a processing and service fee to GovAssist, which includes the non-refundable government fee.</p>
                <p>I confirm and authorize GovAssist, LLC to assist me, by using their processing service in completing and reviewing my application. I also confirm that I am submitting my application and signing it myself.</p>
                <p>
                    By obtaining services from the GovAssist website I have
                    agreed to the following:{" "}
                    <a href={`https://${referralSite}/terms-of-use`}>
                        Terms & Conditions
                    </a>{" "}
                    |{" "}
                    <a href={`https://${referralSite}/refund-policy`}>
                        Refund Policy{" "}
                    </a>{" "}
                    |{" "}
                    <a href={`https://${referralSite}/privacy-policy`}>
                        Privacy Policy{" "}
                    </a>{" "}
                    |{" "}
                    <a href={`https://${referralSite}/legal-disclaimer`}>
                        Disclaimer{" "}
                    </a>
                </p>
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>AGREE</Table.Cell>
            <Table.Cell>
                <Checkbox checked={true} />
            </Table.Cell>
        </Table.Row>
    </React.Fragment>
  )
}

export default NZReviewAndCertification