import axios from "./httpClient";

export function getInterviewsApi(params) {
  return axios.get(`/interview-dates`, { params: {...params, sortCriteria: 'country', sortOrder: 'asc'}});
}

export function getEmbassiesApi(params) {
  return axios.get(`/embassy-accounts`, { params: {...params, sortCriteria: 'country', sortOrder: 'asc'}});
}

export function createEmbassyApi(embassyBody) {
  return axios.post(`/embassy-accounts`, embassyBody);
}
  
export function updateEmbassyApi(embassyId, embassyBody) {
  return axios.patch(`/embassy-accounts/${embassyId}`, embassyBody);
}

export function deleteEmbassyApi(embassyId) {
  return axios.delete(`/embassy-accounts/${embassyId}`);
}

export function getCountryList() {
  return axios.get("/embassy-accounts/countries");
}

export function getConsulatesList(country) {
  return axios.get(`/embassy-accounts/consulates/${country}`);
}
