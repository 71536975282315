import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";

export const ETA_SELECT_VALUES = {
  MARITAL: "maritalStatuses",
  BIRTH_COUNTRY: "birthCountries",
  TERRITORY: "territories",
  COUNTRY_CODE: "countryCodes",
  NATIONALITY: "nationalities",
  GENDER: "genders",
  PASSPORT_TYPE: "passportTypes",
  JOB_OCCUPATION: "jobOccupations",
  JOB_TITLE: "jobTitles",
  TIME_ZONE: "timeZones",
  STATES: "states",
  TRAVEL_TYPES: "travelTypes",
  SECURITY_QUESTIONS_CONDITIONS: "SECURITY_QUESTIONS_CONDITIONS",
};

const specificDependency = (applicant) =>
  !applicant.hasUscis &&
  [25,8,6,44,116,146,148,102,109,203,179,184,189,199,121].includes(applicant.passportNationalityCode) &&
  [23,5,6,41,121,137,141,150,151,169,158,180,183,196,116].includes(applicant.passportNationality);

export const etaSchema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      applicationType: {
        label: "Application Type",
      },
      mainApplicationId: {
        label: "Main Application ID",
        dependsOn: "applicationType",
        dependantOnValues: ["Secondary"],
      },
      siteId: {
        label: "Referral Site",
      },
      orderId: {
        label: "Order ID",
      },
      applicantNumber:{
        label: "Applicant number",
        type: TYPES.TEXT,
      },
      applicantNumberTypeV:{
        label: "Applicant number type V",
        type: TYPES.TEXT,
      },
      orderIdHistory:{
        label:"Order ID History"
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      botRuns: {
        label: "Number of bot runs",
      },
    },
  },
  personalInfoSegment: {
    label: "Personal information",
    type: TYPES.SEGMENT,
    data: {
      userEmail: {
        label: "User contact email",
        type: TYPES.EMAIL,
      },
      submissionEmail: {
        label: "Email used to submit user to eta",
        type: TYPES.EMAIL,
      },
      phoneNumber: {
        label: "Phone number",
        type: TYPES.TEXT,
      },
      phoneCode: {
        label: "Phone number code",
      },
      firstName: {
        label: "First name",
        type: TYPES.TEXT,
      },
      lastName: {
        label: "Last name",
        type: TYPES.TEXT,
      },
      gender: {
        label: "Gender",
        type: TYPES.SELECT,
        values: ETA_SELECT_VALUES.GENDER,
      },
      birthDate: {
        label: "Birth date",
        type: TYPES.DATE,
      },
      birthCountry: {
        label: "Birth country",
        type: TYPES.SELECT,
        values: ETA_SELECT_VALUES.BIRTH_COUNTRY,
        geoLocationProps: ["birthCity"],
      },
      birthCity: {
        label: "Birth city",
        type: TYPES.TEXT,
      },
      maritalStatus: {
        label: "Marital status",
        type: TYPES.SELECT,
        values: ETA_SELECT_VALUES.MARITAL,
        yearDependent: true,
      },
    },
  },
  passportInfoSegment: {
    label: "Passport information",
    type: TYPES.SEGMENT,
    data: {
      passportType: {
        label: "Passport type",
        type: TYPES.SELECT,
        values: ETA_SELECT_VALUES.PASSPORT_TYPE,
      },
      passportNumber: {
        label: "Passport number",
        type: TYPES.TEXT,
      },
      passportNationalityCode: {
        label: "Passport nationality code",
        type: TYPES.SELECT,
        values: ETA_SELECT_VALUES.COUNTRY_CODE,
      },
      usingTaiwanPassport: {
        label: "Using Taiwan passport",
        type: TYPES.CHECKBOX,
        dependsOn: "passportNationalityCode",
        dependantOnValues: [193],
      },
      taiwanId: {
        label: "Taiwan identification number",
        type: TYPES.TEXT,
        dependsOn: "usingTaiwanPassport",
        dependantOnValues: [true],
      },
      usingIsraelPassport: {
        label: "Using Israel passport",
        type: TYPES.CHECKBOX,
        dependsOn: "passportNationalityCode",
        dependantOnValues: [92],
      },
      usingRomanianPassport: {
        label: "Using Romanian E-passport",
        type: TYPES.CHECKBOX,
        dependsOn: "passportNationalityCode",
        dependantOnValues: [158],
      },
      passportNationality: {
        label: "Passport nationality",
        type: TYPES.SELECT,
        values: ETA_SELECT_VALUES.NATIONALITY,
        onChange: (newValue, changeState, applicant, schemaData) => {
          const nationalities = applicant.nationalities
            ? applicant.nationalities.filter(
                (nationality) =>
                  nationality !==
                  schemaData[ETA_SELECT_VALUES.NATIONALITY][newValue],
              )
            : null;
          changeState("nationalities", nationalities, null);
        },
      },
      hasMultipleNationalities: {
        label: "User has multiple nationalities",
        type: TYPES.CHECKBOX,
      },
      nationalities: {
        label: "Select additional nationalities",
        type: TYPES.MULTI_SELECT,
        selectType: "value",
        values: ETA_SELECT_VALUES.NATIONALITY,
        dependsOn: "hasMultipleNationalities",
      },
      passportIssueDate: {
        label: "Passport issue date",
        type: TYPES.DATE,
      },
      passportExpiryDate: {
        label: "Passport expiry date",
        type: TYPES.DATE,
      },
      hasUscis: {
        label:
          "Are you a lawful permanent resident of the United States with a valid U.S. Citizenship and Immigration Services (USCIS) number? ",
        type: TYPES.CHECKBOX,
      },
      uscisNumber: {
        label: "USCIS number",
        type: TYPES.TEXT,
        dependsOn: "hasUscis",
      },
      uscisExpiryDate: {
        label: "USCIS expiry date",
        type: TYPES.DATE,
        dependsOn: "hasUscis",
      },
      isCanadianLPR: {
        label: "Are you a permanent resident of Canada with a permanent resident travel document or permanent resident card?",
        type: TYPES.CHECKBOX,
      }
    },
  },
  additionalInfoSegment: {
    label: "Additional information for specific passport nationality",
    type: TYPES.SEGMENT,
    customDependency: specificDependency,
    data: {
      travelingByAir: {
        label: "Are you travelling to Canada by air?",
        type: TYPES.CHECKBOX,
      },
      hadTmpResidentVisa: {
        label:
          "In the past 10 years, did you hold a valid Canadian temporary resident visa?",
        type: TYPES.CHECKBOX,
      },
      hasTmpResidentVisa: {
        label: "Do you currently hold a valid U.S. nonimmigrant visa?",
        type: TYPES.CHECKBOX,
      },
      hadBorderCrossingCard: {
        label: "Do you currently hold a valid U.S. border crossing card (BCC)?",
        type: TYPES.CHECKBOX,
      },
      nonimmigrantVisaNumber: {
        label: "U.S. nonimmigrant visa number",
        type: TYPES.TEXT,
        customDependency: (applicant) => {
            return applicant.hasTmpResidentVisa || applicant.hadBorderCrossingCard;
        }
    },
    nonimmigrantVisaExpiryDate: {
        label: "Date of expiry of U.S. nonimmigrant visa",
        type: TYPES.DATE,
        customDependency: (applicant) => {
            return applicant.hasTmpResidentVisa || applicant.hadBorderCrossingCard;
        }
    }    
  },
},
  residentionalAddressInfoSegment: {
    label: "Residential address information",
    type: TYPES.SEGMENT,
    data: {
      addressCountry: {
        label: "Address country",
        type: TYPES.SELECT,
        values: ETA_SELECT_VALUES.TERRITORY,
        geoLocationProps: ["addressCity", "addressName", "addressStreetNumber"],
      },
      addressDistrictOrRegion: {
        label: "District or region",
        type: TYPES.TEXT,
      },
      addressCity: {
        label: "Address city",
        type: TYPES.TEXT,
      },
      addressName: {
        label: "Address name",
        note: "This will be used to verify the client's ID on Calls/Chats",
        type: TYPES.TEXT,
      },
      addressName2: {
        label: "Secondary address name",
        type: TYPES.TEXT,
        required: false,
      },
      addressState: {
        label: "State/Province",
        type: TYPES.DEPENDENT_SELECT,
        values: ETA_SELECT_VALUES.STATES,
        dependsOn: "addressCountry",
        dependantOnValues: [37, 223],
      },
      addressZipCode: {
        label: "Zip code",
        type: TYPES.TEXT,
        dependsOn: "addressCountry",
        dependantOnValues: [223],
      },
      addressPostalCode: {
        label: "Zip code",
        type: TYPES.TEXT,
        dependsOn: "addressCountry",
        dependantOnValues: [37],
      },
      addressStreetNumber: {
        label: "Address number/House name",
        note: "This will be used to verify the client's ID on Calls/Chats",
        type: TYPES.TEXT,
      },
      addressApartmentNumber: {
        label: "Apartment number",
        type: TYPES.TEXT,
        required: false,
      },
      prevEta: {
        label: "Have user ever been to canada before",
        type: TYPES.CHECKBOX,
      },
      prevEtaNumber: {
        label: "Previous ETA number",
        type: TYPES.TEXT,
        dependsOn: "prevEta",
      },
    },
  },
  employmentInfoSegment: {
    label: "Employment information",
    type: TYPES.SEGMENT,
    data: {
      jobOccupation: {
        label: "Job occupation",
        type: TYPES.SELECT,
        values: ETA_SELECT_VALUES.JOB_OCCUPATION,
        yearDependent: true,
      },
      jobTitle: {
        label: "Job title",
        type: TYPES.DEPENDENT_SELECT,
        values: ETA_SELECT_VALUES.JOB_TITLE,
        dependsOn: "jobOccupation",
        dependantOnValues: [0, 1, 2, 3, 5, 6, 7, 8, 9, 11, 13],
      },
      jobEmployerName: {
        label: "Employer or organization name",
        type: TYPES.TEXT,
        yearDependent: true,
        dependsOn: "jobOccupation",
        dependantOnValues: [0, 1, 2, 3, 5, 6, 7, 8, 9, 11, 12, 13],
      },
      jobCountry: {
        label: "Job country",
        type: TYPES.SELECT,
        values: ETA_SELECT_VALUES.TERRITORY,
        yearDependent: true,
        dependsOn: "jobOccupation",
        dependantOnValues: [0, 1, 2, 3, 5, 6, 7, 8, 9, 11, 12, 13],
        geoLocationProps: ["jobCity"],
      },
      jobCity: {
        label: "Job city",
        type: TYPES.TEXT,
        yearDependent: true,
        dependsOn: "jobOccupation",
        dependantOnValues: [0, 1, 2, 3, 5, 6, 7, 8, 9, 11, 12, 13],
      },
      jobYear: {
        label: "Year when applicant started working current job",
        type: TYPES.NUMBER,
        min: 1900,
        max: new Date().getFullYear(),
        yearDependent: true,
        dependsOn: "jobOccupation",
        dependantOnValues: [0, 1, 2, 3, 5, 6, 7, 8, 9, 11, 12, 13],
      },
      jobState: {
        label: "State/Province",
        type: TYPES.DEPENDENT_SELECT,
        values: ETA_SELECT_VALUES.STATES,
        dependsOn: "jobCountry",
        dependantOnValues: [37, 223],
      },
    },
  },
  travelInfoSegment: {
    label: "Travel information",
    type: TYPES.SEGMENT,
    data: {
      arrivalType: {
        label: "Arrival type",
        type: TYPES.SELECT,
        required: false,
        values: ETA_SELECT_VALUES.TRAVEL_TYPES,
      },
      arrivalKnown: {
        label: "Is arrival time known",
        type: TYPES.CHECKBOX,
      },
      arrivalDate: {
        label: "Date of arrival",
        type: TYPES.DATE,
        dependsOn: "arrivalKnown",
      },
      arrivalTime: {
        label: "Time of arrival",
        type: TYPES.NESTED,
        dependsOn: "arrivalKnown",
        nested: {
          hourIndex: {
            label: "Hours",
            type: TYPES.SELECT,
            values: "default-hours",
            min: 0,
            max: 23,
            path: "arrivalTime",
          },
          minuteIndex: {
            label: "Minutes",
            type: TYPES.SELECT,
            values: "default-minutes",
            min: 0,
            max: 59,
            path: "arrivalTime",
          },
        },
      },
      arrivalDateTimeZone: {
        label: "Time zone",
        type: TYPES.SELECT,
        values: ETA_SELECT_VALUES.TIME_ZONE,
        dependsOn: "arrivalKnown",
      },
    },
  },
  backgroundQuestionsSegment: {
    label: "Background questions",
    type: TYPES.SEGMENT,
    data: {
      backgroundRefused: {
        label: "Have you ever been refused a visa or permit, denied entry to, or ordered to leave Canada or any other country/territory?",
        type: TYPES.CHECKBOX,
      },
      backgroundRefusedDescribe: {
        label: "For each refusal, please indicate the country that refused you a visa or permit, or denied you entry, as well as the reasons provided to you by the country.",
        type: TYPES.TEXT,
        dependsOn: "backgroundRefused",
      },
      backgroundCrimes: {
        label: "Have you ever committed, been arrested for, been charged with or convicted of any criminal offence in any country/territory?",
        type: TYPES.CHECKBOX,
      },
      backgroundCrimesDescribe: {
        label: "For each arrest, charge, or conviction, please indicate where (city,country), when (month/year), the nature of the offence, and the sentence.",
        type: TYPES.TEXT,
        dependsOn: "backgroundCrimes",
      },
      backgroundTuberculosis: {
        label: "In the past two years, were you diagnosed with tuberculosis or have you been in close contact with a person with tuberculosis?",
        type: TYPES.CHECKBOX,
      },
      backgroundTuberculosisMedical: {
        label: "Is your contact with tuberculosis the result of being a health care worker?",
        type: TYPES.CHECKBOX,
        dependsOn: "backgroundTuberculosis",
      },
      backgroundTuberculosisDiagnosed: {
        label: "Have you ever been diagnosed with tuberculosis?",
        type: TYPES.CHECKBOX,
        dependsOn: "backgroundTuberculosisMedical",
      },
      backgroundCondition: {
        label: "Do you have one of these conditions?",
        type: TYPES.SELECT,
        values: ETA_SELECT_VALUES.SECURITY_QUESTIONS_CONDITIONS,
      },
    },
  },
};
