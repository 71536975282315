import React, { useEffect, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import { Chart, PerformanceTrendQAReport } from "..";
import "./GeneralQAReport.styles.scss";

const GeneralQAReport = ({
  multipleValues,
  multipleValuesSeparated,
  positiveData,
  negativeData,
  positiveDataSeparated,
  negativeDataSeparated,
  type,
  chartConfig,
}) => {
  const [openPerformanceTrend, setOpenPerformanceTrend] = useState(false);
  const [chartData, setChartData] = useState({
    id: type,
    values: [],
    labels: [],
  });

  const hasChartData = useMemo(
    () => positiveData || negativeData || multipleValues,
    [positiveData, negativeData, multipleValues],
  );

  const multipleChartData = useMemo(() => {
    if (!multipleValues) return null;
    const chartData = {
      labels: [],
      values: [],
    };

    Object.keys(multipleValues).forEach((product) => {
      if (multipleValues[product].totalSubmittedEvaluations > 0) {
        let productLabel = product;
        if (!product) productLabel = "unknown";

        chartData.labels.push(productLabel.toUpperCase());
        chartData.values.push(
          multipleValues[product].totalSubmittedEvaluations,
        );
      }
    });

    return chartData;
  }, [multipleValues]);

  useEffect(() => {
    if (hasChartData) {
      let values = [];
      let labels = [];

      if (!multipleValues) {
        values = [positiveData, negativeData];
        labels = [
          ["Positive", "Reviews"],
          ["Negative", "Reviews"],
        ];
      } else {
        values = multipleChartData.values;
        labels = multipleChartData.labels;
      }

      setChartData((chartData) => ({ ...chartData, values, labels }));
    }
  }, [
    hasChartData,
    negativeData,
    positiveData,
    multipleValues,
    multipleChartData,
  ]);

  const qaSeparatedData = useMemo(() => {
    let res = {};

    if (multipleValues) {
      if (!isEmpty(multipleValuesSeparated)) {
        for (const [key, value] of Object.entries(multipleValuesSeparated)) {
          res = {
            ...res,
            [key]: value.totalSubmittedEvaluationsSeparated,
          };
        }
      }
    } else {
      res = {
        positive: positiveDataSeparated,
        negative: negativeDataSeparated,
      };
    }

    return res;
  }, [
    multipleValues,
    positiveDataSeparated,
    negativeDataSeparated,
    multipleValuesSeparated,
  ]);

  return (
    <div
      className="general-report"
      onClick={() => setOpenPerformanceTrend(true)}
      title="Show Performance Trend"
    >
      {hasChartData ? (
        <>
          <Chart
            chartId={chartData.id}
            chartConfig={chartConfig}
            chartData={chartData.values}
            chartLabels={chartData.labels}
          />
          <PerformanceTrendQAReport
            open={openPerformanceTrend}
            setOpen={setOpenPerformanceTrend}
            qaReportData={qaSeparatedData}
            performanceTrendName="general qa report"
          />
        </>
      ) : (
        <h4>No Data</h4>
      )}
    </div>
  );
};

export default GeneralQAReport;
