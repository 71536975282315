import React from "react";
import { Table, Checkbox } from "semantic-ui-react";
import { ALLOWED_REFERRAL_SITES } from "../../../../../EVisa/pages/ApplicantsEdit/components/constants";

const INDReviewAndCertification = ({ referralSite = ALLOWED_REFERRAL_SITES.DEFAULT }) => {
  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell colSpan="2">
          <h4>Consent and Declaration</h4>
          <h5>Applicant's Declaration</h5>
          <div>
            <p>
              All travelers seeking admission to India under the e-Visa (e-Visa)
              scheme are required to carry printout of the Electronic Travel
              Authorization (ETA) sent through email by Bureau of Immigration.
              If your e-Visa application is approved, it establishes that you
              are admissible to enter India under the e-Visa scheme of the
              Government of India. Upon arrival in India, records would be
              examined by the Immigration Officer. Biometric Details (Photograph
              & Fingerprints) of the applicant shall be mandatorily captured
              upon arrival into India. Non-compliance to do so would lead to
              denial of entry into India. A determination that you are not
              eligible for e-Visa does not preclude you from applying for a
              regular Visa in Indian Mission. All information provided by you,
              or on your behalf by a designated third party, must be true and
              correct. An Electronic Travel Authorization (ETA) may be revoked
              at any time and for any reasons whatsoever. You may be subject to
              legal action, if you make materially false, fictitious, or
              fraudulent statement or representation in an Electronic Travel
              Authorization (ETA) application submitted by you. The transaction
              cannot be cancelled or amended once the fee has been paid.
            </p>
            <p>
              I, the applicant, hereby certify that I have read and understood
              all the statements/questions in this application. The answers and
              information furnished in this application are true and correct to
              the best of my knowledge and belief. I understand that the Visa
              fee once paid is non-refundable irrespective of the outcome of my
              application and doesn't in any way guarantee issuance of e-Visa by
              the Government of India. I agree that I cannot cancel or amend
              this transaction.
            </p>
          </div>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell colSpan="2">
          <h5>Third Party Authorization</h5>
          <p>
            I understand that I am applying via GovAssist, LLC; a third-party
            that is not affiliated with the Indian government, that the
            third-party cannot guarantee application approval, that the
            third-party service is offered to help expedite the process, and
            that I have the option to apply directly with the pertinent
            government websites. I also understand that my purchase includes a
            processing and service fee to GovAssist, which includes the
            non-refundable government fee.
          </p>
          <p>
            I confirm and authorize GovAssist, LLC to assist me, by using their
            processing service in completing and reviewing my application. I
            understand and acknowledge that I have not been offered legal advice
            and that this company or its agents and affiliates have never
            claimed to be qualified to offer legal advice under any
            circumstance. I also confirm that I am submitting my application and
            signing it myself.
          </p>
          <p>
            By obtaining services from the GovAssist website I have agreed to
            the following:{" "}
            <a href={`https://${referralSite}/terms-conditions`}>
              Terms & Conditions
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/refund-policy`}>
              Refund Policy{" "}
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/privacy-policy`}>
              Privacy Policy{" "}
            </a>{" "}
            | <a href={`https://${referralSite}/disclaimer`}>Disclaimer </a>
          </p>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>
    </React.Fragment>
  );
};

export default INDReviewAndCertification;
