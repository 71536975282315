import axios from "../clients/uspsServer";

export function uspsGetApplicantApi(id) {
  return axios.get(`/applicant/${id}`);
}

export function uspsUpdateApplicantApi(id, applicant) {
  return axios.put(`/applicant/${id}`, applicant);
}

export function uspsDeleteApplicantApi(id) {
  return axios.delete(`/applicant/${id}`);
}

export function uspsChangeApplicantPaymentStatusApi(id, status) {
  return axios.post(`/applicant/${id}/payment_status`, {
    paymentStatus: status
  });
}

export function uspsChangeApplicantPhoneNumbers(id, phoneNumbers) {
  return axios.post(`/applicant/${id}/phone_numbers`, phoneNumbers);
}

export function uspsChangeApplicantStatusApi(id, status) {
  return axios.post(`/applicant/${id}/status`, { status });
}

export function uspsRunBotApi(id) {
  return axios.post(`/applicant/${id}/run`);
}

export function uspsApplicantsFetchApi(params) {
  return axios.get(`/applicant`, { params });
}
