import { API_NAMES } from "../EVisa/constants/apiNames";
import { TRAVELASSIST_ZENDESK_ENDPOINT } from "../../../../../../constants/endpoints";
import env from "../../../../../../env";
import store from "../../../../../../store";
import { areOnlyAgentRolesWithoutBilling } from "../../../../../../constants/rolesConstants";

export const PRODUCT_NAMES = {
  ...API_NAMES,
  USPS: "usps",
};

export const PRODUCT_NAMES_MAPPING = {
  [PRODUCT_NAMES.ETA]: "Canada ETa",
  [PRODUCT_NAMES.ESTA]: "ESTA",
  [PRODUCT_NAMES.DS160]: "U.S. Visa",
  [PRODUCT_NAMES.NZ]: "NZ ETa",
  [PRODUCT_NAMES.TRK]: "Trk ETa",
  [PRODUCT_NAMES.IND]: "Ind ETa",
  [PRODUCT_NAMES.AUS]: "Aus ETa", 
  [PRODUCT_NAMES.VN]: "Vn ETa", 
  [PRODUCT_NAMES.INV]: "Invitation Letter",
  [PRODUCT_NAMES.SCHENGEN]: "Schengen",
  [PRODUCT_NAMES.DIVERSITY]: "Diversity VISA",
  [PRODUCT_NAMES.GE]: "Global Entry",
  [PRODUCT_NAMES.SG]: "Singapore ETA",
  [PRODUCT_NAMES.DS11]: "DS11 - Passport Service",
  [PRODUCT_NAMES.DS82]: "DS82 - Passport Service",
  [PRODUCT_NAMES.DS64]: "DS64 - Passport Service",
  [PRODUCT_NAMES.SL]: "Sri Lanka ETA",
  [PRODUCT_NAMES.TSA]: "TSA Pre-Check",
  [PRODUCT_NAMES.SENTRI]: "SENTRI",
  [PRODUCT_NAMES.NEXUS]: "NEXUS",
};

export const PRODUCT_NAMES_WITH_BOTS = {
  [PRODUCT_NAMES.ETA]: "Canada ETa",
  [PRODUCT_NAMES.ESTA]: "ESTA",
  [PRODUCT_NAMES.DS160]: "U.S. Visa",
  [PRODUCT_NAMES.NZ]: "NZ ETa",
  [PRODUCT_NAMES.IND]: "Ind ETa",
  [PRODUCT_NAMES.AUS]: "Aus ETa", 
  [PRODUCT_NAMES.VN]: "Vn ETa", 
  [PRODUCT_NAMES.GE]: "Global Entry",
  [PRODUCT_NAMES.SL]: "Sri Lanka ETA",
}

export const PRODUCT_NAMES_WITH_PAYMENTS_BOTS = {
  [PRODUCT_NAMES.GE]: "Global Entry",
  [PRODUCT_NAMES.SENTRI]: "SENTRI",
  [PRODUCT_NAMES.NEXUS]: "NEXUS"
}

export const PRODUCT_NAMES_COLORS = {
  ETA: "#0000FF",
  ESTA: "#8A2BE2",
  DS160: "#A52A2A",
  NZ: "#DEB887",
  TRK: "#5F9EA0",
  IND: "#7FFF00",
  AUS: "#D2691E",
  USPS: "#00FFFF",
};

export const ZENDESK_ENDPOINT_PER_PRODUCT = {
  [PRODUCT_NAMES.ETA]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.ESTA]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.DS160]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.NZ]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.TRK]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.IND]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.AUS]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.VN]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.USPS]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.INV]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.SCHENGEN]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.DIVERSITY]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.GE]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.SG]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.DS11]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.DS82]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.DS64]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.SL]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.TSA]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.SENTRI]: TRAVELASSIST_ZENDESK_ENDPOINT,
  [PRODUCT_NAMES.NEXUS]: TRAVELASSIST_ZENDESK_ENDPOINT,

};

export const CART_PRODUCTS_PER_PRODUCT = {
  [PRODUCT_NAMES.ETA]: [],
  [PRODUCT_NAMES.ESTA]: [],
  [PRODUCT_NAMES.DS160]: [],
  [PRODUCT_NAMES.NZ]: [],
  [PRODUCT_NAMES.TRK]: [],
  [PRODUCT_NAMES.IND]: [],
  [PRODUCT_NAMES.AUS]: [],
  [PRODUCT_NAMES.VN]: [],
  [PRODUCT_NAMES.USPS]: [],
  [PRODUCT_NAMES.INV]: [],
  [PRODUCT_NAMES.SCHENGEN]: [],
  [PRODUCT_NAMES.DIVERSITY]: [],
  [PRODUCT_NAMES.SG]: [],
  [PRODUCT_NAMES.GE]: [],
  [PRODUCT_NAMES.DS11]: [],
  [PRODUCT_NAMES.DS82]: [],
  [PRODUCT_NAMES.DS64]: [],
  [PRODUCT_NAMES.SL]: [],
  [PRODUCT_NAMES.TSA]: [],
  [PRODUCT_NAMES.SENTRI]: [],
  [PRODUCT_NAMES.NEXUS]: [],
};

export const FILTERED_PRODUCTS = env.SHOWN_PRODUCTS;

/**
 *
 * @param {Object} object
 * @param {'key'|'value'} filterBy
 */
export function filterInvalidProductProps(object, filterBy = "key") {
  const validPropsObject = {};

  const { currentUser } = store.getState();

  const shouldFilterByUser = !!currentUser;

  const validUserProducs = currentUser?.products ? currentUser.products : null;

  const isProductAvailableForUser = (product) =>
    !shouldFilterByUser ||
    !validUserProducs ||
    !areOnlyAgentRolesWithoutBilling(currentUser.roles) ||
    validUserProducs.includes(product);

  Object.entries(object).forEach(([key, value]) => {
    const product = filterBy === "key" ? key : value;
    if (
      FILTERED_PRODUCTS.includes(product) &&
      isProductAvailableForUser(product)
    ) {
      validPropsObject[key] = value;
    }
  });
  return validPropsObject;
}

export const FILTERED_PRODUCT_NAMES = filterInvalidProductProps(
  PRODUCT_NAMES,
  "value",
);
