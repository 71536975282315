import React, { Component } from "react";
import Spinner from "../../../../../../../components/Spinner";
import {
  getTwilioPhoneNumberConfigsApi,
  updateTwilioPhoneNumberConfigApi,
  deleteTwilioPhoneNumberConfigApi,
  createTwilioPhoneNumberConfigApi,
} from "../../../../../../../apis/twilio";
import * as toastr from "toastr";
import { Segment, Header, Table, Button, Icon } from "semantic-ui-react";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import CallRoutingConfigurationForm from "./components/CallRoutingConfigurationForm";
import ModalForm from "../../../../../../../components/common/ModalForm";

class CallRoutingConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberConfigs: null,
      isLoading: false,
      currentNumberConfig: null,
    };
  }

  componentDidMount() {
    this.fetchTwilioNumberConfigs();
  }

  changeCurrentNumberConfigPropertyValue(property, value) {
    const { currentNumberConfig } = this.state;
    this.setState({
      currentNumberConfig: { ...currentNumberConfig, [property]: value },
    });
  }

  async fetchTwilioNumberConfigs() {
    this.setState({ isLoading: true });
    try {
      const {
        data: { numberConfigs },
      } = await getTwilioPhoneNumberConfigsApi();
      this.setState({ numberConfigs });
    } catch (err) {
      toastr.error("Failed fetching numbers");
    }
    this.setState({ isLoading: false });
  }

  async createTwilioNumberConfig(result) {
    const { numberConfigs } = this.state;
    try {
      const {
        data: { numberConfig },
      } = await createTwilioPhoneNumberConfigApi(result);
      this.setState({ numberConfigs: [numberConfig, ...numberConfigs] });
      toastr.success("Successfully created number");
    } catch (err) {
      toastr.error("Failed creating number");
    }
  }

  async updateTwilioNumberConfig(configId, result) {
    const { numberConfigs } = this.state;
    const resultChanges = {
      ...result,
      products: result.products ? result.products : [],
    };
    try {
      const {
        data: { numberConfig },
      } = await updateTwilioPhoneNumberConfigApi(configId, resultChanges);
      this.setState({
        numberConfigs: numberConfigs.map((config) =>
          config._id === configId ? numberConfig : config,
        ),
      });
      toastr.success("Successfully updated number");
    } catch (err) {
      toastr.error("Failed updating number");
    }
  }

  async deleteTwilioNumberConfig(configId) {
    const { numberConfigs } = this.state;
    try {
      await deleteTwilioPhoneNumberConfigApi(configId);
      this.setState({
        numberConfigs: numberConfigs.filter(
          (config) => config._id !== configId,
        ),
      });
      toastr.success("Successfully deleted number");
    } catch (err) {
      toastr.error("Failed deleting number");
    }
  }

  renderNumberConfigCreate() {
    return (
      <ModalForm
        headerText="Create configuration form"
        trigger={<Button>Create new config</Button>}
        onSubmit={(result) => this.createTwilioNumberConfig(result)}
        closeOnSubmit
      >
        <CallRoutingConfigurationForm />
      </ModalForm>
    );
  }

  renderNumberConfigEdit(numberConfig) {
    return (
      <ModalForm
        headerText="Edit configuration form"
        trigger={
          <Button color="blue" size="mini" icon>
            <Icon name="edit" />
          </Button>
        }
        onSubmit={(result) =>
          this.updateTwilioNumberConfig(numberConfig._id, result)
        }
        closeOnSubmit
      >
        <CallRoutingConfigurationForm numberConfig={numberConfig} />
      </ModalForm>
    );
  }

  renderNumberConfigDelete(numberConfig) {
    return (
      <ConfirmPopup
        callback={() => this.deleteTwilioNumberConfig(numberConfig._id)}
      >
        <Button color="red" size="mini" icon>
          <Icon name="trash" />
        </Button>
      </ConfirmPopup>
    );
  }

  renderActions(numberConfig) {
    return (
      <React.Fragment>
        {this.renderNumberConfigEdit(numberConfig)}
        {this.renderNumberConfigDelete(numberConfig)}
      </React.Fragment>
    );
  }

  render() {
    const { numberConfigs, isLoading } = this.state;
    if (isLoading) {
      return <Spinner />;
    }

    if (!numberConfigs) {
      return "Couldnt get numbers";
    }
    return (
      <Segment.Group>
        <Segment className="tableTopSegment" inverted>
          <Header className="tableTitle" size="large" floated="left">
            <h3>Twilio number configurations</h3>
            <div style={{ float: "right" }}>
              {this.renderNumberConfigCreate()}
            </div>
          </Header>
        </Segment>
        <Table celled striped fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>Number</Table.HeaderCell>
              <Table.HeaderCell width={2}>Products</Table.HeaderCell>
              <Table.HeaderCell width={2}>Zendesk Host</Table.HeaderCell>
              <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {numberConfigs.map((numberConfig) => (
              <Table.Row key={numberConfig._id}>
                <Table.Cell width={2}>{numberConfig.number}</Table.Cell>
                <Table.Cell width={2}>
                  {numberConfig.products.join(", ")}
                </Table.Cell>
                <Table.Cell width={2}>{numberConfig.zendeskHost}</Table.Cell>
                <Table.Cell width={2}>
                  {this.renderActions(numberConfig)}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment.Group>
    );
  }
}

export default CallRoutingConfiguration;
