import React, { useCallback, useEffect, useState } from "react";
import { Form, Message } from "semantic-ui-react";
import CustomSelect from "../CustomSelect";
import "./FormFields.styles.scss";

const capitalize = (stringValue) => {
  return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
}

const makeLabelName = (key) => {
  return capitalize(key.replace(/.+?[A-Z]/g, (value) => `${value.slice(0, -1)} ${value.slice(-1).toLowerCase()}`));
};

const FormFields = ({ fields, currentItem, setCurrentItem }) => {
  const [item, setItem] = useState(currentItem);

  useEffect(() => {
    if (setCurrentItem) {
      setCurrentItem(item);
    }
  }, [item, setCurrentItem]);

  const changeCurrentItemPropertyValue = useCallback(
    (property, value, multipleSelect) => {
      multipleSelect
        ? setItem((currItem) => ({
            ...currItem,
            [property]: [...(item[property] || []), value],
          }))
        : setItem((currItem) => ({ ...currItem, [property]: value }));
    },
    [item],
  );

  const returnFieldsByType = (field) => {
    const key = typeof field === "string" ? field : field.key;
    const type = typeof field === "string" ? "input" : field.type;
    const {
      defaultValue,
      noRender,
      label,
      text,
      phoneNumber,
      fieldSpecific,
      placeholder,
    } = field;

    const groupFields = field.type === "group" ? field.groupFields : undefined;

    switch (type) {
      case "input":
        return (
          !noRender && (
            <Form.Field key={key}>
              <Form.Input
                id={key}
                value={item[key] || defaultValue || ""}
                label={label || makeLabelName(key)}
                placeholder={placeholder || makeLabelName(key)}
                type={key === "password" ? "password" : "input"}
                name={key}
                {...fieldSpecific}
                onChange={
                  phoneNumber
                    ? (event, field) => {
                        if (/^[\d+ ]*$/.test(field.value)) {
                          changeCurrentItemPropertyValue(
                            key,
                            field.value.replace(
                              /[+ ]{2}/g,
                              (partialValue) => partialValue[0],
                            ),
                          );
                        }
                      }
                    : (event, field) =>
                        changeCurrentItemPropertyValue(key, field.value)
                }
              />
            </Form.Field>
          )
        );
      case "select":
        const { multiple, options, disabled } = fieldSpecific;

        return (
          !noRender && (
            <Form.Field key={key}>
              <label>{label || makeLabelName(key)}</label>
              <CustomSelect
                multiple={multiple}
                item={item}
                setItem={setItem}
                disabled={disabled}
                changeCurrentItemPropertyValue={changeCurrentItemPropertyValue}
                itemPropName={key}
                options={options}
                itemProp={item[key]}
              />
            </Form.Field>
          )
        );
      case "textarea":
        const { readonly } = fieldSpecific;
        return (
          !noRender && (
            <Form.Field key={key}>
              <Form.TextArea
                id={key}
                value={item[key] || defaultValue || ""}
                label={label || makeLabelName(key)}
                placeholder={makeLabelName(key)}
                name={key}
                readonly={readonly && 'readonly'}
                onChange={(fieldOptions, field) => {
                  changeCurrentItemPropertyValue(key, field.value);
                }}
              />
            </Form.Field>
          )
        );

      case "checkbox":
        return (
          !noRender && (
            <Form.Field key={key}>
              <Form.Checkbox
                id={key}
                label={label || makeLabelName(key)}
                name={key}
                value={item[key] ? item[key].toString() : "false"}
                checked={item[key]}
                {...fieldSpecific}
                onChange={() => {
                  changeCurrentItemPropertyValue(key, !item[key]);
                }}
              />
            </Form.Field>
          )
        );
      case "message":
        return (
          !noRender && (
            <Message key={key}>
              <Message.Header>{text}</Message.Header>
            </Message>
          )
        );
      case "group":
        return (
          !noRender && (
            <div key={key} {...fieldSpecific}>
              {renderFieldArray(groupFields)}
            </div>
          )
        );
      default:
        return <></>;
    }
  };

  const renderFieldArray = (fields) => {
    return (
      <>
        {fields.map((field) => {
          return returnFieldsByType(field);
        })}
      </>
    );
  };

  return currentItem && renderFieldArray(fields);
};

export default FormFields;
