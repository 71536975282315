import React from "react";
import { Table, Checkbox } from "semantic-ui-react";

const DS160ReviewAndCertification = ({ declarations }) => {
  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell colSpan="2">
          <h4>Consent and Declaration</h4>
          <h5>Applicant's Declaration</h5>
          <div
            dangerouslySetInnerHTML={{
              __html: declarations.applicantDeclaration,
            }}
          />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell colSpan="2">
          <h5>Third Party Authorization</h5>
          <div
            dangerouslySetInnerHTML={{
              __html: declarations.thirdPartyAuthorization,
            }}
          />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>
    </React.Fragment>
  );
};

export default DS160ReviewAndCertification;
