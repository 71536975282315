import React, { useCallback, useState } from "react";
import { Form } from "semantic-ui-react";
import CustomSelect from "../../../../../../../../../components/common/CustomSelect";
import TagsAndProps, { TAG_OPTIONS } from "../TagsAndProps/TagsAndProps";

import "./TemplateForm.styles.scss";

export const departmentOptions = [
  { value: "Billing", text: "Billing" },
  { value: "Level 1", text: "Level 1" },
  { value: "Level 2", text: "Level 2" },
  { value: "Legal", text: "Legal" },
];

const TemplateForm = ({ template, languages }) => {
  const [item, setItem] = useState({
    ...template,
    tagsAndProps: !template.tagsAndProps.length
      ? Object.keys(template.tagsAndProps).map((key) => {
          if (TAG_OPTIONS.some((el) => el.text === key))
            return { tag: key, prop: template.tagsAndProps[key] };
          else
            return {
              tag: "Other",
              other: key,
              prop: template.tagsAndProps[key],
            };
        })
      : template.tagsAndProps,
  });

  const changeCurrentItemPropertyValue = useCallback(
    (property, value, multipleSelect) => {
      multipleSelect
        ? setItem((currItem) => ({
            ...currItem,
            [property]: [...(item[property] || []), value],
          }))
        : setItem((currItem) => ({ ...currItem, [property]: value }));
    },
    [item],
  );

  return (
    <div>
      <Form.Field>
        <Form.Input
          value={item.name || ""}
          label="Template Name"
          placeholder="Template Name"
          type="input"
          name="name"
          onChange={(event, field) =>
            changeCurrentItemPropertyValue("name", field.value)
          }
        />
      </Form.Field>

      <Form.Field>
        <label>Language</label>
        <CustomSelect
          item={item}
          setItem={setItem}
          changeCurrentItemPropertyValue={changeCurrentItemPropertyValue}
          itemPropName="language"
          options={languages}
          itemProp={item.language}
        />
      </Form.Field>

      <Form.Field>
        <label>Departments</label>
        <CustomSelect
          item={item}
          setItem={setItem}
          changeCurrentItemPropertyValue={changeCurrentItemPropertyValue}
          itemPropName="departments"
          options={departmentOptions}
          itemProp={item.departments}
          multiple
        />
      </Form.Field>

      <Form.Field>
        <Form.Input
          value={item.templateId || ""}
          label="Sendgrid ID"
          placeholder="Sendgrid ID"
          type="input"
          name="templateId"
          onChange={(event, field) =>
            changeCurrentItemPropertyValue("templateId", field.value)
          }
        />
      </Form.Field>

      <Form.Field>
        <Form.Input
          value={item.emailSubject || ""}
          label="Email Subject"
          placeholder="Email Subject"
          type="input"
          name="emailSubject"
          onChange={(event, field) =>
            changeCurrentItemPropertyValue("emailSubject", field.value)
          }
        />
      </Form.Field>

      <TagsAndProps
        tagsAndProps={item.tagsAndProps}
        name="tagsAndProps"
        item={item}
        setItem={setItem}
      />
    </div>
  );
};

TemplateForm.defaultProps = {
  template: {
    name: "",
    templateId: "",
    emailSubject: "",
    departments: [],
    language: "",
    tagsAndProps: [{ tag: "", prop: "", other: "" }],
  },
};

export default TemplateForm;
