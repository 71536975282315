import React from "react";
import { Button } from "semantic-ui-react";
import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";
import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../../constants/endpoints";
import ZendeskIcon from "../../../../../../../../components/Icons/ZendeskIcon/ZendeskIcon";

export const DIVERSITY_SELECT_VALUES = {
  GENDERS: "GENDERS",
  MARITAL_STATUSES: "MARITAL_STATUSES",
  COUNTRIES: "COUNTRIES",
  EDUCATION_LEVELS: "EDUCATION_LEVELS",
};

const AddressSchema = (path) => ({
  country: {
    label: "Country",
    type: TYPES.SELECT,
    required: true,
    values: DIVERSITY_SELECT_VALUES.COUNTRIES,
    path,
  },
  streetAddress: {
    label: "Street address",
    type: TYPES.TEXT,
    path,
  },
  streetAddress2: {
    label: "Street address 2",
    type: TYPES.TEXT,
    path,
  },
  city: {
    label: "City",
    type: TYPES.TEXT,
    path,
  },
  zipCode: {
    label: "Zip code",
    type: TYPES.TEXT,
    path,
  },
});

export const diversitySchema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      applicationType: {
        label: "Application Type",
      },
      mainApplicationId: {
        label: "Main Application ID",
        dependsOn: "applicationType",
        dependantOnValues: ["Secondary"],
      },
      siteId: {
        label: "Referral Site",
      },
      cartSiteId: {
        label: "Purchased from website",
      },
      orderId: {
        label: "Order ID",
      },
      orderIdHistory:{
        label:"Order ID History"
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
        renderIcon: (propValue) => (
          <Button
            onClick={() => {
              window.open(
                TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(propValue),
                "_blank",
              );
            }}
            style={{ width: 36, height: 36, padding: 0, float: "right" }}
            disabled={!propValue}
          >
            <ZendeskIcon className="zendesk-icon" />
          </Button>
        ),
      },
      submissionEmail: {
        label: "Submission email",
        type: TYPES.TEXT,
      },
      submissionPassword: {
        label: "Submission password",
        type: TYPES.TEXT,
      },
      gclid: {
        label: "Google client ID",
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      salesAgent: {
        label: "Sales Agent",
      },
      salesCoupon: {
        label: "Sales Coupon",
      },
    },
  },
  applicantInfoSegment: {
    label: "Applicant information",
    type: TYPES.SEGMENT,
    data: {
      firstName: {
        label: "First name",
        type: TYPES.TEXT,
      },
      lastName: {
        label: "Family name",
        type: TYPES.TEXT,
      },
      middleName: {
        label: "Middle name",
        type: TYPES.TEXT,
      },
      phoneNumber: {
        label: "Phone number",
        type: TYPES.TEXT,
      },
      userEmail: {
        label: "User email address",
        type: TYPES.TEXT,
      },
      gender: {
        label: "Gender",
        type: TYPES.SELECT,
        values: DIVERSITY_SELECT_VALUES.GENDERS,
      },
      birthDate: {
        label: "Birth date",
        type: TYPES.DATE,
      },
      birthCountry: {
        label: "Birth country",
        type: TYPES.SELECT,
        values: DIVERSITY_SELECT_VALUES.COUNTRIES,
      },
      eligibilityBasedOnBirthCountry: {
        label: "Are you claiming eligibility based on the country where you were born?",
        type: TYPES.CHECKBOX,
      },
      eligibilityCountry: {
        label: "Eligibility country",
        type: TYPES.SELECT,
        values: DIVERSITY_SELECT_VALUES.COUNTRIES,
        dependsOn: "eligibilityBasedOnBirthCountry",
        dependantOnValues: [false],
      },
      uploadPhotoLater: {
        label: "Upload photo later",
        type: TYPES.CHECKBOX,
      },
      whoWillReceiveTheCorrespondence: {
        label: "Who will receive the correspodence?",
        type: TYPES.TEXT,
      },
      mailingAddress: {
        label: "Mailing address",
        type: TYPES.NESTED,
        newTable: true,
        nested: AddressSchema("mailingAddress"),
      },
      levelOfEducation: {
        label: "Level of education",
        type: TYPES.SELECT,
        values: DIVERSITY_SELECT_VALUES.EDUCATION_LEVELS,
      },
      maritalStatus: {
        label: "Marital status",
        type: TYPES.SELECT,
        values: DIVERSITY_SELECT_VALUES.MARITAL_STATUSES,
      },
    },
  },
};
