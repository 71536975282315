import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";

export const NZ_SELECT_VALUES = {
  COUNTRY_OF_BIRTH: "COUNTRY_OF_BIRTH",
  NATIONALITIES: "NATIONALITIES",
  GENDERS: "GENDERS",
  ISSUING_COUNTRIES: "ISSUING_COUNTRIES",
};

export const nzSchema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      siteId: {
        label: "Referral Site",
      },
      orderId: {
        label: "Order ID",
      },
      orderIdHistory:{
        label:"Order ID History"
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
      },
      invoiceEmail: {
        label: "Payment email",
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      botRuns: {
        label: "Number of bot runs",
      },
    },
  },
  personalInfoSegment: {
    label: "Personal information",
    type: TYPES.SEGMENT,
    data: {
      contactInformation: {
        label: "Contact information",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          phoneCountry: {
            label: "Country",
            type: TYPES.TEXT,
            path: 'contactInformation'
          },
          countryCode: {
            label: "Country code",
            type: TYPES.TEXT,
            path: 'contactInformation'
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: 'contactInformation'
          },
        },
      },
      userEmail: {
        label: "User contact email",
        type: TYPES.EMAIL,
      },
      submissionEmail: {
        label: "User submission email",
        type: TYPES.TEXT,
      },
      firstName: {
        label: "First name",
        type: TYPES.TEXT,
      },
      lastName: {
        label: "Family name",
        type: TYPES.TEXT,
      },
      hasOtherNames: {
        label: "User has other names",
        type: TYPES.CHECKBOX,
      },
      otherNames: {
        label: "Otrher names",
        type: TYPES.NESTED,
        dependsOn: "hasOtherNames",
        newTable: true,
        nested: {
          firstName: {
            label: "First name",
            type: TYPES.TEXT,
            path: "otherNames",
          },
          lastName: {
            label: "Family name",
            type: TYPES.TEXT,
            path: "otherNames",
          },
        },
      },
      gender: {
        label: "Gender",
        type: TYPES.SELECT,
        values: NZ_SELECT_VALUES.GENDERS,
      },
      nationalId: {
        label: "National ID",
        type: TYPES.TEXT,
        required: false,
      },
      birthDate: {
        label: "Birth date",
        type: TYPES.DATE,
      },
      birthCountry: {
        label: "Birth country",
        type: TYPES.SELECT,
        values: NZ_SELECT_VALUES.COUNTRY_OF_BIRTH,
        geoLocation: "birthGeoFound",
        geoLocationProps: ["birthCity"],
      },
      birthCity: {
        label: "Birth city",
        type: TYPES.TEXT,
      },
    },
  },
  passportInfoSegment: {
    label: "Passport information",
    type: TYPES.SEGMENT,
    data: {
      nationality: {
        label: "Nationality of passport holder",
        type: TYPES.SELECT,
        values: NZ_SELECT_VALUES.NATIONALITIES,
      },
      passportNumber: {
        label: "Passport number",
        type: TYPES.TEXT,
      },
      expirationDate: {
        label: "Passport expiry date",
        type: TYPES.DATE,
      },
      issuingCountry: {
        label: "Passport issuing country",
        type: TYPES.SELECT,
        values: NZ_SELECT_VALUES.ISSUING_COUNTRIES,
      },
    },
  },
  securityQuestionsSegment: {
    label: "Security questions",
    type: TYPES.SEGMENT,
    data: {
      isPermanentAustResident: {
        label: "Is Australian permanent resident?",
        type: TYPES.CHECKBOX,
      },
      medical: {
        label:
          "Will applicant be traveling to New Zealand for medical consultation",
        type: TYPES.CHECKBOX,
      },
      deported: {
        label: "Have you ever been deported, removed or excluded from another country (not New Zealand)?",
        type: TYPES.CHECKBOX,
      },
      prohibited: {
        label: "Are you currently prohibited from entering New Zealand following deportation from New Zealand in the past?",
        type: TYPES.CHECKBOX,
      },
      isStayingInNZ:{
        label: "Are you a transit passenger passing through Auckland International Airport on your way to or from Australia?",
        type: TYPES.CHECKBOX,
      },
      convicted: {
        label: "Have you ever been convicted",
        type: TYPES.CHECKBOX,
      },
      fiveYears: {
        label: "Have you ever been convicted of an offence for which you were sentenced to five years or more imprisonment?",
        type: TYPES.CHECKBOX,
        dependsOn: "convicted",
        dependantOnValues: [true],
      },
      twelveMonths: {
        label: "In the last 10 years have you been convicted of an offence for which you were sentenced to a prison term of 12 months or more?",
        type: TYPES.CHECKBOX,
        dependsOn: "convicted",
        dependantOnValues: [true],
      },
      isTravellingOnCruiseShip: {
        label: "Is travelling on cruise ship",
        type: TYPES.CHECKBOX,
        dependsOn: "isPermanentAustResident",
        dependantOnValues: [false],
      },
      isAmericanSamoaResident: {
        label: "Is American Samoa resident",
        type: TYPES.CHECKBOX,
        dependsOn: "nationality",
        dependantOnValues: ["United States of America"],
      },
    },
  },
};
