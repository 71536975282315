import routeConfig from "../../../../modules/routeConfig";
import UserProfile from "./pages/UserProfile";
import UserTable from "./pages/UserTable";

const routes = [
  {
    path: "/:id",
    component: UserProfile,
  },
  {
    path: "",
    component: UserTable,
  },
];

export default routeConfig(routes);
