import React from 'react';
import TicketStatusTag from "./TicketStatusTag.component";
import moment from 'moment';
import { Popup } from 'semantic-ui-react';

const dateFormat = "DD MMM YYYY HH:mm";

/**
 * @param {
 *  type: string,
 *  data: object
 * } action
 * @returns
 */
const FormattedMessage = (message) => {
  return message.split("\n").map((line, index) => {
    return (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    );
  });
};

const TimeLineAction = ({ note }) => {
  if (note.type === "changeStatus") {
    return (
      <div className="appn-timeline-entry-title">
        <p>
          <b>{note.data.user.name}</b> changed status to{" "}
          <TicketStatusTag ticketStatus={note.data.comment.status.newStatus} />
        </p>
        <Popup 
          content={<small>{moment(note.createdAt).format(dateFormat)}</small>}
          trigger={
            <p className="appn-timeline-entry-time">
              {moment(note.createdAt).fromNow()}
            </p>
          }
        />
      </div>
    );
  }

  if (note.type === "comment") {
    return (
      <div className="appn-timeline-comment-container">
        <div className="appn-timeline-entry-title">
          <p>
            <b>{note.data.user.name}</b> commented
          </p>
          <Popup
            content={<small>{moment(note.createdAt).format(dateFormat)}</small>}
            trigger={
              <p className="appn-timeline-entry-time">
                {moment(note.createdAt).fromNow()}
              </p>
            } 
          />
        </div>
        <div className="appn-timeline-comment-box">
          {FormattedMessage(note.data.comment.message)}
        </div>
      </div>
    );
  }
};

export default TimeLineAction;
