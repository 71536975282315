import React from "react";
import { Dropdown } from "semantic-ui-react";
import enums from "./constants";
import socketService from "../../../../services/socketService";
import { filter, map } from "rxjs/operators";
import { SOCKET_EVENTS } from "../../../../constants/socketEvents";
import { sortedIndex } from "../../../../utils";
import { getOnlineUsers } from "../../../../apis/userApi";
import classNames from "classnames";
import "./OnlineAgents.styles.scss";
import { CredentialsService } from "../../../../services/credentialsService";
import { AVAILABILITY_ONLINE_AGENTS } from "../../../../Router/routers/DashboardRouter/routers/Users/constants/agentAvailability";

class OnlineAgents extends React.Component {
  state = { users: {} };
  subscriptions = [];

  constructor(props) {
    super(props);
    this.fetchUsers();
    this.setupSocketListeners();
  }

  componentWillUnmount() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  setupSocketListeners() {
    this.subscriptions.push(
      socketService.socketSubject
        .pipe(
          filter(
            (event) => event.type === SOCKET_EVENTS.AGENTS.AVAILABILITY_CHANGED,
          ),
          map((ev) => ev.data),
          filter(
            ({ agent: { _id } }) => CredentialsService.getUserId() !== _id,
          ),
        )
        .subscribe(
          async ({
            agent: updatedAgent,
            agentAvailability,
            previousAgentAvailability,
          }) => {
            const { users } = this.state;

            if (previousAgentAvailability.toLowerCase() === "offline") {
              /** agent was offline - so there's no need to look for him in the arrays */
              const rolesOfImportance = Object.values(
                enums.AgentAvailabilityOfImportance,
              );

              const newUsers = JSON.parse(JSON.stringify(users));
              updatedAgent.roles.forEach((role) => {
                if (rolesOfImportance.includes(role)) {
                  const groupArray = newUsers[role];
                  /** Add agent sorted alphabetically */
                  const newInd = sortedIndex(
                    groupArray.map((ag) => ag.fullName.toLowerCase()),
                    updatedAgent.fullName.toLowerCase(),
                  );

                  if (newInd >= 0) {
                    groupArray.splice(newInd, 0, updatedAgent);
                  }
                }
              });
              this.setState({ users: newUsers });
            } else {
              /** agent already exists in our arrays, so we need to find him and update his status */
              let existingAgent = null;
              const update = {};
              Object.entries(users).forEach(([agentRole, agentGroup]) => {
                const agentInd = agentGroup.findIndex(
                  (agent) => agent._id === updatedAgent._id,
                );
                if (agentInd >= 0) {
                  update[agentRole] = agentInd;
                  existingAgent = agentGroup[agentInd];
                }
              });

              if (existingAgent) {
                existingAgent.settings.availability = agentAvailability;

                if (agentAvailability.toLowerCase() === "offline") {
                  /** agent became offline, remove it from all the arrays */
                  Object.entries(update).forEach(([agentRole, agentInd]) => {
                    users[agentRole].splice(agentInd, 1);
                  });
                }
                /** edit state by ref for faster execution, then use forceUpdate */
                this.forceUpdate();
              }
            }
          },
        ),
    );
  }

  fetchUsers = async () => {
    /** method is callend in constructor so we will edit
     * state with this
     */

    const { data } = await getOnlineUsers();
    const users = {};

    const rolesOfImportance = Object.values(
      enums.AgentAvailabilityOfImportance,
    );

    rolesOfImportance.forEach((value) => (users[value] = []));

    data.forEach((agent) => {
      if (agent._id === CredentialsService.getUserId()) return;

      AVAILABILITY_ONLINE_AGENTS.includes(agent.settings.availability) &&
        agent.roles.forEach((role) => {
          if (rolesOfImportance.includes(role)) {
            users[role].push(agent);
          }
        });
    });
    this.setState({ users });
  };

  extractStatusFromAvailability(availability) {
    if (!availability) return "";
    return availability.split(" ")[0].toLowerCase();
  }

  get totalAgentsOnline() {
    const set = new Set();
    Object.values(this.state.users).map((agents) =>
      agents.forEach((agent) => set.add(agent)),
    );
    return set.size;
  }

  render() {
    const { users } = this.state;

    return (
      <div className="dropdown online-agents">
        <Dropdown
          text={`Online Twilio Agents (${this.totalAgentsOnline})`}
          icon="user"
          button
        >
          <Dropdown.Menu>
            {Object.entries(users).map(([key, agents]) => (
              <React.Fragment key={key}>
                <div className="dropdown__subheader">{key}</div>
                {agents.map(
                  ({
                    settings: { availability },
                    firstName,
                    lastName,
                    email,
                    _id,
                  }) => {
                    const status = this.extractStatusFromAvailability(
                      availability,
                    );
                    return (
                      <Dropdown.Item
                        key={_id}
                        title={availability}
                        className="dropdown__item"
                      >
                        <div className="dropdown__item__agent">
                          <span className="dropdown__item__agent__name">
                            {firstName} {lastName}
                          </span>
                          <span className="dropdown__item__agent__email">
                            {email}
                          </span>
                        </div>
                        <span
                          className={classNames(
                            "dropdown__item__status",
                            `dropdown__item__status--${status}`,
                          )}
                        />
                      </Dropdown.Item>
                    );
                  },
                )}
                {agents.length === 0 && (
                  <div className="dropdown__empty-list">
                    No agents available
                  </div>
                )}
              </React.Fragment>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

export default OnlineAgents;
