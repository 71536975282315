import React, { useMemo } from "react";
import { useRouteMatch, Redirect } from "react-router-dom";

const NestedRedirect = (props) => {
  const { to } = props;
  const { url } = useRouteMatch();

  const finalPath = useMemo(() => {
    const { length: urlLength } = url;
    if (url[urlLength - 1] === to[0] && to[0] === "/") {
      return url + to.slice(1);
    }
    return url + to;
  }, [url, to]);

  return <Redirect to={finalPath} />;
};

export default NestedRedirect;
