import { createContext } from "react";

const defaultConfigurationContext = {
  creditCards: [],
  setCreditCards: (value) => {},
  botConfigs: [],
  setBotConfigs: (value) => {},
  ivisaConfig: [],
  setIvisaConfig: (value) => {},
  uploadDocumentsConfigs: [],
  setUploadDocumentsConfig: (value) => {},
};

export default createContext(defaultConfigurationContext);
