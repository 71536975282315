import moment from "moment";
import React from "react";
import { Table, Checkbox, Input } from "semantic-ui-react";
import { ALLOWED_REFERRAL_SITES } from "../../../../../EVisa/pages/ApplicantsEdit/components/constants";

const SchengenReviewAndCertification = ({ applicant, referralSite = ALLOWED_REFERRAL_SITES.DEFAULT }) => {
  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell colSpan="2">
          <div>
            <p>
              I am aware that the visa fee is not refunded if the visa is
              refused.
            </p>
          </div>
          <div>
            <p>
              Applicable in case a multiple-entry visa is applied for (cf. field
              no 24): I am aware of the need to have an adequate travel medical
              insurance for my first stay and any subsequent visits to the
              territory of Member States.
            </p>
          </div>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell colSpan="2">
          <div>
            <p>
              I am aware of and consent to the following: the collection of the
              data required by this application form and the taking of
              myphotograph and, if applicable, the taking of fingerprints, are
              mandatory for the examination of the application; and any personal
              data concerning me which appear on the application form, as well
              as my fingerprints and my photograph will be supplied to
              therelevant authorities of the Member States and processed by
              those authorities, for the purposes of a decision on my
              application.Such data as well as data concerning the decision
              taken on my application or a decision whether to annul, revoke or
              extend a visa issued will be entered into, and stored in the Visa
              Information System (VIS) for a maximum period of five years,
              during which it will be accessible to the visa authorities and the
              authorities competent for carrying out checks on visas at external
              borders and within the Member States, immigration and asylum
              authorities in the Member States for the purposes of verifying
              whether the conditions for the legal entry into, stay and
              residence on the territory of the Member States are fulfilled, of
              identifying persons who do not or who no longer fulfil these
              conditions, of examining an asylum application and of
              determiningresponsibility for such examination. Under certain
              conditions the data will be also available to designated
              authorities of the Member States and to Europol for the purpose of
              the prevention, detection and investigation of terrorist offences
              and of other serious criminal offences. The authority of the
              Member State responsible for processing the data is: The Ministry
              of Foreign Affairs of Denmark or the Danish Immigration Service.
              Read more about the responsible of data and the processing of
              personal data at www.newtodenmark.dk/visa_personaldata.I am aware
              that I have the right to obtain, in any of the Member States,
              notification of the data relating to me recorded in the VIS and of
              the Member State, which transmitted the data, and to request that
              data relating to me, which are inaccurate, be corrected and that
              data relating to me processed unlawfully be deleted. At my express
              request, the authority examining my application will inform me of
              the manner in which I may exercise my right to check the personal
              data concerning me and have them corrected or deleted, including
              the related remedies according to the national law of the Member
              State concerned. The national supervisory authority of that Member
              State [the Danish Data Protection Agency, Carl Jacobsens Vej 35,
              DK-2500 Valby, e-mail: dt@datatilsynet.dk] will hear claims
              concerning the protection of personal data.I declare that to the
              best of my knowledge all particulars supplied by me are correct
              and complete. I am aware that any false statements will lead to my
              application being rejected or to the annulment of a visa already
              granted and may also render me liable to prosecution under the law
              of the Member State which deals with the application.I undertake
              to leave the territory of the Member States before the expiry of
              the visa, if granted. I have been informed that possession of a
              visa is only one of the prerequisites for entry into the European
              territory of the Member States. The mere fact that a visa has been
              granted to me does not mean that I will be entitled to
              compensation if I fail to comply with the relevant provisions of
              Article 6(1) of Regulation (EU) No 2016/399 (Schengen Borders
              Code) and I am therefore refused entry. The prerequisites for
              entry will be checked again on entry into the European territory
              of the Member States.
            </p>
          </div>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell colSpan="2">
          <h5>Third Party Authorization</h5>
          <p>
            I understand that I am applying via GovAssist, LLC; a third-party
            that is not affiliated with the government, that the third-party
            cannot guarantee application approval, that the third-party service
            is offered to help expedite the process, and that I have the option
            to apply directly with the pertinent government websites. I also
            understand that my purchase consists of a processing and service fee
            to GovAssist, which DOES NOT include the Form non-refundable
            government fee, which is required.
          </p>
          <p>
            I confirm and authorize GovAssist, LLC to assist me, by using their
            processing service in completing and reviewing my application. I
            understand and acknowledge that I have not been offered legal advice
            and that this company or its agents and affiliates have never
            claimed to be qualified to offer legal advice under any
            circumstance. I also confirm that I am submitting my application and
            signing it myself.
          </p>
          <p>
            By obtaining services from the GovAssist website, I have agreed to
            the following:{" "}
            <a href={`https://${referralSite}/terms-conditions`}>
              Terms & Conditions
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/refund-policy`}>
              Refund Policy{" "}
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/privacy-policy`}>
              Privacy Policy{" "}
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/legal-disclaimer`}>
              Disclaimer{" "}
            </a>
          </p>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Place of signature</Table.Cell>
        <Table.Cell>
          <Input value={applicant.placeOfSignature} type="text" disabled />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>Date of signature</Table.Cell>
        <Table.Cell>
          <Input
            value={moment(applicant.dateOfSignature).format("DD-MM-YYYY")}
            disabled
          />
        </Table.Cell>
      </Table.Row>
    </React.Fragment>
  );
};

export default SchengenReviewAndCertification;
