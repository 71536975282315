import { useState, useCallback, useMemo } from "react";

/**
 * 
 * @param {{
      sortCriteria?:string,
      sortOrder?:"ascending"|"descending"
     }
    } options 
 */
function useSort(options = {}) {
  const {
    sortCriteria: defaultSortCriteria,
    sortOrder: defaultSortOrder,
  } = options;
  const [sortParams, setSortParams] = useState(
    defaultSortOrder && defaultSortCriteria
      ? {
          sortCriteria: defaultSortCriteria,
          sortOrder: defaultSortOrder,
        }
      : {},
  );

  const handleSortChange = useCallback(
    (column) => () => {
      const { sortCriteria, sortOrder } = sortParams;
      const newSortParams = {
        sortOrder: "descending",
        sortCriteria: column,
      };
      if (sortCriteria === column) {
        if (sortOrder === "ascending") {
          delete newSortParams.sortCriteria;
          delete newSortParams.sortOrder;
        } else {
          newSortParams.sortOrder = "ascending";
        }
      }
      setSortParams(newSortParams);
    },
    [sortParams, setSortParams],
  );

  const normalizedSortParams = useMemo(
    () =>
      sortParams.sortCriteria
        ? {
            sortCriteria: sortParams.sortCriteria,
            sortOrder: sortParams.sortOrder.slice(0, 3),
          }
        : {},
    [sortParams],
  );

  return { sortParams, handleSortChange, normalizedSortParams };
}

export { useSort };
