import httpClientInstance from "../httpClient";

export async function getLetterInfoApi(applicantId, api) {
  return httpClientInstance.get(`${api}/applicant/${applicantId}/letter`);
}

export async function resendCustomerEmailApi(applicantId, api) {
  return httpClientInstance.post(
    `${api}/applicant/${applicantId}/letter/resend-initial`,
  );
}

export async function resendSponsorEmailApi(applicantId, api) {
  return httpClientInstance.post(
    `${api}/applicant/${applicantId}/letter/resend-signature`,
  );
}

export async function checkAvailableAppointmentsApi(applicantId, api, usConsulate) {
  return httpClientInstance.post(
    `${api}/applicant/${applicantId}/check-available-dates`, { usConsulate }
  );
}
