import { getApplicantNotes, createApplicantNote, pinApplicantNote } from "../apis/applicantApi";
import toastr from "toastr";

export class ApplicantNotesService {
  constructor(api, applicantId, user) {
    this.api = api;
    this.applicantId = applicantId;
    this.user = user;
  }

  async getNotes(filter = '') {
    const { data } = await getApplicantNotes(this.applicantId, filter);
    return data.data;
  }

  /**
   * @param {string | object} info Applicant Status | Comment
   * @param {string} type "comment" | "changeStatus"
   */
  async createNote(info, type = "comment") {
    try {
      const payload = {
        applicant: this.applicantId,
        api: this.api,
        user: this.user.id,
        noteType: type,
        comment: {},
      };
  
      switch (type) {
        case "comment":
          payload.comment.message = info;
          break;
        case "changeStatus":
          payload.comment.status = {
            oldStatus: info.oldStatus,
            newStatus: info.newStatus,
          };
          break;
      }
  
      const { data } = await createApplicantNote(payload);
  
      toastr.success("Note successfully created!", "OK");
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * @param {string} noteId
   * @param {boolean} pinned
   * @returns {object} Note
   */
  async pinNote(noteId, pinned) {
    try {
      const { data } = await pinApplicantNote(noteId, { pinned });
      const message = pinned ? "Note successfully pinned!" : "Note successfully unpinned!";
      toastr.success(message, "OK");
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
