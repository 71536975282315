import { FETCH_CURRENT_USER, EDIT_USER_SETTINGS } from "./actionTypes";
import {
  getCurrentUserData,
  patchUserSettings,
  patchUserAvailability,
} from "../../apis/userApi";

export const _fetchCurrentUser = () => async (dispatch) => {
  try {
    const response = await getCurrentUserData();
    dispatch({
      type: FETCH_CURRENT_USER,
      payload: response.data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const editUserSettings = (data) => async (dispatch) => {
  try {
    await patchUserSettings(data);
    dispatch({
      type: EDIT_USER_SETTINGS,
      payload: data,
    });
  } catch (error) {
    console.error(error);
  }
};
export const editAvailability = (data) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_USER_SETTINGS,
      payload: { availability: data },
    });
    await patchUserAvailability(data);
  } catch (error) {
    console.error(error);
  }
};
