import React, { useState, useMemo, useEffect, useContext } from "react";
import { Accordion, Menu } from "semantic-ui-react";
import SpecificFilter from "../../../../components/QAReportPage/components/SpecificFilter";
import "./ManagerAvailabilityReport.styles.scss";
import {
  TimespanFrame,
  UNITS_OF_TIME,
  EVENT_NAMES,
  TimespanLegend,
  PieChart,
} from "../../../../../Users/pages/UserProfile/components/Report";
import { QA_REPORT_FOR } from "../../../../constants/qaReports";
import { getUsersAvailabilityReportApi } from "../../../../../../../../../apis/reports/availabilityReportsApi";
import CalendarFilter from "../../../../../../../../../components/CalendarFilter";
import moment from "moment";
import _ from "lodash";
import {
  useAccordionIndexes,
  useSpecificFilter,
  useFilters,
  useCalendarFilter,
} from "../../../../../../../../../hooks";
import Spinner from "../../../../../../../../../components/Spinner";
import { UsersContext } from "../../../../../../../../../common/UsersContext";
import ShiftReport from "../../../../../Users/pages/UserProfile/components/ShiftReport";
import uuid from "uuid";
import className from "classnames";
import {
  getDateInUserTz,
  getDateInUserTzOrNow,
} from "../../../../../../../../../common/date-format";

const TODAY = new Date();

const priorityMap = {
  offline: 1,
  unavailable: 2,
  available: 3,
};

const ManagerAvailabilityReport = () => {
  const { allUsers, managerUsers } = useContext(UsersContext);

  const { selectedDateSingleFilter, selectedDate, onChangeDate } =
    useCalendarFilter();

  const [selectedAgentReports, setSelectedAgentReports] = useState();
  const [loader, setLoader] = useState(false);

  const selectedDateInTz = useMemo(
    () => ({
      startDate: selectedDate.startDate
        ? getDateInUserTz(selectedDate.startDate)
        : null,
      endDate: selectedDate.endDate
        ? getDateInUserTzOrNow(selectedDate.endDate)
        : null,
    }),
    [selectedDate],
  );

  useEffect(() => {
    setLoader(true);
  }, [selectedDate]);

  const {
    filters: { selectedAgents, selectedAllAgents },
    setFilters: setSelectedAgents,
  } = useFilters(
    {
      selectedAgents: {},
      selectedAllAgents: false,
    },
    {
      localStorageKey: "qa-report-agent-filters",
    },
  );

  const {
    filters: { selectedGroups },
    setFilters: setSelectedGroups,
  } = useFilters(
    {
      selectedGroups: [],
    },
    {
      localStorageKey: "qa-report-group-filters",
    },
  );

  const { activeAccordionIndexes, handleAccordionClick } =
    useAccordionIndexes();

  const { onChangeSpecificFilter } = useSpecificFilter({
    selectedAgents,
    selectedGroups,
  });

  useEffect(() => {
    async function getUsersReports() {
      try {
        const { startDate, endDate } = selectedDateInTz;
        const allUsersSelected = managerUsers;

        const { data: reports } = await getUsersAvailabilityReportApi(
          {
            from: startDate,
            to: endDate,
          },
          selectedAllAgents
            ? allUsersSelected &&
                allUsersSelected.map((user) => user._id).join()
            : selectedAgents && Object.keys(selectedAgents).join(),
        );
        setSelectedAgentReports(reports);
      } catch (err) {
        console.error(err);
      }
    }
    if (selectedDateInTz.startDate && managerUsers.length) getUsersReports();
  }, [selectedDateInTz, selectedAllAgents, selectedAgents, managerUsers]);

  const onChangeSpecificFilterComponent = (value, type, categoryName) => {
    switch (type) {
      case "agents":
      case "select-all-agents":
        setSelectedAgents(onChangeSpecificFilter(value, type, categoryName));
        setSelectedGroups({ selectedGroups: [] });
        break;

      case "groups":
        const { selectedGroups, selectedAgents, selectedAllAgents } =
          onChangeSpecificFilter(value, type, categoryName);

        setSelectedGroups({ selectedGroups });
        setSelectedAgents({ selectedAgents, selectedAllAgents });
        break;

      default:
        break;
    }
  };

  const teamReports = useMemo(() => {
    const { startDate } = selectedDateInTz;

    let newSelectedAgentReports = {};
    Object.keys(selectedAgentReports || {}).forEach((agentId) => {
      const firstAgentReport = selectedAgentReports[agentId][0];

      if (
        firstAgentReport &&
        new Date(firstAgentReport.createdAt) - new Date(startDate) > 0
      ) {
        newSelectedAgentReports = {
          ...newSelectedAgentReports,
          [agentId]: [
            {
              ...firstAgentReport,
              _id: uuid(),
              createdAt: startDate,
              to: firstAgentReport.createdAt,
              name: firstAgentReport.data.previousAvailabilityStatus
                ? firstAgentReport.data.previousAvailabilityStatus
                    .split(" ")[0]
                    .toLowerCase()
                : EVENT_NAMES.OFFLINE,
            },
            ...selectedAgentReports[agentId],
          ],
        };
      }
    });

    const allReports = Array.from(
      new Set(
        Object.values(newSelectedAgentReports || {})
          .map((rArr) =>
            rArr.map((report, i, reps) => ({
              ...report,
              createdAt: moment(report.createdAt),
              priority: priorityMap[report.name],
              to: moment(reps[i + 1] ? reps[i + 1].createdAt : TODAY),
            })),
          )
          .flat()
          .sort((a, b) => {
            if (new Date(a.createdAt) - new Date(b.createdAt) === 0) {
              return new Date(a.to) - new Date(b.to);
            }
            return new Date(a.createdAt) - new Date(b.createdAt);
          }),
      ),
    );

    const priorities = Object.values(priorityMap);

    const reportsByPriority = priorities.reduce(
      (acc, priority) => [
        ...acc,
        allReports.filter((r) => r.priority === priority),
      ],
      [],
    );

    if (!allReports.length) return allReports;

    const {
      0: { createdAt },
      [allReports.length - 1]: { to },
    } = allReports;

    let newReports = [
      {
        ...reportsByPriority[0][0],
        createdAt: moment(startDate - createdAt > 0 ? createdAt : startDate),
        to,
      },
    ];

    function mergeReportArray(rArr) {
      let merged = [];
      const tmp = [...rArr];

      if (tmp.length === 1) merged = [...tmp];

      for (let i = 0; i < tmp.length - 1; i++) {
        const r = tmp[i];
        const existingInd = merged.findIndex((rep) => rep._id === r._id);

        const right = tmp[i + 1];
        if (r.to >= right.createdAt) {
          const newEntry = {
            ...r,
            to: r.to > right.to ? r.to : right.to,
          };

          tmp.splice(i--, 2, newEntry);

          if (existingInd >= 0) {
            merged[existingInd] = newEntry;
          } else {
            merged.push(newEntry);
          }
        } else {
          if (existingInd < 0) merged.push(r);
          if (i === tmp.length - 2) merged.push(right);
        }
      }
      return merged;
    }

    reportsByPriority
      .filter((_, ind) => ind > 0)
      .map(mergeReportArray)
      .forEach((reportArr) => {
        reportArr.forEach((rep) => {
          newReports = newReports.filter(
            (r) =>
              !(
                r.createdAt >= rep.createdAt &&
                r.to <= rep.to &&
                rep.priority >= r.priority
              ),
          );

          /** left */
          const left = newReports
            .reverse()
            .find(
              (r) =>
                rep.createdAt <= r.to &&
                rep.createdAt > r.createdAt &&
                rep.priority >= r.priority,
            );

          newReports.reverse();

          /** right */
          const right = newReports.find(
            (r) =>
              rep.to >= r.createdAt &&
              rep.to < r.to &&
              rep.priority >= r.priority,
          );

          if (left && right && left === right) {
            const newLeft = { ...left, to: rep.createdAt };
            const newRight = { ...right, createdAt: rep.to };
            const ind = newReports.findIndex((r) => r === left);

            newReports.splice(ind, 1, newLeft, rep, newRight);
            return;
          } else {
            if (left) {
              left.to = rep.createdAt;
            }

            if (right) {
              right.createdAt = rep.to;
            }
          }
          newReports.push(rep);
          newReports.sort((a, b) => a.createdAt - b.createdAt);
        });
      });

    return newReports;
  }, [selectedDateInTz, selectedAgentReports]);

  const ReportsComponents = useMemo(() => {
    setLoader(false);
    return !_.isEmpty(selectedAgentReports) &&
      selectedDate.startDate !== null &&
      selectedDate.endDate !== null ? (
      <>
        <PieChart
          reports={teamReports}
          firstDate={selectedDateInTz.startDate}
          lastDate={selectedDateInTz.endDate}
        />
        <h2>Total</h2>
        <ShiftReport
          firstDate={selectedDateInTz.startDate}
          lastDate={selectedDateInTz.endDate}
        />
        <TimespanFrame
          reports={teamReports}
          firstDate={selectedDateInTz.startDate}
          lastDate={selectedDateInTz.endDate}
          separator={UNITS_OF_TIME.DAY}
          forManagerReports
        />
        <h2>By agent</h2>
        {Object.keys(selectedAgentReports).map((userId) => {
          return (
            <div key={userId}>
              <div>
                {!Object.keys(selectedAgents).length || selectedAllAgents
                  ? allUsers &&
                    allUsers.find((user) => user._id === userId)?.fullName
                  : selectedAgents &&
                    selectedAgents[userId] &&
                    selectedAgents[userId].name}
              </div>
              <TimespanFrame
                reports={selectedAgentReports[userId]}
                firstDate={selectedDateInTz.startDate}
                lastDate={selectedDateInTz.endDate}
                separator={UNITS_OF_TIME.DAY}
              />
            </div>
          );
        })}
        <TimespanLegend />
      </>
    ) : (
      <h4>Agents have no reports for this time period</h4>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgentReports, selectedAgents]);

  return (
    <div className={className("team-availability")}>
      <div className="team-availability__reports">
        <h2>Team Availability</h2>
        {loader ? (
          selectedDate.startDate !== null ? (
            <>
              <Spinner /> <h4>Loading reports...</h4>
            </>
          ) : (
            <h4>The time period is not selected</h4>
          )
        ) : (
          ReportsComponents
        )}
      </div>
      <div>
        <Accordion as={Menu} vertical fluid>
          <Menu.Item>
            <Accordion.Title
              active={activeAccordionIndexes[0] === 1}
              content="Calendar Filter"
              index={0}
              onClick={handleAccordionClick}
            />
            <Accordion.Content
              active={activeAccordionIndexes[0] === 1}
              content={
                <CalendarFilter
                  onChangeDate={onChangeDate}
                  selectedDate={selectedDate}
                  selectedDateSingleFilter={selectedDateSingleFilter}
                />
              }
            />
          </Menu.Item>
          <Menu.Item>
            <Accordion.Title
              active={activeAccordionIndexes[1] === 1}
              content="Specific Filter"
              index={1}
              onClick={handleAccordionClick}
            />
            <Accordion.Content
              active={activeAccordionIndexes[1] === 1}
              content={
                <SpecificFilter
                  type={QA_REPORT_FOR.MANAGER}
                  onChangeSpecificFilter={onChangeSpecificFilterComponent}
                  selectedAgents={selectedAgents}
                  selectedGroups={selectedGroups}
                  availabilityReport
                />
              }
            />
          </Menu.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default ManagerAvailabilityReport;
