import React, { useMemo } from "react";
import { FILTERED_PRODUCT_NAMES } from "../../../../../../routers/Products/constants/productNames";
import { ZENDESK_HOSTS } from "../../../../../../routers/MyAccount/constants/zendesk";
import FormFields from "../../../../../../../../../components/common/FormFields";

const productOptions = Object.entries(FILTERED_PRODUCT_NAMES).map(
  ([text, value]) => ({
    value,
    text,
  }),
);

const zendeskOptions = ZENDESK_HOSTS.map((host) => ({
  value: host.link,
  text: host.name,
}));

const CallRoutingConfigurationForm = ({ numberConfig }) => {
  const fields = useMemo(
    () => [
      {
        key: "number",
        type: "input",
        phoneNumber: true,
        fieldSpecific: {
          type: "text",
        },
      },
      {
        key: "products",
        type: "select",
        fieldSpecific: {
          options: productOptions,
          multiple: true,
        },
        defaultValue: [],
      },
      {
        key: "zendeskHost",
        type: "select",
        fieldSpecific: {
          options: zendeskOptions,
        },
        defaultValue: null,
      },
    ],
    [],
  );

  return <FormFields fields={fields} currentItem={numberConfig} />;
};

CallRoutingConfigurationForm.defaultProps = {
  numberConfig: {
    number: "",
    products: [],
    zendeskHost: null,
  },
};

export default CallRoutingConfigurationForm;
