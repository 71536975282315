import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import ApexChart from "react-apexcharts";
import Spinner from "../Spinner";
import { transformChartDataForType } from "./helpers/chartDataTransformer";

const Chart = ({ chartId, chartConfig, chartData, chartLabels }) => {
  const [transformedData, setTransformedData] = useState({
    options: {},
    series: [],
    type: "",
    loading: false,
  });

  const shouldLoadChart = useMemo(
    () => !transformedData.loading && transformedData.type,
    [transformedData],
  );

  const { currentUser } = useSelector((state) => ({
    currentUser: state.currentUser,
  }));

  useEffect(() => {
    if (chartData && currentUser) {
      const {
        settings: { activeTheme },
      } = currentUser;
      const transformChartData = async () => {
        let data = { options: {}, series: [], type: "" };

        setTransformedData((data) => ({ ...data, loading: true }));

        const { options, series } = await transformChartDataForType(
          chartConfig,
          chartId,
          chartData,
          chartLabels,
          activeTheme,
        );

        data.options = options;
        data.series = series;
        data.type = chartConfig.chartType;

        setTransformedData({ ...data, loading: false });
      };

      transformChartData();
    }
  }, [chartConfig, chartId, chartData, chartLabels, currentUser]);

  return shouldLoadChart ? (
    <ApexChart
      options={{
        ...transformedData.options,
        theme: { mode: currentUser.settings.activeTheme },
      }}
      series={transformedData.series}
      type={transformedData.type}
      height={300}
    />
  ) : (
    <Spinner />
  );
};

export default Chart;
