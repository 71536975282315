import axios from "../httpClient";

function getBotConfiguration() {
  return axios.get(`/bot-configuration`);
}

function editBotConfiguration(body) {
  return axios.patch(`/bot-configuration`, body);
}

export default {
  getBotConfiguration,
  editBotConfiguration,
};
