import { capitalizeEachWord } from "../../../../../../utils";

const capitalizeWordsForApplicantName = ({propertyValue,applicantProperty,applicant}) => {
    return propertyValue
            ? ["firstName", "lastName", "fullName"].includes(
                applicantProperty,
              )
              ?
               capitalizeEachWord(propertyValue,applicant.api==='eta' && true)
              : propertyValue
            : ""
}
export {capitalizeWordsForApplicantName}