import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";

const COUNTRIES_WITH_STATES = [
  "AFGH",
  "ALBA",
  "ALGE",
  "ANDO",
  "ANGA",
  "ANBA",
  "ARGE",
  "ARME",
  "A",
  "AUST",
  "AZER",
  "BHMS",
  "BAHR",
  "BDES",
  "BARS",
  "BYEL",
  "BELM",
  "BELZ",
  "BENI",
  "BHUT",
  "BOLI",
  "BSES",
  "BOHE",
  "BOTS",
  "BRAZ",
  "BRUI",
  "BULG",
  "UVOL",
  "BURU",
  "CABV",
  "CAMB",
  "CREP",
  "CANA",
  "CARE",
  "CHAD",
  "CHIL",
  "PRCH",
  "COLA",
  "COMO",
  "CONG",
  "DRCO",
  "CRIC",
  "ICOA",
  "CROA",
  "CUBA",
  "CYPR",
  "CZEH",
  "DENM",
  "JIBU",
  "DOMI",
  "DREP",
  "ECUA",
  "EGYP",
  "ESAL",
  "ENGL",
  "EQGN",
  "ERIT",
  "ESTO",
  "ESWA",
  "ETHI",
  "FIJI",
  "FINL",
  "FRAE",
  "GABO",
  "GAMB",
  "GEOG",
  "GERM",
  "GHAN",
  "GREE",
  "GRED",
  "GREN",
  "GUAT",
  "GUIN",
  "GUBS",
  "GUYN",
  "HAIT",
  "HOND",
  "HUNG",
  "ICEL",
  "INDI",
  "INDO",
  "IRAN",
  "IRAQ",
  "IREP",
  "ISRA",
  "ITAL",
  "JAMA",
  "JAPA",
  "JORD",
  "KAZA",
  "KENY",
  "GIIS",
  "NKOR",
  "SKOR",
  "KUWA",
  "KIRG",
  "LAOS",
  "LATV",
  "LEBA",
  "LESO",
  "LIBE",
  "LIBA",
  "LIEC",
  "LITH",
  "LUXE",
  "MADA",
  "MALW",
  "MALS",
  "MIS",
  "M",
  "MALT",
  "MAIS",
  "MAUA",
  "MAUS",
  "MEXI",
  "MOLD",
  "MONA",
  "MONG",
  "MNTE",
  "MORO",
  "MOZA",
  "MYAN",
  "NAMI",
  "NAUR",
  "NEPA",
  "NETH",
  "NZEA",
  "NICA",
  "NIGR",
  "NGRA",
  "NMAC",
  "NOIR",
  "NORW",
  "OMAN",
  "PKSN",
  "PALA",
  "PANA",
  "NGUI",
  "PARA",
  "PERU",
  "PHIL",
  "POLA",
  "PORL",
  "QATA",
  "ROMA",
  "RFED",
  "RWAN",
  "SHTC",
  "STLU",
  "SAMO",
  "SMAR",
  "STPR",
  "SAAR",
  "SCOT",
  "SENE",
  "SERB",
  "SEYC",
  "SLEO",
  "SING",
  "SVKA",
  "SLOV",
  "SOLI",
  "SOMA",
  "SAFR",
  "SSDN",
  "SPAI",
  "SRIL",
  "SCKN",
  "STVG",
  "SUDA",
  "SURI",
  "SWED",
  "SWIT",
  "SYRI",
  "TADZ",
  "TANZ",
  "THAI",
  "TILE",
  "TOGO",
  "TONG",
  "TRIN",
  "TUNI",
  "TURE",
  "TURM",
  "TUVU",
  "USOI",
  "UGAN",
  "UKRA",
  "UAEM",
  "UK",
  "USA",
  "URUG",
  "UZBE",
  "VANU",
  "VENE",
  "VIET",
  "WALE",
  "WAFU",
  "YEME",
  "ZAMB",
  "ZIMB",
];

export const AUS_SELECT_VALUES = {
  CURRENT_LOCATION: "CURRENT_LOCATION",
  BIRTH_COUNTRIES: "BIRTH_COUNTRIES",
  NATIONALITY: "NATIONALITY",
  ADDRESS_COUNTRIES: "ADDRESS_COUNTRIES",
  PHONE_CODES: "PHONE_CODES",
  PHONE_CODES_KEY_VALUE_OBJECT: "PHONE_CODES_KEY_VALUE_OBJECT",
  GENDERS: "GENDERS",
  REASON_FOR_NAME_CHANGE: "REASON_FOR_NAME_CHANGE",
  ISSUING_COUNTRY: "ISSUING_COUNTRY",
  OTHER_PPT_COUNTRY: "OTHER_PPT_COUNTRY",
  RELATIONSHIP_STATUSES: "RELATIONSHIP_STATUSES",
  OTHER_TRAVEL_DOCUMENT_TYPES: "OTHER_TRAVEL_DOCUMENT_TYPES",
  IDENTITY_DOCUMENT_TYPES: "IDENTITY_DOCUMENT_TYPES",
  BEEN_CHARGED: "BEEN_CHARGED",
  BEEN_CONVICTED: "BEEN_CONVICTED",
  STATES_BY_COUNTRY: "STATES_BY_COUNTRY",
  NATIONAL_ID_ISSUING_COUNTRY: "NATIONAL_ID_ISSUING_COUNTRY",
  CITIZEN_OTHER_COUNTRIES: "CITIZEN_OTHER_COUNTRIES",
  RESIDENCE_COUNTRIES: "RESIDENCE_COUNTRIES",
  POSTAL_ADDRESS_COUNTRIES: "POSTAL_ADDRESS_COUNTRIES",
  OTHER_ID_DOC_COUNTRY: "OTHER_ID_DOC_COUNTRY",
};

const ETA_TYPE_OPTIONS = {
  0: "Tourist",
  1: "Business",
};

export const ausSchema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      applicantNumber: {
        label: "Applicant number",
        type: TYPES.TEXT,
      },
      siteId: {
        label: "Referral Site",
      },
      orderId: {
        label: "Order ID",
      },
      orderIdHistory:{
        label:"Order ID History"
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      botRuns: {
        label: "Number of bot runs",
      },
      userEmail: {
        label: "User contact email",
        type: TYPES.EMAIL,
      },
      submissionEmail: {
        label: "User submission email",
        type: TYPES.TEXT,
      },
    },
  },
  personalInfoSegment: {
    label: "Personal information",
    type: TYPES.SEGMENT,
    data: {
      firstName: {
        label: "First name",
        type: TYPES.TEXT,
      },
      lastName: {
        label: "Family name",
        type: TYPES.TEXT,
      },
      gender: {
        label: "Gender",
        type: TYPES.SELECT,
        values: AUS_SELECT_VALUES.GENDERS,
      },
      hasOtherNamesOrSpellings: {
        label: "Has additional names",
        type: TYPES.CHECKBOX,
      },
      otherNamesOrSpellings: {
        label: "Additional names info",
        type: TYPES.ARRAY,
        dependsOn: "hasOtherNamesOrSpellings",
        data: {
          givenNames: {
            label: "First name",
            type: TYPES.TEXT,
            path: "otherNamesOrSpellings.$otherNamesOrSpellings",
          },
          familyName: {
            label: "Last name",
            type: TYPES.TEXT,
            path: "otherNamesOrSpellings.$otherNamesOrSpellings",
          },
          reasonForChange: {
            label: "Reason for other name",
            type: TYPES.SELECT,
            values: AUS_SELECT_VALUES.REASON_FOR_NAME_CHANGE,
            path: "otherNamesOrSpellings.$otherNamesOrSpellings",
          },
          giveDetails: {
            label: "Explain reason other",
            type: TYPES.TEXT,
            dependsOn: "otherNamesOrSpellings.$otherNamesOrSpellings.reasonForChange",
            dependantOnValues: ["O"],
            path: "otherNamesOrSpellings.$otherNamesOrSpellings",
          },
        },
      },
      birthCountry: {
        label: "Birth country",
        type: TYPES.SELECT,
        values: AUS_SELECT_VALUES.BIRTH_COUNTRIES,
      },
      birthState: {
        label: "State of birth",
        type: TYPES.TEXT,
      },
      birthCity: {
        label: "City of birth",
        type: TYPES.TEXT,
      },
      birthDate: {
        label: "Birth date",
        type: TYPES.DATE,
      },
      relationshipStatus: {
        label: "Relationship status",
        type: TYPES.SELECT,
        values: AUS_SELECT_VALUES.RELATIONSHIP_STATUSES,
      },
      isTourist: {
        label: "ETA Type",
        type: TYPES.SELECT,
        values: ETA_TYPE_OPTIONS,
      },
      travelReasonDetails: {
        label: "Travel reason details",
        type: TYPES.TEXT,
      },
    },
  },
  passportInfoSegment: {
    label: "Passport information",
    type: TYPES.SEGMENT,
    data: {
      passportNumber: {
        label: "Passport number",
        type: TYPES.TEXT,
      },
      issuingAuthority: {
        label: "Issuing authority",
        type: TYPES.TEXT,
      },
      passportCountry: {
        label: "Issuing country",
        type: TYPES.SELECT,
        values: AUS_SELECT_VALUES.ISSUING_COUNTRY,
      },
      nationality: {
        label: "Nationality",
        type: TYPES.SELECT,
        values: AUS_SELECT_VALUES.NATIONALITY,
      },
      hasNationalId: {
        label: "Has National ID",
        type: TYPES.CHECKBOX,
      },
      nationalIdInfo: {
        label: "National ID",
        type: TYPES.ARRAY,
        dependsOn: "hasNationalId",
        data: {
          familyName: {
            label: "Last Name",
            type: TYPES.TEXT,
            path: "nationalIdInfo.$nationalIdInfo",
          },
          givenNames: {
            label: "First Name",
            type: TYPES.TEXT,
            path: "nationalIdInfo.$nationalIdInfo",
          },
          identificationNumber: {
            label: "Identification number",
            type: TYPES.TEXT,
            path: "nationalIdInfo.$nationalIdInfo",
          },
          countryOfIssue: {
            label: "Country of issue",
            type: TYPES.SELECT,
            values: AUS_SELECT_VALUES.NATIONAL_ID_ISSUING_COUNTRY,
            path: "nationalIdInfo.$nationalIdInfo",
          },
          issuanceDate: {
            label: "Issuance date",
            type: TYPES.DATE,
            path: "nationalIdInfo.$nationalIdInfo",
          },
          expiryDate: {
            label: "Expiry date",
            type: TYPES.DATE,
            path: "nationalIdInfo.$nationalIdInfo",
          },
        },
      },
      issuanceDate: {
        label: "Issuance date",
        type: TYPES.DATE,
      },
      expiryDate: {
        label: "Expiry date",
        type: TYPES.DATE,
      },
      hasGrantNumber: {
        label: "Has grant number",
        type: TYPES.CHECKBOX,
      },
      grantNumber: {
        label: "Grant number",
        type: TYPES.TEXT,
        dependsOn: "hasGrantNumber",
      },
      isCitizenPassportCountry: {
        label: "Is applicant a citizen of selected issuing country",
        type: TYPES.CHECKBOX,
      },
      isCitizenOtherCountry: {
        label: "Is the applicant a citizen of any other country?",
        type: TYPES.CHECKBOX,
      },
      citizenOtherCountriesList: {
        label: "Other citizenships",
        type: TYPES.MULTI_SELECT,
        dependsOn: "isCitizenOtherCountry",
        values: AUS_SELECT_VALUES.CITIZEN_OTHER_COUNTRIES,
        selectType: "number",
      },
      isStateless: {
        label: "Is the applicant currently stateless?",
        type: TYPES.CHECKBOX,
        dependsOn: ["isCitizenPassportCountry", "isCitizenOtherCountry"],
        dependantOnValues: [false, false],
      },
      statelessDetails: {
        label: "No citizenship, no stateless details",
        type: TYPES.TEXT,
        dependsOn: [
          "isCitizenPassportCountry",
          "isCitizenOtherCountry",
          "isStateless",
        ],
        dependantOnValues: [false, false, false],
      },
      otherCurrentPpt: {
        label: "Other current valid passports",
        type: TYPES.CHECKBOX,
      },
      otherPptInfo: {
        label: "Other passports information",
        type: TYPES.ARRAY,
        dependsOn: "otherCurrentPpt",
        data: {
          typeOfDocument: {
            label: "Document type",
            type: TYPES.SELECT,
            values: AUS_SELECT_VALUES.OTHER_TRAVEL_DOCUMENT_TYPES,
            path: "otherPptInfo.$otherPptInfo",
          },
          passportNumber: {
            label: "Passport Number",
            type: TYPES.TEXT,
            path: "otherPptInfo.$otherPptInfo",
            dependsOn: "otherPptInfo.$otherPptInfo.typeOfDocument",
            dependantOnValues: ["01"],
          },
          documentNumber: {
            label: "Document Number",
            type: TYPES.TEXT,
            path: "otherPptInfo.$otherPptInfo",
            dependsOn: "otherPptInfo.$otherPptInfo.typeOfDocument",
            dependantOnValues: ["04", "98"],
          },
          nationalityOfDocumentHolder: {
            label: "Nationality",
            type: TYPES.SELECT,
            values: AUS_SELECT_VALUES.OTHER_PPT_COUNTRY,
            path: "otherPptInfo.$otherPptInfo",
          },
          countryOfIssue: {
            label: "Issuing country",
            type: TYPES.SELECT,
            values: AUS_SELECT_VALUES.OTHER_PPT_COUNTRY,
            path: "otherPptInfo.$otherPptInfo",
            dependsOn: "otherPptInfo.$otherPptInfo.typeOfDocument",
            dependantOnValues: ["01"],
          },
          placeOfIssue: {
            label: "Issuing authority",
            type: TYPES.TEXT,
            path: "otherPptInfo.$otherPptInfo",
            dependsOn: "otherPptInfo.$otherPptInfo.typeOfDocument",
            dependantOnValues: ["01", "98"],
          },
          issuanceDate: {
            label: "Issuance date",
            type: TYPES.DATE,
            path: "otherPptInfo.$otherPptInfo",
            dependsOn: "otherPptInfo.$otherPptInfo.typeOfDocument",
            dependantOnValues: ["01"],
          },
          expiryDate: {
            label: "Expiry date",
            type: TYPES.DATE,
            path: "otherPptInfo.$otherPptInfo",
            dependsOn: "otherPptInfo.$otherPptInfo.typeOfDocument",
            dependantOnValues: ["01", "98"],
          },
          givenNames: {
            label: "First name",
            type: TYPES.TEXT,
            path: "otherPptInfo.$otherPptInfo",
          },
          familyName: {
            label: "Family name",
            type: TYPES.TEXT,
            path: "otherPptInfo.$otherPptInfo",
          },
          dateOfBirth: {
            label: "Birth date",
            type: TYPES.DATE,
            path: "otherPptInfo.$otherPptInfo",
          },
          gender: {
            label: "Gender",
            type: TYPES.SELECT,
            values: AUS_SELECT_VALUES.GENDERS,
            path: "otherPptInfo.$otherPptInfo",
            dependsOn: "otherPptInfo.$otherPptInfo.typeOfDocument",
            dependantOnValues: ["01", "98"],
          },
        },
      },
      hasOtherIdentityDocuments: {
        label: "Has other identity documents",
        type: TYPES.CHECKBOX,
      },
      otherIdentityDocumentsInfo: {
        label: "Other identity documents",
        type: TYPES.ARRAY,
        dependsOn: "hasOtherIdentityDocuments",
        data: {
          givenNames: {
            label: "First name",
            type: TYPES.TEXT,
            path: "otherIdentityDocumentsInfo.$otherIdentityDocumentsInfo",
          },
          familyName: {
            label: "Family name",
            type: TYPES.TEXT,
            path: "otherIdentityDocumentsInfo.$otherIdentityDocumentsInfo",
          },
          identificationNumber: {
            label: "Identification number",
            type: TYPES.TEXT,
            path: "otherIdentityDocumentsInfo.$otherIdentityDocumentsInfo",
          },
          typeOfDocument: {
            label: "Document type",
            type: TYPES.SELECT,
            values: AUS_SELECT_VALUES.IDENTITY_DOCUMENT_TYPES,
            path: "otherIdentityDocumentsInfo.$otherIdentityDocumentsInfo",
          },
          countryOfIssue: {
            label: "Issuing country",
            type: TYPES.SELECT,
            values: AUS_SELECT_VALUES.OTHER_ID_DOC_COUNTRY,
            path: "otherIdentityDocumentsInfo.$otherIdentityDocumentsInfo",
          },
        },
      },
    },
  },
  contactInfoSegment: {
    label: "Contact information",
    type: TYPES.SEGMENT,
    data: {
      currentLocation: {
        label: "Country of residence",
        type: TYPES.SELECT,
        values: AUS_SELECT_VALUES.CURRENT_LOCATION,
      },
      residentalAddress: {
        label: "Address",
        note: "Parts of this section will be used to verify the client's ID on Calls/Chats",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          country: {
            label: "Country",
            type: TYPES.SELECT,
            values: AUS_SELECT_VALUES.RESIDENCE_COUNTRIES,
            path: "residentalAddress",
          },
          state: {
            label: "State",
            path: "residentalAddress",
            type: TYPES.DEPENDENT_SELECT,
            values: AUS_SELECT_VALUES.STATES_BY_COUNTRY,
            dependsOn: "residentalAddress.country",
            dependantOnValues: COUNTRIES_WITH_STATES,
          },
          stateText: {
            label: "State",
            type: TYPES.NESTED,
            customDependency: (applicant) => {
              if (!COUNTRIES_WITH_STATES.includes(applicant?.residentalAddress?.country)) return true;
            },
            nested: {
              state: {
                type: TYPES.TEXT,
                path: "residentalAddress",
              },
            },
          },
          address: {
            label: "Street address",
            type: TYPES.TEXT,
            path: "residentalAddress",
          },
          town: {
            label: "City",
            type: TYPES.TEXT,
            path: "residentalAddress",
          },
          postalCode: {
            label: "Zip code",
            type: TYPES.TEXT,
            path: "residentalAddress",
          },
        },
      },
      isPostalAddressSame: {
        label: "Is postal address same",
        type: TYPES.CHECKBOX,
      },
      postalAddress: {
        label: "Address",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "isPostalAddressSame",
        dependantOnValues: [false],
        nested: {
          country: {
            label: "Country",
            type: TYPES.SELECT,
            values: AUS_SELECT_VALUES.POSTAL_ADDRESS_COUNTRIES,
            path: "postalAddress",
          },
          state: {
            label: "State",
            path: "postalAddress",
            type: TYPES.DEPENDENT_SELECT,
            values: AUS_SELECT_VALUES.STATES_BY_COUNTRY,
            dependsOn: "postalAddress.country",
            dependantOnValues: COUNTRIES_WITH_STATES,
          },
          stateText: {
            label: "State",
            type: TYPES.NESTED,
            customDependency: (applicant) => {
              if (!COUNTRIES_WITH_STATES.includes(applicant?.postalAddress?.country)) return true;
            },
            nested: {
              state: {
                type: TYPES.TEXT,
                path: "postalAddress",
              },
            },
          },
          address: {
            label: "Street address",
            type: TYPES.TEXT,
            path: "postalAddress",
          },
          town: {
            label: "City",
            type: TYPES.TEXT,
            path: "postalAddress",
          },
          postalCode: {
            label: "Zip code",
            type: TYPES.TEXT,
            path: "postalAddress",
          },
        },
      },
      homeTelephone: {
        label: "Home telephone",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          countryCode: {
            label: "Country",
            type: TYPES.SELECT,
            values: AUS_SELECT_VALUES.PHONE_CODES_KEY_VALUE_OBJECT,
            path: "homeTelephone",
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "homeTelephone",
          },
        },
      },
      businessTelephone: {
        label: "Business telephone",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          countryCode: {
            label: "Country",
            type: TYPES.SELECT,
            values: AUS_SELECT_VALUES.PHONE_CODES_KEY_VALUE_OBJECT,
            path: "businessTelephone",
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "businessTelephone",
          },
        },
      },
      mobileTelephone: {
        label: "Mobile phone",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          countryCode: {
            label: "Country",
            type: TYPES.SELECT,
            values: AUS_SELECT_VALUES.PHONE_CODES_KEY_VALUE_OBJECT,
            path: "mobileTelephone",
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "mobileTelephone",
          },
        },
      },
    },
  },
  securityQuestionsSegment: {
    label: "Security questions",
    type: TYPES.SEGMENT,
    data: {
      // criminalConviction: {
      //   label: "Applicant is criminaly convicted",
      //   type: TYPES.CHECKBOX,
      // },
      hasBeenCharged: {
        label: "Applicant is charged with offence",
        type: TYPES.CHECKBOX,
      },
      chargeList: {
        label: "Offence details",
        type: TYPES.ARRAY,
        dependsOn: "hasBeenCharged",
        data: {
          offenceType: {
            label: "Offence type",
            type: TYPES.SELECT,
            values: AUS_SELECT_VALUES.BEEN_CHARGED,
            path: "chargeList.$chargeList",
          },
          otherType: {
            label: "Other offence type",
            type: TYPES.TEXT,
            dependsOn: "chargeList.$chargeList.offenceType",
            dependantOnValues: ["17"],
            path: "chargeList.$chargeList",
          },
          dateOfOffence: {
            label: "Date of offence",
            type: TYPES.DATE,
            path: "chargeList.$chargeList",
          },
          description: {
            label: "Offence description",
            type: TYPES.TEXT,
            path: "chargeList.$chargeList",
          },
        },
      },
      hasBeenConvicted: {
        label: "Applicant is convicted of offence",
        type: TYPES.CHECKBOX,
      },
      convictList: {
        label: "Conviction details",
        type: TYPES.ARRAY,
        dependsOn: "hasBeenConvicted",
        data: {
          offenceType: {
            label: "Offence type",
            type: TYPES.SELECT,
            values: AUS_SELECT_VALUES.BEEN_CONVICTED,
            path: "convictList.$convictList",
          },
          otherType: {
            label: "Other offence type",
            type: TYPES.TEXT,
            dependsOn: "convictList.$convictList.offenceType",
            dependantOnValues: ["17"],
            path: "convictList.$convictList",
          },
          dateOfConvict: {
            label: "Date of conviction",
            type: TYPES.DATE,
            path: "convictList.$convictList",
          },
          description: {
            label: "Conviction description",
            type: TYPES.TEXT,
            path: "convictList.$convictList",
          },
        },
      },
      hasBeenSubjectOfViolence: {
        label: "Applicant is subject of violence",
        type: TYPES.CHECKBOX,
      },
      violenceList: {
        label: "Violence details",
        type: TYPES.ARRAY,
        dependsOn: "hasBeenSubjectOfViolence",
        data: {
          dateOfRaise: {
            label: "Date order raised",
            type: TYPES.DATE,
            path: "violenceList.$violenceList",
          },
          description: {
            label: "Violence description",
            type: TYPES.TEXT,
            path: "violenceList.$violenceList",
          },
        },
      },
    },
  },
};
