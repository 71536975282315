import axios from "../httpClient";

function getDocumentUploadConfig() {
  return axios.get(`/document-upload-configuration`);
}

function editDocumentUploadConfiguration(body) {
  return axios.put(`/document-upload-configuration`, body);
}

export default {
  getDocumentUploadConfig,
  editDocumentUploadConfiguration,
};
