import React, { useState } from "react";
import ConfirmPopup from "../../../../../../../../../components/ConfirmPopup";
import { API_NAMES } from "../../../constants/apiNames";
import { validateApplicationApi } from "../../../../../../../../../apis/applicantApi";
import LoadingModalContent from "./common/LoadingModalContent";
import {
  VALIDATION_TYPES,
  VALIDATION_STYLE_MAP,
} from "../../../../../../../../../constants/applicationValidation";
import GeneralModalMessage from "./common/GeneralModalMessage";
import {
  Segment,
  Button,
  HeaderContent,
  Header,
  Icon,
  Divider,
  PlaceholderParagraph,
  PlaceholderLine,
  PlaceholderHeader,
  Placeholder,
  ListItem,
  List,
} from "semantic-ui-react";

const showValidationOnProducts = [API_NAMES.DS11, API_NAMES.DS82];

function ValidateApplication({ applicantId, api }) {
  const [content, setContent] = useState(null);
  const shouldRender = showValidationOnProducts.includes(api);

  const validateApplication = async () => {
    // Show loading
    setContent(defaultContent);

    try {
      const { data } = await validateApplicationApi(applicantId, api);

      setContent(formatContentFromResponse(data));
      if (!data) {
        setContent(
          <GeneralModalMessage
            message="We could not retrieve validation details for this application"
            type={VALIDATION_TYPES.ERROR}
          />,
        );
      }
    } catch (error) {
      console.log(error);
      setContent(
        <GeneralModalMessage
          message={`We could not retrieve validation details for this application. Error: ${error.message}`}
          type={VALIDATION_TYPES.ERROR}
        />,
      );
    }
  };

  const groupValidationResults = (validationResults) => {
    const groupedByType = validationResults.reduce((acc, current) => {
      // Check if the array already has the key for the type
      if (!acc[current.type]) {
        acc[current.type] = [];
      }
      // Push the current item into the appropriate type array
      acc[current.type].push(current);
      return acc;
    }, {});

    return groupedByType;
  };

  const formatContentFromResponse = (data) => {
    const { errors: validationResults = [] } = data;
    const results = groupValidationResults(validationResults);

    if (Object.keys(results).length === 0) {
      return (
        <GeneralModalMessage
          message="No application validations were found!"
          type={VALIDATION_TYPES.INFORMATION}
        />
      );
    }

    return (
      <>
        <Header as="h2">
          <HeaderContent>Validation Results</HeaderContent>
        </Header>

        <Segment padded basic>
          {Object.keys(results).map((type) => {
            const { color, icon, name } =
              VALIDATION_STYLE_MAP[type] ||
              VALIDATION_STYLE_MAP[VALIDATION_TYPES.ERROR];

            // TODO: refactor this to use GeneralModalMessage
            return (
              <>
                <Segment color={color} key={`${type}`}>
                  <Header as="h3" color={color}>
                    <Icon name={icon} />
                    <HeaderContent>{name}</HeaderContent>
                  </Header>
                  <List bulleted>
                    {results[type].map(({ message }) => (
                      <ListItem key={`${type}-${message}`}>{message}</ListItem>
                    ))}
                  </List>
                </Segment>
                <Divider hidden key={`${type}-divider`} />
              </>
            );
          })}
        </Segment>
      </>
    );
  };

  const defaultContent = (
    <Segment>
      <LoadingModalContent message="Validating Application..." />

      <Placeholder fluid>
        <PlaceholderHeader image>
          <PlaceholderLine />
          <PlaceholderLine />
        </PlaceholderHeader>
        <PlaceholderParagraph>
          <PlaceholderLine />
          <PlaceholderLine />
          <PlaceholderLine />
        </PlaceholderParagraph>
      </Placeholder>
    </Segment>
  );

  return (
    <div>
      {shouldRender && (
        <ConfirmPopup
          onOpen={validateApplication}
          content={content || defaultContent}
        >
          <Button primary>Validate Application</Button>
        </ConfirmPopup>
      )}
    </div>
  );
}

export default ValidateApplication;
