import React, { useEffect, useState, useMemo } from "react";
import { Chart, PerformanceTrendQAReport } from "..";
import "./OverallQAReport.styles.scss";
import { QA_REPORTS, QA_REPORT_FOR } from "../../../../constants/qaReports";
import { formatSecondsToMinutes } from "../../../../../../../../../common/date-format";
import classNames from "classnames";

const OverallQAReport = ({ type, agentData, chartConfig }) => {
  const [reportData, setReportData] = useState({});
  const [reportDataSeparated, setReportDataSeparated] = useState({});
  const [openPerformanceTrend, setOpenPerformanceTrend] = useState(false);

  const hasAgentData = useMemo(() => {
    switch (type) {
      case QA_REPORT_FOR.AGENT:
        return agentData.totalSubmitted > 0;
      case QA_REPORT_FOR.MANAGER:
        return agentData.totalEvaluations > 0;
      case QA_REPORT_FOR.QA_AGENT:
      case QA_REPORT_FOR.QA_MANAGER:
        return agentData.evaluationsTotal > 0;
      default:
        break;
    }
  }, [agentData, type]);

  const isAgent = useMemo(() => type === QA_REPORT_FOR.AGENT, [type]);
  const isManager = useMemo(() => type === QA_REPORT_FOR.MANAGER, [type]);

  useEffect(() => {
    if (hasAgentData) {
      let reportData = {};
      let reportDataSeparated = {};

      switch (type) {
        case QA_REPORT_FOR.AGENT:
          reportData = {
            positive: agentData.totalPositive,
            negative: agentData.totalNegative,
          };
          reportDataSeparated = {
            "Positive Reviews": agentData.totalPositiveSeparated,
            "Negative Reviews": agentData.totalNegativeSeparated,
          };
          reportData.values = [reportData.positive, reportData.negative];
          reportData.labels = ["Positive Reviews", "Negative Reviews"];
          break;

        case QA_REPORT_FOR.MANAGER:
          reportData = {
            positive: agentData.totalSubmittedEvaluations,
            negative: agentData.totalNotSubmittedEvaluations,
          };
          reportDataSeparated = {
            Reviewed: agentData.totalSubmittedEvaluationsSeparated,
            "Not Reviewed": agentData.totalNotSubmittedEvaluationsSeparated,
          };
          reportData.values = [reportData.positive, reportData.negative];
          reportData.labels = ["Reviewed", "Not Reviewed"];
          break;

        case QA_REPORT_FOR.QA_AGENT:
        case QA_REPORT_FOR.QA_MANAGER:
          reportData = {
            avgReviewTime: formatSecondsToMinutes(agentData.avgReviewTime),
            avgTicketsPerHour: Math.floor(agentData.avgTicketsPerHour) || 0,
            total: agentData.agentsTotal,
            positive: agentData.submittedEvaluationsCount,
            negative: agentData.notFinishedEvaluationsCount,
          };
          reportDataSeparated = {
            Reviewed: agentData.submittedEvaluationsCountSeparated,
            "Not Reviewed": agentData.notFinishedEvaluationsCountSeparated,
          };
          reportData.values = [reportData.positive, reportData.negative];
          reportData.labels = ["Reviewed", "Not Reviewed"];
          break;
        default:
          break;
      }
      setReportData(reportData);
      setReportDataSeparated(reportDataSeparated);
    }
  }, [agentData, type, hasAgentData]);

  const renderForType = () => {
    let render = null;
    switch (type) {
      case QA_REPORT_FOR.AGENT:
        render = (
          <h2>
            Overall Reviews: {reportData.positive + reportData.negative || 0}
          </h2>
        );
        break;
      case QA_REPORT_FOR.QA_AGENT:
      case QA_REPORT_FOR.QA_MANAGER:
        render = (
          <React.Fragment>
            <h2>Interactions Reviewed: {reportData.total || 0}</h2>
            <h2>Average Ticket Review Time: {reportData.avgReviewTime || 0}</h2>
            <h2>
              Average Tickets Reviewed/hour: {reportData.avgTicketsPerHour || 0}
            </h2>
          </React.Fragment>
        );
        break;
      default:
        break;
    }
    return render;
  };
  const getChartTitle = () => {
    switch (type) {
      case QA_REPORT_FOR.AGENT:
        return "Support QA Success Ratio";
      case QA_REPORT_FOR.MANAGER:
      case QA_REPORT_FOR.QA_AGENT:
      case QA_REPORT_FOR.QA_MANAGER:
        return "Review Ratio";

      default:
        break;
    }
  };
  return (
    <div
      className={classNames("overall-qa-report", {
        "overall-qa-report--column": !isAgent,
        "overall-qa-report--none": isManager,
      })}
    >
      <div className="overall-qa-report__overall-reviews">
        {renderForType()}
      </div>
      <div
        className="overall-qa-report__success-ratio"
        onClick={() => setOpenPerformanceTrend(true)}
        title="Show Performance Trend"
      >
        <h2>{getChartTitle()}</h2>
        {hasAgentData ? (
          <>
            <Chart
              chartId={QA_REPORTS.OVERALL_REPORT}
              chartConfig={chartConfig}
              chartData={reportData.values}
              chartLabels={reportData.labels}
            />
            <PerformanceTrendQAReport
              open={openPerformanceTrend}
              setOpen={setOpenPerformanceTrend}
              qaReportData={reportDataSeparated}
              performanceTrendName="overall qa report"
            />
          </>
        ) : (
          <h4>No Review Data</h4>
        )}
      </div>
    </div>
  );
};

export default OverallQAReport;
