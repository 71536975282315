import React, { useEffect, useMemo } from "react";
import { Dropdown, Menu } from "semantic-ui-react";
import { useSelector } from "react-redux";
import Spinner from "../../../Spinner";
import { TwilioInitMenu } from "..";
import BotMonitoring from "../BotMonitoring";
import { emitLogout } from "../../../../events";
import TextIcon from "../../../TextIcon";
import { NavLink } from "react-router-dom";

import "./ZendeskTopMenu.styles.scss";

const productOptions = [
  { key: 'ds160', value: 'ds160',  text: 'U.S. Visa', onClick: () => window.location.pathname = '/zendesk/ds160' },
  { key: 'esta', value: 'esta',  text: 'ESTA', onClick: () => window.location.pathname = '/zendesk/esta' },
  { key: 'eta', value: 'eta',  text: 'Canada ETA', onClick: () => window.location.pathname = '/zendesk/eta' },
  { key: 'schengen', value: 'schengen',  text: 'Schengen Visa', onClick: () => window.location.pathname = '/zendesk/schengen' },
  { key: 'diversity', value: 'diversity',  text: 'Diversity VISA', onClick: () => window.location.pathname = '/zendesk/aus' },
  { key: 'nz', value: 'nz',  text: 'New Zealand ETA', onClick: () => window.location.pathname = '/zendesk/nz' },
  { key: 'trk', value: 'trk',  text: 'Turkey ETA', onClick: () => window.location.pathname = '/zendesk/trk' },
  { key: 'ind', value: 'ind',  text: 'India ETA', onClick: () => window.location.pathname = '/zendesk/ind' },
  { key: 'vn', value: 'vn',  text: 'Vietnam ETA', onClick: () => window.location.pathname = '/zendesk/vn' },
  { key: 'aus', value: 'aus',  text: 'Australia ETA', onClick: () => window.location.pathname = '/zendesk/aus' },
  { key: 'inv', value: 'inv',  text: 'Invitation Letter', onClick: () => window.location.pathname = '/zendesk/inv_letter' },
  { key: 'ge', value: 'ge',  text: 'Global Entry', onClick: () => window.location.pathname = '/zendesk/ge' },
  { key: 'sg', value: 'sg',  text: 'Singapore ETA', onClick: () => window.location.pathname = '/zendesk/sg' },
  { key: 'ds11', value: 'ds11',  text: 'DS11 - Passport Service', onClick: () => window.location.pathname = '/zendesk/ds11' },
  { key: 'ds82', value: 'ds82',  text: 'DS82 - Passport Service', onClick: () => window.location.pathname = '/zendesk/ds82' },
  { key: 'ds64', value: 'ds64',  text: 'DS64 - Passport Service', onClick: () => window.location.pathname = '/zendesk/ds64' },
  { key: 'sl', value: 'sl',  text: 'Sri Lanka ETA', onClick: () => window.location.pathname = '/zendesk/sl' },
  { key: 'tsa', value: 'tsa',  text: 'TSA Pre-Check', onClick: () => window.location.pathname = '/zendesk/tsa' },
  { key: 'sentri', value: 'sentri', text: 'SENTRI', onClick: () => window.location.pathname = '/zendesk/sentri' },
  { key: 'nexus', value: 'nexus',  text: 'NEXUS', onClick: () => window.location.pathname = '/zendesk/nexus' },
]

export const setRootClassAttribute = (rootClass) => {
  var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
  root.setAttribute("class", rootClass);
};

const ZendeskTopMenu = () => {
  const { user } = useSelector((state) => ({
    user: state.currentUser,
  }));

  useEffect(() => {
    user && setRootClassAttribute("zendesk");
  }, [user]);

  const renderTopMenu = useMemo(() => {
    if (user === null) {
      return <Spinner />;
    } else {
      return (
        <Menu fixed="top" className="zendesk-top-menu">
          <Menu.Item className="zendesk-top-menu__no-border">
            <Dropdown
              placeholder='GRS Products'
              search
              selection
              options={productOptions}
            />
          </Menu.Item>
          <Menu.Item className="zendesk-top-menu__no-border">
            <NavLink to="/zendesk/interview-dates" className="ui button blue">
              Interview dates
            </NavLink>
          </Menu.Item>

          <div className="zendesk-top-menu__header-right">
            <Menu.Item className="zendesk-top-menu__no-border">
              <TwilioInitMenu />
            </Menu.Item>

            <Menu.Item>
              <BotMonitoring />
            </Menu.Item>

            <Menu.Item
              header
              className="zendesk-top-menu__header-right__logged-as"
            >
              Logged in as:
              <span className="zendesk-top-menu__header-right__logged-as__user">
                {user.username}
              </span>
            </Menu.Item>

            <Menu.Item
              name="sign-out"
              className="zendesk-top-menu__header-right__log-out"
              onClick={() => {
                emitLogout(true);
              }}
            >
              <TextIcon name={"sign-out"}>Log out</TextIcon>
            </Menu.Item>
          </div>
        </Menu>
      );
    }
  }, [user]);

  return renderTopMenu;
};

export default ZendeskTopMenu;
