import React, { Component } from "react";
import { Tab, Form, TextArea, Button, Loader } from "semantic-ui-react";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import { Prompt } from "react-router";
import "./ZendeskConfiguration.scss";
import { isEqual, cloneDeep } from "lodash";
import {
  ZENDESK_ENDPOINT_PER_PRODUCT,
  PRODUCT_NAMES_MAPPING,
  filterInvalidProductProps,
  FILTERED_PRODUCTS,
} from "../../../../routers/Products/constants/productNames";
import apis from "../../../../../../../apis";

const ITEMS = filterInvalidProductProps(
  Object.keys(ZENDESK_ENDPOINT_PER_PRODUCT).reduce(
    (items, api) => ({
      ...items,
      [api]: PRODUCT_NAMES_MAPPING[api],
    }),
    {},
  ),
);

function getAssocObjectKeyByIndex(object, index) {
  return Object.keys(object).find((key, ind) => ind === index);
}

class ZendeskConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      configurations: null,
      originalConfigurations: null,
      activeApi: FILTERED_PRODUCTS[0],
    };

    this.initPanes();
  }

  componentDidMount() {
    this.getConfigurations();
  }

  async getConfigurations() {
    const {
      data,
    } = await apis.configuration.zendeskConfig.getZendeskTicketConfiguration();
    this.setState({
      configurations: cloneDeep(data),
      originalConfigurations: cloneDeep(data),
    });
    return data;
  }

  async saveChanges() {
    const { activeApi, originalConfigurations, configurations } = this.state;
    const config = configurations[activeApi];
    const {
      data,
    } = apis.configuration.zendeskConfig.updateZendeskTicketConfiguration(
      activeApi,
      config,
    );
    this.setState({
      configurations: {
        ...configurations,
        [activeApi]: cloneDeep(data[activeApi]),
      },
      originalConfigurations: {
        ...originalConfigurations,
        [activeApi]: cloneDeep(data[activeApi]),
      },
    });
    return data;
  }

  discardChanges() {
    const { activeApi, originalConfigurations, configurations } = this.state;

    this.setState({
      configurations: {
        ...configurations,
        [activeApi]: cloneDeep(originalConfigurations[activeApi]),
      },
    });
  }

  initPanes() {
    this.panes = Object.keys(ITEMS).map((label) => ({
      menuItem: ITEMS[label],
      render: () => this.renderEditor(),
    }));
  }

  get shouldBlockNavigation() {
    const { originalConfigurations, configurations } = this.state;

    return (
      originalConfigurations !== null &&
      configurations !== null &&
      !isEqual(originalConfigurations, configurations)
    );
  }

  renderEditor() {
    const { configurations, activeApi } = this.state;

    return (
      <React.Fragment>
        <Prompt
          when={this.shouldBlockNavigation}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Form>
          <TextArea
            placeholder="Write initial text here!"
            value={configurations[activeApi].initialText}
            className="ticket-input"
            onChange={(event, field) =>
              this.setState({
                configurations: {
                  ...configurations,
                  [activeApi]: {
                    ...configurations[activeApi],
                    initialText: field.value,
                  },
                },
              })
            }
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "12px",
            }}
          >
            <ConfirmPopup
              content="Discard changes??"
              callback={() => {
                this.discardChanges();
              }}
            >
              <Button color="red" size="large">
                Discard
              </Button>
            </ConfirmPopup>
            <ConfirmPopup
              content="Are you sure you want to save changes?"
              callback={() => {
                this.saveChanges();
              }}
            >
              <Button color="green" size="large">
                Save
              </Button>
            </ConfirmPopup>
          </div>
        </Form>
      </React.Fragment>
    );
  }

  render() {
    const { activeIndex, originalConfigurations, configurations } = this.state;

    if (!configurations || !originalConfigurations) {
      return <Loader active />;
    }
    return (
      <div>
        <Tab
          menu={{ fluid: true, vertical: true }}
          activeIndex={activeIndex}
          panes={this.panes}
          onTabChange={(event, tab) =>
            this.setState({
              activeIndex: tab.activeIndex,
              activeApi: getAssocObjectKeyByIndex(ITEMS, tab.activeIndex),
            })
          }
          className="zendesk-configuration"
        />
      </div>
    );
  }
}

export default ZendeskConfiguration;
