import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";

export const VN_SELECT_VALUES = {
  GENDERS: "GENDERS",
  NATIONALITIES: "NATIONALITIES",
  PASSPORT_TYPE: "PASSPORT_TYPE",
  PURPOSE_OF_ENTRY: "PURPOSE_OF_ENTRY",
  CITIES: "CITIES",
  CHECKPOINT: "CHECKPOINT",
  WHO_WILL_COVER: "WHO_WILL_COVER",
  HAVE_HEALTH_INSURANCE: "HAVE_HEALTH_INSURANCE",
  NUMBER_OF_ENTRIES: "NUMBER_OF_ENTRIES",
  COVER_TYPE: "COVER_TYPE",
};

export const vnSchema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      applicantNumber: {
        label: "Applicant number",
        type: TYPES.TEXT,
      },
      siteId: {
        label: "Referral Site",
      },
      orderId: {
        label: "Order ID",
      },
      orderIdHistory:{
        label:"Order ID History"
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      botRuns: {
        label: "Number of bot runs",
      },
    }
  },
  personalInfoSegment: {
    label: "Personal information",
    type: TYPES.SEGMENT,
    data: {
      userEmail: {
        label: "User contact email",
        type: TYPES.EMAIL,
      },
      submissionEmail: {
        label: "Submission email",
        type: TYPES.EMAIL,
      },
      firstName: {
        label: "First name",
        type: TYPES.TEXT,
      },
      lastName: {
        label: "Family name",
        type: TYPES.TEXT,
      },
      gender: {
        label: "Gender",
        type: TYPES.SELECT,
        values: VN_SELECT_VALUES.GENDERS,
      },
      birthDate: {
        label: "Birth data",
        type: TYPES.DATE,
      },
      birthPlace: { 
        label: "Birth place", 
        type: TYPES.TEXT 
      },
      nationalityAtBirth: {
        label: "Nationality of birth",
        type: TYPES.SELECT,
        values: VN_SELECT_VALUES.NATIONALITIES,
      },
      nationality: {
        label: "Current nationality",
        type: TYPES.SELECT,
        values: VN_SELECT_VALUES.NATIONALITIES,
      },
      idCardNumber: {
        label: "Id card number",
        type: TYPES.TEXT,
      },
    }
  },
  passportInfoSegment: {
    label: "Passport information",
    type: TYPES.SEGMENT,
    data: {
      passportNumber: {
        label: "Passport number",
        type: TYPES.TEXT,
      },
      passportType: {
        label: "Passport type",
        type: TYPES.SELECT,
        values: VN_SELECT_VALUES.PASSPORT_TYPE,
      },
      passportTypeOther: {
        label: "Passport type other",
        type: TYPES.TEXT,
        dependsOn: "passportType",
        dependantOnValues: ['KH'],
      },
      passportIssueDate: {
         label: "Passport issue date",
          type: TYPES.DATE 
      },
      expiryDate: {
        label: "Passport expiration date",
        type: TYPES.DATE,
      },
      passportIssuingAuthority: {
        label: "Passport issuing authority",
        type: TYPES.TEXT,
      },
      usedOtherPassports: {
        label: "Used other passports", 
        type: TYPES.CHECKBOX 
      },
      otherPassports: {
        label: "Other passports",
        type: TYPES.ARRAY,
        dependsOn: "usedOtherPassports",
        dependantOnValues: [true],
        newTable: true,
        data: {
          passportNo: {
            label: "Passport number",
            type: TYPES.TEXT,
            path: "otherPassports.$otherPassports",
          },
          fullName: {
            label: "Full Name",
            type: TYPES.TEXT,
            path: "otherPassports.$otherPassports",
          },
          dateOfBirth: {
            label: "Date of birth",
            type: TYPES.DATE,
            path: "otherPassports.$otherPassports",
          },
          nationality: {
            label: "Nationality",
            type: TYPES.TEXT,
            path: "otherPassports.$otherPassports",
          },
        },
      },
      hasOtherValidPassport: {
        label: "Has other valid passport",
        type: TYPES.CHECKBOX,
      },
      otherValidPassport: {
        label: "Other valid passport",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "hasOtherValidPassport",
        nested: {
          passportType: {
            label: "Passport type",
            type: TYPES.SELECT,
            values: VN_SELECT_VALUES.PASSPORT_TYPE,
            path: "otherValidPassport",
          },
          passportTypeOther: {
            label: "Passport type other",
            type: TYPES.TEXT,
            path: "otherValidPassport",
            dependsOn: "otherValidPassport.passportType",
            dependantOnValues: ['KH'],
          },
          passportNumber: {
            label: "Passport number",
            type: TYPES.TEXT,
            path: "otherValidPassport",
          },
          passportIssuingAuthority: {
            label: "Passport issuing authority",
            type: TYPES.TEXT,
            path: "otherValidPassport",
          },
          passportIssueDate: {
            label: "Passport issue date",
            type: TYPES.DATE,
            path: "otherValidPassport",
          },
          expiryDate: {
            label: "Expiry date",
            type: TYPES.DATE,
            path: "otherValidPassport",
          },
        },
      },
      hasMultipleNationalities: {
        label: "Has multiple nationalities",
        type: TYPES.CHECKBOX,
      },
      multipleNationalities: {
        label: "Details",
        type: TYPES.TEXT,
        dependsOn: "hasMultipleNationalities",
      },
      hasLawViolations: {
        label: "Has law violations",
        type: TYPES.CHECKBOX 
      },
      lawViolations: {
        label: "Law violations",
        type: TYPES.ARRAY,
        dependsOn: "hasLawViolations",
        newTable: true,
        data: {
          actOfViolation: {
            label: "Act of violation",
            type: TYPES.TEXT,
            path: "lawViolations.$lawViolations",
          },
          timeOfViolation: {
            label: "Time of violation",
            type: TYPES.DATE,
            path: "lawViolations.$lawViolations",
          },
          formOfSanction: {
            label: "Form of sanction",
            type: TYPES.TEXT,
            path: "lawViolations.$lawViolations",
          },
          authorityImposedSanction: {
            label: "Authority imposed sanction",
            type: TYPES.TEXT,
            path: "lawViolations.$lawViolations",
          },
        },
      },
    },
  },
  travelInfoSegment: {
    label: "Travel information",
    type: TYPES.SEGMENT,
    data: {
      permanentResidentalAddress: {
        label: "Permanent residental address",
        note: "This will be used to verify the client's ID on Calls/Chats",
        type: TYPES.TEXT,
      },
      contactAddress: { 
        label: "Contact address", 
        type: TYPES.TEXT 
      },
      religion: {
        label: "Religion",
        type: TYPES.TEXT,
      },
      occupation: {
        label: "Occupation",
        type: TYPES.TEXT,
      },
      occupationInfo: {
        label: "Occupation info",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          nameOfCompanyOrSchool: {
            label: "Name of company or school",
            type: TYPES.TEXT,
            path: "occupationInfo",
          },
          address: { 
            label: "Address", 
            type: TYPES.TEXT, 
            path: "occupationInfo" },
          position: { 
            label: "Position", 
            type: TYPES.TEXT, 
            path: "occupationInfo" },
          telephoneNumber: {
            label: "Telephone number",
            type: TYPES.TEXT,
            path: "occupationInfo",
          },
        },
      },
      emergencyContact: {
        label: "Emergency contact",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          fullName: {
            label: "Full Name",
            type: TYPES.TEXT,
            path: "emergencyContact",
          },
          telephoneNumber: {
            label: "Telephone number",
            type: TYPES.TEXT,
            path: "emergencyContact",
          },
          currentResidentialAddress: {
            label: "Current residential address",
            type: TYPES.TEXT,
            path: "emergencyContact",
          },
          relationship: {
            label: "Relationship",
            type: TYPES.TEXT,
            path: "emergencyContact",
          },
        },
      },
      purposeOfEntry: {
        label: "Purpose of entry",
        type: TYPES.SELECT,
        values: VN_SELECT_VALUES.PURPOSE_OF_ENTRY,
      },
      lengthOfStay: {
        label: "Intended length of stay in Vietnam (number of days)",
        type: TYPES.TEXT,
      },
      entryDate: { 
        label: "Intended date of entry", 
        type: TYPES.DATE 
      },
      phoneNumber: {
        label: "Phone number (Vietnam)",
        type: TYPES.TEXT,
      },
      temporaryResidentalAddress: {
        label: "Intended temporary residental address in Vietnam",
        type: TYPES.TEXT,
      },
      city: {
        label: "City/town",
        type: TYPES.SELECT,
        values: VN_SELECT_VALUES.CITIES,
      },
      hasInvitingOrganization: {
        label: "Inviting/guaranteeing agency/organization (if any)",
        type: TYPES.CHECKBOX,
      },
      invitingOrganization: {
        label: "Inviting/guaranteeing agency/organization (if any)",
        type: TYPES.NESTED,
        dependsOn: "hasInvitingOrganization",
        dependantOnValues: [true],
        newTable: true,
        nested: {
          name: {
            label: "Name of hosting organization",
            type: TYPES.TEXT,
            path: "invitingOrganization",
          },
          address: {
            label: "Address",
            type: TYPES.TEXT,
            path: "invitingOrganization",
          },
          telephoneNumber: {
            label: "Telephone number",
            type: TYPES.TEXT,
            path: "invitingOrganization",
          },
          purpose: {
            label: "Purpose",
            type: TYPES.TEXT,
            path: "invitingOrganization",
          },
        },
      },
      hasAccompanyingChildren: {
        label: "Accompanying Children",
        type: TYPES.CHECKBOX,
      },
      accompanyChildren: {
        label: "Under 14 years old accompanying child(ren) included in your passport (if any)",
        type: TYPES.NESTED,
        dependsOn: "hasAccompanyingChildren",
        dependantOnValues: [true],
        newTable: true,
        nested: {
          firstChild: {
            label: "",
            type: TYPES.NESTED,
            newTable: true,
            nested: {
              fullName: {
                label: "Full Name",
                type: TYPES.TEXT,
                path: "accompanyChildren.firstChild",
              },
              gender: {
                label: "Gender",
                type: TYPES.TEXT,
                path: "accompanyChildren.firstChild",
              },
              birthDate: {
                label: "Birth date",
                type: TYPES.DATE,
                path: "accompanyChildren.firstChild",
              },
            },
          },
          secondChild: {
            label: "",
            type: TYPES.NESTED,
            newTable: true,
            nested: {
              fullName: {
                label: "Full Name",
                type: TYPES.TEXT,
                path: "accompanyChildren.secondChild",
              },
              gender: {
                label: "Gender",
                type: TYPES.TEXT,
                path: "accompanyChildren.secondChild",
              },
              birthDate: {
                label: "Birth date",
                type: TYPES.DATE,
                path: "accompanyChildren.secondChild",
              },
            },
          },
        },
      },
      haveBeenToVietnamInTheLastYear: {
        label: "Have been to Vietnam in the last year",
        type: TYPES.CHECKBOX,
      },
      visitsToVietnam: {
        label: "Visits to Vietnam",
        type: TYPES.ARRAY,
        newTable: true,
        dependsOn: "haveBeenToVietnamInTheLastYear",
        data: {
          from: {
            label: "From",
            type: TYPES.DATE,
            path: "visitsToVietnam.$visitsToVietnam",
          },
          to: {
            label: "To",
            type: TYPES.DATE,
            path: "visitsToVietnam.$visitsToVietnam",
          },
          purpose: {
            label: "Purpose",
            type: TYPES.TEXT,
            path: "visitsToVietnam.$visitsToVietnam",
          },
        },
      },
      haveRelativesInVietnam: {
        label: "Have relatives in Vietnam",
        type: TYPES.CHECKBOX,
      },
      relativesInVietnam: {
        label: "Relatives in Vietnam",
        type: TYPES.ARRAY,
        newTable: true,
        dependsOn: "haveRelativesInVietnam",
        data: {
          fullName: {
            label: "Full Name",
            type: TYPES.TEXT,
            path: "relativesInVietnam.$relativesInVietnam",
          },
          dateOfBirth: {
            label: "Date of birth",
            type: TYPES.DATE,
            path: "relativesInVietnam.$relativesInVietnam",
          },
          nationality: {
            label: "Nationality",
            type: TYPES.TEXT,
            path: "relativesInVietnam.$relativesInVietnam",
          },
          relationship: {
            label: "Relationship",
            type: TYPES.TEXT,
            path: "relativesInVietnam.$relativesInVietnam",
          },
          residentialAddress: {
            label: "Residental address",
            type: TYPES.TEXT,
            path: "relativesInVietnam.$relativesInVietnam",
          },
        },
      },
      tripExpenses: {
        label: "Trip expenses",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          intendedExpenses: {
            label: "Intended expenses",
            type: TYPES.TEXT,
            path: "tripExpenses",
          },
          whoWillCover: {
            label: "Who will cover",
            type: TYPES.SELECT,
            values: VN_SELECT_VALUES.WHO_WILL_COVER,
            path: "tripExpenses",
          },
          coverType: {
            label: "Cover type",
            type: TYPES.SELECT,
            values: VN_SELECT_VALUES.COVER_TYPE,
            path: "tripExpenses.applicantInfo",
            dependsOn: "tripExpenses.whoWillCover",
            dependantOnValues: ['CN'],
          },
          organizationInfo: {
            label: "Organization info",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn: "tripExpenses.whoWillCover",
            dependantOnValues: ['CT'],
            nested: {
              name: {
                label: "Name",
                type: TYPES.TEXT,
                path: "tripExpenses.organizationInfo",
              },
              address: {
                label: "Address",
                type: TYPES.TEXT,
                path: "tripExpenses.organizationInfo",
              },
              telephoneNumber: {
                label: "Telephone number",
                type: TYPES.TEXT,
                path: "tripExpenses.organizationInfo",
              },
              coverType: {
                label: "Cover type",
                type: TYPES.SELECT,
                values: VN_SELECT_VALUES.COVER_TYPE,
                path: "tripExpenses.organizationInfo",
              },
            },
          },
          haveHealthInsurance: {
            label: "Have health insurance",
            type: TYPES.SELECT,
            values: VN_SELECT_VALUES.HAVE_HEALTH_INSURANCE,
            path: "tripExpenses",
          },
          healthInsuranceDetail: {
            label: "Health insurance detail",
            type: TYPES.TEXT,
            path: "tripExpenses",
            dependsOn: "tripExpenses.haveHealthInsurance",
            dependantOnValues: ["Y"],
          },
        },
      },
      numberOfEntries: {
        label: "Number of entries",
        type: TYPES.SELECT,
        values: VN_SELECT_VALUES.NUMBER_OF_ENTRIES,
      },
      requestedInformation: {
        label: "Requested information",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          eVisaFrom: {
            label: "Grant e-visa valid from",
            type: TYPES.DATE,
            path: "requestedInformation",
          },
          eVisaTo: {
            label: "Grant e-visa valid to",
            type: TYPES.DATE,
            path: "requestedInformation",
          },
          entryCheckpoint: {
            label: "Allowed to entry through checkpoint",
            type: TYPES.SELECT,
            values: VN_SELECT_VALUES.CHECKPOINT,
            path: "requestedInformation",
          },
          exitCheckpoint: {
            label: "Allowed to exit through checkpoint",
            type: TYPES.SELECT,
            values: VN_SELECT_VALUES.CHECKPOINT,
            path: "requestedInformation",
          },
        },
      },
    },
  },
};
