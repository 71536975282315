import React, { Component } from "react";
import { Segment, Header, Table, Button, Icon } from "semantic-ui-react";
import {
  getPartnersApi,
  createPartnersApi,
  updatePartnerApi,
  deletePartnerApi,
} from "../../../../../../../../../apis/departments/admin/partners";
import Spinner from "../../../../../../../../../components/Spinner";
import * as toastr from "toastr";
import ModalForm from "../../../../../../../../../components/common/ModalForm";
import ConfirmPopup from "../../../../../../../../../components/ConfirmPopup";
import PartnerForm from "./components/PartnerForm";

class Parners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      partners: null,
      currentPartner: null,
    };
  }

  componentDidMount() {
    this.fetchQAPartners();
  }

  async fetchQAPartners() {
    try {
      const {
        data: { partners },
      } = await getPartnersApi();
      this.setState({ isLoading: false, partners });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }

  async updatePartner(partnerId, changedPart) {
    const { partners } = this.state;
    const {
      data: { partner },
    } = await updatePartnerApi(partnerId, changedPart);
    this.setState({
      partners: partners.map((statePartner) =>
        statePartner._id === partnerId ? partner : statePartner,
      ),
    });
    toastr.success("Successfully updated partner");
  }

  async createPartner(partnerToCreate) {
    const { partners } = this.state;
    try {
      const {
        data: { partner },
      } = await createPartnersApi(partnerToCreate);
      this.setState({ partners: [partner, ...partners] });
      toastr.success("Successfully created partner");
    } catch (err) {
      toastr.error("Failed creating partner");
    }
  }

  async deletePartner(partner) {
    const { partners } = this.state;
    try {
      this.setState({ isLoading: true });
      await deletePartnerApi(partner._id);
      toastr.success("Deleted partner");
      this.setState({
        partners: partners.filter(
          (statePartner) => partner._id !== statePartner._id,
        ),
      });
    } catch (err) {
      toastr.error("Couldnt delete partner");
    }
    this.setState({ isLoading: false });
  }

  changeCurrentPartnerPropertyValue(property, value) {
    const { currentPartner } = this.state;
    this.setState({
      currentPartner: { ...currentPartner, [property]: value },
    });
  }

  renderPartnerEdit(partner) {
    return (
      <ModalForm
        headerText="Edit partner form"
        loaderText="Preparing data..."
        onSubmit={(result) => this.updatePartner(partner._id, result)}
        closeOnSubmit
        trigger={
          <Button color="blue" size="mini" icon>
            <Icon name="edit" />
          </Button>
        }
      >
        <PartnerForm partnerFromParent={partner} />
      </ModalForm>
    );
  }

  renderPartnerCreate() {
    return (
      <ModalForm
        headerText="Create partner form"
        trigger={<Button>Create new partner</Button>}
        onSubmit={(result) => this.createPartner(result)}
        closeOnSubmit
      >
        <PartnerForm />
      </ModalForm>
    );
  }

  renderPartnerDelete(partner) {
    return (
      <ConfirmPopup
        callback={() => this.deletePartner(partner)}
        content={`Are you sure you want to REMOVE partner: ${partner.name} (${partner.companyName})`}
      >
        <Button color="red" size="mini" icon>
          <Icon name="trash" />
        </Button>
      </ConfirmPopup>
    );
  }

  renderActions(partner) {
    return (
      <React.Fragment>
        {this.renderPartnerEdit(partner)}
        {!partner.usedInEvaluation && this.renderPartnerDelete(partner)}
      </React.Fragment>
    );
  }

  render() {
    const { partners, isLoading } = this.state;
    if (isLoading) {
      return <Spinner />;
    }

    if (!partners) {
      return <p>Failed loading partners</p>;
    }

    return (
      <Segment.Group>
        <Segment className="tableTopSegment" inverted>
          <Header className="tableTitle" size="large" floated="left">
            <h3>Partners</h3>
            <div style={{ float: "right" }}>{this.renderPartnerCreate()}</div>
          </Header>
        </Segment>
        <Table celled striped fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>Name</Table.HeaderCell>
              <Table.HeaderCell width={1}>Email</Table.HeaderCell>
              <Table.HeaderCell width={1}>Company name</Table.HeaderCell>
              <Table.HeaderCell width={1}>Tracking Code</Table.HeaderCell>
              <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {partners.map((partner) => (
              <Table.Row key={partner._id}>
                <Table.Cell width={1}>{partner.name}</Table.Cell>
                <Table.Cell width={1}>{partner.email}</Table.Cell>
                <Table.Cell width={1}>{partner.companyName}</Table.Cell>
                <Table.Cell width={1}>{partner.trackingId}</Table.Cell>
                <Table.Cell width={1}>{this.renderActions(partner)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment.Group>
    );
  }
}

export default Parners;
