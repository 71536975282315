import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";
import { get } from "lodash";

export const IND_SELECT_VALUES = {
  GENDERS: "GENDERS",
  RELIGIONS: "RELIGIONS",
  EDUCATION: "EDUCATION",
  MARITAL_STATUS: "MARITAL_STATUS",
  OCCUPATION: "OCCUPATION",
  OLD_VISA_TYPE: "OLD_VISA_TYPE",
  STATES: "STATES",
  DISTRICTS: "DISTRICTS",
  VISA_TYPE: "VISA_TYPE",
  VISA_SUB_TYPES: "VISA_SUB_TYPES",
  EXIT_PORT: "EXIT_PORT",
  ARRIVAL_PORT: "ARRIVAL_PORT",
  PASSPORT_TYPE: "PASSPORT_TYPE",
  NATIONALITY_ACQUIRE: "NATIONALITY_ACQUIRE",
  NATIONALITY: "NATIONALITY",
  FAMILY_NATIONALITY: "FAMILY_NATIONALITY",
  SAARC_COUNTRIES: "SAARC_COUNTRIES",
  COUNTRIES: "COUNTRIES",
  LAST_10_YEAR_COUNTRIES: "LAST_10_YEAR_COUNTRIES",
  VISA_HOLDER_IDENTIFICATION_TYPE: "VISA_HOLDER_IDENTIFICATION_TYPE",
  VESSEL_TYPE: "VESSEL_TYPE",
  EVENT_TYPE: "EVENT_TYPE",
  COMPANY_NAMES: "COMPANY_NAMES",
  CAPACITY: "CAPACITY",
  CAPACITY_TYPE: "CAPACITY_TYPE",
  OCCUPATION_SPECIFY: "OCCUPATION_SPECIFY",
};

export const indSchema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      siteId: {
        label: "Referral Site",
      },
      orderId: {
        label: "Order ID",
      },
      orderIdHistory:{
        label:"Order ID History"
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      botRuns: {
        label: "Number of bot runs",
      },
    }
  },
  applicantInfoSegment: {
    label: "Applicant information",
    type: TYPES.SEGMENT,
    data: {
      userEmail: {
        label: "User contact email",
        type: TYPES.EMAIL,
      },
      submissionEmail: {
        label: "Email used to submit user to eta",
        type: TYPES.EMAIL,
      },
      firstName: {
        label: "First name",
        type: TYPES.TEXT,
      },
      lastName: {
        label: "Last name",
        type: TYPES.TEXT,
      },
      hasAdditionalNames: {
        label: "User has other alias",
        type: TYPES.CHECKBOX,
      },
      additionalFirstName: {
        label: "Additional first name",
        type: TYPES.TEXT,
        dependsOn: "hasAdditionalNames",
      },
      additionalLastName: {
        label: "Additional last name",
        type: TYPES.TEXT,
        dependsOn: "hasAdditionalNames",
      },
      gender: {
        label: "Gender",
        type: TYPES.SELECT,
        values: IND_SELECT_VALUES.GENDERS,
      },
      birthDate: {
        label: "Birth data",
        type: TYPES.DATE,
      },
      birthCity: {
        label: "Birth city",
        type: TYPES.TEXT,
      },
      birthCountry: {
        label: "Birth country",
        type: TYPES.SELECT,
        values: IND_SELECT_VALUES.COUNTRIES,
      },
    }
  },
  tripDetailsSegment: {
    label: "Trip details",
    type: TYPES.SEGMENT,
    data: {
      visaType: {
        label: "Visa type",
        type: TYPES.SELECT,
        values: IND_SELECT_VALUES.VISA_TYPE,
      },
      visaSubType: {
        label: "Visa sub type",
        type: TYPES.DEPENDENT_SELECT,
        values: IND_SELECT_VALUES.VISA_SUB_TYPES,
        dependsOn: "visaType",
      },
      arrivalDate: {
        label: "Arrival date",
        type: TYPES.DATE,
      },
      portOfArrival: {
        label: "Arrival airport",
        type: TYPES.SELECT,
        values: IND_SELECT_VALUES.ARRIVAL_PORT,
      },
      exitPort: {
        label: "Exit airport",
        type: TYPES.SELECT,
        values: IND_SELECT_VALUES.EXIT_PORT,
      },
      g20ConferenceInfo: {
        label: "G20 eConference Visa",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "visaType",
        dependantOnValues: ["g20eConference"],
        nested: {
          subject: {
            label: "Conference name",
            type: TYPES.TEXT,
            path: "g20ConferenceInfo",
          },
          startDate: {
            label: "Start date of conference",
            type: TYPES.DATE,
            path: "g20ConferenceInfo",
          },
          endDate: {
            label: "End date of conference",
            type: TYPES.DATE,
            path: "g20ConferenceInfo",
          },
          venue: {
            label: "Venue of conference",
            type: TYPES.NESTED,
            newTable: true,
            nested: {
              address: {
                label: "Address",
                type: TYPES.TEXT,
                path: "g20ConferenceInfo.venue",
              },
              state: {
                label: "State",
                type: TYPES.SELECT,
                values: IND_SELECT_VALUES.STATES,
                path: "g20ConferenceInfo.venue",
              },
              district: {
                label: "District",
                type: TYPES.DEPENDENT_SELECT,
                dependsOn: "g20ConferenceInfo.venue.state",
                values: IND_SELECT_VALUES.DISTRICTS,
                path: "g20ConferenceInfo.venue",
              },
    
              pinCode: {
                label: "Pincode",
                type: TYPES.TEXT,
                path: "g20ConferenceInfo.venue",
              },
            },
          },
          detailsOfOrganizer: {
            label: "Details of organizer",
            type: TYPES.NESTED,
            newTable: true,
            nested: {
              name: {
                label: "Name",
                type: TYPES.TEXT,
                path: "g20ConferenceInfo.detailsOfOrganizer",
              },
              address: {
                label: "Address",
                type: TYPES.TEXT,
                path: "g20ConferenceInfo.detailsOfOrganizer",
              },
              phoneNumber: {
                label: "Phone number",
                type: TYPES.TEXT,
                path: "g20ConferenceInfo.detailsOfOrganizer",
              },
              emailId: {
                label: "Email",
                type: TYPES.TEXT,
                path: "g20ConferenceInfo.detailsOfOrganizer",
              },
            },
          },
        },
      },
      hospitalInfo: {
        label: "Medical purpose",
        type: TYPES.NESTED,
        dependsOn: "visaType",
        dependantOnValues: ["eMedical"],
        newTable: true,
        nested: {
          name: {
            label: "Hospital name",
            type: TYPES.TEXT,
            path: "hospitalInfo",
          },
          address: {
            label: "Street address",
            type: TYPES.TEXT,
            path: "hospitalInfo",
          },
          state: {
            label: "State",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.STATES,
            path: "hospitalInfo",
          },
          district: {
            label: "District",
            type: TYPES.DEPENDENT_SELECT,
            dependsOn: "hospitalInfo.state",
            values: IND_SELECT_VALUES.DISTRICTS,
            path: "hospitalInfo",
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "hospitalInfo",
          },
          treatmentType: {
            label: "Treatment type",
            type: TYPES.TEXT,
            path: "hospitalInfo",
          },
        },
      },
      conferenceInfo: {
        label: "Conference info",
        type: TYPES.NESTED,
        dependsOn: "visaType",
        dependantOnValues: ["eConference"],
        newTable: true,
        nested: {
          name: {
            label: "Conference name",
            type: TYPES.TEXT,
            path: "conferenceInfo",
          },
          startDate: {
            label: "Start date of conference",
            type: TYPES.DATE,
            path: "conferenceInfo",
          },
          endDate: {
            label: "End date of conference",
            type: TYPES.DATE,
            path: "conferenceInfo",
          },
          address: {
            label: "Street address",
            type: TYPES.TEXT,
            path: "conferenceInfo",
          },
          state: {
            label: "State",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.STATES,
            path: "conferenceInfo",
          },
          district: {
            label: "District",
            type: TYPES.DEPENDENT_SELECT,
            dependsOn: "conferenceInfo.state",
            values: IND_SELECT_VALUES.DISTRICTS,
            path: "conferenceInfo",
          },
          pincode: {
            label: "Pin Code",
            type: TYPES.TEXT,
            path: "conferenceInfo",
          },
          organizerName: {
            label: "Organizer name",
            type: TYPES.TEXT,
            path: "conferenceInfo",
          },
          organizerAddress: {
            label: "Organizer address",
            type: TYPES.TEXT,
            path: "conferenceInfo",
          },
          organizerPhoneNumber: {
            label: "Organizer phone number",
            type: TYPES.TEXT,
            path: "conferenceInfo",
          },
          organizerEmail: {
            label: "Organizer email",
            type: TYPES.EMAIL,
            path: "conferenceInfo",
          },
        },
      },
      eMedicalPatientTravellingInfo: {
        label: "Details of principal e-Medical Visa holder (patient)",
        type: TYPES.NESTED,
        dependsOn: "visaType",
        dependantOnValues: ["attendant"],
        newTable: true,
        nested: {
          visaHolderName: {
            label: "Name",
            type: TYPES.TEXT,
            path: "eMedicalPatientTravellingInfo",
          },
          visaHolderIdentificationType: {
            label: "Identification Type",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.VISA_HOLDER_IDENTIFICATION_TYPE,
            path: "eMedicalPatientTravellingInfo",
          },
          visaHolderNumber: {
            label: "Visa number",
            type: TYPES.TEXT,
            path: "eMedicalPatientTravellingInfo",
          },
          visaHolderApplicationId: {
            label: "Application ID",
            type: TYPES.TEXT,
            path: "eMedicalPatientTravellingInfo",
          },
          visaHolderPassportNumber: {
            label: "Passport number",
            type: TYPES.TEXT,
            path: "eMedicalPatientTravellingInfo",
          },
          visaHolderDateOfBirth: {
            label: "Date of birth",
            type: TYPES.DATE,
            path: "eMedicalPatientTravellingInfo",
          },
          visaHolderNationality: {
            label: "Nationality",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.NATIONALITY,
            path: "eMedicalPatientTravellingInfo",
          },
        },
      },
      companyInfo: {
        label: "Details Of The Applicants Company",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "visaSubType",
        dependantOnValues: ["industrial", "sale", "technical", "manpower", "exhibitions", "expert"],
        nested: {
          name: {
            label: "Name",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
          address: {
            label: "Address",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
          phoneNumber: {
            label: "Phone Number",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
          website: {
            label: "Website",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
          natureOfBusiness: {
            label: "Nature of businesss",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
        },
      },
      detailsOfIndianFirm: {
        label: "Details Of The Indian Firm",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "visaSubType",
        dependantOnValues: ["technical", "expert"],
        nested: {
          name: {
            label: "Name",
            type: TYPES.TEXT,
            path: "companyInfo.detailsOfIndianFirm",
          },
          address: {
            label: "Address",
            type: TYPES.TEXT,
            path: "companyInfo.detailsOfIndianFirm",
          },
          phoneNumber: {
            label: "Phone Number",
            type: TYPES.TEXT,
            path: "companyInfo.detailsOfIndianFirm",
          },
          website: {
            label: "Website",
            type: TYPES.TEXT,
            path: "companyInfo.detailsOfIndianFirm",
          },
        },
      },
      companyRepresentativeDetails: {
        label: "Details Of The Company Representative In India",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "visaSubType",
        dependantOnValues: ["manpower"],
        nested: {
          companyName: {
            label: "Company name",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
          companyPhoneNumber: {
            label: "Company phone number",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
          natureOfJob: {
            label: "Nature of job for which you are recruiting",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
          recruitmentPlaces: {
            label: "Places where recruitment is to be conducted",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
        },
      },
      fairDetails: {
        label: "Details Of The Exhibition/Trade Fair",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "visaSubType",
        dependantOnValues: ["exhibitions"],
        nested: {
          exhibitionFairName: {
            label: "Name",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
          exhibitionFairAddress: {
            label: "Address",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
        },
      },
      travelAgencyDetails: {
        label: "Details Of The Travel Agency In Native Country",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "visaSubType",
        dependantOnValues: ["conducting"],
        nested: {
          travelAgencyName: {
            label: "Name",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
          travelAgencyAddress: {
            label: "Address",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
          cities: {
            label: "Cities to be visited during the tour",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
        },
      },
      detailsOfTravelAgent: {
        label: "Details Of The Travel Agent/Associate In India",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "visaSubType",
        dependantOnValues: ["conducting"],
        nested: {
          name: {
            label: "Name",
            type: TYPES.TEXT,
            path: "companyInfo.detailsOfTravelAgent",
          },
          phoneNumber: {
            label: "Phone no.",
            type: TYPES.TEXT,
            path: "companyInfo.detailsOfTravelAgent",
          },
          address: {
            label: "Address",
            type: TYPES.TEXT,
            path: "companyInfo.detailsOfTravelAgent",
          },
        },
      },
      schemeDetails: {
        label: "Details Of The Travel Agent/Associate In India",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "visaSubType",
        dependantOnValues: ["scheme"],
        nested: {
          specialCompanyName: {
            label: "Company name",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.COMPANY_NAMES,
            path: "companyInfo",
          },
          address: {
            label: "Address",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
          state: {
            label: "State",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.STATES,
            path: "companyInfo",
          },
          district: {
            label: "District",
            type: TYPES.DEPENDENT_SELECT,
            dependsOn: "companyInfo.state",
            values: IND_SELECT_VALUES.DISTRICTS,
            path: "companyInfo",
          },
          phoneNumber: {
            label: "Phone no.",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
          natureOfJob: {
            label: "Role/nature of the job",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
          remunerationPerAnnum: {
            label: "Remuneration per annum",
            type: TYPES.TEXT,
            path: "companyInfo",
          },
          tenureStartDate: {
            label: "Tenure start date",
            type: TYPES.DATE,
            path: "companyInfo",
          },
          tenureEndDate: {
            label: "Tenure end date",
            type: TYPES.DATE,
            path: "companyInfo",
          },
        },
      },
      tournamentInfo: {
        label: "Sport Event Details",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "visaSubType",
        dependantOnValues: ["sports"],
        nested: {
          eventName: {
            label: "Event name",
            type: TYPES.TEXT,
            path: "tournamentInfo",
          },
          eventType: {
            label: "Event type",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.EVENT_TYPE,
            path: "tournamentInfo",
          },
          eventStartDate: {
            label: "Event start date",
            type: TYPES.DATE,
            path: "tournamentInfo",
          },
          eventEndDate: {
            label: "Event end date",
            type: TYPES.DATE,
            path: "tournamentInfo",
          },
          eventAddress: {
            label: "Address",
            type: TYPES.TEXT,
            path: "tournamentInfo",
          },
          state: {
            label: "State",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.STATES,
            path: "tournamentInfo",
          },
          district: {
            label: "District",
            type: TYPES.DEPENDENT_SELECT,
            dependsOn: "tournamentInfo.state",
            values: IND_SELECT_VALUES.DISTRICTS,
            path: "tournamentInfo",
          },
          pincode: {
            label: "Pincode",
            type: TYPES.TEXT,
            path: "tournamentInfo",
          },
          organizerName: {
            label: "Organizer name",
            type: TYPES.TEXT,
            path: "tournamentInfo",
          },
          organizerAddress: {
            label: "Organizer address",
            type: TYPES.TEXT,
            path: "tournamentInfo",
          },
          organizerPhoneNumber: {
            label: "Organizer phone number",
            type: TYPES.TEXT,
            path: "tournamentInfo",
          },
          organizerEmail: {
            label: "Organizer email",
            type: TYPES.TEXT,
            path: "tournamentInfo",
          },
          restrictedArea: {
            label: "Visit to restricted area",
            type: TYPES.CHECKBOX,
            path: "tournamentInfo",
          },
          capacity: {
            label: "Capacity",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.CAPACITY,
            path: "tournamentInfo",
          },
          previousVisitDetails: {
            label: "Previous visit details",
            type: TYPES.TEXT,
            path: "tournamentInfo",
          },
          taxCompliance: {
            label: "Tax compliance details",
            type: TYPES.CHECKBOX,
            path: "tournamentInfo",
          },
          capacityType: {
            label: "Capacity type",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.CAPACITY_TYPE,
            path: "tournamentInfo",
          },
        },
      },
      vesselInfo: {
        label: "Vessel details",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "visaSubType",
        dependantOnValues: ["vessel"],
        nested: {
          vesselName: {
            label: "Vessel name",
            type: TYPES.TEXT,
            path: "vesselInfo",
          },
          vesselType: {
            label: "Vessel type",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.VESSEL_TYPE,
            path: "vesselInfo",
          },
          portOfEntry: {
            label: "Joining port of entry",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.EXIT_PORT,
            path: "vesselInfo",
          },
          joiningDate: {
            label: "Date of boarding",
            type: TYPES.DATE,
            path: "vesselInfo",
          },
          currentRank: {
            label: "Current rank",
            type: TYPES.TEXT,
            path: "vesselInfo",
          },
        },
      },
      shippingAgentInfo: {
        label: "Details of Shipping Agent",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "visaSubType",
        dependantOnValues: ["vessel"],
        nested: {
          agentName: {
            label: "Name",
            type: TYPES.TEXT,
            path: "vesselInfo",
          },
          agentAddress: {
            label: "Address",
            type: TYPES.TEXT,
            path: "vesselInfo",
          },
          state: {
            label: "State",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.STATES,
            path: "vesselInfo",
          },
          district: {
            label: "District",
            type: TYPES.DEPENDENT_SELECT,
            dependsOn: "vesselInfo.state",
            values: IND_SELECT_VALUES.DISTRICTS,
            path: "vesselInfo",
          },
        },
      },
      meetingFriendsInfo: {
        label: "Details of the Friends/Family",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: ["visaType","visaSubType"],
        dependantOnValues: ["eTourist1", "relatives"],
        nested: {
          details: {
            label: "Details",
            type: TYPES.TEXT,
            path: "meetingFriendsInfo",
          },
          address: {
            label: "Address",
            type: TYPES.TEXT,
            path: "meetingFriendsInfo",
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "meetingFriendsInfo",
          },
          state: {
            label: "State",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.STATES,
            path: "meetingFriendsInfo",
          },
          district: {
            label: "District",
            type: TYPES.DEPENDENT_SELECT,
            dependsOn: "meetingFriendsInfo.state",
            values: IND_SELECT_VALUES.DISTRICTS,
            path: "meetingFriendsInfo",
          },
        },
      },
      visaDetails: {
        label: "Visa details",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          placesToBeVisited: {
            label: "Places to be visited",
            type: TYPES.TEXT,
            path: "visaDetails",
          },
          placesToBeVisited2: {
            label: "Places to be visited (part2)",
            type: TYPES.TEXT,
            path: "visaDetails",
          },
          bookedHotel: {
            label: "Booked hotel",
            type: TYPES.CHECKBOX,
            path: "visaDetails",
          },
          tourOperator: {
            label: "Tour operator",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn: "visaDetails.bookedHotel",
            dependantOnValues: [true],
            nested: {
              name: {
                label: "Name",
                type: TYPES.TEXT,
                path: "visaDetails.tourOperator",
              },
              address: {
                label: "Address",
                type: TYPES.TEXT,
                path: "visaDetails.tourOperator",
              },
              hotelName: {
                label: "Hotel name",
                type: TYPES.TEXT,
                path: "visaDetails.tourOperator",
              },
              hotelCity: {
                label: "Hotel city",
                type: TYPES.TEXT,
                path: "visaDetails.tourOperator",
              },
            },
          },
        },
      },
      visitedIndiaBefore: {
        label: "Visited India",
        type: TYPES.CHECKBOX,
      },
      previousIndiaVisit: {
        label: "Previous visit info",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "visitedIndiaBefore",
        nested: {
          address: {
            label: "Street address",
            type: TYPES.TEXT,
            path: "previousIndiaVisit",
          },
          address2: {
            label: "Street address 2",
            type: TYPES.TEXT,
            path: "previousIndiaVisit",
          },
          address3: {
            label: "Street address 3",
            type: TYPES.TEXT,
            path: "previousIndiaVisit",
          },
          cities: {
            label: "Cities",
            type: TYPES.TEXT,
            path: "previousIndiaVisit",
          },
          lastVisaNo: {
            label: "Last visa number",
            type: TYPES.TEXT,
            path: "previousIndiaVisit",
          },
          visaType: {
            label: "Visa type",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.OLD_VISA_TYPE,
            path: "previousIndiaVisit",
          },
          issuancePlace: {
            label: "Issuance place",
            type: TYPES.TEXT,
            path: "previousIndiaVisit",
          },
          issuanceDate: {
            label: "Issuance date",
            type: TYPES.DATE,
            path: "previousIndiaVisit",
          },
        },
      },
      refusedVisa: {
        label: "Has ever been refused for visa",
        type: TYPES.CHECKBOX,
      },
      refusedVisaDetails: {
        label: "Refused visa when and reason",
        type: TYPES.TEXT,
        dependsOn: "refusedVisa",
      },
      reference: {
        label: "Reference",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          india: {
            label: "India address",
            type: TYPES.NESTED,
            newTable: true,
            path: "reference",
            nested: {
              name: {
                label: "Name",
                type: TYPES.TEXT,
                path: "reference.india",
              },
              address: {
                label: "Street address",
                type: TYPES.TEXT,
                path: "reference.india",
              },
              address2: {
                label: "Street address 2",
                type: TYPES.TEXT,
                path: "reference.india",
              },
              phoneNumber: {
                label: "Phone number",
                type: TYPES.TEXT,
                path: "reference.india",
              },
              state: {
                label: "State",
                type: TYPES.SELECT,
                values: IND_SELECT_VALUES.STATES,
                path: "reference.india",
              },
              district: {
                label: "District",
                type: TYPES.DEPENDENT_SELECT,
                dependsOn: "reference.india.state",
                values: IND_SELECT_VALUES.DISTRICTS,
                path: "reference.india",
              },
            },
          },
          home: {
            label: "Country of residence address",
            type: TYPES.NESTED,
            newTable: true,
            path: "reference",
            nested: {
              name: {
                label: "Name",
                type: TYPES.TEXT,
                path: "reference.home",
              },
              address: {
                label: "Street address",
                type: TYPES.TEXT,
                path: "reference.home",
              },
              address2: {
                label: "Street address 2",
                type: TYPES.TEXT,
                path: "reference.home",
              },
              phoneNumber: {
                label: "Phone number",
                type: TYPES.TEXT,
                path: "reference.home",
              },
            },
          },
        },
      },
      placesToBeVisited: {
        label: "Places to be visited",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          details: {
            label: "Details",
            type: TYPES.TEXT,
            path: "placesToBeVisited",
          },
          state: {
            label: "State",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.STATES,
            path: "placesToBeVisited",
          },
        },
      },
      relativesAddress: {
        label: "Relatives address",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          name: {
            label: "Name",
            type: TYPES.TEXT,
            path: "relativesAddress",
          },
          relation: {
            label: "Relation",
            type: TYPES.TEXT,
            path: "relativesAddress",
          },
          street: {
            label: "Street address",
            type: TYPES.TEXT,
            path: "relativesAddress",
          },
          city: {
            label: "City",
            type: TYPES.TEXT,
            path: "relativesAddress",
          },
          state: {
            label: "State",
            type: TYPES.TEXT,
            path: "relativesAddress",
            values: IND_SELECT_VALUES.STATES,
          },
          district: {
            label: "District",
            type: TYPES.DEPENDENT_SELECT,
            path: "relativesAddress",
            dependsOn: "relativesAddress.state",
            values: IND_SELECT_VALUES.DISTRICTS,
          },
          pinCode: {
            label: "Pin code",
            type: TYPES.TEXT,
            path: "relativesAddress",
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "relativesAddress",
          },
        },
      },
      stayAddress: {
        label: "Stay address",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          place: {
            label: "Place",
            type: TYPES.TEXT,
            path: "stayAddress",
          },
          hotel: {
            label: "Hotel",
            type: TYPES.TEXT,
            path: "stayAddress",
          },
          state: {
            label: "State",
            type: TYPES.SELECT,
            path: "stayAddress",
            values: IND_SELECT_VALUES.STATES,
          },
          district: {
            label: "District",
            type: TYPES.DEPENDENT_SELECT,
            path: "stayAddress",
            dependsOn: "stayAddress.state",
            values: IND_SELECT_VALUES.DISTRICTS,
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "stayAddress",
          },
          visitJK: {
            label: "Visit JK",
            type: TYPES.CHECKBOX,
            path: "stayAddress",
          },
          partensJK: {
            label: "Partens JK",
            type: TYPES.CHECKBOX,
            path: "stayAddress",
          },
          haveYouMigrated: {
            label: "Have you migrated",
            type: TYPES.CHECKBOX,
            path: "stayAddress",
          },
          migration: {
            label: "Migration information",
            type: TYPES.NESTED,
            newTable: true,
            dependsOn: "stayAddress.haveYouMigrated",
            path: "stayAddress",
            nested: {
              date: {
                label: "Date",
                type: TYPES.DATE,
                path: "stayAddress.migration",
              },
              address: {
                label: "Address",
                type: TYPES.TEXT,
                path: "stayAddress.migration",
              },
            },
          },
        },
      },
    },
  },
  passportInfoSegment: {
    label: "Passport information",
    type: TYPES.SEGMENT,
    data: {
      nationality: {
        label: "Applicant nationality",
        type: TYPES.SELECT,
        values: IND_SELECT_VALUES.NATIONALITY,
      },
      howDidAcquireNationality: {
        label: "Did you acquire nationality by birth or by naturalization",
        type: TYPES.SELECT,
        values: IND_SELECT_VALUES.NATIONALITY_ACQUIRE,
      },
      prevNationality: {
        label: "Previous nationality",
        type: TYPES.SELECT,
        values: IND_SELECT_VALUES.COUNTRIES,
        dependsOn: "howDidAcquireNationality",
        dependantOnValues: ["NATURALIZATION"],
      },
      livedTwoYears: {
        label:
          "Have you lived for at least two years in the country where you are applying visa?",
        type: TYPES.CHECKBOX,
      },

      passportNumber: {
        label: "Passport number",
        type: TYPES.TEXT,
      },
      issuanceCountry: {
        label: "Passport issuance country",
        type: TYPES.SELECT,
        values: IND_SELECT_VALUES.COUNTRIES,
      },
      nationalId: {
        label: "National ID",
        type: TYPES.TEXT,
        required: false,
      },
      passportType: {
        label: "Passport type",
        type: TYPES.SELECT,
        values: IND_SELECT_VALUES.PASSPORT_TYPE,
      },
      issuancePlace: {
        label: "Issuance place",
        type: TYPES.TEXT,
      },
      issuanceDate: {
        label: "Passport issuance date",
        type: TYPES.DATE,
      },
      expirationDate: {
        label: "Passport expiration date",
        type: TYPES.DATE,
      },
      otherValidPpt: {
        label: "Has other valid passport",
        type: TYPES.CHECKBOX,
      },
      otherPptInfo: {
        label: "Other passport info",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "otherValidPpt",
        nested: {
          issuanceCountry: {
            label: "Passport issuance country",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.COUNTRIES,
            path: "otherPptInfo",
          },
          passportNumber: {
            label: "Passport number",
            type: TYPES.TEXT,
            path: "otherPptInfo",
          },
          issuanceDate: {
            label: "Issuance date",
            type: TYPES.TEXT,
            path: "otherPptInfo",
          },
          issuancePlace: {
            label: "Issuance place",
            type: TYPES.TEXT,
            path: "otherPptInfo",
          },
          nationality: {
            label: "Nationality",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.COUNTRIES,
            path: "otherPptInfo",
          },
        },
      },
    },
  },
  personalInfoSegment: {
    label: "Personal information",
    type: TYPES.SEGMENT,
    data: {
      presentAddress: {
        label: "Present address",
        note: "Parts of this section will be used to verify the client's ID on Calls/Chats",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          street: {
            label: "Street address",
            type: TYPES.TEXT,
            path: "presentAddress",
          },
          city: {
            label: "City",
            type: TYPES.TEXT,
            path: "presentAddress",
          },
          country: {
            label: "Country",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.COUNTRIES,
            path: "presentAddress",
          },
          state: {
            label: "State",
            type: TYPES.TEXT,
            path: "presentAddress",
          },
          zipCode: {
            label: "Zip code",
            type: TYPES.TEXT,
            path: "presentAddress",
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            path: "presentAddress",
          },
          mobileNumber: {
            label: "Mobile number",
            type: TYPES.TEXT,
            path: "presentAddress",
          },
        },
      },
      sameAddress: {
        label: "Same address",
        type: TYPES.CHECKBOX,
      },
      permanentAddress: {
        label: "Permanent address",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "sameAddress",
        dependantOnValues: [false],
        nested: {
          street: {
            label: "Street address",
            type: TYPES.TEXT,
            path: "permanentAddress",
          },
          city: {
            label: "City",
            type: TYPES.TEXT,
            path: "permanentAddress",
          },
          state: {
            label: "State",
            type: TYPES.TEXT,
            path: "permanentAddress",
          },
        },
      },
      religion: {
        label: "Religion",
        type: TYPES.SELECT,
        values: IND_SELECT_VALUES.RELIGIONS,
      },
      religionSpecify: {
        label: "Religion specify",
        type: TYPES.TEXT,
        dependsOn: "religion",
        dependantOnValues: ["OTHERS"],
      },
      visibleMarks: {
        label: "Applicant has visible identification marks",
        type: TYPES.TEXT,
      },
      fathersInfo: {
        label: "Father info",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          name: {
            label: "Name",
            type: TYPES.TEXT,
            path: "fathersInfo",
          },
          nationality: {
            label: "Nationality",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.FAMILY_NATIONALITY,
            path: "fathersInfo",
          },
          prevNationality: {
            label: "Previous nationality",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.FAMILY_NATIONALITY,
            required: false,
            path: "fathersInfo",
          },
          birthCity: {
            label: "Birth city",
            type: TYPES.TEXT,
            path: "fathersInfo",
          },
          birthCountry: {
            label: "Birth country",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.COUNTRIES,
            path: "fathersInfo",
          },
        },
      },
      mothersInfo: {
        label: "Mothers info",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          name: {
            label: "Name",
            type: TYPES.TEXT,
            path: "mothersInfo",
          },
          nationality: {
            label: "Nationality",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.FAMILY_NATIONALITY,
            path: "mothersInfo",
          },
          prevNationality: {
            label: "Previous nationality",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.FAMILY_NATIONALITY,
            required: false,
            path: "mothersInfo",
          },
          birthCity: {
            label: "Birth city",
            type: TYPES.TEXT,
            path: "mothersInfo",
          },
          birthCountry: {
            label: "Birth country",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.COUNTRIES,
            path: "mothersInfo",
          },
        },
      },
      maritalStatus: {
        label: "Marital status",
        type: TYPES.SELECT,
        values: IND_SELECT_VALUES.MARITAL_STATUS,
      },
      spouseInfo: {
        label: "Spouse info",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "maritalStatus",
        dependantOnValues: ["0"],
        nested: {
          name: {
            label: "Name",
            type: TYPES.TEXT,
            path: "spouseInfo",
          },
          nationality: {
            label: "Nationality",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.FAMILY_NATIONALITY,
            path: "spouseInfo",
          },
          prevNationality: {
            label: "Previous nationality",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.FAMILY_NATIONALITY,
            required: false,
            path: "spouseInfo",
          },
          birthCity: {
            label: "Birth city",
            type: TYPES.TEXT,
            path: "spouseInfo",
          },
          birthCountry: {
            label: "Birth country",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.COUNTRIES,
            path: "spouseInfo",
          },
        },
      },
    },
  },
  employmentInfoSegment: {
    label: "Employment information",
    type: TYPES.SEGMENT,
    data: {
      profession: {
        label: "Profession",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          presentOccupation: {
            label: "Present occupation",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.OCCUPATION,
            path: "profession",
          },
          occupationSpecifyFor: {
            label: "Specify below occupation details of",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.OCCUPATION_SPECIFY,
            dependsOn: "profession.presentOccupation",
            dependantOnValues: ["HOUSE WIFE", "STUDENT"],
            path: "profession",
          },
          specify: {
            label: "Specify",
            type: TYPES.TEXT,
            dependsOn: "profession.presentOccupation",
            dependantOnValues: ["OTHERS"],
            path: "profession",
          },
          employer: {
            label: "Employer",
            type: TYPES.TEXT,
            path: "profession",
          },
          designation: {
            label: "Designation (title)",
            type: TYPES.TEXT,
            required: false,
            path: "profession",
          },
          address: {
            label: "Address info",
            type: TYPES.TEXT,
            path: "profession",
          },
          phoneNumber: {
            label: "Phone number",
            type: TYPES.TEXT,
            required: false,
            path: "profession",
          },
          pastOccupation: {
            label: "Previous occupation",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.OCCUPATION,
            required: false,
            path: "profession",
          },
          pastSpecify: {
            label: "Specify previous occupation",
            type: TYPES.TEXT,
            dependsOn: "profession.pastOccupation",
            dependantOnValues: ["OTHERS"],
            path: "profession",
          },
        },
      },
      education: {
        label: "Education qualification",
        type: TYPES.SELECT,
        values: IND_SELECT_VALUES.EDUCATION,
      },
    },
  },
  securityInfoSegment: {
    label: "Security information",
    type: TYPES.SEGMENT,
    data: {
      parentsPakistans: {
        label: "Were your Parents/Grandparents (paternal/maternal) Pakistan",
        type: TYPES.CHECKBOX,
      },
      parentsPakistansDetails: {
        label: "If Yes, give details",
        type: TYPES.TEXT,
        dependsOn: "parentsPakistans",
      },
      militaryService: {
        label: "Has been in military",
        type: TYPES.CHECKBOX,
      },
      militaryInfo: {
        label: "Military info",
        type: TYPES.NESTED,
        newTable: true,
        dependsOn: "militaryService",
        nested: {
          organization: {
            label: "Organization",
            type: TYPES.TEXT,
            path: "militaryInfo",
          },
          designation: {
            label: "Designation (title)",
            type: TYPES.TEXT,
            path: "militaryInfo",
          },
          rank: {
            label: "Military rank",
            type: TYPES.TEXT,
            path: "militaryInfo",
          },
          postingPlace: {
            label: "Posting place",
            type: TYPES.TEXT,
            path: "militaryInfo",
          },
        },
      },
      hasBeenArrested: {
        label: "Has been arrested",
        type: TYPES.CHECKBOX,
      },
      hasBeenRefused: {
        label: "Has been refused",
        type: TYPES.CHECKBOX,
      },
      hasBeenEngagedInTrafficking: {
        label: "Has been engaged in trafficking",
        type: TYPES.CHECKBOX,
      },
      hasBeenEngagedInCrime: {
        label: "Has been engaged in crime",
        type: TYPES.CHECKBOX,
      },
      hasPermissionToVisit: {
        label: "Has permission to visit",
        type: TYPES.CHECKBOX,
      },
      countriesVisitedInLast10Years: {
        label: "Countries visited in last 10 years",
        type: TYPES.ARRAY,
        data: {
          country: {
            label: "Country visited",
            type: TYPES.SELECT,
            values: IND_SELECT_VALUES.LAST_10_YEAR_COUNTRIES,
            path: "countriesVisitedInLast10Years.$countriesVisitedInLast10Years",
          },
          visitedInLast3Years: {
            label: "Has been visited in last 3 years",
            type: TYPES.CHECKBOX,
            path: "countriesVisitedInLast10Years.$countriesVisitedInLast10Years",
            customDependency(applicant, path, schemaData) {
              const currentCountry = get(applicant, path);
              return (
                currentCountry &&
                schemaData[IND_SELECT_VALUES.SAARC_COUNTRIES][
                  currentCountry.country
                ]
              );
            },
          },
          year: {
            label: "Year",
            type: TYPES.TEXT,
            dependsOn:
              "countriesVisitedInLast10Years.$countriesVisitedInLast10Years.visitedInLast3Years",
            path: "countriesVisitedInLast10Years.$countriesVisitedInLast10Years",
          },
          noOfVisited: {
            label: "Number of visited",
            type: TYPES.TEXT,
            dependsOn:
              "countriesVisitedInLast10Years.$countriesVisitedInLast10Years.visitedInLast3Years",
            path: "countriesVisitedInLast10Years.$countriesVisitedInLast10Years",
          },
        },
      },
    },
  },
};
