import React, { useCallback, useEffect } from "react";
import { Header, Segment, Table, Input, Loader } from "semantic-ui-react";
import { useFilters, notEmptyValidator } from "../../../../../hooks";
import { getInterviewsApi } from "../../../../../apis/interviewsApi";
import { usePagination } from "../../../../../hooks/usePagination.hook";

const columns = [
  {
    label: "Country",
    prop: "country",
    width: 1,
  },
  {
    label: "U.S. Consulates",
    prop: "usConsulate",
    width: 2,
  },
  {
    label: "Type",
    prop: "type",
    width: 1,
  },
];

const InterviewDatesPage = () => {
  const { filters, normalizedFilters, setFormFilter } = useFilters(
    {
      searchString: "",
    },
    {
      localStorageKey: "interviews",
      filterValidators: {
        searchString: notEmptyValidator(),
      },
      filterDebounces: {
        searchString: 700,
      },
      temporaryFilters: ["searchString"],
    },
  );

  const {
    items: interviews,
    Paginator,
    loadItems: loadInterviews,
    isLoading,
  } = usePagination(getInterviewsApi, {
    initialLoad: false,
    clearOnLoad: false,
  });

  useEffect(() => {
    loadInterviews(1, { ...normalizedFilters });
  }, [loadInterviews, normalizedFilters]);

  const renderFilters = useCallback(() => {
    const { searchString } = filters;

    return (
      <div style={{ float: "right" }} className="users-filters">
        <Input
          placeholder="Search (by Country and Consulates)"
          id="search-input"
          name="searchString"
          value={searchString || ""}
          onChange={setFormFilter}
          style={{ "min-width": "250px" }}
        />
      </div>
    );
  }, [filters, setFormFilter]);

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader active />
      ) : (
        <Segment.Group>
          <Segment
            className="tableTopSegment interviews-table__header"
            inverted
          >
            <Header className="tableTitle" size="large" floated="left">
              Interview Dates
            </Header>
            {renderFilters()}
          </Segment>
          <Table celled striped fixed className="interviews-table">
            <Table.Header>
              <Table.Row>
                {columns.map((column) => (
                  <Table.HeaderCell key={column.prop} width={column.width}>
                    {column.label}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell
                  key="interviewScheduling"
                  width={3}
                >
                  Interview Scheduling
                </Table.HeaderCell>
                <Table.HeaderCell
                  key="dropOffAppointments"
                  width={3}
                >
                  ASC/VAC/OFC/Drop-off Appointments
                </Table.HeaderCell>
                <Table.HeaderCell key="lastChecked" width={2}>
                  Last date/time Checked
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {interviews &&
                Array.isArray(interviews) &&
                interviews.map((interview) => (
                  <Table.Row key={interview._id}>
                    {columns.map((column) => (
                      <Table.Cell key={column.prop}>
                        {interview[column.prop]}
                      </Table.Cell>
                    ))}
                    <Table.Cell>
                      {interview.interviewScheduling}
                    </Table.Cell>
                    <Table.Cell>
                      {interview.dropOffAppointments}
                    </Table.Cell>
                    <Table.Cell>
                      {new Date(interview.updatedAt).toLocaleString()}
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={columns.length + 3}>
                  <Paginator />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Segment.Group>
      )}
    </React.Fragment>
  );
};

export default InterviewDatesPage;
