import React, { useEffect, useState, useRef } from "react";
import { Button, Icon } from "semantic-ui-react";
import "./CustomSelect.styles.scss";

const CustomSelect = ({
  multiple,
  defaultValue,
  disabled,
  item,
  setItem,
  value,
  itemPropName,
  options,
  changeCurrentItemPropertyValue,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const deleteValueFromSelect = (e, selectValue, itemSelectedValues) => {
    e.stopPropagation();
    const itemValuesProps = itemSelectedValues.filter((v) => v !== selectValue);
    setItem((currItem) => ({
      ...currItem,
      [itemPropName]: [...itemValuesProps],
    }));
  };

  return (
    <div
      ref={ref}
      onClick={() => setOpen(!open)}
      style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}
    >
      <select
        id={itemPropName}
        value={value || item[itemPropName] || defaultValue}
        name={itemPropName}
        multiple={multiple}
        onChange={() => {}}
        style={{ display: "none" }}
      >
        {Array.isArray(options) &&
          options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.value}
              </option>
            );
          })}
      </select>
      <div className="custom-select" key={itemPropName} id={itemPropName}>
        {multiple ? (
          Array.isArray(item[itemPropName]) &&
          item[itemPropName].map((selectValue) => {
            return (
              <div key={selectValue} className="select-values">
                {selectValue}
                <Button
                  type="button"
                  icon={
                    <Icon name="x" size="small" className="delete-value-icon" />
                  }
                  circular
                  size="tiny"
                  className="delete-value-button"
                  onClick={(e) =>
                    deleteValueFromSelect(e, selectValue, item[itemPropName])
                  }
                />
              </div>
            );
          })
        ) : (
          <div style={{ padding: "4px" }}>{options && 
            options.find(option => item[itemPropName] === option.value) ?
            options.find(option => item[itemPropName] === option.value).text :
            item[itemPropName] ||
            (options.find(option => value === option.value) && options.find(option => value === option.value)?.text)}</div>
        )}
      </div>
      <div className={`${!open ? "open-options " : ""}custom-select-options`}>
        {(options
          ? Array.isArray(item[itemPropName]) && item[itemPropName].length > 0
            ? options.filter(
                (option) => !item[itemPropName].includes(option.value),
              )
            : options
          : []
        ).map((option) => {
          return (
            <div
              key={option.value}
              onClick={() =>
                changeCurrentItemPropertyValue(
                  itemPropName,
                  option.value,
                  multiple,
                )
              }
            >
              {option.text}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomSelect;
