import React, { useMemo } from "react";
import DatePicker from "react-date-picker";
import { Radio } from "semantic-ui-react";
import moment from "moment";
import classNames from "classnames";

import "./CalendarFilter.styles.scss";

const TODAY = new Date();

const singleFilters = [
  { label: "Past 7 Days", value: "7", unit: "days" },
  { label: "Past 14 Days", value: "14", unit: "days" },
  { label: "Past 30 Days", value: "30", unit: "days" },
  {
    label: "Past 3 Months",
    value: "3",
    unit: "months",
  },
  {
    label: "Past 6 Months",
    value: "6",
    unit: "months",
  },
  { label: "Past 1 Year", value: "1", unit: "years" },
];

const CalendarFilter = ({
  onChangeDate,
  selectedDate,
  selectedDateSingleFilter,
  showSingleFilters = true,
  showRangeDisplay = true,
}) => {
  const selectedRange = useMemo(() => Object.values(selectedDate), [
    selectedDate,
  ]);
  const selectedRangeDisplay = useMemo(
    () =>
      `${moment(selectedRange[0]).format("YYYY-MM-DD")} - ${moment(
        selectedRange[1],
      ).format("YYYY-MM-DD")} `,
    [selectedRange],
  );

  return (
    <div
      className={classNames("calendar-filters", {
        "calendar-filters--row": !showSingleFilters,
      })}
    >
      {showSingleFilters && (
        <div className="calendar-filters__single-filters">
          {singleFilters.map((filter) => (
            <Radio
              key={filter.label}
              className="calendar-filters__single-filters__filter"
              label={filter.label}
              name="single-filters"
              value={filter.value}
              checked={selectedDateSingleFilter === filter.value}
              onChange={(e, { value }) =>
                onChangeDate(value, "radio", filter.unit)
              }
            />
          ))}
        </div>
      )}
      <span
        className={classNames("calendar-filters__custom-range-title", {
          "calendar-filters__custom-range-title--row": !showSingleFilters,
        })}
      >
        Custom Range:
      </span>
      <DatePicker
        onChange={(date) => onChangeDate(date, "range")}
        value={selectedRange}
        maxDate={TODAY}
        selectRange={true}
        format="y/MM/dd"
        returnValue="range"
      />
      {showRangeDisplay && selectedRange.length > 0 && selectedRange[0] ? (
        <span
          className={classNames("calendar-filters__custom-range-display", {
            "calendar-filters__custom-range-display--row": !showSingleFilters,
          })}
        >
          {selectedRangeDisplay}
        </span>
      ) : null}
    </div>
  );
};

export default CalendarFilter;
