import React from 'react'
import { Route } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

const NavigateButton = props => {
  const { route } = props
  return (
    <Route
      render={({ history }) => (
        <Button
          {...props}
          onClick={() => {
            history.push(route)
          }}
        />
      )}
    />
  )
}

export default NavigateButton
