export const VALIDATION_TYPES = {
  ERROR: "error",
  WARNING: "warning",
  INFORMATION: "info",
  CHECK_PASS: "check-pass",
};

export const VALIDATION_STYLE_MAP = {
  [VALIDATION_TYPES.ERROR]: {
    name: "Errors",
    color: "red",
    icon: "dont",
  },
  [VALIDATION_TYPES.WARNING]: {
    name: "Warnings",
    color: "orange",
    icon: "warning sign",
  },
  [VALIDATION_TYPES.INFORMATION]: {
    name: "Information",
    color: "teal",
    icon: "attention",
  },
  [VALIDATION_TYPES.CHECK_PASS]: {
    name: "Valid",
    color: "green",
    icon: "checkmark",
  },
};
