import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Header, Form, Segment, Button } from "semantic-ui-react";
import auth from "../../apis/auth";
import { formDataToObject } from "../../common/formData";
import useQueryParams from "../../hooks/useQueryParams";
import toastr from "toastr";
import { logServerError } from "../../common/logs";

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const params = useQueryParams();

  const history = useHistory();

  const token = useMemo(() => params.get("token"), [params]);

  const validate = useCallback((password, confirmPassword) => {
    if (password !== confirmPassword) {
      return "Passwords don't match";
    }
    return "";
  }, []);

  const onSubmitHandler = useCallback(
    async (event) => {
      const resetPasswordObject = formDataToObject(new FormData(event.target));
      const { password, confirmPassword } = resetPasswordObject;

      if (!!password && !!confirmPassword) {
        const errorMsg = validate(password, confirmPassword);

        if (errorMsg) {
          toastr.error(errorMsg);
        } else {
          setIsLoading(true);
          try {
            await auth.resetPassword(token, { password });
            toastr.success("Pasword successfully reset.");
            setIsLoading(false);
            history.push("");
          } catch (err) {
            logServerError(err);
            setIsLoading(false);
          }
        }
      }
    },
    [history, token, validate],
  );

  if (!token) {
    history.push("");
  }

  return (
    <Grid textAlign="center" className="main-grid-login" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          Reset password
        </Header>
        <Form size="large" onSubmit={onSubmitHandler}>
          <Segment stacked>
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="New Password"
              type="password"
              name="password"
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Confirm Password"
              type="password"
              name="confirmPassword"
            />
            <Button color="teal" fluid size="large" disabled={isLoading}>
              Submit
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default ResetPassword;
