export const AGENT_AVAILABILITY = {
  AVAILABLE: "Available",
  UNAVAILABLE: "Unavailable",
  // START_OF_SHIFT: "Unavailable - start of shift",
  // END_OF_SHIFT: "Unavailable - end of shift",
  // ON_BREAK: "Unavailable - on break",
  // ON_CALL: "Unavailable - on call",
  // SPECIAL_ASSIGNMENT: "Unavailable - Special Assignment",
  // ABSENT_AGENT: "Unavailable - absent agent",
  OFFLINE: "Offline",
};

export const CALL_AVAILABLE_STATUSES = [
  AGENT_AVAILABILITY.AVAILABLE,
];

export const SMS_AVAILABLE_STATUSES = [
  AGENT_AVAILABILITY.AVAILABLE,
  AGENT_AVAILABILITY.UNAVAILABLE,
  AGENT_AVAILABILITY.START_OF_SHIFT,
  AGENT_AVAILABILITY.END_OF_SHIFT,
  AGENT_AVAILABILITY.ON_CALL,
  AGENT_AVAILABILITY.SPECIAL_ASSIGNMENT,
];

export const AVAILABILITY_ONLINE_AGENTS = Object.values(
  AGENT_AVAILABILITY,
).filter((availability) => availability !== AGENT_AVAILABILITY.OFFLINE);
