import { isEmpty } from "lodash";

/**
 * Make set of positive/negative/unrelated dates
 *
 * @param {{
 *  positive: Array<string>=[],
 *  negative: Array<string>=[],
 *  unrelated: Array<string>=[],
 *  total: Array<string>=[]
 * }} allKindOfDates - optionally contains positive, negative or/and unrelated arrays of dates
 */
const preparedDates = (
  allKindOfDates = { positive: [], negative: [], unrelated: [], total: [] },
) => {
  let res = [];

  // we concatenate positive, negative or/and unrelated dates arrays
  //
  Object.values(allKindOfDates).forEach((dates) => {
    if (isEmpty(dates)) return;

    res = [...res, ...dates.map(({ date }) => date)];
  });

  // we need sorted dates without duplicates
  //
  return [...new Set(res)].sort();
};

/**
 * Make prepared countes data for chart
 *
 * @param {Array<{
 *  count: number,
 *  date: string
 * }>} data - positive, negative or unrelated data
 * @param {Array<string>} setOfDates - array of all dates for chart
 * @returns {Array<number>} - array of countes prepared for chart
 */
const prepareCountes = (data, setOfDates) => {
  if (isEmpty(data) || isEmpty(setOfDates)) return [];

  // get dates as array from data
  //
  const datesFromData = data?.map(({ date }) => date);

  // dates in global setDates that aren't in data's dates (dates with count=0)
  //
  const datesWithoutCount = setOfDates.filter(
    (date) => !datesFromData.includes(date),
  );

  // make array of objects with count=0 for missed dates
  //
  const objectsWithoutCounts = datesWithoutCount.map((date) => ({
    count: 0,
    date,
  }));

  // make array of objects for global dates
  //
  const allObjects = [...data, ...objectsWithoutCounts];

  // sort objects by dates
  //
  const sortedObjects = allObjects.sort(
    (a, b) => new Date(a.date) - new Date(b.date),
  );

  // now we have sorted array of objects ({ count, date }) for all performance trend dates
  // but we can have duplicate dates - [{ count: 2, date: '2020-09-01' }, { count: 3, date: '2020-09-01' }]
  //
  let res = setOfDates.reduce((ret, currDate) => {
    // get counts with equal date
    //
    let counts = sortedObjects
      .filter(({ date }) => date === currDate)
      .map(({ count }) => count);

    ret.push(counts.reduce((a, b) => a + b, 0));

    return ret;
  }, []);

  // we return just countes array in that orders
  //
  return res;
};

/**
 * Preparing data for chart series
 *
 * @param {{
 *  positive: Array<{ count: number, date: string }>,
 *  negative: Array<{ count: number, date: string }>,
 *  ...
 * }} reportData - object that contains reports with countes and dates in separated format
 * @param {Array<string>} sortedDates - array of all dates for chart
 * @returns {{
 *  positive: Array<number>
 *  negative: Array<number>
 *  ...
 * }} - return object that contains series with array of counts
 */
const prepareChartSeries = (reportData = {}, sortedDates = []) => {
  let chartSeries = {};

  for (const [key, value] of Object.entries(reportData)) {
    chartSeries = {
      ...chartSeries,
      [key]: prepareCountes(value, sortedDates),
    };
  }

  return chartSeries;
};

export { preparedDates, prepareCountes, prepareChartSeries };
