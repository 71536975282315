import React, { useEffect, useCallback, useMemo, useState } from "react";
import * as toastr from "toastr";
import { usePagination } from "../../../../../../../hooks/usePagination.hook";
import apis from "../../../../../../../apis";
import {
  useFilters,
  notEmptyValidator,
} from "../../../../../../../hooks/useFilters.hook";
import {
  Table,
  Segment,
  Header,
  Select,
  Button,
  Icon,
} from "semantic-ui-react";
import Spinner from "../../../../../../../components/Spinner";
import {
  getDateFormat,
  getEndOfDay,
  getStartOfDay,
  getUtcOffset,
} from "../../../../../../../common/date-format";
import { DateInput } from "semantic-ui-calendar-react";
import SmallConfirmPopup from "../../../../../../../components/SmallConfirmPopup";
import { logServerError } from "../../../../../../../common/logs.js";
import {
  APPLICANT_STATUSES,
  PAYMENT_STATUSES,
} from "../../../Products/constants/applicantStatuses";
import { downloadCsv } from "../../../../../../../utils";
import { dsSchema } from "../../../Products/constants/schemaGenerator/schemas/dsSchema";
import { estaSchema } from "../../../Products/constants/schemaGenerator/schemas/estaSchema";

const ExportTool = () => {
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [isExporting, setIsExporting] = useState(false);
  const [fields, setFields] = useState([]);

  const {
    items: data,
    loadItems: loadData,
    isLoading,
    refreshItems: refreshCalls,
    Paginator,
  } = usePagination(apis.exportTool.getApplicants, {
    clearOnLoad: false,
    initialLoad: false,
  });

  const { normalizedFilters, filters, setFormFilter } = useFilters(
    {},
    {
      filterValidators: {
        createdAt: notEmptyValidator(),
        __t: notEmptyValidator(),
        status: notEmptyValidator(),
        paymentStatus: notEmptyValidator(),
      },
      temporaryFilters: ["createdAt", "__t", "status", "paymentStatus"],
    },
  );

  useEffect(() => {
    loadData(1, normalizedFilters);
  }, [normalizedFilters, loadData]);

  const getCreatedAt = (from, to) => {
    const fromISO = getStartOfDay(from, getUtcOffset());
    const toISO = getEndOfDay(to, getUtcOffset());

    if (!from && !to) {
      return "";
    }

    if (from && !to) {
      return {
        $gte: fromISO,
      };
    }

    if (!from && to) {
      return {
        $lt: toISO,
      };
    }
    return { $gte: fromISO, $lt: toISO };
  };

  const showSections = useMemo(() => {
    const { __t: productType } = filters;

    if (productType) {
      if (
        productType.length === 0 ||
        (productType.includes("DsApplicant") &&
          productType.includes("EstaApplicant"))
      ) {
        return false;
      }
      return true;
    }

    return false;
  }, [filters]);

  const separators = useMemo(() => {
    const { __t: productType } = filters;

    if (productType) {
      if (
        productType.length === 0 ||
        (productType.includes("DsApplicant") &&
          productType.includes("EstaApplicant"))
      ) {
        return [];
      }

      if (productType.includes("DsApplicant")) {
        return Object.entries(dsSchema).filter(
          (el) => el[1].type === "segment",
        );
      } else {
        return Object.entries(estaSchema).filter(
          (el) => el[1].type === "segment",
        );
      }
    }

    return [];
  }, [filters]);

  const exportApplicantsToCsv = useCallback(
    async (filters) => {
      let params = { ...filters };
      if (fields.length > 0) {
        params = { ...params, sections: fields };
      }

      try {
        const { data } = await apis.exportTool.exportApplicantsToCsv(params);
        downloadCsv(data, "Applicants");
        toastr.success("Successfully exported data.");
      } catch (err) {
        logServerError(err);
      }
      setIsExporting(false);
    },
    [fields],
  );

  const applicantStatusesOptions = useMemo(
    () =>
      Object.keys(APPLICANT_STATUSES).map((el) => ({
        text: el,
        value: APPLICANT_STATUSES[el],
      })),
    [],
  );

  const paymentStatusesOptions = useMemo(
    () =>
      Object.keys(PAYMENT_STATUSES).map((el) => ({
        text: el,
        value: PAYMENT_STATUSES[el],
      })),
    [],
  );

  const handleProductTypeChange = useCallback(
    (_, field) => {
      setFormFilter(_, field);
      setFields([]);
    },
    [setFormFilter],
  );

  const filtersRendered = useMemo(() => {
    const { __t = [], status = [], paymentStatus = [] } = filters;
    return (
      <div className="sk-table-filters-export-tool">
        <div className="sk-table-filters-export-tool__filters">
          <SmallConfirmPopup
            header={"Export data to CSV file?"}
            callback={() => {}}
            content={
              <>
                <Button
                  onClick={async () => {
                    setIsExporting(true);
                    exportApplicantsToCsv(filters);
                  }}
                  type="button"
                  style={{ marginTop: "16px" }}
                  positive
                  floated="right"
                  content="Export"
                  disabled={isExporting}
                />
              </>
            }
          >
            <Button
              color="blue"
              type="button"
              className="ui medium icon button"
              disabled={isExporting}
            >
              <Icon className={"download"} />
            </Button>
          </SmallConfirmPopup>
          <DateInput
            name="dateFrom"
            dateFormat={"YYYY-MM-DD"}
            clearable={true}
            placeholder="Date from"
            value={dateFrom}
            popupPosition="top right"
            animation="none"
            duration={0}
            iconPosition="left"
            onChange={(_, field) => {
              const { value } = field;
              setDateFrom(value);
              setFormFilter(null, {
                name: "createdAt",
                value: getCreatedAt(value, dateTo),
              });
            }}
          />
          <DateInput
            name="dateTo"
            dateFormat={"YYYY-MM-DD"}
            clearable={true}
            placeholder="Date to"
            value={dateTo}
            popupPosition="top right"
            animation="none"
            duration={0}
            iconPosition="left"
            onChange={(_, field) => {
              const { value } = field;
              setDateTo(value);
              setFormFilter(null, {
                name: "createdAt",
                value: getCreatedAt(dateFrom, value),
              });
            }}
          />
          <Select
            placeholder="Select visa type"
            multiple
            options={[
              {
                text: "VISA",
                value: "DsApplicant",
              },
              {
                text: "ESTA",
                value: "EstaApplicant",
              },
            ]}
            value={__t}
            name="__t"
            onChange={handleProductTypeChange}
          />
          <Select
            placeholder="Select status"
            multiple
            options={applicantStatusesOptions}
            value={status}
            name="status"
            onChange={setFormFilter}
          />
          <Select
            placeholder="Select payment status"
            multiple
            options={paymentStatusesOptions}
            value={paymentStatus}
            name="paymentStatus"
            onChange={setFormFilter}
          />
          <Button onClick={refreshCalls} disabled={isLoading}>
            {isLoading ? "Loading" : "Refresh"}
          </Button>
        </div>
        {showSections && (
          <div className="sk-table-filters-export-tool__sections">
            <Select
              placeholder="Select fields"
              multiple
              options={separators.map(([key, value]) => ({
                text: value.label,
                value: key,
              }))}
              value={fields}
              onChange={(_, field) => {
                const { value } = field;
                setFields(value);
              }}
            />
          </div>
        )}
      </div>
    );
  }, [
    filters,
    isExporting,
    dateFrom,
    dateTo,
    applicantStatusesOptions,
    paymentStatusesOptions,
    isLoading,
    refreshCalls,
    setFormFilter,
    showSections,
    separators,
    fields,
    exportApplicantsToCsv,
    handleProductTypeChange,
  ]);

  return (
    <Segment.Group>
      <Segment className="tableTopSegment" inverted>
        <Header className="tableTitle" size="large" floated="left">
          <h3>Export Tool Table</h3>
        </Header>
      </Segment>
      {filtersRendered}
      <Table celled striped fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>ID</Table.HeaderCell>
            <Table.HeaderCell width={1}>Site ID</Table.HeaderCell>
            <Table.HeaderCell width={1}>First Name</Table.HeaderCell>
            <Table.HeaderCell width={1}>Last Name</Table.HeaderCell>
            <Table.HeaderCell width={1}>Form created date</Table.HeaderCell>
            <Table.HeaderCell width={1}>Citizenship</Table.HeaderCell>
            <Table.HeaderCell width={1}>Product type</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!data ? (
            <Table.Row>
              <Table.Cell>
                <Spinner />
              </Table.Cell>
            </Table.Row>
          ) : (
            data.map(
              ({
                _id,
                siteId,
                firstName,
                lastName,
                createdAt,
                citizenship,
                productType,
              }) => (
                <Table.Row key={_id}>
                  <Table.Cell width={1}>
                    {productType === "VISA" ? (
                      <a href={`/ds160/applicant/${_id}`}>{_id}</a>
                    ) : (
                      <a href={`/esta/applicant/${_id}`}>{_id}</a>
                    )}
                  </Table.Cell>
                  <Table.Cell width={1}>{siteId}</Table.Cell>
                  <Table.Cell width={1}>{firstName}</Table.Cell>
                  <Table.Cell width={1}>{lastName}</Table.Cell>
                  <Table.Cell width={1}>{getDateFormat(createdAt)}</Table.Cell>
                  <Table.Cell width={1}>{citizenship}</Table.Cell>
                  <Table.Cell width={1}>{productType}</Table.Cell>
                </Table.Row>
              ),
            )
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={7}>
              <Paginator />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment.Group>
  );
};

export default ExportTool;
