import {
  FETCH_PRODUCT_CONFIG,
  UPDATE_PRODUCT_CONFIG,
} from "../actions/productConfigActions";

const initialData = {
  aus: false,
  ds160: true,
  esta: true,
  eta: true,
  vn: false,
  ind: false,
  nz: false,
  trk: false,
  usps: false,
  schengen: false,
  inv_letter: false,
  diversity: false,
  ge: false,
  sg: false,
  ds11: false,
  ds82: false,
  ds64: false,
  sl: false,
  tsa: false,
  sentri: false,
  nexus: false,
};

const productConfigReducer = (state = initialData, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PRODUCT_CONFIG:
      return payload;
    case UPDATE_PRODUCT_CONFIG:
      return payload;
    default:
      return state;
  }
};

export default productConfigReducer;
