import React, { useMemo } from "react";
import { useCallback, useState, useEffect } from "react";
import { Pagination, Message } from "semantic-ui-react";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { logServerError } from "../common/logs";

const defaultOptions = {
  limit: 20,
  clearOnLoad: true,
  initialLoad: true,
  loadDebounce: 500,
  dataProperty: "items",
};

/**
 *
 * @param {({skip:number,limit:number,[string]:string})=>Promise<{data:{items:Array<any>,currentPage:number,totalPages:number}}} getRequest
 * @param {
        {
          limit?:number,
          clearOnLoad?:boolean,
          initialLoad?:boolean,
          dataProperty?:string
        }|undefined} options
 */
function usePagination(getRequest, options = {}, id) {
  const { limit, clearOnLoad, initialLoad, loadDebounce, dataProperty } =
    useMemo(
      () => ({ ...defaultOptions, ...options }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

  const [items, setItems] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [filtersData, setFiltersData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadItemsSubject] = useState(new Subject());

  const loadItems = useCallback(
    async (page, filtersData) => {
      setFiltersData(filtersData);
      setCurrentPage(page);
      if (clearOnLoad) {
        setItems(null);
      }
      setIsLoading(true);
      if(id) try {
        const {
          data: { totalPages },
          data,
        } = await getRequest(id, {
          skip: (page - 1) * limit,
          limit,
          ...filtersData,
        });
        const items = data[dataProperty];
        setItems(items);
        setTotalPages(totalPages);
      } catch (err) {
        logServerError(err);
      }
     else try {
        const {
          data: { totalPages },
          data,
        } = await getRequest({
          skip: (page - 1) * limit,
          limit,
          ...filtersData,
        });
        const items = data[dataProperty];
        setItems(items);
        setTotalPages(totalPages);
      } catch (err) {
        logServerError(err);
      }
      setIsLoading(false);
    },
    [clearOnLoad, id, getRequest, limit, dataProperty],
  );

  useEffect(() => {
    const subscription = loadItemsSubject
      .pipe(debounceTime(loadDebounce))
      .subscribe(({ page, filters }) => {
        loadItems(page, filters);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [loadItemsSubject, loadItems, loadDebounce]);

  const setPage = useCallback(
    (page) => {
      loadItems(page, filtersData);
    },
    [filtersData, loadItems],
  );

  const refreshItems = useCallback(
    () => loadItems(currentPage, filtersData),
    [filtersData, loadItems, currentPage],
  );

  const deboucedLoadItems = useCallback(
    (page, filters) => {
      loadItemsSubject.next({ page, filters });
    },
    [loadItemsSubject],
  );

  const Paginator = useCallback(() => {
    if (totalPages > 0 || !items) {
      return (
        <Pagination
          activePage={currentPage}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          totalPages={totalPages}
          floated="right"
          onPageChange={(e, { activePage }) => {
            setPage(activePage);
          }}
        />
      );
    }
    return <Message size="big">No results!</Message>;
  }, [items, totalPages, currentPage, setPage]);

  useEffect(() => {
    if (initialLoad) {
      loadItems(1);
    }
    // disabled for a reason, dont load on every change of options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    items,
    totalPages,
    currentPage,
    setPage,
    loadItems,
    deboucedLoadItems,
    refreshItems,
    setItems,
    Paginator,
    isLoading,
  };
}

export { usePagination };
