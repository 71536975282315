import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";
import { get } from "lodash";

export const TRK_SELECT_VALUES = {
  ISSUING_COUNTRIES: "ISSUING_COUNTRIES",
  TRAVEL_DOCUMENT: "TRAVEL_DOCUMENT",
  VISA_COUNTRIES: "VISA_COUNTRIES",
  SUPPORT_TYPE: "SUPPORT_TYPE",
  COUNTRY_MAPPING: "COUNTRY_MAPPING",
  BIRTH_COUNTRIES: "BIRTH_COUNTRIES",
  ISSUING_COUNTRIES_PROPERTIES: "ISSUING_COUNTRIES_PROPERTIES",
};

export const trkSchema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      siteId: {
        label: "Referral Site",
      },
      orderId: {
        label: "Order ID",
      },
      orderIdHistory:{
        label:"Order ID History"
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      botRuns: {
        label: "Number of bot runs",
      },
    },
  },
  personalInfoSegment: {
    label: "Personal information",
    type: TYPES.SEGMENT,
    data: {
      userEmail: {
        label: "User contact email",
        type: TYPES.EMAIL,
      },
      submissionEmail: {
        label: "User submission email",
        type: TYPES.TEXT,
      },
      firstName: {
        label: "First name",
        type: TYPES.TEXT,
      },
      lastName: {
        label: "Family name",
        type: TYPES.TEXT,
      },
      fathersName: {
        label: "Father's name",
        type: TYPES.TEXT,
      },
      mothersName: {
        label: "Mother's name",
        type: TYPES.TEXT,
      },
      birthCountry: {
        label: "Birth country",
        type: TYPES.SELECT,
        values: TRK_SELECT_VALUES.BIRTH_COUNTRIES,
      },
      birthCity: {
        label: "Birth city",
        type: TYPES.TEXT,
      },
      birthDate: {
        label: "Birth date",
        type: TYPES.DATE,
      },
      arrivalDate: {
        label: "Arrival date",
        type: TYPES.DATE,
      },
    }
  },
  passportInfoSegment: {
    label: "Passport information",
    type: TYPES.SEGMENT,
    data: {
      issuingCountry: {
        label: "Country of citizenship",
        type: TYPES.SELECT,
        values: TRK_SELECT_VALUES.ISSUING_COUNTRIES,
        geoLocation: "birthGeoFound",
        geoLocationProps: ["birthCity"],
      },
      travelDocument: {
        label: "Travel document",
        type: TYPES.SELECT,
        values: TRK_SELECT_VALUES.TRAVEL_DOCUMENT,
      },
      passportNumber: {
        label: (applicant) =>
          applicant.travelDocument === "KMB" ? "ID Card number" : "Passport number",
        type: TYPES.TEXT,
      },
      issuanceDate: {
        label: (applicant) =>
          applicant.travelDocument === "KMB"
            ? "ID Card issuing date"
            : "Passport issuing date",
        type: TYPES.DATE,
      },
      expirationDate: {
        label: (applicant) =>
          applicant.travelDocument === "KMB"
            ? "ID Card expiry date"
            : "Passport expiry date",
        type: TYPES.DATE,
      },
      supportingDoc: {
        label: "Supporting document",
        type: TYPES.NESTED,
        newTable: true,
        customDependency(applicant, path, schemaData) {
          const { issuingCountry, travelDocument } = applicant;
          if (!issuingCountry || !travelDocument) {
            return false;
          }
          const countryInfo =
            schemaData[TRK_SELECT_VALUES.ISSUING_COUNTRIES_PROPERTIES][
              issuingCountry
            ];
          return (
            countryInfo &&
            countryInfo.supportingDocType &&
            countryInfo.supportingDocType[travelDocument]
          );
        },
        nested: {
          docType: {
            label: "Supporting doc type",
            type: TYPES.SELECT,
            path: "supportingDoc",
            values: (applicant, path, schemaData) => {
              const { travelDocument, issuingCountry } = applicant;
              const {
                [TRK_SELECT_VALUES.SUPPORT_TYPE]: SUPPORT_TYPE,
                [TRK_SELECT_VALUES.ISSUING_COUNTRIES_PROPERTIES]: ISSUING_COUNTRIES_PROPERTIES,
              } = schemaData;
              const supportingType =
                ISSUING_COUNTRIES_PROPERTIES[issuingCountry].supportingDocType[
                  travelDocument
                ];
              if (supportingType === "type1") {
                const ST = { ...SUPPORT_TYPE };
                delete ST[0];
                return ST;
              }
              return SUPPORT_TYPE;
            },
          },
          from: {
            label: "Supporting doc from",
            type: TYPES.SELECT,
            values: (applicant, path, schemaData) => {
              const { travelDocument, issuingCountry } = applicant;
              const {
                [TRK_SELECT_VALUES.COUNTRY_MAPPING]: COUNTRY_MAPPING,
                [TRK_SELECT_VALUES.ISSUING_COUNTRIES_PROPERTIES]: ISSUING_COUNTRIES_PROPERTIES,
              } = schemaData;
              const type = get(applicant, `${path}.docType`);
              const supportingType =
                ISSUING_COUNTRIES_PROPERTIES[issuingCountry].supportingDocType[
                  travelDocument
                ];
              return COUNTRY_MAPPING[type][supportingType];
            },
            dependsOn: "supportingDoc.docType",
            dependantOnValues: [1, 2],
            path: "supportingDoc",
          },
          number: {
            label: "Supporting doc number",
            type: TYPES.TEXT,
            path: "supportingDoc",
            dependsOn: "supportingDoc.docType",
            dependantOnValues: [1, 2],
          },
          hasExpirationDate: {
            label: "Does it have expiration date",
            type: TYPES.CHECKBOX,
            path: "supportingDoc",
            dependsOn: "supportingDoc.docType",
            dependantOnValues: [1, 2],
          },
          expirationDate: {
            label: "Supporting expiration date",
            type: TYPES.DATE,
            dependsOn: ["supportingDoc.docType", "supportingDoc.hasExpirationDate"],
            path: "supportingDoc",
            dependantOnValues: [[1, 2], [true]],
          },
        },
      },
    },
  },
  contactInfoSegment: {
    label: "Contact information",
    type: TYPES.SEGMENT,
    data: {
      address: {
        label: "Address",
        note: "Parts of this section will be used to verify the client's ID on Calls/Chats",
        type: TYPES.NESTED,
        newTable: true,
        nested: {
          addressLine1: {
            label: "Address line 1",
            type: TYPES.TEXT,
            path: "address",
          },
          addressLine2: {
            label: "Address line 2",
            type: TYPES.TEXT,
            path: "address",
          },
          apartmentNumber: {
            label: "Apartment number",
            type: TYPES.TEXT,
            path: "address",
          },
          city: {
            label: "City",
            type: TYPES.TEXT,
            path: "address",
          },
          state: {
            label: "State",
            type: TYPES.TEXT,
            path: "address",
          },
          country: {
            label: "Country",
            type: TYPES.SELECT,
            values: TRK_SELECT_VALUES.BIRTH_COUNTRIES,
            path: "address",
          },
        },
      },
      phoneNumber: {
        label: "Phone number",
        type: TYPES.TEXT,
      },
      phoneCode: {
        label: "Phone number code",
      },
    },
  },
};
