import React from "react";
import { Table, Checkbox } from "semantic-ui-react";
import { ALLOWED_REFERRAL_SITES } from "../../../../../EVisa/pages/ApplicantsEdit/components/constants";

const NexusReviewAndCertification = ({ referralSite = ALLOWED_REFERRAL_SITES.DEFAULT }) => {
  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell colSpan="2">
          <h4> Declaration</h4>
          <div>
            <p>
              I declare that the information I have provided in this application
              is truthful, complete, and accurate to the best of my knowledge. I
              understand that attempting to obtain a NEXUS program by wilful
              misrepresentation of a material fact or fraud, may lead to
              refusal. I am aware that the government fee is not refunded if the
              NEXUS application is refused.
            </p>
            <p>
              The authority to collect the information on this application, any
              supporting documentation, fingerprints, and other requested
              information is contained in Titles 8 and 19 of the U.S. Code and
              corresponding regulations. Furnishing the information on this form
              is voluntary; however, failure to provide all the requested
              information may be a basis for denying your application. It may
              also be provided to other government agencies (Federal, state,
              local, and/or foreign) as permitted under the Privacy Act of 1974,
              5 U.S.C. & 552a (2002) and other applicable law. All applicants
              are subject to a check of criminal information databases,
              immigration and customs databases, and other enforcement databases
              in order to determine eligibility for this program.
            </p>
          </div>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell colSpan="2">
          <h4> FBI Privacy Statements</h4>
          <div>
            <h5>FBI Privacy Act Statements</h5>
            <p>
              This privacy act statement is located on the back of the FD-258
              fingerprint card.
            </p>
            <p>
              Authority: The FBI’s acquisition, preservation, and exchange of
              fingerprints and associated information is generally authorized
              under 28 U.S.C. 534. Depending on the nature of your application,
              supplemental authorities include Federal statutes, State statutes
              pursuant to Pub. L. 92-544, Presidential Executive Orders, and
              federal regulations. Providing your fingerprints and associated
              information is voluntary; however, failure to do so may affect
              completion or approval of your application.
            </p>
            <p>
              Principal Purpose: Certain determinations, such as employment,
              licensing, and security clearances, may be predicated on
              fingerprint-based background checks. Your fingerprints and
              associated information/biometrics may be provided to the
              employing, investigating, or otherwise responsible agency, and/or
              the FBI for the purpose of comparing your fingerprints to other
              fingerprints in the FBI’s Next Generation Identification (NGI)
              system or its successor systems (including civil, criminal, and
              latent fingerprint repositories) or other available records of the
              employing, investigating, or otherwise responsible agency. The FBI
              may retain your fingerprints and associated information/biometrics
              in NGI after the completion of this application and, while
              retained, your fingerprints may continue to be compared against
              other fingerprints submitted to or retained by NGI.
            </p>
            <p>
              Routine Uses: During the processing of this application and for as
              long thereafter as your fingerprints and associated
              information/biometrics are retained in NGI, your information may
              be disclosed pursuant to your consent, and may be disclosed
              without your consent as permitted by the Privacy Act of 1974 and
              all applicable Routine Uses as may be published at any time in the
              Federal Register, including the Routine Uses for the NGI system
              and the FBI’s Blanket Routine Uses. Routine uses include, but are
              not limited to, disclosures to: employing, governmental or
              authorized non-governmental agencies responsible for employment,
              contracting, licensing, security clearances, and other suitability
              determinations; local, state, tribal, or federal law enforcement
              agencies; criminal justice agencies; and agencies responsible for
              national security or public safety.
            </p>
          </div>
          <h5>Noncriminal Justice Applicant's Privacy Rights</h5>
          <p>
            As an applicant who is the subject of a national fingerprint-based
            criminal history record check for a noncriminal justice purpose
            (such as an application for employment or a license, an immigration
            or naturalization matter, security clearance, or adoption), you have
            certain rights which are discussed below.
          </p>
          <p>
            You must be provided written notification that your fingerprints
            will be used to check the criminal history records of the FBI.
          </p>
          <p>
            You must be provided, and acknowledge receipt of, an adequate
            Privacy Act Statement when you submit your fingerprints and
            associated personal information. This Privacy Act Statement should
            explain the authority for collecting your information and how your
            information will be used, retained, and shared.
          </p>
          <p>
            If you have a criminal history record, the officials making a
            determination of your suitability for the employment, license, or
            other benefit must provide you the opportunity to complete or
            challenge the accuracy of the information in the record
          </p>
          <p>
            The officials must advise you that the procedures for obtaining a
            change, correction, or update of your criminal history record are
            set forth at Title 28, Code of Federal Regulations (CFR), Section
            16.34.
          </p>
          <p>
            If you have a criminal history record, you should be afforded a
            reasonable amount of time to correct or complete the record (or
            decline to do so) before the officials deny you the employment,
            license, or other benefit based on information in the criminal
            history record.
          </p>
          <p>
            You have the right to expect that officials receiving the results of
            the criminal history record check will use it only for authorized
            purposes and will not retain or disseminate it in violation of
            federal statute, regulation or executive order, or rule, procedure
            or standard established by the National Crime Prevention and Privacy
            Compact Council.
          </p>
          <p>
            If agency policy permits, the officials may provide you with a copy
            of your FBI criminal history record for review and possible
            challenge. If agency policy does not permit it to provide you a copy
            of the record, you may obtain a copy of the record by submitting
            fingerprints and a fee to the FBI. Information regarding this
            process may be obtained at
            https://www.fbi.gov/services/cjis/identity-history-summary-checks.
          </p>
          <p>
            If you decide to challenge the accuracy or completeness of your FBI
            criminal history record, you should send your challenge to the
            agency that contributed the questioned information to the FBI.
            Alternatively, you may send your challenge directly to the FBI. The
            FBI will then forward your challenge to the agency that contributed
            the questioned information and request the agency to verify or
            correct the challenged entry. Upon receipt of an official
            communication from that agency, the FBI will make any necessary
            changes/corrections to your record in accordance with the
            information supplied by that agency. (See 28 CFR 16.30 through
            16.34.)
          </p>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell colSpan="2">
          <h4> Canada Privacy Act Statements</h4>
          <div>
            <p>
              The information you provide in your application, including
              supporting documentation and biometric data, is collected by the
              Canada Border Services Agency (CBSA) and is protected pursuant to
              both the Customs Act and the Privacy Act. In accordance with
              Canadian laws and regulations, this information will be shared
              with other government departments or agencies in Canada and the
              United States of America for the purpose of the operation of the
              NEXUS program and to conduct applicable checks and verifications
              to determine your eligibility and continued eligibility in the
              NEXUS program. If the required information is not provided, your
              application may not be processed and the authorization may not be
              granted.
            </p>
            <p>
              Individuals to whom the information relates have rights of access
              to, correction of and protection of, their personal information
              under the Privacy Act. The information collected is described in
              Personal Information Bank # CBSA PPU 031. Instructions for
              obtaining information are provided in Info Source, which is
              available at public libraries, government public reading rooms and
              on the Internet at: http://infosource.gc.ca.
            </p>
            <p>
              Principal Purpose: Certain determinations, such as employment,
              licensing, and security clearances, may be predicated on
              fingerprint-based background checks. Your fingerprints and
              associated information/biometrics may be provided to the
              employing, investigating, or otherwise responsible agency, and/or
              the FBI for the purpose of comparing your fingerprints to other
              fingerprints in the FBI’s Next Generation Identification (NGI)
              system or its successor systems (including civil, criminal, and
              latent fingerprint repositories) or other available records of the
              employing, investigating, or otherwise responsible agency. The FBI
              may retain your fingerprints and associated information/biometrics
              in NGI after the completion of this application and, while
              retained, your fingerprints may continue to be compared against
              other fingerprints submitted to or retained by NGI.
            </p>
            <h5>Consent Statement</h5>
            <p>
              I understand that any information gathered for the purposes of
              this application, including any supporting documentation,
              background information, biometric data and information obtained
              from the relevant files of law enforcement agencies, including
              intelligence gathered for law enforcement purposes, will be used
              for the purpose of the operation of the NEXUS program and to
              conduct applicable checks and verifications to determine
              eligibility and continued eligibility in the NEXUS program as
              described in the Presentation of Persons (2003) Regulations. My
              contact information may also be used by the CBSA to send me
              notifications related to changes to the NEXUS program.
            </p>
          </div>

          <p>
            In addition, I understand that my personal information gathered for
            the purposes of this application, including my supporting
            documentation, background information, biometric data, and any other
            information obtained and collected for the purpose of the operation
            of the NEXUS program and to conduct applicable checks and
            verifications to determine my eligibility and continued eligibility
            in the NEXUS program, may be accessed and used by the CBSA, as well
            as by other government departments or agencies in Canada (including
            the Royal Canadian Mounted Police and the Canadian Security
            Intelligence Service), in accordance with the Privacy Act.
          </p>
          <p>
            In addition to the above-noted use by the CBSA and other Canadian
            government departments and agencies, I also understand that the CBSA
            will share its determination of my eligibility to the NEXUS program,
            based on Canadian criteria, with the United States Department of
            Homeland Security (DHS). The DHS will, in turn, disclose to the CBSA
            its determination of my eligibility based on the American criteria.{" "}
          </p>
          <p>
            If you do not consent to the above-noted collection, use and sharing
            of your personal information, your application cannot be processed
            and an authorization cannot be granted.
          </p>
          <p>
            Do you consent to the above-noted collection, use, and sharing of
            your personal information AND do you certify that all the
            information given on this application, and in support of this
            application, is provided voluntarily and is true, accurate, and
            complete, and that you have read, understood, and agree to abide by
            all conditions applicable to the program to which you apply and to
            the use of the associated authorization, including all instructions
            and notices accompanying this application?
          </p>
          <p>
            I certify that all information given on this application(s), and in
            support of this application(s), was provided voluntarily and is true
            and complete. I understand that any information on this
            application(s), including any supporting documentation, background
            information, finger and biometric data will be shared among law
            enforcement and other government agencies in accordance with
            applicable laws.
          </p>
          <p>
            I certify that I have read, understood, and agree to abide by all
            conditions required for use of the program(s) I'm applying for,
            including all instructions and notices accompanying this
            application(s).
          </p>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell colSpan="2">
          <h5>Third Party Authorization</h5>
          <p>
            I understand that I am applying via GovAssist, LLC; a third-party
            that is not affiliated with the government, that the third-party
            cannot guarantee application approval, that the third-party service
            is offered to help expedite the process, and that I have the option
            to apply directly with the pertinent government websites. I also
            understand that my purchase consists of a processing and service fee
            to GovAssist, which DOES NOT include the Form non-refundable
            government fee, which is required.
          </p>
          <p>
            I confirm and authorize GovAssist, LLC to assist me, by using their
            processing service in completing and reviewing my application. I
            understand and acknowledge that I have not been offered legal advice
            and that this company or its agents and affiliates have never
            claimed to be qualified to offer legal advice under any
            circumstance. I also confirm that I am submitting my application and
            signing it myself.
          </p>
          <p>
            By obtaining services from the GovAssist website, I have agreed to
            the following:{" "}
            <a href={`https://${referralSite}/terms-conditions`}>
              Terms & Conditions
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/refund-policy`}>
              Refund Policy{" "}
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/privacy-policy`}>
              Privacy Policy{" "}
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/legal-disclaimer`}>
              Disclaimer{" "}
            </a>
          </p>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>
    </React.Fragment>
  );
};

export default NexusReviewAndCertification;
