/* eslint-disable no-loop-func */
import moment from "moment";
import { isEqual } from "lodash";
import { v1 as uuid } from "uuid";
import { makeColorForShift } from "./makeColorForShift";
import { makeArrayOfHours } from "./makeArrayOfHours";

/**
 *
 * Make single shift segments
 *
 * @typedef {{segmentGroup: { firstSegment: string, lastSegment: string }}} segmentGroup
 * @typedef {{segmentGroup1: segmentGroup, segmentGroup2: segmentGroup, segmentGroup3: segmentGroup }} shiftGroup
 *
 * @param {Date} firstDate - start date
 * @param {Date} lastDate - end date
 * @param {shiftGroup} shifts - shift group
 */
export const makeSingleShiftSegments = (firstDate, lastDate, shifts) => {
  const resShiftsGroupSortable = makeArrayOfHours(shifts);

  // get hour and minutes from props
  //
  const firstHourMinutes = moment(firstDate).format("HH:mm").split(":");
  const lastHourMinutes = moment(lastDate).format("HH:mm").split(":");

  let resShiftDisplay = [];

  resShiftsGroupSortable.forEach((s) => {
    // get start (first part) of shift reports
    //
    if (
      s.hours.includes(parseInt(firstHourMinutes[0])) ||
      s.hours[0] > parseInt(firstHourMinutes[0])
    ) {
      // get first hours of shift reports
      //
      const sHours = [
        ...s.hours.filter((v) => v >= parseInt(firstHourMinutes[0])),
      ];

      // add first part to shift report
      //
      resShiftDisplay = [
        ...resShiftDisplay,
        {
          id: uuid(),
          hours: sHours,
          shifts: s.shifts,
          colors: s.shifts.split(" ").map((shift) => makeColorForShift(shift)),
          flexGrow: sHours.length,
        },
      ];
    }
  });

  // get number of days between start and end date
  //
  let numDays = moment(lastDate).diff(firstDate, "days") - 1;

  // add shift reports for all days between start and end date
  //
  while (numDays > 0) {
    resShiftsGroupSortable.forEach((s) => {
      resShiftDisplay = [
        ...resShiftDisplay,
        {
          id: uuid(),
          hours: [...s.hours],
          shifts: s.shifts,
          colors: s.shifts.split(" ").map((shift) => makeColorForShift(shift)),
          flexGrow: s.hours.length,
        },
      ];
    });
    numDays--;
  }

  // add last hours in last day of shift report
  //
  resShiftsGroupSortable.forEach((s) => {
    const lastHourInt = parseInt(lastHourMinutes[0]);

    // get hours from start day to last hour in end date
    //
    if (
      s.hours.includes(lastHourInt + 1) ||
      (s.hours.includes(lastHourInt) && lastHourInt === 23) ||
      s.hours[s.hours.length - 1] < lastHourInt + 1
    ) {
      let sHours = s.hours.filter((v) => v <= lastHourInt + 1);

      // for last segment we calculating part of hour (e.g. 17:45)
      //
      if (
        s.hours.includes(lastHourInt + 1) ||
        (s.hours.includes(lastHourInt) && lastHourInt === 23)
      ) {
        sHours.push(sHours[sHours.length - 1] + 1);
      } else {
        sHours.push(sHours[sHours.length - 1]);
      }

      // add last part in result array
      //
      resShiftDisplay = [
        ...resShiftDisplay,
        {
          id: uuid(),
          hours: sHours,
          shifts: s.shifts,
          colors: s.shifts.split(" ").map((shift) => makeColorForShift(shift)),
          flexGrow: sHours.length,
        },
      ];
    }
  });

  const lastSegment = resShiftDisplay[resShiftDisplay.length - 1];

  // last part of report maybe isn't full hour, and his flexGrow maybe isn't one
  //
  if (lastHourMinutes[1] > 0) {
    lastSegment.flexGrow -= 1 - parseInt(lastHourMinutes[1]) / 60;
    lastSegment.hours[lastSegment.hours.length - 1] =
      lastSegment.hours[lastSegment.hours.length - 1] +
      ":" +
      lastHourMinutes[1];
  }

  // if some hours belong to the same shift (the same shifts), then those parts should be merged
  //
  for (let j = 0; j < resShiftDisplay.length - 1; j++) {
    if (isEqual(resShiftDisplay[j].colors, resShiftDisplay[j + 1].colors)) {
      const mergedShift = {
        ...resShiftDisplay[j],
        id: uuid(),
        hours: [...resShiftDisplay[j].hours, ...resShiftDisplay[j + 1].hours],
        flexGrow: resShiftDisplay[j].flexGrow + resShiftDisplay[j + 1].flexGrow,
      };
      resShiftDisplay.splice(j--, 2, mergedShift);
    }
  }

  return resShiftDisplay;
};
