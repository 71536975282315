import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import twilioService from "./services/twilioService";
import { getCurrentUserData } from "./apis/userApi";
import { FETCH_CURRENT_USER } from "./store/actions/actionTypes";
import { Loader } from "semantic-ui-react";
import Router from "./Router";
import UsersContextProvider from "./common/UsersContext";
import { AuthService } from "./services/authService";
import { CredentialsService } from "./services/credentialsService";
import socketService from "./services/socketService";
import apis from "./apis";
import { FETCH_PRODUCT_CONFIG } from "./store/actions/productConfigActions";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { loggedIn } = useSelector((state) => ({
    loggedIn: state.auth.loggedIn,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn === false) {
      setIsLoading(false);
    }
    if (loggedIn) {
      setIsLoading(true);
      const getProductConfig = async () => {
        try {
          const {
            data: {
              aus,
              ds160,
              esta,
              eta,
              ind,
              vn,
              nz,
              trk,
              usps,
              inv_letter,
              schengen,
              diversity,
              ge,
              sg,
              ds11,
              ds82,
              ds64,
              sl,
              tsa,
              sentri,
              nexus,
            },
          } = await apis.productConfig.fetchProductConfig();

          dispatch({
            type: FETCH_PRODUCT_CONFIG,
            payload: {
              aus,
              ds160,
              esta,
              eta,
              ind,
              vn,
              nz,
              trk,
              usps,
              inv_letter,
              schengen,
              diversity,
              ge,
              sg,
              ds11,
              ds82,
              ds64,
              sl,
              tsa,
              sentri,
              nexus,
            },
          });
        } catch (error) {
          console.error(error);
        }
        setIsLoading(false);
      };

      getProductConfig();
    }
  }, [loggedIn, dispatch]);

  useEffect(() => {
    async function fetchSimulatedUserData() {
      const simulatedUserId = CredentialsService.getUserId();
      if (CredentialsService.isSimulation()) {
        const { user } = await AuthService.simulateUserLogin(simulatedUserId);
        dispatch({
          type: FETCH_CURRENT_USER,
          payload: user,
        });
      }
    }
    fetchSimulatedUserData();
  }, [dispatch]);

  useEffect(() => {
    if (!CredentialsService.isSimulation()) {
      const init = async () => {
        try {
          const { data } = await getCurrentUserData();
          dispatch({
            type: FETCH_CURRENT_USER,
            payload: data,
          });
        } catch (err) {
          dispatch({
            type: FETCH_CURRENT_USER,
            payload: null,
          });
        }
      };
      if (loggedIn === undefined) {
        init();
      }
    }
  }, [dispatch, loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      socketService.connect();
      twilioService.loadToken();
      return () => {
        socketService.disconnect();
      };
    }
  }, [loggedIn]);

  if (loggedIn === undefined || isLoading) {
    return <Loader active />;
  }

  return (
    <UsersContextProvider>
      <Router />
    </UsersContextProvider>
  );
};

export default App;
