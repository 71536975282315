import { useCallback, useState } from "react";

export function useCallbackRef() {
  const [ref, setRef] = useState();

  const refCallback = useCallback((element) => {
    if (element !== null) {
      setRef(element);
    }
  }, []);

  return [ref, refCallback];
}
