import React from "react";
import {
  Partners,
  // QAReportPage,
  // BillingReport,
} from "./components";
// import { QA_REPORT_FOR } from "../../constants/qaReports";
import MenuTabs from "../../../../../../../components/MenuTabs";
import CallsTable from "../../components/CallsTable";
// import ManagerAvailabilityReport from "../ManagerArea/components/ManagerAvailabilityReport";
import ExportTool from "../../components/ExportTool";
import DocumentHandler from "../../components/DocumentHandler";
import ReviewCertification from "../../components/ReviewCertification/ReviewCertification.component";
import DocHandlerTemplates from "../../components/DocHandlerTemplates";
import UserLogs from "../../components/UserLogs";

const tabs = [
  {
    name: "Partners",
    component: <Partners />,
    route: "partners",
  },
  
  {
    name: "Calls",
    component: <CallsTable />,
    route: "calls",
  },
  // {
  //   name: "QA Team Report",
  //   component: <QAReportPage type={QA_REPORT_FOR.QA_MANAGER} />,
  //   route: "qa-team-report",
  // },
  
  // {
  //   name: "General QA Report",
  //   component: <QAReportPage type={QA_REPORT_FOR.MANAGER} />,
  //   route: "general-qa-report",
  // },
  // {
  //   name: "Reports availability",
  //   component: <ManagerAvailabilityReport />,
  //   route: "availability",
  // },
  // {
  //   name: "Billing Report",
  //   component: <BillingReport />,
  //   route: "billing-report",
  // },
  {
    name: "Export Tool",
    component: <ExportTool />,
    route: "export-tool",
  },
  {
    name: "Brochures",
    component: <DocumentHandler />,
    route: "brochures",
  },
  {
    name: "Review and Certification",
    component: <ReviewCertification />,
    route:"review-certification"
  },
  {
    name: "Doc. Handler Templates",
    component: <DocHandlerTemplates />,
    route:"document-handler-templates"
  },
  {
    name: "User Logs",
    component: <UserLogs />,
    route:"user-logs"
  }
];

class AdminArea extends React.Component {
  render() {
    return (
      <MenuTabs
        {...this.props}
        tabs={tabs}
        defaultRoute="my-account/admin"
        pathParam="subRoute"
      />
    );
  }
}

export default AdminArea;
