import React, { useCallback, useEffect, useState } from "react";
import { Button, Header, Input, Segment, Table, Select, Form } from "semantic-ui-react";
import { columns } from "../../constants/botStatisticConstants";
import { PRODUCT_NAMES_WITH_BOTS } from "../../../../routers/Products/constants/productNames";
import { getPaginatedBotErrorStatisticsByProduct } from "../../../../../../../apis/configuration/botsErrorStatisticApi";
import {
  notEmptyValidator,
  useFilters,
  usePagination,
} from "../../../../../../../hooks";
import { DateInput } from "semantic-ui-calendar-react";
import {
  getEndOfDayNoOffset,
  getStartOfDayNoOffset,
} from "../../../../../../../common/date-format";
import moment from "moment";
import './BotsErrorStatisticConfiguration.styles.scss'

const BotsErrorStatisticConfiguration = () => {
  const [selectedProduct, setSelectedProduct] = useState(Object.keys(PRODUCT_NAMES_WITH_BOTS)[0]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const combinedStatisticColumns = [
    ...columns,
    { key: "lastErrorTime", label: "Last error time" }
  ];

  const getProductDisplayName = (productName) => {
    return PRODUCT_NAMES_WITH_BOTS[productName] || productName;
  };

  const {
    items: errorStatistics,
    Paginator,
    loadItems: loadStatistics,
    isLoading,
  } = usePagination(getPaginatedBotErrorStatisticsByProduct, {
    initialLoad: false,
    clearOnLoad: true,
  }, selectedProduct);

  const { filters, normalizedFilters, setFormFilter, resetFilters } = useFilters(
    {
      searchString: "",
    },
    {
      localStorageKey: "bots-error-statistics",
      filterValidators: {
        searchString: notEmptyValidator(),
      },
      filterDebounces: {
        searchString: 700,
      },
      temporaryFilters: ["searchString"],
    },
  );

  const handleResetFilters = useCallback(() => {
    setDateFrom("");
    setDateTo("");
    resetFilters();
  }, [resetFilters]);

  useEffect(() => {
    loadStatistics(1, { ...normalizedFilters });
  }, [loadStatistics, normalizedFilters]);


  const renderFilters = useCallback(() => {
    const { searchString } = filters;

    return (
      <div
        style={{ float: "right", display: "flex", alignItems: "center", gap: "20px" }}
        className="users-filters"
      >
        <DateInput
          name="dateFrom"
          dateFormat={"YYYY-MM-DD"}
          clearable={true}
          placeholder="Date from"
          value={dateFrom}
          maxDate={moment().format("YYYY-MM-DD")} 
          popupPosition="top right"
          animation="none"
          duration={0}
          iconPosition="left"
          onChange={(_, field) => {
            const { value } = field;
            setDateFrom(value);
            setFormFilter(null, {
              name: "dateFrom",
              value: getStartOfDayNoOffset(value),
            });
            if (new Date(dateTo) < new Date(value)) {
              setDateTo(value); 
            }
          }}
        />
        <DateInput
          name="dateTo"
          dateFormat={"YYYY-MM-DD"}
          clearable={true}
          placeholder="Date to"
          value={dateTo}
          minDate={dateFrom} // Set minDate to be Date from
          maxDate={moment().format("YYYY-MM-DD")} 
          popupPosition="top right"
          animation="none"
          duration={0}
          iconPosition="left"
          onChange={(_, field) => {
            const { value } = field;
            setDateTo(value);
            setFormFilter(null, {
              name: "dateTo",
              value: getEndOfDayNoOffset(value),
            });
          }}
        />

        <Input
          placeholder="Search (by Error message)"
          id="search-input"
          name="searchString"
          value={searchString || ""}
          onChange={setFormFilter}
          style={{ minWidth: "250px" }}
        />

        <Button onClick={handleResetFilters}>
          {isLoading ? "Loading.." : "Refresh"}
        </Button>
      </div>
    );
  }, [dateFrom, dateTo, filters, handleResetFilters, isLoading, setFormFilter]);

  return (
    <>
      <Form className="bot-statistics-select">
        <Form.Field>
          <label className="bot-statistics-label">Select Product</label>
          <Select
            className="bot-statistics-select"
            options={Object.keys(PRODUCT_NAMES_WITH_BOTS).map((product) => ({
              key: product,
              text: PRODUCT_NAMES_WITH_BOTS[product],
              value: product,
            }))}
            value={selectedProduct}
            onChange={(event, { value }) => setSelectedProduct(value)}
          />
        </Form.Field>
      </Form>

      <Segment.Group>
        <Segment className="tableTitle adminTable" inverted>
          <Header size="large" floated="left">
            Bot Error Statistics
          </Header>
          {renderFilters()}
        </Segment>
        <Table sortable celled striped>
          <Table.Header>
            <Table.Row>
              {combinedStatisticColumns.map((column) => (
                <Table.HeaderCell
                  key={column.key}
                  width={1}
                >
                  {column.label}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {errorStatistics &&
              errorStatistics.map((error, index) => (
                <Table.Row key={index}>
                  {combinedStatisticColumns.map((column) => {
                    const { key } = column;
                    let cellValue = error[key];

                            if (key === "product") {
                              cellValue = getProductDisplayName(cellValue);
                            }

                            if (key === "alertInfo" && error.alertInfo) {
                              if (!error.alertInfo.enabled) {
                                cellValue = "Enabled: No";
                              } else {
                                cellValue = (
                                  <ul>
                                    <li>
                                      Maximum Count Per Interval:{" "}
                                      {error.alertInfo.maximumCountPerInterval}
                                    </li>
                                    <li>
                                      Hours Period For Maximum Count:{" "}
                                      {
                                        error.alertInfo
                                          .hoursPeriodForMaximumCount
                                      }
                                    </li>
                                    <li>
                                      Emails:{" "}
                                      {error.alertInfo.emails
                                        ? error.alertInfo.emails.join(", ")
                                        : ""}
                                    </li>
                                    <li>
                                      Enabled:{" "}
                                      {error.alertInfo.enabled ? "Yes" : "No"}
                                    </li>
                                  </ul>
                                );
                              }
                            }

                            if (key === "lastErrorTime") {
                              cellValue = new Date(error[key]).toLocaleString();
                            }

                            return (
                              <Table.Cell key={error._id + key} width={1}>
                                {cellValue}
                              </Table.Cell>
                            );
                          })}
                        </Table.Row>
                      ))}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan={combinedStatisticColumns.length}>
                        <Paginator />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              </Segment.Group>
    </>
  );
};

export default BotsErrorStatisticConfiguration;
