import React, { useState, useMemo } from "react";
import { PerformanceTrendQAReport, Chart } from "../../..";
import "./CategoryAndProductChart.styles.scss";

const CHART_LABELS = ["Positive", "Negative"];

const CategoryAndProductChart = ({
  category,
  chartConfig,
  chartData,
  filter,
  categoryFilter,
  chartDataSeparated,
}) => {
  const [openPerformanceTrend, setOpenPerformanceTrend] = useState(false);

  const header = useMemo(() => categoryFilter[category], [
    categoryFilter,
    category,
  ]);
  return (
    <div
      className="category"
      title="Show Performance Trend"
      onClick={() => setOpenPerformanceTrend(true)}
    >
      <h4>{header}</h4>
      {chartDataSeparated?.[category]?.[filter] && (
        <PerformanceTrendQAReport
          open={openPerformanceTrend}
          setOpen={setOpenPerformanceTrend}
          qaReportData={chartDataSeparated[category][filter]}
          performanceTrendName={header}
        />
      )}
      {chartData[filter] && chartData[filter][category] ? (
        <Chart
          chartId={filter + category}
          chartData={chartData[filter][category]}
          chartLabels={CHART_LABELS}
          chartConfig={chartConfig}
        />
      ) : (
        <span className="category__no-data">No Data For Category</span>
      )}
    </div>
  );
};

export default CategoryAndProductChart;
