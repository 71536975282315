export class LocalStorageService {
  static getItem(key) {
    const data = localStorage.getItem(key);
    if (data && data !== "undefined") {
      return JSON.parse(data);
    }

    return null;
  }

  static setItem(key, data) {
    if (typeof data === "string") {
      localStorage.setItem(key, data);
    }

    localStorage.setItem(key, JSON.stringify(data));
  }

  static removeItem(key) {
    localStorage.removeItem(key);
  }

  static clear() {
    localStorage.clear();
  }

  static getToken() {
    return LocalStorageService.getItem("token");
  }

  static get chatToken() {
    return (LocalStorageService.getItem("skChatAuth") || {}).token;
  }

  static get chatUser() {
    return (LocalStorageService.getItem("skChatAuth") || {}).chatUser;
  }

  static get chatUserId() {
    return (this.chatUser || {})._id;
  }

  static getUser() {
    return LocalStorageService.getItem("user");
  }

  static getUserId() {
    return (LocalStorageService.getItem("user") || {})._id;
  }

  static get schemaData() {
    return LocalStorageService.getItem("schemaData");
  }

  static set schemaData(schemaData) {
    LocalStorageService.setItem("schemaData", schemaData);
  }

  static setOptions(api, options) {
    const { page, searchString, ...optionsForSave } = options;
    LocalStorageService.setItem(`${api}-options`, optionsForSave);
  }

  static getOptions(api) {
    return LocalStorageService.getItem(`${api}-options`) || {};
  }

  static set menuIndex(index) {
    LocalStorageService.setItem("menu-index", index);
  }

  static get menuIndex() {
    return LocalStorageService.getItem("menu-index");
  }
}
