import {applyMiddleware, createStore, compose} from "redux";
import reduxThunk from "redux-thunk";
import combineReducers from './reducers'

const reducer = combineReducers;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(reduxThunk));

export default createStore(
    reducer,
    enhancer
);