import { etaSchema } from "./etaSchema";
import { estaSchema } from "./estaSchema";
import { dsSchema } from "./dsSchema";
import { nzSchema } from "./nzSchema";
import { trkSchema } from "./trkSchema";
import { indSchema } from "./indSchema";
import { ausSchema } from "./ausSchema";
import { vnSchema } from "./vnSchema";
import { API_NAMES } from "../../../EVisa/constants/apiNames";
import { uspsSchema } from "./uspsSchema";
import { PRODUCT_NAMES } from "../../productNames";
import { invSchema } from "./invSchema";
import { schengenSchema } from "./schengenSchema";
import { diversitySchema } from "./diversitySchema";
import { geSchema } from "./geSchema";
import { singaporeSchema } from "./singaporeSchema";
import { ds11Schema } from "./ds11Schema";
import { ds82Schema } from "./ds82Schema";
import { ds64Schema } from "./ds64Schema";
import { sriLankaSchema } from "./slSchema";
import { tsaSchema } from "./tsaSchema";
import { sentriSchema } from "./sentriSchema";
import { nexusSchema } from './nexusSchema';

export const SCHEMAS = {
  [API_NAMES.ETA]: etaSchema,
  [API_NAMES.ESTA]: estaSchema,
  [API_NAMES.DS160]: dsSchema,
  [API_NAMES.NZ]: nzSchema,
  [API_NAMES.TRK]: trkSchema,
  [API_NAMES.IND]: indSchema,
  [API_NAMES.AUS]: ausSchema,
  [API_NAMES.VN]: vnSchema,
  [PRODUCT_NAMES.USPS]: uspsSchema,
  [API_NAMES.INV]: invSchema,
  [API_NAMES.SCHENGEN]: schengenSchema,
  [API_NAMES.DIVERSITY]: diversitySchema,
  [API_NAMES.GE]: geSchema,
  [API_NAMES.SG]: singaporeSchema,
  [API_NAMES.DS11]: ds11Schema,
  [API_NAMES.DS82]: ds82Schema,
  [API_NAMES.DS64]: ds64Schema,
  [API_NAMES.SL]: sriLankaSchema,
  [API_NAMES.TSA]: tsaSchema,
  [API_NAMES.SENTRI]: sentriSchema,
  [API_NAMES.NEXUS]: nexusSchema,
};
