import io from "socket.io-client";
import { SOCKET_ENDPOINT } from "../constants/endpoints";
import { LocalStorageService } from "./localStorageService";
import { Subject } from "rxjs";
import { SOCKET_EVENTS } from "../constants/socketEvents";
import twilioService from "./twilioService";
import { emitLogout } from "../events";

class SocketService {
  /**
   * Subscribe on socketSubject to get
   */
  socketSubject = new Subject();

  connect() {
    if (this.socket) {
      this.disconnect();
    }

    this.socket = io.connect(SOCKET_ENDPOINT, {
      transports: ["websocket", "polling"],
      secure: true,
    });

    Object.keys(SOCKET_EVENTS)
      .map((api) => SOCKET_EVENTS[api])
      .forEach((apiEvent) => {
        Object.keys(apiEvent)
          .map((index) => apiEvent[index])
          .forEach((event) => {
            this.socket.on(event, (data) => {
              this.socketSubject.next({ data, type: event });
            });
          });
      });

    this.socket.on("connect", () => {
      const token = LocalStorageService.getToken();
      if (token) {
        this.sendEvent("auth", { token });
      }
    });

    this.socket.on("authenticated", () => {
      // in case sockets disconnected resend if twilio is activated
      if (twilioService.deviceStatus === "ready") {
        this.sendEvent("twilio-start");
      }
    });

    // When server pings, respond user is online
    this.socket.on("check-connection", () => {
      this.sendEvent("confirm-connection");
    });

    this.socket.on("logout", () => {
      // logout if other tab from browser is logging out
      emitLogout();
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  /**
   * Emit event to socket server with given name and payload
   * @param {string} eventName
   * @param {any} eventPayload
   */
  sendEvent(eventName, eventPayload = null) {
    if (!this.socket) {
      throw new Error("Socket has to be initialized before events are sent");
    }
    this.socket.emit(eventName, eventPayload);
  }
}

export default new SocketService();
