import { useCallback, useState, useEffect, useMemo } from "react";
import { LocalStorageService } from "../services/localStorageService";
import { Subject, interval } from "rxjs";
import { debounce } from "rxjs/operators";

const defaultValidator = () => true;
const defaultTransformator = () => undefined;
const notEmptyValidator = () => (value) =>
  value !== "" &&
  value !== undefined &&
  value !== null &&
  (!Array.isArray(value) || value.length !== 0);
/**
 *
 * @param {RegExp} regExp
 */
const regExpValidator = (regExp) => (value) => regExp.test(value);

const defaultOptions = {
  localStorageKey: null,
  temporaryFilters: [],
  filterValidators: {},
  filterTransformators: {},
  filterDebounces: {},
};
/**
 * 
 * @param {any} initialValue 
 * @param {{
      localStorageKey?:string,
      temporaryFilters?:string[],
      filterValidators?:Object.<string,(filterValue:any,filters:any)=>boolean>,
      filterTransformators?:Object.<string,(filterValue:any,filters:any)=>undefined|{propKey:string,propValue:string}>,
      filterDebounces?:Object.<string,number>
    }} options 
 */
function useFilters(initialValue = {}, options = {}) {
  const {
    localStorageKey,
    temporaryFilters,
    filterValidators,
    filterTransformators,
    filterDebounces,
    // state shouldnt change on options change as they can't be changed
    // its easier implementation so when used options dont have to we wrapped in useMemo and it prevents filter changes on every rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } = useMemo(() => ({ ...defaultOptions, ...options }), []);
  const initialFilterValue = localStorageKey
    ? {
        ...initialValue,
        ...(LocalStorageService.getItem(localStorageKey) || {}),
      }
    : initialValue;

  const [filters, setFiltersLocal] = useState(initialFilterValue);
  const [normalizedFiltersSubject] = useState(new Subject());

  const getNormalizedFilters = useCallback(
    (filters) =>
      getNormalizedFiltersHelper(
        filters,
        filterTransformators,
        filterValidators,
      ),
    [filterTransformators, filterValidators],
  );

  const [normalizedFilters, setNormalizedFiltersLocal] = useState(
    getNormalizedFilters(filters),
  );

  const setNormalizedFilters = useCallback(
    (filters) => {
      setNormalizedFiltersLocal(getNormalizedFilters(filters));
    },
    [setNormalizedFiltersLocal, getNormalizedFilters],
  );

  useEffect(() => {
    if (localStorageKey) {
      const permanentFilters = {};
      Object.entries(filters).forEach(([prop, value]) => {
        if (!temporaryFilters.includes(prop)) {
          permanentFilters[prop] = value;
        }
      });
      // save to localStorage only permanentFilters
      LocalStorageService.setItem(localStorageKey, permanentFilters);
    }
  }, [localStorageKey, temporaryFilters, filters]);

  useEffect(() => {
    const subscription = normalizedFiltersSubject
      .pipe(debounce(({ name }) => interval(filterDebounces[name] || 0)))
      .subscribe(({ filters }) => {
        setNormalizedFilters(filters);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [
    setNormalizedFilters,
    normalizedFiltersSubject,
    getNormalizedFilters,
    filterDebounces,
  ]);

  const setFilter = useCallback(
    (filterName, filterValue) => {
      const newFilters = { ...filters, [filterName]: filterValue };
      setFiltersLocal(newFilters);
      normalizedFiltersSubject.next({ name: filterName, filters: newFilters });
    },
    [filters, setFiltersLocal, normalizedFiltersSubject],
  );

  const updateFilters = useCallback(
    (partialFilters = {}) => {
      const newFilters = { ...filters, ...partialFilters };
      setFiltersLocal(newFilters);
      setNormalizedFilters(newFilters);
    },
    [filters, setFiltersLocal, setNormalizedFilters],
  );

  const setFilters = useCallback(
    (newFilters = {}) => {
      setFiltersLocal(newFilters);
      setNormalizedFilters(newFilters);
    },
    [setFiltersLocal, setNormalizedFilters],
  );

  const setFormFilter = useCallback(
    (ev, { name, value }) => {
      if (!name) {
        console.error("When using setFormFilter set name property on input");
        return;
      }
      setFilter(name, value);
    },
    [setFilter],
  );

  const resetFilters = useCallback(() => {
    setFiltersLocal({});
    setNormalizedFilters({});
  },[setNormalizedFilters])

  return {
    filters,
    normalizedFilters,
    setFilter,
    updateFilters,
    setFilters,
    setFormFilter,
    resetFilters,
  };
}

function getNormalizedFiltersHelper(
  filters,
  filterTransformators,
  filterValidators,
) {
  const normalizedFilters = {};
  Object.entries(filters).forEach(([prop, value]) => {
    const filterValidator = filterValidators[prop] || defaultValidator;
    const filterTransformator =
      filterTransformators[prop] || defaultTransformator;
    if (!filterValidator(value, filters)) {
      return;
    }
    const transformedValue = filterTransformator(value, filters);
    if (transformedValue) {
      const { propKey, propValue } = transformedValue;
      normalizedFilters[propKey] = propValue;
    } else {
      normalizedFilters[prop] = value;
    }
  });
  return normalizedFilters;
}

export { useFilters, notEmptyValidator, regExpValidator };
