export const TRANSACTION_TYPE = {
  AUTH_INVOICE: "auth-invoice",
  CHARGE_INVOICE: "charge-invoice",
  REFUND_INVOICE: "refund-invoice",
  CHARGEBACK_INVOICE: "chargeback-invoice",
};

export const TRANSACTION_TYPE_LABEL_MAPPING = {
  [TRANSACTION_TYPE.AUTH_INVOICE]: "Total Auths",
  [TRANSACTION_TYPE.CHARGE_INVOICE]: "Total Charges",
  [TRANSACTION_TYPE.REFUND_INVOICE]: "Total Refunds",
  [TRANSACTION_TYPE.CHARGEBACK_INVOICE]: "Total Chargebacks",
};
