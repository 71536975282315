import React, { useCallback } from "react";
import { useRouteMatch, Route, Switch } from "react-router-dom";
import EnhancedRoute from "./EnhancedRoute";

const NestedRoutes = ({ routes }) => {
  const { url } = useRouteMatch();

  const finalPath = useCallback(
    (to = "") => {
      const { length } = url;
      if (url[length - 1] === to[0] && to[0] === "/") {
        return url + to.slice(1);
      }
      return url + to;
    },
    [url],
  );

  return (
    <Switch>
      {routes.map(({ path, exact = true, ...rest }, ind) => (
        <Route
          key={`${path}${ind}`}
          path={finalPath(path)}
          exact={exact}
          render={(props) => <EnhancedRoute {...props} {...rest} />}
        />
      ))}
    </Switch>
  );
};

export default NestedRoutes;
