// value contains information about the chart seperated by spaces "text text text" (e.g. "bar true true")
// first text is chart type (e.g. bar chart)
// second is if chart is other version of same chart type (e.g. horizontal bar chart)
// third is if chart is stacked
export const CHART_TYPES = [
  { text: "Pie Chart", value: "pie false false" },
  { text: "Column Chart", value: "bar false false" },
  { text: "Stacked Column Chart", value: "bar false true" },
  { text: "Bar Chart", value: "bar true false" },
  { text: "Stacked Bar Chart", value: "bar true true" },
];

export const PERFORMANCE_TREND_CHART_TYPES = [
  { text: "Line Chart", value: "line false false" },
  { text: "Stacked Column Chart", value: "bar false true" },
  { text: "Stacked Bar Chart", value: "bar true true" },
];
