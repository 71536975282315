import React, { useState, useEffect, useCallback } from "react";
import {
  Select,
  Button,
  Input,
  List,
  Item,
  Segment,
  Table,
  Header,
  Form,
  Modal,
} from "semantic-ui-react";
import toastr from "toastr";
import {
  getPaginatedBotErrorStatisticsByProduct,
  updateBotErrorStatistics,
} from "../../../../../../../apis/configuration/botsErrorStatisticApi";
import { EMAIL_REGEX } from "../../../../../../../common/regex-templates";
import Spinner from "../../../../../../../components/Spinner";
import confirmPopup from "../../../../../../../modules/confirmPopup";
import { usePagination } from "../../../../../../../hooks";
import { getAllBotErrorStatisticsByProduct } from "../../../../../../../apis/configuration/botsErrorStatisticApi";
import {
  columns,
  timeIntervals,
} from "../../constants/botStatisticConstants";
import { PRODUCT_NAMES_WITH_BOTS } from "../../../../routers/Products/constants/productNames";
import "./BotAlarmConfigurationList.styles.scss";

const BotAlarmConfigurationList = () => {
  const [selectedProduct, setSelectedProduct] = useState(Object.keys(PRODUCT_NAMES_WITH_BOTS)[0]);
  const [formData, setFormData] = useState({
    errors: [],
    selectedError: "",
    maximumCountPerInterval: "",
    selectedTimeInterval: "",
    emails: [],
    emailValue: "",
    emailError: "",
  });
  const [editData, setEditData] = useState({
    selectedError: "",
    maximumCountPerInterval: "",
    selectedTimeInterval: "",
    emails: [],
    emailValue: "",
    emailError: "",
  });
  const [modalOpen, setModalOpen] = useState(false);

  const fetchErrorsByProduct = useCallback(async (product) => {
    try {
      const { data } =  await getAllBotErrorStatisticsByProduct(product);
      setFormData((prevState) => ({
        ...prevState,
        errors: data,
        selectedError: "",
      }));
    } catch (error) {
      toastr.error("Failed to fetch error statistics for the selected product");
      console.error("Error fetching error statistics:", error);
      return [];
    }
  }, []);

  const getProductDisplayName = (productName) => {
    return PRODUCT_NAMES_WITH_BOTS[productName] || productName;
  };

  const {
    items: enabledAlarms,
    Paginator,
    loadItems: loadEnabledAlarms,
    isLoading,
  } = usePagination(
    (product, params) =>
      getPaginatedBotErrorStatisticsByProduct(product, {
        ...params,
        "alertInfo.enabled": true,
      }),
    {
      initialLoad: false,
      clearOnLoad: true,
    },
    selectedProduct
  );

  useEffect(() => {
    if (selectedProduct) {
      fetchErrorsByProduct(selectedProduct);
    }
  }, [selectedProduct, fetchErrorsByProduct]);

  useEffect(() => {
    if (selectedProduct) {
      loadEnabledAlarms(1);
    }
    // eslint-disable-next-line
  }, [selectedProduct]);

  const handleSaveButtonClick = useCallback(async () => {
    try {
      const {
        emails,
        maximumCountPerInterval,
        selectedTimeInterval,
        selectedError,
      } = formData;

      if (emails.length > 0) {
        const invalidEmail = emails.find((email) => !EMAIL_REGEX.test(email));
        if (invalidEmail) {
          setFormData((prevState) => ({
            ...prevState,
            emailError: "Invalid email address",
          }));
          return;
        }
      }

      const body = {
        maximumCountPerInterval: parseInt(maximumCountPerInterval),
        hoursPeriodForMaximumCount: parseInt(selectedTimeInterval),
        emails: emails,
        enabled: true,
      };

      const response = await updateBotErrorStatistics(
        { product: selectedProduct, message: selectedError },
        { alertInfo: body }
      );

      if (response && response.status === 200) {
        toastr.success("Alarms added successfully");
        // Clear form fields
        setFormData((prevState) => ({
          ...prevState,
          selectedError: "",
          maximumCountPerInterval: "",
          selectedTimeInterval: "",
          emails: [],
          emailValue: "",
          emailError: "",
        }));
        // Reload enabled alarms
        loadEnabledAlarms(1);
      } else {
        toastr.error("Failed to add alarms");
      }
    } catch (error) {
      console.error("Error adding alarms:", error);
      toastr.error("Failed to add alarms");
    }
  }, [formData, selectedProduct, loadEnabledAlarms]);

  const handleButtonDisableClick = useCallback(
    async (error) => {
      if (await confirmPopup("Are you sure you want to disable this alarm?")) {
        try {
          await updateBotErrorStatistics(
            { product: error.product, message: error.message },
            { "alertInfo.enabled": false },
          );
          toastr.success("Alarm disabled successfully");
          fetchErrorsByProduct(selectedProduct);
          loadEnabledAlarms(1);
        } catch (error) {
          console.error("Error disabling alarm:", error);
          toastr.error("Failed to disable alarm");
        }
      }
    },
    [selectedProduct, loadEnabledAlarms, fetchErrorsByProduct]
  );

  const handleEdit = useCallback(async () => {
    try {
      const {
        emails,
        maximumCountPerInterval,
        selectedTimeInterval,
        selectedError,
      } = editData;

      const body = {
        maximumCountPerInterval: parseInt(maximumCountPerInterval),
        hoursPeriodForMaximumCount: parseInt(selectedTimeInterval),
        emails: emails,
        enabled: true,
      };

      await updateBotErrorStatistics(
        { product: selectedError.product, message: selectedError.message },
        { alertInfo: body },
      );

      toastr.success("Changes saved successfully");
      loadEnabledAlarms(1);
      setEditData((prevState) => ({
        ...prevState,
      }));
      setModalOpen(false);
    } catch (error) {
      console.error("Error saving changes:", error);
      toastr.error("Failed to save changes");
    }
  }, 
  [
    editData, 
    loadEnabledAlarms
  ]
);

  return (
    <>
    <Segment className="bot-alarms-segment-header" inverted >
      <Header size="large" style={{ textAlign: "center" }}>
        Activate alarm
      </Header>
    </Segment>
      <Form className="bot-alarms-select">
        <Form.Field>
        <label className="bot-alarms-label">Select Product</label>
          <Select
            options={Object.keys(PRODUCT_NAMES_WITH_BOTS).map((route) => ({
              key: route,
              text: PRODUCT_NAMES_WITH_BOTS[route],
              value: route,
            }))}
            value={selectedProduct}
            onChange={(event, { value }) => {
              setSelectedProduct(value);
              fetchErrorsByProduct(value);
            }}
          />
        </Form.Field>
      </Form>
      <List
        horizontal
        style={{
          display: "flex",
          alignItems: "flex-start",
          height: "92px",
        }}
      >
        {formData.errors.length > 0 && (
          <>
            <List.Item>
              <Item.Meta>Error message</Item.Meta>
              <Item.Description>
                <Select
                  placeholder="Select error"
                  name="selectedError"
                  options={formData.errors.map((error, index) => ({
                    key: index,
                    text: error,
                    value: error,
                  }))}
                  value={formData.selectedError}
                  onChange={(event, { value }) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      selectedError: value,
                    }))
                  }
                />
              </Item.Description>
            </List.Item>
            <List.Item>
              <Item.Meta>Time interval</Item.Meta>
              <Item.Description>
                <Select
                  placeholder="Select time interval"
                  name="selectedTimeInterval"
                  options={timeIntervals}
                  value={formData.selectedTimeInterval}
                  onChange={(event, { value }) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      selectedTimeInterval: value,
                    }))
                  }
                />
              </Item.Description>
            </List.Item>
            <List.Item>
              <Item.Meta>Error count</Item.Meta>
              <Item.Description>
                <Input
                  placeholder="Bot Error Count"
                  name="maximumCountPerInterval"
                  type="number"
                  value={formData.maximumCountPerInterval}
                  onChange={(event, { value }) =>
                    !value.startsWith("-") &&
                    !isNaN(value) &&
                    setFormData((prevState) => ({
                      ...prevState,
                      maximumCountPerInterval: value,
                    }))
                  }
                />
              </Item.Description>
            </List.Item>
            <List.Item>
              <Item.Meta>Emails to Notify</Item.Meta>
              <Item.Description>
                <Input
                  placeholder="Enter Email"
                  name="emailValue"
                  type="emails"
                  value={formData.emailValue}
                  onChange={(event, { value }) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      emailValue: value,
                      emailError: "",
                    }))
                  }
                />
                <Button
                  icon="plus"
                  onClick={() => {
                    if (!EMAIL_REGEX.test(formData.emailValue)) {
                      setFormData((prevState) => ({
                        ...prevState,
                        emailError: "Invalid email address",
                      }));
                      return;
                    }
                    setFormData((prevState) => ({
                      ...prevState,
                      emails: [...prevState.emails, prevState.emailValue],
                      emailValue: "",
                      emailError: "",
                    }));
                  }}
                />
                {formData.emailError && (
                  <div style={{ color: "red" }}>{formData.emailError}</div>
                )}
                <List horizontal>
                  {formData.emails.map((email, index) => (
                    <List.Item key={index}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                      >
                        <Button
                          icon="minus"
                          onClick={() =>
                            setFormData((prevState) => ({
                              ...prevState,
                              emails: prevState.emails.filter(
                                (_, i) => i !== index
                              ),
                            }))
                          }
                        />
                        <div>{email}</div>
                      </div>
                    </List.Item>
                  ))}
                </List>
              </Item.Description>
            </List.Item>
          </>
        )}
        {formData.errors.length === 0 && (
          <List.Item>
            <Item.Description>
              <Segment inverted>
              <Header size="small">No error messages available for this product</Header>
              </Segment>
            </Item.Description>
          </List.Item>
        )}
        {formData.errors.length > 0 && (
          <List.Item style={{ alignSelf: "center" }}>
            <Item.Description>
              <Button
                color="green"
                onClick={handleSaveButtonClick}
                className="enable-alarm-button"
                disabled={
                  !formData.selectedError ||
                  !formData.selectedTimeInterval ||
                  !formData.maximumCountPerInterval
                }
              >
                Enable alarm
              </Button>
            </Item.Description>
          </List.Item>
        )}
      </List>
      <Segment className="bot-config-segment" inverted>
        <Header size="large">Active alarms</Header>
        {isLoading ? <Spinner /> : null}
        {!formData.loading && enabledAlarms?.length > 0 && (
          <Table sortable celled striped>
            <Table.Header>
              <Table.Row>
                {columns.map((column) => (
                  <Table.HeaderCell key={column.key} width={column.width}>
                    {column.label}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {enabledAlarms &&
                enabledAlarms.map((error, index) => (
                  <Table.Row key={index}>
                    {columns.map((column) => (
                      <Table.Cell key={column.key}>
                        {column.key === "product" ? (
                          getProductDisplayName(error.product)
                        ) : column.key === "alertInfo" ? (
                          <ul>
                            <li>
                              Maximum Count Per Interval:{" "}
                              {error.alertInfo.maximumCountPerInterval}
                            </li>
                            <li>
                              Hours Period For Maximum Count:{" "}
                              {error.alertInfo.hoursPeriodForMaximumCount}
                            </li>
                            <li>
                              Emails:{" "}
                              {error.alertInfo.emails
                                ? error.alertInfo.emails.join(", ")
                                : ""}
                            </li>
                            <li>
                              Enabled: {error.alertInfo.enabled ? "Yes" : "No"}
                            </li>
                          </ul>
                        ) : (
                          error[column.key]
                        )}
                      </Table.Cell>
                    ))}
                    <Table.Cell>
                      <div className="statistic-buttons">
                        <Button
                          color="red"
                          onClick={() => handleButtonDisableClick(error)}
                        >
                          Disable Alarm
                        </Button>
                        <Button
                          color="blue"
                          onClick={() => {
                            setEditData({
                              selectedError: error,
                              maximumCountPerInterval:
                                error.alertInfo.maximumCountPerInterval,
                              selectedTimeInterval: parseInt(
                                error.alertInfo.hoursPeriodForMaximumCount,
                              ),
                              emails: error.alertInfo.emails || [],
                              emailValue: "",
                              emailError: "",
                            });
                            setModalOpen(true);
                          }}
                        >
                          Edit Alarm
                        </Button>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={columns.length + 1}>
                  <Paginator />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
        {!formData.loading && selectedProduct && enabledAlarms?.length === 0 && (
          <div>No active alarms found for the selected product</div>
        )}
        {/* Modal for editing alarm */}
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          className="bot-edit-modal"
        >
          <Modal.Header>Edit Alarm</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>Maximum Count Per Interval</label>
                <Input
                  placeholder="Bot Error Count"
                  name="maximumCountPerInterval"
                  type="number"
                  value={editData.maximumCountPerInterval}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (!value.startsWith("-") && !isNaN(value)) {
                      setEditData((prevState) => ({
                        ...prevState,
                        maximumCountPerInterval: value,
                      }));
                    }
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Hours Period For Maximum Count</label>
                <Select
                  placeholder="Select time interval"
                  name="hoursPeriodForMaximumCount"
                  options={timeIntervals}
                  value={editData.selectedTimeInterval}
                  onChange={(event, { value }) =>
                    setEditData((prevState) => ({
                      ...prevState,
                      selectedTimeInterval: value,
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Emails to Notify</label>
                <div className="email-input-wrapper">
                  <Input
                    placeholder="Enter Email"
                    name="emailValue"
                    type="emails"
                    value={editData.emailValue}
                    onChange={(event) => {
                      const { value } = event.target;
                      setEditData((prevState) => ({
                        ...prevState,
                        emailValue: value,
                        emailError: "",
                      }));
                    }}
                  />
                  <Button
                    icon="plus"
                    onClick={() => {
                      if (!EMAIL_REGEX.test(editData.emailValue)) {
                        setEditData((prevState) => ({
                          ...prevState,
                          emailError: "Invalid email address",
                        }));
                        return;
                      }
                      setEditData((prevState) => ({
                        ...prevState,
                        emails: [...prevState.emails, prevState.emailValue],
                        emailValue: "",
                        emailError: "",
                      }));
                    }}
                  />
                </div>
                {editData.emailError && <div>{editData.emailError}</div>}
                <List>
                  {editData.emails.map((email, index) => (
                    <List.Item key={index}>
                      <Button
                        icon="minus"
                        onClick={() =>
                          setEditData((prevState) => ({
                            ...prevState,
                            emails: prevState.emails.filter(
                              (_, i) => i !== index,
                            ),
                          }))
                        }
                      />
                      {email}
                    </List.Item>
                  ))}
                </List>
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color="black" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content="Submit"
              onClick={handleEdit}
            />
          </Modal.Actions>
        </Modal>
      </Segment>
    </>
  );
};

export default BotAlarmConfigurationList;
