import env from "../env";

export const SERVER_ENDPOINT = env.API_URL;
export const USPS_SERVER = env.USPS_URL;
export const API_ENDPOINT = `${SERVER_ENDPOINT}/api/`;
export const USPS_API_ENDPOINT = `${USPS_SERVER}/api/dashboard/`;
export const SOCKET_ENDPOINT = SERVER_ENDPOINT;
export const TWILIO_ENDPOINT = "https://www.twilio.com";
export const TRAVELASSIST_ZENDESK_ENDPOINT = "https://travelassist.zendesk.com";

const ZENDESK_TICKET_ENDPOINT = (instanceUrl, zendeskId) =>
  `${instanceUrl}/agent/tickets/${zendeskId}`;

export const TRAVELASSIST_ZENDESK_TICKET_ENDPOINT = (zendeskId) =>
  ZENDESK_TICKET_ENDPOINT(TRAVELASSIST_ZENDESK_ENDPOINT, zendeskId);

export const TWILIO_CALL_ENDPOINT = (callId) =>
  `${TWILIO_ENDPOINT}/console/voice/calls/logs/${callId}`;
