import React, { useEffect, useState, useMemo } from "react";
import "./CategoryOrProductQAReport.styles.scss";
import { QA_REPORTS } from "../../../../constants/qaReports";
import CategoryOrProductChart from "./components/CategoryOrProductChart/CategoryOrProductChart.component";

const CategoryOrProductQAReport = ({
  selectedDateReport,
  mainFilter,
  helperFilter,
  type,
  chartConfig,
}) => {
  const isProductReport = useMemo(() => type === QA_REPORTS.PRODUCT_REPORT, [
    type,
  ]);

  const [chartData, setChartData] = useState({});
  const [chartDataSeparated, setChartDataSeparated] = useState({});

  const hasMainFilter = useMemo(() => JSON.stringify(mainFilter) !== "{}", [
    mainFilter,
  ]);

  useEffect(() => {
    if (selectedDateReport) {
      const chartValues = isProductReport
        ? selectedDateReport.reduce((ret, { product, count, answer }) => {
            if (!ret[product]) ret[product] = [0, 0];
            if (answer) ret[product][0] += count;
            else ret[product][1] += count;
            return ret;
          }, {})
        : selectedDateReport.reduce(
            (ret, { category, product, count, answer }) => {
              if (helperFilter[product]) {
                if (!ret[category]) ret[category] = [0, 0];
                if (answer) ret[category][0] += count;
                else ret[category][1] += count;
              }
              return ret;
            },
            {},
          );

      const chartValuesSeparated = isProductReport
        ? selectedDateReport.reduce((ret, { product, count, answer, date }) => {
            if (!ret[product]) ret[product] = { positive: [], negative: [] };

            if (answer)
              ret[product].positive = [
                ...ret[product].positive,
                ...[{ count, date }],
              ];
            else
              ret[product].negative = [
                ...ret[product].negative,
                ...[{ count, date }],
              ];
            return ret;
          }, {})
        : selectedDateReport.reduce(
            (ret, { category, product, count, date, answer }) => {
              if (helperFilter[product]) {
                if (!ret[category])
                  ret[category] = { positive: [], negative: [] };

                if (answer)
                  ret[category].positive = [
                    ...ret[category].positive,
                    ...[{ count, date }],
                  ];
                else
                  ret[category].negative = [
                    ...ret[category].negative,
                    ...[{ count, date }],
                  ];
              }
              return ret;
            },
            {},
          );

      setChartDataSeparated(chartValuesSeparated);
      setChartData(chartValues);
    }
  }, [selectedDateReport, isProductReport, helperFilter]);

  return (
    <div className="cat-or-prod-qa-report">
      {hasMainFilter ? (
        <div className="cat-or-prod-qa-report__products">
          {Object.keys(mainFilter).map((filter) => (
            <CategoryOrProductChart
              key={filter}
              isProductReport={isProductReport}
              mainFilter={mainFilter}
              filter={filter}
              chartConfig={chartConfig}
              chartData={chartData}
              chartDataSeparated={chartDataSeparated}
            />
          ))}
        </div>
      ) : (
        <h4>
          {isProductReport ? "No Product Selected" : "No Category Selected"}
        </h4>
      )}
    </div>
  );
};

export default CategoryOrProductQAReport;
