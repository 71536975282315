import React, { useCallback, useEffect, useMemo, useState } from "react";
import QRCode from "qrcode";
import {
  Button,
  Grid,
  Header,
  Input,
  Loader,
  Segment,
} from "semantic-ui-react";
import auth from "../../apis/auth";
import { LocalStorageService } from "../../services/localStorageService";
import { AuthService } from "../../services/authService";
import { useDispatch } from "react-redux";
import { FETCH_CURRENT_USER } from "../../store/actions/actionTypes";
import toastr from "toastr";
import { history } from "../../modules/history";

const TwoFactorAuthentication = () => {
  const dispatch = useDispatch();
  const [base32, setBase32] = useState("");
  const [qrcodeUrl, setQrCodeUrl] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [authMethod, setAuthMethod] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const isConfiguredTwoFactorAuth = useMemo(() => {
    return (
      LocalStorageService.getItem("isConfiguredTwoFactorAuth") === "true" ||
      LocalStorageService.getItem("isConfiguredTwoFactorAuth") === true
    );
  }, []);

  const generateOTPSecret = useCallback(async (userId) => {
    try {
      setIsLoading(true);
      const {
        data: { base32, otpauth_url },
      } = await auth.generateOTPSecret(userId);
      setBase32(base32);
      QRCode.toDataURL(otpauth_url).then(setQrCodeUrl);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchUser = useCallback(async (userId) => {
    try {
      setIsLoading(true);
      const {
        data,
      } = await auth.fetchUser(userId);
      LocalStorageService.setItem("user", data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleInitiateSmsVerification = useCallback(async () => {
    try {
      setIsLoading(true);
      const userId = LocalStorageService.getUserId();
      await auth.initiateSmsVerification(userId, phoneNumber);
      toastr.success("SMS with code has been sent to your phone.")
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [phoneNumber]);

  const loginUser = useCallback(async () => {
    try {
      setIsLoading(true);
      const {
        data: { user },
      } = await auth.getAuthUser();
      LocalStorageService.setItem("user", user);
      dispatch({
        type: FETCH_CURRENT_USER,
        payload: user,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  const checkSmsVerificationCode = useCallback(async () => {
    try {
      setIsLoading(true);
      const userId = LocalStorageService.getUserId();
      const { data } = await auth.checkSmsVerificationCode(userId, verifyCode);
      AuthService.saveLoginTokensToStorage(data);
      loginUser();
    } catch (e) {
      toastr.error("Authentication code is invalid");
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [loginUser, verifyCode]);

  const validateOTP = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await auth.validateOTP(
        LocalStorageService.getItem("userId"),
        verifyCode,
      );
      AuthService.saveLoginTokensToStorage(data);
      loginUser();
    } catch (e) {
      toastr.error("Authentication code is invalid or user doesn't exist");
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [loginUser, verifyCode]);

  const renderGoogleAuthenticator = useMemo(
    () => (
      <Segment>
        <Button onClick={() => setAuthMethod("")}>Back</Button>
        <Header as="h2" color="teal" textAlign="center">
          Two-Factor Authentication (2FA)
        </Header>
        {!isConfiguredTwoFactorAuth && (
          <>
            <Segment textAlign="left">
              <Header as="h3" color="teal" textAlign="center">
                Configuring Google Authenticator
              </Header>
              <Header as="p" color="teal">
                1. Install Google Authenticator.
              </Header>
              <Header as="p" color="teal">
                2. In the authenticator app, select "+" icon.
              </Header>
              <Header as="p" color="teal">
                3. Select "Scan a QR code" and use the phone's camera to scan
                this barcode.
              </Header>
            </Segment>
            <Segment textAlign="center">
              <Header as="h3" color="teal" textAlign="center">
                Scan QR Code
              </Header>
              <img src={qrcodeUrl} alt="qrcode url" />
            </Segment>
            <Segment>
              <Header as="h3" color="teal" textAlign="center">
                Or Enter Code Into Your App
              </Header>

              <Header as="span" color="teal">
                Secret key: {base32}
              </Header>
            </Segment>
          </>
        )}
        <Segment>
          <Header as="h3" color="teal" textAlign="center">
            Verify Code
          </Header>

          <Header as="p" color="teal">
            Please enter authentication code:
          </Header>
          <Input
            value={verifyCode}
            onChange={(event) => setVerifyCode(event.target.value)}
            onKeyDown={(event) => event.keyCode === 13 && validateOTP()}
            placeholder="Authentication Code"
            autoFocus
          />
        </Segment>
        <Button onClick={validateOTP}>Verify</Button>
      </Segment>
    ),
    [base32, isConfiguredTwoFactorAuth, qrcodeUrl, validateOTP, verifyCode],
  );

  const renderSmsAuth = useMemo(() => {
    const user = LocalStorageService.getUser();

    return (
      <Segment>
        <Button onClick={() => setAuthMethod("")}>Back</Button>
        <Header as="h2" color="teal" textAlign="center">
          Two-Factor Authentication (2FA)
        </Header>
        {!user?.smsAuthenticationNumber && (
          <>
            <Segment textAlign="center">
              <Header as="h3" color="teal" textAlign="center">
                Configuring SMS Authenticator
              </Header>
              <Header as="p" color="teal">
                Enter your phone number:
              </Header>
              <Input
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
                placeholder="(+123) Phone Number"
              />
              <Button onClick={handleInitiateSmsVerification}>Save</Button>
            </Segment>
          </>
        )}
        <Segment>
          <Header as="h3" color="teal" textAlign="center">
            Verify Code
          </Header>

          <Header as="p" color="teal">
            Please enter authentication code:
          </Header>
          <Input
            value={verifyCode}
            onChange={(event) => setVerifyCode(event.target.value)}
            onKeyDown={(event) =>
              event.keyCode === 13 && checkSmsVerificationCode()
            }
            placeholder="Authentication Code"
            autoFocus
          />
        </Segment>
        <Button onClick={checkSmsVerificationCode}>Verify</Button>
      </Segment>
    );
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    LocalStorageService.getUser(),
    checkSmsVerificationCode,
    handleInitiateSmsVerification,
    phoneNumber,
    verifyCode,
  ]);

  const renderAuthMethod = useMemo(() => {
    switch (authMethod) {
      case "google":
        return renderGoogleAuthenticator;
      case "sms":
        return renderSmsAuth;
      default:
        return (
          <Segment>
            <Header as="h2" color="teal" textAlign="center">
              Choose Two-Factor Authentication Method
            </Header>
            <Button
              onClick={() => {
                setAuthMethod("google");
                setVerifyCode("");
              }}
            >
              Google Authenticator
            </Button>
            <Button
              onClick={() => {
                const user = LocalStorageService.getUser();
                if (user?.smsAuthenticationNumber)
                  handleInitiateSmsVerification(user?.id);
                setAuthMethod("sms");
                setVerifyCode("");
              }}
            >
              SMS Message
            </Button>
          </Segment>
        );
    }
  }, [
    authMethod,
    handleInitiateSmsVerification,
    renderGoogleAuthenticator,
    renderSmsAuth,
  ]);

  useEffect(() => {
    !isConfiguredTwoFactorAuth &&
      authMethod === "google" &&
      generateOTPSecret(LocalStorageService.getItem("userId"));
  }, [authMethod, generateOTPSecret, isConfiguredTwoFactorAuth]);

  useEffect(() => {
    const user = LocalStorageService.getUser();
    const userId = LocalStorageService.getItem("userId");
    if (!user && !userId) history.push("/login");
  }, [authMethod]);

  useEffect(() => {
    const userId = LocalStorageService.getItem("userId");
    if(!LocalStorageService.getUser() && userId) {
      fetchUser(userId)
    }
  },[fetchUser])

  return (
    <Grid textAlign="center" className="main-grid-login" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 800 }}>
        {!isLoading ? renderAuthMethod : <Loader active />}
      </Grid.Column>
    </Grid>
  );
};

export default TwoFactorAuthentication;
