import React, { useCallback, useState, useEffect, useContext } from "react";
import { Segment, Table, Select, Loader, Button } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { getDateFormat } from "../../../../../../../../../common/date-format";
import { notEmptyValidator, useFilters, usePagination } from "../../../../../../../../../hooks";
import apis from "../../../../../../../../../apis";
import { UsersContext } from "../../../../../../../../../common/UsersContext";
import ModalTable from "../../../../../../../../../components/common/ModalTable";
import moment from "moment";
import { useMemo } from "react";

const ApplicantLogsTable = ({ applicantId }) => {
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const { allUsers } = useContext(UsersContext);

  const {
    items: logs,
    loadItems: loadLogs,
    isLoading,
    refreshItems: refreshLogs,
    Paginator,
  } = usePagination(
    apis.logs.getApplicantLogs,
    {
      clearOnLoad: false,
      initialLoad: false,
    },
    applicantId,
  );

  const { normalizedFilters, filters, setFormFilter } = useFilters(
    {},
    {
      localStorageKey: "applicant-logs-filters",
      filterValidators: {
        createdAt: notEmptyValidator(),
        userId: notEmptyValidator(),
      },
      temporaryFilters: ["userId", "createdAt"],
    },
  );

  useEffect(() => {
    loadLogs(1, normalizedFilters);
  }, [loadLogs, normalizedFilters]);

  const getCreatedAt = (from, to) => {
    if (!from && !to) {
      return "";
    }

    if (from && !to) {
      return {
        $gte: from,
      };
    }

    if (!from && to) {
      return {
        $lt: to,
      };
    }
    return { $gte: from, $lt: to };
  };

  const columns = useMemo(() => {
    return([
      {
        label: "Event type",
        prop: "eventType",
        width: 2,
      },
      {
        label: "Agent",
        prop: "userId",
        width: 2,
        getValue: (fieldValue) => (fieldValue ? allUsers.find(agent => agent.id === fieldValue)?.fullName : "")
      },
    ]);
  },[allUsers])

  const renderHeader = useCallback(() => {
    const agentOptions = allUsers.map((agent) => ({
      key: agent.id,
      text: agent.fullName,
      value: agent.id,
    }));

    const { userId } = filters;

    return (
      <div className="users-header">
        <div className="users-filters">
          <DateInput
            name="dateFrom"
            dateFormat={"YYYY-MM-DD"}
            clearable={true}
            placeholder="Date from"
            maxDate={dateTo ? moment(dateTo).subtract(1, 'day') : undefined}
            value={dateFrom}
            popupPosition="top right"
            animation="none"
            duration={0}
            iconPosition="left"
            onChange={(_, field) => {
              const { value } = field;
              setDateFrom(value);
              setFormFilter(null, {
                name: "createdAt",
                value: getCreatedAt(value, dateTo),
              });
            }}
          />
          <DateInput
            name="dateTo"
            dateFormat={"YYYY-MM-DD"}
            clearable={true}
            placeholder="Date to"
            minDate={dateFrom ? moment(dateFrom).add(1, 'day') : undefined}
            value={dateTo}
            popupPosition="top right"
            animation="none"
            duration={0}
            iconPosition="left"
            onChange={(_, field) => {
              const { value } = field;
              setDateTo(value);
              setFormFilter(null, {
                name: "createdAt",
                value: getCreatedAt(dateFrom, value),
              });
            }}
          />
          <Select
            options={agentOptions}
            placeholder="Select agent"
            name="userId"
            value={userId}
            onChange={setFormFilter}
            search
            clearable
          />
          <Button onClick={refreshLogs}>
            {isLoading ? "Loading.." : "Refresh"}
          </Button>
        </div>
      </div>
    );
  }, [
    allUsers,
    dateFrom,
    dateTo,
    filters,
    isLoading,
    refreshLogs,
    setFormFilter,
  ]);

  return (
    <React.Fragment>
      {!logs ? (
        <Loader active />
      ) : (
        <Segment.Group>
          {renderHeader()}
          <Table celled striped fixed selectable className="user-table">
            <Table.Header>
              <Table.Row>
                {columns.map((column) => (
                  <Table.HeaderCell
                    key={column.prop}
                    width={column.width ? column.width : 1}
                  >
                    {column.label}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
                <Table.HeaderCell width={2}>Created at</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {logs &&
                logs.map((log) => (
                  <Table.Row key={log._id}>
                    {columns.map((column) => (
                      <Table.Cell key={column.prop}>
                        {column.getValue
                          ? column.getValue(log[column.prop])
                          : log[column.prop]}
                      </Table.Cell>
                    ))}
                    <Table.Cell>
                      <ModalTable
                        headerText="List of actions made"
                        data={log.actions}
                        columns={[
                          {
                            label: "Property",
                            prop: "propertyName",
                            width: 1,
                          },
                          {
                            label: "Old value",
                            prop: "oldValue",
                            width: 2,
                          },
                          {
                            label: "New value",
                            prop: "newValue",
                            width: 2,
                          },
                        ]}
                        trigger={<Button>See actions</Button>}
                      />
                    </Table.Cell>
                    <Table.Cell>{getDateFormat(log.createdAt)}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={columns.length+2 }>
                  <Paginator />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Segment.Group>
      )}
    </React.Fragment>
  );
};

export default ApplicantLogsTable;
