import React, { useState, useMemo } from "react";
import { PerformanceTrendQAReport, Chart } from "../../..";
import "./CategoryOrProductChart.styles.scss";

const CHART_LABELS = ["Positive", "Negative"];

const CategoryOrProductChart = ({
  chartData,
  chartDataSeparated,
  mainFilter,
  filter,
  isProductReport,
  chartConfig,
}) => {
  const [openPerformanceTrend, setOpenPerformanceTrend] = useState(false);

  const header = useMemo(
    () =>
      isProductReport ? filter.toUpperCase() : mainFilter[filter].toUpperCase(),
    [filter, mainFilter, isProductReport],
  );

  return (
    <div
      className="product"
      onClick={() => setOpenPerformanceTrend(true)}
      title="Show Performance Trend"
    >
      <h3>{header}</h3>
      {chartData[filter] ? (
        <>
          <PerformanceTrendQAReport
            open={openPerformanceTrend}
            setOpen={setOpenPerformanceTrend}
            qaReportData={chartDataSeparated[filter]}
            performanceTrendName={header}
          />
          <Chart
            chartId={filter}
            chartConfig={chartConfig}
            chartData={chartData[filter]}
            chartLabels={CHART_LABELS}
          />
        </>
      ) : (
        <span className="product__no-data">
          {isProductReport ? "No Data For Product" : "No Data For Category"}
        </span>
      )}
    </div>
  );
};

export default CategoryOrProductChart;
