import axios from "./httpClient";

export function getTwilioTokenApi() {
  return axios.get("/twilio/token");
}
export function sendTwilioSMS(phoneNumber, message, applicantInfo) {
  return axios.post("/twilio/sms", { message, phoneNumber, applicantInfo });
}

export function getTwilioPhoneNumberConfigsApi() {
  return axios.get("/twilio/numbers");
}

export function createTwilioPhoneNumberConfigApi(newConfig) {
  return axios.post("/twilio/numbers", newConfig);
}

export function updateTwilioPhoneNumberConfigApi(configId, updatedConfig) {
  return axios.patch(`/twilio/numbers/${configId}`, updatedConfig);
}

export function deleteTwilioPhoneNumberConfigApi(configId) {
  return axios.delete(`/twilio/numbers/${configId}`);
}
