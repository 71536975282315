export const USPS_APPLICANT_STATUSES = {
  NEW: "New",
  PENDING_SUBMISSION: "Pending submission",
  BOT_IN_PROGRESS: "Bot in progress",
  BOT_RETURNED_ERROR: "Bot returned error",
  SUBMITTED: "Submitted",
  SUBMITTED_AND_PAID: "Submitted and paid",
  WAITING_FOR_APPROVAL: "Waiting for approval",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  CANCELLED: "Cancelled",
  CONTACT_SUPPORT: "Contact support"
};

export const USPS_PAYMENT_STATUSES = {
  UNPAID: "Unpaid",
  PAID: "Paid",
  PAYMENT_FAILED: "Payment failed",
  PAYMENT_REFUNDED: "Payment refunded",
  CHARGEGACK: "Payment charged back"
};

export const USPS_AGENT_CHANGEABLE_STATUSES = [
  USPS_APPLICANT_STATUSES.CONTACT_SUPPORT
];

export const PRE_SUBMITTED_USPS_STATUSES = [
  USPS_APPLICANT_STATUSES.NEW,
  USPS_APPLICANT_STATUSES.PENDING_SUBMISSION,
  USPS_APPLICANT_STATUSES.BOT_IN_PROGRESS,
  USPS_APPLICANT_STATUSES.BOT_RETURNED_ERROR
];
