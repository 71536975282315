import { calculatePercentages } from "../utils";

const CHART_OPTIONS = {
  chart: {
    id: "",
    toolbar: {
      show: false,
    },
    stacked: false,
  },

  labels: [],

  legend: {
    show: true,
    position: "bottom",

    formatter: function (val, opts) {
      let label = val;
      let values = opts.w.config.series[opts.seriesIndex];
      if (Array.isArray(val)) label = val.join(" ");
      if (values.data) values = values.data;

      return label + ": " + values;
    },
  },

  dataLabels: {
    offsetY: 0,
    style: {
      fontSize: "14px",
      colors: ["#fefefe"],
    },
    background: {
      enabled: false,
    },

    formatter: function (val, opts) {
      return val;
    },
  },

  plotOptions: {
    bar: {
      distributed: false,
      horizontal: false,

      dataLabels: {
        position: "center",
      },
    },
  },

  xaxis: {
    type: "category",
    categories: [],
  },

  yaxis: {
    labels: {
      formatter: function (val) {
        return val;
      },
    },
  },

  tooltip: { enabled: false },
};

/**
 * Transforms passed data for selected chart type
 * @param {object} chartConfig, configuration for the chart
 * @param {string} chartId, id of chart used for data manipulation
 * @param {number[]} chartValues, values for chart
 * @param {string[]} chartLabels, labels for chart
 *
 */

export function transformChartDataForType(
  chartConfig,
  chartId,
  chartValues,
  chartLabels = [],
  theme = "light",
) {
  const {
    chartType = "pie",
    chartOtherVersion = false,
    chartStacked = false,
    useDate = false,
  } = chartConfig;

  let options = { theme: theme };

  let series = chartValues;

  switch (chartType) {
    case "pie":
      options = {
        ...CHART_OPTIONS,

        chart: {
          ...CHART_OPTIONS.chart,
          id: chartId,
          type: "pie",
        },

        labels: chartLabels.map((label, ind) => {
          const percentage = calculatePercentages(series, series[ind]);
          return label + ": (" + percentage + " %)";
        }),

        legend: {
          ...CHART_OPTIONS.legend,

          formatter: function (val, opts) {
            return val;
          },
        },

        dataLabels: {
          ...CHART_OPTIONS.dataLabels,

          formatter: function (val, opts) {
            return opts.w.config.series[opts.seriesIndex];
          },
        },
      };
      break;

    case "bar":
      options = {
        ...CHART_OPTIONS,

        chart: {
          ...CHART_OPTIONS.chart,
          id: chartId,
          stacked: chartStacked,
          type: "bar",
        },

        labels:
          !chartStacked && !useDate
            ? chartLabels.map((label, ind) => {
                const percentage = calculatePercentages(series, series[ind]);
                return label + ": (" + percentage + " %)";
              })
            : [""],

        legend: {
          ...CHART_OPTIONS.legend,

          formatter: !useDate
            ? function (val, opts) {
                let label = val;
                let value = opts.w.config.series[opts.seriesIndex];
                if (Array.isArray(val)) label = val.join(" ");
                if (value.data) value = value.data?.[0];

                const series = chartStacked
                  ? opts.w.config.series.map(({ data }) => data[0])
                  : opts.w.config.series[0].data;

                return label + ": " + calculatePercentages(series, value) + "%";
              }
            : function (val, opts) {
                return val;
              },

          show: chartStacked,
        },

        dataLabels: {
          ...CHART_OPTIONS.dataLabels,

          offsetY: 3,
          style: {
            fontSize: "14px",
            colors: ["#304758"],
          },

          background: {
            enabled: true,
          },
        },

        plotOptions: {
          ...CHART_OPTIONS.plotOptions,

          bar: {
            ...CHART_OPTIONS.plotOptions.bar,

            distributed: !chartStacked,
            horizontal: chartOtherVersion,
          },
        },

        xaxis: !useDate
          ? {
              type: useDate ? "datetime" : CHART_OPTIONS.xaxis.type,
              categories: useDate
                ? chartLabels
                : CHART_OPTIONS.xaxis.categories,
            }
          : {
              categories: useDate
                ? chartLabels
                : CHART_OPTIONS.xaxis.categories,
              labels: {
                formatter: function (val) {
                  return val;
                },
              },
            },
        tooltip: {
          enabled: true,
          y: {
            show: true,
            formatter: (v) => v,
            title: {
              formatter: (v) => v,
            },
          },
        },
      };

      if (useDate) {
        series = Object.keys(chartValues).map((name) => ({
          name,
          data: chartValues[name],
        }));
      } else if (!chartStacked)
        series = [
          {
            name: "Value",
            data: chartValues,
          },
        ];
      else {
        let chartSeries = [];
        for (let i = 0; i < chartValues.length; i++) {
          chartSeries.push({
            name: chartLabels[i],
            data: [chartValues[i]],
          });
        }

        series = chartSeries;
      }
      break;

    case "line":
      options = {
        ...CHART_OPTIONS,

        chart: {
          ...CHART_OPTIONS.chart,
          id: chartId,
          type: "line",
        },

        legend: {
          ...CHART_OPTIONS.legend,

          formatter: function (val, opts) {
            return val;
          },
        },

        dataLabels: {
          enabled: true,
        },
        xaxis: {
          type: useDate ? "datetime" : CHART_OPTIONS.xaxis.type,
          categories: useDate ? chartLabels : CHART_OPTIONS.xaxis.categories,
        },
        tooltip: { enabled: true },
      };

      series = Object.keys(chartValues).map((name) => ({
        name,
        data: chartValues[name],
      }));
      break;

    default:
      break;
  }

  return new Promise((resolve) => resolve({ options, series }));
}
