import React from 'react'
import { Form, Button } from 'semantic-ui-react'
import CustomSelect from "../../../../../../../../../components/common/CustomSelect";

export const TAG_OPTIONS = [
    '{{first_name}}',
    '{{last_name}}',
    '{{agent_name}}',
    '{{site_name}}',
    'Other'
  ].map((el) => ({text: el, value: el}));
  
  const PROP_OPTIONS = [
    'firstName',
    'lastName',
    'passportNumber',
    'userEmail',
    'invoiceEmail',
    'submissionEmail',
    'paymentStatus',
    'status',
    'applicantNumber',
    'applicantExpiryDate',
    'zendeskId',
    'orderId',
    'userLanguage',
    'purchaseFlowType',
    'siteId',
    'cartSiteId',
    'affiliateId',
    'userCountry',
    'isFreeReapplication',
    'serviceExtension',
    'serviceExtensionAt',
    'salesAgent',
    'salesCoupon',
    'missingVipProduct',
    'vip',
    'applicationType',
  ].map((el) => ({text: el, value: el}));

const TagsAndProps = ({ name, item, setItem }) => {
  return (
      <div>
      {item[name].map((field, index) => (
        <div key={`${name}-${index}`} className="tagsAndPropsRow" widths="equal">
            <div className='eight wide field'>
                <Form.Field>
                    <label>Tag</label>
                    <CustomSelect
                        item={item}
                        setItem={setItem}
                        changeCurrentItemPropertyValue={(propName, value) => 
                          setItem((prev) => ({...prev, tagsAndProps: prev.tagsAndProps.map((el, ind) => 
                            ind === index ? {tag: value, other: '', prop: ['{{agent_name}}','{{site_name}}'].includes(value) ? 'Default' : ''} : el)}))
                        }
                        value={item.tagsAndProps[index].tag}
                        itemPropName={`tagsAndProps[${index}].tag`}
                        options={TAG_OPTIONS}
                    />
                </Form.Field>
                {item.tagsAndProps[index].tag === 'Other' && 
                    <Form.Field>
                        <Form.Input
                            value={item.tagsAndProps[index].other || ""}
                            label="Custom tag"
                            placeholder="Custom tag"
                            type="input"
                            name={`tagsAndProps[${index}].other`}
                            onChange={(event, field) =>
                                setItem((prev) => ({...prev, tagsAndProps: prev.tagsAndProps.map((el, ind) => ind === index ? {...el, other: field.value} : el)}))
                            }
                        />
                    </Form.Field>
                }
            </div>

            <Form.Field className='eight wide field'>
                <label>Prop</label>
                <CustomSelect
                    item={item}
                    setItem={setItem}
                    changeCurrentItemPropertyValue={(propName, value) => 
                      setItem((prev) => ({...prev, tagsAndProps: prev.tagsAndProps.map((el, ind) => ind === index ? {...el, prop: value} : el)}))
                    }
                    value={item.tagsAndProps[index].prop}
                    itemPropName={`tagsAndProps[${index}].prop`}
                    options={[...PROP_OPTIONS, ...(['{{agent_name}}','{{site_name}}'].includes(item.tagsAndProps[index].tag) ? [{text: 'Default', value: 'Default'}] : [])]}
                    disabled={['{{agent_name}}','{{site_name}}'].includes(item.tagsAndProps[index].tag)}
                />
            </Form.Field>

            {item.tagsAndProps.length > 0 && item.tagsAndProps.length - 1 === index ? (
              <Button circular type="button" icon="add" size="huge" onClick={() => 
                setItem((prev) => ({...prev, tagsAndProps: [...prev.tagsAndProps, {tag: "", prop: "", other: ""}]}))} />
            ) : (
              <Button circular type="button" icon="delete" size="huge" onClick={() => 
                setItem((prev) => ({...prev, tagsAndProps: prev.tagsAndProps.filter((el, elIndex) => index !== elIndex)}))} />
            )}
        </div>
      ))}
    </div>
  )
}

export default TagsAndProps
