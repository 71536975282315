import { API_NAMES } from "../Router/routers/DashboardRouter/routers/Products/EVisa/constants/apiNames";
import { PRODUCT_NAMES } from "../Router/routers/DashboardRouter/routers/Products/constants/productNames";

const DEFAULT_EVISA_EVENTS = {
  NEW_APPLICANT: "new_applicant",
  STATUS_CHANGED: "status_changed",
  BOT_ERROR: "bot_error",
  NEW_IMAGE: "new_image",
  PAYMENT_STATUS_CHANGED: "pamynet_status_changed",
};

const DEFAULT_PRODUCT_EVENTS = {
  NEW_APPLICANT: "new_applicant",
  STATUS_CHANGED: "status_changed",
  PAYMENT_STATUS_CHANGED: "pamynet_status_changed",
};

const PRODUCTS_WITH_SOCKETS = [PRODUCT_NAMES.K1];

const SOCKET_EVENTS = {
  AGENTS: {
    AVAILABILITY_CHANGED: "availability_changed",
  },
  esta: {
    PDF_ADDED: "esta_pdf_added",
    ESTA_STAGE_CHANGED: "esta_stage_changed",
  },
  ds160: {
    DS_STAGE_CHANGED: "ds160_stage_changed",
    PDF_ADDED: "ds160_pdf_added",
  },
  usps: {
    NEW_APPLICANT: "usps_new_applicant",
    STATUS_CHANGED: "usps_status_changed",
    BOT_ERROR: "usps_bot_error",
    NEW_IMAGE: "usps_new_image",
    PAYMENT_STATUS_CHANGED: "usps_payment_status_changed",
  },
  k1_visa: {
    NEW_APPLICANT: "k1_visa_new_applicant",
    STATUS_CHANGED: "k1_visa_status_changed",
    PAYMENT_STATUS_CHANGED: "k1_visa_pamynet_status_changed",
  },
  ge: {
    BOT_UPDATES: "ge_bot_updates",
  }
};

Object.values(API_NAMES).forEach((api) => {
  if (!SOCKET_EVENTS[api]) {
    SOCKET_EVENTS[api] = {};
  }
  Object.entries(DEFAULT_EVISA_EVENTS).forEach(([key, value]) => {
    SOCKET_EVENTS[api][key] = `${api}_${value}`;
  });
});

Object.values(PRODUCTS_WITH_SOCKETS).forEach((product) => {
  if (!SOCKET_EVENTS[product]) {
    SOCKET_EVENTS[product] = {};
  }
  Object.entries(DEFAULT_PRODUCT_EVENTS).forEach(([key, value]) => {
    SOCKET_EVENTS[product][key] = `${product}_${value}`;
  });
});

export { SOCKET_EVENTS };
