import React, { useEffect, useMemo, useState } from "react";
import Spinner from "../../../../../../../../../components/Spinner";
import * as moment from "moment";
import {
  getUserAvailabilityReportApi,
  getCurrentUserAvailabilityReportApi,
} from "../../../../../../../../../apis/reports/availabilityReportsApi";
import "./UserReport.styles.scss";
import {
  UNITS_OF_TIME,
  TimespanFrame,
  TimespanLegend,
  PieChart,
  EVENT_NAMES,
} from "../Report";
import {
  useAccordionIndexes,
  useCalendarFilter,
} from "../../../../../../../../../hooks";
import CalendarFilter from "../../../../../../../../../components/CalendarFilter";
import { Accordion, Menu } from "semantic-ui-react";
import uuid from "uuid";
import {
  getDateInUserTz,
  getDateInUserTzOrNow,
} from "../../../../../../../../../common/date-format";

const UserReport = ({ userId }) => {
  const [reports, setReports] = useState([]);
  const [loader, setLoader] = useState(false);

  const { selectedDateSingleFilter, selectedDate, onChangeDate } =
    useCalendarFilter();

  const { activeAccordionIndexes, handleAccordionClick } =
    useAccordionIndexes();

  const selectedDateInTz = useMemo(
    () => ({
      startDate: selectedDate.startDate
        ? getDateInUserTz(selectedDate.startDate)
        : null,
      endDate: selectedDate.endDate
        ? getDateInUserTzOrNow(selectedDate.endDate)
        : null,
    }),
    [selectedDate],
  );

  useEffect(() => {
    setLoader(true);
    async function getReports() {
      const { startDate, endDate } = selectedDateInTz;
      const { data: reports } = !userId
        ? await getCurrentUserAvailabilityReportApi({
            from: startDate,
            to: endDate,
          })
        : await getUserAvailabilityReportApi(userId, {
            from: startDate,
            to: endDate,
          });

      let resReports = reports.map((report) => ({
        ...report,
        createdAt: moment(report.createdAt),
      }));

      if (
        !!reports[0] &&
        new Date(reports[0].createdAt) - new Date(startDate) > 0
      ) {
        resReports.unshift({
          ...reports[0],
          _id: uuid(),
          createdAt: moment(startDate),
          name:
            reports[0].data.previousAvailabilityStatus
              ?.split(" ")[0]
              .toLowerCase() || EVENT_NAMES.OFFLINE,
        });
      }
      setReports(resReports);
    }
    if (selectedDateInTz.startDate) getReports();
  }, [selectedDateInTz, userId]);

  const renderReports = useMemo(() => {
    reports && setLoader(false);

    return (
      reports && (
        <div className="sk-agent-availability">
          <div className="sk-agent-availability__report">
            <h2 className="sk-activity-title">Agent Availability</h2>
            {loader ? (
              selectedDate.startDate !== null ? (
                <>
                  <Spinner /> <h4>Loading reports...</h4>
                </>
              ) : (
                <h4>The time period is not selected</h4>
              )
            ) : (
              <>
                <PieChart
                  reports={reports}
                  firstDate={selectedDateInTz.startDate}
                  lastDate={selectedDateInTz.endDate}
                />
                <TimespanFrame
                  reports={reports}
                  firstDate={selectedDateInTz.startDate}
                  separator={UNITS_OF_TIME.DAY}
                  lastDate={selectedDateInTz.endDate}
                />
                <TimespanLegend />
              </>
            )}
          </div>
          <div className="sk-agent-availability__calendar-filter">
            <Accordion as={Menu} vertical fluid>
              <Menu.Item>
                <Accordion.Title
                  active={activeAccordionIndexes[0] === 1}
                  content="Calendar Filter"
                  index={0}
                  onClick={handleAccordionClick}
                />
                <Accordion.Content
                  active={activeAccordionIndexes[0] === 1}
                  content={
                    <CalendarFilter
                      onChangeDate={onChangeDate}
                      selectedDate={selectedDate}
                      selectedDateSingleFilter={selectedDateSingleFilter}
                    />
                  }
                />
              </Menu.Item>
            </Accordion>
          </div>
        </div>
      )
    );
  }, [
    activeAccordionIndexes,
    handleAccordionClick,
    onChangeDate,
    selectedDate,
    selectedDateInTz,
    selectedDateSingleFilter,
    loader,
    reports,
  ]);
  return renderReports;
};
export default UserReport;
