import { calculateTimeByOffset } from "./calculateTimeByOffset";

/**
 *  Get all shifts with included UTC offsets
 *
 * @typedef {{segmentGroup: { firstSegment: string, lastSegment: string }}} segmentGroup
 * @typedef {{segmentGroup1: segmentGroup, segmentGroup2: segmentGroup, segmentGroup3: segmentGroup }} shiftGroup
 * @param {Array<shiftGroup>} allShifts
 * @returns - segment groups with included offset
 */
export const getAllShiftDependOfUTC = (allShifts) =>
  allShifts.map((shiftGroup) => {
    let res = {};

    // iterating through the shiftGroup object
    //
    for (const [key, value] of Object.entries(shiftGroup)) {
      // key - shift group name
      // value - object: first and last segment
      //
      const { firstSegment, lastSegment } = value;

      // calculate new first and last segment with included offset
      //
      const firstSegmentRes = calculateTimeByOffset(firstSegment);
      const lastSegmentRes = calculateTimeByOffset(lastSegment);

      // make new segment gruops with included offset
      //
      res = {
        ...res,
        [key]: {
          firstSegment: firstSegmentRes,
          lastSegment: lastSegmentRes,
        },
      };
    }

    return {
      ...res,
    };
  });
