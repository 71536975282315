import React from "react";
import { Button } from "semantic-ui-react";
import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";
import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../../constants/endpoints";
import ZendeskIcon from "../../../../../../../../components/Icons/ZendeskIcon/ZendeskIcon";
import { get } from "lodash";

export const SINGAPORE_SELECT_VALUES = {
  COMMERCIAL_FLIGHT_CODES: "COMMERCIAL_FLIGHT_CODES",
  COUNTRY_CODES: "COUNTRY_CODES",
  COUNTRY_OF_BIRTH: "COUNTRY_OF_BIRTH",
  COUNTRY_OF_BIRTH_WITH_FLAGS: "COUNTRY_OF_BIRTH_WITH_FLAGS",
  CRUISE_NAME: "CRUISE_NAME",
  GENDERS: "GENDERS",
  IDENTITY_VERIFICATION_QUESTIONS: "IDENTITY_VERIFICATION_QUESTIONS",
  MODE_OF_TRAVEL: "MODE_OF_TRAVEL",
  MODE_OF_TRAVEL_AIR: "MODE_OF_TRAVEL_AIR",
  MODE_OF_TRAVEL_LAND: "MODE_OF_TRAVEL_LAND",
  MODE_OF_TRAVEL_SEA: "MODE_OF_TRAVEL_SEA",
  NAME_OF_HOTEL: "NAME_OF_HOTEL",
  NATIONALITY: "NATIONALITY",
  NATIONALITY_WITH_FLAGS: "NATIONALITY_WITH_FLAGS",
  OTHER_ACCOMMODATION: "OTHER_ACCOMMODATION",
  PHONE_CODES: "PHONE_CODES",
  PLACE_OF_RESIDENCE: "PLACE_OF_RESIDENCE",
  TYPE_OF_ACCOMMODATION: "TYPE_OF_ACCOMMODATION",
  PURPOSES: "PURPOSES",
};

export const singaporeSchema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      applicationType: {
        label: "Application Type",
      },
      mainApplicationId: {
        label: "Main Application ID",
        dependsOn: "applicationType",
        dependantOnValues: ["Secondary"],
      },
      siteId: {
        label: "Referral Site",
      },
      cartSiteId: {
        label: "Purchased from website",
      },
      orderId: {
        label: "Order ID",
      },
      orderIdHistory: {
        label: "Order ID history",
      },
      tripsApplicationNumbers: {
        label: "Trips application numbers",
        type: TYPES.MULTI_TEXT,
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
        renderIcon: (propValue) => (
          <Button
            onClick={() => {
              window.open(
                TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(propValue),
                "_blank",
              );
            }}
            style={{ width: 36, height: 36, padding: 0, float: "right" }}
            disabled={!propValue}
          >
            <ZendeskIcon className="zendesk-icon" />
          </Button>
        ),
      },
      submissionEmail: {
        label: "Submission email",
        type: TYPES.TEXT,
      },
      submissionPassword: {
        label: "Submission password",
        type: TYPES.TEXT,
      },
      gclid: {
        label: "Google client ID",
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      salesAgent: {
        label: "Sales Agent",
      },
      salesCoupon: {
        label: "Sales Coupon",
      },
    },
  },
  personalInfoSegment: {
    label: "Personal information",
    type: TYPES.SEGMENT,
    data: {
      firstName: {
        label: "First name",
        type: TYPES.TEXT,
      },
      lastName: {
        label: "Family name",
        type: TYPES.TEXT,
      },
      userEmail: {
        label: "User email address",
        type: TYPES.TEXT,
      },
      gender: {
        label: "Gender",
        type: TYPES.SELECT,
        values: SINGAPORE_SELECT_VALUES.GENDERS,
      },
      dateOfBirth: {
        label: "Birth date",
        type: TYPES.DATE,
      },
      countryOfBirth: {
        label: "Birth country",
        type: TYPES.SELECT,
        values: SINGAPORE_SELECT_VALUES.COUNTRY_OF_BIRTH,
      },
      placeOfResidence: {
        label: "Place of residence",
        type: TYPES.SELECT,
        values: SINGAPORE_SELECT_VALUES.PLACE_OF_RESIDENCE,
      },
      countryCode: {
        label: "Country call code",
        type: TYPES.SELECT,
        values: SINGAPORE_SELECT_VALUES.PHONE_CODES,
        text: "phone",
        value: "key"
      },
      mobileNumber: {
        label: "Phone number",
        type: TYPES.TEXT,
      },
    },
  },
  passportInformation: {
    label: "Passport information",
    type: TYPES.SEGMENT,
    data: {
      nationality: {
        label: "Nationality",
        type: TYPES.SELECT,
        values: SINGAPORE_SELECT_VALUES.NATIONALITY,
        value: "$value",
      },
      identityCardNumber: {
        label: "Identity Card Number",
        type: TYPES.TEXT,
        dependsOn: "nationality",
        dependantOnValues: ["MALAYSIAN"]
      },
      passportNumber: {
        label: "Number of travel document",
        type: TYPES.TEXT,
      },
      passportExpiryDate: {
        label: "Valid until",
        type: TYPES.DATE,
      },
    },
  },
  tripInformation: {
    label: "Trip information",
    type: TYPES.SEGMENT,
    data: {
      trips: {
        label: "Trips",
        type: TYPES.ARRAY,
        data: {
          mainPurposeOfJourney: {
            label: "Purpose of travel",
            type: TYPES.SELECT,
            values: SINGAPORE_SELECT_VALUES.PURPOSES,
            path: "trips.$trips",
          },
          otherPurpose: {
            label: "Other purpose of travel",
            type: TYPES.TEXT,
            path: "trips.$trips",
            dependsOn: "trips.$trips.mainPurposeOfJourney",
            dependantOnValues: ["OTHER"],
          },
          applicationNumber: {
            label: "Application number",
            type: TYPES.TEXT,
            path: "trips.$trips",
          },
          dateOfArrival: {
            label: "Date of arrival",
            type: TYPES.DATE,
            path: "trips.$trips",
          },
          modeOfTravel: {
            label: "Mode of travel",
            type: TYPES.NESTED,
            newTable: true,
            nested: {
              mainModeOfTravel: {
                label: "Main mode of travel",
                type: TYPES.SELECT,
                values: SINGAPORE_SELECT_VALUES.MODE_OF_TRAVEL,
                path: "trips.$trips.modeOfTravel"
              },

              seaModelOfTravelDetails: {
                label: "Mode of travel details",
                type: TYPES.SELECT,
                values: SINGAPORE_SELECT_VALUES.MODE_OF_TRAVEL_SEA,
                path: "trips.$trips.modeOfTravel.sea",
                dependsOn: "trips.$trips.modeOfTravel.mainModeOfTravel",
                dependantOnValues: ["2", 2],
              },
              cruiseName: {
                label: "Cruise name",
                type: TYPES.SELECT,
                values: SINGAPORE_SELECT_VALUES.CRUISE_NAME,
                path: "trips.$trips.modeOfTravel.sea",
                dependsOn: ["trips.$trips.modeOfTravel.mainModeOfTravel","trips.$trips.modeOfTravel.sea.seaModelOfTravelDetails"],
                dependantOnValues: [["2",2],["0", 0]],
              },
              vesselName: {
                label: "Vessel name",
                type: TYPES.TEXT,
                path: "trips.$trips.modeOfTravel.sea",
                dependsOn: ["trips.$trips.modeOfTravel.mainModeOfTravel","trips.$trips.modeOfTravel.sea.seaModelOfTravelDetails"],
                dependantOnValues: [["2",2],["1","2","3",1,2,3]],
              },

              landModeOfTravelDetails: {
                label: "Mode of travel details",
                type: TYPES.SELECT,
                values: SINGAPORE_SELECT_VALUES.MODE_OF_TRAVEL_LAND,
                path: "trips.$trips.modeOfTravel.land",
                dependsOn: "trips.$trips.modeOfTravel.mainModeOfTravel",
                dependantOnValues: ["1",1],
              },
              vehicleNumber: {
                label: "Vehicle number",
                type: TYPES.TEXT,
                path: "trips.$trips.modeOfTravel.land",
                dependsOn: "trips.$trips.modeOfTravel.mainModeOfTravel",
                dependantOnValues: ["1",1],
              },

              airModeOfTravelDetails: {
                label: "Mode of travel details",
                type: TYPES.SELECT,
                values: SINGAPORE_SELECT_VALUES.MODE_OF_TRAVEL_AIR,
                path: "trips.$trips.modeOfTravel.air",
                dependsOn: "trips.$trips.modeOfTravel.mainModeOfTravel",
                dependantOnValues: ["0",0],
              },
              carrierName: {
                label: "Carrier name / flight number",
                type: TYPES.TEXT,
                path: "trips.$trips.modeOfTravel.air",
                dependsOn: ["trips.$trips.modeOfTravel.mainModeOfTravel","trips.$trips.modeOfTravel.air.airModeOfTravelDetails"],
                dependantOnValues: [["0",0],["1",1]],
              },
              flightCode: {
                label: "Flight code",
                type: TYPES.SELECT,
                values: SINGAPORE_SELECT_VALUES.COMMERCIAL_FLIGHT_CODES,
                path: "trips.$trips.modeOfTravel.air",
                dependsOn: ["trips.$trips.modeOfTravel.mainModeOfTravel","trips.$trips.modeOfTravel.air.airModeOfTravelDetails"],
                dependantOnValues: [["0",0],["0",0]],
              },
              flightNumber: {
                label: "Flight number",
                type: TYPES.TEXT,
                path: "trips.$trips.modeOfTravel.air",
                dependsOn: ["trips.$trips.modeOfTravel.mainModeOfTravel","trips.$trips.modeOfTravel.air.airModeOfTravelDetails"],
                dependantOnValues: [["0",0],["0",0]],
              },
            },
          },

          accommodation: {
            label: "Accomodation",
            type: TYPES.NESTED,
            newTable: true,
            nested: {
              typeOfAccommodation: {
                label: "Type of accommodation",
                type: TYPES.SELECT,
                values: SINGAPORE_SELECT_VALUES.TYPE_OF_ACCOMMODATION,
                path: "trips.$trips.accommodation",
              },

              nameOfHotel: {
                label: "Hotel name",
                type: TYPES.SELECT,
                values: SINGAPORE_SELECT_VALUES.NAME_OF_HOTEL,
                path: "trips.$trips.accommodation.hotel",
                dependsOn: "trips.$trips.accommodation.typeOfAccommodation",
                dependantOnValues: ["0",0],
              },
              otherHotelNames: {
                label: "Other hotel name",
                type: TYPES.TEXT,
                path: "trips.$trips.accommodation.hotel",
                customDependency: (applicant, path, schemaData) => {
                  const typeOfAccommodation = get(applicant, `${path.replace('.hotel','')}.typeOfAccommodation`);
                  const hotelName = get(applicant, `${path}.nameOfHotel`);
                  if(["0",0].includes(typeOfAccommodation) && schemaData.NAME_OF_HOTEL[hotelName] === "OTHERS") return true;
                }
              },

              others: {
                label: "Other accomodation",
                type: TYPES.SELECT,
                values: SINGAPORE_SELECT_VALUES.OTHER_ACCOMMODATION,
                path: "trips.$trips.accommodation",
                dependsOn: "trips.$trips.accommodation.typeOfAccommodation",
                dependantOnValues: ["1",1],
              },

              postalCode: {
                label: "Postal code",
                type: TYPES.TEXT,
                path: "trips.$trips.accommodation.residential",
                dependsOn: "trips.$trips.accommodation.typeOfAccommodation",
                dependantOnValues: ["2",2],
              },
              blockNumber: {
                label: "Block number",
                type: TYPES.TEXT,
                path: "trips.$trips.accommodation.residential",
                dependsOn: "trips.$trips.accommodation.typeOfAccommodation",
                dependantOnValues: ["2",2],
              },
              streetName: {
                label: "Street name",
                type: TYPES.TEXT,
                path: "trips.$trips.accommodation.residential",
                dependsOn: "trips.$trips.accommodation.typeOfAccommodation",
                dependantOnValues: ["2",2],
              },
              buildingName: {
                label: "Building name",
                type: TYPES.TEXT,
                path: "trips.$trips.accommodation.residential",
                dependsOn: "trips.$trips.accommodation.typeOfAccommodation",
                dependantOnValues: ["2",2],
              },
              floorNumberNotApplicable: {
                label: "Floor number not applicable",
                type: TYPES.CHECKBOX,
                path: "trips.$trips.accommodation.residential",
                dependsOn: "trips.$trips.accommodation.typeOfAccommodation",
                dependantOnValues: ["2",2],
              },
              floorNumber: {
                label: "Floor number",
                type: TYPES.TEXT,
                path: "trips.$trips.accommodation.residential",
                dependsOn: ["trips.$trips.accommodation.typeOfAccommodation","trips.$trips.accommodation.residential.floorNumberNotApplicable"],
                dependantOnValues: [["2",2],[false,undefined]],
              },
              unitNumberNotApplicable: {
                label: "Unit number not applicable",
                type: TYPES.CHECKBOX,
                path: "trips.$trips.accommodation.residential",
                dependsOn: "trips.$trips.accommodation.typeOfAccommodation",
                dependantOnValues: ["2",2],
              },
              unitNumber: {
                label: "Unit number",
                type: TYPES.TEXT,
                path: "trips.$trips.accommodation.residential",
                dependsOn: ["trips.$trips.accommodation.typeOfAccommodation","trips.$trips.accommodation.residential.unitNumberNotApplicable"],
                dependantOnValues: [["2",2],[false,undefined]],
              },
            },
          },

          lastCityBeforeSingapore: {
            label: "Last city before Singapore",
            type: TYPES.SELECT,
            values: SINGAPORE_SELECT_VALUES.PLACE_OF_RESIDENCE,
            path: "trips.$trips",
          },
          sameLastCityAsNext: {
            label: "Next city same as last city",
            type: TYPES.CHECKBOX,
            path: "trips.$trips",
          },
          nextCityAfterSingapore: {
            label: "Next city after Singapore",
            type: TYPES.SELECT,
            values: SINGAPORE_SELECT_VALUES.PLACE_OF_RESIDENCE,
            path: "trips.$trips",
            dependsOn: "trips.$trips.sameLastCityAsNext",
            dependantOnValues: [false],
          },
          departureDateFromSingapore: {
            label: "Departure date from Singapore",
            type: TYPES.DATE,
            path: "trips.$trips",
          },
          usedPassportUnderDifferentName: {
            label: "Used passport under different name",
            type: TYPES.CHECKBOX,
            path: "trips.$trips",
          },
          nameDifferentFromCurrentPassport: {
            label: "Different name",
            type: TYPES.TEXT,
            path: "trips.$trips",
            dependsOn: "trips.$trips.usedPassportUnderDifferentName",
          },
          currentlyHaveIllness: {
            label: "Currently have illness",
            type: TYPES.CHECKBOX,
            path: "trips.$trips.healthDeclaration",
          },
          visitedInLast14Days: {
            label: "Have you visited the Middle East, Africa or the countries in Latin America in the past 14 days prior to your arrival in Singapore?",
            type: TYPES.CHECKBOX,
            path: "trips.$trips.healthDeclaration",
            dependsOn: "trips.$trips.healthDeclaration.currentlyHaveIllness",
          },
          visitedInLast6Days: {
            label: "Have you visited any of the listed countries in Africa or Latin America in the past 6 days prior to your arrival in Singapore?",
            type: TYPES.CHECKBOX,
            path: "trips.$trips.healthDeclaration",
            dependsOn: "trips.$trips.healthDeclaration.currentlyHaveIllness",
            dependantOnValues: [false],
          },
        },
      },
    },
  },
};
