import React, { useCallback, useContext, useMemo } from "react";
import { Button, Icon, Table, Segment, Header } from "semantic-ui-react";
import SmallConfirmPopup from "../../../../../../../components/SmallConfirmPopup";
import "./CreditCardsConfiguration.styles.scss";
import ConfigurationContext from "../../providers/ConfigurationProvider/Configuration.context";
import apis from "../../../../../../../apis";
import { logServerError } from "../../../../../../../common/logs";
import toastr from "toastr";
import ModalForm from "../../../../../../../components/common/ModalForm";
import CreditCardForm from "./components/CreditCardForm";

const columns = {
  cardName: {
    label: "Card Name",
  },
  last4Digits: {
    label: "Last four digits",
  },
  cardHolder: {
    label: "Card Holder",
  },
};

const CreditCardsConfiguration = () => {
  const { setCreditCards, creditCards } = useContext(ConfigurationContext);

  const deleteCreditCardHandler = useCallback(
    async (id) => {
      try {
        await apis.configuration.creditCardConfig.deleteCreditCard(id);
        setCreditCards((cards) => cards.filter((card) => card._id !== id));
        toastr.success("Credit card deleted!");
      } catch (err) {
        logServerError(err);
      }
    },
    [setCreditCards],
  );

  const createCreditCardHandler = useCallback(
    async (result) => {
      try {
        const {
          data: card,
        } = await apis.configuration.creditCardConfig.createCreditCard(result);
        toastr.success("Credit card created!");
        setCreditCards((cards) => [card, ...cards]);
      } catch (err) {
        logServerError(err);
      }
    },
    [setCreditCards],
  );

  const createModalRendered = useMemo(() => {
    return (
      <ModalForm
        trigger={
          <Button style={{ margin: "12px" }} color="teal">
            Add Credit Card
          </Button>
        }
        headerText="Create new credit card"
        onSubmit={(result) => createCreditCardHandler(result)}
        closeOnSubmit
      >
        <CreditCardForm />
      </ModalForm>
    );
  }, [createCreditCardHandler]);

  return (
    <Segment.Group>
      <Segment className="tableTitle adminTable" inverted>
        <Header size="large" floated="left">
          Credit cards
        </Header>
      </Segment>
      <Table sortable celled striped>
        <Table.Header>
          <Table.Row>
            {Object.keys(columns).map((columnName) => {
              const { label } = columns[columnName];
              return (
                <Table.HeaderCell key={columnName} width={1}>
                  {label}
                </Table.HeaderCell>
              );
            })}
            <Table.HeaderCell width={1}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {creditCards.map((creditCard) => (
            <Table.Row key={creditCard._id}>
              {Object.keys(columns).map((columnName) => {
                const { value } = columns[columnName];

                const tdValue = value
                  ? value(creditCard)
                  : creditCard[columnName];
                let modTdValue = "";
                columnName === "last4Digits"
                  ? (modTdValue = `**** **** **** ${tdValue}`)
                  : (modTdValue = tdValue);

                return (
                  <Table.Cell
                    key={creditCard.id + columnName}
                    width={columnName === "last4Digits" ? 2 : 1}
                  >
                    {modTdValue}
                  </Table.Cell>
                );
              })}
              <Table.Cell>
                <SmallConfirmPopup
                  header="Are you sure you want to delete credit card?"
                  content={<Button negative floated="right" content="Delete" />}
                  callback={() => deleteCreditCardHandler(creditCard._id)}
                >
                  <span className="icon-container">
                    <Icon name="trash" size="large" />
                  </span>
                </SmallConfirmPopup>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {createModalRendered}
    </Segment.Group>
  );
};

export default CreditCardsConfiguration;
