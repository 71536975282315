/* eslint-disable no-restricted-globals */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import ConfirmPopup from "../../../../../components/ConfirmPopup";
import {
  getQATicketByIdApi,
  updateQATicketApi,
  updateQATicketStatusApi,
  getAgentsQATicketByIdApi,
  resolveTicketDisputeApi,
  openTicketDisputeApi,
  acknowledgeTicketApi,
} from "../../../../../apis/departments/qa/QATickets";
import { set } from "lodash";
import { Segment, Button, Loader, Dropdown, Icon } from "semantic-ui-react";
import NavigateButton from "../../../../../components/NavigateButton";
import "./QATicket.styles.scss";
import {
  QA_EVALUATION_TICKET_STATUS,
  QA_EVALUATION_TICKET_DISPUTE_STATUS,
} from "../../constants/qaTicketStatuses";
import * as toastr from "toastr";
import { connect } from "react-redux";
import { AuthService } from "../../../../../services/authService";
import { ROLES } from "../../../../../constants/rolesConstants";
import { logServerError } from "../../../../../common/logs";
import QAQuestionItem from "./components/QAQuestionItem";
import TicketDisputeModal from "../../routers/MyAccount/components/QATicketTable/components/TicketDisputeModal";
import { UsersContext } from "../../../../../common/UsersContext";
import apis from "../../../../../apis";
import renderConfirm from "../../../../../components/common/renderConfirm";

const QATicketPage = ({
  user,
  match: {
    params: { id: ticketId },
  },
}) => {
  const [ticket, setTicket] = useState(null);
  const [isTicketEditable, setIsTicketEditable] = useState(false);
  const [noInputCheck, setNoInputCheck] = useState(true);

  const isQATeamMember = useMemo(() => {
    return AuthService.checkRolesForUser(user, [
      ROLES.QA_AGENT,
      ROLES.QA_MANAGER,
      ROLES.ADMIN,
    ]);
  }, [user]);

  const isQAAgent = useMemo(() => {
    return AuthService.checkRolesForUser(user, [ROLES.QA_AGENT]);
  }, [user]);

  const { allUsers } = useContext(UsersContext);

  const [
    availableAgentsForEvaluation,
    setAvailableAgentsForEvaluation,
  ] = useState();

  useEffect(() => {
    setAvailableAgentsForEvaluation(
      ticket && ticket.agents
        ? allUsers.filter((user) => {
            let res = true;
            for (let agentEv of ticket.agents) {
              if (agentEv.agent._id === user._id) {
                res = false;
                break;
              }
            }
            return res;
          })
        : allUsers,
    );
  }, [allUsers, ticket]);

  useEffect(() => {
    async function loadTicket() {
      const getQATicketApi = isQATeamMember
        ? getQATicketByIdApi
        : getAgentsQATicketByIdApi;

      try {
        const {
          data: { evaluation },
        } = await getQATicketApi(ticketId);
        setTicket(evaluation);
        setIsTicketEditable(
          !(
            evaluation.status === QA_EVALUATION_TICKET_STATUS.Submitted ||
            evaluation.assignedAgentId !== user._id
          ),
        );
      } catch (err) {
        toastr.error("Failed loading ticket!");
      }
    }
    if (ticketId) {
      loadTicket();
    }
  }, [ticketId, isQATeamMember, user]);

  const changeTicketPropertyValue = useCallback(
    (propertyPath, value, updateApi = true) => {
      if (isTicketEditable) {
        const oldTicket = JSON.parse(JSON.stringify(ticket));
        const newTicket = set(oldTicket, propertyPath, value);
        setTicket(newTicket);
        if (updateApi) {
          updateQATicketApi(ticketId, newTicket);
        }
      } else {
        toastr.error(
          "Already submitted or not owned ticket cannot be changed!",
        );
      }
    },
    [ticketId, ticket, isTicketEditable],
  );

  const resolveDispute = useCallback(
    async (agentId, disputeId, disputeResolutionBody) => {
      try {
        const {
          data: { dispute },
        } = await resolveTicketDisputeApi(disputeId, disputeResolutionBody);
        setTicket((ticket) => ({
          ...ticket,
          agents: ticket.agents.map((agentInfo) =>
            agentId === agentId.agentId ? { ...agentInfo, dispute } : agentInfo,
          ),
        }));
        toastr.success("Successfully resolved dispute");
      } catch (err) {
        toastr.error("Failed resolving dispute!");
      }
    },
    [setTicket],
  );

  const createDispute = useCallback(
    (ticketId) => async (newDispute) => {
      if (
        !confirm(
          `Are you sure you want to open dispute with reason text: "${newDispute.disputeReasonText}"`,
        )
      ) {
        return;
      }
      try {
        const {
          data: { evaluation },
        } = await openTicketDisputeApi(ticketId, newDispute);
        setTicket((ticket) => ({ ...ticket, ...evaluation }));
        toastr.success("Successfully created dispute");
      } catch (err) {
        logServerError(err);
      }
    },
    [setTicket],
  );

  const submitTicketReview = useCallback(async () => {
    const newStatus = QA_EVALUATION_TICKET_STATUS.Submitted;
    try {
      await updateQATicketStatusApi(ticketId, newStatus);
      setTicket({ ...ticket, status: newStatus });
      setIsTicketEditable(false);
      toastr.success(`Updated ticket status to ${newStatus}`);
    } catch (err) {
      logServerError(err);
    }
  }, [ticketId, ticket]);

  const renderDisputes = useCallback(
    (agentInfo) => {
      const { agentId, dispute, assessmentResult } = agentInfo;
      if (isQAAgent) {
        return (
          dispute &&
          dispute.status !==
            QA_EVALUATION_TICKET_DISPUTE_STATUS.NOT_RESOLVED && (
            <TicketDisputeModal
              ticketDispute={dispute}
              onSubmit={(disputeBody) =>
                resolveDispute(agentId, dispute._id, disputeBody)
              }
              modalState="resolving"
            >
              <Button>View dispute</Button>
            </TicketDisputeModal>
          )
        );
      }
      if (isQATeamMember) {
        return (
          <TicketDisputeModal
            ticketDispute={dispute}
            onSubmit={(disputeBody) =>
              resolveDispute(agentId, dispute._id, disputeBody)
            }
            modalState="resolving"
          >
            <Button>View dispute</Button>
          </TicketDisputeModal>
        );
      }

      if (ticket.status === QA_EVALUATION_TICKET_STATUS.Submitted) {
        return (
          <TicketDisputeModal
            ticketDispute={dispute}
            agentAssessmentResult={assessmentResult}
            modalState="creating"
            onSubmit={createDispute(ticketId)}
          >
            <Button>{dispute ? "View dispute" : "Open dispute"}</Button>
          </TicketDisputeModal>
        );
      }
      return null;
    },
    [
      ticketId,
      ticket,
      resolveDispute,
      createDispute,
      isQATeamMember,
      isQAAgent,
    ],
  );

  const addingNewAgentHandler = useCallback(
    async (newAgent) => {
      if (
        await renderConfirm.show({
          title: "Confirm Popup",
          message: `Are you sure you want to add agent ${newAgent.fullName} to the evaluation form?`,
          cancelButton: true,
          className: "confirm-popup",
        })
      ) {
        try {
          const {
            data: { agent: agentInfo },
          } = await apis.evaluation.addAgentApi(ticket._id, newAgent._id);

          setTicket({
            ...ticket,
            agents: [
              ...ticket.agents,
              {
                agent: newAgent,
                ...agentInfo,
              },
            ],
          });
          toastr.success(`${newAgent.fullName} has been added to the ticket!`);
        } catch (err) {
          toastr.error("Failed adding agent to ticket!");
        }
      }
    },
    [ticket],
  );

  const deleteAgentHandler = useCallback(
    async (agent) => {
      if (
        await renderConfirm.show({
          title: "Delete Popup",
          message: `Are you sure you want to delete agent ${agent.fullName} from the evaluation form?`,
          cancelButton: true,
          className: "delete-popup",
        })
      ) {
        try {
          await apis.evaluation.deleteAgentApi(ticket._id, agent._id);

          setTicket({
            ...ticket,
            agents: ticket.agents.filter(
              (currAgent) => currAgent.agent._id !== agent._id,
            ),
          });
          toastr.success(`${agent.fullName} has been removed from the ticket!`);
        } catch (err) {
          toastr.error("Failed deleting agent from ticket!");
        }
      }
    },
    [ticket],
  );

  const acknowledgeTicketHandler = useCallback(
    () => async () => {
      if (
        await renderConfirm.show({
          title: "Ticket acknowledgment",
          message: `Are you sure you want to acknowledge this ticket?`,
          cancelButton: true,
        })
      ) {
        try {
          await acknowledgeTicketApi(ticket._id);
          setTicket((ticket) => ({
            ...ticket,
            acknowledge: { [user._id]: true },
          }));
        } catch (err) {
          toastr.error("Failed ticket acknowledging");
        }
      }
    },
    [ticket, setTicket, user],
  );

  const isTicketAcknowledged = useCallback(
    (ticket) => {
      return ticket.acknowledge && ticket.acknowledge[user._id];
    },
    [user],
  );

  const renderNavigateBackButton = useCallback(() => {
    let title = "Back to agent area";
    let route = "/my-account/agent/evaluations";
    if (
      AuthService.checkRolesForUser(user, [
        ROLES.L1_MANAGER,
        ROLES.L2_MANAGER,
        ROLES.BT_MANAGER,
      ])
    ) {
      title = "Back to manager agent area";
      route = "/my-account/manager/evaluations";
    }
    if (AuthService.checkRolesForUser(user, [ROLES.QA_AGENT])) {
      title = "Back to QA agent area";
      route = "/my-account/qa-agent/evaluations";
    }
    if (AuthService.checkRolesForUser(user, [ROLES.QA_MANAGER])) {
      title = "Back to QA manager area";
      route = "/my-account/qa-manager/evaluations";
    }
    if (AuthService.checkRolesForUser(user, [ROLES.ADMIN])) {
      title = "Back to admin area";
      route = "/my-account/admin/partners";
    }

    return <NavigateButton route={route}>{title}</NavigateButton>;
  }, [user]);

  const ticketAgentsFiltered = useMemo(() => {
    if (!ticket) {
      return [];
    }
    const specificAgentId = new URLSearchParams(window.location.search).get(
      "agentId",
    );
    if (specificAgentId) {
      const agents = ticket.agents.filter(
        (agentInfo) => agentInfo.agentId === specificAgentId,
      );
      if (agents.length) {
        return agents;
      }
    }
    return ticket.agents;
  }, [ticket]);

  if (!ticket) {
    return <Loader active />;
  }
  return (
    <Segment.Group>
      <Segment className="sk-table-segment" inverted>
        <div className="sk-table-header">
          {renderNavigateBackButton()}
          <div className="qa-ticket-page-ticket-info">
            <p>#{ticket.zendeskId}</p>
            <a
              href={ticket.zendeskUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link to ticket
            </a>
          </div>
          <div>
            <ConfirmPopup
              content="Are you sure you want submit applicant changes?"
              callback={submitTicketReview}
            >
              <Button disabled={!isTicketEditable || noInputCheck}>
                Submit
              </Button>
            </ConfirmPopup>
            {!isTicketAcknowledged(ticket) && (
              <Button onClick={acknowledgeTicketHandler()}>Acknowledge</Button>
            )}
          </div>
        </div>
      </Segment>
      <Segment>
        <section className="qa-ticket-page-section">
          <h3>General Ticket Assessment</h3>
          <div className="qa-ticket-page-question-container">
            {ticket.generalQuestions.map((question, questionIndex) => (
              <QAQuestionItem
                key={questionIndex}
                question={question}
                onChange={(name, value) => {
                  setNoInputCheck(false);
                  changeTicketPropertyValue(
                    `generalQuestions.${questionIndex}.${name}`,
                    value,
                  );
                }}
              />
            ))}
          </div>
        </section>
        <section className="qa-ticket-page-section">
          <div className="qa-ticket-page-section__subheader">
            <h3>Agent Ticket Assessment</h3>
            <Dropdown
              button
              className="icon agent-ticket-dropdown"
              labeled
              icon={<Icon name="users" color="teal" />}
              text="Add new agent to the evaluation form"
              search={(dropItem, searchString) => {
                return dropItem.filter(
                  (item) =>
                    item.props.children &&
                    item.props.children[0] &&
                    item.props.children[0].props.children &&
                    item.props.children[0].props.children
                      .toLowerCase()
                      .includes(searchString.toLocaleLowerCase()),
                );
              }}
              options={
                availableAgentsForEvaluation &&
                availableAgentsForEvaluation.map((user) => {
                  return (
                    <Dropdown.Item key={user._id}>
                      <div>{user.fullName}</div>
                      <Button
                        icon={<Icon name="plus" />}
                        color="teal"
                        size="mini"
                        basic
                        circular
                        disabled={!isTicketEditable}
                        onClick={() => addingNewAgentHandler(user)}
                      />
                    </Dropdown.Item>
                  );
                })
              }
            />
          </div>

          {ticket &&
            ticketAgentsFiltered
              .filter((agentInfo) => agentInfo.agent)
              .map((agentInfo, agentIndex) => (
                <div key={agentIndex}>
                  <div className="qa-ticket-page-header">
                    <h4>{agentInfo.agent.fullName}</h4>
                    {renderDisputes(agentInfo)}
                    <Button
                      basic
                      size="tiny"
                      icon={<Icon name="trash" color="red" />}
                      disabled={!isTicketEditable}
                      onClick={() => deleteAgentHandler(agentInfo.agent)}
                    />
                  </div>

                  <div className="qa-ticket-page-question-container">
                    {agentInfo.agentQuestions.map((question, questionIndex) => (
                      <QAQuestionItem
                        removeSkip={true}
                        key={questionIndex}
                        question={question}
                        onChange={(name, value) => {
                          setNoInputCheck(false);
                          changeTicketPropertyValue(
                            `agents.${agentIndex}.agentQuestions.${questionIndex}.${name}`,
                            value,
                          );
                        }}
                      />
                    ))}
                  </div>
                  <hr />
                </div>
              ))}
        </section>
        <div style={{ justifyContent: "flex-end", display: "flex" }}>
          <ConfirmPopup
            content="Are you sure you want submit applicant changes?"
            callback={submitTicketReview}
          >
            <Button disabled={!isTicketEditable || noInputCheck}>Submit</Button>
          </ConfirmPopup>
        </div>
      </Segment>
    </Segment.Group>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.currentUser,
  };
};

export default connect(mapStateToProps)(QATicketPage);
