export default ([tableComp, editComp]) => [
  {
    path: "",
    component: tableComp,
  },
  {
    path: "/applicant/:id",
    component: editComp,
  },
];
