import React from "react";
import { preparedDates, prepareChartSeries } from "./utils";
import PerformanceTrend from "../../../PerformanceTrend";
import { isEmpty } from "lodash";

const PerformanceTrendQAReport = ({
  open = false,
  setOpen,
  qaReportData,
  performanceTrendName,
}) => {
  const sortedDates = preparedDates(qaReportData);
  const chartData = prepareChartSeries(qaReportData, sortedDates);

  return (
    <>
      {!isEmpty(chartData) && !isEmpty(sortedDates) && (
        <PerformanceTrend
          open={open}
          setOpen={setOpen}
          performanceTrendFor={{ name: performanceTrendName }}
          chartData={chartData}
          chartLabels={sortedDates}
        />
      )}
    </>
  );
};

export default PerformanceTrendQAReport;
