import React, { useCallback, useState, useMemo, useEffect } from "react";
import ModalForm from "../../../../../../../components/common/ModalForm";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import {
  Segment,
  Header,
  Button,
  Form,
  Select,
  TextArea,
} from "semantic-ui-react";
import {
  getAllReviewsAndCertifications,
  createReviewAndCertification,
  updateReviewAndCertification,
  deleteReviewAndCertification,
} from "../../../../../../../apis/reviewCertification";
import * as toastr from "toastr";
import SiteForm from "./components/SiteForm";

import "./ReviewCertification.styles.scss";

const ReviewCertification = (props) => {
  const [formType, setFormType] = useState("");
  const [template, setTemplate] = useState("");
  const [sites, setSites] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);

  const updateSite = useCallback(async (id, site) => {
    try {
      const { data } = await updateReviewAndCertification(id, site);
      setSites((prev) => prev.map((el) => (el._id === id ? data : el)));
      toastr.success("Successfully edited website template");
    } catch (e) {
      console.error(e);
      toastr.error("Failed to edit website template");
    }
  }, []);

  const createSite = useCallback(
    async (site) => {
      if (
        (site.siteName === "Default" && site.siteId !== "default") ||
        (site.siteName !== "Default" && site.siteId === "default")
      ) {
        toastr.error(
          'Default template name must be "Default" and site id must be "default"!',
        );
      } else if (
        site.siteId === "default" &&
        sites.some((el) => el.siteId === "default" && site.form === el.form)
      ) {
        toastr.error("Default template for selected form type already exists!");
      } else if (
        sites.some(
          (el) =>
            (el.siteId.toLowerCase() === site.siteId.toLowerCase() ||
              el.siteName.toLowerCase() === site.siteName.toLowerCase()) &&
            site.form === el.form,
        )
      ) {
        toastr.error(
          "Template with that name or site id already exists for selected form!",
        );
      } else
        try {
          const { data } = await createReviewAndCertification(site);
          const defaultSite = sites.find(
            (site) => site.siteName === "Default" && site.form === data.form,
          );
          const texts = {
            applicantDeclaration: defaultSite
              ? defaultSite.applicantDeclaration
              : undefined,
            thirdPartyAuthorization: defaultSite
              ? defaultSite.thirdPartyAuthorization
              : undefined,
            visaApplicationIssueDetected:
              data.form === "VISA"
                ? defaultSite
                  ? defaultSite.visaApplicationIssueDetected
                  : undefined
                : undefined,
          };
          setSites((prev) => [{ ...data, ...texts }, ...prev]);
          setTemplate(data.siteId);
          setFormType(data.form);
          updateSite(data._id, texts);
          toastr.success("Successfully created new website template");
        } catch (e) {
          console.error(e);
          toastr.error("Failed to create new website template");
        }
    },
    [sites, updateSite],
  );

  const deleteSite = useCallback(async (id) => {
    try {
      await deleteReviewAndCertification(id);
      setSites((prev) => prev.filter((site) => site._id !== id));
      toastr.success("Successfully deleted website template");
    } catch (e) {
      console.error(e);
      toastr.error("Failed to delete website template");
    }
  }, []);

  const fetchSites = useCallback(async () => {
    try {
      const {
        data,
      } = await getAllReviewsAndCertifications();
      setSites(data);
    } catch (e) {
      console.error(e);
      toastr.error("Failed to fetch websites");
    }
  }, []);

  useEffect(() => {
    fetchSites();
  }, [fetchSites]);

  useEffect(() => {
    setCurrentItem(
      sites.find((site) => site.form === formType && site.siteId === template),
    );
  }, [formType, sites, template]);

  const templateOptions = useMemo(() => {
    const uniqueOptions = [
      ...new Map(
        sites.map((site) => [
          site.siteId,
          { text: site.siteName, value: site.siteId },
        ]),
      ).values(),
    ];

    if (uniqueOptions.length)
      return [
        uniqueOptions.find((option) => option.value === "default"),
        ...uniqueOptions.filter((option) => option.value !== "default"),
      ];
    else return [];
  }, [sites]);

  const formOptions = useMemo(
    () =>
      sites
        .filter((site) => site.siteId === template)
        .map((site) => ({ text: site.form, value: site.form })),
    [sites, template],
  );

  const renderActions = useCallback(
    () => (
      <div style={{ display: "flex", float: "right", gap: "8px" }}>
        {currentItem && currentItem.siteId !== "default" && (
          <React.Fragment>
            <ModalForm
              headerText="Edit website"
              loaderText="Preparing data..."
              onSubmit={(result) => updateSite(currentItem._id, result)}
              closeOnSubmit
              trigger={<Button>Edit</Button>}
            >
              <Form.Field>
                <Form.Input type="input" name="siteName" label="Website name" />
              </Form.Field>
            </ModalForm>

            <ConfirmPopup
              callback={() => deleteSite(currentItem._id)}
              content={`Are you sure you want to DELETE website: ${currentItem.siteName}`}
            >
              <Button>Delete</Button>
            </ConfirmPopup>
          </React.Fragment>
        )}

        <ModalForm
          headerText="Add new website"
          className="review-certification__add-new-modal"
          trigger={<Button>Add new website</Button>}
          onSubmit={(result) => createSite(result)}
          closeOnSubmit
        >
          <SiteForm />
        </ModalForm>
      </div>
    ),
    [createSite, currentItem, deleteSite, updateSite],
  );

  return (
    <Segment.Group>
      <Segment className="tableTopSegment" inverted>
        <Header className="tableTitle" size="large" floated="left">
          <h3>Review and Certification</h3>
          {renderActions()}
        </Header>
      </Segment>
      <Form>
        <div className="review-certification">
          <div className="review-certification__menu">
            <Select
              placeholder="Select template"
              value={template}
              options={templateOptions || []}
              onChange={(ev, field) => setTemplate(field.value)}
            />
            <Select
              placeholder="Select form"
              value={formType}
              options={formOptions || []}
              onChange={(ev, field) => setFormType(field.value)}
            />
            {currentItem && (
              <Segment inverted className="tableTopSegment">
                <Header size="small" inverted>
                  Site ID: {currentItem.siteId}
                </Header>
              </Segment>
            )}
          </div>
          {!currentItem ? (
          <Segment className="tableTopSegment" inverted>
            <Header size="small">
              Please select first template and then form type
            </Header>
          </Segment>
          ) : (
            <div className="review-certification__content">
              <Segment className="tableTopSegment" inverted>
              <Header size="small" inverted>
                Consent And Declaration
              </Header>
              </Segment>

              <Segment className="tableTopSegment" inverted>
              <Header size="small" inverted>
                Applicant's Declaration
              </Header>
              </Segment>
              <TextArea
                placeholder="Write applicant declaration here!"
                value={currentItem.applicantDeclaration || ""}
                className="declaration-textarea"
                rows={20}
                onChange={(event, field) =>
                  setCurrentItem((prev) => ({
                    ...prev,
                    applicantDeclaration: field.value,
                  }))
                }
              />

              <Segment className="tableTopSegment" inverted>
              <Header size="small" inverted>
                Third Party Authorization
              </Header>
              </Segment>
              <TextArea
                placeholder="Write third party authorization here!"
                value={currentItem.thirdPartyAuthorization || ""}
                className="declaration-textarea"
                rows={20}
                onChange={(event, field) =>
                  setCurrentItem((prev) => ({
                    ...prev,
                    thirdPartyAuthorization: field.value,
                  }))
                }
              />

              {formType === "VISA" && (
                <React.Fragment>
                  <Segment className="tableTopSegment" inverted>
                  <Header size="small" inverted>
                    Visa Application Issue Detected
                  </Header>
                  </Segment>
                  <TextArea
                    placeholder="Write visa application issue here!"
                    value={currentItem.visaApplicationIssueDetected || ""}
                    className="declaration-textarea"
                    rows={20}
                    onChange={(event, field) =>
                      setCurrentItem((prev) => ({
                        ...prev,
                        visaApplicationIssueDetected: field.value,
                      }))
                    }
                  />
                </React.Fragment>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "12px",
                  gap: "8px",
                }}
              >
                <ConfirmPopup
                  content="Are you sure you want to discard changes?"
                  callback={() =>
                    setCurrentItem((prev) =>
                      sites.find((site) => site.siteId === prev.siteId),
                    )
                  }
                >
                  <Button color="red" size="large">
                    Discard
                  </Button>
                </ConfirmPopup>
                <ConfirmPopup
                  content="Are you sure you want to save changes?"
                  callback={async () =>
                    updateSite(currentItem._id, currentItem)
                  }
                >
                  <Button color="green" size="large">
                    Save
                  </Button>
                </ConfirmPopup>
              </div>
            </div>
          )}
        </div>
      </Form>
    </Segment.Group>
  );
};

export default ReviewCertification;
