import React from "react";
import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";
import { Button } from "semantic-ui-react";
import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../../constants/endpoints";
import ZendeskIcon from "../../../../../../../../components/Icons/ZendeskIcon/ZendeskIcon";

export const invSchema = {
    generalInfoSeparator: {
        label: "General application information",
        type: TYPES.SEPARATOR,
    },
          _id: {
            label: "ID",
          },
          applicantNumber: {
            label: "Applicant number",
          },
          serviceExtension: {
            label: "Service extension",
          },
          serviceExtensionAt: {
            label: "Service extended at",
            dependsOn: "serviceExtension",
          },
          applicationType: {
            label: "Application Type",
          },
          mainApplicationId: {
            label: "Main Application ID",
            dependsOn: "applicationType",
            dependantOnValues: ["Secondary"],
          },
          siteId: {
            label: "Referral Site",
          },
          cartSiteId: {
            label: "Purchased from website",
          },
          orderId: {
            label: "Order ID",
          },
          orderIdHistory:{
            label:"Order ID History"
          },
          invoiceEmail: {
            label: "Payment email",
          },
          paymentCountry: {
            label: "Payment country",
          },
          zendeskId: {
            label: "Zendesk ID",
            type: TYPES.TEXT,
            required: false,
            renderIcon: (propValue) => (
              <Button
                onClick={() => {
                  window.open(
                    TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(propValue),
                    "_blank",
                  );
                }}
                style={{ width: 36, height: 36, padding: 0, float: "right" }}
                disabled={!propValue}
              >
                <ZendeskIcon className="zendesk-icon" />
              </Button>
            ),
          },
          gclid: {
            label: "Google client ID",
          },
          userLanguage: {
            label: "User form language",
          },
          userCountry: {
            label: "Users IP country",
          },
          salesAgent: {
            label: "Sales Agent",
            type: TYPES.TEXT,
            required: false,
          },
          salesCoupon: {
            label: "Sales Coupon",
            type: TYPES.TEXT,
            required: false,
          },
          firstName: {
            label: "First name / Given name",
            type: TYPES.TEXT,
          },
          lastName: {
            label: "Last name / Surname",
            type: TYPES.TEXT,
          },
          userEmail: {
            label: "Email",
            type: TYPES.TEXT,
          },
}