import React from 'react'
import { Checkbox, Table } from "semantic-ui-react";
import { ALLOWED_REFERRAL_SITES } from '../../../../../EVisa/pages/ApplicantsEdit/components/constants';

const DS11ReviewAndCertification = ({ referralSite = ALLOWED_REFERRAL_SITES.GOVASSIST }) => {
  return (
    <React.Fragment>
        <Table.Row>
            <Table.Cell colSpan="2" >
                <h3>Personal Data Confidentiality</h3>
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell colSpan="2">
                <h4>Consent and Declaration</h4>
                <div>
                    <p>I declare that the information I have provided in this application is truthful, complete and accurate to the best of my knowledge.</p>
                    <p>I understand that false statements made knowingly and willfully in passport applications or in affidavits or other supporting documents submitted therewith are punishable by fine and/or imprisonment under provisions of <a href="https://www.justice.gov/archives/jm/criminal-resource-manual-903-false-statements-concealment-18-usc-1001">18 U.S.C. 1001</a> and/or <a href="https://www.justice.gov/archives/jm/criminal-resource-manual-1944-18-usc-1542-false-statement-application-passport-and-use-passport">18 U.S.C. 1542</a>. Alteration or mutilation of a U.S. passport is punishable by fine and/or imprisonment under provisions of <a href="https://www.justice.gov/archives/jm/criminal-resource-manual-1945-18-usc-1543-making-or-using-forged-passport">19 U.S.C. 1543</a>. The use of a U.S. passport in violation of the restrictions contained therein or of the passport regulations is punishable by fine and/or imprisonment under <a href="https://www.justice.gov/archives/jm/criminal-resource-manual-1946-18-usc-1544-misuse-passport">18 U.S.C. 1544</a>. All statements and documents submitted are subject to verification.</p>
                    <p>If you are submitting on behalf of another individual:</p>
                    <p>The individual I am submitting the application for is aware of all the contents of my submission, and acknowledges and consents to all the aforementioned and following statements. The individual on whose behalf I am submitting the application for is aware that he/she may be liable for offence(s) concerning any false, inaccurate, or incomplete statement or declaration contained in the application. I also understand that I may be liable for abetting these offences.</p>
                </div>
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>AGREE</Table.Cell>
            <Table.Cell>
                <Checkbox checked={true} />
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell colSpan="2">
                <h5>Third Party Authorization</h5>
                <p>I understand that I am applying via GovAssist, LLC; a third-party that is not affiliated with the United States government, that the third-party cannot guarantee application approval, that the third-party service is offered to help expedite and simplify the process, and that I have the option to apply directly with the pertinent government websites. I hereby authorize GovAssist to disclose the information submitted through this service request to Government Authorities, Passport Application Centers, Logistics and Application Support Companies as necessary to provide the requested services. I am aware that GovAssist collaborates with third-party courier companies to secure appointment slots intended to expedite my passport application and to submit documents on my behalf. I consent to these partnered entities, who are duly registered with the passport agencies, to carry my documents and act as my representatives in the submission of my passport application and documents to the government agencies. I also understand that my purchase consists of a processing and service fee to GovAssist, which DOES NOT include non-refundable government fees and $35 USD execution fee, which are required by the Passport Acceptance Facility and the U.S. Department of State. I also understand that my purchase consists of a processing and service fee to GovAssist.</p>
                <p>I confirm and authorize GovAssist, LLC to assist me, by using their processing service in completing and reviewing my application. I understand and acknowledge that I have not been offered legal advice and that this company or its agents and affiliates have never claimed to be qualified to offer legal advice under any circumstance. I also confirm that I am submitting my application and signing it myself.</p>
                <p>By obtaining services from the GovAssist website, I have agreed to the following:</p>
                <p>
                    <a href={`https://${referralSite}/terms-of-use`}>
                    Terms of Use
                    </a>{" "}
                    |{" "}
                    <a href={`https://${referralSite}/terms-of-service`}>
                    Terms of Service{" "}
                    </a>{" "}
                    |{" "}
                    <a href={`https://${referralSite}/refund-policy`}>
                    Refund Policy{" "}
                    </a>{" "}
                    |{" "}
                    <a href={`https://${referralSite}/privacy-policy`}>
                    Privacy Policy{" "}
                    </a>{" "}
                    |{" "}
                    <a href={`https://${referralSite}/legal-disclaimer`}>
                    Disclaimer{" "}
                    </a>
                </p>
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>AGREE</Table.Cell>
            <Table.Cell>
                <Checkbox checked={true} />
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>
                I acknowledge that I have read and agree to GovAssist's <a href={`https://${referralSite}/terms-of-service`}>Terms and Conditions</a>
            </Table.Cell>
            <Table.Cell>
                <Checkbox checked={true} />
            </Table.Cell>
        </Table.Row>
    </React.Fragment>
  )
}

export default DS11ReviewAndCertification