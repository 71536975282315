import React from "react";
import "./Dialpad.scss";
import Stopwatch from "../Stopwatch";
import TwilioService from "../../services/twilioService";
import { Subject, from } from "rxjs";
import { debounceTime, tap, switchMap, filter } from "rxjs/operators";
import { Input, Button, Select } from "semantic-ui-react";
import axios from "../../apis/httpClient";
import toastr from "toastr";
import {
  FILTERED_PRODUCT_NAMES,
  PRODUCT_NAMES_MAPPING,
} from "../../Router/routers/DashboardRouter/routers/Products/constants/productNames";

const buttons = [
  [
    { number: "1", text: null },
    { number: "2", text: "abc" },
    { number: "3", text: "def" },
  ],
  [
    { number: "4", text: "ghi" },
    { number: "5", text: "jkl" },
    { number: "6", text: "mno" },
  ],
  [
    { number: "7", text: "pqrs" },
    { number: "8", text: "tuv" },
    { number: "9", text: "wxyz" },
  ],
  [
    { number: "*", text: null },
    { number: "0", text: "+" },
    { number: "#", text: null },
  ],
];

const productOptions = Object.entries(FILTERED_PRODUCT_NAMES).map(
  ([key, value]) => ({
    key,
    value,
    text: PRODUCT_NAMES_MAPPING[value],
  }),
);

class Dialpad extends React.Component {
  constructor(props) {
    super(props);
    const { phoneNumber, zendeskId, product } = props;

    this.state = {
      phoneNumber: phoneNumber || "",
      zendeskId: zendeskId || "",
      product: product,
      temporaryProduct: product,
      callInProgress: false,
      connection: null,
      ringing: false,
      isIncoming: null,
      existZendeskId: !!zendeskId,
      existApplicant: !!zendeskId,
      zendeskIdStatusLoading: false,
      sendingDigit: "",
    };

    this.subscriptions = [];
    this.onSearch$ = new Subject();
  }

  componentDidMount() {
    this.initializeTwilioCallListeners();
    this.initializeZendeskSearch();
  }

  componentWillUnmount() {
    this.unsubscribeListeners();
    if (this.subscriptionSearch) {
      this.subscriptionSearch.unsubscribe();
    }
  }

  initializeZendeskSearch() {
    this.subscriptionSearch = this.onSearch$
      .pipe(
        tap((zendeskId) => {
          if (!zendeskId) {
            this.setState({
              zendeskIdStatusLoading: false,
              existApplicant: false,
              existZendeskId: false,
            });
          } else {
            this.setState({ zendeskIdStatusLoading: true });
          }
          this.setState({ zendeskId: zendeskId });
        }),
        debounceTime(450),
        filter(Boolean),
        switchMap((zendeskId) => {
          const { product } = this.state;
          return from(
            axios.get(`/zendesk/ticket/${zendeskId}/check?product=${product}`),
          );
        }),
      )
      .subscribe(({ data: { zendesk, applicant } }) => {
        this.setState({
          existApplicant: applicant,
          existZendeskId: zendesk,
          zendeskIdStatusLoading: false,
        });
      });
  }
  initializeTwilioCallListeners() {
    this.unsubscribeListeners();

    this.subscriptions.push(
      TwilioService.callRingingObservable.subscribe((data) => {
        const { connection, isIncoming } = data;
        this.setState({
          ringing: true,
          callInProgress: true,
          connection,
          isIncoming,
        });
      }),
    );
    this.subscriptions.push(
      TwilioService.callRingingStoppedObservable.subscribe((data) => {
        const { accepted, isIncoming } = data;
        const newState = { ringing: false, isIncoming };
        if (!accepted) {
          newState.connection = null;
          newState.callInProgress = false;
        }
        this.setState(newState);
      }),
    );
    this.subscriptions.push(
      TwilioService.callStartedObservable.subscribe((data) => {
        const { connection, isIncoming } = data;
        this.setState({
          callInProgress: true,
          connection,
          ringing: false,
          isIncoming,
        });
      }),
    );
    this.subscriptions.push(
      TwilioService.callStoppedObservable.subscribe((data) => {
        this.setState({
          callInProgress: false,
          connection: null,
          isIncoming: null,
          sendingDigit: "",
        });
      }),
    );
  }

  unsubscribeListeners() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  handleDialPadClose() {
    this.props.closeModal();
  }

  handleNumberAdd(buttonElement) {
    const { number } = buttonElement;

    this.setState({ phoneNumber: `${this.state.phoneNumber}${number}` });
  }

  handleCallEnd() {
    const { connection } = this.state;
    connection.disconnect();
  }

  handleIncomingCallAccept() {
    const { connection } = this.state;
    connection.accept();
  }
  handleIncomingCallReject() {
    const { connection } = this.state;
    connection.reject();
  }

  async handleCallStart(
    forceNoApplicantCall = false,
    forceNoZendeskIdCall = false,
  ) {
    const {
      phoneNumber,
      existApplicant,
      existZendeskId,
      zendeskId,
      product,
    } = this.state;

    if (!phoneNumber) {
      toastr.error("Phone number is required.", "Error");
      return;
    }
    // Call even if no zendesk ID
    if (forceNoZendeskIdCall) {
      return TwilioService.startCall(phoneNumber, {
        forceNoTicket: true,
        product,
      });
    }
    // Call if no applicant with given zendesk ID
    if (existZendeskId && (existApplicant || forceNoApplicantCall)) {
      return TwilioService.startCall(phoneNumber, { zendeskId, product });
    }
  }

  getCallParticipant() {
    try {
      const {
        connection: {
          parameters: { From } = {},
          message: { phoneNumber } = {},
        } = {},
        isIncoming,
      } = this.state;

      return isIncoming ? From : phoneNumber;
    } catch (err) {
      return null;
    }
  }

  renderAdditionalProps() {
    const { connection } = this.state;

    const {
      zendeskUrl,
      dashboardUrl,
      firstName,
      lastName,
      userEmail,
    } = TwilioService.getIncomingCallParticipantInfo(connection);

    return (
      <div className="sk-dial-pad-ringing-props">
        {firstName && lastName && <p>{`${firstName} ${lastName}`}</p>}
        {userEmail && <p>{userEmail}</p>}
        {zendeskUrl && (
          <p>
            <a target="_blank" rel="noopener noreferrer" href={zendeskUrl}>
              Zendesk URL
            </a>
          </p>
        )}
        {dashboardUrl && (
          <p>
            <a target="_blank" rel="noopener noreferrer" href={dashboardUrl}>
              Dashboard URL
            </a>
          </p>
        )}
      </div>
    );
  }

  renderDialPadPopup() {
    const {
      phoneNumber,
      product,
      zendeskId,
      zendeskIdStatusLoading,
      existApplicant,
      existZendeskId,
    } = this.state;

    return (
      <div className="sk-dial-pad-container">
        <div className="sk-dial-pad-input-container">
          <div className="sk-dial-pad-input-numbers">
            <Input
              // className="sk-dial-pad-input"
              name="phoneNumber"
              value={phoneNumber}
              onChange={(event) => {
                this.setState({ phoneNumber: event.target.value });
              }}
              // type="number"
              label="Phone number:"
              size="small"
            ></Input>
            <Input
              // className="sk-dial-pad-input"
              icon={
                zendeskIdStatusLoading
                  ? ""
                  : existZendeskId
                  ? "check"
                  : "delete"
              }
              loading={zendeskIdStatusLoading}
              name="zendeskId"
              value={zendeskId}
              onChange={(event) => {
                this.onSearch$.next(event.target.value);
              }}
              // type="number"
              label="Zendesk ID: "
              size="small"
              // tabIndex
              ref={this.zendeskIdRef}
            ></Input>
          </div>
          <div className="sk-dial-pad-input-side">
            <button
              onClick={() => this.handleDialPadClose()}
              className="sk-dial-pad-delete-button"
            >
              <i className="remove icon"></i>
            </button>
          </div>
        </div>
        <div>
          <Button
            circular
            floated="right"
            onClick={() =>
              this.setState({ temporaryProduct: product, product: null })
            }
          >
            {PRODUCT_NAMES_MAPPING[product]}
          </Button>
        </div>
        <div className="sk-dial-pad-buttons-container">
          {buttons.map((buttonGroup, index) => (
            <div className="sk-dial-pad-buttons-row" key={index}>
              {buttonGroup.map((buttonElement, buttonIndex) => (
                <button
                  key={buttonIndex}
                  type="button"
                  onClick={() => {
                    this.handleNumberAdd(buttonElement);
                  }}
                  className="sk-dial-pad-button"
                  value={buttonElement.number}
                >
                  <div className="sk-dial-pad-button-content">
                    {buttonElement.number}
                    <span>{buttonElement.text}</span>
                  </div>
                </button>
              ))}
            </div>
          ))}
        </div>
        {zendeskIdStatusLoading || existApplicant ? (
          <button
            className="sk-dial-pad-call-button"
            disabled={zendeskIdStatusLoading}
            onClick={() => this.handleCallStart()}
          >
            <i aria-hidden="true" className="call icon"></i>
          </button>
        ) : (
          <div className="sk-dial-pad-call-warning-zendeskId">
            <p>
              {!existZendeskId
                ? "Will not call because Zendesk ID does not exist"
                : "Will not call because Zendesk ID does not belong to an applicant"}{" "}
            </p>
            <div className="button-group">
              <Button
                onClick={() => {
                  this.handleCallStart(!existApplicant, !existZendeskId);
                }}
              >
                {!existZendeskId
                  ? "Call and create a new ticket"
                  : "Force Call"}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderDialPadInCall() {
    const { connection, sendingDigit } = this.state;
    return (
      <div className="sk-dial-pad-in-call-container">
        <div
          className="sk-dial-pad-in-call-info-container"
          style={{ height: "100%" }}
        >
          <div>
            <h5>{this.getCallParticipant()}</h5>
          </div>
          {this.renderAdditionalProps()}

          <Stopwatch></Stopwatch>
          <h6>{sendingDigit}</h6>
          <div className="sk-dial-pad-buttons-container">
            {buttons.map((buttonGroup, index) => (
              <div className="sk-dial-pad-buttons-row" key={index}>
                {buttonGroup.map((buttonElement, buttonIndex) => (
                  <button
                    key={buttonIndex}
                    type="button"
                    onClick={() => {
                      this.setState({
                        sendingDigit: `${sendingDigit}${buttonElement.number}`,
                      });

                      TwilioService.sendDigits(
                        connection,
                        buttonElement.number,
                      );
                    }}
                    className="sk-dial-pad-button"
                    value={buttonElement.number}
                  >
                    <div className="sk-dial-pad-button-content">
                      {buttonElement.number}
                      <span>{buttonElement.text}</span>
                    </div>
                  </button>
                ))}
              </div>
            ))}
          </div>
        </div>

        <button
          className="sk-dial-pad-end-call-button"
          onClick={() => this.handleCallEnd()}
        >
          <i aria-hidden="true" className="call icon"></i>
        </button>
      </div>
    );
  }

  renderDialPadRinging() {
    return (
      <div className="sk-dial-pad-ringing-container">
        <div
          className="sk-dial-pad-ringing-info-container"
          style={{ height: "100%" }}
        >
          <h5 style={{ alignSelf: "center", fontSize: "20px" }}>
            {this.getCallParticipant()}
          </h5>
          {this.renderAdditionalProps()}
        </div>
        <div className="sk-dial-pad-menage-incoming-call-buttons">
          <button
            className="ui circular icon button accept-call"
            onClick={() => this.handleIncomingCallAccept()}
          >
            <i className="phone accept-call-icon is-animating"></i>
          </button>
          <button
            className="ui circular icon button reject-call"
            onClick={() => this.handleIncomingCallReject()}
          >
            <i
              aria-hidden="true"
              className="phone reject-call-icon is-animating"
            ></i>
          </button>
        </div>
      </div>
    );
  }

  renderProductPicker() {
    const { temporaryProduct } = this.state;

    return (
      <div className="sk-dial-pad-product-selection">
        <h3>Please select product</h3>
        <Select
          value={temporaryProduct}
          options={productOptions}
          onChange={(_, field) => {
            this.setState({ temporaryProduct: field.value });
          }}
        />
        <Button
          disabled={!temporaryProduct}
          positive={temporaryProduct}
          onClick={() => {
            this.setState({ product: temporaryProduct });
            this.onSearch$.next("");
          }}
        >
          Accept
        </Button>
      </div>
    );
  }

  render() {
    const { callInProgress, ringing, isIncoming, product } = this.state;

    if (!callInProgress && !product) {
      return this.renderProductPicker();
    }

    return (
      <div className="sk-dial-pad-modal">
        {!callInProgress
          ? this.renderDialPadPopup()
          : ringing && isIncoming
          ? this.renderDialPadRinging()
          : this.renderDialPadInCall()}
      </div>
    );
  }
}

export default Dialpad;
