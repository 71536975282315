import React from "react";
import * as moment from "moment";

import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../constants/endpoints";
import { getDateFormat } from "../../../../../../../common/date-format";

export const columns = {
  fullName: {
    label: "Name",
  },
  startForwarding: {
    label: "Start forwarding",
    value: (applicant) =>
      applicant.hasAlreadyMoved
        ? `Already moved`
        : moment(applicant.startForwarding).utc(false).format("YYYY-MM-DD"),
  },
  userEmail: {
    label: "Email",
  },
  _id: {
    label: "ID",
    value: (applicant) => (
      <a
        href={`usps/applicant/${applicant._id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {applicant._id}
      </a>
    ),
  },
  createdAt: {
    label: "Created at",
    value: (applicant) => getDateFormat(applicant.createdAt),
  },
  paymentStatus: {
    label: "Payment status",
  },
  status: {
    label: "Status",
  },
  zendeskId: {
    label: "Zendesk ID",
    value: (applicant) => (
      <a
        href={TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(applicant.zendeskId)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {applicant.zendeskId}
      </a>
    ),
  },
};
