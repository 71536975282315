import React, { useEffect } from "react";
import { Confirm, Button } from "semantic-ui-react";
import { useCallback, useState } from "react";

const ConfirmPopup = ({
  children,
  content,
  cancelButton,
  confirmButton,
  countdown,
  size,
  onOpen = () => {},
  onClose = () => {},
  callback = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [countdownTime, setCountdownTime] = useState(0);

  useEffect(() => {
    if (countdown && open) {
      setCountdownTime(countdown);
      let localCountdown = countdown;
      const intervalRef = setInterval(() => {
        setCountdownTime(--localCountdown);
      }, 1000);
      setTimeout(() => {
        clearInterval(intervalRef);
      }, countdown * 1000);
    }
  }, [countdown, open]);

  const openHandler = useCallback(
    (event) => {
      if (event) {
        event.stopPropagation();
      }
      setOpen(true);
      onOpen();
    },
    [onOpen],
  );

  const closeHandler = useCallback(
    (event) => {
      if (event) {
        event.stopPropagation();
      }
      setOpen(false);
      onClose();
    },
    [onClose],
  );

  const confirmHandler = useCallback(
    (event) => {
      closeHandler(event);
      callback();
    },
    [closeHandler, callback],
  );

  return (
    <React.Fragment>
      {children ? (
        React.cloneElement(children, { onClick: openHandler })
      ) : (
        <Button onClick={openHandler}>Open</Button>
      )}
      <Confirm
        content={content || "Are you sure?"}
        open={open}
        cancelButton={cancelButton}
        onCancel={closeHandler}
        onConfirm={confirmHandler}
        size={size}
        confirmButton={
          <Button disabled={countdownTime > 0}>
            {confirmButton || 'OK'} {countdownTime ? `(${countdownTime})` : ""}
          </Button>
        }
      />
    </React.Fragment>
  );
};

export default ConfirmPopup;
