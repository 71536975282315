import { LocalStorageService } from "./localStorageService";
import { history } from "../modules/history";
import TwilioService from "../services/twilioService";
import socketService from "./socketService";
import store from "../store";
import { FETCH_CURRENT_USER } from "../store/actions/actionTypes";
import { SessionStorageService } from "./sessionStorageService";
import auth from "../apis/auth";
import { emitLogout, LogoutListener } from "../events";

const chatModule = window.SKChat;
export class AuthService {
  static async login(loginBody, zendeskView) {
    const { data } = await auth.getUserLogin(loginBody);

    AuthService.saveAuthStateToStorage(data, false);

    setTimeout(() => {
      zendeskView && history.push("/zendesk");
      TwilioService.start();
    }, 0);

    return data;
  }

  static async refreshSession() {
    try {
      const { data } = await auth.refreshSession(LocalStorageService.getItem("refreshToken"));

      AuthService.saveAuthStateToStorage(data, false);

      setTimeout(() => {
        TwilioService.start();
      }, 0);

      return data;
    } catch(e) {
      console.error(e)
      emitLogout();
    }
  }

  static async socialLogin() {
    await auth.getAuthUser();
  }

  static saveLoginTokensToStorage(data) {
    const { token, twilioToken, refreshToken} = data;
    
    LocalStorageService.setItem("token", token);
    LocalStorageService.setItem("twilioToken", twilioToken);
    LocalStorageService.setItem("refreshToken", refreshToken);
  }

  static async simulateUserLogin(userId) {
    const { data } = await auth.getSimulatedUserLogin(userId);
    AuthService.saveAuthStateToStorage(data, true);

    return data;
  }

  static saveAuthStateToStorage(data, simulate) {
    const { user, token, refreshToken, twilioToken } = data;
    if (simulate) {
      SessionStorageService.setItem("user", user);
      SessionStorageService.setItem("token", token);
    } else {
      LocalStorageService.setItem("user", user);
      LocalStorageService.setItem("token", token);
      LocalStorageService.setItem("twilioToken", twilioToken);
      LocalStorageService.setItem("refreshToken", refreshToken);
    }
  }

  static clearAuthStateFromLocalStorage() {
    LocalStorageService.removeItem("user");
    LocalStorageService.removeItem("token");
  }

  static clearAuthStateFromSessionStorage() {
    SessionStorageService.removeItem("user");
    SessionStorageService.removeItem("token");
  }

  static checkRolesForUser(user, roles) {
    if (typeof roles === "string") {
      roles = [roles];
    }
    return (
      user && user.roles && user.roles.some((role) => roles.includes(role))
    );
  }

  static logout(zendeskView) {
    if (!SessionStorageService.getUser()) {
      AuthService.clearAuthStateFromLocalStorage();
      if (chatModule) {
        chatModule.clearAuth();
      }

      socketService.sendEvent("logout");
      TwilioService.stop();
    } else {
      AuthService.clearAuthStateFromSessionStorage();
      window.close();
    }
    store.dispatch({
      type: FETCH_CURRENT_USER,
      payload: null,
    });
    history.push(zendeskView ? "/zendesk-login" : "/login");
  }
}

LogoutListener.on("logout", (zendeskView) => {
  AuthService.logout(zendeskView);
});
