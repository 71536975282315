import React, { useCallback } from "react";
import { Grid, Header, Form, Segment, Button } from "semantic-ui-react";
import { formDataToObject } from "../../common/formData";
import { logServerError } from "../../common/logs";
import { AuthService } from "../../services/authService";
import { useDispatch } from "react-redux";
import { FETCH_CURRENT_USER } from "../../store/actions/actionTypes";
import { NavLink, useHistory } from "react-router-dom";
import { API_ENDPOINT } from "../../constants/endpoints";
import { LocalStorageService } from "../../services/localStorageService";

import "./Login.styles.scss";

const LoginForm = ({ zendeskView }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmitHandler = useCallback(
    async (ev) => {
      const loginObject = formDataToObject(new FormData(ev.target));
      try {
        const {
          twoFactorAuthEnabled,
          isConfiguredTwoFactorAuth,
          user,
          userId,
        } = await AuthService.login(loginObject, zendeskView);
        if (twoFactorAuthEnabled) {
          LocalStorageService.setItem("userId", userId);
          LocalStorageService.setItem(
            "isConfiguredTwoFactorAuth",
            isConfiguredTwoFactorAuth,
          );
          history.push("/two-factor-authentication");
        } else
          dispatch({
            type: FETCH_CURRENT_USER,
            payload: user,
          });
      } catch (err) {
        logServerError(err);
      }
    },
    [dispatch, history, zendeskView],
  );

  return (
    <Grid textAlign="center" className="main-grid-login" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          Log-in to your account
        </Header>
        <Form size="large" onSubmit={onSubmitHandler}>
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Username"
              name="username"
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              name="password"
            />
            <div className="main-grid-login__forgot-password">
              <NavLink to="forgot-password">Forgot password?</NavLink>
            </div>
            <Button color="teal" fluid size="large">
              Login
            </Button>
            {!zendeskView && (
              <>
                <span>or</span>
                <div
                  class="google-btn"
                  onClick={() =>
                    window.location.replace(`${API_ENDPOINT}auth/google`)
                  }
                >
                  <div class="google-icon-wrapper">
                    <img
                      class="google-icon"
                      alt="google-icon"
                      src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                    />
                  </div>
                  <p class="btn-text">
                    <b>Sign in with Google</b>
                  </p>
                </div>
              </>
            )}
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default LoginForm;
