import React, { useCallback, useState } from "react";
import { Grid, Header, Form, Segment, Button } from "semantic-ui-react";
import { formDataToObject } from "../../common/formData";
import { logServerError } from "../../common/logs";
import { useHistory } from "react-router-dom";
import auth from "../../apis/auth";
import toastr from "toastr";

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const onSubmitHandler = useCallback(
    async (event) => {
      const forgotPasswordObject = formDataToObject(new FormData(event.target));

      if (!!forgotPasswordObject.email) {
        setIsLoading(true);
        try {
          const {
            data: { message },
          } = await auth.forgotPassword(forgotPasswordObject);
          toastr.success(message);
          setIsLoading(false);
          history.push("");
        } catch (err) {
          logServerError(err);
          setIsLoading(false);
        }
      }
    },
    [history],
  );

  return (
    <Grid textAlign="center" className="main-grid-login" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          Forgot password
        </Header>
        <Form size="large" onSubmit={onSubmitHandler}>
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Email"
              name="email"
            />
            <Button color="teal" fluid size="large" disabled={isLoading}>
              Submit
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default LoginForm;
