import { FORM_SCHEMA_TYPES } from "../constants/schemaGenerator/formSchemaTypes";

export function getEmptyApplicantFromSchema(applicantSchema) {
  const applicant = {};
  Object.keys(applicantSchema).forEach(schemaPropertyKey => {
    _addApplicantProperty(applicant, applicantSchema, schemaPropertyKey);
  });
  return applicant;
}

function _addApplicantProperty(applicant, schema, schemaKey) {
  switch (schema[schemaKey].type) {
    case FORM_SCHEMA_TYPES.SEPARATOR: // if separator just skip
      return;
    // case FORM_SCHEMA_TYPES.NESTED: // if nested go recursively
    //   applicant[schemaKey] = {};
    //   Object.keys(schema[schemaKey].nested).forEach(key => {
    //     _addApplicantProperty(
    //       applicant[schemaKey],
    //       schema[schemaKey].nested,
    //       key
    //     );
    //   });
    //   break;
    case FORM_SCHEMA_TYPES.ARRAY: // if any type of array just init to array
    case FORM_SCHEMA_TYPES.MULTI_SELECT:
    case FORM_SCHEMA_TYPES.MULTI_TEXT:
      applicant[schemaKey] = [];
      return;
    default:
      applicant[schemaKey] = undefined;
      break;
  }
}

function _generateAssocZeroToN(n, fromOne) {
  const assoc = {};
  for (let i =  0; i < n; i++) {
    assoc[`${i}`] = fromOne ? i + 1 : i;
  }
  return assoc;
}

export const DEFAULT_SCHEMA_DATA = {
  "default-seconds": _generateAssocZeroToN(60),
  "default-minutes": _generateAssocZeroToN(60),
  "default-hours": _generateAssocZeroToN(24),
  "default-days": _generateAssocZeroToN(32, true),
};
