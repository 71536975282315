import React from 'react';
import Avatar from "../../../../../../../../../components/Avatar";
import TimeLineAction from "./TimeLineAction.component";
import { Icon } from "semantic-ui-react";

const allowPinTypes = ['comment'];

const TimeLineEntry = ({ avatar, commentAction, handlePinNote, showPins = true }) => {
  const [comment, setComment] = React.useState(null);

  const pinNote = (id, pinned) => {
    handlePinNote(id, pinned);
    comment.pinned = pinned;

    setComment(comment);
  };

  React.useEffect(() => {
    setComment(commentAction);
  }, [commentAction]);
  
  if (!comment) {
    return null;
  }

  return (
    <div className="appn-timeline-entry">
      <div className="appn-timeline-entry-avatar">
        <Avatar text={avatar} />
      </div>
      <div className="appn-timeline-entry-content">
        {
          showPins && allowPinTypes.includes(comment.type) ? (
            <div className="appn-timeline-entry-pin" onClick={() => pinNote(comment.id, !comment.pinned)}>
            { comment.pinned ? <Icon name="pin" color="blue" /> : <Icon name="pin" /> }
            </div>
          ): null
        }
        <TimeLineAction note={comment} />
      </div>
    </div>
  );
};
export default TimeLineEntry;
