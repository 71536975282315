import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Modal,
  Popup,
  Table,
  Input,
  Header,
  Divider,
  Segment,
  Loader,
} from "semantic-ui-react";
import { ApplicantDocumentsContext } from "../../../../../../../../../../common/ApplicantDocumentsContext";
import ConfirmPopup from "../../../../../../../../../../components/ConfirmPopup";
import { getDateFormat } from "../../../../../../../../../../common/date-format";
import apis from "../../../../../../../../../../apis";
import AddNewDocumentModal from "../AddNewDocumentModal/AddNewDocumentModal.component";
import DownloadPassportPdf from "../DownloadPassportPdf/DownloadPassportPdf.component";
import toastr from "toastr";
import { useSelector } from "react-redux";
import {
  ROLES,
  isManagerOrAdmin,
} from "../../../../../../../../../../constants/rolesConstants";
import { DOCUMENT_TYPE } from "../ApplicantDocumentHandlerView/ApplicantDocumentHandlerView.component";

import { partyiallyUpdateApplicantApi } from "../../../../../../../../../../apis/applicantApi";
import {
  addSpaceBeforeCapital,
  capitalize,
} from "../../../../../../../../../../common/string-format";
import {
  byteArrayToUrl,
  capitalizeEachWord,
  checkFileType,
  isPassportApplication,
} from "../../../../../../../../../../utils";

import applicantDocumentsHandler from "../../../../../../../../../../apis/applicantDocumentsHandler";
import PassportPortraitPhoto from "../PassportPortraitPhoto";

const DocumentView = (props) => {
  const { data, setData, documentType, shouldUpload } = props;

  const { api, applicant } = useContext(ApplicantDocumentsContext);

  const user = useSelector((state) => state.currentUser);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [editingName, setEditingName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [chosenDocument, setChosenDocument] = useState(null);

  const isDocumentClient = documentType === DOCUMENT_TYPE.client;

  useEffect(() => {
    if (
      applicant.applicationType === "Main" &&
      data.length > 0 &&
      data[0].type !== "Main"
    )
      setData((prev) => [
        prev.find((el) => el.type === "Main"),
        ...prev.filter((el) => el.type !== "Main"),
      ]);
  }, [applicant.applicationType, data, setData]);

  const handleSetActiveBrochure = useCallback(
    async (documentId, id) => {
      setIsRequestLoading(true);

      try {
        const { data } =
          await apis.applicantDocumentHandler.updateActiveVersion(
            api,
            documentId,
            id,
          );
        setData((prev) => prev.map((el) => (el.id !== data.id ? el : data)));
        toastr.success("Successfully set document as active.");
      } catch (error) {
        toastr.error("Failed to set document as active.");
        console.error(error);
      }

      setIsRequestLoading(false);
    },
    [setData, api],
  );

  const handleDeleteDocument = useCallback(
    async (brochureId, id) => {
      setIsRequestLoading(true);

      try {
        const { data } =
          await apis.applicantDocumentHandler.deleteDocumentVersion(
            api,
            brochureId,
            id,
          );
        setData((prev) => prev.map((el) => (el.id !== data.id ? el : data)));
        toastr.success("Successfully deleted document.");
      } catch (error) {
        toastr.error("Failed to delete document.");
        console.error(error);
      }

      setIsRequestLoading(false);
    },
    [api, setData],
  );

  const handleDeleteDocumentAllVersions = useCallback(
    async (applicantId, documentId, name, files) => {
      setIsRequestLoading(true);
      try {
        await apis.applicantDocumentHandler.deleteDocumentAllVersions(
          api,
          documentId,
        );
        setData((prev) =>
          prev.map((applicant) =>
            applicant.id === applicantId
              ? {
                  ...applicant,
                  documents: applicant.documents.filter(
                    (doc) => doc.id !== documentId,
                  ),
                }
              : applicant,
          ),
        );
        toastr.success(
          "Successfully deleted document and all previous versions.",
        );
      } catch (error) {
        toastr.error("Failed to delete document.");
        console.error(error);
      }

      if (documentType === DOCUMENT_TYPE.client) {
        try {
          const newFiles = JSON.parse(JSON.stringify(files));
          delete newFiles[name];
          await partyiallyUpdateApplicantApi(
            applicantId,
            { files: newFiles },
            api,
          );
        } catch (error) {
          toastr.error("Failed to delete document.");
          console.error(error);
        }
      }
      setIsRequestLoading(false);
    },
    [api, documentType, setData],
  );

  const handleRenameDocument = useCallback(
    async (id) => {
      setIsRequestLoading(true);
      try {
        const { data } = await apis.applicantDocumentHandler.updateDocument(
          api,
          id,
          { name: editingName },
        );
        setData((prev) =>
          prev.map((applicant) => ({
            ...applicant,
            documents: applicant.documents.map((doc) =>
              doc.id === id ? data : doc,
            ),
          })),
        );
        toastr.success("Successfully renamed document.");
      } catch (error) {
        toastr.error("Failed to delete document.");
        console.error(error);
      }

      setIsRequestLoading(false);
    },
    [api, editingName, setData],
  );

  const _logRetrieveDocument = useCallback(async (documentId, key, type) => {
    const { email: identity } = user;
    const { id: applicationId } = applicant;
    
    await applicantDocumentsHandler.logRetrieveDocument(applicationId, documentId, identity, key)
  }, [applicant, user])

  const openDocument = useCallback(async (key, type, documentId = '') => {
    try {
      setIsLoading(key);

      await _logRetrieveDocument(documentId, key, type)

      const {
        data: {
          buffer: { data },
        },
      } = await apis.applicantDocumentHandler.getFile(key);
      const url = byteArrayToUrl(data, type);
      window.open(url, "_blank");
    } catch (e) {
      console.error(e);
      toastr.error("Failed to open file");
    } finally {
      setIsLoading(false);
    }
  }, [_logRetrieveDocument]);

  return (
    <div>
      {data.map((app, index) => {
        const { documents, id, firstName, lastName, type, files } = app;
        return (
          <React.Fragment key={`container-${id}`}>
            {index > 0 && <Divider />}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <Segment inverted className="tableTopSegment">
                <Header inverted>
                  {capitalizeEachWord(
                    `${
                      type === "Main" ? "Main Applicant" : "Secondary Applicant"
                    } - ${firstName} ${lastName}`,
                  )}
                </Header>
              </Segment>

              {!shouldUpload && isPassportApplication(api) && (
                <DownloadPassportPdf
                  applicant={app.type === "Main" ? applicant : app}
                  api={api}
                />
              )}
              {shouldUpload && (
                <div
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <AddNewDocumentModal
                    setData={setData}
                    index={index}
                    documentType={documentType}
                    applicant={app}
                    chosenDocument={chosenDocument}
                    removeChosenDocument={() => setChosenDocument(null)}
                  />
                </div>
              )}
            </div>

            {
              documentType === DOCUMENT_TYPE.client 
              && isPassportApplication(api) ? (
                <PassportPortraitPhoto
                  id={id}
                  api={api}
                  applicant={applicant}
                />
              ) : null
            }

            <Table celled striped fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>Document name</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Uploaded date</Table.HeaderCell>
                  <Table.HeaderCell width={1}>
                    Is sent to applicant?
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1}>Sent date</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {documents.length === 0 && (
                  <Table.Row>
                    <Table.Cell width={1}>No documents</Table.Cell>
                  </Table.Row>
                )}
                {documents.length > 0 &&
                  documents.map(
                    ({
                      id: documentId,
                      name,
                      activeVersion,
                      previousVersions,
                    }) => {
                      if (!activeVersion)
                        return (
                          <Table.Row>
                            <Table.Cell colSpan={4} style={{ color: "red" }}>
                              Document "{name}" is missing (doesn't have active
                              version)
                            </Table.Cell>
                            {shouldUpload && (
                              <Popup
                                content="Upload file"
                                position="top center"
                                trigger={
                                  <Button
                                    color="blue"
                                    icon="upload"
                                    disabled={
                                      isRequestLoading ||
                                      previousVersions.length >= 5
                                    }
                                    onClick={() => {
                                      setChosenDocument({ documentId, name });
                                      document
                                        .getElementById("add-new-document")
                                        .click();
                                    }}
                                  />
                                }
                              />
                            )}
                          </Table.Row>
                        );
                      return (
                        <Fragment key={documentId}>
                          <Table.Row>
                            <Table.Cell>
                              {isDocumentClient
                                ? capitalize(addSpaceBeforeCapital(name))
                                : name}
                            </Table.Cell>
                            <Table.Cell>
                              {getDateFormat(activeVersion.dateUploaded)}
                            </Table.Cell>
                            <Table.Cell>
                              {activeVersion.hasAlreadySentToCustomer
                                ? "YES"
                                : "NO"}
                            </Table.Cell>
                            <Table.Cell>
                              {activeVersion.hasAlreadySentToCustomer
                                ? getDateFormat(activeVersion.sentDate)
                                : "-"}
                            </Table.Cell>
                            <Table.Cell>
                              {shouldUpload && (
                                <Popup
                                  content="Upload file"
                                  position="top center"
                                  trigger={
                                    <Button
                                      color="blue"
                                      icon="upload"
                                      disabled={
                                        isRequestLoading ||
                                        previousVersions.length >= 5
                                      }
                                      onClick={() => {
                                        setChosenDocument({ documentId, name });
                                        document
                                          .getElementById("add-new-document")
                                          .click();
                                      }}
                                    />
                                  }
                                />
                              )}
                              <Popup
                                content="Preview file"
                                position="top center"
                                trigger={
                                  isLoading === activeVersion.fileKey ? (
                                    <Loader inline active />
                                  ) : (
                                    <Button
                                      color="yellow"
                                      disabled={
                                        !activeVersion.fileKey || isLoading
                                      }
                                      icon="file"
                                      onClick={() =>
                                        openDocument(
                                          activeVersion.fileKey,
                                          checkFileType(
                                            activeVersion.fileKey.replace(
                                              ".enc",
                                              "",
                                            ),
                                            true,
                                          ),
                                          documentId
                                        )
                                      }
                                    />
                                  )
                                }
                              />

                              {!isDocumentClient && (
                                <Modal
                                  closeIcon
                                  className="brochures_archive_modal"
                                  trigger={
                                    <span>
                                      <Popup
                                        content="Preview previous versions"
                                        position="top center"
                                        trigger={
                                          <Button
                                            color="orange"
                                            type="button"
                                            icon="archive"
                                            disabled={!previousVersions.length}
                                          />
                                        }
                                      />
                                    </span>
                                  }
                                >
                                  <Modal.Header>{`${name} - Previous versions`}</Modal.Header>
                                  <Modal.Content>
                                    <Table celled striped fixed>
                                      <Table.Header>
                                        <Table.Row>
                                          <Table.HeaderCell width={2}>
                                            Preview
                                          </Table.HeaderCell>
                                          <Table.HeaderCell width={1}>
                                            Uploaded date
                                          </Table.HeaderCell>
                                          <Table.HeaderCell width={1}>
                                            Is sent to applicant?
                                          </Table.HeaderCell>
                                          <Table.HeaderCell width={1}>
                                            Sent date
                                          </Table.HeaderCell>
                                          <Table.HeaderCell width={1}>
                                            Actions
                                          </Table.HeaderCell>
                                        </Table.Row>
                                      </Table.Header>
                                      <Table.Body>
                                        {previousVersions.length > 0 ? (
                                          previousVersions.map(
                                            ({
                                              id,
                                              fileKey,
                                              dateUploaded,
                                              hasAlreadySentToCustomer,
                                              sentDate,
                                            }) => {
                                              return (
                                                <Table.Row key={id}>
                                                  <Table.Cell width={1}>
                                                    <Button
                                                      onClick={() =>
                                                        openDocument(
                                                          fileKey,
                                                          [
                                                            "passport",
                                                            "portrait",
                                                            "firstChildPortrait",
                                                            "secondChildPortrait",
                                                          ].includes(name)
                                                            ? "image/jpeg"
                                                            : [
                                                                "incompleteVisaApplicationForm",
                                                                "Schengen Visa incomplete application form",
                                                              ].some((string) =>
                                                                name.startsWith(
                                                                  string,
                                                                ),
                                                              )
                                                            ? "application/json"
                                                            : "application/pdf",
                                                        )
                                                      }
                                                    >
                                                      Document
                                                    </Button>
                                                  </Table.Cell>
                                                  <Table.Cell width={1}>
                                                    {getDateFormat(
                                                      dateUploaded,
                                                    )}
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                    {hasAlreadySentToCustomer
                                                      ? "YES"
                                                      : "NO"}
                                                  </Table.Cell>
                                                  <Table.Cell>
                                                    {hasAlreadySentToCustomer
                                                      ? getDateFormat(sentDate)
                                                      : "-"}
                                                  </Table.Cell>
                                                  <Table.Cell width={1}>
                                                    <Popup
                                                      content="Set as active version"
                                                      position="top center"
                                                      trigger={
                                                        <Button
                                                          color="yellow"
                                                          disabled={
                                                            isRequestLoading
                                                          }
                                                          type="button"
                                                          icon="star"
                                                          onClick={() =>
                                                            handleSetActiveBrochure(
                                                              documentId,
                                                              id,
                                                            )
                                                          }
                                                        />
                                                      }
                                                    />
                                                    <ConfirmPopup
                                                      content="Are you sure you want to delete document version?"
                                                      callback={() => {
                                                        handleDeleteDocument(
                                                          documentId,
                                                          id,
                                                        );
                                                      }}
                                                    >
                                                      <Button
                                                        color="red"
                                                        type="button"
                                                        disabled={
                                                          isRequestLoading
                                                        }
                                                        icon="trash"
                                                      />
                                                    </ConfirmPopup>
                                                  </Table.Cell>
                                                </Table.Row>
                                              );
                                            },
                                          )
                                        ) : (
                                          <Table.Row>
                                            <Table.Cell width={1}>
                                              No previous versions
                                            </Table.Cell>
                                          </Table.Row>
                                        )}
                                      </Table.Body>
                                    </Table>
                                  </Modal.Content>
                                </Modal>
                              )}

                              {[
                                DOCUMENT_TYPE.agent,
                                DOCUMENT_TYPE.financial,
                              ].includes(documentType) &&
                                isManagerOrAdmin(user.roles) && (
                                  <Modal
                                    className="new-brochure-modal"
                                    closeIcon
                                    trigger={
                                      <span>
                                        <Popup
                                          content="Rename document"
                                          position="top center"
                                          trigger={
                                            <Button
                                              color="green"
                                              type="button"
                                              disabled={isRequestLoading}
                                              icon="edit"
                                            />
                                          }
                                        />
                                      </span>
                                    }
                                  >
                                    <Modal.Header>Rename document</Modal.Header>
                                    <Modal.Content className="new-brochure-modal__content">
                                      <Input
                                        name="name"
                                        value={editingName}
                                        onChange={(event) =>
                                          setEditingName(event.target.value)
                                        }
                                        label="New document name"
                                        className="new-brochure-modal__content__name"
                                      />
                                    </Modal.Content>
                                    <Modal.Actions>
                                      <Button
                                        color="blue"
                                        onClick={() =>
                                          handleRenameDocument(documentId)
                                        }
                                        disabled={isRequestLoading}
                                      >
                                        Rename
                                      </Button>
                                    </Modal.Actions>
                                  </Modal>
                                )}

                              {[
                                DOCUMENT_TYPE.agent,
                                DOCUMENT_TYPE.financial,
                                DOCUMENT_TYPE.client,
                              ].includes(documentType) &&
                                user.roles.some((role) =>
                                  [
                                    ROLES.ADMIN,
                                    ROLES.BT_MANAGER,
                                    ROLES.L1_MANAGER,
                                    ROLES.L2_MANAGER,
                                    ROLES.QA_MANAGER,
                                  ].includes(role),
                                ) && (
                                  <ConfirmPopup
                                    content="Are you sure you want to delete this document and all previous versions?"
                                    callback={() =>
                                      handleDeleteDocumentAllVersions(
                                        id,
                                        documentId,
                                        name,
                                        files,
                                      )
                                    }
                                  >
                                    <Button
                                      color="red"
                                      type="button"
                                      disabled={isRequestLoading}
                                      icon="trash"
                                    />
                                  </ConfirmPopup>
                                )}
                            </Table.Cell>
                          </Table.Row>
                        </Fragment>
                      );
                    },
                  )}
              </Table.Body>
            </Table>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default DocumentView;
