import React, { useCallback, useContext, useMemo, useState } from "react";
import { Button, Input, Modal, Select } from "semantic-ui-react";
import toastr from "toastr";
import apis from "../../../../../../../../../../apis";
import { uploadApplicantFileApi } from "../../../../../../../../../../apis/applicantApi";
import { ApplicantDocumentsContext } from "../../../../../../../../../../common/ApplicantDocumentsContext";
import FileInput from "../../../../../../../../../../components/FileInput/FileInput.component";
import { API_NAMES } from "../../../../constants/apiNames";
import { DOCUMENT_TYPE } from "../ApplicantDocumentHandlerView/ApplicantDocumentHandlerView.component";
import { DOCUMENTS_OPTIONS, capitalizeEachWord } from "../../../../../../../../../../utils";
import { EVENT_TYPE, LOG_TYPE } from "../../../../../../../../../../constants/logs";
import { useSelector } from "react-redux";
import JSONPretty from "react-json-pretty";

const MRZ_RESPONSE_CASES = {
  MRZ_MATCH: "MRZ_MATCH",
  MRZ_MISMATCH: "MRZ_MISMATCH",
  MRZ_ERROR: "MRZ_ERROR",
  MRZ_UNREADABLE: "MRZ_UNREADABLE",
  MRZ_API_NOT_AVAILABLE: "MRZ_API_NOT_AVAILABLE",
};

const AddNewDocumentModal = (props) => {
  const {
    setData,
    index,
    applicant,
    documentType,
    chosenDocument,
    removeChosenDocument,
  } = props;

  const { api, fetchDocumentsClients, fetchDocumentsAgents } = useContext(ApplicantDocumentsContext);

  const [name, setName] = useState("");
  const [otherName, setOtherName] = useState("");
  const [file, setFile] = useState(null);
  const [fileBlob, setFileBlob] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mrzError, setMrzError] = useState(null);

  const isDocumentClient = documentType === DOCUMENT_TYPE.client;
  const user = useSelector((state) => state.currentUser);

  const error = useMemo(() => {
    if (["estaConfirmation", "vietnamConfirmationPage"].includes(name) && !applicant.applicantNumber)
      return "Confirmation page can not be uploaded if applicant doesn't have Application number populated!";
    return null;
  }, [applicant, name]);

  const getFileName = useMemo(() => {
    if (chosenDocument?.name) {
      if (!name) setName(chosenDocument?.name);
      if (!isDocumentClient) return chosenDocument.name;
    }
    const fileName = DOCUMENTS_OPTIONS[documentType][api].find((el) =>
      chosenDocument?.name
        ? el.value === chosenDocument.name
        : el.value === name,
    )?.text;
    switch (name) {
      case "other":
        return `${otherName} - ${capitalizeEachWord(applicant.lastName)}, ${capitalizeEachWord(
          applicant.firstName,
        )}${
          applicant.applicantNumber ? ` - ${applicant.applicantNumber}` : ""
        }`;
      case "invoice":
        return `Payment Receipt - ${applicant.orderId} - ${capitalizeEachWord(
          applicant.lastName,
        )} ${capitalizeEachWord(applicant.firstName)}`;
      default:
        return `${fileName} - ${capitalizeEachWord(applicant.lastName)}, ${capitalizeEachWord(
          applicant.firstName,
        )}${
          applicant.applicantNumber && !isDocumentClient
            ? ` - ${applicant.applicantNumber}`
            : ""
        }`;
    }
  }, [
    api,
    applicant,
    documentType,
    name,
    otherName,
    chosenDocument,
    isDocumentClient,
  ]);

  const handleOnFileUpload = useCallback(
    (event) => {
      if (
        (!name && !chosenDocument?.name) ||
        event.target.files[0]?.name.includes(getFileName)
      ) {
        setFile(event.target.files[0]);
  
        if (event.target.files[0].type === "application/json") {
          const reader = new FileReader();
          reader.onload = (e) => {
            // Set the JSON data URL to the fileBlob state
            setFileBlob(e.target.result);
          };
          reader.readAsText(event.target.files[0]);
        } else {
          const blob = new Blob([event.target.files[0]], {
            type: [
              "passport",
              "portrait",
              "firstChildPortrait",
              "secondChildPortrait",
            ].includes(name)
              ? "image/jpg"
              : "application/pdf",
          });
          setFileBlob(URL.createObjectURL(blob));
          URL.revokeObjectURL(blob);
        }
      } else {
        toastr.error("File name does not match!");
        event.target.value = null;
      }
    },
    [getFileName, name, chosenDocument],
  );

  const createEventLog = useCallback(
    async (name, location) => {
      try {
        await apis.logs.createEventLog({
          logType: LOG_TYPE.INSIDE_APPLICANT,
          eventType: EVENT_TYPE.DOCUMENT_UPLOAD,
          userId: user.id,
          applicantId: applicant.id,
          visaType: api,
          actions: [
            { propertyName: "fileName", oldValue: null, newValue: name },
            {
              propertyName: "documentType",
              oldValue: null,
              newValue: documentType,
            },
            { propertyName: "location", oldValue: null, newValue: location },
          ],
        });
      } catch (e) {
        console.error(e);
      }
    },
    [api, applicant, documentType, user],
  );

  const handleSubmit = useCallback(
    async (uploadWithoutMrz) => {
      if (!file.name.includes(getFileName)) {
        toastr.error("File name does not match!");
        return;
      }

      setIsLoading(true);

    try {
      let res;
      // Check if the uploaded file is a JSON file
      if (file.type === "application/json") {
        // Handle JSON file upload
        res = await apis.applicantDocumentHandler.addJsonFile(
          api,
          applicant.id,
          getFileName,
          documentType,
          file,
        );
            createEventLog(getFileName, res.data.activeVersion.fileKey);
            fetchDocumentsAgents()
          } else if (isDocumentClient) {
            const formData = new FormData();
        formData.append("fileName", name);
        formData.append(
          [
            "passport",
            "portrait",
            "firstChildPortrait",
            "secondChildPortrait",
          ].includes(name)
            ? "image"
            : "pdf",
          file,
        );
        if (uploadWithoutMrz === true)
          formData.append("uploadWithoutMrz", uploadWithoutMrz);

          res = await uploadApplicantFileApi(
            applicant.id,
            api,
            [
              "passport",
              "portrait",
              "firstChildPortrait",
              "secondChildPortrait",
            ].includes(name)
              ? "image"
              : "pdf",
            formData,
          );
          createEventLog(name, res.data.fileKey);
          fetchDocumentsClients();
        } else {
          res = await apis.applicantDocumentHandler.addDocument(
            api,
            applicant.id,
            getFileName,
            documentType,
            file,
          );
          createEventLog(getFileName, res.data.activeVersion.fileKey);

        setData((prev) =>
          prev.map((applicant, ind) =>
            ind === index
              ? {
                  ...applicant,
                  documents: applicant.documents.some(
                    (el) => (el.id = res.data.id),
                  )
                    ? applicant.documents.map((doc) => {
                        return doc.name === res.data.name ? res.data : doc;
                      })
                    : [...applicant.documents, res.data],
                }
              : applicant,
          ),
        );
      }
      setFile(null);
      setName("");
      setFileBlob("");
      removeChosenDocument();
      toastr.success("Successfully added new document.");
    } catch (error) {
      if (error.response.data.status) setMrzError(error.response.data);
      else {
        toastr.error("Failed to add new document.");
        if (error?.response?.data?.message) {
          toastr.error(error.response.data.message);
        }
        console.error(error);
      }
    } 
      setIsLoading(false);
  },
  [
    api,
    applicant,
    documentType,
    fetchDocumentsClients,
    fetchDocumentsAgents,
    file,
    getFileName,
    index,
    isDocumentClient,
    name,
    setData,
    removeChosenDocument,
    createEventLog,
  ],
);

  return (
    <Modal
      className="new-brochure-modal"
      closeIcon
      trigger={
        <Button id="add-new-document" type="button">
          Add new document
        </Button>
      }
      onClose={() => {
        setFile(null);
        setFileBlob("");
        setName("");
        setOtherName("");
        removeChosenDocument();
      }}
    >
      <Modal.Header>Add new document</Modal.Header>
      <Modal.Content className="new-brochure-modal__content">
        {!chosenDocument && (
          <Select
            name="name"
            onChange={(_, field) => {
              setName(field.value);
            }}
            label="Document name"
            className="new-brochure-modal__content__name"
            placeholder="Please select which document you will upload"
            search
            options={
              documentType && api && DOCUMENTS_OPTIONS[documentType][api]
            }
          />
        )}
        {name === "other" && (
          <Input
            name="otherName"
            value={otherName}
            onChange={(event) => setOtherName(event.target.value)}
            label="Other document name"
            className="new-brochure-modal__content__name"
          />
        )}
        {((name && name !== "other") || otherName || chosenDocument?.name) &&
          !error && (
            <span>Name of document should be exactly: "{getFileName}"</span>
          )}
        {!!error && <span style={{ color: "red" }}>{error}</span>}
        <div className="new-brochure-modal__content__file">
          <FileInput
            disabled={
              (api === API_NAMES.IND && isDocumentClient && !name) || !!error
            }
            onFileUpload={handleOnFileUpload}
            accept={
              [
                "passport",
                "portrait",
                "firstChildPortrait",
                "secondChildPortrait",
              ].includes(name)
                ? ".jpeg,.jpg,.png"
                : ["incompleteVisaApplicationForm","Schengen Visa incomplete application form"].some(string => name.startsWith(string))
                ? ".json"
                : ".pdf"
            }
            className="new-brochure-modal__content__file__input"
          />
          {fileBlob && (
            <a href={fileBlob} target="_blank" rel="noopener noreferrer">
              {`Preview document: ${file?.name}`}
            </a>
          )}
        </div>
        {fileBlob && file && (
          <div className="new-brochure-modal__content__preview" style={{ overflowX: 'auto' }}>
          {file.type === "application/json" ? (
            <JSONPretty id="json-pretty" data={JSON.parse(fileBlob)} style={{ maxWidth: '100%' }}></JSONPretty>
          ) : (
            <object
              data={fileBlob}
              type={
                [
                  "passport",
                  "portrait",
                  "firstChildPortrait",
                  "secondChildPortrait",
                ].includes(name)
                  ? "image/jpg"
                  : "application/pdf"
              }
              width="50%"
              height="100%"
            >
              Preview couldn't be loaded, please click on above link to preview
              uploaded file
            </object>
          )}
        </div>
      )}

        <Modal open={!!mrzError}>
          <Modal.Header>
            Error happened while scanning uploaded passport
          </Modal.Header>
          <Modal.Content>
            {mrzError?.status === MRZ_RESPONSE_CASES.MRZ_MISMATCH && (
              <div>
                <p>
                  There was a mismatch while reading data from uploaded
                  passport:
                </p>
                <p>
                  MRZ Scanned Data:
                  <br />
                  First name: {mrzError.values.mrzFirstName}
                  <br />
                  Last name: {mrzError.values.mrzLastName}
                  <br />
                  Passport number: {mrzError.values.mrzPassportNumber}
                  <br />
                </p>
                <p>
                  Applicant's data:
                  <br />
                  First name: {mrzError.values.firstName}
                  <br />
                  Last name: {mrzError.values.lastName}
                  <br />
                  Passport number: {mrzError.values.passportNumber}
                  <br />
                </p>
                <p>
                  If you think that this is okay you can proceed with uploading
                  this passport, otherwise please choose matching passport
                </p>
              </div>
            )}
            {[
              MRZ_RESPONSE_CASES.MRZ_API_NOT_AVAILABLE,
              MRZ_RESPONSE_CASES.MRZ_ERROR,
              MRZ_RESPONSE_CASES.MRZ_UNREADABLE,
            ].includes(mrzError?.status) && (
              <div>
                <p>
                  There is an unknown error with MRZ scanner, you can proceed if
                  you are 100% sure that this passport is matching applicant
                </p>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setMrzError(null)}>Cancel</Button>
            <Button
              color="blue"
              disabled={!file || (!name && !chosenDocument?.name) || isLoading}
              onClick={() => {
                handleSubmit(true);
              }}
              loading={isLoading}
            >
              Proceed
            </Button>
          </Modal.Actions>
        </Modal>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="blue"
          onClick={handleSubmit}
          disabled={
            !file || (!name && !chosenDocument?.name) || isLoading || !!error
          }
          loading={isLoading}
        >
          Upload
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddNewDocumentModal;
