import { FETCH_SCHEMA_DATA_SUCCESS } from "../actions/actionTypes";

const INITIAL_STATE = {};

const schemaDataReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case FETCH_SCHEMA_DATA_SUCCESS:
      return { ...state, [action.api]: action.payload };
    default:
      return state;
  }
};

export default schemaDataReducer;
