import React, { useMemo, useCallback, useContext } from "react";
import "./SpecificFilter.styles.scss";
import { Checkbox, Button } from "semantic-ui-react";
import { FILTERED_PRODUCT_NAMES } from "../../../../../Products/constants/productNames";
import { GROUPS, QA_REPORT_FOR } from "../../../../constants/qaReports";
import { ROLES } from "../../../../../../../../../constants/rolesConstants";
import SearchAndDisplay from "../SearchAndDisplay";
import { UsersContext } from "../../../../../../../../../common/UsersContext";
import className from "classnames";

const productFilters = Object.values(FILTERED_PRODUCT_NAMES).map(
  (productName) => {
    return {
      label: productName.toUpperCase(),
      name: "products",
      value: productName,
    };
  },
);

const groupFilter = Object.keys(GROUPS).map((group) => ({
  label: group,
  name: "groups",
  value: GROUPS[group],
}));

const SpecificFilter = ({
  type,
  agentQuestions,
  onChangeSpecificFilter,
  selectedProducts,
  selectedAllProducts,
  selectedAgentCategories,
  selectedAllAgentCategories,
  selectedGeneralCategories,
  selectedAllGeneralCategories,
  selectedAgents,
  selectedGroups,
  availabilityReport,
}) => {
  const requestOptions = useMemo(() => {
    switch (type) {
      case QA_REPORT_FOR.MANAGER:
        return {
          roles: [
            ROLES.L1_AGENT,
            ROLES.L1_MANAGER,
            ROLES.L2_AGENT,
            ROLES.L2_MANAGER,
            ROLES.BT_AGENT,
            ROLES.BT_MANAGER,
          ],
        };
      case QA_REPORT_FOR.QA_MANAGER:
        return {
          roles: [ROLES.QA_AGENT],
        };
      default:
        return {};
    }
  }, [type]);

  const { managerUsers } = useContext(UsersContext);

  const agentsToDisplay = useMemo(
    () => Object.values(selectedAgents).map(({ id, name }) => ({ id, name })),
    [selectedAgents],
  );

  const generalCategoryFilters = useMemo(
    () =>
      agentQuestions &&
      agentQuestions
        .filter((question) => question.type === "general" && question.active)
        .map((question) => {
          return {
            label: question.title,
            name: "general-categories",
            value: question._id,
          };
        }),
    [agentQuestions],
  );

  const agentCategoryFilters = useMemo(
    () =>
      agentQuestions &&
      agentQuestions
        .filter((question) => question.type === "agent" && question.active)
        .map((question) => {
          return {
            label: question.title,
            name: "agent-categories",
            value: question._id,
          };
        }),
    [agentQuestions],
  );

  const isFilterChecked = useCallback(
    (filterValue) => {
      switch (filterValue.name) {
        case "products":
          return (
            selectedProducts &&
            selectedProducts[filterValue.value] === filterValue.value
          );
        case "agent-categories":
          return (
            selectedAgentCategories &&
            selectedAgentCategories[filterValue.value] === filterValue.label
          );
        case "general-categories":
          return (
            selectedGeneralCategories &&
            selectedGeneralCategories[filterValue.value] === filterValue.label
          );
        case "groups":
          return selectedGroups && selectedGroups.includes(filterValue.value);
        default:
          return false;
      }
    },
    [
      selectedProducts,
      selectedAgentCategories,
      selectedGeneralCategories,
      selectedGroups,
    ],
  );

  const getFilters = () => {
    let filters = !availabilityReport
      ? [
          {
            title: "Product",
            selectAllButton: (
              <Button
                basic
                compact
                color="grey"
                size="mini"
                onClick={() =>
                  onChangeSpecificFilter(
                    productFilters,
                    `${
                      selectedAllProducts ? "deselect" : "select"
                    }-all-products`,
                  )
                }
              >
                {selectedAllProducts ? "Deselect All" : "Select All"}
              </Button>
            ),
            values: productFilters,
          },
        ]
      : [];

    switch (type) {
      case QA_REPORT_FOR.AGENT:
        filters = [
          ...filters,
          {
            title: "Category",
            selectAllButton: (
              <Button
                basic
                compact
                color="grey"
                size="mini"
                onClick={() =>
                  onChangeSpecificFilter(
                    agentCategoryFilters,
                    `${
                      selectedAllAgentCategories ? "deselect" : "select"
                    }-all-agent-categories`,
                  )
                }
              >
                {selectedAllAgentCategories ? "Deselect All" : "Select All"}
              </Button>
            ),
            values: agentCategoryFilters,
          },
        ];
        break;

      case QA_REPORT_FOR.MANAGER:
        filters = [
          {
            title: "Group",
            selectAllButton: null,
            values: groupFilter,
          },
          {
            title: "Agents",
            selectAllButton: (
              <Button
                basic
                compact
                color="grey"
                size="mini"
                onClick={() => onChangeSpecificFilter({}, "select-all-agents")}
              >
                Select All
              </Button>
            ),
            values: (
              <SearchAndDisplay
                items={managerUsers}
                requestOptions={requestOptions}
                selectedItems={selectedAgents}
                onSelectItem={onChangeSpecificFilter}
                itemsToDisplay={agentsToDisplay}
                itemName="Agents"
              />
            ),
          },

          ...filters,
        ];
        if (!availabilityReport) {
          filters = [
            ...filters,
            {
              title: "Agent",
              selectAllButton: (
                <Button
                  basic
                  compact
                  color="grey"
                  size="mini"
                  onClick={() =>
                    onChangeSpecificFilter(
                      agentCategoryFilters,
                      `${
                        selectedAllAgentCategories ? "deselect" : "select"
                      }-all-agent-categories`,
                    )
                  }
                >
                  {selectedAllAgentCategories ? "Deselect All" : "Select All"}
                </Button>
              ),
              values: agentCategoryFilters,
            },

            {
              title: "General",
              selectAllButton: (
                <Button
                  basic
                  compact
                  color="grey"
                  size="mini"
                  onClick={() =>
                    onChangeSpecificFilter(
                      generalCategoryFilters,
                      `${
                        selectedAllGeneralCategories ? "deselect" : "select"
                      }-all-general-categories`,
                    )
                  }
                >
                  {selectedAllGeneralCategories ? "Deselect All" : "Select All"}
                </Button>
              ),
              values: generalCategoryFilters,
            },
          ];
        }

        break;

      case QA_REPORT_FOR.QA_MANAGER:
        filters = [
          {
            title: "QA Agents",
            selectAllButton: (
              <Button
                basic
                compact
                color="grey"
                size="mini"
                onClick={() => onChangeSpecificFilter({}, "select-all-agents")}
              >
                Select All
              </Button>
            ),
            values: (
              <SearchAndDisplay
                items={managerUsers}
                requestOptions={requestOptions}
                selectedItems={selectedAgents}
                onSelectItem={onChangeSpecificFilter}
                itemsToDisplay={agentsToDisplay}
                itemName="Agents"
              />
            ),
          },
          ...filters,
        ];
        break;

      default:
        break;
    }
    return filters;
  };
  const filters = getFilters();

  return (
    <div className={className("specific-filters")}>
      {filters.map((filter, filterIndex) => (
        <div className="specific-filters__filter" key={filterIndex}>
          {filter.title === "Agent" && (
            <span className="specific-filters__filter__category-title">
              Category
            </span>
          )}
          <span className="specific-filters__filter__title">
            {filter.title}
            {filter.selectAllButton}
          </span>
          {filter.title === "Agents" || filter.title === "QA Agents"
            ? filter.values
            : filter.values.map((filterValue, index) => (
                <Checkbox
                  key={index}
                  className="specific-filters__filter__checkbox"
                  label={filterValue.label}
                  name={filterValue.name}
                  value={filterValue.value}
                  checked={isFilterChecked(filterValue)}
                  onChange={(e, { value }) =>
                    onChangeSpecificFilter(
                      value,
                      filterValue.name,
                      filterValue.label,
                    )
                  }
                />
              ))}
        </div>
      ))}
    </div>
  );
};

export default SpecificFilter;
