import React, { useMemo } from "react";

import { Button, Input, Select } from "semantic-ui-react";
import {
  K1_PAYMENT_STATUSES,
  K1_APPLICANT_STATUSES,
} from "../../constants/applicantStatuses";
import { PRODUCT_NAMES } from "../../../../../constants/productNames";
import { getOptionsFromObject } from "../../../../../../../../../../common/select-helper";

import { useCallbackRef } from "../../../../../../../../../../hooks/useCallbackRef.hook";
import {
  useFilters,
  notEmptyValidator,
} from "../../../../../../../../../../hooks/useFilters.hook";
import { getDateFormat } from "../../../../../../../../../../common/date-format";
import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../../../../constants/endpoints";
import ApplicantsTable from "../../../../../components/ApplicantsTable";

const K1ApplicantsTable = () => {
  const [applicantsTable, applicantsTableRef] = useCallbackRef();
  const { filters, normalizedFilters, setFormFilter } = useFilters(
    {
      searchString: "",
      paymentStatus: [K1_PAYMENT_STATUSES.Paid],
      status: [],
    },
    {
      localStorageKey: "k1-table",
      filterValidators: {
        searchString: notEmptyValidator(),
      },
      filterDebounces: {
        searchString: 700,
      },
      temporaryFilters: ["searchString"],
    },
  );

  const filtersRendered = useMemo(() => {
    if (!applicantsTable) {
      return null;
    }
    const { searchString, paymentStatus, status } = filters;
    return (
      <div className="filters-container">
        <Select
          placeholder="Select status"
          options={getOptionsFromObject(K1_APPLICANT_STATUSES, {
            optionTextFrom: "key",
            optionValueFrom: "value",
          })}
          name="status"
          multiple
          value={status || []}
          onChange={setFormFilter}
        />
        <Select
          placeholder="Select payment status"
          options={getOptionsFromObject(K1_PAYMENT_STATUSES, {
            optionTextFrom: "key",
            optionValueFrom: "value",
          })}
          multiple
          name="paymentStatus"
          value={paymentStatus || []}
          onChange={setFormFilter}
        />
        <Input
          value={searchString || ""}
          type="text"
          name="searchString"
          placeholder="Search"
          onChange={setFormFilter}
        />
        <Button onClick={applicantsTable.refreshItems}>
          {applicantsTable.isLoading ? "Loading.." : "Refresh"}
        </Button>
      </div>
    );
  }, [filters, setFormFilter, applicantsTable]);

  const tableColumns = useMemo(
    () => ({
      name: {
        label: "Name",
      },
      userEmail: {
        label: "Email",
      },
      _id: {
        label: "ID",
        value: (applicant) => (
          <a
            href={`k1_visa/applicant/${applicant._id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {applicant._id}
          </a>
        ),
      },
      createdAt: {
        label: "Created at",
        value: (applicant) => getDateFormat(applicant.createdAt),
      },
      paymentStatus: {
        label: "Payment status",
      },
      status: {
        label: "Status",
      },
      zendeskId: {
        label: "Zendesk ID",
        value: (applicant) => (
          <a
            href={TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(applicant.zendeskId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {applicant.zendeskId}
          </a>
        ),
      },
    }),
    [],
  );

  return (
    <ApplicantsTable
      api={PRODUCT_NAMES.K1}
      normalizedFilters={normalizedFilters}
      filtersRendered={filtersRendered}
      ref={applicantsTableRef}
      tableColumns={tableColumns}
    />
  );
};

export default K1ApplicantsTable;
