import React from "react";
import "./Stopwatch.scss";

class Stopwatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      secondsElapsed: 0
      // minutesElapsed: 0, hoursElapsed: 0
    };
  }

  getSeconds() {
    return ("0" + (this.state.secondsElapsed % 60)).slice(-2);
  }

  getMinutes() {
    let t = Math.floor(this.state.secondsElapsed / 60);
    return ("0" + (t % 60)).slice(-2);
  }

  getHours() {
    return Math.floor(this.state.secondsElapsed / 3600);
  }

  componentDidMount() {
    this.timer = setInterval(
      () => this.setState({ secondsElapsed: this.state.secondsElapsed + 1 }),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <div className="sk-timmer-container">
        <span className="sk-timmer-label">
          {this.getHours()}:{this.getMinutes()}:{this.getSeconds()}
        </span>
      </div>
    );
  }
}

export default Stopwatch;
