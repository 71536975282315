/**
 * Get color for some shift name
 *
 * @param {string} shift - shift name
 * @returns - color for that shift
 */
export const makeColorForShift = (shift) => {
  switch (shift) {
    case "S1":
      return "#6cb2cc";
    case "S2":
      return "#4b86f2";
    case "S3":
      return "#347892";
    case "S4":
      return "#474adf";
    case "S5":
      return "#0d4f68";
    case "S6":
      return "#202bbe";
    case "empty":
      return "var(--bg-empty-shift)";
    default:
      return "rgb(0, 0, 0, 0)";
  }
};
