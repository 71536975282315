import React from "react";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import { Modal, Button, Loader, Dimmer } from "semantic-ui-react";
import { getDateFormat } from "../../../../../../../common/date-format";
import { getApplicantLogsApi } from "../../../../../../../apis/applicantApi";

class StatusHistory extends React.Component {

  constructor(props) {
    super(props);
    this.state={logs:undefined,isLoading:true}
    
    this.getApplicantLog=this.getApplicantLog.bind(this)
  }
   getApplicantLog=async ()=> {
    this.setState({isLoading:true})
    const  logsResponse  = await getApplicantLogsApi(this.props.id, this.props.api);
    this.setState({logs:logsResponse?.data})
    this.setState({isLoading:false})

  }
  render() {
    return (
      <ConfirmPopup
        content={
          <React.Fragment>
            <Modal.Header>Status list</Modal.Header>
            <Modal.Content scrolling>
              <Modal.Description>
                {!this.state.isLoading? this.state.logs.map((log, index) => (
                  <React.Fragment key={index}>
                    <p>
                      <span> {log.text}</span>
                      <span style={{ float: "right" }}>
                        {getDateFormat(log.createdAt)}
                      </span>
                    </p>
                    <hr />
                  </React.Fragment>
                )):(
                  <Dimmer active={this.state.isLoading} >
                  <Loader size="large" />
                </Dimmer>
                )}
              </Modal.Description>
            </Modal.Content>
          </React.Fragment>
        }
        onOpen={this.getApplicantLog}
        cancelButton={null}
        callback={async () => {}}
      >
       <Button >Status history</Button>
     </ConfirmPopup>
    );
  }
}

export default StatusHistory;
