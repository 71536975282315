import React from "react";
import toastr from "toastr";

import {
  Item,
  List,
  Checkbox,
  Input,
  Button,
  Loader,
  Message,
  TextArea,
  Select,
} from "semantic-ui-react";
import "./EmailAlertConfiguration.scss";
import { connect } from "react-redux";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import { EMAIL_REGEX } from "../../../../../../../common/regex-templates";
import apis from "../../../../../../../apis";
import utils from "../../../../../../../utils";
import { PRODUCT_NAMES_MAPPING } from "../../../../routers/Products/constants/productNames";

const PRODUCT_PROPS = {
  "Product name": "{{product}}",
  "Hours since last sale": "{{hoursSinceLastSale}}",
  "?h": "{{hours}}",
  "Sales in last ?h": "{{numberOfSalesInPastCustomHours}}",
  "Time of last sale": "{{timeOfLastSale}}",
};

class EmailAlertConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      initialConfigs: [],
      emailConfs: [],
      emailValues: [],
      periods:[1,3,6,12,24]
    };
  }

  async componentDidMount() {
    try {
      const {
        data,
      } = await apis.configuration.emailAlertConfig.getEmailAlerts();
      const emailConfs = data.map((conf) => ({
        type: conf.type,
        hoursBetweenSales: conf.hoursBetweenSales,
        hoursPeriodForMinimumSales:conf.hoursPeriodForMinimumSales,
        minimumSalesNum: conf.minimumSalesNum,
        emails: conf.emails,
        sendAfter: conf.sendAfter,
        enabled: conf.enabled,
        email: conf.email ? conf.email : { subject: "", body: "" },
      }));
      const initialConfigs = JSON.parse(JSON.stringify(emailConfs));
      this.setState({
        emailConfs,
        initialConfigs,
        fetching: false,
        emailValues: emailConfs.map((conf) => ({ value: "", error: null })),
      });
    } catch (err) {
      console.error(err);
    }
  }

  updateConfiguration = async (data) => {
    try {
      await apis.configuration.emailAlertConfig.updateEmailAlerts(data);
      const initialConfigs = JSON.parse(JSON.stringify(data));
      this.setState({ initialConfigs });
    } catch (err) {
      console.error(err);
      toastr.error("Email alert failed to update!", "Failed");
    }
  };

  updateConfigurationField = (index, property, value, callback = () => {}) => {
    const { emailConfs } = this.state;
    this.setState(
      {
        emailConfs: emailConfs.map((conf, confIndex) =>
          index === confIndex ? { ...conf, [property]: value } : conf,
        ),
      },
      callback,
    );
  };

  updateConfigurationsEmailProps(prop, value) {
    const { emailConfs } = this.state;
    this.setState({
      emailConfs: emailConfs.map((conf) => ({
        ...conf,
        email: { ...conf.email, [prop]: value },
      })),
    });
  }

  addEmailToConfiguration = (indexConfig) => {
    const config = this.state.emailConfs[indexConfig];
    const { value, error } = this.state.emailValues[indexConfig];
    if (!value || error) {
      return;
    }

    if (config.emails.some((email) => email === value)) {
      const { emailValues } = this.state;
      this.setState({
        emailValues: emailValues.map((emailValue, index) =>
          index === indexConfig
            ? {
                value,
                error: "Email already exist in List",
              }
            : emailValue,
        ),
      });
      return;
    }

    this.updateConfigurationField(indexConfig, "emails", [
      ...config.emails,
      value,
    ]);

    this.setState({
      emailValues: this.state.emailValues.map((emailValue, index) =>
        index === indexConfig
          ? {
              value: "",
              error: null,
            }
          : emailValue,
      ),
    });
  };

  removeEmailAddress = (indexConfig, indexEmail) => {
    const updatedEmails = this.state.emailConfs[indexConfig].emails.filter(
      (email, index) => index !== indexEmail,
    );
    this.updateConfigurationField(indexConfig, "emails", updatedEmails);
  };

  setInputValue = (configurationIndex, value) => {
    const { emailValues } = this.state;
    this.setState({
      emailValues: emailValues.map((emailValue, index) =>
        configurationIndex === index
          ? {
              value,
              error: EMAIL_REGEX.test(value)
                ? null
                : "Email address is not valid",
            }
          : emailValue,
      ),
    });
  };

  onSubmit = () => {
    this.state.emailConfs.forEach((config, index) => {
      const initialConfig = JSON.stringify(this.state.initialConfigs[index]);
      if (JSON.stringify(config) !== initialConfig) {
        this.updateConfiguration(config);
      }
    });
    toastr.success("Email alerts have been updated!", "Success");
  };

  onDiscardHandler() {
    this.setState({
      emailConfs: JSON.parse(JSON.stringify(this.state.initialConfigs)),
      initialValues: [],
    });
  }

  renderEmailList = (emails, key) => {
    return emails.map((email, index) => {
      return (
        <List.Item
          key={index}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            icon="minus"
            onClick={() => this.removeEmailAddress(key, index)}
          />
          {email}
        </List.Item>
      );
    });
  };

  renderItemContent = (configurationIndex) => {
    const { emailValues, emailConfs } = this.state;
    const emailConf = emailConfs[configurationIndex];
    return (
      <List horizontal key={configurationIndex}>
        <List.Item>
          <Item.Meta>Hours Between Sales</Item.Meta>
          <Item.Description>
            <Input
              name="hoursBetweenSales"
              type="number"
              placeholder="hours"
              value={emailConf.hoursBetweenSales}
              onChange={(event, { value, name }) => {
                if (value >= 0) {
                  this.updateConfigurationField(configurationIndex, name, value);
                }
              }}
            />
          </Item.Description>
        </List.Item>
        <List.Item>
          <Item.Meta>Minimum Sales</Item.Meta>
          <Item.Description>
            <Input
              placeholder="minimum sales"
              name="minimumSalesNum"
              type="number"
              value={emailConf.minimumSalesNum}
              onChange={(event, { value, name }) => {
                if (value >= 0) {
                  this.updateConfigurationField(configurationIndex, name, value);
                }
              }}
            />
          </Item.Description>
        </List.Item>
        <List.Item>
          <Item.Meta>Period</Item.Meta>
          <Item.Description>
            <Select
             defaultValue={emailConf.hoursPeriodForMinimumSales}
             name="hoursPeriodForMinimumSales"
             options={this.state.periods.map((period) => ({
              text: `${period}h`,
              value: period, 
            }))}
           onChange={(_,{value,name})=>this.updateConfigurationField(configurationIndex,name,value)
          }
            /> 
          </Item.Description>
        </List.Item>
        <List.Item>
          <Item.Meta>Emails to Notify</Item.Meta>
          <Item.Description>
            <Button
              icon="plus"
              onClick={() => this.addEmailToConfiguration(configurationIndex)}
            />
            <Input
              ref={(input) => (this.inputtext = input)}
              placeholder="Enter Email"
              type="email"
              onChange={(event, { value }) =>
                this.setInputValue(configurationIndex, value)
              }
              value={emailValues[configurationIndex].value}
            />
            {emailValues[configurationIndex].error && (
              <Message color="red">
                {emailValues[configurationIndex].error}
              </Message>
            )}
            <List
              style={{
                maxHeight: "290px",
                overflow: "auto",
              }}
            >
              {this.renderEmailList(emailConf.emails, configurationIndex)}
            </List>
          </Item.Description>
        </List.Item>
        <List.Item>
          <Checkbox
            label="enabled"
            checked={emailConf.enabled}
            onChange={(event, { checked }) =>
              this.updateConfigurationField(
                configurationIndex,
                "enabled",
                checked,
              )
            }
          />
        </List.Item>
      </List>
    );
  };

  renderItems = () => {
    return this.state.emailConfs.map((conf, index) => {
      return (
        <Item key={index}>
          <Item.Content className="primary-card-container">
            <Item.Header>{PRODUCT_NAMES_MAPPING[conf.type.toLowerCase()] || conf.type.toUpperCase()}</Item.Header>
            {this.renderItemContent(index)}
          </Item.Content>
        </Item>
      );
    });
  };

  render() {
    const { emailConfs, fetching } = this.state;

    if (fetching) {
      return <Loader active inline="centered" />;
    }

    if (!emailConfs.length) {
      return <p>No email configurations found</p>;
    }

    const {
      email: { subject, body },
    } = emailConfs[0];
    return (
      <Item.Group divided className="alarms-tab">
        {this.renderItems()}
        <div className="email-container">
          <div className="email-container-email">
            <Input
              ref={(input) => (this.inputtext = input)}
              placeholder="Enter subject"
              onChange={(event, { value }) =>
                this.updateConfigurationsEmailProps("subject", value)
              }
              value={subject}
            />
            <TextArea
              placeholder="Write email text here"
              value={body}
              className="ticket-input"
              onChange={(event, { value }) =>
                this.updateConfigurationsEmailProps("body", value)
              }
            />
          </div>
          <div className="email-container-legenda">
            {Object.entries(PRODUCT_PROPS).map(([label, name]) => (
              <div
                key={name}
                onClick={() =>
                  utils.copyToClipboard(name, `Copied ${name} to clipboard`)
                }
              >
                <strong>{label}:&nbsp;</strong>
                <span id={name}>{name}</span>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "12px",
          }}
        >
          <ConfirmPopup
            content="Are you sure you want discard changes?"
            callback={() => this.onDiscardHandler()}
          >
            <Button className="discard-button">Discard</Button>
          </ConfirmPopup>
          <ConfirmPopup
            content="Are you sure you want save changes?"
            callback={() => this.onSubmit()}
          >
            <Button className="save-button">Save</Button>
          </ConfirmPopup>
        </div>
      </Item.Group>
    );
  }
}

const mapStateToProps = (state) => ({
  bots: state.bots,
});

export default connect(mapStateToProps)(EmailAlertConfiguration);
