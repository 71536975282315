import axios from "../httpClient";

function getEmailAlerts() {
  return axios.get(`/alert/email-alert`);
}
function updateEmailAlerts(data) {
  return axios.patch(`/alert/email-alert`, data);
}

export default {
  getEmailAlerts,
  updateEmailAlerts,
};
