import React from "react";

import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../constants/endpoints";
import { getDateFormat } from "../../../../../../../common/date-format";
import { API_NAMES } from "../../EVisa/constants/apiNames";
import { capitalizeEachWord } from "../../../../../../../utils";

export const columns = {
	fullName: {
		label: "Name",
		value: (applicant) =>
		capitalizeEachWord(applicant.fullName),
	},
	userEmail: {
		label: "Email",
	},

	_id: {
		label: 'ID',
		value: (applicant, zendeskView) => {
			return (
				<a
				href={`${API_NAMES.GE}/applicant/${applicant._id}`}
				target={zendeskView ? "" : "_blank"}
				rel="noopener noreferrer"
			  >
				{applicant._id}
			  </a>
			)}
		},
	applicationType: {
		label: "Type",
	},
	createdAt: {
		label: "Created at",
		value: (applicant) => getDateFormat(applicant.createdAt),
	},
	paymentStatus: {
		label: "Payment status",
	},
	status:{
		label: "Status",
	},
	zendeskId: {
		label: "Zendesk ID",
		value: (applicant) => (
			<a
				href={TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(applicant.zendeskId)}
				target="_blank"
				rel="noopener noreferrer"
			>
				{applicant.zendeskId}
      </a>
		)}
	}
