import React, { useEffect, useState, useMemo, useCallback } from "react";
import toastr from "toastr";
import {
  getCurrentAgentReport,
  getCurrentQAAgentReport,
  getQATeamReport,
  getGeneralQAReport,
} from "../../../../../../../apis/reports/qaReportsApi";
import "./QAReportPage.styles.scss";
import {
  OverallQAReport,
  CategoryOrProductQAReport,
  CalendarFilter,
  SpecificFilter,
  CategoryAndProductQAReport,
  DisputeQAReport,
  GeneralQAReport,
} from "./components";
import { Accordion, Menu, Button, Select, Checkbox } from "semantic-ui-react";
import { getQAQuestionsApi } from "../../../../../../../apis/qaQuestionsApi";
import { QA_REPORTS, QA_REPORT_FOR } from "../../constants/qaReports";
import classNames from "classnames";
import Spinner from "../../../../../../../components/Spinner";
import {
  useSpecificFilter,
  useAccordionIndexes,
  useCalendarFilter,
  useFilters,
} from "../../../../../../../hooks";
import className from "classnames";

// value contains information about the chart seperated by spaces "text text text" (e.g. "bar true true")
// first text is chart type (e.g. bar chart)
// second is if chart is other version of same chart type (e.g. horizontal bar chart)
// third is if chart is stacked
const CHART_TYPES = [
  { text: "Pie Chart", value: "pie false false" },
  { text: "Column Chart", value: "bar false false" },
  { text: "Stacked Column Chart", value: "bar false true" },
  { text: "Bar Chart", value: "bar true false" },
  { text: "Stacked Bar Chart", value: "bar true true" },
];

const SEPARATE_DATE_OPTIONS = [
  { key: "day", value: "Day", text: "Day" },
  { key: "month", value: "Month", text: "Month" },
  { key: "year", value: "Year", text: "Year" },
];

const QAReportPage = ({ type }) => {
  const [agentReport, setAgentReport] = useState({});
  const [agentQuestions, setAgentQuestions] = useState([]);
  const [selectedDateSeparator, setSelectedDateSeparator] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isQAAgent = useMemo(() => type === QA_REPORT_FOR.QA_AGENT, [type]);
  const isQAManager = useMemo(() => type === QA_REPORT_FOR.QA_MANAGER, [type]);
  const isManager = useMemo(() => type === QA_REPORT_FOR.MANAGER, [type]);

  const {
    selectedDateSingleFilter,
    selectedDate,
    onChangeDate,
  } = useCalendarFilter();

  const {
    activeAccordionIndexes,
    handleAccordionClick,
  } = useAccordionIndexes();

  const {
    filters: { selectedProducts, selectedAllProducts },
    setFilters: setSelectedProducts,
  } = useFilters(
    {
      selectedProducts: {},
      selectedAllProducts: false,
    },
    {
      localStorageKey: "qa-report-product-filters",
    },
  );

  const {
    filters: { selectedAgentCategories, selectedAllAgentCategories },
    setFilters: setSelectedAgentCategories,
  } = useFilters(
    {
      selectedAgentCategories: {},
      selectedAllAgentCategories: false,
    },
    {
      localStorageKey: "qa-report-agent-category-filters",
    },
  );

  const {
    filters: { selectedGeneralCategories, selectedAllGeneralCategories },
    setFilters: setSelectedGeneralCategories,
  } = useFilters(
    {
      selectedGeneralCategories: {},
      selectedAllGeneralCategories: false,
    },
    {
      localStorageKey: "qa-report-general-category-filters",
    },
  );

  const {
    filters: { selectedAgents, selectedAllAgents },
    setFilters: setSelectedAgents,
  } = useFilters(
    {
      selectedAgents: {},
      selectedAllAgents: false,
    },
    {
      localStorageKey: "qa-report-agent-filters",
    },
  );

  const {
    filters: { selectedGroups },
    setFilters: setSelectedGroups,
  } = useFilters(
    {
      selectedGroups: [],
    },
    {
      localStorageKey: "qa-report-group-filters",
    },
  );

  const { filters: showReports, setFilter: setShowReports } = useFilters(
    {
      [QA_REPORTS.PRODUCT_REPORT]: false,
      [QA_REPORTS.CATEGORY_REPORT]: false,
      [QA_REPORTS.PRODUCT_CATEGORY_REPORT]: false,
      [QA_REPORTS.DISPUTES_REPORT]: false,
      [QA_REPORTS.AFTER_DISPUTES_REPORT]: false,
    },
    {
      localStorageKey: "show-qa-reports",
    },
  );

  const { filters: chartConfig, setFilters: setChartType } = useFilters(
    { chartType: "pie", chartOtherVersion: false, chartStacked: false },
    {
      localStorageKey: "charts-type-Configuration",
    },
  );

  const { onChangeSpecificFilter } = useSpecificFilter({
    selectedAgents,
    selectedProducts,
    selectedAgentCategories,
    selectedGeneralCategories,
    selectedGroups,
  });

  const onChangeDateSeparator = useCallback((e, { value }) => {
    setSelectedDateSeparator(value);
  }, []);

  const selectedChart = useMemo(
    () =>
      `${chartConfig.chartType} ${chartConfig.chartOtherVersion} ${chartConfig.chartStacked}`,
    [chartConfig],
  );

  // ticket checkbox filter
  const {
    filters: { checked: ticketCheckboxChecked },
    setFilters: setTicketCheckboxChecked,
  } = useFilters(
    { checked: false },
    {
      localStorageKey: "qa-report-ticket-checkbox",
    },
  );

  const ticketCheckboxLabel = useMemo(() => {
    let label = "";

    if (type === QA_REPORT_FOR.QA_MANAGER)
      label = "Include not assigned tickets";
    else if (type === QA_REPORT_FOR.MANAGER)
      label = "Include tickets with no agents";

    return label;
  }, [type]);

  const getChartsBelowOverallReport = () => {
    let reportsBelowOverallReport = [];
    switch (type) {
      case QA_REPORT_FOR.AGENT:
        reportsBelowOverallReport = [
          {
            title: "Support QA Success Ratio Per Product",
            buttonShowOrHide: (
              <Button
                basic
                color="grey"
                onClick={() =>
                  setShowReports(
                    QA_REPORTS.PRODUCT_REPORT,
                    !showReports[QA_REPORTS.PRODUCT_REPORT],
                  )
                }
              >
                {!showReports[QA_REPORTS.PRODUCT_REPORT] ? "Show" : "Hide"}
              </Button>
            ),
            reportComponent: showReports[QA_REPORTS.PRODUCT_REPORT] && (
              <CategoryOrProductQAReport
                selectedDateReport={agentReport.perProductAndCategory}
                mainFilter={selectedProducts}
                type={QA_REPORTS.PRODUCT_REPORT}
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "Support QA Success Ratio Per Category",
            buttonShowOrHide: (
              <Button
                basic
                color="grey"
                onClick={() =>
                  setShowReports(
                    QA_REPORTS.CATEGORY_REPORT,
                    !showReports[QA_REPORTS.CATEGORY_REPORT],
                  )
                }
              >
                {!showReports[QA_REPORTS.CATEGORY_REPORT] ? "Show" : "Hide"}
              </Button>
            ),
            reportComponent: showReports[QA_REPORTS.CATEGORY_REPORT] && (
              <CategoryOrProductQAReport
                selectedDateReport={agentReport.perProductAndCategory}
                mainFilter={selectedAgentCategories}
                helperFilter={selectedProducts}
                type={QA_REPORTS.CATEGORY_REPORT}
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "Support QA Success Ratio Per Category & Per Product",
            buttonShowOrHide: (
              <Button
                basic
                color="grey"
                onClick={() =>
                  setShowReports(
                    QA_REPORTS.PRODUCT_CATEGORY_REPORT,
                    !showReports[QA_REPORTS.PRODUCT_CATEGORY_REPORT],
                  )
                }
              >
                {!showReports[QA_REPORTS.PRODUCT_CATEGORY_REPORT]
                  ? "Show"
                  : "Hide"}
              </Button>
            ),
            reportComponent: showReports[
              QA_REPORTS.PRODUCT_CATEGORY_REPORT
            ] && (
              <CategoryAndProductQAReport
                selectedDateReport={agentReport.perProductAndCategory}
                productFilter={selectedProducts}
                categoryFilter={selectedAgentCategories}
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "Support Dispute Ratio",
            buttonShowOrHide: (
              <Button
                basic
                color="grey"
                onClick={() =>
                  setShowReports(
                    QA_REPORTS.DISPUTES_REPORT,
                    !showReports[QA_REPORTS.DISPUTES_REPORT],
                  )
                }
              >
                {!showReports[QA_REPORTS.DISPUTES_REPORT] ? "Show" : "Hide"}
              </Button>
            ),
            reportComponent: showReports[QA_REPORTS.DISPUTES_REPORT] && (
              <DisputeQAReport
                total={agentReport.totalSubmitted}
                totalSeparated={agentReport.totalSubmittedSeparated}
                totalDisputed={agentReport.totalDisputed}
                totalDisputedSeparated={agentReport.totalDisputedSeparated}
                disputedCountByStatus={agentReport.disputedCountByStatus}
                disputedCountByStatusSeparated={
                  agentReport.disputedCountByStatusSeparated
                }
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "Support Review Results After Dispute",
            buttonShowOrHide: (
              <Button
                basic
                color="grey"
                onClick={() =>
                  setShowReports(
                    QA_REPORTS.AFTER_DISPUTES_REPORT,
                    !showReports[QA_REPORTS.AFTER_DISPUTES_REPORT],
                  )
                }
              >
                {!showReports[QA_REPORTS.AFTER_DISPUTES_REPORT]
                  ? "Show"
                  : "Hide"}
              </Button>
            ),
            reportComponent: showReports[QA_REPORTS.AFTER_DISPUTES_REPORT] && (
              <DisputeQAReport
                total={agentReport.totalSubmitted}
                totalSeparated={agentReport.totalSubmittedSeparated}
                totalDisputed={agentReport.totalDisputed}
                totalDisputedSeparated={agentReport.totalDisputedSeparated}
                disputedCountByStatus={agentReport.disputedCountByStatus}
                disputedCountByStatusSeparated={
                  agentReport.disputedCountByStatusSeparated
                }
                afterDisputeReport={true}
                chartConfig={chartConfig}
              />
            ),
          },
        ];
        break;

      case QA_REPORT_FOR.MANAGER:
        reportsBelowOverallReport = [
          {
            title: "Reviews Per Product",
            buttonShowOrHide: null,
            reportComponent: (
              <GeneralQAReport
                multipleValues={agentReport.totalPerProduct}
                multipleValuesSeparated={agentReport.totalPerProductSeparated}
                type={QA_REPORTS.GENERAL_PRODUCT_REPORT}
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "General Ticket QA Success Ratio",
            buttonShowOrHide: null,
            reportComponent: (
              <GeneralQAReport
                positiveData={agentReport.generalPositive}
                negativeData={agentReport.generalNegative}
                positiveDataSeparated={agentReport.generalPositiveSeparated}
                negativeDataSeparated={agentReport.generalNegativeSeparated}
                type={QA_REPORTS.GENERAL_TICKET_REPORT}
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "Support QA Success Ratio",
            buttonShowOrHide: null,
            reportComponent: (
              <GeneralQAReport
                positiveData={agentReport.totalPositiveAssessments}
                negativeData={agentReport.totalNegativeAssessments}
                positiveDataSeparated={
                  agentReport.totalPositiveAssessmentsSeparated
                }
                negativeDataSeparated={
                  agentReport.totalNegativeAssessmentsSeparated
                }
                type={QA_REPORTS.SUCCESS_RATIO_REPORT}
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "Support QA Success Ratio Per Product",
            buttonShowOrHide: (
              <Button
                basic
                color="grey"
                onClick={() =>
                  setShowReports(
                    QA_REPORTS.PRODUCT_REPORT,
                    !showReports[QA_REPORTS.PRODUCT_REPORT],
                  )
                }
              >
                {!showReports[QA_REPORTS.PRODUCT_REPORT] ? "Show" : "Hide"}
              </Button>
            ),
            reportComponent: showReports[QA_REPORTS.PRODUCT_REPORT] && (
              <CategoryOrProductQAReport
                selectedDateReport={
                  agentReport.evaluationsPerCategoryProductAgentQuestionsSeparated
                }
                mainFilter={selectedProducts}
                type={QA_REPORTS.PRODUCT_REPORT}
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "Support QA Success Ratio Per Category",
            buttonShowOrHide: (
              <Button
                basic
                color="grey"
                onClick={() =>
                  setShowReports(
                    QA_REPORTS.CATEGORY_REPORT,
                    !showReports[QA_REPORTS.CATEGORY_REPORT],
                  )
                }
              >
                {!showReports[QA_REPORTS.CATEGORY_REPORT] ? "Show" : "Hide"}
              </Button>
            ),
            reportComponent: showReports[QA_REPORTS.CATEGORY_REPORT] && (
              <CategoryOrProductQAReport
                selectedDateReport={
                  agentReport.evaluationsPerCategoryProductAgentQuestionsSeparated
                }
                mainFilter={selectedAgentCategories}
                helperFilter={selectedProducts}
                type={QA_REPORTS.CATEGORY_REPORT}
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "Support QA Success Ratio Per Category & Per Product",
            buttonShowOrHide: (
              <Button
                basic
                color="grey"
                onClick={() =>
                  setShowReports(
                    QA_REPORTS.PRODUCT_CATEGORY_REPORT,
                    !showReports[QA_REPORTS.PRODUCT_CATEGORY_REPORT],
                  )
                }
              >
                {!showReports[QA_REPORTS.PRODUCT_CATEGORY_REPORT]
                  ? "Show"
                  : "Hide"}
              </Button>
            ),
            reportComponent: showReports[
              QA_REPORTS.PRODUCT_CATEGORY_REPORT
            ] && (
              <CategoryAndProductQAReport
                selectedDateReport={
                  agentReport.evaluationsPerCategoryProductAgentQuestionsSeparated
                }
                productFilter={selectedProducts}
                categoryFilter={selectedAgentCategories}
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "QA Dispute Ratio",
            buttonShowOrHide: null,
            reportComponent: (
              <DisputeQAReport
                total={agentReport.totalSubmittedEvaluations}
                totalSeparated={agentReport.totalSubmittedEvaluationsSeparated}
                totalDisputed={agentReport.totalDisputed}
                totalDisputedSeparated={agentReport.totalDisputedSeparated}
                disputedCountByStatus={agentReport.disputedCountByStatus}
                disputedCountByStatusSeparated={
                  agentReport.disputedCountByStatusSeparated
                }
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "QA Review Results After Dispute",
            buttonShowOrHide: null,
            reportComponent: (
              <DisputeQAReport
                total={agentReport.totalSubmittedEvaluations}
                totalSeparated={agentReport.totalSubmittedEvaluationsSeparated}
                totalDisputed={agentReport.totalDisputed}
                totalDisputedSeparated={agentReport.totalDisputedSeparated}
                disputedCountByStatus={agentReport.disputedCountByStatus}
                disputedCountByStatusSeparated={
                  agentReport.disputedCountByStatusSeparated
                }
                afterDisputeReport={true}
                chartConfig={chartConfig}
              />
            ),
          },
        ];
        break;

      case QA_REPORT_FOR.QA_AGENT:
        reportsBelowOverallReport = [
          {
            title: "General Ticket QA Success Ratio",
            buttonShowOrHide: null,
            reportComponent: (
              <GeneralQAReport
                positiveData={agentReport.generalPositive}
                negativeData={agentReport.generalNegative}
                positiveDataSeparated={agentReport.generalPositiveSeparated}
                negativeDataSeparated={agentReport.generalNegativeSeparated}
                type={QA_REPORTS.GENERAL_TICKET_REPORT}
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "Support QA Success Ratio",
            buttonShowOrHide: null,
            reportComponent: (
              <GeneralQAReport
                positiveData={agentReport.agentPositiveReviews}
                negativeData={agentReport.agentNegativeReviews}
                positiveDataSeparated={
                  agentReport.agentPositiveReviewsSeparated
                }
                negativeDataSeparated={
                  agentReport.agentNegativeReviewsSeparated
                }
                type={QA_REPORTS.SUCCESS_RATIO_REPORT}
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "Support Dispute Ratio",
            buttonShowOrHide: null,
            reportComponent: (
              <DisputeQAReport
                total={agentReport.submittedEvaluationsCount}
                totalSeparated={agentReport.submittedEvaluationsCountSeparated}
                totalDisputed={agentReport.totalDisputed}
                totalDisputedSeparated={agentReport.totalDisputedSeparated}
                disputedCountByStatus={agentReport.disputedCountByStatus}
                disputedCountByStatusSeparated={
                  agentReport.disputedCountByStatusSeparated
                }
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "Support Review Results After Dispute",
            buttonShowOrHide: null,
            reportComponent: (
              <DisputeQAReport
                total={agentReport.submittedEvaluationsCount}
                totalSeparated={agentReport.submittedEvaluationsCountSeparated}
                totalDisputed={agentReport.totalDisputed}
                totalDisputedSeparated={agentReport.totalDisputedSeparated}
                disputedCountByStatus={agentReport.disputedCountByStatus}
                disputedCountByStatusSeparated={
                  agentReport.disputedCountByStatusSeparated
                }
                afterDisputeReport={true}
                chartConfig={chartConfig}
              />
            ),
          },
        ];
        break;

      case QA_REPORT_FOR.QA_MANAGER:
        reportsBelowOverallReport = [
          {
            title: "General Ticket QA Success Ratio",
            buttonShowOrHide: null,
            reportComponent: (
              <GeneralQAReport
                positiveData={agentReport.generalPositive}
                negativeData={agentReport.generalNegative}
                positiveDataSeparated={agentReport.generalPositiveSeparated}
                negativeDataSeparated={agentReport.generalNegativeSeparated}
                type={QA_REPORTS.GENERAL_TICKET_REPORT}
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "Support QA Success Ratio",
            buttonShowOrHide: null,
            reportComponent: (
              <GeneralQAReport
                positiveData={agentReport.agentPositiveReviews}
                negativeData={agentReport.agentNegativeReviews}
                positiveDataSeparated={
                  agentReport.agentPositiveReviewsSeparated
                }
                negativeDataSeparated={
                  agentReport.agentNegativeReviewsSeparated
                }
                type={QA_REPORTS.SUCCESS_RATIO_REPORT}
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "QA Dispute Ratio",
            buttonShowOrHide: null,
            reportComponent: (
              <DisputeQAReport
                total={agentReport.submittedEvaluationsCount}
                totalSeparated={agentReport.submittedEvaluationsCountSeparated}
                totalDisputed={agentReport.totalDisputed}
                totalDisputedSeparated={agentReport.totalDisputedSeparated}
                disputedCountByStatus={agentReport.disputedCountByStatus}
                disputedCountByStatusSeparated={
                  agentReport.disputedCountByStatusSeparated
                }
                chartConfig={chartConfig}
              />
            ),
          },
          {
            title: "QA Review Results After Dispute",
            buttonShowOrHide: null,
            reportComponent: (
              <DisputeQAReport
                total={agentReport.submittedEvaluationsCount}
                totalSeparated={agentReport.submittedEvaluationsCountSeparated}
                totalDisputed={agentReport.totalDisputed}
                totalDisputedSeparated={agentReport.totalDisputedSeparated}
                disputedCountByStatus={agentReport.disputedCountByStatus}
                disputedCountByStatusSeparated={
                  agentReport.disputedCountByStatusSeparated
                }
                afterDisputeReport={true}
                chartConfig={chartConfig}
              />
            ),
          },
        ];
        break;

      default:
        break;
    }
    return reportsBelowOverallReport;
  };
  const reportsBelowOverallReport = getChartsBelowOverallReport();

  useEffect(() => {
    switch (type) {
      case QA_REPORT_FOR.MANAGER:
        setIsLoading(true);

        getGeneralQAReport(
          selectedDate,
          selectedAgents,
          selectedProducts,
          selectedAgentCategories,
          selectedGeneralCategories,
          selectedAllAgents,
          selectedAllProducts,
          selectedAllAgentCategories,
          selectedAllGeneralCategories,
          ticketCheckboxChecked,
          selectedDateSeparator,
        ).then(
          ({ data }) => {
            const totalPerProduct =
              Object.values(data.totalPerProduct).reduce(
                (sum, { totalSubmittedEvaluations }) =>
                  (sum += totalSubmittedEvaluations),
                0,
              ) > 0
                ? data.totalPerProduct
                : null;
            setAgentReport({ ...data, totalPerProduct });
            setIsLoading(false);
          },
          (e) => toastr.error("Failed fetching report"),
        );
        break;

      case QA_REPORT_FOR.QA_MANAGER:
        setIsLoading(true);

        getQATeamReport(
          selectedDate,
          selectedAgents,
          selectedProducts,
          selectedAllAgents,
          selectedAllProducts,
          ticketCheckboxChecked,
          selectedDateSeparator,
        ).then(
          ({ data }) => {
            setAgentReport(data);
            setIsLoading(false);
          },
          (e) => toastr.error("Failed fetching report"),
        );
        break;
      default:
        break;
    }
  }, [
    selectedDate,
    selectedAgents,
    selectedProducts,
    selectedAgentCategories,
    selectedGeneralCategories,
    selectedAllAgents,
    selectedAllProducts,
    selectedAllAgentCategories,
    selectedAllGeneralCategories,
    ticketCheckboxChecked,
    selectedDateSeparator,
    type,
  ]);

  useEffect(() => {
    switch (type) {
      case QA_REPORT_FOR.AGENT:
        setIsLoading(true);

        getCurrentAgentReport(selectedDate, selectedDateSeparator).then(
          ({ data }) => {
            setAgentReport(data);
            setIsLoading(false);
          },
          (e) => toastr.error("Failed fetching report"),
        );
        break;

      case QA_REPORT_FOR.QA_AGENT:
        setIsLoading(true);

        getCurrentQAAgentReport(selectedDate, selectedDateSeparator).then(
          ({ data }) => {
            setAgentReport(data);
            setIsLoading(false);
          },
          (e) => toastr.error("Failed fetching report"),
        );
        break;
      default:
        break;
    }
  }, [type, selectedDate, selectedDateSeparator]);

  useEffect(() => {
    switch (type) {
      case QA_REPORT_FOR.AGENT:
      case QA_REPORT_FOR.MANAGER:
        getQAQuestionsApi().then(
          ({ data: { questions } }) => {
            setAgentQuestions(questions);
          },
          (e) => toastr.error("Failed fetching agent questions"),
        );
        break;
      default:
        break;
    }
  }, [type]);

  const onChangeSpecificFilterComponent = (value, type, categoryName) => {
    switch (type) {
      case "products":
      case "select-all-products":
      case "deselect-all-products":
        setSelectedProducts(onChangeSpecificFilter(value, type, categoryName));
        break;

      case "agent-categories":
      case "select-all-agent-categories":
      case "deselect-all-agent-categories":
        setSelectedAgentCategories(
          onChangeSpecificFilter(value, type, categoryName),
        );
        break;

      case "general-categories":
      case "select-all-general-categories":
      case "deselect-all-general-categories":
        setSelectedGeneralCategories(
          onChangeSpecificFilter(value, type, categoryName),
        );
        break;

      case "agents":
      case "select-all-agents":
        setSelectedAgents(onChangeSpecificFilter(value, type, categoryName));
        setSelectedGroups({ selectedGroups: [] });
        break;

      case "groups":
        const {
          selectedGroups,
          selectedAgents,
          selectedAllAgents,
        } = onChangeSpecificFilter(value, type, categoryName);

        setSelectedGroups({ selectedGroups });
        setSelectedAgents({ selectedAgents, selectedAllAgents });
        break;

      default:
        break;
    }
  };

  return (
    <div className={className("qa-report-page")}>
      <div className="qa-report-page__reports">
        {!isLoading ? (
          <OverallQAReport
            type={type}
            agentData={agentReport}
            chartConfig={chartConfig}
          />
        ) : (
          <Spinner />
        )}
        <div
          className={classNames("qa-report-page__reports__below-overall", {
            "qa-report-page__reports__below-overall--grid":
              isQAAgent || isQAManager,
          })}
        >
          {reportsBelowOverallReport.map((report) => (
            <div
              className="qa-report-page__reports__below-overall__report"
              key={report.title}
            >
              <h3
                className={classNames(
                  "qa-report-page__reports__below-overall__report__title",
                  {
                    "qa-report-page__reports__below-overall__report__title--center":
                      isQAAgent || isQAManager,
                  },
                )}
              >
                <span className="qa-report-page__reports__below-overall__report__title__text">
                  {report.title}
                </span>
                {report.buttonShowOrHide}
              </h3>
              {!isLoading ? report.reportComponent : <Spinner />}
            </div>
          ))}
        </div>
      </div>
      <div className="qa-report-page__filter">
        <div className="qa-report-page__filter__config">
          <Select
            placeholder="Select Chart Type"
            fluid
            value={selectedChart}
            options={CHART_TYPES}
            onChange={(fieldOptions, field) => {
              const values = field.value.split(" ");
              const chartType = values[0];
              const chartOtherVersion = values[1] === "true";
              const chartStacked = values[2] === "true";

              setChartType({ chartType, chartOtherVersion, chartStacked });
            }}
          />
          <Select
            placeholder="Separate Date By"
            fluid
            options={SEPARATE_DATE_OPTIONS}
            value={selectedDateSeparator}
            onChange={onChangeDateSeparator}
          />
          {(isManager || isQAManager) && (
            <Checkbox
              label={ticketCheckboxLabel}
              name="ticketCheckbox"
              checked={ticketCheckboxChecked}
              onChange={(e, { checked }) =>
                setTicketCheckboxChecked({ checked })
              }
            />
          )}
        </div>
        <div className="qa-report-page__filter__accordion">
          <Accordion as={Menu} vertical fluid>
            <Menu.Item>
              <Accordion.Title
                active={activeAccordionIndexes[0] === 1}
                content="Calendar Filter"
                index={0}
                onClick={handleAccordionClick}
              />
              <Accordion.Content
                active={activeAccordionIndexes[0] === 1}
                content={
                  <CalendarFilter
                    onChangeDate={onChangeDate}
                    selectedDate={selectedDate}
                    selectedDateSingleFilter={selectedDateSingleFilter}
                  />
                }
              />
            </Menu.Item>

            {!isQAAgent && (
              <Menu.Item>
                <Accordion.Title
                  active={activeAccordionIndexes[1] === 1}
                  content="Specific Filter"
                  index={1}
                  onClick={handleAccordionClick}
                />
                <Accordion.Content
                  active={activeAccordionIndexes[1] === 1}
                  content={
                    <SpecificFilter
                      type={type}
                      agentQuestions={agentQuestions}
                      onChangeSpecificFilter={onChangeSpecificFilterComponent}
                      selectedProducts={selectedProducts}
                      selectedAllProducts={selectedAllProducts}
                      selectedAgentCategories={selectedAgentCategories}
                      selectedAllAgentCategories={selectedAllAgentCategories}
                      selectedGeneralCategories={selectedGeneralCategories}
                      selectedAllGeneralCategories={
                        selectedAllGeneralCategories
                      }
                      selectedAgents={selectedAgents}
                      selectedGroups={selectedGroups}
                    />
                  }
                />
              </Menu.Item>
            )}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default QAReportPage;
