import React, {
  useEffect,
  useCallback,
  useMemo,
  useContext,
  useState,
} from "react";
import moment from "moment";
import * as toastr from "toastr";
import { usePagination } from "../../../../../../../hooks/usePagination.hook";
import apis from "../../../../../../../apis";
import {
  useFilters,
  regExpValidator,
  notEmptyValidator,
} from "../../../../../../../hooks/useFilters.hook";
import {
  Table,
  Segment,
  Header,
  Input,
  Select,
  Button,
  Icon,
} from "semantic-ui-react";
import Spinner from "../../../../../../../components/Spinner";
import { getDateFormat } from "../../../../../../../common/date-format";
import { CALL_TYPES } from "./constants/callTypes";
import { getOptionsFromObject } from "../../../../../../../common/select-helper";
import { TWILIO_CALL_ENDPOINT } from "../../../../../../../constants/endpoints";
import { getZendeskNameByUrl, ZENDESK_HOSTS } from "../../constants/zendesk";
import { UsersContext } from "../../../../../../../common/UsersContext";
import { DateInput } from "semantic-ui-calendar-react";
import SmallConfirmPopup from "../../../../../../../components/SmallConfirmPopup";
import { logServerError } from "../../../../../../../common/logs.js";

const CallsTable = () => {
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [isExporting, setIsExporting] = useState(false);

  const { allUsers: users } = useContext(UsersContext);
  const {
    items: calls,
    loadItems: loadCalls,
    isLoading,
    refreshItems: refreshCalls,
    Paginator,
  } = usePagination(apis.calls.getCalls, {
    clearOnLoad: false,
    initialLoad: false,
  });

  const { normalizedFilters, filters, setFormFilter } = useFilters(
    {
      zendeskHost: ZENDESK_HOSTS[0].link,
    },
    {
      localStorageKey: "call-filters",
      filterValidators: {
        zendeskId: regExpValidator(/^\d+$/),
        callId: notEmptyValidator(),
        finalAgentId: notEmptyValidator(),
        createdAt: notEmptyValidator(),
      },
      temporaryFilters: ["zendeskId", "callId", "finalAgentId", "createdAt"],
    },
  );

  useEffect(() => {
    loadCalls(1, normalizedFilters);
  }, [normalizedFilters, loadCalls]);

  const agentOptions = useMemo(() => {
    if (!users) return [];

    return users.map((user) => ({
      text: user.fullName,
      value: user._id,
      key: user._id,
    }));
  }, [users]);

  const getCreatedAt = (from, to) => {
    if (!from && !to) {
      return "";
    }

    if (from && !to) {
      return {
        $gte: from,
      };
    }

    if (!from && to) {
      return {
        $lt: to,
      };
    }
    return { $gte: from, $lt: to };
  };

  const exportCallsToGoogleSheet = useCallback(async (filters) => {
    try {
      const { status } = await apis.calls.exportCallsToGoogleSheet(filters);
      if (status === 200) {
        toastr.success("Successfully exported data");
      }
      setIsExporting(false);
    } catch (err) {
      logServerError(err);
    }
  }, []);

  const filtersRendered = useMemo(() => {
    const {
      zendeskId = "",
      callId = "",
      callType = [],
      finalAgentId = [],
    } = filters;
    return (
      <div className="sk-table-filters">
        <SmallConfirmPopup
          header={"Export data to google sheet"}
          callback={() => {}}
          content={
            <div>
              <Button
                onClick={() => {
                  setIsExporting(true);
                  exportCallsToGoogleSheet(filters);
                }}
                positive
                floated="right"
                content="Export"
              />
            </div>
          }
        >
          <Button
            color={"blue"}
            className="ui medium icon button"
            disabled={isExporting}
          >
            <Icon className={"download"}></Icon>
          </Button>
        </SmallConfirmPopup>
        <DateInput
          name="dateFrom"
          dateFormat={"YYYY-MM-DD"}
          clearable={true}
          placeholder="Date from"
          value={dateFrom}
          popupPosition="top right"
          animation="none"
          duration={0}
          iconPosition="left"
          onChange={(_, field) => {
            const { value } = field;
            setDateFrom(value);
            setFormFilter(null, {
              name: "createdAt",
              value: getCreatedAt(value, dateTo),
            });
          }}
        />
        <DateInput
          name="dateTo"
          dateFormat={"YYYY-MM-DD"}
          clearable={true}
          placeholder="Date to"
          value={dateTo}
          popupPosition="top right"
          animation="none"
          duration={0}
          iconPosition="left"
          onChange={(_, field) => {
            const { value } = field;
            setDateTo(value);
            setFormFilter(null, {
              name: "createdAt",
              value: getCreatedAt(dateFrom, value),
            });
          }}
        />
        <Select
          placeholder="Select agent"
          multiple
          options={agentOptions}
          value={finalAgentId}
          name="finalAgentId"
          onChange={setFormFilter}
        />
        <Input
          label={"Zendesk ID"}
          value={zendeskId}
          type="text"
          name="zendeskId"
          onChange={setFormFilter}
        />
        <Input
          label={"Call ID"}
          value={callId}
          type="text"
          name="callId"
          onChange={setFormFilter}
        />
        <Select
          placeholder="Select call type"
          multiple
          options={getOptionsFromObject(CALL_TYPES, {
            optionValueFrom: "value",
          })}
          value={callType}
          name="callType"
          onChange={setFormFilter}
        />
        <Button onClick={refreshCalls}>
          {isLoading ? "Loading.." : "Refresh"}
        </Button>
      </div>
    );
  }, [
    filters,
    agentOptions,
    setFormFilter,
    refreshCalls,
    isLoading,
    dateFrom,
    dateTo,
    isExporting,
    exportCallsToGoogleSheet,
  ]);

  const getCallDuration = useCallback((call) => {
    const duration = moment.utc(call.callDuration * 1000).format("HH:mm:ss");
    if (duration === "Invalid date") {
      return "N/A";
    }
    return duration;
  }, []);

  const getCallFrom = useCallback((call) => {
    const {
      callType,
      callBody: { From = "Not Saved" } = {},
      finalAgent,
    } = call;
    if (callType === CALL_TYPES.INCOMING) {
      return From;
    }
    return finalAgent ? finalAgent.fullName : From;
  }, []);

  const getCallTo = useCallback((call) => {
    const { callType, callBody: { To = "Not saved", phoneNumber } = {} } = call;
    if (callType === CALL_TYPES.INCOMING) {
      return To || "Not saved";
    }
    return phoneNumber || To;
  }, []);

  return (
    <Segment.Group>
      <Segment className="tableTopSegment" inverted>
        <Header className="tableTitle" size="large" floated="left">
          <h3>Calls table</h3>
        </Header>
      </Segment>
      {filtersRendered}
      <Table celled striped fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Call ID</Table.HeaderCell>
            <Table.HeaderCell width={1}>Type</Table.HeaderCell>
            <Table.HeaderCell width={1}>Agent</Table.HeaderCell>
            <Table.HeaderCell width={1}>Zendesk ID</Table.HeaderCell>
            <Table.HeaderCell width={1}>From</Table.HeaderCell>
            <Table.HeaderCell width={1}>Call Duration</Table.HeaderCell>
            <Table.HeaderCell width={1}>To</Table.HeaderCell>
            <Table.HeaderCell width={1}>Created At</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!calls ? (
            <Table.Row>
              <Table.Cell>
                <Spinner />
              </Table.Cell>
            </Table.Row>
          ) : (
            calls.map((call, index) => (
              <Table.Row key={call._id}>
                <Table.Cell width={2}>
                  <a
                    href={TWILIO_CALL_ENDPOINT(call.callId)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {call.callId}
                  </a>
                </Table.Cell>
                <Table.Cell width={1}>{call.callType}</Table.Cell>
                <Table.Cell width={1}>
                  {call.callType === "incoming"
                    ? call.finalAgent
                      ? call.finalAgent.fullName
                      : "N/A"
                    : ""}
                </Table.Cell>
                <Table.Cell width={1}>
                  <a
                    href={call.zendeskUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {call.zendeskId} ({getZendeskNameByUrl(call.zendeskHost)})
                  </a>
                </Table.Cell>
                <Table.Cell width={1}>{getCallFrom(call)}</Table.Cell>
                <Table.Cell width={1}>{getCallDuration(call)}</Table.Cell>
                <Table.Cell width={1}>{getCallTo(call)}</Table.Cell>
                <Table.Cell width={1}>
                  {getDateFormat(call.createdAt)}
                </Table.Cell>
              </Table.Row>
            ))
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={6}>
              <Paginator />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment.Group>
  );
};

export default CallsTable;
