export const AGENT_SHIFTS = {
  SHIFT_1: "2AM - 11AM EST",
  SHIFT_2: "6AM - 3PM EST",
  SHIFT_3: "10AM - 7PM EST",
  SHIFT_4: "2PM - 11PM EST",
  SHIFT_5: "6PM - 3AM EST",
  SHIFT_6: "10PM - 7AM EST"
};

export const SHIFT_IN_TIME = {
  [AGENT_SHIFTS.SHIFT_1]: {
    from: 2,
    to: 11
  },
  [AGENT_SHIFTS.SHIFT_2]: {
    from: 6,
    to: 15
  },
  [AGENT_SHIFTS.SHIFT_3]: {
    from: 10,
    to: 19
  },
  [AGENT_SHIFTS.SHIFT_4]: {
    from: 14,
    to: 23
  },
  [AGENT_SHIFTS.SHIFT_5]: {
    from: 18,
    to: 3
  },
  [AGENT_SHIFTS.SHIFT_6]: {
    from: 22,
    to: 7
  }
}