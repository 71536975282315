import axios from "../httpClient";

function getBrochures(language, visaType) {
  return axios.get("brochures", { params: { language, visaType } });
}

function getBrochure(id) {
  return axios.get(`brochures/${id}`);
}

function updateBrochure(documentId, brochure) {
  return axios.patch(`/brochures/${documentId}`, brochure);
}

function deleteBrochure(id) {
  return axios.delete(`brochures/${id}`);
}

function deleteBrochureVersion(brochureId, versionId) {
  return axios.delete(`brochures/${brochureId}/versions/${versionId}`);
}

function addBrochure(name, file, language, type) {
  const formData = new FormData();

  formData.append("fileName", "brochure");
  formData.append("name", name);
  formData.append("pdf", file);
  formData.append("language", language);
  formData.append("visaType", type);

  return axios.post("brochures/upload", formData);
}

function updateActiveVersion(brochureId, versionId) {
  return axios.patch(`brochures/${brochureId}/versions/${versionId}`);
}

function getBrochureLanguages() {
  return axios.get("brochures/brochure-languages");
}

function addNewLanguage(language) {
  return axios.post("brochures/add-brochure-language", { language });
}

export default {
  getBrochures,
  getBrochure,
  deleteBrochure,
  deleteBrochureVersion,
  addBrochure,
  updateActiveVersion,
  updateBrochure,
  getBrochureLanguages,
  addNewLanguage,
};
