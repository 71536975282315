import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import "./Confirm.styles.scss";

const Confirm = (props) => {
  const { children, parent, onConfirm, onCancel } = props;

  useEffect(() => {
    disableBodyScroll(document.body);
    return () => {
      enableBodyScroll(document.body);
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      <div className="overlay" onClick={onCancel} />
      <div className="confirm">
        <div className="confirm__content">{children}</div>
        <div className="confirm__actions">
          <button className="decline" onClick={onCancel}>
            Cancel
          </button>
          <Button color="teal" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </>,
    parent,
  );
};

Confirm.propTypes = {
  parent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.object,
  ]),
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

Confirm.defaultProps = {
  children: "Are you sure?",
  parent: document.body,
  onConfirm: () => {},
  onCancel: () => {},
};

export default Confirm;
