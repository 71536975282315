export const FORM_SCHEMA_TYPES = {
  TEXT: "text",
  NUMBER: "number",
  DATE: "date",
  DATE_TIME: "date_time",
  NESTED: "nested",
  SELECT: "select",
  CHECKBOX: "checkbox",
  DEPENDENT_SELECT: "dependent_select",
  EMAIL: "email",
  MULTI_SELECT: "multi_select",
  MULTI_TEXT: "multi_text",
  ARRAY: "array",
  SEPARATOR: "separator",
  SEGMENT: "segment",
};
