/* eslint-disable no-loop-func */
import { v1 as uuid } from "uuid";
import { makeShiftSegments } from "./makeShiftSegments";

/**
 * Group shifts that have an intersection of hours (they have common hours) - sorted by hours
 *
 * @typedef {{segmentGroup: { firstSegment: string, lastSegment: string }}} segmentGroup
 * @typedef {{segmentGroup1: segmentGroup, segmentGroup2: segmentGroup, segmentGroup3: segmentGroup }} shiftGroup
 * @param {shiftGroup} shifts
 * @returns {{id: string, shift: string, hours: Array<number>}}
 */
export const makeArrayOfHours = (shifts) => {
  const shiftSegments = makeShiftSegments(shifts);

  const allShiftsRes = Object.keys(shifts);

  // make object with props: combinations of the shifts names, values: empty array
  //
  let result = {};
  let makeCombinationOfShiftNames = function (prefix, shiftSegment) {
    for (let i = 0; i < shiftSegment.length; i++) {
      result = { ...result, ...{ [prefix + shiftSegment[i]]: [] } };
      makeCombinationOfShiftNames(
        prefix + shiftSegment[i] + " ",
        shiftSegment.slice(i + 1),
      );
    }
  };
  makeCombinationOfShiftNames("", allShiftsRes);

  // start value for "i"
  //
  let i = Object.keys(shifts).length - 1;
  let nestedItersectionHours = [];
  let resShiftsGroup = [];

  while (i !== 0) {
    // iterating through combinations of the shift names
    //
    for (const [key] of Object.entries(result)) {
      // get shifts from key and check number of shifts, we assign some hour to prop with most shifts
      //
      if (key.split(" ").length - 1 === i) {
        let keys = key.split(" ");
        const arrShiftSegKeys = [];
        keys.forEach((key) => {
          arrShiftSegKeys.push(shiftSegments[key]);
        });

        // get intersection hours
        //
        const intersectionHours = arrShiftSegKeys
          .reduce((a, b) => b.filter(Set.prototype.has, new Set(a)))
          .filter((v) => !nestedItersectionHours.includes(v));

        // add to nested intersection hours
        // e.g. S1 include 5am and S2 include 5am, result = { "S1 S2": [5] }, not result = { "S1": [5], "S2": [5] }
        //
        nestedItersectionHours = [
          ...nestedItersectionHours,
          ...intersectionHours,
        ];

        // make object with new results
        //
        if (intersectionHours.length > 0) {
          resShiftsGroup.push({
            id: uuid(),
            shifts: keys.join(" "),
            hours: intersectionHours,
          });
        }
      }
    }
    i--;
  }

  // shift segments have values with all hours for some shift
  //
  for (const [key, value] of Object.entries(shiftSegments)) {
    // filter values that aren't in nested shifts (hours just in some shift, and they aren't in another shift)
    //
    const hoursForShift = value.filter(
      (v) => !nestedItersectionHours.includes(v),
    );

    // add that hours for that shift
    //
    if (hoursForShift.length > 0) {
      resShiftsGroup.push({
        id: uuid(),
        shifts: key,
        hours: hoursForShift,
      });
      nestedItersectionHours = [...nestedItersectionHours, ...hoursForShift];
    }
  }

  // hours without some shift
  //
  const emptySegments = Array.from({ length: 24 }, (_, ind) => ind).filter(
    (v) => !nestedItersectionHours.includes(v),
  );

  if (emptySegments.length > 0)
    resShiftsGroup.push({
      id: uuid(),
      shifts: "empty",
      hours: emptySegments,
    });

  // sort shifts by group of hours
  //
  resShiftsGroup.forEach((s) => {
    // maybe 0 isn't first hour (e.g. [22, 23, 0, 1, 2])
    //
    if (s.hours.includes(0) && s.hours[0] !== 0) {
      const idForDelete = s.id;

      // make two object in that case (e.g. [0, 1, 2] and [22, 23])
      //
      resShiftsGroup.push({
        id: uuid(),
        shifts: s.shifts,
        hours: s.hours.slice(s.hours.indexOf(0), s.hours.length),
      });

      resShiftsGroup.push({
        id: uuid(),
        shifts: s.shifts,
        hours: s.hours.slice(0, s.hours.indexOf(0)),
      });

      resShiftsGroup = [...resShiftsGroup.filter((r) => r.id !== idForDelete)];
    }
  });

  // sort by first hour in group of hours
  //
  resShiftsGroup = [...resShiftsGroup.sort((a, b) => a.hours[0] - b.hours[0])];

  return resShiftsGroup;
};
