import axios from "../httpClient";


const getSecretById = async (secretId) => {
    const result = await axios.post(`/olympus/secret/access`, {
        secretId
      });
    return result.data.plainTextSecret
}

const updateSecretById = async (secretId, newValue) => {
    const result = await axios.patch(`/olympus/secret/update`, {
        secretId,
        newValue,
    })

    return 
}


export {
    getSecretById,
    updateSecretById
}