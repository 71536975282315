import React, { Component } from "react";
import { ROLES } from "../../../../../constants/rolesConstants";
import CreditCardsConfiguration from "./components/CreditCardsConfiguration";
import BotsConfiguration from "./components/BotsConfiguration";
import GeneralConfiguration from "./components/GeneralConfiguration";
import ZendeskConfiguration from "./components/ZendeskConfiguration";
import EmailAlertConfiguration from "./components/EmailAlertConfiguration";
// import QAQuestions from "./components/QAQuestions";
import CallRoutingConfiguration from "./components/CallRoutingConfiguration";
import ConfigurationProvider from "./providers/ConfigurationProvider";
import MenuTabs from "../../../../../components/MenuTabs";
import ApplicantsDeletionConfiguration from "./components/ApplicantsDeletionConfiguration/ApplicantsDeletionConfiguration.component";
import IvisaConfiguration from "./components/IvisaConfiguration";
import DocumentUploadConfiguration from "./components/DocumentUploadConfiguration";
import ProductConfig from "../ProductConfig/ProductConfig.page"
import BotsStatisticConfiguration from "./components/BotsErrorStatisticConfiguration";
import BotAlarmConfigurationList from "./components/BotAlarmConfigurationList/BotAlarmConfigurationList.component";
const tabs = [
  {
    name: "General",
    component: <GeneralConfiguration />,
    route: "general",
  },
  {
    name: "Product Configuration",
    component: <ProductConfig />,
    route: "product-configuration",
  },
  {
    name: "Zendesk",
    component: <ZendeskConfiguration />,
    authorizedRoles: [ROLES.ADMIN],
    route: "zendesk",
  },
  {
    name: "Credit Cards",
    component: <CreditCardsConfiguration />,
    authorizedRoles: [ROLES.ADMIN],
    route: "credit-card",
  },
  {
    name: "Bots",
    component: <BotsConfiguration />,
    authorizedRoles: [ROLES.ADMIN],
    route: "bot-config",
  },
  {
    name: "Sales Alarms",
    component: <EmailAlertConfiguration />,
    authorizedRoles: [ROLES.ADMIN],
    route: "alarms",
  },
  {
    name: "Bot Alarms",
    component: <BotAlarmConfigurationList/>,
    authorizedRoles: [ROLES.ADMIN],
    route: "bot-alarms",
  },
  {
    name: "Bot Error Statistics",
    component: <BotsStatisticConfiguration/>,
    authorizedRoles: [ROLES.ADMIN],
    route: "bot-error-statistics",
  },
  // {
  //   name: "QA Questions",
  //   component: <QAQuestions />,
  //   authorizedRoles: [ROLES.ADMIN, ROLES.QA_MANAGER],
  //   route: "qa-questions",
  // },
  {
    name: "Twilio number configs",
    component: <CallRoutingConfiguration />,
    authorizedRoles: [ROLES.ADMIN],
    route: "twilio-numbers",
  },
  {
    name: "Applicants for deletion",
    component: <ApplicantsDeletionConfiguration />,
    route: "applicants-deletion",
  },
  {
    name: "Ivisa",
    component: <IvisaConfiguration />,
    authorizedRoles: [ROLES.ADMIN],
    route: "ivisa",
  },
  {
    name: "Requesting documents",
    component: <DocumentUploadConfiguration />,
    authorizedRoles: [ROLES.ADMIN],
    route: "requesting-documents",
  },
];

class ConfigurationMenu extends Component {
  render() {
    return (
      <ConfigurationProvider>
        <MenuTabs
          {...this.props}
          tabs={tabs}
          defaultRoute="support"
          pathParam="configType"
        />
      </ConfigurationProvider>
    );
  }
}

export default ConfigurationMenu;
