import React, { useState, useEffect, useRef } from "react";
import ConfigurationContext from "./Configuration.context";
import apis from "../../../../../../../apis";
import { logServerError } from "../../../../../../../common/logs";
import { Loader } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { AuthService } from "../../../../../../../services/authService";
import { ROLES } from "../../../../../../../constants/rolesConstants";

const ConfigurationProvider = ({ children }) => {
  const [creditCards, setCreditCards] = useState();
  const [botConfigs, setBotConfigs] = useState();
  const [ivisaConfig, setIvisaConfig] = useState();
  const [uploadingDocumentsConfig, setUploadingDocumentsConfig] = useState('test');
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useSelector((state) => ({
    currentUser: state.currentUser,
  }));
  const prevUserId = useRef(null);

  useEffect(() => {
    const initConfig = async () => {
      try {
        const [
          { data: botConfigs },
          { data: creditCards },
          { data: ivisaConfig },
          { data: uploadingDocumentsConfig },
        ] = await Promise.all([
          apis.configuration.botConfig.getBotConfiguration(),
          apis.configuration.creditCardConfig.getCreditCards(),
          apis.configuration.ivisaConfig.getIvisaConfig(),
          apis.configuration.documentUploadConfiguration.getDocumentUploadConfig(),
        ]);
        setCreditCards(creditCards);
        setBotConfigs(botConfigs);
        setIvisaConfig(ivisaConfig);
        setUploadingDocumentsConfig(uploadingDocumentsConfig)
        setIsLoading(false);
      } catch (err) {
        logServerError(err);
      }
    };
    if (
      currentUser &&
      prevUserId.current !== currentUser?.id &&
      AuthService.checkRolesForUser(currentUser, [ROLES.ADMIN])
    ) {
      prevUserId.current = currentUser._id;
      initConfig();
    } else {
      setIsLoading(false);
    }
  }, [setBotConfigs, setCreditCards, setIvisaConfig, currentUser]);

  if (isLoading) {
    return <Loader active />;
  }

  return (
    <ConfigurationContext.Provider
      value={{
        creditCards,
        botConfigs,
        ivisaConfig,
        uploadingDocumentsConfig,
        setBotConfigs,
        setCreditCards,
        setIvisaConfig,
        setUploadingDocumentsConfig,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

export default ConfigurationProvider;
