import React, { useContext, useCallback, useState, useMemo } from "react";
import { Item, Select, Button, Checkbox, Input } from "semantic-ui-react";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import "./BotsConfiguration.scss";
import toastr from "toastr";
import ConfigurationContext from "../../providers/ConfigurationProvider/Configuration.context";
import { logServerError } from "../../../../../../../common/logs";
import apis from "../../../../../../../apis";
import { filterInvalidProductProps } from "../../../../routers/Products/constants/productNames";

const BotsConfiguration = () => {
  const {
    botConfigs: defaultBotConfigs,
    creditCards,
    setBotConfigs: setDefaultBotConfigs,
  } = useContext(ConfigurationContext);
  const [botConfigs, setBotConfigs] = useState(defaultBotConfigs);

  const editBotConigurationHandler = useCallback(async () => {
    try {
      await apis.configuration.botConfig.editBotConfiguration(botConfigs);
      setDefaultBotConfigs(botConfigs);
      toastr.success("Configurations have been updated!");
    } catch (err) {
      logServerError(err);
    }
  }, [botConfigs, setDefaultBotConfigs]);

  const discardHandler = useCallback(() => {
    setBotConfigs(defaultBotConfigs);
  }, [setBotConfigs, defaultBotConfigs]);

  const creditCardOptions = useMemo(
    () =>
      Object.keys(creditCards).map((creditCard, index) => ({
        key: index,
        value: creditCards[creditCard]._id,
        text: creditCards[creditCard].cardName,
        description: `**** **** **** ${creditCards[creditCard].last4Digits}`,
      })),
    [creditCards],
  );

  const changeBotPropery = useCallback(
    (key, prop, value) => {
      const currentConfig = botConfigs[key];
      setBotConfigs({
        ...botConfigs,
        [key]: { ...currentConfig, [prop]: value },
      });
    },
    [setBotConfigs, botConfigs],
  );

  const renderAutosubmitOptions = useCallback(
    (key) => {
      const {
        autoRun: { enabled, delay },
        autoRun,
      } = botConfigs[key];
      return (
        <React.Fragment>
          <Item.Content>
            <Item.Meta>Bot Autosubmit Settings</Item.Meta>
            <Item.Description className="bot-autosubmit-content-wrapper">
              <div className="bot-autosubmit-toggle">
                <Checkbox
                  toggle
                  onChange={(event, { checked }) =>
                    changeBotPropery(key, "autoRun", {
                      ...autoRun,
                      enabled: checked,
                    })
                  }
                  checked={enabled}
                />
                {enabled ? "enabled" : "disabled"}
              </div>
              <div className="bot-autosubmit-input">
                <Input
                  name="delay"
                  type="text"
                  disabled={!enabled}
                  value={delay}
                  source={key}
                  onChange={(event, { value }) => {
                    if (!value || /^[0-9]*([.,][0-9]*)?$/.test(value)) {
                      changeBotPropery(key, "autoRun", {
                        ...autoRun,
                        delay: value,
                      });
                    }
                  }}
                />
                Autorun Delay (minutes)
              </div>
            </Item.Description>
          </Item.Content>
        </React.Fragment>
      );
    },
    [botConfigs, changeBotPropery],
  );

  const renderChecker = useCallback(
    (key) => {
      const {
        statusChecker: { timeToNextAttemptArray, attemptsNumber, enabled },
        statusChecker,
      } = botConfigs[key];

      return (
        key === "esta" && (
          <React.Fragment>
            <Item.Content>
              <Item.Meta>Bot Status Checker Settings</Item.Meta>
              <Item.Description className="bot-autosubmit-content-wrapper">
                <div className="bot-autosubmit-toggle">
                  <Checkbox
                    toggle
                    onChange={(event, { checked }) =>
                      changeBotPropery(key, "statusChecker", {
                        ...statusChecker,
                        enabled: checked,
                      })
                    }
                    checked={enabled}
                  />
                  {enabled ? "enabled" : "disabled"}
                </div>
                <div className="bot-autosubmit-input">
                  Attempts number
                  <br/>
                  <Input
                    name="attemptsNumber"
                    type="number"
                    min="0"
                    value={attemptsNumber}
                    onChange={(_, { value }) => {
                      changeBotPropery(key, "statusChecker", {
                        ...statusChecker,
                        attemptsNumber: value,
                      });
                    }}
                    onBlur={() => {
                      const numberValue = parseInt(attemptsNumber);
                      if (!numberValue || numberValue < 0) {
                        changeBotPropery(key, "statusChecker", {
                          ...statusChecker,
                          attemptsNumber: 0,
                        });
                      }
                    }}
                  />
                </div>
              </Item.Description>
              <div className="bot-next-attempt">
                Time to next attempt - Time in minutes to the next bot attempt
                {timeToNextAttemptArray.map((el, index) => (
                  <div key={index} className="bot-next-attempt__input">
                    <Input
                      value={el}
                      type="number"
                      min="1"
                      onChange={(_, { value }) => {
                        const newValue = [...timeToNextAttemptArray];
                        newValue.splice(index, 1, value);
                        changeBotPropery(key, "statusChecker", {
                          ...statusChecker,
                          timeToNextAttemptArray: newValue,
                        });
                      }}
                      onBlur={() => {
                        const numberValue = parseInt(
                          timeToNextAttemptArray[index],
                        );
                        if (!numberValue || numberValue < 1) {
                          const newValue = [...timeToNextAttemptArray];
                          newValue.splice(index, 1, 1);
                          changeBotPropery(key, "statusChecker", {
                            ...statusChecker,
                            timeToNextAttemptArray: newValue,
                          });
                        }
                      }}
                    />
                    <Button
                      type="button"
                      style={{ marginLeft: "8px", padding: "8px" }}
                      onClick={() => {
                        const newValue = [...timeToNextAttemptArray];
                        newValue.splice(index, 1);
                        changeBotPropery(key, "statusChecker", {
                          ...statusChecker,
                          timeToNextAttemptArray: newValue,
                        });
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                ))}
                <Button
                  type="button"
                  style={{
                    padding: "8px",
                    width: "max-content",
                    marginTop: "8px",
                  }}
                  onClick={() => {
                    changeBotPropery(key, "statusChecker", {
                      ...statusChecker,
                      timeToNextAttemptArray: [...timeToNextAttemptArray, 1],
                    });
                  }}
                >
                  Add
                </Button>
              </div>
            </Item.Content>
          </React.Fragment>
        )
      );
    },
    [botConfigs, changeBotPropery],
  );

  return (
    <React.Fragment>
      <Item.Group className="bot-item-container">
        {Object.entries(filterInvalidProductProps(botConfigs)).map(
          ([key, botConfig]) => {
            return (
              <React.Fragment key={key}>
                <Item>
                  <Item.Content className="primary-card-container">
                    <Item.Header
                      style={{
                        color: "#00b5ad",
                        // backgroundColor: '#00b5ad',
                        display: "block",
                      }}
                    >
                      {key.toUpperCase()}
                    </Item.Header>
                    <Item.Meta>Primary credit card</Item.Meta>
                    <Item.Description>
                      <Select
                        button
                        className="icon"
                        floating
                        labeled
                        onChange={(event, field) =>
                          changeBotPropery(key, "creditCard", field.value)
                        }
                        value={botConfig.creditCard}
                        icon="credit card outline"
                        options={creditCardOptions}
                      />
                    </Item.Description>
                  </Item.Content>
                  <Item.Content className="secondary-card-container">
                    <Item.Meta>Secondary credit card</Item.Meta>
                    <Item.Description>
                      <Select
                        name={`secondary.${key}`}
                        button
                        className="icon"
                        floating
                        labeled
                        onChange={(event, field) =>
                          changeBotPropery(key, "alternativeCard", field.value)
                        }
                        value={botConfig.alternativeCard}
                        icon="credit card outline"
                        options={creditCardOptions}
                      />
                    </Item.Description>
                  </Item.Content>
                  {renderAutosubmitOptions(key)}
                </Item>
                <Item>{renderChecker(key)}</Item>
              </React.Fragment>
            );
          },
        )}
      </Item.Group>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "12px",
        }}
      >
        <ConfirmPopup
          content="Are you sure you want discard changes?"
          callback={discardHandler}
        >
          <Button className="discard-button">Discard</Button>
        </ConfirmPopup>
        <ConfirmPopup
          content="Are you sure you want save changes?"
          callback={editBotConigurationHandler}
        >
          <Button className="save-button">Save</Button>
        </ConfirmPopup>
      </div>
    </React.Fragment>
  );
};

export default BotsConfiguration;
