import React, { useMemo } from "react";
import moment from "moment";
import Timespan from "./components/Timespan";
import className from "classnames";
import { transformDifference, EVENT_NAMES } from "../../common/index";
import "./TimespanFrame.styles.scss";
import { capitalize } from "lodash";
import { getDateFormat } from "../../../../../../../../../../../common/date-format";

export const getColorByAvailability = (availability) => {
  switch (availability) {
    case EVENT_NAMES.AVAILABLE:
      return {
        backgroundColor: "#54ab13",
        color: "white",
      };
    case EVENT_NAMES.OFFLINE:
      return {
        backgroundColor: "#6f6f6f",
        color: "white",
      };
    default:
      return {
        backgroundColor: "#e3b207",
        color: "#212121",
      };
  }
};

export const renderTimeDifference = (diff, title = "Diff:") => {
  const diffObj = transformDifference(diff);
  return (
    <div>
      <strong>{title}</strong>
      <span className="sk-difference">
        {Object.entries(diffObj).map(([key, value], ind, { length }) => {
          if (value) {
            return (
              <span key={key}>
                {value} {key}
                {value > 1 ? "s" : ""}
                {ind < length - 1 ? " " : ""}
              </span>
            );
          } else return null;
        })}
      </span>
    </div>
  );
};

const TimespanFrame = ({ reports, firstDate, separator, lastDate }) => {
  const maxParts = moment(lastDate).diff(moment(firstDate), separator, true);

  const reportsFulfiled = useMemo(() => {
    if (!reports || !reports.length) {
      return [];
    }
    return [
      {
        createdAt: moment(firstDate).toDate().toISOString(),
        name:
          reports[0].data.previousAvailabilityStatus
            .split(" ")[0]
            .toLowerCase() || EVENT_NAMES.OFFLINE,
      },
      ...reports,
    ];
  }, [reports, firstDate]);

  let partCounter = 0;
  return (
    <div className={className("sk-activity-bar-container")}>
      <label className="sk-activity-tip">
        Hover over the area to inspect it
      </label>
      <div className="sk-activity-bar">
        {reportsFulfiled.map((report, index) => {
          const nextReport = reportsFulfiled[index + 1];

          let currentParts = 0;
          const currentDate = moment(report.createdAt);
          let nextDate = null;

          if (!nextReport) {
            currentParts = maxParts - partCounter;
            nextDate = moment(lastDate);
          } else {
            nextDate = moment(nextReport.createdAt);
            currentParts = nextDate.diff(currentDate, separator, true);
          }
          partCounter += currentParts;

          const styles = {
            width: `${(currentParts / maxParts) * 100}%`,
            ...getColorByAvailability(report.name),
          };
          return (
            <React.Fragment key={"timespan" + index}>
              <Timespan {...styles} className={report.name}>
                <p>
                  <strong>Status: </strong>
                  {capitalize(report.name)}
                </p>
                <p>
                  <strong>From: </strong>
                  {getDateFormat(currentDate)}
                </p>
                <p>
                  <strong>To: </strong>
                  {getDateFormat(nextDate)}
                </p>
                <div>{renderTimeDifference(nextDate.diff(currentDate))}</div>
              </Timespan>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default TimespanFrame;
