import React, { useState, useMemo, useCallback } from "react";
import { Button } from "semantic-ui-react";
import FormFields from "../../../../../../../../components/common/FormFields";
import ModalForm from "../../../../../../../../components/common/ModalForm";

import {
  DISPUTE_STATUS_MAPPING,
  QA_EVALUATION_TICKET_DISPUTE_STATUS,
} from "../../../../../constants/qaTicketStatuses";

const disputeOptions = Object.entries(QA_EVALUATION_TICKET_DISPUTE_STATUS).map(
  ([key, value]) => ({
    key: value,
    value: value,
    text: DISPUTE_STATUS_MAPPING[key],
  }),
);

/**
 *
 * @param {{ticketDispute?:any,agentAssessmentResult?:boolean,onSubmit:()=>any,modalState?:'creating'|'resolving'}} param0
 */
const TicketDisputeModal = ({
  disableActions,
  ticketDispute = null,
  agentAssessmentResult = false,
  onSubmit = () => {},
  modalState = "creating",
  children,
}) => {
  const [disputeReasonText, setDisputeReasonText] = useState("");
  const [disputeResolutionText, setDisputeResolutionText] = useState("");
  const [disputeResolutionStatus, setDisputeResolutionStatus] = useState(false);

  const clearForm = useCallback(() => {
    setDisputeReasonText("");
    setDisputeResolutionText("");
    setDisputeResolutionStatus(
      QA_EVALUATION_TICKET_DISPUTE_STATUS.NOT_RESOLVED,
    );
  }, [
    setDisputeReasonText,
    setDisputeResolutionText,
    setDisputeResolutionStatus,
  ]);

  const { disputeStatus, isResolved = false } = useMemo(() => {
    if (!ticketDispute) {
      return {};
    }
    return {
      disputeStatus: ticketDispute.status,
      isResolved:
        ticketDispute.status !==
        QA_EVALUATION_TICKET_DISPUTE_STATUS.NOT_RESOLVED,
    };
  }, [ticketDispute]);

  const fields = useMemo(() => {
    const disputeReasonText = ticketDispute && ticketDispute.disputeReasonText;
    const disputeResolutionText =
      ticketDispute && ticketDispute.disputeResolutionText;

    return [
      {
        key: "disputeStatus",
        type: "message",
        text: `Dispute status: ${DISPUTE_STATUS_MAPPING[disputeStatus]}`,
        noRender: !ticketDispute,
      },
      {
        key: "disputeReasonText",
        type: "textarea",
        label: "Dispute reason description",
        defaultValue: disputeReasonText,
        fieldSpecific: {
          readonly: Boolean(ticketDispute || modalState === "resolving"),
        },
      },
      {
        key: "disputeResolutionText",
        type: "textarea",
        label: "Dispute resolution description",
        defaultValue: disputeResolutionText,
        noRender: !ticketDispute,
        fieldSpecific: {
          readonly: Boolean(modalState === "creating" || isResolved),
        },
      },
      {
        key: "disputeStatus",
        type: "select",
        label: "Resolve dispute status",
        fieldSpecific: {
          options: disputeOptions,
        },
        noRender: !ticketDispute || isResolved || modalState !== "resolving",
      },
    ];
  }, [isResolved, modalState, ticketDispute, disputeStatus]);

  const buttonColor = useMemo(() => {
    switch (disputeStatus) {
      case QA_EVALUATION_TICKET_DISPUTE_STATUS.NOT_RESOLVED:
        return "yellow";
      case QA_EVALUATION_TICKET_DISPUTE_STATUS.RESOLVED_NEGATIVE:
        return "red";
      case QA_EVALUATION_TICKET_DISPUTE_STATUS.RESOLVED_POSITIVE:
        return "green";
      case QA_EVALUATION_TICKET_DISPUTE_STATUS.UNRELATED_DISPUTE:
        return "grey";
      default:
        return "orange";
    }
  }, [disputeStatus]);

  if (modalState === "creating" && agentAssessmentResult && !ticketDispute) {
    return null;
  }
  if (modalState === "resolving" && !ticketDispute) {
    return null;
  }

  const onSubmitForm = (result) => {
    const { disputeResolutionText, disputeReasonText, disputeStatus } = result;

    switch (modalState) {
      case "resolving":
        if (!isResolved) {
          onSubmit({
            disputeStatus,
            disputeResolutionText,
          });
        }
        break;
      default:
        if (!ticketDispute) {
          onSubmit({
            disputeReasonText,
          });
        }
        break;
    }
  };

  return (
    <ModalForm
      headerText="Ticket Dispute"
      submitButtonText={
        (ticketDispute &&
          !!ticketDispute.disputeReasonText &&
          !!ticketDispute.disputeResolutionText) ||
        isResolved
          ? "OK"
          : "Submit"
      }
      onSubmit={(result) => onSubmitForm(result)}
      onClose={clearForm}
      closeOnSubmit
      trigger={
        children ? (
          React.cloneElement(children, { color: buttonColor })
        ) : (
          <Button
            color={buttonColor}
            size="mini"
            icon="file outline"
            disabled={disableActions}
          ></Button>
        )
      }
    >
      <FormFields
        currentItem={{
          disputeReasonText,
          disputeResolutionText,
          disputeResolutionStatus,
        }}
        fields={fields}
      />
    </ModalForm>
  );
};

export default TicketDisputeModal;
