import {
  APPLICANT_STATUSES,
  getApplicantStatusString,
} from "../../constants/applicantStatuses";
import { API_NAMES } from "./apiNames";

const STANDARD_STATUSES = [
  APPLICANT_STATUSES.New,
  APPLICANT_STATUSES["Pending submission"],
  APPLICANT_STATUSES["Bot in queue"],
  APPLICANT_STATUSES["Bot in progress"],
  APPLICANT_STATUSES["Bot returned error"],
  APPLICANT_STATUSES["Submitted"],
  APPLICANT_STATUSES["Submitted and paid"],
  APPLICANT_STATUSES["Waiting for approval"],
  APPLICANT_STATUSES["More info required"],
  APPLICANT_STATUSES["Approved"],
  APPLICANT_STATUSES["Rejected"],
  APPLICANT_STATUSES["Email parse failed"],
  APPLICANT_STATUSES["Escalated"],
  APPLICANT_STATUSES["Cancelled"],
  APPLICANT_STATUSES["Processed manually"],
  APPLICANT_STATUSES["Partially Refunded"],
];

export const APPLICANT_STATUS_BY_API = {
  [API_NAMES.ETA]: [
    APPLICANT_STATUSES.New,
    APPLICANT_STATUSES["Pending submission"],
    APPLICANT_STATUSES["Bot in progress"],
    APPLICANT_STATUSES["Bot returned error"],
    APPLICANT_STATUSES["Submitted"],
    APPLICANT_STATUSES["Submitted and paid"],
    APPLICANT_STATUSES["Waiting for approval"],
    APPLICANT_STATUSES["More info required"],
    APPLICANT_STATUSES["Approved"],
    APPLICANT_STATUSES["Rejected"],
    APPLICANT_STATUSES["Email parse failed"],
    APPLICANT_STATUSES["Escalated"],
    APPLICANT_STATUSES["Cancelled"],
    APPLICANT_STATUSES["Processed manually"],
    APPLICANT_STATUSES["Payment failed"],
  ],
  [API_NAMES.ESTA]: [
    APPLICANT_STATUSES.New,
    APPLICANT_STATUSES["Pending submission"],
    APPLICANT_STATUSES["Bot in queue"],
    APPLICANT_STATUSES["Bot in progress"],
    APPLICANT_STATUSES["Bot returned error"],
    APPLICANT_STATUSES["Submitted"],
    APPLICANT_STATUSES["Submitted and paid"],
    APPLICANT_STATUSES["Waiting for approval"],
    APPLICANT_STATUSES["More info required"],
    APPLICANT_STATUSES["Approved"],
    APPLICANT_STATUSES["Rejected"],
    APPLICANT_STATUSES["Escalated"],
    APPLICANT_STATUSES["Processed manually"],
    APPLICANT_STATUSES["Passport already exists, paid application"],
    APPLICANT_STATUSES["Status checker error"],
    APPLICANT_STATUSES["Payment could not be determined"],
    APPLICANT_STATUSES["Payment failed - will retry payment"],
    APPLICANT_STATUSES[
      "Passport already exists, failed retrieving old application"
    ],
    APPLICANT_STATUSES["Failed retrying payment by autopilot"],
    APPLICANT_STATUSES["Retrying payment"],
    APPLICANT_STATUSES[
      "Previously submitted application. Travel not authorized"
    ],
    APPLICANT_STATUSES["Partially Refunded"],
    APPLICANT_STATUSES["Submitted to IVISA"],
    APPLICANT_STATUSES["Service error"],
    APPLICANT_STATUSES["Invalid passport image"],
  ],
  [API_NAMES.DS160]: [
    APPLICANT_STATUSES.New,
    APPLICANT_STATUSES["Pending submission"],
    APPLICANT_STATUSES["Bot in queue"],
    APPLICANT_STATUSES["Bot in progress"],
    APPLICANT_STATUSES["Bot returned error"],
    APPLICANT_STATUSES["Submitted"],
    APPLICANT_STATUSES["Submitted and paid"],
    APPLICANT_STATUSES["More info required"],
    APPLICANT_STATUSES["Extended Support"],
    APPLICANT_STATUSES["Escalated"],
    APPLICANT_STATUSES["Processed manually"],
    APPLICANT_STATUSES["Embassy account created"],
    APPLICANT_STATUSES["Interview Scheduled"],
    APPLICANT_STATUSES["Biometrical appointment"],
    APPLICANT_STATUSES["Drop off Appointment"],
    APPLICANT_STATUSES["Follow-up Appointment"],
    APPLICANT_STATUSES["Interview waiver"],
    APPLICANT_STATUSES["VISA Approved"],
    APPLICANT_STATUSES["VISA Issued"],
    APPLICANT_STATUSES["Passport received"],
    APPLICANT_STATUSES["Embassy bot in progress"],
    APPLICANT_STATUSES["Embassy bot in queue"],
    APPLICANT_STATUSES["Embassy bot returned error"],
    APPLICANT_STATUSES["Administrative Processing"],
    APPLICANT_STATUSES["Personal embassy account"],
    APPLICANT_STATUSES["Visa rejected"],
    APPLICANT_STATUSES["Passport in Transit"],
    APPLICANT_STATUSES["Refunded"],
    APPLICANT_STATUSES["Chargeback"],
    APPLICANT_STATUSES["Canceled Authorization"],
    APPLICANT_STATUSES["Partially Refunded"],
  ],
  [API_NAMES.INV]: [
    APPLICANT_STATUSES.New,
    APPLICANT_STATUSES["Escalated"],
    APPLICANT_STATUSES["Submitted"],
    APPLICANT_STATUSES["Refunded"],
    APPLICANT_STATUSES["Chargeback"],
    APPLICANT_STATUSES["Canceled Authorization"],
    APPLICANT_STATUSES["Partially Refunded"],
    APPLICANT_STATUSES["Customer data received"],
  ],
  [API_NAMES.SCHENGEN]: [
    APPLICANT_STATUSES.New,
    APPLICANT_STATUSES["Processed manually"],
    APPLICANT_STATUSES["Embassy account created"],
    APPLICANT_STATUSES["Interview Scheduled"],
    APPLICANT_STATUSES["Administrative Processing"],
    APPLICANT_STATUSES["Visa rejected"],
    APPLICANT_STATUSES["VISA Issued"],
    APPLICANT_STATUSES["Passport in Transit"],
    APPLICANT_STATUSES["Passport received"],
    APPLICANT_STATUSES["Escalated"],
    APPLICANT_STATUSES["Refunded"],
    APPLICANT_STATUSES["Partially Refunded"],
    APPLICANT_STATUSES["Chargeback"],
    APPLICANT_STATUSES["Canceled Authorization"],
    APPLICANT_STATUSES["More info required"],
  ],
  [API_NAMES.NZ]: [
    ...STANDARD_STATUSES,
    APPLICANT_STATUSES[
      "Applicant has valid NZeTA, proceed with override if needed"
    ],
  ],
  [API_NAMES.GE]: [
    ...STANDARD_STATUSES,
    APPLICANT_STATUSES["Appointment Scheduled"],
    APPLICANT_STATUSES["Payment bot in queue"],
    APPLICANT_STATUSES["Payment bot in progress"],
  ],
  [API_NAMES.DS11]: [
    ...STANDARD_STATUSES,
    APPLICANT_STATUSES["Ready for PKO"],
    APPLICANT_STATUSES["Agency: QuickPassport"],
    APPLICANT_STATUSES["Photo not uploaded"],
    APPLICANT_STATUSES["Photo accepted"],
    APPLICANT_STATUSES["Photo invalid"],
    APPLICANT_STATUSES["Kit printed"],
    APPLICANT_STATUSES["Kit mailed"],
  ],
  [API_NAMES.DS64]: [
    ...STANDARD_STATUSES,
    APPLICANT_STATUSES["Ready for PKO"],
    APPLICANT_STATUSES["Agency: QuickPassport"],
    APPLICANT_STATUSES["Photo not uploaded"],
    APPLICANT_STATUSES["Photo accepted"],
    APPLICANT_STATUSES["Photo invalid"],
    APPLICANT_STATUSES["Kit printed"],
    APPLICANT_STATUSES["Kit mailed"],
  ],
  [API_NAMES.DS82]: [
    ...STANDARD_STATUSES,
    APPLICANT_STATUSES["Ready for PKO"],
    APPLICANT_STATUSES["Agency: QuickPassport"],
    APPLICANT_STATUSES["Photo not uploaded"],
    APPLICANT_STATUSES["Photo accepted"],
    APPLICANT_STATUSES["Photo invalid"],
    APPLICANT_STATUSES["Kit printed"],
    APPLICANT_STATUSES["Kit mailed"],
  ],
};

export const EVISA_APPLICANT_STATUSES = {};

Object.values(API_NAMES).forEach((api) => {
  const statuses = APPLICANT_STATUS_BY_API[api] || STANDARD_STATUSES;
  const statusMapping = {};
  statuses.forEach((status) => {
    statusMapping[getApplicantStatusString(status)] = status;
  });
  EVISA_APPLICANT_STATUSES[api] = statusMapping;
});
