import axios from "./httpClient";

export function getQAQuestionsApi(filters) {
  return axios.get(`/department/qa/questions`, { params: filters });
}

export function createQAQuestionApi(newQuestion) {
  return axios.post(`/department/qa/questions`, newQuestion);
}

export function updateQAQuestionApi(questionId, newQuestion) {
  return axios.patch(`/department/qa/questions/${questionId}`, newQuestion);
}

export function deleteQAQuestionsApi(questionId) {
  return axios.delete(`/department/qa/questions/${questionId}`);
}

export function changeQuestionPositionOrderApi(questionIdPoisitonMapping) {
  return axios.patch(
    `/department/qa/questions/positions`,
    questionIdPoisitonMapping,
  );
}
