import React, { useEffect, useMemo } from "react";
import { Loader } from "semantic-ui-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ApplicantNotesService } from "../../../../../../../../../../services/applicantNotes";
import TimeLine from "./TimeLine.component";
import { APPLICATION_NOTES_TEXTAREA_MAXLENGTH } from "../_constants/constants";

const CommentBox = ({ api, editingApplicant, showTimeLine = true, setPinnedNote, showPublishButton = true }) => {
  const [notes, setNotes] = useState([]);
  const [applicantId, setApplicantId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.currentUser);

  const ApplicantNotes = useMemo(() => {
    if (editingApplicant) {
      return new ApplicantNotesService(api, editingApplicant.id, user);
    }
  }, [editingApplicant]);

  const handlePublishNote = async () => {
    const textarea = document.querySelector("#publish-comment");
    const text = textarea.value;
    if (!text) return;
    textarea.value = "";
    const newNote = await ApplicantNotes.createNote(text, "comment");
    setNotes([newNote, ...notes]);
  };

  useEffect(() => {
    if (editingApplicant) {
      setApplicantId(editingApplicant.id);
    }
  }, [editingApplicant]);

  useEffect(() => {
    setIsLoading(true);

    if (applicantId) {
      ApplicantNotes.getNotes().then((notes) => {
        setNotes(notes);
        setIsLoading(false);
      });
    }
  }, [applicantId]);

  return (
    <div className="appn-comment-box">
      <h3 className="appn-comment-box-title">Applicant Notes</h3>
      <div className="appn-comment-box-actions">
        <textarea id="publish-comment" name="comment" maxLength={APPLICATION_NOTES_TEXTAREA_MAXLENGTH} placeholder="Add a comment..." />
        {
          showPublishButton ? (
            <div className="appn-comment-box-options">
              <button
                disabled={isLoading}
                type="button"
                className="appn-comment-box-button"
                onClick={() => handlePublishNote()}
              >
                Publish
              </button>
            </div>
          ): null
        }
      </div>
      {showTimeLine ? (
        <>{isLoading ? <Loader active /> 
          : (<TimeLine 
              notes={notes} 
              handlePinNote={setPinnedNote}
            />)}
        </>
      ) : null}
    </div>
  );
};

export default CommentBox;
