import axios from "../httpClient";

export function getApplicantsForDeletionUnpaid(params) {
  return axios.get(`/applicants-deletion-list/unpaid`, { params });
}

export function getApplicantsForDeletionMarked(params) {
  return axios.get(`/applicants-deletion-list/marked`, { params });
}

export function getApplicantsForDeletionUnfinished(params) {
  return axios.get(`/applicants-deletion-list/unfinished`, { params });
}

export default {
  getApplicantsForDeletionUnpaid,
  getApplicantsForDeletionMarked,
  getApplicantsForDeletionUnfinished,
};
