export const QA_EVALUATION_TICKET_STATUS = {
  New: "new",
  Reopened: "reopened",
  "In progress": "in-progress",
  Submitted: "submitted",
};

export const QA_EVALUATION_TICKET_DISPUTE_STATUS = {
  NOT_RESOLVED: "NOT_RESOLVED",
  RESOLVED_POSITIVE: "RESOLVED_POSITIVE",
  RESOLVED_NEGATIVE: "RESOLVED_NEGATIVE",
  UNRELATED_DISPUTE: "UNRELATED_DISPUTE",
};

export const DISPUTE_STATUS_MAPPING = {
  NOT_RESOLVED: "Not resolved",
  RESOLVED_POSITIVE: "Resolved - Positive",
  RESOLVED_NEGATIVE: "Resolved - Negative",
  UNRELATED_DISPUTE: "Unrelated dispute",
};

export const QA_EVALUATION_TICKET_AGENT_STATUS = {
  NEW: "NEW",
  REVIEWED: "REVIEWED",
  DISPUTED: "DISPUTED",
};
