import React from "react";
import { Table, Checkbox } from "semantic-ui-react";
import { ALLOWED_REFERRAL_SITES } from "../../../../../EVisa/pages/ApplicantsEdit/components/constants";

const SentriReviewAndCertification = ({ referralSite = ALLOWED_REFERRAL_SITES.DEFAULT }) => {
  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell colSpan="2">
          <p>
            <b>Declaration</b>
          </p>
          <p>
            I am aware that the visa fee is not refunded if the visa is refused.
          </p>
          <p>
            Applicable in case a multiple-entry visa is applied for (cf. field
            no 24): I am aware of the need to have an adequate travel medical
            insurance for my first stay and any subsequent visits to the
            territory of Member States.
          </p>
          <p>
            I undertake that my Principal is aware of the contents of my
            submission and that it will be taken to have been personally
            submitted by him/her. My Principal is aware that he/she may be
            liable for offence(s) concerning any false, inaccurate, or
            incomplete statement or declaration contained in the arrival card. I
            also understand that I may be liable for abetting these offences.
          </p>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell colSpan="2">
          <p>
            <b>FBI Privacy Act Statement</b>
          </p>
          <p>
            This privacy act statement is located on the back of the FD-258
            fingerprint card.
          </p>
          <p>
            Authority: The FBI’s acquisition, preservation, and exchange of
            fingerprints and associated information is generally authorized
            under 28 U.S.C. 534. Depending on the nature of your application,
            supplemental authorities include Federal statutes, State statutes
            pursuant to Pub. L. 92-544, Presidential Executive Orders, and
            federal regulations. Providing your fingerprints and associated
            information is voluntary; however, failure to do so may affect
            completion or approval of your application.
          </p>
          <p>
            Principal Purpose: Certain determinations, such as employment,
            licensing, and security clearances, may be predicated on
            fingerprint-based background checks. Your fingerprints and
            associated information/biometrics may be provided to the employing,
            investigating, or otherwise responsible agency, and/or the FBI for
            the purpose of comparing your fingerprints to other fingerprints in
            the FBI’s Next Generation Identification (NGI) system or its
            successor systems (including civil, criminal, and latent fingerprint
            repositories) or other available records of the employing,
            investigating, or otherwise responsible agency. The FBI may retain
            your fingerprints and associated information/biometrics in NGI after
            the completion of this application and, while retained, your
            fingerprints may continue to be compared against other fingerprints
            submitted to or retained by NGI.
          </p>
          <p>
            Routine Uses: During the processing of this application and for as
            long thereafter as your fingerprints and associated
            information/biometrics are retained in NGI, your information may be
            disclosed pursuant to your consent, and may be disclosed without
            your consent as permitted by the Privacy Act of 1974 and all
            applicable Routine Uses as may be published at any time in the
            Federal Register, including the Routine Uses for the NGI system and
            the FBI’s Blanket Routine Uses. Routine uses include, but are not
            limited to, disclosures to: employing, governmental or authorized
            non-governmental agencies responsible for employment, contracting,
            licensing, security clearances, and other suitability
            determinations; local, state, tribal, or federal law enforcement
            agencies; criminal justice agencies; and agencies responsible for
            national security or public safety.
          </p>
          <p>
            <b>Noncriminal Justice Applicant's Privacy Rights</b>
          </p>
          <p>
            As an applicant who is the subject of a national fingerprint-based
            criminal history record check for a noncriminal justice purpose
            (such as an application for employment or a license, an immigration
            or naturalization matter, security clearance, or adoption), you have
            certain rights which are discussed below.
          </p>
          <p>
            You must be provided written notification1 that your fingerprints
            will be used to check the criminal history records of the FBI.
          </p>
          <p>
            {" "}
            You must be provided, and acknowledge receipt of, an adequate
            Privacy Act Statement when you submit your fingerprints and
            associated personal information. This Privacy Act Statement should
            explain the authority for collecting your information and how your
            information will be used, retained, and shared.
          </p>
          <p>
            If you have a criminal history record, the officials making a
            determination of your suitability for the employment, license, or
            other benefit must provide you the opportunity to complete or
            challenge the accuracy of the information in the record
          </p>
          <p>
            The officials must advise you that the procedures for obtaining a
            change, correction, or update of your criminal history record are
            set forth at Title 28, Code of Federal Regulations (CFR), Section
            16.34.
          </p>
          <p>
            If you have a criminal history record, you should be afforded a
            reasonable amount of time to correct or complete the record (or
            decline to do so) before the officials deny you the employment,
            license, or other benefit based on information in the criminal
            history record.
          </p>
          <p>
            You have the right to expect that officials receiving the results of
            the criminal history record check will use it only for authorized
            purposes and will not retain or disseminate it in violation of
            federal statute, regulation or executive order, or rule, procedure
            or standard established by the National Crime Prevention and Privacy
            Compact Council.
          </p>
          <p>
            If agency policy permits, the officials may provide you with a copy
            of your FBI criminal history record for review and possible
            challenge. If agency policy does not permit it to provide you a copy
            of the record, you may obtain a copy of the record by submitting
            fingerprints and a fee to the FBI. Information regarding this
            process may be obtained at
            https://www.fbi.gov/services/cjis/identity-history-summary-checks.
          </p>
          <p>
            If you decide to challenge the accuracy or completeness of your FBI
            criminal history record, you should send your challenge to the
            agency that contributed the questioned information to the FBI.
            Alternatively, you may send your challenge directly to the FBI. The
            FBI will then forward your challenge to the agency that contributed
            the questioned information and request the agency to verify or
            correct the challenged entry. Upon receipt of an official
            communication from that agency, the FBI will make any necessary
            changes/corrections to your record in accordance with the
            information supplied by that agency. (See 28 CFR 16.30 through
            16.34.)
          </p>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell colSpan="2">
          <h5>Third Party Authorization</h5>
          <p>
            I understand that I am applying via GovAssist, LLC; a third-party
            that is not affiliated with the government, that the third-party
            cannot guarantee application approval, that the third-party service
            is offered to help expedite the process, and that I have the option
            to apply directly with the pertinent government websites. I also
            understand that my purchase consists of a processing and service fee
            to GovAssist, which DOES NOT include the Form non-refundable
            government fee, which is required.
          </p>
          <p>
            I confirm and authorize GovAssist, LLC to assist me, by using their
            processing service in completing and reviewing my application. I
            understand and acknowledge that I have not been offered legal advice
            and that this company or its agents and affiliates have never
            claimed to be qualified to offer legal advice under any
            circumstance. I also confirm that I am submitting my application and
            signing it myself.
          </p>
          <p>
            By obtaining services from the GovAssist website, I have agreed to
            the following:{" "}
            <a href={`https://${referralSite}/terms-conditions`}>
              Terms & Conditions
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/refund-policy`}>
              Refund Policy{" "}
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/privacy-policy`}>
              Privacy Policy{" "}
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/legal-disclaimer`}>
              Disclaimer{" "}
            </a>
          </p>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>
    </React.Fragment>
  );
};

export default SentriReviewAndCertification;
