/**
 * Calculates the percentage of a value in the total value
 *
 * @param {Array<number>} arrayOfValues
 * @param {number} value
 * @returns {number} percentage (0-100)
 */
export const calculatePercentages = (arrayOfValues, value) => {
  if (!arrayOfValues.some((v) => v === value)) return null;

  const sum = arrayOfValues.reduce((a, b) => a + b, 0);

  return ((value / sum) * 100).toFixed(1);
};
