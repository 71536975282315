import React from "react";
import Layout from "../../../components/Layout/Layout.component";
import redirect from "../../modules/redirect";
import NestedRoutes from "../../components/NestedRoutes";
import { ProductsRouter } from "../DashboardRouter/routers";
import InterviewDatesPage from "../DashboardRouter/pages/InterviewDates";

const routes = [
  {
    path: "/interview-dates",
    component: InterviewDatesPage,
  },
  {
    path: "",
    component: redirect("/esta"),
  },
  {
    component: ProductsRouter,
    exact: false,
  },
];

const ZendeskDashboardRouter = () => {
  return (
    <Layout zendeskView={true}>
      <NestedRoutes routes={routes} />
    </Layout>
  );
};

export default ZendeskDashboardRouter;
