import botConfig from "./botConfigApi";
import creditCardConfig from "./creditCardApi";
import emailAlertConfig from "./emailAlertsApi";
import zendeskConfig from "./zendeskConfigApi";
import ivisaConfig from "./ivisaConfiguration";
import documentUploadConfiguration from "./documentUploadConfiguration";

export default {
  botConfig,
  creditCardConfig,
  emailAlertConfig,
  zendeskConfig,
  ivisaConfig,
  documentUploadConfiguration,
};
