import React, { useCallback, useState } from "react";
import { Button, Input, Modal, Select } from "semantic-ui-react";
import FileInput from "../../../../../../../../../components/FileInput/FileInput.component";
import toastr from "toastr";
import apis from "../../../../../../../../../apis";

import "./AddNewBrochureModal.styles.scss";

const AddNewBrochureModal = (props) => {
  const { setBrochures, languages } = props;

  const [name, setName] = useState("");
  const [language, setLanguage] = useState("");
  const [type, setType] = useState("");
  const [file, setFile] = useState(null);
  const [fileBlob, setFileBlob] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleOnFileUpload = useCallback((event) => {
    setFile(event.target.files[0]);

    const blob = new Blob([event.target.files[0]], { type: "application/pdf" });
    setFileBlob(URL.createObjectURL(blob));
    URL.revokeObjectURL(blob)
  }, []);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await apis.documentHandler.addBrochure(name, file, language, type);
      setFile(null);
      setName("");
      setLanguage("");
      setType("");
      setFileBlob("");
      setBrochures((prev) => [...prev, data]);
      toastr.success("Successfully added new brochure.");
    } catch (error) {
      toastr.error("Failed to add new brochure.");
      if (error?.response?.data?.message) {
        toastr.error(error.response.data.message);
      }
      console.error(error);
    }

    setIsLoading(false);
  }, [file, language, name, setBrochures, type]);

  return (
    <Modal
      className="new-brochure-modal"
      closeIcon
      trigger={<Button type="button">Add new brochure</Button>}
    >
      <Modal.Header>Add new brochure</Modal.Header>
      <Modal.Content className="new-brochure-modal__content">
        <Input
          name="name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          label="Document name"
          className="new-brochure-modal__content__name"
        />
        <Select
          placeholder="Select language"
          value={language}
          options={languages || []}
          onChange={(ev, field) => setLanguage(field.value)}
        />
        <Select
          placeholder="Select form type"
          value={type}
          options={
            [
              { text: "VISA", value: "VISA" },
              { text: "ESTA", value: "ESTA" },
            ] || []
          }
          onChange={(ev, field) => setType(field.value)}
        />
        <div className="new-brochure-modal__content__file">
          <FileInput
            onFileUpload={handleOnFileUpload}
            accept=".pdf"
            className="new-brochure-modal__content__file__input"
          />
          {fileBlob && (
            <a href={fileBlob} target="_blank" rel="noopener noreferrer">
              {`Preview document: ${file?.name}`}
            </a>
          )}
        </div>
        {fileBlob && (
          <div className="new-brochure-modal__content__preview">
            <object
              data={fileBlob}
              type="application/pdf"
              width="50%"
              height="100%"
            >
              Preview couldn't be loaded, please click on above link to preview
              uploaded file
            </object>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="blue"
          onClick={handleSubmit}
          disabled={!file || !name || !language || isLoading}
          loading={isLoading}
        >
          Upload
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddNewBrochureModal;
