import React, { useCallback, useState } from "react";
import { Form, Header } from "semantic-ui-react";
import CustomSelect from "../../../../../../../../../components/common/CustomSelect";

const ChangeTypeForm = ({ applicant }) => {
  const [item, setItem] = useState(applicant);

  const changeCurrentItemPropertyValue = useCallback((property, value) => {
    setItem((currItem) => ({ ...currItem, [property]: value }));
  }, []);

  return (
    <div>
      <Form.Field>
        <label>Type</label>
        <CustomSelect
          item={item}
          setItem={setItem}
          changeCurrentItemPropertyValue={changeCurrentItemPropertyValue}
          itemPropName="applicationType"
          options={[
            { value: "Main", text: "Main" },
            { value: "Secondary", text: "Secondary" },
          ]}
          itemProp={item.applicationType}
          disabled={!!item.secondaryApplicationList.length}
        />
      </Form.Field>
      {!!item.secondaryApplicationList.length && (
        <Header size="small" color="red" inverted>
          You cannot change applicant type because it already has linked
          secondary applicants!
        </Header>
      )}

      {item.applicationType === "Secondary" && (
        <Form.Field>
          <Form.Input
            value={item.mainApplicationId || ""}
            label="Main Application ID"
            placeholder="Main Application ID"
            type="input"
            name="mainApplicationId"
            onChange={(event, field) =>
              changeCurrentItemPropertyValue("mainApplicationId", field.value)
            }
          />
        </Form.Field>
      )}
    </div>
  );
};

ChangeTypeForm.defaultProps = {
  applicant: {
    applicationType: "",
    mainApplicationId: "",
  },
};

export default ChangeTypeForm;
