import axios from "../../httpClient";

export function getPartnersApi(filters) {
  return axios.get(`/department/admin/partners`, { params: filters });
}

export function createPartnersApi(partnerBody) {
  return axios.post(`/department/admin/partners`, partnerBody);
}

export function updatePartnerApi(partnerId, partnerBody) {
  return axios.patch(`/department/admin/partners/${partnerId}`, partnerBody);
}

export function deletePartnerApi(partnerId) {
  return axios.delete(`/department/admin/partners/${partnerId}`);
}
