import React, { useCallback, useEffect, useState } from "react";
import {
  Segment,
  Header,
  Table,
  Select,
  Button,
  Checkbox,
  Loader,
} from "semantic-ui-react";
import { getDateFormat } from "../../../../../../../common/date-format";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import { useSelector, useDispatch } from "react-redux";
import toastr from "toastr";
import { editUserSettings } from "../../../../../../../store/actions/userActions";
import { CredentialsService } from "../../../../../../../services/credentialsService";

const GeneralConfiguration = () => {
  const [userSettings, setUserSettings] = useState(null);
  const [cloneUserSettings, setCloneUserSettings] = useState(null);

  const { currentUser } = useSelector((state) => ({
    currentUser: state.currentUser,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      setCloneUserSettings(JSON.parse(JSON.stringify(currentUser.settings)));
      setUserSettings(JSON.parse(JSON.stringify(currentUser.settings)));
    }
  }, [currentUser]);

  const discardChanges = useCallback(() => {
    setUserSettings(cloneUserSettings);
  }, [cloneUserSettings]);

  const saveChanges = useCallback(() => {
    setCloneUserSettings(JSON.parse(JSON.stringify(userSettings)));
    dispatch(editUserSettings(userSettings));
    toastr.success("Configuration successfully changed!", "Saved");
  }, [dispatch, userSettings]);

  return !userSettings ? (
    <Loader active />
  ) : (
    <>
      <Segment.Group>
        <Segment className="tableTopSegment" inverted>
          <Header className="tableTitle" size="large" floated="left">
            <h3>General configuration</h3>
          </Header>
        </Segment>
        <Table celled striped fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>Configuration name</Table.HeaderCell>
              <Table.HeaderCell width={3}>Configuration value</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={1}>Timezone</Table.Cell>
              <Table.Cell width={3}>
                <Select
                  value={parseInt(userSettings.timeZone)}
                  search={true}
                  style={{ width: "70%" }}
                  options={Array.from({ length: 24 }, (v, k) => k - 12).map(
                    (value) => ({
                      text: `UTC ${value >= 0 ? "+" + value : value}`,
                      value,
                    }),
                  )}
                  onChange={(fieldOptions, field) => {
                    setUserSettings({
                      ...userSettings,
                      timeZone: field.value,
                    });
                  }}
                />
                <span style={{ marginLeft: "10px" }}>
                  Current time:{" "}
                  {getDateFormat(new Date(), userSettings.timeZone)}
                </span>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={1}>Dark theme</Table.Cell>
              <Table.Cell width={3}>
                {" "}
                <Checkbox
                  slider
                  checked={userSettings.activeTheme === "dark"}
                  onChange={() => {
                    setUserSettings({
                      ...userSettings,
                      activeTheme:
                        userSettings.activeTheme === "dark" ? "light" : "dark",
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "12px",
          }}
        >
          <ConfirmPopup
            content="Are you sure you want discard changes?"
            callback={() => discardChanges()}
          >
            <Button className="discard-button">Discard</Button>
          </ConfirmPopup>
          <ConfirmPopup
            content="Are you sure you want save changes?"
            callback={() => saveChanges()}
          >
            <Button
              className="save-button"
              disabled={!!CredentialsService.isSimulation()}
            >
              Save
            </Button>
          </ConfirmPopup>
        </div>
      </Segment.Group>
    </>
  );
};

export default GeneralConfiguration;
