import React, { useCallback, useContext, useState } from "react";
import { Button, Modal, Select, Input} from "semantic-ui-react";
import { ApplicantDocumentsContext } from "../../../../../../../../../../common/ApplicantDocumentsContext";
import { DOCUMENT_TYPE } from "../ApplicantDocumentHandlerView/ApplicantDocumentHandlerView.component";
import { DOCUMENTS_OPTIONS } from "../../../../../../../../../../utils";

import './RequestDocumentModal.styles.scss';

const RequestDocumentModal = (props) => {
  const {
    documentType,
  } = props;
  const { sendDocumentRequest, api } = useContext(ApplicantDocumentsContext);

  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [otherDocument, setOtherDocument] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(
    DOCUMENTS_OPTIONS[DOCUMENT_TYPE[documentType]] &&
    DOCUMENTS_OPTIONS[DOCUMENT_TYPE[documentType]][api]
      ? DOCUMENTS_OPTIONS[DOCUMENT_TYPE[documentType]][api]
      : []
  );

  const handleSubmit = useCallback( () => {
    setIsLoading(true);
    sendDocumentRequest(selectedDocuments);
    setSelectedDocuments([]);
    setIsLoading(false);
  }, [selectedDocuments, sendDocumentRequest]);
  
  const handleAddOtherDocument = useCallback(() => {
    if (otherDocument) {
      const newDocument = { text: otherDocument, value: otherDocument };
      // Update the options state with the new document
      setOptions((prevOptions) => [...prevOptions, newDocument]);
     
      setSelectedDocuments((prevSelected) => [...prevSelected, otherDocument]);
      setOtherDocument("");
    }
  }, [otherDocument]);

  return (
    <Modal
      className="request-document-modal"
      closeIcon
      trigger={
        <Button id="request-documents" type="button">
          Request Documents
        </Button>
      }
    >
      <Modal.Header>Request Documents</Modal.Header>
      <Modal.Content className="request-document-modal__content">
        <Select
          name="name"
          onChange={(_, { value }) => {
            setSelectedDocuments(value);
          }} 
          label="Document name"
          className="new-brochure-modal__content__name"
          placeholder="Please select which document needs to be upload"
          search
          multiple
          options={options}
          value={selectedDocuments}
        />
        <div className="request-document-modal__content__other">
          <Input
            name="other"
            label="Other"
            className="request-document-modal__content__other__input"
            onChange={(e, { value }) => setOtherDocument(value)}
            value={otherDocument}
          />
          <Button
            color="blue"
            onClick={handleAddOtherDocument}
            loading={isLoading}
          >
            Add document
          </Button>
      </div>
      </Modal.Content>
      <Modal.Actions>
      <Button
        color="blue"
        onClick={handleSubmit}
        disabled={!selectedDocuments.length || isLoading}
        loading={isLoading}
      >
        Send request
      </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default RequestDocumentModal;
