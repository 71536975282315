import React, { useContext, useCallback, useState } from "react";
import { Item, Button, Input } from "semantic-ui-react";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import "./DocumentUploadConfiguration.scss";
import toastr from "toastr";
import ConfigurationContext from "../../providers/ConfigurationProvider/Configuration.context";
import { logServerError } from "../../../../../../../common/logs";
import apis from "../../../../../../../apis";

const DocumentUploadConfiguration = () => {
  const {
    uploadingDocumentsConfig: defaultUploadingDocumentsConfig,
    setUploadingDocumentsConfig: setDefaultUploadingDocumentsConfig,
  } = useContext(ConfigurationContext);
  const [uploadingDocumentsConfig, setUploadingDocumentsConfig] = useState(defaultUploadingDocumentsConfig);
  const { expirationDays } = uploadingDocumentsConfig;

  const editUploadingDocumentHandler = useCallback(async () => {
    try {
      await apis.configuration.documentUploadConfiguration.editDocumentUploadConfiguration(uploadingDocumentsConfig);
      setDefaultUploadingDocumentsConfig(uploadingDocumentsConfig);
      toastr.success("Configuration have been updated!");
    } catch (err) {
      logServerError(err);
    }
  }, [setDefaultUploadingDocumentsConfig, uploadingDocumentsConfig]);

  const discardHandler = useCallback(() => {
    setUploadingDocumentsConfig(defaultUploadingDocumentsConfig);
  }, [defaultUploadingDocumentsConfig]);

  return (
    <React.Fragment>
      <Item.Meta>Uploading Documents Configuration</Item.Meta>
      <Item.Description className="document-content-wrapper">
        <div className="expiration-days-input">
          Link Expiration Days
          <br />
          <Input
            name="expirationDays"
            type="number"
            min="-1"
            value={expirationDays}
            onChange={(_, { value }) => {
              setUploadingDocumentsConfig({ expirationDays: value });
            }}
          />
        </div>
      </Item.Description>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "12px",
        }}
      >
        <ConfirmPopup
          content="Are you sure you want discard changes?"
          callback={discardHandler}
        >
          <Button className="discard-button">Discard</Button>
        </ConfirmPopup>
        <ConfirmPopup
          content="Are you sure you want save changes?"
          callback={editUploadingDocumentHandler}
        >
          <Button className="save-button">Save</Button>
        </ConfirmPopup>
      </div>
    </React.Fragment>
  );
};

export default DocumentUploadConfiguration;
