import React, { useMemo, useState } from "react";
import { Modal, Select } from "semantic-ui-react";
import { transformConstantsText } from "../../../../../../../common/string-format";
import { PERFORMANCE_TREND_CHART_TYPES } from "../../constants/chartTypes";
import { Chart } from "../QAReportPage/components";

const INITIAL_CHART_CONFIG = {
  chartType: "bar",
  chartOtherVersion: false,
  chartStacked: true,
  useDate: true,
};

const PerformanceTrend = ({
  open = false,
  setOpen,
  chartData,
  chartLabels,
  performanceTrendFor,
}) => {
  const [chartConfig, setChartConfig] = useState(INITIAL_CHART_CONFIG);

  const selectedChart = useMemo(
    () =>
      `${chartConfig?.chartType} ${chartConfig?.chartOtherVersion} ${chartConfig?.chartStacked}`,
    [chartConfig],
  );
  const modalHeader = useMemo(
    () =>
      `Performance Trend - ${
        performanceTrendFor?.name &&
        transformConstantsText(performanceTrendFor.name).toUpperCase()
      }`,
    [performanceTrendFor],
  );

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size="fullscreen"
    >
      <Modal.Header>{modalHeader}</Modal.Header>
      <Modal.Content>
        <Select
          placeholder="Select Chart Type"
          fluid
          value={selectedChart}
          options={PERFORMANCE_TREND_CHART_TYPES}
          onChange={(fieldOptions, field) => {
            const values = field.value.split(" ");
            const chartType = values[0];
            const chartOtherVersion = values[1] === "true";
            const chartStacked = values[2] === "true";

            setChartConfig((state) => ({
              ...state,
              chartType,
              chartOtherVersion,
              chartStacked,
            }));
          }}
        />
        <Chart
          chartId={performanceTrendFor.name}
          chartConfig={chartConfig}
          chartData={chartData}
          chartLabels={chartLabels}
        />
      </Modal.Content>
    </Modal>
  );
};

export default PerformanceTrend;
