import { fetchSchemaDataApi } from "../../apis/schemaDataApi";
import { FETCH_SCHEMA_DATA_SUCCESS } from "./actionTypes";
import { fetchUspsSchemaDataApi } from "../../apis/usps/uspsData";

export const fetchSchemaData = (api) => async (dispatch) => {
  try {
    const { data } = await fetchSchemaDataApi(api);
    if (data) {
      dispatch({ type: FETCH_SCHEMA_DATA_SUCCESS, payload: data, api });
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchUspsSchemaData = () => async (dispatch) => {
  try {
    const { data } = await fetchUspsSchemaDataApi();
    if (data) {
      dispatch({ type: FETCH_SCHEMA_DATA_SUCCESS, payload: data, api: "usps" });
    }
  } catch (error) {
    console.error(error);
  }
};
