import axios from "../httpClient";
import moment from "moment";

export function getCurrentAgentReport(
  selectedDate,
  selectedDateSeparator = "Day",
) {
  const { startDate, endDate } = selectedDate;

  return axios.get("/report/qa/support-agent", {
    params: {
      from: startDate ? moment(startDate).utc(true).toDate() : null,
      to: endDate ? moment(endDate).utc(true).toDate() : null,
      separateBy: selectedDateSeparator,
    },
  });
}

export function getCurrentQAAgentReport(
  selectedDate,
  selectedDateSeparator = "Day",
) {
  const { startDate, endDate } = selectedDate;

  return axios.get("/report/qa/qa-agent", {
    params: {
      from: startDate ? moment(startDate).utc(true).toDate() : null,
      to: endDate ? moment(endDate).utc(true).toDate() : null,
      separateBy: selectedDateSeparator,
    },
  });
}

export function getQATeamReport(
  selectedDate,
  selectedAgents = {},
  selectedProducts = {},
  selectedAllAgents = false,
  selectedAllProducts = false,
  ticketCheckboxChecked = false,
  selectedDateSeparator = "Day",
) {
  const { startDate, endDate } = selectedDate;
  const agentIds = Object.keys(selectedAgents).join();
  const products = Object.values(selectedProducts).join();

  return axios.get("/report/qa/qa-team", {
    params: {
      from: startDate ? moment(startDate).utc(true).toDate() : null,
      to: endDate ? moment(endDate).utc(true).toDate() : null,
      agentIds: selectedAllAgents ? null : agentIds ? agentIds : null,
      products: selectedAllProducts ? null : products ? products : null,
      includeNotAssigned: ticketCheckboxChecked ? "1" : "0",
      separateBy: selectedDateSeparator,
    },
  });
}

export function getGeneralQAReport(
  selectedDate,
  selectedAgents = {},
  selectedProducts = {},
  selectedAgentQuestion = {},
  selectedGeneralQuestion = {},
  selectedAllAgents = false,
  selectedAllProducts = false,
  selectedAllAgentQuestion = false,
  selectedAllGeneralQuestion = false,
  ticketCheckboxChecked = false,
  selectedDateSeparator = "Day",
) {
  const { startDate, endDate } = selectedDate;
  const agentIds = Object.keys(selectedAgents).join();
  const products = Object.values(selectedProducts).join();

  const generalQuestionIds = Object.keys(selectedGeneralQuestion).join();
  const agentQuestionIds = Object.keys(selectedAgentQuestion).join();

  return axios.get("/report/qa/general", {
    params: {
      from: startDate ? moment(startDate).utc(true).toDate() : null,
      to: endDate ? moment(endDate).utc(true).toDate() : null,
      agentIds: selectedAllAgents ? null : agentIds ? agentIds : null,
      products: selectedAllProducts ? null : products ? products : null,
      generalQuestionIds: selectedAllGeneralQuestion
        ? null
        : generalQuestionIds
        ? generalQuestionIds
        : null,
      agentQuestionIds: selectedAllAgentQuestion
        ? null
        : agentQuestionIds
        ? agentQuestionIds
        : null,
      includeEmpty: ticketCheckboxChecked ? "1" : "0",
      separateBy: selectedDateSeparator,
    },
  });
}
