/**
 * Make hour (hour:minutes) in 00-12 format
 *
 * @param {number | string} hour - hour in format 00-24 or hour with minutes
 * @param {string} firstLast - first or last
 * @returns - hour (hour:minutes) (am or pm)
 */
export const makeHourInAMPM = (hour, firstLast) => {
  // if hour doesn't contain minutes
  //
  if (typeof hour === "number") {
    if (hour > 12) {
      return `${hour - 12}pm`;
    } else {
      return `${hour}am`;
    }
  } else {
    // hour:monutes e.g. 17:45
    // we get the first next hour with minutes and so we subtract one hour
    //
    let parentHour = parseInt(hour.split(":")[0]) - 1;

    // first segment is hour without minutes
    // last segment is hour with minutes (last part in shift report) e.g. 17-17:45
    //
    if (firstLast === "first") {
      return parentHour > 12 ? parentHour - 12 + "pm" : parentHour + "am";
    } else {
      if (parentHour > 12) {
        return parentHour - 12 + ":" + hour.split(":")[1] + "pm";
      } else {
        return parentHour + ":" + hour.split(":")[1] + "am";
      }
    }
  }
};
