import React, { useCallback, useState, useEffect } from "react";
import {
  Header,
  Segment,
  Table,
  Input,
  Select,
  Loader,
  Button,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { getDateFormat } from "../../../../../../../../../common/date-format";
import {
  notEmptyValidator,
  regExpValidator,
  useFilters,
  usePagination,
} from "../../../../../../../../../hooks";
import apis from "../../../../../../../../../apis";
import ModalTable from "../../../../../../../../../components/common/ModalTable";
import moment from "moment";
import { API_NAMES } from "../../../../../Products/EVisa/constants/apiNames";
import { useMemo } from "react";

const UserLogsTable = ({ userId }) => {
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const {
    items: logs,
    loadItems: loadLogs,
    isLoading,
    refreshItems: refreshLogs,
    Paginator,
  } = usePagination(
    apis.logs.getUserLogs,
    {
      clearOnLoad: false,
      initialLoad: false,
    },
    userId,
  );

  const { normalizedFilters, filters, setFormFilter, updateFilters } = useFilters(
    {},
    {
      localStorageKey: "user-logs-filters",
      filterValidators: {
        applicantId: regExpValidator(/^.{24}$/),
        eventType: notEmptyValidator(),
        visaType: notEmptyValidator(),
        createdAt: notEmptyValidator(),
        logType: notEmptyValidator(),
      },
      temporaryFilters: ["eventType", "applicantId", "visaType", "createdAt"],
    },
  );

  useEffect(() => {
    loadLogs(1, normalizedFilters);
  }, [loadLogs, normalizedFilters]);

  const getCreatedAt = (from, to) => {
    if (!from && !to) {
      return "";
    }

    if (from && !to) {
      return {
        $gte: from,
      };
    }

    if (!from && to) {
      return {
        $lt: to,
      };
    }
    return { $gte: from, $lt: to };
  };

  const columns = useMemo(() => {
    const { logType } = filters;
    const isOutsideAppLog = logType === "outsideApplicant"

  return([
    {
      label: "Log type",
      prop: "logType",
      width: 2,
    },
    {
      label: "Event type",
      prop: "eventType",
      width: 2,
    },
    ...(!isOutsideAppLog ? [{
      label: "Visa Type",
      prop: "visaType",
      width: 2,
    },
    {
      label: "Applicant ID",
      prop: "applicantId",
      width: 2,
    }] : []),
  ])},[filters]);

  const renderHeader = useCallback(() => {
    const visaTypeOptions = Object.keys(API_NAMES).map((type) => ({
      key: API_NAMES[type],
      text: API_NAMES[type],
      value: API_NAMES[type],
    }));

    const logTypes = ["outsideApplicant", "insideApplicant"].map((type) => ({
      key: type,
      text: type,
      value: type,
    }))

    const { eventType, applicantId, visaType, logType } = filters;
    const isOutsideAppLog = logType === "outsideApplicant"

    return (
      <div className="users-header">
        <div className="users-filters">
        <Select
            options={logTypes}
            placeholder="Select log type"
            name="logType"
            value={logType}
            onChange={(_, field) => {
              updateFilters({[field.name]: field.value, ...(field.value === "outsideApplicant" && {visaType: "", applicantId: ""})})
            }}
            clearable
          />
          <Input
            label="Event type"
            name="eventType"
            value={eventType}
            onChange={setFormFilter}
          />
          <DateInput
            name="dateFrom"
            dateFormat={"YYYY-MM-DD"}
            clearable={true}
            placeholder="Date from"
            maxDate={dateTo ? moment(dateTo).subtract(1, 'day') : undefined}
            value={dateFrom}
            popupPosition="top right"
            animation="none"
            duration={0}
            iconPosition="left"
            onChange={(_, field) => {
              const { value } = field;
              setDateFrom(value);
              setFormFilter(null, {
                name: "createdAt",
                value: getCreatedAt(value, dateTo),
              });
            }}
          />
          <DateInput
            name="dateTo"
            dateFormat={"YYYY-MM-DD"}
            clearable={true}
            placeholder="Date to"
            minDate={dateFrom ? moment(dateFrom).add(1, 'day') : undefined}
            value={dateTo}
            popupPosition="top right"
            animation="none"
            duration={0}
            iconPosition="left"
            onChange={(_, field) => {
              const { value } = field;
              setDateTo(value);
              setFormFilter(null, {
                name: "createdAt",
                value: getCreatedAt(dateFrom, value),
              });
            }}
          />
          {!isOutsideAppLog && <><Input
            label="Application ID"
            name="applicantId"
            value={applicantId}
            onChange={setFormFilter}
          />
         <Select
            options={visaTypeOptions}
            placeholder="Select visa type"
            name="visaType"
            value={visaType}
            onChange={setFormFilter}
            clearable
          /></>}
          <Button onClick={refreshLogs}>
            {isLoading ? "Loading.." : "Refresh"}
          </Button>
        </div>
      </div>
    );
  }, [dateFrom, dateTo, filters, isLoading, refreshLogs, setFormFilter, updateFilters]);

  return (
    <React.Fragment>
      {!logs ? (
        <Loader active />
      ) : (
        <Segment.Group>
          <Segment className="tableTopSegment" inverted>
            <Header className="tableTitle" size="large" floated="left">
              User Logs
            </Header>
          </Segment>
          {renderHeader()}
          <Table celled striped fixed selectable className="user-table">
            <Table.Header>
              <Table.Row>
                {columns.map((column) => (
                  <Table.HeaderCell
                    key={column.prop}
                    width={column.width ? column.width : 1}
                  >
                    {column.label}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
                <Table.HeaderCell width={2}>Created at</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {logs &&
                logs.map((log) => (
                  <Table.Row key={log._id}>
                    {columns.map((column) => (
                      <Table.Cell key={column.prop}>
                        {column.getValue
                          ? column.getValue(log[column.prop])
                          : log[column.prop]}
                      </Table.Cell>
                    ))}
                    <Table.Cell>
                      <ModalTable
                        headerText="List of actions made"
                        data={log.actions}
                        columns={[
                          {
                            label: "Property",
                            prop: "propertyName",
                            width: 1,
                          },
                          {
                            label: "Old value",
                            prop: "oldValue",
                            width: 2,
                          },
                          {
                            label: "New value",
                            prop: "newValue",
                            width: 2,
                          },
                        ]}
                        trigger={<Button>See actions</Button>}
                      />
                    </Table.Cell>
                    <Table.Cell>{getDateFormat(log.createdAt)}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={columns.length}>
                  <Paginator />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Segment.Group>
      )}
    </React.Fragment>
  );
};

export default UserLogsTable;
