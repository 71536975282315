import axios from "../httpClient";

function getCreditCards() {
  return axios.get(`/credit-card`);
}

function createCreditCard(body) {
  return axios.post(`/credit-card`, body);
}

function deleteCreditCard(id) {
  return axios.delete(`/credit-card/${id}`);
}

export default {
  getCreditCards,
  createCreditCard,
  deleteCreditCard,
};
