import React, { useCallback, useState } from "react";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import { Modal, Button, Dimmer, Loader, Icon } from "semantic-ui-react";
import { getApplicantEmailsApi } from "../../../../../../../apis/applicantApi";
import { EMAIL_PREVIEW } from "../../../../../../../constants/serverEndpoints";
import "./ApplicantEmails.styles.scss";

const ApplicantEmails = ({ id, api }) => {
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getApplicantEmails = useCallback(async () => {
    try {
      setIsLoading(true);
      const emailsResponse = await getApplicantEmailsApi(id, api);
      setEmails(emailsResponse?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [api, id]);

  const createEmailHtml = useCallback((text) => {
    const formattedText = text
      .split('\n')
      .map(line => 
        `<p>${line.replace(/(https?:\/\/[^\s<>[\]{}()]+)([<>[\]{}()])?/g, '<a href="$1" target="_blank">$1</a>$2')}</p>`
      )
      .join('');


    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const newWindow = window.open('', '', `width=${screenWidth},height=${screenHeight}`);

    const emailHtml = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
          body { font-family: 'Times New Roman', Times, serif; padding: 20px; }
          .email-content {  padding: 20px; }
        </style>
      </head>
      <body>
        <div class="email-content">
          ${formattedText}
        </div>
      </body>
      </html>
    `;
    
    newWindow.document.write(emailHtml);
    newWindow.document.close();
  }, []);

  return (
    <ConfirmPopup
      content={
        <React.Fragment>
          <Modal.Header>Emails list</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              {!isLoading ? (
                emails.length > 0 ? (
                  emails.map((email, index) => (
                    <React.Fragment key={index}>
                      <p className="applicant-emails">
                        <span
                          data-text={email.subject}
                          className={
                            email.subject && email.subject?.length > 70
                              ? "email__subject"
                              : ""
                          }
                        >
                          {email.subject ? email.subject : "No email subject."}
                        </span>
                        <span>
                          <Button
                            color="blue"
                            size="medium"
                            icon
                            onClick={() => {
                              email.html!=='false'
                                ? window.open(
                                    `${EMAIL_PREVIEW(email._id)}`,
                                    "_blank",
                                  )
                                : createEmailHtml(email.text);
                            }}
                          >
                            <Icon name="mail" />
                          </Button>
                        </span>
                      </p>
                      <hr />
                    </React.Fragment>
                  ))
                ) : (
                  <p>There are no emails.</p>
                )
              ) : (
                <Dimmer active={isLoading}>
                  <Loader size="large" />
                </Dimmer>
              )}
            </Modal.Description>
          </Modal.Content>
        </React.Fragment>
      }
      onOpen={getApplicantEmails}
      cancelButton={null}
      callback={async () => {}}
    >
      <Button color="blue">Emails</Button>
    </ConfirmPopup>
  );
};

export default ApplicantEmails;
