import React from "react";
import { Button } from "semantic-ui-react";
import { FORM_SCHEMA_TYPES as TYPES } from "../formSchemaTypes";
import { TRAVELASSIST_ZENDESK_TICKET_ENDPOINT } from "../../../../../../../../constants/endpoints";
import ZendeskIcon from "../../../../../../../../components/Icons/ZendeskIcon/ZendeskIcon";
import { get } from "lodash";

export const DS64_SELECT_VALUES = {
    GENDERS: "GENDERS",
    COUNTRIES: "COUNTRIES",
    HAIR_COLORS: "HAIR_COLORS",
    EYE_COLORS: "EYE_COLORS",
    COMMUNICATION_METHODS: "COMMUNICATION_METHODS",
    PHONE_COUNTRY_CODES: "PHONE_COUNTRY_CODES",
    PHONE_TYPES: "PHONE_TYPES",
    PASSPORT_STATUS: "PASSPORT_STATUS",
    NAME_CHANGE_OPTIONS: "NAME_CHANGE_OPTIONS",
    PASSPORT_TYPES: "PASSPORT_TYPES",
    PASSPORT_LOST: "PASSPORT_LOST",
    AMERICAN_STATES: "AMERICAN_STATES",
};

const passportTypes = {
    book: 0,
    card: 1,
    both: 2,
}

const AddressSchema = (path) => ({
    streetAddress:{
        label: "Street Address",
        type: TYPES.TEXT,
        path,
        customDependency: (applicant, path, schemaData) => {
            const country = get(applicant, `${path}.country`);
            return country === "USA";
        }
    },
    country:{
        label: "Country",
        type: TYPES.SELECT,
        values: DS64_SELECT_VALUES.COUNTRIES,
        path,
    },
    state:{
        label: "State",
        type: TYPES.SELECT,
        values: DS64_SELECT_VALUES.AMERICAN_STATES,
        customDependency: (applicant, path, schemaData) => {
            const country = get(applicant, `${path}.country`);
            return country === "USA";
        },
        path,
    },
    city:{
        label: "City",
        type: TYPES.TEXT,
        path,
        customDependency: (applicant, path, schemaData) => {
            const country = get(applicant, `${path}.country`);
            return country === "USA";
        }
    },
    apartmentUnit:{
        label: "Apartment/Unit",
        type: TYPES.TEXT,
        path,
        customDependency: (applicant, path, schemaData) => {
            const apartmentUnit = get(applicant, `${path}.apartmentUnit`);
            return !!apartmentUnit;
        }
    },
    zipCode:{
        label: "Zip Code",
        type: TYPES.TEXT,
        path,
        customDependency: (applicant, path, schemaData) => {
            const country = get(applicant, `${path}.country`);
            return country === "USA";
        }
    },
});

const PhoneNumberSchema = (path) => ({
    countryCode:{
        label: "Country code",
        type: TYPES.SELECT,
        values: DS64_SELECT_VALUES.PHONE_COUNTRY_CODES,
        path,
    },
    number:{
        label: "Number",
        type: TYPES.TEXT,
        path,
    },
})

const PassportLostSchema = (path) => ({
    details:{
        label: "Details",
        type: TYPES.TEXT,
        path,
    },
    country:{
        label: "Country",
        type: TYPES.SELECT,
        values: DS64_SELECT_VALUES.COUNTRIES,
        path,
    },
    state:{
        label: "State",
        type: TYPES.SELECT,
        values: DS64_SELECT_VALUES.AMERICAN_STATES,
        customDependency: (applicant, path, schemaData) => {
            const country = get(applicant, `${path}.country`);
            return country === "USA";
        },
        path,
    },
    city:{
        label: "City",
        type: TYPES.TEXT,
        path,
    },
    whenLostDate:{
        label: "When lost date",
        type: TYPES.DATE,
        path,
    },
    whereLostDetails:{
        label: "Where lost details",
        type: TYPES.TEXT,
        path,
    },
    filePoliceReport:{
        label: "File police report",
        type: TYPES.CHECKBOX,
        path,
    }
})

const PassportBookInfoSchema = (path) => ({
    number: {
        label: "Number",
        type: TYPES.TEXT,
        path,
    },
    issueDate: {
        label: "Issue Date",
        type: TYPES.DATE,
        path,
    },
});

const PassportOtherInfo = (path) => ({
    number: {
        label: "How many passports you lost?",
        type: TYPES.NUMBER,
        path,
    },
    aproxLostDate:{
        label: "Aprox lost date",
        type: TYPES.DATE,
        path,
    }
})

export const ds64Schema = {
  generalInfoSegment: {
    label: "General application information",
    type: TYPES.SEGMENT,
    initialOpen: true,
    data: {
      _id: {
        label: "ID",
      },
      applicationType: {
        label: "Application Type",
      },
      mainApplicationId: {
        label: "Main Application ID",
        dependsOn: "applicationType",
        dependantOnValues: ["Secondary"],
      },
      siteId: {
        label: "Referral Site",
      },
      cartSiteId: {
        label: "Purchased from website",
      },
      orderId: {
        label: "Order ID",
      },
      orderIdHistory: {
        label: "Order ID history",
      },
      invoiceEmail: {
        label: "Payment email",
      },
      zendeskId: {
        label: "Zendesk ID",
        type: TYPES.TEXT,
        required: false,
        renderIcon: (propValue) => (
          <Button
            onClick={() => {
              window.open(
                TRAVELASSIST_ZENDESK_TICKET_ENDPOINT(propValue),
                "_blank",
              );
            }}
            style={{ width: 36, height: 36, padding: 0, float: "right" }}
            disabled={!propValue}
          >
            <ZendeskIcon className="zendesk-icon" />
          </Button>
        ),
      },
      submissionEmail: {
        label: "Submission email",
        type: TYPES.TEXT,
      },
      submissionPassword: {
        label: "Submission password",
        type: TYPES.TEXT,
      },
      gclid: {
        label: "Google client ID",
      },
      userLanguage: {
        label: "User form language",
      },
      userCountry: {
        label: "Users IP country",
      },
      salesAgent: {
        label: "Sales Agent",
      },
      salesCoupon: {
        label: "Sales Coupon",
      },
    },
  },
  personalInfoSegment: {
    label: "Personal information",
    type: TYPES.SEGMENT,
    data: {
        passportType:{
            label: "Document(s) the applicant is reporting",
            type: TYPES.SELECT,
            values: DS64_SELECT_VALUES.PASSPORT_TYPES,
        },
        passportBookLostOrStolen:{
            label: "Passport book lost or stolen?",
            type: TYPES.SELECT,
            values: DS64_SELECT_VALUES.PASSPORT_LOST,
            customDependency: (applicant, path, schemaData) => {
                const passportType = get(applicant, 'passportType');
                return passportType.toString() === passportTypes.book.toString() || passportType.toString() === passportTypes.both.toString();
            }
        },
        passportCardLostOrStolen:{
            label: "Passport card lost or stolen?",
            type: TYPES.SELECT,
            values: DS64_SELECT_VALUES.PASSPORT_LOST,
            customDependency: (applicant, path, schemaData) => {
                const passportType = get(applicant, 'passportType');
                return passportType.toString() === passportTypes.card.toString() || passportType.toString() === passportTypes.both.toString();
            }
        },
        isApplicationForNewPassport:{
            label: "Is the applicant submitting this form in connection with an application for a new U.S. passport book and/or card?",
            type: TYPES.CHECKBOX,
        },
        firstName: {
            label: "First name",
            type: TYPES.TEXT,
        },
        lastName: {
            label: "Last name",
            type: TYPES.TEXT,
        },
        middleName: {
            label: "Middle name",
            type: TYPES.TEXT,
        },
        isNameChanged: {
            label: "Is name changed?",
            type: TYPES.CHECKBOX,
        },
        lastPassportFullName:{
            label: "Last passport full name",
            type: TYPES.TEXT,
        },
        birthDate:{
            label: "Date of birth",
            type: TYPES.DATE,
        },
        birthCountry:{
            label: "Country of birth",
            type: TYPES.SELECT,
            values: DS64_SELECT_VALUES.COUNTRIES
        },
        birthStateProvince:{
            label: "State/Province of birth",
            type: TYPES.SELECT,
            values: DS64_SELECT_VALUES.AMERICAN_STATES,
            customDependency: (applicant, path, schemaData) => {
                const country = get(applicant, 'birthCountry');
                return country === "USA";
            }
        },
        birthCity:{
            label: "City of birth",
            type: TYPES.TEXT,
        },
        USSocialSecurityNumber:{
            label: "U.S. Social Security Number",
            type: TYPES.TEXT,
        },
    },
  },
  contactInformation: {
    label: "Contact information",
    type: TYPES.SEGMENT,
    data: {
        userEmail: {
            label: "User email",
            type: TYPES.TEXT,
        },
        phoneNumber:{
            label: "Phone number",
            type: TYPES.NESTED,
            newTable: true,
            nested: PhoneNumberSchema("phoneNumber"),
        },
        additionalPhoneNumbers:{
            label: "Additional phone numbers",
            type: TYPES.ARRAY,
            data:{
                phoneNumber:{
                    label: "Phone number",
                    type: TYPES.NESTED,
                    newTable: true,
                    nested: PhoneNumberSchema("additionalPhoneNumbers.$additionalPhoneNumbers.phoneNumber"),
                },
                label:{
                    label: "Phone label",
                    type: TYPES.SELECT,
                    values: DS64_SELECT_VALUES.PHONE_TYPES,
                    path: "additionalPhoneNumbers.$additionalPhoneNumbers",
                },

            },
        },
        address:{
            label: "Address/Country",
            type: TYPES.NESTED,
            newTable: true,
            nested: AddressSchema("address"),
        },
    },
  },
  passportInformation: {
    label: "Passport information",
    type: TYPES.SEGMENT,
    data:{
        passportLost:{
            label: "Passport lost/stolen information",
            type: TYPES.NESTED,
            newTable: true,
            nested: PassportLostSchema("passportLost"),
        },
        passportBookInfo: {
            label: "Passport book information",
            type: TYPES.NESTED,
            newTable: true,
            nested: PassportBookInfoSchema("passportBookInfo"),
            customDependency: (applicant, path, schemaData) => {
                const passportType = get(applicant, 'passportType');
                return passportType.toString() === passportTypes.book.toString() || passportType.toString() === passportTypes.both.toString();
            }
        },
        passportCardInfo: {
            label: "Passport card information",
            type: TYPES.NESTED,
            newTable: true,
            nested: PassportBookInfoSchema("passportCardInfo"),
            customDependency: (applicant, path, schemaData) => {
                const passportType = get(applicant, 'passportType');
                return passportType.toString() === passportTypes.card.toString() || passportType.toString() === passportTypes.both.toString();
            }
        },
        isOtherPassportLost:{
            label: "Other passport lost/stolen",
            type: TYPES.CHECKBOX,
        },
        otherPassportInfo:{
            label: "Other passport information",
            type: TYPES.NESTED,
            newTable: true,
            nested: PassportOtherInfo("otherPassportInfo"),
        },
        isPassportPoliceReport:{
            label: "Police report",
            type: TYPES.CHECKBOX,
        },
    },
  },
};

