import axios from "../httpClient";

function getAllLogs(params) {
  return axios.get(`event-logs`, { params });
}

function getUserLogs(id, params) {
  return axios.get(`event-logs/user/${id}`, { params });
}

function getApplicantLogs(id, params) {
  return axios.get(`event-logs/applicant/${id}`, { params });
}

function getEventTypes(id, params) {
  return axios.get(`event-logs/event-types`);
}

function createEventLog(data) {
  return axios.post(`event-logs`, data)
}

function exportEventLogsToCSV(params){
  return axios.get(`event-logs/csv`, {params})
}

export default {
    getAllLogs,
    getUserLogs,
    getApplicantLogs,
    getEventTypes,
    createEventLog,
    exportEventLogsToCSV,
};
