const SECRET_PREFIX = 'secret:'

export function parseSecret(value) {
    try {
    if(!value) {
      return {
        isSecret: false,
        value
      }
    } else if (value.startsWith(SECRET_PREFIX)) {
      return {
        isSecret: true,
        secretId: value.replace(SECRET_PREFIX,''),
        value: undefined
      }
    }
    return {
      isSecret: false,
      value
    }
  }catch {
    return {
      isSecret: false,
      value
    }
  }
}