import React from "react";
import "./MessagePopup.scss";
import { Form, Input, Button } from "semantic-ui-react";
import TwillioService from "../../services/twilioService";
import { Subject, from } from "rxjs";
import { debounceTime, filter, switchMap, tap } from "rxjs/operators";
import toastr from "toastr";
import axios from "../../apis/httpClient";

class MessagePopup extends React.Component {
  constructor(props) {
    super(props);
    const { phoneNumber = "", zendeskId = "" } = props;
    this.state = {
      phoneNumber: phoneNumber,
      zendeskID: zendeskId,
      message: "",
      errorPhoneNumber: false,
      errorMessage: false,
      errorZendeskID: false,
      existZendeskID: !!zendeskId,
      existApplicant: !!zendeskId,
      zendeskIDStatus: zendeskId ? "check" : "",
    };

    this.subscriptions = [];
    this.onSearch$ = new Subject();
  }

  componentDidMount() {
    this.initializeZendeskSearch();
  }
  componentWillUnmount() {
    if (this.subscriptionSearch) {
      this.subscriptionSearch.unsubscribe();
    }
  }

  initializeZendeskSearch() {
    this.subscriptionSearch = this.onSearch$
      .pipe(
        tap((zendeskId) => {
          if (!zendeskId) {
            this.setState({
              zendeskIDStatus: "",
              existApplicant: false,
              existZendeskID: false,
            });
          } else {
            this.setState({ zendeskIDStatus: "loading" });
          }
          this.setState({ zendeskID: zendeskId });
        }),
        debounceTime(450),
        filter(Boolean),
        switchMap((zendeskId) =>
          from(axios.get(`/zendesk/ticket/${zendeskId}/check`)),
        ),
      )
      .subscribe(({ data: { zendesk, applicant } }) => {
        this.setState({ existApplicant: applicant, existZendeskID: zendesk });
        if (zendesk) {
          this.setState({ zendeskIDStatus: "check" });
        } else {
          this.setState({ zendeskIDStatus: "delete" });
        }
      });
  }

  sendMessage(event) {
    event.stopPropagation();
    const { phoneNumber, message, zendeskID } = this.state;
    if (!phoneNumber) {
      this.setState({ errorPhoneNumber: true });
      toastr.error("Phone number is required.", "Error");
      return;
    }
    if (!message) {
      this.setState({ errorMessage: true });
      return;
    }
    if (!zendeskID) {
      this.setState({ errorZendeskID: true });
      toastr.error("Zendesk ID does not exist.", "Error");
      return;
    }
    if (phoneNumber && message && zendeskID) {
      TwillioService.sendSMS(phoneNumber, message, {
        zendeskId: zendeskID,
      });
    }
  }

  phoneNumberChanged(value) {
    if (/^\+?[0-9 ]*$/.test(value)) {
      this.setState({
        phoneNumber: value.replace(/ {2}/, " "),
      });
      if (value) {
        this.setState({ errorPhoneNumber: false });
      }
    }
  }

  messageChanged(value) {
    this.setState({ message: value });
    if (value) {
      this.setState({ errorMessage: false });
    }
  }

  zendeskIDChanged(value) {
    if (value) {
      this.setState({ errorZendeskID: false });
    }
  }

  renderMessagePopup() {
    const { phoneNumber, message } = this.state;

    return (
      <div className="sk-message-popup-container">
        <Form className="sk-message-popup-form">
          <div className="sk-dial-pad-input-numbers">
            <Input
              name="phoneNumber"
              value={phoneNumber}
              onChange={(event) => {
                this.setState({ phoneNumber: event.target.value });
                this.phoneNumberChanged(event.target.value);
              }}
              type="type"
              label="Phone number:"
              size="small"
              error={this.state.errorPhoneNumber}
            ></Input>
            <Input
              icon={
                this.state.zendeskIDStatus === "check"
                  ? "check"
                  : this.state.zendeskIDStatus === "delete"
                  ? "delete"
                  : ""
              }
              loading={this.state.zendeskIDStatus === "loading" ? true : false}
              name="zendeskID"
              value={this.state.zendeskID}
              onChange={(event) => {
                this.onSearch$.next(event.target.value);
              }}
              label="Zendesk ID: "
              size="small"
              error={this.state.errorZendeskID}
              ref={this.zendeskIDRef}
            ></Input>
          </div>
          <div className="sk-message-popup-textarea-container">
            <Form.TextArea
              value={message}
              onChange={(event) => this.messageChanged(event.target.value)}
              rows={10}
              placeholder="Text message"
              error={this.state.errorMessage}
            ></Form.TextArea>
          </div>

          <div className="sk-message-button-group">
            <Button
              className="sk-message-send-button"
              onClick={(event) => this.sendMessage(event)}
            >
              Send
            </Button>
            <Button
              className="sk-message-cancel-button"
              onClick={() => this.props.closeModal()}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    );
  }

  render() {
    return (
      <div className="sk-message-popup-modal">{this.renderMessagePopup()}</div>
    );
  }
}

export default MessagePopup;
