import React, { useState, useEffect, useMemo, useContext } from "react";
import { AGENT_SHIFTS } from "../../../../constants/agentShifts";
import {
  ROLES,
  MANAGER_ROLES,
} from "../../../../../../../../../constants/rolesConstants";
import { FILTERED_PRODUCT_NAMES } from "../../../../../Products/constants/productNames";
import FormFields from "../../../../../../../../../components/common/FormFields";
import { UsersContext } from "../../../../../../../../../common/UsersContext";
import { ZENDESK_HOSTS } from "../../../../../MyAccount/constants/zendesk";

const rolesOptions = Object.values(ROLES).map((roleName) => ({
  key: roleName,
  value: roleName,
  text: roleName,
}));

const productOptions = Object.entries(FILTERED_PRODUCT_NAMES).map(
  ([key, value]) => ({
    key,
    value,
    text: key,
  }),
);

const shiftOptions = Object.values(AGENT_SHIFTS).map((shift) => ({
  key: shift,
  value: shift,
  text: shift,
}));

const zendeskHostOptions = [
  {
    value: null,
    key: "empty",
    text: "No zendesk host",
  },
  ...ZENDESK_HOSTS.map(({ link, name }) => ({
    key: name,
    value: link,
    text: name,
  })),
];

const UserForm = ({ userFromParent, isEditing }) => {
  const [availableManagers, setAvailableManagers] = useState(null);
  const [user, setUser] = useState(userFromParent);

  const fields = useMemo(
    () => [
      {
        key: "username",
        type: "input",
        fieldSpecific: {
          required: true,
        },
      },
      {
        key: "email",
        type: "input",
        fieldSpecific: {
          required: true,
        },
      },
      {
        key: "password",
        type: "input",
        fieldSpecific: {
          required: !isEditing,
        },
      },
      {
        key: "firstName",
        type: "input",
        fieldSpecific: {
          required: true,
        },
      },
      {
        key: "lastName",
        type: "input",
        fieldSpecific: {
          required: true,
        },
      },
      {
        key: "zendeskId",
        type: "input",
        fieldSpecific: {
          required: false,
        },
      },
      {
        key: "roles",
        type: "select",
        fieldSpecific: {
          options: rolesOptions,
          multiple: true,
        },
        defaultValue: [],
      },
      {
        key: "shift",
        type: "select",
        fieldSpecific: {
          options: shiftOptions,
          multiple: false,
        },
        defaultValue: null,
      },
      {
        key: "zendeskHost",
        type: "select",
        fieldSpecific: {
          options: zendeskHostOptions,
          multiple: false,
        },
        defaultValue: null,
      },
      {
        key: "products",
        type: "select",
        fieldSpecific: {
          options: productOptions,
          multiple: true,
        },
        defaultValue: Object.values(FILTERED_PRODUCT_NAMES),
      },
      {
        key: "managerId",
        type: "select",
        fieldSpecific: {
          options: availableManagers
            ? [
                {
                  text: "No manager",
                  value: "NO_MANAGER",
                  key: -1,
                },
                ...availableManagers.map((user) => ({
                  value: user._id,
                  text: user.fullName,
                  key: user._id,
                })),
              ]
            : [
                {
                  text: "No manager",
                  value: "NO_MANAGER",
                  key: -1,
                },
              ],
          multiple: false,
        },
        defaultValue: "NO_MANAGER",
      },
    ],
    [availableManagers, isEditing],
  );

  const { getUsersFiltered } = useContext(UsersContext);

  useEffect(() => {
    async function fetchAvailableManagers() {
      const managerRoles = (user || { roles: [] }).roles
        .map((role) => MANAGER_ROLES[role])
        .filter(Boolean);
      if (!managerRoles.length) {
        setAvailableManagers([]);
        return;
      }
      setAvailableManagers(null);
      const managers = getUsersFiltered({ roles: managerRoles });
      setAvailableManagers(managers);
    }
    fetchAvailableManagers();
  }, [user, getUsersFiltered]);

  return (
    <FormFields
      currentItem={userFromParent}
      setCurrentItem={setUser}
      fields={fields}
    />
  );
};

UserForm.defaultProps = {
  userFromParent: {
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    roles: [],
    zendeskHost: null,
    products: Object.values(FILTERED_PRODUCT_NAMES),
    zendeskId: "",
    managerId: null,
  },
};

export default UserForm;
