import httpClient from "../httpClient";

function addAgentApi(ticketId, agentId) {
  return httpClient.post(
    `/department/qa/evaluations/${ticketId}/agent/${agentId}`,
  );
}

function deleteAgentApi(ticketId, agentId) {
  return httpClient.delete(
    `/department/qa/evaluations/${ticketId}/agent/${agentId}`,
  );
}

export default {
  addAgentApi,
  deleteAgentApi,
};
