import React, { createContext, useState, useEffect, useCallback } from "react";
import toastr from "toastr";
import apis from "../apis";


export const ApplicantDocumentsContext = createContext(null);

const ApplicantDocumentsContextProvider = ({ children, applicantId, api }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [applicant, setApplicant] = useState(null);
  const [documentsBots, setDocumentsBots] = useState([]);
  const [documentsAgents, setDocumentsAgents] = useState([]);
  const [documentsClients, setDocumentsClients] = useState([]);
  const [documentsFinancial, setDocumentsFinancial] = useState([]);
  const [documentsBrochures, setDocumentsBrochures] = useState([]);
  const [brochureLanguageOptions, setBrochureLanguageOptions] = useState([])

  const fetchBrochures = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await apis.applicantDocumentHandler.getBrochures(api, applicantId);
      setDocumentsBrochures(data);
    } catch (error) {
      toastr.error("Failed to fetch brochures.");
      console.error(error);
    }

    setIsLoading(false);
  }, [api, applicantId]);

  const fetchBrochureLanguages = useCallback(async () => {
    setIsLoading(true);

    try {
      const {
        data: { languages },
      } = await apis.documentHandler.getBrochureLanguages();
      setBrochureLanguageOptions(languages.map((lng) => ({ text: lng, value: lng })));
    } catch (e) {
      toastr.error("Failed to fetch languages.");
      console.error(e);
    }

    setIsLoading(false);
  }, []);

  const fetchDocumentsAgents = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await apis.applicantDocumentHandler.getDocumentsAgent(api, applicantId);
      setDocumentsAgents(data.documents);
      setApplicant(data.applicant)
    } catch (error) {
      toastr.error("Failed to fetch documents uploaded by agents.");
      console.error(error);
    }

    setIsLoading(false);
  }, [api, applicantId]);

  const fetchDocumentsClients = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await apis.applicantDocumentHandler.getDocumentsClient(api, applicantId);
      setDocumentsClients(data.documents);
      setApplicant(data.applicant)
    } catch (error) {
      toastr.error("Failed to fetch documents uploaded by clients.");
      console.error(error);
    }

    setIsLoading(false);
  }, [api, applicantId]);

  const fetchDocumentsBots = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await apis.applicantDocumentHandler.getDocumentsBots(api, applicantId);
      setDocumentsBots(data.documents);
      setApplicant(data.applicant)
    } catch (error) {
      toastr.error("Failed to fetch documents downloaded by bot.");
      console.error(error);
    }

    setIsLoading(false);
  }, [api, applicantId]);

  const fetchDocumentsFinancial = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await apis.applicantDocumentHandler.getDocumentsFinancial(api, applicantId);
      setDocumentsFinancial(data.documents);
      setApplicant(data.applicant)
    } catch (error) {
      toastr.error("Failed to fetch financial documents.");
      console.error(error);
    }

    setIsLoading(false);
  }, [api, applicantId]);

  const sendDocumentRequest = useCallback(async (selectedDocuments) => {
    try {
      await apis.applicantDocumentHandler.sendDocumentRequest(api, applicantId, selectedDocuments);
      toastr.success("Document request sent successfully.");
    } catch (error) {
      console.error("Failed to send document request", error);
      toastr.error("Failed to send document request.");
    }
  }, [api, applicantId]);
  
  useEffect(() => {
    fetchBrochureLanguages();
    fetchDocumentsClients();
    api !== "inv_letter" && fetchBrochures();
    api !== "inv_letter" && fetchDocumentsAgents();
    api !== "inv_letter" && fetchDocumentsBots();
    api !== "inv_letter" && fetchDocumentsFinancial();
  }, [
    fetchBrochures,
    fetchBrochureLanguages,
    fetchDocumentsAgents,
    fetchDocumentsBots,
    fetchDocumentsClients,
    fetchDocumentsFinancial,
    api,
  ]);

  return (
    <ApplicantDocumentsContext.Provider
      value={{
        applicantId,
        api,
        isLoading,
        setIsLoading,
        applicant,
        setApplicant,
        documentsBots,
        setDocumentsBots,
        documentsAgents,
        setDocumentsAgents,
        documentsClients,
        setDocumentsClients,
        documentsFinancial,
        setDocumentsFinancial,
        documentsBrochures,
        setDocumentsBrochures,
        fetchDocumentsClients,
        brochureLanguageOptions,
        sendDocumentRequest,
        fetchDocumentsAgents,
      }}
    >
      {children}
    </ApplicantDocumentsContext.Provider>
  );
};

export default ApplicantDocumentsContextProvider;
