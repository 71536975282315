import React, { useState, useEffect } from "react";
import moment from "moment";
import { EVENT_NAMES, transformDifference } from "../../common";
import "./PieChart.styles.scss";
import PieChart, {
  Legend,
  Series,
  Label,
  Connector,
  Font,
  Size,
} from "devextreme-react/pie-chart";

const makeLabels = (name, duration) => {
  return (
    name.charAt(0).toUpperCase().bold() +
    name.slice(1).bold() +
    makeDurationLabel(duration)
  );
};

const makeDataForPie = (dataAvailability) => {
  let res = [];
  dataAvailability.forEach((e) => {
    res.push({
      region: makeLabels(e.name, e.duration),
      val: e.duration,
    });
  });
  return res;
};

function customizeText(arg) {
  return `${
    arg.argumentText && arg.argumentText.split(" ")[0]
  } - ${arg.percentText.bold()}`;
}

const makeDurationLabel = (duration) => {
  const label = Object.entries(transformDifference(duration))
    .map(([key, duration]) =>
      duration > 0 ? duration + " " + (duration > 1 ? `${key}s` : key) : "",
    )
    .join(" ");

  return label.length > 4 ? label : " -- / -- / -- ";
};

const Pie = ({ reports, firstDate, lastDate }) => {
  const [activityTotals, setActivityTotals] = useState();

  useEffect(() => {
    if (reports && reports.length) {
      /** Firstly, we have to calculate the duration of the event before the first
       * report, because first report doesnt always start from 00:00
       * so we need to calculate duration between first report and firstDate
       */
      const eventInd = Object.values(EVENT_NAMES).findIndex((event) =>
        (
          (reports[0].data && reports[0].data.previousAvailabilityStatus) ||
          EVENT_NAMES.OFFLINE
        )
          .toLowerCase()
          .includes(event.toLowerCase()),
      );

      const reportsTransformed = Object.values(EVENT_NAMES).map((status) => ({
        name: status,
        duration: 0,
      }));

      reportsTransformed[eventInd].duration +=
        reports[0].createdAt - moment(firstDate);

      /** Gets duration of the events in representable array for the pie chart
       * which is array of numbers
       * @returns {number[]}, e.g. [66425555, 75122961, 455783107], each number
       * represents a duration(in miliseconds) of each event(events are: available,
       *  unavailable, offline)
       */
      reports.forEach((report, ind) => {
        const nextReport = reports[ind + 1];
        /** we are calculating duration based on the next report */
        const val = nextReport
          ? nextReport.createdAt - report.createdAt
          : /** if there is no nextReport we use the lastDate */
            moment(lastDate) - report.createdAt;

        if (
          reportsTransformed[
            Object.values(EVENT_NAMES).findIndex(
              (event) => report.name === event,
            )
          ]
        )
          reportsTransformed[
            Object.values(EVENT_NAMES).findIndex(
              (event) => report.name.split(" ")[0].toLowerCase() === event,
            )
          ].duration += val;
      });
      setActivityTotals(reportsTransformed);
    }
  }, [firstDate, lastDate, reports]);

  return (
    reports &&
    !!reports.length && (
      <PieChart
        type="doughnut"
        palette={["#54ab13", "#e3b207", "#6f6f6f"]}
        innerRadius={0.65}
        dataSource={activityTotals ? makeDataForPie(activityTotals) : []}
      >
        <Size height={300} />
        <Series argumentField="region" width={100}>
          <Label
            visible={true}
            position="columns"
            customizeText={customizeText}
          >
            <Font size={16} />
            <Connector visible={true} width={0.5} />
          </Label>
        </Series>
        <Legend
          margin={0}
          horizontalAlignment="right"
          verticalAlignment="middle"
        >
          <Font size={16} />
        </Legend>
      </PieChart>
    )
  );
};

export default Pie;
