import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Icon, Menu, Dropdown } from "semantic-ui-react";
import { useSelector } from "react-redux";
import Spinner from "../../../Spinner";
import "./TopMenu.styles.scss";
import { OnlineAgents, TwilioInitMenu, Notifications } from "..";
import classNames from "classnames";
import { CredentialsService } from "../../../../services/credentialsService";
import BotMonitoring from "../BotMonitoring";

export const setRootClassAttribute = (rootClass) => {
  var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
  root.setAttribute("class", rootClass);
};

const TopMenu = ({ setSideMenuExtended, setSideBarVisible }) => {
  const [width, setWidth] = useState(window.innerWidth);

  const { user } = useSelector((state) => ({
    user: state.currentUser,
  }));

  const updateScreenWidth = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", updateScreenWidth);

    return function cleanup() {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, [updateScreenWidth]);

  useEffect(() => {
    user && setRootClassAttribute(user.settings.activeTheme);
  }, [user]);

  const renderTopMenu = useMemo(() => {
    const isDesktop = width > 930;
    const toggleSide = isDesktop ? setSideMenuExtended : setSideBarVisible;

    if (user === null) {
      return <Spinner />;
    } else {
      return (
        <Menu
          fixed="top"
          className={`top-menu${
            CredentialsService.isSimulation() ? " top-menu--simulation" : ""
          }`}
        >
          <Menu.Item
            className="top-menu__no-border"
            onClick={() => toggleSide((state) => !state)}
          >
            <Icon name="bars" />
          </Menu.Item>

          {isDesktop ? (
            <>
              <Menu.Item className="top-menu__no-border">
                {!CredentialsService.isSimulation() && (
                  <TwilioInitMenu></TwilioInitMenu>
                )}
              </Menu.Item>

              <div className="top-menu__header-right">
                {CredentialsService.isSimulation() && (
                  <div className="simulation-header">
                    <Icon name="user circle" size="large" color="teal" />
                    <div className="simulation-header__title">
                      User simulation
                    </div>
                  </div>
                )}
                <Menu.Item>
                  <BotMonitoring />
                </Menu.Item>
                {user.roles.length > 0 && (
                  <Menu.Item header className="top-menu__header-right__item">
                    <OnlineAgents />
                  </Menu.Item>
                )}

                <Menu.Item header className="top-menu__header-right__item">
                  <Notifications />
                </Menu.Item>

                <Menu.Item header className="top-menu__header-right__logged-as">
                  Logged in as:
                  <span className="top-menu__header-right__logged-as__user">
                    {user.username}
                  </span>
                </Menu.Item>
              </div>
            </>
          ) : (
            <div
              className={classNames(
                "top-menu__header-right",
                "top-menu__header-right--mobile",
              )}
            >
              <Notifications />
              <Dropdown
                direction="left"
                button
                basic
                trigger={
                  <span className="top-menu__header-right__logged-as">
                    Logged in as:
                    <span className="top-menu__header-right__logged-as__user">
                      {user.username}
                    </span>
                  </span>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item onClick={(e) => e.stopPropagation()}>
                    <TwilioInitMenu />
                  </Dropdown.Item>

                  <Dropdown.Item onClick={(e) => e.stopPropagation()}>
                    {user.roles.length > 0 && <OnlineAgents />}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => e.stopPropagation()}>
                    <BotMonitoring />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </Menu>
      );
    }
  }, [setSideBarVisible, setSideMenuExtended, width, user]);

  return renderTopMenu;
};

export default TopMenu;
