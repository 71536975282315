import { useState, useCallback } from "react";

export function useAccordionIndexes(initialActiveAccordionIndexes = [1, 1]) {
  const [activeAccordionIndexes, setActiveAccordionIndexes] = useState(
    initialActiveAccordionIndexes,
  );

  const handleAccordionClick = useCallback(
    (e, titleProps) => {
      const { index } = titleProps;

      const newActiveAccordionIndexes = [...activeAccordionIndexes];
      newActiveAccordionIndexes[index] =
        activeAccordionIndexes[index] === 1 ? -1 : 1;

      setActiveAccordionIndexes(newActiveAccordionIndexes);
    },
    [activeAccordionIndexes],
  );

  return {
    activeAccordionIndexes,
    handleAccordionClick,
  };
}
