import React from "react";
import { Table, Checkbox } from "semantic-ui-react";
import { ALLOWED_REFERRAL_SITES } from "../../../../../EVisa/pages/ApplicantsEdit/components/constants";

const SGReviewAndCertification = ({ referralSite = ALLOWED_REFERRAL_SITES.DEFAULT }) => {
  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell colSpan="2">
          <div>
            <p>
              <b>Consent And Declaration</b>
              <b>Applicant's Declaration</b>
            </p>
            <p>
              I hereby confirm that the information I have provided herein,
              including my local contact and residential details, is true,
              accurate, and complete to the best of my knowledge and belief.
            </p>
            <p>
              I understand that the truth and accuracy of the information
              submitted in this form is my responsibility. Should there be any
              change to the information provided, I undertake to inform the
              Immigration & Checkpoints Authority ("Authority"), by submitting a
              fresh declaration. I also undertake to update the information
              required under this form if requested to do so by the Authority.
            </p>
            <p>
              I understand that if I provide any false, inaccurate, or
              incomplete information in this form, I may be held liable to
              offences related to such action.
            </p>

            <p>
              <b>Consent to data sharing with other government agencies:</b>
            </p>
            <p>I consent, for the information I have provided herein,</p>
            <p>
              a. For the Government of Singapore and statutory authorities to
              obtain from and verify information (including my medical records
              and information relating to them) with any person, organisation or
              any other source, and,
            </p>
            <p>
              b. To the release of all information obtained (including my
              medical records and information relating to them) to the
              Government of Singapore, statutory authorities and their agents.
            </p>

            <p>
              <b>
                If you are submitting the arrival card on behalf of someone else
                (the "Principal"):
              </b>
            </p>
            <p>
              I undertake that my Principal is aware of the contents of my
              submission and that it will be taken to have been personally
              submitted by him/her. My Principal is aware that he/she may be
              liable for offence(s) concerning any false, inaccurate, or
              incomplete statement or declaration contained in the arrival card.
              I also understand that I may be liable for abetting these
              offences.
            </p>
          </div>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell colSpan="2">
          <h5>Third Party Authorization</h5>
          <p>
            I understand that I am applying via GovAssist, LLC; a third-party
            that is not affiliated with the government, that the third-party
            cannot guarantee application approval, that the third-party service
            is offered to help expedite the process, and that I have the option
            to apply directly with the pertinent government websites. I also
            understand that my purchase consists of a processing and service fee
            to GovAssist.
          </p>
          <p>
            I confirm and authorize GovAssist, LLC to assist me, by using their
            processing service in completing and reviewing my application. I
            understand and acknowledge that I have not been offered legal advice
            and that this company or its agents and affiliates have never
            claimed to be qualified to offer legal advice under any
            circumstance. I also confirm that I am submitting my application and
            signing it myself.
          </p>
          <p>
            By obtaining services from the GovAssist website, I have agreed to
            the following:{" "}
            <a href={`https://${referralSite}/terms-conditions`}>
              Terms & Conditions
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/refund-policy`}>
              Refund Policy{" "}
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/privacy-policy`}>
              Privacy Policy{" "}
            </a>{" "}
            |{" "}
            <a href={`https://${referralSite}/legal-disclaimer`}>
              Disclaimer{" "}
            </a>
          </p>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>AGREE</Table.Cell>
        <Table.Cell>
          <Checkbox checked={true} />
        </Table.Cell>
      </Table.Row>
    </React.Fragment>
  );
};

export default SGReviewAndCertification;
