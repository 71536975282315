import React from "react";
import { Icon } from "semantic-ui-react";
import TimeLine from "../ApplicantNotes/_components/TimeLine.component";

const ApplicantPinnedNotes = ({ pinnedApplicantNotes, handlePinNote }) => {
  const [isPinnedNotesOpen, setIsPinnedNotesOpen] = React.useState(true);

  return (
    <div className="appn-timeline-pinned-notes">
      <h3 className="appn-timeline-pinned-header">
        <div className="ui appn-timeline-pinned-title">
          <Icon name="pin" size="small" color="blue" />
          Pinned Notes
        </div>
        <button
          className="appn-timeline-pinned-button"
          onClick={() => setIsPinnedNotesOpen(!isPinnedNotesOpen)}
        >
          {isPinnedNotesOpen ? (
            <Icon name="angle up" inverted circular size="small" color="blue" />
          ) : (
            <Icon name="angle down" inverted circular size="small" color="blue" />
          )}
        </button>
      </h3>
      {isPinnedNotesOpen ? (
        <TimeLine
          notes={pinnedApplicantNotes}
          handlePinNote={handlePinNote}
          showPins={false}
        />
      ) : <TimeLine notes={pinnedApplicantNotes} handlePinNote={handlePinNote} showPins={false} maxNotes={1} />}
    </div>
  );
};

export default ApplicantPinnedNotes;
