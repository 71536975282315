import React from 'react';
import { Button } from "semantic-ui-react";


const SecretField = ({reveledSecret,isLoading,onEditClick,onRevealSecret, allowEdit = true}) => {
    return (
      <div className='secretContainer'>
        <span>{reveledSecret ? reveledSecret : '******************'}</span>
        {reveledSecret ? null 
        : <Button disabled={isLoading} color='orange' onClick={onRevealSecret}>
          {
            isLoading ? 'Revealing...' : 'Reveal Secret'
          }
        </Button>}
        { 
          allowEdit ? (
            <Button disabled={isLoading} onClick={onEditClick}>Edit Secret</Button>
          ) : null
        }
      </div>
    )
}
export default SecretField