import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { Loader } from "semantic-ui-react";
import auth from "../../apis/auth";
import { AuthService } from "../../services/authService";
import { LocalStorageService } from "../../services/localStorageService";
import { FETCH_CURRENT_USER } from "../../store/actions/actionTypes";

const SocialLogin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const socialLogin = useCallback(async () => {
    try {
      const {
        data: { user },
      } = await auth.getAuthUser();
      LocalStorageService.setItem("user", user);
      dispatch({
        type: FETCH_CURRENT_USER,
        payload: user,
      });
    } catch (e) {
      console.error(e);
    }
  }, [dispatch]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("twoFactorAuthEnabled")) {
      LocalStorageService.setItem("userId", query.get("userId"));
      LocalStorageService.setItem("isConfiguredTwoFactorAuth", query.get("isConfiguredTwoFactorAuth"));
      history.push("/two-factor-authentication");
    }
    if (query.get("token")) {
      AuthService.saveLoginTokensToStorage({
        token: query.get("token"),
        twilioToken: query.get("twilioToken"),
        refreshToken: query.get("refreshToken"),
      });
      socialLogin();
    }
  }, [history, location.search, socialLogin]);

  return <Loader active />;
};

export default SocialLogin;
