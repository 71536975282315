import React from "react";
import DashboardRouter from "./routers/DashboardRouter";
import LoginForm from "../pages/Login";
import EnhancedRoute from "./components/EnhancedRoute";
import { Switch, Redirect, Route } from "react-router-dom";
import { history } from "../modules/history";
import { Router } from "react-router-dom";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword.page";
import SocialLogin from "../pages/SocialLogin/SocialLogin.page";
import TwoFactorAuthentication from "../pages/TwoFactorAuthentication";
import ZendeskDashboardRouter from "./routers/ZendeskDashboardRouter";

export const routes = [
  {
    path: "/login",
    onlyPublic: true,
    component: LoginForm,
  },
  {
    path: "/forgot-password",
    onlyPublic: true,
    authorized: false,
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    onlyPublic: true,
    authorized: false,
    component: ResetPassword,
  },
  {
    path: "/social-login",
    onlyPublic: true,
    authorized: false,
    component: SocialLogin,
  },
  {
    path: "/two-factor-authentication",
    onlyPublic: true,
    authorized: false,
    component: TwoFactorAuthentication,
  },
  {
    path: "/zendesk",
    exact: false,
    authorized: true,
    component: ZendeskDashboardRouter,
    zendeskView: true,
  },
  {
    path: "/zendesk-login",
    onlyPublic: true,
    component: LoginForm,
    componentProps: { zendeskView: true }
  },
  {
    path: "",
    exact: false,
    authorized: true,
    component: DashboardRouter,
  },
  {
    path: "*",
    component: () => <Redirect to="" />,
  },
];

const Routes = () => (
  <Router history={history}>
    <Switch>
      {routes.map(({ path, exact = true, ...rest }) => (
        <Route
          key={path}
          path={path}
          exact={exact}
          render={(props) => <EnhancedRoute {...props} {...rest} />}
        />
      ))}
    </Switch>
  </Router>
);

export default Routes;
