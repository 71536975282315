import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  ROLES,
  areOnlyAgentRolesWithoutBilling,
} from "../../constants/rolesConstants";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthService } from "../../services/authService";
import { CredentialsService } from "../../services/credentialsService";
import { emitLogout } from "../../events";

const EnhancedRoute = (props) => {
  const {
    authorized,
    location,
    authorizedRoles,
    onlyPublic,
    component: Component,
    componentProps,
    product,
    zendeskView,
    ...rest
  } = props;

  const {
    auth: { loggedIn },
    currentUser,
  } = useSelector(({ auth, currentUser }) => ({
    auth,
    currentUser,
  }));
  const finalRoute = useMemo(() => {
    if ((authorized || authorizedRoles) && !loggedIn) {
      return (
        <Redirect
          to={{
            pathname: zendeskView ? "/zendesk-login" : "/login",
            state: { from: location },
          }}
        />
      );
    }

    if (onlyPublic && loggedIn) {
      return <Redirect to="/" />;
    }

    if (
      currentUser?.products?.length &&
      product &&
      areOnlyAgentRolesWithoutBilling(currentUser.roles)
    ) {
      if (!currentUser.products.includes(product)) {
        return <Redirect to={`/${currentUser.products[0]}`} />;
      }
    }

    if (authorizedRoles) {
      const user = CredentialsService.getUser();

      const userAllowed = AuthService.checkRolesForUser(user, authorizedRoles);
      if (!userAllowed) {
        emitLogout();
      }
    }

    return <Component {...rest} {...componentProps} />;
  }, [
    authorized,
    authorizedRoles,
    loggedIn,
    onlyPublic,
    location,
    rest,
    componentProps,
    currentUser,
    product,
    zendeskView,
  ]);

  return finalRoute;
};

EnhancedRoute.propTypes = {
  exact: PropTypes.bool,
  authorized: PropTypes.bool,
  onlyPublic: PropTypes.bool,
  authorizedRoles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(Object.values(ROLES))),
    PropTypes.oneOf(Object.values(ROLES)),
  ]),
  component: PropTypes.elementType,
  componentProps: PropTypes.object,
};

EnhancedRoute.defaultProps = {
  authorized: false,
  exact: true,
  onlyPublic: false,
};

export default EnhancedRoute;
