import { combineReducers } from "redux";
import schemaDataReducer from "./schemaData";
import currentUserReducer from "./currentUser";
import authReducer from "./auth";
import productConfigReducer from "./productConfig";

export default combineReducers({
  schemaData: schemaDataReducer,
  currentUser: currentUserReducer,
  auth: authReducer,
  productConfig: productConfigReducer,
});
