import React, { useEffect, useState, useMemo } from "react";
import { Chart, PerformanceTrendQAReport } from "..";
import "./DisputeQAReport.styles.scss";
import { QA_EVALUATION_TICKET_DISPUTE_STATUS } from "../../../../../../constants/qaTicketStatuses";
import { QA_REPORTS } from "../../../../constants/qaReports";

const DISPUTE_STATUS_LABEL_MAPPING = {
  [QA_EVALUATION_TICKET_DISPUTE_STATUS.RESOLVED_POSITIVE]: [
    "Disputed",
    "Positive",
  ],
  [QA_EVALUATION_TICKET_DISPUTE_STATUS.RESOLVED_NEGATIVE]: [
    "Disputed",
    "Negative",
  ],
  [QA_EVALUATION_TICKET_DISPUTE_STATUS.NOT_RESOLVED]: [
    "Disputes",
    "Still Open",
  ],
  [QA_EVALUATION_TICKET_DISPUTE_STATUS.UNRELATED_DISPUTE]: [
    "Unrelated",
    "Disputes",
  ],
};

const DisputeQAReport = ({
  total,
  totalSeparated,
  totalDisputed,
  totalDisputedSeparated,
  disputedCountByStatus,
  disputedCountByStatusSeparated,
  afterDisputeReport = false,
  chartConfig,
}) => {
  const [openPerformanceTrend, setOpenPerformanceTrend] = useState(false);
  const [chartData, setChartData] = useState({
    id: "",
    values: [],
    labels: [],
  });
  const hasDisputeStatusData = useMemo(
    () => disputedCountByStatus && disputedCountByStatus.length > 0,
    [disputedCountByStatus],
  );

  useEffect(() => {
    let values = [];
    let labels = [];
    let id = "";
    if (afterDisputeReport && hasDisputeStatusData) {
      id = QA_REPORTS.AFTER_DISPUTES_REPORT;

      disputedCountByStatus.forEach((dispute) => {
        labels.push(DISPUTE_STATUS_LABEL_MAPPING[dispute.status]);
        values.push(dispute.count);
      });
    } else if (total && totalDisputed) {
      labels = [
        ["Disputed", "Reviews"],
        ["Non-Disputed", "Reviews"],
      ];
      id = QA_REPORTS.DISPUTES_REPORT;
      values = [totalDisputed, total - totalDisputed];
    }

    setChartData({ id, values, labels });
  }, [
    afterDisputeReport,
    hasDisputeStatusData,
    disputedCountByStatus,
    total,
    totalDisputed,
  ]);

  const qaSeparatedData = useMemo(
    () =>
      afterDisputeReport
        ? disputedCountByStatusSeparated?.reduce(
            (ret, { status, date, count }) => {
              if (status) {
                ret = {
                  ...ret,
                  [status]: ret?.hasOwnProperty(status)
                    ? [...ret[status], ...[{ date, count }]]
                    : [{ date, count }],
                };
              }

              return ret;
            },
            {},
          )
        : {
            Disputed: totalDisputedSeparated,
            "Non Disputed": totalSeparated,
          },
    [
      afterDisputeReport,
      disputedCountByStatusSeparated,
      totalSeparated,
      totalDisputedSeparated,
    ],
  );

  return (
    <div
      className="dispute-qa-report"
      title="Show Performance Trend"
      onClick={() => setOpenPerformanceTrend(true)}
    >
      {(total && totalDisputed) ||
      (afterDisputeReport && hasDisputeStatusData) ? (
        <>
          <Chart
            chartId={chartData.id}
            chartConfig={chartConfig}
            chartData={chartData.values}
            chartLabels={chartData.labels}
          />
          <PerformanceTrendQAReport
            open={openPerformanceTrend}
            setOpen={setOpenPerformanceTrend}
            qaReportData={qaSeparatedData}
            performanceTrendName="dispute qa report"
          />
        </>
      ) : (
        <h4>No Dispute Data</h4>
      )}
    </div>
  );
};

export default DisputeQAReport;
