import React, { useCallback, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import CustomSelect from "../../../../../../../../../../components/common/CustomSelect";
import { getConsulatesList } from "../../../../../../../../../../apis/interviewsApi";

const CheckTypeCForm = ({ applicationCountry, obj }) => {
  const [item, setItem] = useState(obj);
  const [consulateOptions, setConsulateOptions] = useState([]);

  const fetchConsulates = useCallback(async (country) => {
    try {
      const { data } = await getConsulatesList(country);
      setConsulateOptions(
        data.map((consulate) => ({ value: consulate, text: consulate })),
      );
      setItem({usConsulate: data[0]})
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    applicationCountry && fetchConsulates(applicationCountry);
  }, [applicationCountry, fetchConsulates]);

  const changeCurrentItemPropertyValue = useCallback((property, value) => {
    setItem((currItem) => ({ ...currItem, [property]: value }));
  }, []);

  return (
    <div>
      <Form.Field>
        <label>U.S. Consulate</label>
        <CustomSelect
          item={item}
          setItem={setItem}
          changeCurrentItemPropertyValue={changeCurrentItemPropertyValue}
          itemPropName="usConsulate"
          options={consulateOptions}
          itemProp={item.usConsulate}
        />
      </Form.Field>
    </div>
  );
};

CheckTypeCForm.defaultProps = {
  obj: {
    usConsulate: "",
  }
};

export default CheckTypeCForm;
