import axios from "../../httpClient";

export function getQATicketsApi(filters) {
  return axios.get(`/department/qa/evaluations`, { params: filters });
}

export function getQATicketByIdApi(ticketId) {
  return axios.get(`/department/qa/evaluations/${ticketId}`);
}

export function updateQATicketApi(ticketId, updatedTicket) {
  return axios.patch(`/department/qa/evaluations/${ticketId}`, updatedTicket);
}

export function updateQATicketStatusApi(ticketId, newStatus) {
  return axios.put(`/department/qa/evaluations/${ticketId}/status`, {
    status: newStatus,
  });
}

export function getAgentsQATickets(filters) {
  return axios.get(`/department/agent/evaluations`, { params: filters });
}

export function getAgentsQATicketByIdApi(ticketId) {
  return axios.get(`/department/agent/evaluations/${ticketId}`);
}

/**
 *
 * @param {string} ticketId
 * @param {string|null} agentId if null it will assign to current logged in agent
 */
export function assignQATicketApi(ticketId, agentId = null) {
  return axios.post(`/department/qa/evaluations/${ticketId}/assigned-agent`, {
    agentId,
  });
}

export function acknowledgeTicketApi(ticketId) {
  return axios.post(`/department/qa/evaluations/${ticketId}/acknowledge`);
}

export function openTicketDisputeApi(ticketId, disputeBody) {
  return axios.post(
    `/department/qa/evaluations/${ticketId}/disputes`,
    disputeBody,
  );
}

export function resolveTicketDisputeApi(disputeId, disputeResolutionBody) {
  return axios.post(
    `/department/qa/evaluations/disputes/${disputeId}`,
    disputeResolutionBody,
  );
}

export function getTicketDisputesApi(filters) {
  return axios.get("/department/qa/evaluations/disputes", {
    params: filters,
  });
}
