import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Segment,
  Header,
  Modal,
  Table,
  Button,
  Popup,
  Select,
  Form,
  Loader,
} from "semantic-ui-react";
import Spinner from "../../../../../../../components/Spinner";
import apis from "../../../../../../../apis";
import { getDateFormat } from "../../../../../../../common/date-format";
import toastr from "toastr";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import AddNewBrochureModal from "./components/AddNewBrochureModal";
import ModalForm from "../../../../../../../components/common/ModalForm";
import { byteArrayToUrl } from "../../../../../../../utils";

import "./DocumentHandler.styles.scss";

const DocumentHandler = () => {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [brochures, setBrochures] = useState([]);
  const [languageOptions, setLanguageOptions] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [editingBrochure, setEditingBrochure] = useState("");

  const fileInputRef = useRef(null);

  const fetchLanguages = useCallback(async () => {
    setIsPageLoading(true);

    try {
      const {
        data: { languages },
      } = await apis.documentHandler.getBrochureLanguages();
      setLanguageOptions(languages.map((lng) => ({ text: lng, value: lng })));
    } catch (e) {
      toastr.error("Failed to fetch languages.");
      console.error(e);
    }

    setIsPageLoading(false);
  }, []);

  const fetchBrochures = useCallback(async () => {
    setIsPageLoading(true);

    try {
      const { data } = await apis.documentHandler.getBrochures(
        selectedLanguage,
        selectedType,
      );
      setBrochures(data);
    } catch (error) {
      toastr.error("Failed to fetch brochures.");
      console.error(error);
    }

    setIsPageLoading(false);
  }, [selectedLanguage, selectedType]);

  const handleDeleteBrochure = useCallback(async (brochureId, id) => {
    setIsRequestLoading(true);

    try {
      const { data } = await apis.documentHandler.deleteBrochureVersion(
        brochureId,
        id,
      );
      setBrochures((prev) => prev.map((el) => (el.id !== data.id ? el : data)));
      toastr.success("Successfully deleted brochure.");
    } catch (error) {
      toastr.error("Failed to delete brochure.");
      console.error(error);
    }

    setIsRequestLoading(false);
  }, []);

  const handleSetActiveBrochure = useCallback(async (brochureId, id) => {
    setIsRequestLoading(true);

    try {
      const { data } = await apis.documentHandler.updateActiveVersion(
        brochureId,
        id,
      );
      setBrochures((prev) => prev.map((el) => (el.id !== data.id ? el : data)));
      toastr.success("Successfully set brochure as active.");
    } catch (error) {
      toastr.error("Failed to set brochure as active.");
      console.error(error);
    }

    setIsRequestLoading(false);
  }, []);

  const handleOnUploadFileClick = useCallback((id) => {
    setActiveId(id);
    fileInputRef.current.click();
  }, []);

  const handleUploadBrochure = useCallback(
    async (brochureId, file) => {
      if (brochureId) {
        const brochure = brochures.find(({ id }) => id === brochureId);

        setIsRequestLoading(true);

        try {
          const { data } = await apis.documentHandler.addBrochure(
            brochure.name,
            file,
            brochure.language,
            brochure.visaType,
          );
          setBrochures((prev) =>
            prev.map((el) => (el.id !== data.id ? el : data)),
          );
          toastr.success("Successfully added new brochure.");
        } catch (error) {
          toastr.error("Failed to add new brochure.");
          if (error?.response?.data?.message) {
            toastr.error(error.response.data.message);
          }
          console.error(error);
        }

        setIsRequestLoading(false);
      }
    },
    [brochures],
  );

  const handleOnFileChange = useCallback(
    (event) => {
      if (activeId) {
        const file = event.target.files[0];
        handleUploadBrochure(activeId, file);
      }
      fileInputRef.current.value = null;
    },
    [activeId, handleUploadBrochure],
  );

  const handleDeleteDocumentAllVersions = useCallback(async (id) => {
    setIsRequestLoading(true);
    try {
      await apis.documentHandler.deleteBrochure(id);
      setBrochures((prev) => prev.filter((el) => el.id !== id));
      toastr.success(
        "Successfully deleted document and all previous versions.",
      );
    } catch (error) {
      toastr.error("Failed to delete document.");
      console.error(error);
    }
    setIsRequestLoading(false);
  }, []);

  const handleEditBrochure = useCallback(async (id, brochure) => {
    setIsRequestLoading(true);
    try {
      await apis.documentHandler.updateBrochure(id, brochure);
      setBrochures((prev) => prev.map((el) => (el.id === id ? {...el, ...brochure} : el)));
      toastr.success("Successfully edited document.");
    } catch (error) {
      toastr.error("Failed to edit document.");
      console.error(error);
    }
    setIsRequestLoading(false);
  }, []);

  const handleAddNewLanguage = useCallback(async (language) => {
    setIsRequestLoading(true);

    try {
      await apis.documentHandler.addNewLanguage(language);
      setLanguageOptions((prev) => [
        ...prev,
        { text: language, value: language },
      ]);
      toastr.success("Successfully added new language.");
    } catch (e) {
      toastr.error("Failed to add new language.");
      console.error(e);
    }

    setIsRequestLoading(false);
  }, []);

  const openBrochure = useCallback(async (key) => {
    try {
      setIsRequestLoading(key)
      const {
        data: {
          buffer: { data },
        },
      } = await apis.applicantDocumentHandler.getFile(key);
      const url = byteArrayToUrl(data, "application/pdf");
      window.open(url, "_blank");
    } catch (e) {
      console.error(e);
      toastr.error("Failed to open brochure");
    } finally {
      setIsRequestLoading(false)
    }
  }, []);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  useEffect(() => {
    fetchBrochures();
  }, [fetchBrochures]);

  return (
    <Segment.Group>
      <Segment className="tableTopSegment" inverted>
        <Header className="tableTitle" size="large" floated="left">
          <h3>Brochures</h3>
          <div className="brochure-actions">
            <Select
              placeholder="Select language"
              value={selectedLanguage}
              options={languageOptions || []}
              onChange={(ev, field) => setSelectedLanguage(field.value)}
            />
            <Select
              placeholder="Select visa type"
              value={selectedType}
              options={[
                { text: "VISA", value: "VISA" },
                { text: "ESTA", value: "ESTA" },
              ]}
              onChange={(ev, field) => setSelectedType(field.value)}
            />
            <ModalForm
              headerText="Add new language"
              loaderText="Preparing data..."
              onSubmit={(result) => handleAddNewLanguage(result.language)}
              closeOnSubmit
              trigger={<Button type="button">Add new language</Button>}
            >
              <Form.Field>
                <Form.Input type="input" name="language" label="New language" />
              </Form.Field>
            </ModalForm>
            <AddNewBrochureModal languages={languageOptions} setBrochures={setBrochures} />
          </div>
        </Header>
      </Segment>
      <Segment>
        {isPageLoading ? (
          <Spinner />
        ) : (
          <>
            <input
              ref={fileInputRef}
              type="file"
              multiple={false}
              accept=".pdf"
              hidden
              onChange={handleOnFileChange}
            />
            <Table celled striped fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>Document name</Table.HeaderCell>
                  <Table.HeaderCell width={1}>
                    Document language
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1}>
                    Visa Type
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1}>Uploaded date</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {brochures.length === 0 && (
                  <Table.Row>
                    <Table.Cell width={1}>No documents</Table.Cell>
                  </Table.Row>
                )}
                {brochures.length > 0 &&
                  brochures.map(
                    ({
                      id: brochureId,
                      name,
                      language,
                      visaType,
                      activeVersion,
                      previousVersions,
                    }) => {
                      return (
                        <Fragment key={brochureId}>
                          <Table.Row>
                            <Table.Cell>{name}</Table.Cell>
                            <Table.Cell>{language}</Table.Cell>
                            <Table.Cell>{visaType}</Table.Cell>
                            <Table.Cell>
                              {getDateFormat(activeVersion.dateUploaded)}
                            </Table.Cell>
                            <Table.Cell>
                              <Popup
                                content="Upload file"
                                position="top center"
                                trigger={
                                  <Button
                                    loading={
                                      brochureId === activeId &&
                                      isRequestLoading
                                    }
                                    color="blue"
                                    icon="upload"
                                    disabled={
                                      isRequestLoading ||
                                      previousVersions.length >= 5
                                    }
                                    onClick={() =>
                                      handleOnUploadFileClick(brochureId)
                                    }
                                  />
                                }
                              />
                              <Popup
                                content="Preview file"
                                position="top center"
                                trigger={
                                  isRequestLoading === activeVersion.brochureKey ? (
                                    <Loader inline active />
                                  ) : (
                                    <Button
                                      as="a"
                                      color="yellow"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      disabled={!activeVersion.brochureKey || isRequestLoading}
                                      icon="file"
                                      onClick={() => openBrochure(activeVersion.brochureKey)}
                                    />
                                  )
                                }
                              />

                              <Modal
                                closeIcon
                                className="brochures_archive_modal"
                                trigger={
                                  <Button
                                    color="orange"
                                    type="button"
                                    icon="archive"
                                    disabled={!previousVersions.length}
                                  />
                                }
                              >
                                <Modal.Header>{`${name} - Previous versions`}</Modal.Header>
                                <Modal.Content>
                                  <Table celled striped fixed>
                                    <Table.Header>
                                      <Table.Row>
                                        <Table.HeaderCell width={2}>
                                          Preview
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={1}>
                                          Uploaded date
                                        </Table.HeaderCell>
                                        <Table.HeaderCell width={1}>
                                          Actions
                                        </Table.HeaderCell>
                                      </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                      {previousVersions.length > 0 ? (
                                        previousVersions.map(
                                          ({ id, url, dateUploaded }) => {
                                            return (
                                              <Table.Row key={id}>
                                                <Table.Cell width={1}>
                                                  <a
                                                    href={url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    Document
                                                  </a>
                                                </Table.Cell>
                                                <Table.Cell width={1}>
                                                  {getDateFormat(dateUploaded)}
                                                </Table.Cell>
                                                <Table.Cell width={1}>
                                                  <Popup
                                                    content="Set as active version"
                                                    position="top center"
                                                    trigger={
                                                      <Button
                                                        color="yellow"
                                                        disabled={
                                                          isRequestLoading
                                                        }
                                                        type="button"
                                                        icon="star"
                                                        onClick={() =>
                                                          handleSetActiveBrochure(
                                                            brochureId,
                                                            id,
                                                          )
                                                        }
                                                      />
                                                    }
                                                  />
                                                  <ConfirmPopup
                                                    content="Are you sure you want to delete brochure version?"
                                                    callback={() => {
                                                      handleDeleteBrochure(
                                                        brochureId,
                                                        id,
                                                      );
                                                    }}
                                                  >
                                                    <Button
                                                      color="red"
                                                      type="button"
                                                      disabled={
                                                        isRequestLoading
                                                      }
                                                      icon="trash"
                                                    />
                                                  </ConfirmPopup>
                                                </Table.Cell>
                                              </Table.Row>
                                            );
                                          },
                                        )
                                      ) : (
                                        <Table.Row>
                                          <Table.Cell width={1}>
                                            No previous versions
                                          </Table.Cell>
                                        </Table.Row>
                                      )}
                                    </Table.Body>
                                  </Table>
                                </Modal.Content>
                              </Modal>

                              <ModalForm
                                headerText="Edit brochure"
                                loaderText="Preparing data..."
                                onSubmit={(result) =>
                                  handleEditBrochure(
                                    brochureId,
                                    editingBrochure,
                                  )
                                }
                                closeOnSubmit
                                onOpen={() =>
                                  setEditingBrochure({ name, language, visaType })
                                }
                                submitButtonText="Save"
                                trigger={
                                  <Button
                                    color="green"
                                    type="button"
                                    disabled={isRequestLoading}
                                    icon="edit"
                                  />
                                }
                              >
                                <Form.Field>
                                  <Form.Input
                                    value={editingBrochure.name || ""}
                                    label="Brochure name"
                                    type="input"
                                    name="name"
                                    onChange={(event, field) =>
                                      setEditingBrochure((prev) => ({
                                        ...prev,
                                        name: field.value,
                                      }))
                                    }
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <label>Brochure language</label>
                                  <Select
                                    value={editingBrochure.language || ""}
                                    disabled={isRequestLoading}
                                    name="language"
                                    options={languageOptions}
                                    onChange={(ev, field) =>
                                      setEditingBrochure((prev) => ({
                                        ...prev,
                                        language: field.value,
                                      }))
                                    }
                                  />
                                </Form.Field>
                                  <Form.Field>
                                  <label>Visa Type</label>
                                  <Select
                                    value={editingBrochure.visaType || ""}
                                    disabled={isRequestLoading}
                                    name="visaType"
                                    options={[
                                      { text: "VISA", value: "VISA" },
                                      { text: "ESTA", value: "ESTA" },
                                    ]}
                                    onChange={(ev, field) =>
                                      setEditingBrochure((prev) => ({
                                        ...prev,
                                        visaType: field.value,
                                      }))
                                    }
                                  />
                                </Form.Field>
                              </ModalForm>

                              <ConfirmPopup
                                content="Are you sure you want to delete this document and all previous versions?"
                                callback={() =>
                                  handleDeleteDocumentAllVersions(brochureId)
                                }
                              >
                                <Button
                                  color="red"
                                  type="button"
                                  disabled={isRequestLoading}
                                  icon="trash"
                                />
                              </ConfirmPopup>
                            </Table.Cell>
                          </Table.Row>
                        </Fragment>
                      );
                    },
                  )}
              </Table.Body>
            </Table>
          </>
        )}
      </Segment>
    </Segment.Group>
  );
};

export default DocumentHandler;
