import AdminArea from "./AdminArea";
import QAAgentArea from "./QAAgentArea";
import QAManagerArea from "./QAManagerArea";
import AgentArea from "./AgentArea";
import ManagerArea from "./ManagerArea";

export default {
  AdminArea,
  QAAgentArea,
  QAManagerArea,
  AgentArea,
  ManagerArea,
};
