import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  createUserApi,
  deleteUsersApi,
  getAllUsersApi,
  updateUserApi,
} from "../apis/userApi";
import toastr from "toastr";
import { AuthService } from "../services/authService";
import { ROLES } from "../constants/rolesConstants";
import { useSelector } from "react-redux";

export const UsersContext = createContext();

const UsersContextProvider = ({ children }) => {
  const [allUsers, setAllUsers] = useState([]);

  const { user } = useSelector((state) => ({
    user: state.currentUser,
  }));
  const { loggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    async function fetchAllUsers() {
      const { data } = await getAllUsersApi();
      setAllUsers(data);
    }
    if (loggedIn) {
      fetchAllUsers();
    } else {
      setAllUsers([]);
    }
  }, [loggedIn]);

  const isAdmin = useMemo(() => {
    return AuthService.checkRolesForUser(user, [ROLES.ADMIN]);
  }, [user]);

  const updateUser = useCallback(
    async (userId, changedPart, withoutRequest) => {
      setAllUsers(
        allUsers.map((stateUser) =>
          stateUser._id === userId
            ? { ...stateUser, ...changedPart }
            : stateUser,
        ),
      );
      if (withoutRequest) return;
      try {
        const { data: user } = await updateUserApi(userId, changedPart);

        const newUsers = allUsers.map((stateUser) =>
          stateUser._id === userId ? user : stateUser,
        );
        setAllUsers(newUsers);

        toastr.success("Successfully updated user");
        return newUsers;
      } catch (err) {
        toastr.error("Failed updating users");
      }
    },
    [allUsers],
  );

  const createUser = useCallback(
    async (userToCreate) => {
      try {
        const { data: user } = await createUserApi(userToCreate);
        const newUsers = [user, ...allUsers];
        setAllUsers(newUsers);
        toastr.success("Successfully created user");
        return newUsers;
      } catch (err) {
        toastr.error("Failed creating user");
      }
    },
    [allUsers],
  );

  const deleteUser = useCallback(
    async (user) => {
      try {
        await deleteUsersApi(user._id);
        toastr.success("Deleted user");
        const newUsers = allUsers.filter(
          (stateUser) => user._id !== stateUser._id,
        );
        setAllUsers(newUsers);
        return newUsers;
      } catch (err) {
        toastr.error("Couldnt delete user");
      }
    },
    [allUsers],
  );

  const managerUsers = useMemo(() => {
    return user
      ? isAdmin
        ? allUsers
        : allUsers.filter((currUser) => currUser.managerId === user._id)
      : [];
  }, [allUsers, user, isAdmin]);

  const getUsersFiltered = useCallback(
    (filters) => {
      const { name, roles, online, availability, zendeskHost } = filters;
      let filteredUsers = managerUsers;

      Object.keys(filters).forEach((filter) => {
        switch (filter) {
          case "name":
            filteredUsers = filteredUsers.filter(
              (user) =>
                user.fullName?.toLowerCase().includes(name) ||
                user.username?.toLowerCase().includes(name) ||
                user.email?.toLowerCase().includes(name),
            );
            break;
          case "roles":
            if (roles && roles.length > 0) {
              filteredUsers = filteredUsers.filter(
                (user) =>
                  user.roles?.filter((role) => roles.includes(role)).length > 0,
              );
            }
            break;
          case "online":
            filteredUsers =
              online === null
                ? filteredUsers
                : filteredUsers.filter(
                    (user) => user.state?.isOnline === online,
                  );
            break;
          case "availability":
            if (availability && availability.length > 0) {
              filteredUsers = filteredUsers.filter((user) =>
                availability.includes(user.settings.availability.toLowerCase()),
              );
            }
            break;
          case "zendeskHost":
            filteredUsers =
              zendeskHost === null
                ? filteredUsers
                : filteredUsers.filter(
                    (user) => user.zendeskHost === zendeskHost,
                  );
            break;
          default:
            break;
        }
      });
      return filteredUsers;
    },
    [managerUsers],
  );

  return (
    <UsersContext.Provider
      value={{
        allUsers,
        managerUsers,
        getUsersFiltered,
        setAllUsers,
        createUser,
        updateUser,
        deleteUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export default UsersContextProvider;
