import React from 'react'
import { VALIDATION_TYPES, VALIDATION_STYLE_MAP } from "../../../../../../../../../../constants/applicationValidation";
import {
    Segment,
    HeaderContent,
    Header,
    Icon,
  } from "semantic-ui-react";

const GeneralModalMessage = ({ message, type }) => {
    const { color, icon } = VALIDATION_STYLE_MAP[type] || VALIDATION_STYLE_MAP[VALIDATION_TYPES.ERROR];

    return (
      <Segment color={color}>
        <Header as="h3" color={color}>
          <Icon name={icon} />
          <HeaderContent>{message}</HeaderContent>
        </Header>
      </Segment>
    );
}

export default GeneralModalMessage