import React, { useEffect, useState } from 'react';
import TimeLineEntry from "./TimeLineEntry.component";
import { Icon } from "semantic-ui-react";
import { uniqueId } from "lodash";

const TimeLine = ({ notes, handlePinNote, maxNotes, showPins = true }) => {
  const [appNotes, setAppNotes] = useState([]);

  useEffect(() => {
    if (maxNotes) {
      setAppNotes(notes.slice(0, maxNotes));
    } else {
      setAppNotes(notes);
    }
  }, [notes, maxNotes]);
  
  return (
    <div className="appn-timeline">
      {appNotes.length > 0 ? (
        <>
          <div className="appn-line" />
          {appNotes.map((note) => (
            <TimeLineEntry
              key={uniqueId()}
              avatar={note.data.user.name}
              commentAction={note}
              handlePinNote={handlePinNote}
              showPins={showPins}
            />
          ))}
        </>
      ) : (
        <div className="appn-text">
          <h3>
            <Icon className="appn-icon" name="sticky note outline" />
            Applicant Notes
          </h3>
          <p>This is where applicant notes will go</p>
        </div>
      )}
    </div>
  );
};

export default TimeLine;
