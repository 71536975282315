export const columns = [
	{ key: "product", label: "Product" },
	{ key: "message", label: "Error message" },
	{ key: "count", label: "Total count" },
	{ key: "alertInfo", label: "Alert info" },
  ];
  
  export const timeIntervals = [
	{ key: "1h", text: "1h", value: 1 },
	{ key: "3h", text: "3h", value: 3 },
	{ key: "6h", text: "6h", value: 6 },
	{ key: "12h", text: "12h", value: 12 },
	{ key: "24h", text: "24h", value: 24 }
  ];
  
