import axios from "../httpClient";

function getIvisaConfig() {
  return axios.get(`/ivisa/configuration`);
}

function editIvisaConfiguration(body) {
  return axios.put(`/ivisa/configuration`, body);
}

export default {
  getIvisaConfig,
  editIvisaConfiguration,
};
