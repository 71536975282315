import React from "react";
import { Popup, TextArea, Button } from "semantic-ui-react";
import Toggle from "../../../../../../../components/Toggle";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import "./QAQuestionItem.styles.scss";

const QAQuestionItem = ({ question, onChange, removeSkip = false }) => {
  return (
    <div className="qa-ticket-page-question-item">
      {question.description ? (
        <Popup
          content={question.description}
          trigger={
            <p className="qa-ticket-page-question-title">{question.title}</p>
          }
        />
      ) : (
        <p className="qa-ticket-page-question-title">{question.title}</p>
      )}
      <Toggle
        disabled={question.skip}
        checked={question.answer}
        callback={(checked) => onChange("answer", checked)}
      />
      <ConfirmPopup
        callback={() => onChange("notes", question.notes)}
        content={
          <div style={{ padding: "10px" }}>
            <h4>Please write notes below</h4>
            <TextArea
              disabled={question.skip}
              value={question.notes}
              className="sk-textarea"
              onChange={(ev, { value }) => onChange("notes", value)}
            />
          </div>
        }
      >
        <Button color={question.notes ? "green" : undefined}>Notes</Button>
      </ConfirmPopup>
      <div className="qa-ticket-page-question-not-apply-container">
        {!removeSkip && (
          <React.Fragment>
            <p className="qa-ticket-page-question-not-apply-label">
              Does not apply
            </p>
            <Toggle
              checked={question.skip}
              callback={(checked) => onChange("skip", checked)}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default QAQuestionItem;
