import React, { useCallback, useState } from "react";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import { Modal, Button, Dimmer, Loader, Icon, Popup } from "semantic-ui-react";
import { getDateFormat } from "../../../../../../../common/date-format";
import { getApplicantErrorsApi } from "../../../../../../../apis/applicantApi";
import apis from "../../../../../../../apis";
import toastr from "toastr";
import { byteArrayToUrl } from "../../../../../../../utils";
import "./ApplicantErrors.styles.scss";

const ApplicantErrors = ({ id, api, icon }) => {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getApplicantErrors = useCallback(async () => {
    try {
      setIsLoading(true);
      const errorsResponse = await getApplicantErrorsApi(id, api);
      setErrors(errorsResponse?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [api, id]);

  const openDocument = useCallback(async (key, type) => {
    try {
      setIsLoading(true);
      const {
        data: {
          buffer: { data },
        },
      } = await apis.applicantDocumentHandler.getFile(key);
      const url = byteArrayToUrl(data, type);
      window.open(url, "_blank");
    } catch (e) {
      console.error(e);
      toastr.error("Failed to open file");
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <ConfirmPopup
      content={
        <React.Fragment>
          <Modal.Header>Errors list</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              {!isLoading ? (
                errors.length > 0 ? (
                  errors.map((error, index) => (
                    <React.Fragment key={index}>
                      <Popup
                        hoverable
                        content={error.message}
                        disabled={error.message.length < 70}
                        trigger={
                          <p className="error">
                            <span
                              className={
                                error.message?.length > 70
                                  ? "error__message"
                                  : ""
                              }
                              data-text={error.message || "Unknown error"}
                            >
                              {error.message || "Unknown error"}
                            </span>
                            <p className="error__date">
                              <span>{getDateFormat(error.createdAt)}</span>
                              {error.errorImage && (
                                <Button
                                  color="red"
                                  size="medium"
                                  icon
                                  onClick={() =>
                                    openDocument(error.errorImage, "image/jpg")
                                  }
                                >
                                  <Icon name="exclamation triangle" />{" "}
                                </Button>
                              )}
                            </p>
                          </p>
                        }
                        position="right center"
                      />
                      <hr />
                    </React.Fragment>
                  ))
                ) : (
                  <p>There are no errors.</p>
                )
              ) : (
                <Dimmer active={isLoading}>
                  <Loader size="large" />
                </Dimmer>
              )}
            </Modal.Description>
          </Modal.Content>
        </React.Fragment>
      }
      onOpen={getApplicantErrors}
      cancelButton={null}
      callback={async () => {}}
    >
      {icon ? (
        <Button color="red" size="mini" icon>
          <Icon name="exclamation triangle" />
        </Button>
      ) : (
        <Button color="red">Errors</Button>
      )}
    </ConfirmPopup>
  );
};

export default ApplicantErrors;
