import axios from "../httpClient";

function getZendeskTicketConfiguration() {
  return axios.get("/configuration/zendesk/ticket");
}

function updateZendeskTicketConfiguration(api, configuration) {
  return axios.patch(`/configuration/zendesk/ticket`, {
    api,
    configuration,
  });
}

export default {
  getZendeskTicketConfiguration,
  updateZendeskTicketConfiguration,
};
