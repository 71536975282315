import React, { useCallback, useState } from "react";
import { Form } from "semantic-ui-react";

const MarkAsCapturedForm = ({ applicant }) => {
  const [item, setItem] = useState(applicant);

  const changeCurrentItemPropertyValue = useCallback((property, value) => {
    setItem((currItem) => ({ ...currItem, [property]: value }));
  }, []);

  return (
    <div>
      <Form.Field>
          <Form.Input
            value={item.orderId || ""}
            label="Order ID"
            placeholder="Order ID"
            type="input"
            name="orderId"
            onChange={(event, field) =>
              changeCurrentItemPropertyValue("orderId", field.value)
            }
          />
        </Form.Field>
    </div>
  );
};

MarkAsCapturedForm.defaultProps = {
  applicant: {
    orderId: "",
  },
};

export default MarkAsCapturedForm;
