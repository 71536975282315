import React, { useContext, useCallback, useState } from "react";
import { Item, Button, Checkbox, Input } from "semantic-ui-react";
import ConfirmPopup from "../../../../../../../components/ConfirmPopup";
import "./IvisaConfiguration.scss";
import toastr from "toastr";
import ConfigurationContext from "../../providers/ConfigurationProvider/Configuration.context";
import { logServerError } from "../../../../../../../common/logs";
import apis from "../../../../../../../apis";

const IvisaConfiguration = () => {
  const {
    ivisaConfig: defaultIvisaConfig,
    setIvisaConfig: setDefaultIvisaConfig,
  } = useContext(ConfigurationContext);
  const [ivisaConfig, setIvisaConfig] = useState(defaultIvisaConfig);

  const { isManualSubmissionEnabled, dailyQuotaLimit } = ivisaConfig;

  const editIvisaConigurationHandler = useCallback(async () => {
    try {
      await apis.configuration.ivisaConfig.editIvisaConfiguration(ivisaConfig);
      setDefaultIvisaConfig(ivisaConfig);
      toastr.success("Configuration have been updated!");
    } catch (err) {
      logServerError(err);
    }
  }, [ivisaConfig, setDefaultIvisaConfig]);

  const discardHandler = useCallback(() => {
    setIvisaConfig(defaultIvisaConfig);
  }, [setIvisaConfig, defaultIvisaConfig]);

  const changeIvisaProperty = useCallback(
    (prop, value) => {
      setIvisaConfig({
        ...ivisaConfig,
        [prop]: value,
      });
    },
    [setIvisaConfig, ivisaConfig],
  );

  return (
    <React.Fragment>
      <Item.Meta>Ivisa Configuration</Item.Meta>
      <Item.Description className="ivisa-content-wrapper">
        <div className="manual-submit-toggle">
          <p>Manual submission </p>
          <br />
          <Checkbox
            toggle
            onChange={(event, { checked }) =>
              changeIvisaProperty("isManualSubmissionEnabled", checked)
            }
            checked={isManualSubmissionEnabled}
          />
          {isManualSubmissionEnabled ? "enabled" : "disabled"}
        </div>
        <div className="daily-quota-input">
          Daily Quota Limit
          <br />
          <Input
            name="dailyQuotaLimit"
            type="number"
            min="-1"
            value={dailyQuotaLimit}
            onChange={(_, { value }) => {
              changeIvisaProperty("dailyQuotaLimit", value);
            }}
          />
        </div>
      </Item.Description>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "12px",
        }}
      >
        <ConfirmPopup
          content="Are you sure you want discard changes?"
          callback={discardHandler}
        >
          <Button className="discard-button">Discard</Button>
        </ConfirmPopup>
        <ConfirmPopup
          content="Are you sure you want save changes?"
          callback={editIvisaConigurationHandler}
        >
          <Button className="save-button">Save</Button>
        </ConfirmPopup>
      </div>
    </React.Fragment>
  );
};

export default IvisaConfiguration;
